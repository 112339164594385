import { FitmentTypeEnum, productWheelTypes } from '../globalConstants/global.constant'
import { CartOrderEntries } from '../redux/models/cart.interface'
import { iSaveForLaterEntry } from '../redux/models/SaveForLater.interface'
import getPageType from '../utils/getPageType'
import { pageTypes } from '../config'
import { checkNotNullAndUndefined } from './checkNotNullAndUndefined'

export const isTireType = (typeString?: string): boolean =>
    typeString === productWheelTypes.Tire || typeString === productWheelTypes.WinterTire

export const isProductTire = (product: CartOrderEntries | iSaveForLaterEntry): boolean => {
    return !checkNotNullAndUndefined(product?.productWheelType) && isTireType(product?.productWheelType?.toUpperCase())
}

export const isProductWheel = (product: CartOrderEntries | iSaveForLaterEntry): boolean =>
    product?.productWheelType && product?.productWheelType?.toUpperCase() === productWheelTypes.Wheel ? true : false
export const isProductOtherAutoPart = (product: CartOrderEntries | iSaveForLaterEntry): boolean => {
    return (
        // eslint-disable-next-line sonar/expression-complexity
        !checkNotNullAndUndefined(product?.fitmentTypeCode) &&
        product?.fitmentTypeCode !== FitmentTypeEnum.UNIVERSAL_FIT_01 &&
        product?.productWheelType?.toUpperCase() !== productWheelTypes.Wheel &&
        product?.productWheelType?.toUpperCase() !== productWheelTypes.Tire &&
        product?.productWheelType?.toUpperCase() !== productWheelTypes.WinterTire
    )
}
export const isAutoProduct = (products: CartOrderEntries[]): boolean => {
    return !!products?.filter((product: CartOrderEntries) => isProductTire(product) || isProductWheel(product)).length
}
export const isServiceEligible = (products: CartOrderEntries[]): boolean => {
    return (
        !!products?.filter((product: CartOrderEntries) => product.hasStoreAutoServiceCenter).length &&
        isAutoProduct(products) &&
        !!products?.filter((product: CartOrderEntries) => product.installationEligible).length
    )
}
export const isPageCartOrWishlist = (): boolean => {
    const pageType = getPageType()
    return pageType === pageTypes.cart || pageType === pageTypes.wishlist
}

export const isProductAutomotive = (product: CartOrderEntries | iSaveForLaterEntry): boolean => {
    return isProductWheel(product) || isProductTire(product) || isProductOtherAutoPart(product)
}

/**
 * This function is used to check order details page or order confirmation page
 * @return {boolean}
 */
export const isOrderPage = (): boolean => {
    const pageType = getPageType()
    return pageType === pageTypes.orderDetails || pageType === pageTypes.orderConfirmation
}
