import React, { useState, Validator } from 'react'
import PropTypes from 'prop-types'

import { PREFIX, BREAKPOINTS } from '../config'
import Accordion from '../Accordion'
import { useGlobalResizeEvent } from '../../utils/useWindowEvent'
import { ResourceCompProps, LinkPlaceholder, BrandResourceObj } from './resource.types'
import Icon from '../Icon'
import { isArrayNotEmpty } from '../../utils'

const Resources: React.FC<ResourceCompProps> = ({
    heading,
    resourceBody,
    defaultState = false,
    isPrerender,
}): JSX.Element => {
    /**
     * Renders the resource body text with icons and description.
     * @function
     * @return {JSX.Element} resource body element
     */
    function renderResourceBody(): JSX.Element {
        return (
            <>
                {resourceBody.map((value: BrandResourceObj, index: number) => {
                    return (
                        value.description && (
                            <div
                                className={` ${PREFIX}-pr-sm ${PREFIX}-col-sm-6 ${PREFIX}-col-md-3 ${PREFIX}-col-lg-3 ${PREFIX}-col-xs-6 ${PREFIX}-resources__container`}
                                key={`${value.iconId}${value.iconLabel}${index}`}>
                                <div className={`${PREFIX}-resources__body`}>
                                    <div className={`${PREFIX}-resources__icon`}>
                                        <Icon type={value.iconId} />
                                    </div>
                                    <div className={`${PREFIX}-resources__details`}>
                                        <h3 className={`${PREFIX}-resources__label`}>{value.iconLabel}</h3>
                                        <p className={`${PREFIX}-resources__text`}>{value.description}</p>
                                        {renderResourceLinks(value)}
                                    </div>
                                </div>
                            </div>
                        )
                    )
                })}
            </>
        )
    }

    /**
     * Renders the resource links under each resource.
     * @function
     * @param {BrandResourceObj} resourceObj :Multi-select field data
     * @return {JSX.Element} resource link element
     */
    function renderResourceLinks(resourceObj: BrandResourceObj): JSX.Element {
        return (
            <>
                {isArrayNotEmpty(resourceObj.linkPlaceholder) && (
                    <div className={`${PREFIX}-resources__links`}>
                        {resourceObj.linkPlaceholder.map((link: LinkPlaceholder, index: number) => {
                            // don't add download link for html links
                            const isDownloadReq = !(link?.linkURL && link?.linkURL.includes('.html'))
                            return (
                                link?.linkURL && (
                                    <a
                                        className={`${PREFIX}-resources__link ${PREFIX}-pb-base`}
                                        href={`${link.linkURL}`}
                                        key={`${link.linkURL}${index}`}
                                        target={link.openTarget}
                                        data-link-value={link.link}
                                        download={!!isDownloadReq}>
                                        {link.link}
                                    </a>
                                )
                            )
                        })}
                    </div>
                )}
            </>
        )
    }

    const [isMobile, setIsMobile] = useState(window.innerWidth <= BREAKPOINTS.tabletMaxWidth)

    const handleResize = () => {
        setIsMobile(window.innerWidth <= BREAKPOINTS.tabletMaxWidth)
    }

    useGlobalResizeEvent(handleResize)

    const renderResources = (): JSX.Element => {
        const prerenderClass = isPrerender ? `${PREFIX}-md-none` : ''
        return isMobile ? (
            <div id="resources" className={`${PREFIX}-resources ${PREFIX}-section`}>
                <Accordion title={heading} isHeaderOpen={defaultState}>
                    <div className={`${PREFIX}-row`}>{isArrayNotEmpty(resourceBody) && renderResourceBody()}</div>
                </Accordion>
            </div>
        ) : (
            <div id="resources" className={`${PREFIX}-resources ${PREFIX}-section ${prerenderClass}`}>
                <div className={`${PREFIX}-row`}>{isArrayNotEmpty(resourceBody) && renderResourceBody()}</div>
            </div>
        )
    }
    return <>{renderResources()}</>
}
Resources.propTypes = {
    heading: PropTypes.string.isRequired,
    resourceBody: PropTypes.array.isRequired as Validator<BrandResourceObj[]>,
    defaultState: PropTypes.bool,
    isPrerender: PropTypes.bool,
}
export default Resources
