/* eslint-disable max-lines */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import {
    Button,
    Icon,
    Tag,
    getPageType,
    replaceStrWithDynamicVal,
    handleClickOrEnterAccessibility,
    StoreTooltip,
    Tooltip,
    areAllParamsValid,
    isAtleastOneParamValid,
    getFormattedDateTime,
    libUtils,
    SkeletonComponent,
    getFormattedDateWithComparison,
    getFormattedTimeDate,
    getFormattedPrice,
} from '@nl/lib'
import { PREFIX, pageTypes } from '../../../config'
import { BuyOnlineType, BoxType, ShipToHomeType } from './BuyOnline.type'
import { commonContentAvailableSelector, commonContentSelector } from '../../../redux/selectors/commonContent.selectors'
import { fulfillmentConstants } from '../BuyBox.constant'
import { productDataSelector, productSelector } from '../../../redux/selectors/product.selectors'
import {
    renderExpressDeliveryAvailabilityDate,
    getVariantMessage,
    rendersTooltipComp,
    isNeededStoreWarningMessage,
    showFulfillmentOptions,
    tagTypeForSTHFirstFulfillment,
    labelTagSTHFirstFulfillment,
    getFulfillmentTagValueForThirdBox,
    handleKeyDown,
    expressDeliveryFulfillmentTagDetails,
    getFulfillmentMethod,
    shouldFetchEstimateDeliveryDate,
    renderSTHEstimateLabel,
    isRepairOrderRequired,
    getFriendlyDateTime,
    getFormattedSTHEarliestDate,
    getFormattedEstimatedFee,
    extractMinAndMaxSTHETA,
    isOnlineOnlyProduct,
    extractBadgesAndOrderable,
    isExpressDeliveryEligible,
    showCurbsideLabel,
    extractBadges,
    getAvailabilityTagLabel,
    showNeedMore,
    showSplitEtaDatesForSTH,
    freePickUpSplitLabelClass,
    isExpressDeliveryEnabled,
    getEDUnavailableLabel,
    isSkuOutOfStock,
    isAvailableAtStore,
    isOOSAtStoreAndDC,
} from './BuyOnline.helper'
import { IStoreLocator } from '../../../redux/models/commonContent.interface'
import { storeDetailsDataSelector } from '../../../redux/selectors/storeDetails.selectors'
import { userProfileDataSelector, userProfileVehicleSelector } from '../../../redux/selectors/userProfile.selectors'
import {
    checkEstimateDeliveryDateAvailability,
    getShippingThreshold,
    isProductEligibleForExpressDelivery,
    isStoreEligibleForED,
} from '../BuyBox.helper'
import { inStockString, inStockTag, lowStockTag } from './FulfillmentOptions.constant'
import { FulfillmentMethods } from '../../ShoppingCart/ShoppingCart.type'
import { enableDestructOnUndefinedData } from '../../../utils/PDP/enableDestructOnUndefinedData.utils'
import { postalCodeRegex, getBOPISDateFormat, StoreChangeSource, emptySpace } from '../../../globalConstants'
import { fetchPreferredStoreDetails, setSelectedStoreName } from '../../../redux/actions'
import { StorePickUpType } from '../SwitchFulfillmentOptions/SwitchFulfillmentOptions.type'
import { getEstimateDeliveryDate } from '../../../redux/actions'
import { estimatedDeliveryDateSelector } from '../../../redux/selectors/estimatedDeliveryDate.selectors'
import appCacheService from '../../../utils/appCacheService'
import { useAuth } from '../../../hooks'
import { deliveryOptionsSelector } from '../../../redux/selectors/deliveryOptionsSelectors'
import { setIsDeliveryOptionsApiDone } from '../../../redux/actionCreators'
import { getCriticalFitmentProducts } from '../../AutomotivePDP/AutomotiveBuyBox/AutomotiveBuyBox.helper'
import { FulfillmentDTOP, ItemAvailability, ProductSku } from '../../../redux/models/product.interface'
import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux.hook'

const BuyOnline: React.FC<BuyOnlineType> = ({ ...props }): JSX.Element => {
    const {
        buyOnlineHeaderText,
        shipToHomeLabel = '',
        freeShippingEnabled = false,
        freePickUpInStoreLabel = '',
        availableLabel = '',
        checkIfAllVariantsSelected = false,
        freeShippingOverLabel = '',
        regularShippingThreshold = 0,
        shipsInLabel,
        variantDetails,
        isShipToHome = false,
        availabilitySelectVariantMsg = '',
        availabilitySelectVariantGenericMsg = '',
        pickupAvailabilitySelectVariantMsg = '',
        sthAvailabilitySelectVariantMsg = '',
        getItByLabelff = '',
        getItTomorrowLabelff = '',
        getItTodayLabelff = '',
        fulfillmentTooltip = '',
        inStockInSelectedStore = false,
        inStockAtDC = false,
        shippingFeeCalculation,
        fulfillment,
        triangleShippingThreshold = 0,
        a11yTooltipIcon,
        availableByLabel,
        timeLabelWhenPickUpAvailable,
        sendRequestByClickNearByStores,
        selectedStoreQty = 0,
        rendersStoreFlyoutComp,
        pickUpAtStoreLabel,
        closeFlyout,
        shouldStoreFlyoutOpen,
        nearByStoresList,
        setIsShipToHome,
        setIsFreePickUp,
        setIsExpressDelivery,
        isAddToCartClicked,
        inStockAtNearbyStores,
        outOfStockAtStoreff = '',
        outOfStockTitle,
        outOfStockText,
        isProductLevel,
        isOutOfStockInAllStore,
        isVariantsHidden,
        hidingAllFirstVariantOOSMessage,
        productNotForSth,
        unavailableLabel = '',
        shipToHomeUnavailable,
        setSelectedFulfillment,
        inStockLabel,
        checkOtherStoresLabel = '',
        isOnlineOnly = false,
        bopisUnavailable = false,
        freePickUpUnavailableAtSelectedStore,
        freePickUpUnavailable,
        isSellableProduct,
        inStorePurchaseOnly,
        checkStoreAvailability,
        inStorePurchase,
        outOfStockLabel = '',
        expressDeliveryUnavailable,
        expressDeliveryUnavailableOOS,
        a11yEstimateFeesTooltipIcon,
        isBopisOnlySkuWithStoreBopisUnavailable,
        eligibilityAndShippingInfoLabel,
        storePickUpIcon,
        isShippingPostalCodeHide,
        showEstimatedFees,
        isPostalCode,
        setEstimateFeesModal,
        postalCodeVal,
        showEstimateFeesModal,
        changeLabel,
        setPostalCodeVal,
        qtySelectorInput,
        isProductBulk,
        availabilityObj,
        sku,
        estimateStandardShippingFeesTooltipHeaderText,
        estimateStandardShippingFeesTooltipBodyText,
        shipsByLabel,
        shipsBetween,
        postalCodeForEligFeesLabel,
        deliveryLabel,
        deliveryFeeLabel,
        deliverToLabel,
        needMoreLabel,
        isNearbyStoresListEmpty,
        sameDayDeliverySLETooltipBodyText,
        pickUpBetweenLabel,
        freePickUpInStoreOverLabel,
        thresholdTooltipHeaderText,
        thresholdTooltipThresholdConditionText,
        thresholdTooltipDescription,
        urgencyMessageLabel,
        isShowCurbsideAvailabilityLabelForProposedStores,
        isShowAvailableByLabel,
        curbsidePickupAvailableLabel,
        isAutomotiveProduct,
        isOutOfStockAtStoreAndDC,
        selectStoreLabel,
        isProductNotEligibleForSTH,
        needMoreItemsDescLabel,
        heavyOversizeHeader,
        heavyOversizeBody,
        heavyOversizeItemText,
        isProductDiscontinued,
        isSeparatedErrorHandling,
        isFreePickUp,
        isExpressDeliveryEligibleForPostalCode = true,
        delFeesToolTipHeaderText,
        delFeesToolTipBodyText,
        setShowEstimatedFeesEFModal,
        estimatedFeesPriceText,
        estimatedExpressDeliveryFeesPriceText,
        onlineOrdersNotAcceptedBuyBoxMsg,
        showAutoServiceUnavailableMsg,
        isProductFullyDiscontinued,
        fullyDiscontinuedTitle,
        skus,
        qtySelectorInputFront,
        qtySelectorInputRear,
        isNearByStoresAvailable,
        setIsNearByStoresAvailable,
        flyoutVariantsInfoData,
        setIsStoreSelectedFromBuyBox,
        isStoreSelectedFromBuyBox,
        enablePromoBadgesForBoxesModel,
    } = props

    const thresholdTooltipIconRef = useRef(null)

    const notEligibleExpressDeliveryTooltipIconRef = useRef(null)
    const [notEligibleExpressDeliveryTooltipVisibility, setNotEligibleExpressDeliveryTooltipVisibility] =
        useState(false)

    const { commonContentAvailable } = useAppSelector(commonContentSelector)
    const getCommonContent = () => {
        return {
            generalContent: commonContentAvailable?.general || ({} as typeof commonContentAvailable.general),
            productContent: commonContentAvailable?.product || ({} as typeof commonContentAvailable.product),
            automotiveContent: commonContentAvailable?.automotive || ({} as typeof commonContentAvailable.automotive),
            accessibilityContent:
                commonContentAvailable?.accessibility || ({} as typeof commonContentAvailable.accessibility),
        }
    }
    const { generalContent, productContent, accessibilityContent, automotiveContent } = getCommonContent()
    const { attributeFrontLabel, attributeRearLabel } = automotiveContent
    const {
        expressDeliveryLabel,
        shipToHomePromoBadgeLabel,
        shipToHomePromoBadgeEnabled,
        sameDayDeliveryPromoBadgeLabel,
        sameDayDeliveryPromoBadgeEnabled,
        inStorePickUpPromoBadgeLabel,
        inStorePickUpPromoBadgeEnabled,
    } = generalContent

    const { storeLocator: commonContentStoreLocator = {} as IStoreLocator } =
        useAppSelector(commonContentAvailableSelector)
    const { closesAtLabel, openingAtLabel, closeLabel, openLabel, storeDetailsCTALabel } = commonContentStoreLocator

    const generalProductData = useAppSelector(productSelector)
    const { preferredStoreDetails, selectedStoreName, selectedPreferredStoreId, isNearbyStoreListApiDone } =
        useAppSelector(storeDetailsDataSelector)
    const { estimateDeliveryDateData, estimateDeliveryDateSuccess, estimateDeliveryDateFailure } =
        useAppSelector(estimatedDeliveryDateSelector)
    const productData = useAppSelector(productDataSelector)
    const currentProductData = flyoutVariantsInfoData?.productData || productData
    const {
        selectedFirstVariant,
        selectedSecondVariant,
        selectedThirdVariant,
        productSkuData,
        isHeliumInflationSelected,
    } = flyoutVariantsInfoData || generalProductData
    const isInFlyout = !!flyoutVariantsInfoData
    const { estimatedDeliveryDateEnabled } = preferredStoreDetails
    const updateButtonRef = useRef<HTMLButtonElement>(null)
    const estimateShippingFeeTooltipIconRef = useRef(null)
    const runEstimateDeliveryDateApiOnce = useRef(0)
    const dispatch = useAppDispatch()
    const [heavyOversizeTooltipVisibility, setHeavyOversizeTooltipVisibility] = useState(false)

    const { a11yTooltipIcon: informationLabel, a11yCloseIconLabel: closeIconLabel } = accessibilityContent

    const [section, setSection] = useState(isShipToHome ? shipToHomeLabel : freePickUpInStoreLabel)
    const tablistRef = useRef() as React.MutableRefObject<HTMLUListElement>
    const buttonRef = useRef() as React.MutableRefObject<HTMLButtonElement>
    const [tooltipvisibility, setTooltipVisibility] = useState(false)
    const isPDPPage = getPageType() === pageTypes.pdpPage

    const componentClassName = `${PREFIX}-buy-online`
    const selectedclass = `${componentClassName}__list-item--selected`
    const labelClass = `${componentClassName}__box-icon--label__selected`
    const disabledClass = `${componentClassName}__list-item--disabled`
    const sellableLabelClassName = `${componentClassName}__fulfillment__sellable-label`
    const noOnlineOrderClass = `${componentClassName}__fulfillment--no-online-order`
    const urgencyTextClassName = `${componentClassName}__free-pickup-container__urgency-text`
    const bopisUnavailableClass = bopisUnavailable ? '--noavailability-bullet' : ''

    const userProfileData = useAppSelector(userProfileDataSelector)
    const { isDeliveryOptionsApiDone } = useAppSelector(deliveryOptionsSelector)
    const freeShippingTooltipIconRef = useRef(null)
    const [freeShippingTooltipVisibility, setFreeShippingTooltipVisibility] = useState(false)
    const {
        shipToHome,
        expressDelivery: skuExpressDeliveryFulfillmentData = {
            enabled: false,
            etaEarliest: '',
            orderIn: '',
        },
        storePickUp,
    } = fulfillment || {}
    const shippingEstimation = shipToHome?.shippingEstimate
    const isTriangleSelectUser = userProfileData?.loyalty?.tsSubscriptionStatus === 'A'
    const [estimatedDeliveryDateStart, setEstimatedDeliveryDateStart] = useState('')
    const [estimatedDeliveryDateEnd, setEstimatedDeliveryDateEnd] = useState('')
    const [estimateShippingFeeTooltipVisibility, setEstimateShippingFeeTooltipVisibility] = useState(false)
    const { skus: productSkus, homeService } = currentProductData
    const { etaEarliest } = enableDestructOnUndefinedData(shipToHome) as ShipToHomeType
    const friendlySTHDate = getFormattedSTHEarliestDate(etaEarliest, getFormattedDateTime(etaEarliest)[0])
    const { orderable } = extractBadgesAndOrderable(productSkuData)
    const badges = extractBadges(currentProductData)
    const { MinETA, MaxETA } = extractMinAndMaxSTHETA(availabilityObj)
    const repairOrderRequired = productSkus?.some(prodSku => prodSku?.repairOrderRequired)
    const { onlineOrdering, isEligibleForCurbside, threshold, inStorePickUp, fee, storeTimezone } =
        preferredStoreDetails
    const { bopisETA, sthETA, Quantity: selectedProductDCQuantity } = { ...fulfillment?.availability?.Corporate }
    const [thresholdTooltipVisibility, setThresholdTooltipVisibility] = useState(false)
    const { etaEarliest: storePickUpDate } = enableDestructOnUndefinedData(storePickUp) as unknown as StorePickUpType
    const friendlyStorePickupDate = getFormattedTimeDate(storePickUpDate, getBOPISDateFormat())
    const isCurbsideAndAvailableByLabels = showCurbsideLabel(
        isShowCurbsideAvailabilityLabelForProposedStores as boolean,
        isShowAvailableByLabel as boolean,
    )
    const isOnlineOnlyCompared = useMemo(() => isOnlineOnlyProduct(badges), [badges])
    const repairOrderRequiredCompared = isRepairOrderRequired(repairOrderRequired, currentProductData)
    const friendlyDcSthDate = getFriendlyDateTime(MinETA, MaxETA)
    const friendlyDcBopisDate = getFriendlyDateTime(bopisETA?.MinETA as string, bopisETA?.MaxETA as string)
    const estimatedDeliveryDate = getFriendlyDateTime(estimatedDeliveryDateStart, estimatedDeliveryDateEnd)
    const isSTHEtaAvailable = areAllParamsValid(Boolean(MinETA), Boolean(MaxETA))
    const isEstimatedDateAvailable = areAllParamsValid(
        Boolean(estimatedDeliveryDateStart),
        Boolean(estimatedDeliveryDateEnd),
    )
    const [showNeedMoreDescriptionSTH, setShowNeedMoreDescriptionSTH] = useState(false)
    const [showNeedMoreDescriptionBopis, setShowNeedMoreDescriptionBopis] = useState(false)

    const isExpressQuantityExceeded =
        selectedStoreQty > 0 && libUtils.getNumberOrDefault(qtySelectorInput) > selectedStoreQty
    const showNeedFasterShipping = areAllParamsValid(
        selectedStoreQty >= 0,
        orderable,
        !isOutOfStockInAllStore,
        !isNearbyStoresListEmpty,
    )
    const showAvailableAtNearbyStores = isAtleastOneParamValid(isOutOfStockAtStoreAndDC, !isNearbyStoresListEmpty)
    const showNearByStoreslist = areAllParamsValid(
        isNearByStoresAvailable,
        Boolean(isAtleastOneParamValid(showNeedFasterShipping, showAvailableAtNearbyStores as boolean)),
    )
    const preferredStoreId = appCacheService.preferredStoreId.get()
    const heavyOversizeButtonRef = useRef(null)

    const isFreePickUpSection = section === freePickUpInStoreLabel
    const isSthSection = section === shipToHomeLabel
    const { isLoggedIn } = useAuth()
    const vehicle = useAppSelector(userProfileVehicleSelector)
    const { criticalFitment } = vehicle
    const isStaggeredFitmentProduct = getCriticalFitmentProducts(criticalFitment).isStaggered

    const isSplitEta = showSplitEtaDatesForSTH(
        orderable,
        selectedStoreQty,
        qtySelectorInput as number,
        selectedProductDCQuantity as number,
        isAutomotiveProduct as boolean,
        isProductDiscontinued,
        isSthSection,
    )

    const changeEstimateShippingFeeTooltipVisibility = (event: React.MouseEvent<HTMLButtonElement>): void => {
        event.stopPropagation()
        setEstimateShippingFeeTooltipVisibility(!estimateShippingFeeTooltipVisibility)
    }

    const changeHandlingHeavyOversizeTooltipVisibility = (event: React.MouseEvent<HTMLButtonElement>): void => {
        event.stopPropagation()
        setHeavyOversizeTooltipVisibility(!heavyOversizeTooltipVisibility)
    }

    const formattedEstimatedFee = getFormattedEstimatedFee(
        isShipToHome,
        estimatedFeesPriceText,
        estimatedExpressDeliveryFeesPriceText,
    )

    // if true displays fulfillment options else displays a out of stock
    const showFulfillment = showFulfillmentOptions(
        isOutOfStockInAllStore,
        isProductLevel,
        isVariantsHidden,
        checkIfAllVariantsSelected,
    )

    // checks if the express delivery is enabled for postal code product.
    const isEDEnabledForPostalCodeProduct = areAllParamsValid(
        Boolean(fulfillment?.expressDelivery?.enabled),
        isExpressDeliveryEligible(freeShippingEnabled, preferredStoreDetails),
        !isProductBulk,
        !isExpressQuantityExceeded,
        !isAutomotiveProduct,
        !isOnlineOnlyCompared,
        isExpressDeliveryEligibleForPostalCode,
    )

    // checks if the product is eligible for express delivery
    const isProductEligibleForED = isExpressDeliveryEnabled(
        fulfillment,
        isExpressDeliveryEligible(freeShippingEnabled, preferredStoreDetails),
        freeShippingEnabled,
        isEDEnabledForPostalCodeProduct,
    )

    const [expressDeliveryTagType, expressDeliveryTagLabel] = expressDeliveryFulfillmentTagDetails(
        fulfillment,
        outOfStockLabel,
        availableLabel,
        unavailableLabel,
        checkIfAllVariantsSelected,
        isProductEligibleForED,
    )

    const bopisAvailabilityLabel = getAvailabilityTagLabel(
        inStockInSelectedStore,
        isNearbyStoresListEmpty,
        checkOtherStoresLabel,
        outOfStockLabel,
    )

    /**
     * @param enabledFreeShipping
     * @param tagType
     */
    const checkPromoBadgeEnabled = useCallback(
        (enabledFreeShipping: boolean): boolean | undefined => {
            return enabledFreeShipping ? shipToHomePromoBadgeEnabled : inStorePickUpPromoBadgeEnabled
        },
        [shipToHomePromoBadgeEnabled, inStorePickUpPromoBadgeEnabled],
    )

    /**
     *
     * @param enabledFreeShipping
     * @param tagType
     */
    const checkIsShowPromoInfo = useCallback(
        (enabledFreeShipping: boolean, tagType: string): boolean | undefined => {
            return (
                enablePromoBadgesForBoxesModel &&
                tagType !== fulfillmentConstants.unavailable &&
                checkIfAllVariantsSelected &&
                checkPromoBadgeEnabled(enabledFreeShipping)
            )
        },
        [checkIfAllVariantsSelected],
    )

    const checkIsShowExpressDeliveryPromoInfo = useCallback(
        (tagType: string): boolean | undefined => {
            return (
                // eslint-disable-next-line sonar/expression-complexity
                enablePromoBadgesForBoxesModel &&
                tagType !== fulfillmentConstants.unavailable &&
                checkIfAllVariantsSelected &&
                sameDayDeliveryPromoBadgeEnabled &&
                isProductEligibleForExpressDelivery(fulfillment, freeShippingEnabled) &&
                isStoreEligibleForED(preferredStoreDetails, freeShippingEnabled)
            )
        },
        [fulfillment, freeShippingEnabled, preferredStoreDetails],
    )

    // useEffect to trigger the stores call when store is selected from list of near by stores
    useEffect(() => {
        if (isStoreSelectedFromBuyBox && preferredStoreId === selectedPreferredStoreId) {
            dispatch(fetchPreferredStoreDetails(preferredStoreId, undefined, undefined, StoreChangeSource.pdp))
            setIsStoreSelectedFromBuyBox(false)
        }
    }, [dispatch, preferredStoreId, selectedPreferredStoreId, isStoreSelectedFromBuyBox, setIsStoreSelectedFromBuyBox])

    /**
     * Function returns tag details for first box in three box fulfillment
     * @returns {string[]} tag type details
     */
    const getFulfillmentTagDetailsForFirstBox = (): string[] => {
        let tagType
        let tagLabel
        if (freeShippingEnabled) {
            tagType = tagTypeForSTHFirstFulfillment(freeShippingEnabled, productNotForSth)
            tagLabel = labelTagSTHFirstFulfillment(
                freeShippingEnabled,
                productNotForSth,
                availableLabel,
                unavailableLabel,
            )
        } else {
            if (!inStockInSelectedStore && (!inStockAtDC || !orderable) && !isNearbyStoresListEmpty) {
                tagType = lowStockTag
                tagLabel = checkOtherStoresLabel
            } else {
                tagType = inStockTag
                tagLabel = availableLabel
            }
        }

        return [tagType, tagLabel]
    }
    const [fulfillmentTagType, fulfillmentTagLabel] = getFulfillmentTagDetailsForFirstBox()

    // Array containing information such as type, tagType, label, tagLabel about 3 box fulfillment options for a product.
    const boxInfo = useMemo(
        () => [
            {
                type: freeShippingEnabled ? 'ct-default-ship-to-home' : storePickUpIcon,
                tagType: fulfillmentTagType,
                label: freeShippingEnabled ? shipToHomeLabel : freePickUpInStoreLabel,
                tagLabel: fulfillmentTagLabel,
                fulfillmentMethod: getFulfillmentMethod(freeShippingEnabled),
                isShowPromoBanner: checkIsShowPromoInfo(freeShippingEnabled, fulfillmentTagType),
                promoBannerLabel: freeShippingEnabled
                    ? (shipToHomePromoBadgeLabel as string)
                    : (inStorePickUpPromoBadgeLabel as string),
            },
            {
                type: 'ct-express-delivery',
                tagType: expressDeliveryTagType,
                label: expressDeliveryLabel,
                tagLabel: expressDeliveryTagLabel,
                fulfillmentMethod: FulfillmentMethods.EXPRESS,
                isShowPromoBanner: checkIsShowExpressDeliveryPromoInfo(expressDeliveryTagType),
                promoBannerLabel: sameDayDeliveryPromoBadgeLabel as string,
            },
            {
                type: freeShippingEnabled ? storePickUpIcon : 'ct-default-ship-to-home',
                tagType: getFulfillmentTagValueForThirdBox(
                    freeShippingEnabled,
                    productNotForSth,
                    inStockString,
                    fulfillmentConstants.unavailable,
                    isOnlineOnly,
                    selectedStoreQty,
                    'low-stock',
                    bopisUnavailable,
                    checkIfAllVariantsSelected,
                    isOutOfStockAtStoreAndDC,
                ),
                label: freeShippingEnabled ? freePickUpInStoreLabel : shipToHomeLabel,
                tagLabel: getFulfillmentTagValueForThirdBox(
                    freeShippingEnabled,
                    productNotForSth,
                    availableLabel,
                    unavailableLabel,
                    isOnlineOnly,
                    selectedStoreQty,
                    bopisAvailabilityLabel,
                    bopisUnavailable,
                    checkIfAllVariantsSelected,
                    isOutOfStockAtStoreAndDC,
                ),
                fulfillmentMethod: getFulfillmentMethod(!freeShippingEnabled),
                isShowPromoBanner: checkIsShowPromoInfo(
                    !freeShippingEnabled,
                    getFulfillmentTagValueForThirdBox(
                        freeShippingEnabled,
                        productNotForSth,
                        inStockString,
                        fulfillmentConstants.unavailable,
                        isOnlineOnly,
                        selectedStoreQty,
                        'low-stock',
                        bopisUnavailable,
                        checkIfAllVariantsSelected,
                        isOutOfStockAtStoreAndDC,
                    ),
                ),
                promoBannerLabel: freeShippingEnabled
                    ? (inStorePickUpPromoBadgeLabel as string)
                    : (shipToHomePromoBadgeLabel as string),
            },
        ],
        [
            availableLabel,
            bopisAvailabilityLabel,
            bopisUnavailable,
            checkIfAllVariantsSelected,
            expressDeliveryLabel,
            expressDeliveryTagLabel,
            expressDeliveryTagType,
            freePickUpInStoreLabel,
            freeShippingEnabled,
            fulfillmentTagLabel,
            fulfillmentTagType,
            isOnlineOnly,
            isOutOfStockAtStoreAndDC,
            productNotForSth,
            selectedStoreQty,
            shipToHomeLabel,
            storePickUpIcon,
            unavailableLabel,
        ],
    )

    useEffect(() => {
        setSelectedFulfillment(section)
        if (isSthSection) {
            setIsShipToHome(true)
            dispatch(setIsDeliveryOptionsApiDone(true))
            // eslint-disable-next-line sonarjs/elseif-without-else
        } else if (!(isSthSection && isFreePickUpSection)) {
            dispatch(setIsDeliveryOptionsApiDone(true))
        }
    }, [section, setSelectedFulfillment, setIsShipToHome, isSthSection])

    // useEffect to setIsNearByStoresAvailable variable to true when oos at selected store
    useEffect(() => {
        if (selectedStoreQty === 0) {
            setIsNearByStoresAvailable(true)
        }
    }, [selectedStoreQty])

    /**
     * This function returns the flag to determine whether to include DC quantity
     * @returns {boolean} includeFlagInStockAtDC
     */
    const includeFlagInStockAtDC = useCallback(() => {
        return isSeparatedErrorHandling && isFreePickUp ? false : inStockAtDC
    }, [inStockAtDC, isFreePickUp, isSeparatedErrorHandling])

    // useEffect to dispatch setSelectedStoreName
    useEffect(() => {
        if (!inStockInSelectedStore && !includeFlagInStockAtDC() && inStockAtNearbyStores && selectedStoreName) {
            dispatch(setSelectedStoreName(''))
            // eslint-disable-next-line sonarjs/elseif-without-else
        } else if ((!freeShippingEnabled && !inStockInSelectedStore && inStockAtDC) || inStockInSelectedStore) {
            dispatch(setSelectedStoreName(preferredStoreDetails?.displayName))
        }
    }, [
        dispatch,
        freeShippingEnabled,
        inStockAtDC,
        inStockAtNearbyStores,
        inStockInSelectedStore,
        preferredStoreDetails,
        includeFlagInStockAtDC,
        selectedStoreName,
    ])

    /**
     * This function handles click or enter event for tab
     * @param { React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement> } event - click or keydown event
     * @param { number } currentIndex - current index
     * @param { string } label - label
     * @returns { void } - returns nothing
     */
    const handleClickOrEnter = (
        event: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>,
        currentIndex: number,
        label: string,
    ) => {
        handleClickOrEnterAccessibility(event, currentIndex, tablistRef.current)
        setSection(label)
    }

    /**
     * Function to set the current item
     * @param {BoxType} item - item
     * @returns {void} - returns nothing
     */
    const onClickListItem = (item: BoxType): void => {
        setSection(item.label)
        switchFulfillmentMethod(item.fulfillmentMethod)
    }

    /**
     * function to switch FulfillmentMethod
     * @param { string } fulfillmentMethod fulfillmentMethod
     * @returns {void} - returns nothing
     */
    const switchFulfillmentMethod = (fulfillmentMethod: string): void => {
        switch (fulfillmentMethod) {
            case FulfillmentMethods.STH:
                setIsShipToHome(true)
                setIsFreePickUp(false)
                setIsExpressDelivery(false)
                break
            case FulfillmentMethods.EXPRESS:
                setIsShipToHome(false)
                setIsFreePickUp(false)
                setIsExpressDelivery(true)
                break
            case FulfillmentMethods.BOPIS:
                setIsShipToHome(false)
                setIsFreePickUp(true)
                setIsExpressDelivery(false)
                break
            default:
                break
        }
    }

    /**
     * Function to render buy online label
     * @returns {JSX.Element} its return JSX elements
     */
    const renderBuyOnlineLabel = (): JSX.Element => {
        return <div className={`${componentClassName}__buyonlinelabel`}>{buyOnlineHeaderText} :</div>
    }

    /**
     * Function to return class name string for fulfillment method
     * @param {string} label label name
     * @param {string} tagType tag name
     * @returns {string} class name
     */
    const fulfillmentMethodClassName = (label: string, tagType: string): string => {
        const isSelectedClass = label === section ? selectedclass : ''
        const isDisabledClass = tagType === fulfillmentConstants.unavailable ? disabledClass : ''

        return `${componentClassName}__list-item ${isSelectedClass} ${isDisabledClass}`
    }

    /**
     * Function to render 3 Box UI for new fulfillment
     * @returns {JSX.Element} return fulfillment section
     */
    const renderFulfillmentMethodSection = (): JSX.Element => {
        return (
            <>
                <ul className={`${componentClassName}__list`} ref={tablistRef} role="tablist">
                    {boxInfo &&
                        boxInfo.map((box, i) => {
                            const { type, tagType, label = '', tagLabel, isShowPromoBanner, promoBannerLabel } = box
                            return (
                                <li
                                    key={i}
                                    className={fulfillmentMethodClassName(label, tagType)}
                                    role="presentation"
                                    onClick={() => onClickListItem(box as BoxType)}>
                                    <div
                                        className={`${componentClassName}__box`}
                                        onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
                                            handleKeyDown(event, i, label, tablistRef, handleClickOrEnter)
                                        }}
                                        aria-label={isShowPromoBanner ? `${promoBannerLabel} ${label}` : label}
                                        data-accessibility="true"
                                        role="tab"
                                        tabIndex={label === section ? 0 : -1}
                                        aria-selected={label === section ? true : false}>
                                        <div className={`${componentClassName}__box-info`}>
                                            {isShowPromoBanner && (
                                                <span className={`${componentClassName}__box-info--label`}>
                                                    {promoBannerLabel}
                                                </span>
                                            )}
                                            <Icon type={type} size="xxl" />
                                        </div>
                                        <div className={`${componentClassName}__box-icon`}>
                                            <span
                                                className={`${componentClassName}__box-icon--label  ${
                                                    label === section ? labelClass : ''
                                                }`}>
                                                {label}
                                            </span>
                                        </div>
                                        {checkIfAllVariantsSelected && (
                                            <div className={`${componentClassName}__box-tag`}>
                                                <Tag
                                                    type="availability-bullet"
                                                    subType={`${
                                                        tagLabel !== unavailableLabel
                                                            ? `availability-bullet--${tagType}`
                                                            : ''
                                                    } ${bopisUnavailableClass}`}>
                                                    {tagLabel}
                                                </Tag>
                                            </div>
                                        )}
                                    </div>
                                </li>
                            )
                        })}
                </ul>
            </>
        )
    }

    /**
     * Renders tooltip component text
     * @returns {JSX.Element | null} returns tooltip component text
     */
    const renderFreeShippingTooltip = (): JSX.Element => {
        const bodyContent = (
            <div data-testid="tooltip-ineligible-body" dangerouslySetInnerHTML={{ __html: fulfillmentTooltip }} />
        )
        return (
            <Tooltip
                visibility={true}
                setVisibility={setFreeShippingTooltipVisibility}
                iconID="ct-close"
                bodyText={bodyContent}
                coords={freeShippingTooltipIconRef.current as unknown as HTMLElement}
                isModalPresent={isInFlyout}
            />
        )
    }

    /**
     * This function renders the free shipping tooltip icon
     * @returns {JSX.Element} return renderFreeShippingTooltipIcon
     */
    const renderFreeShippingTooltipIcon = (): JSX.Element => {
        return (
            <button
                className={`${PREFIX}-switch-fulfillment__sth__info-icon`}
                type="button"
                ref={freeShippingTooltipIconRef}
                onClick={freeShippingTooltipHandler}
                aria-label={a11yTooltipIcon}>
                <Icon size="md" type="ct-information-details" />
            </button>
        )
    }

    /**
     * Function to set free shipping tooltip visibility.
     * @param {React.MouseEvent} event - event
     * @returns {void} - returns nothing
     */
    const freeShippingTooltipHandler = (event: React.MouseEvent): void => {
        event.stopPropagation()
        setFreeShippingTooltipVisibility(flag => !flag)
    }

    /**
     * Renders availability component
     * @returns {JSX.Element} returns availability component
     */
    const rendersAvailabilityLabel = (): JSX.Element | null => {
        return inStockInSelectedStore || inStockAtDC ? (
            <Tag type="availability-bullet" subType={`availability-bullet--in-stock`}>
                {inStockLabel}
            </Tag>
        ) : null
    }

    /**
     * Returns  STH Free shipping label
     * @returns {JSX.Element} returns  STH Free shipping label
     */
    const getSTHFreeShippingLabel = (): JSX.Element => {
        const shippingThreshold = getShippingThreshold(
            isTriangleSelectUser,
            triangleShippingThreshold,
            regularShippingThreshold,
        )

        return (
            <span className={`${componentClassName}__ship-home-container--label`}>
                {replaceStrWithDynamicVal(freeShippingOverLabel, shippingThreshold)}
            </span>
        )
    }

    /**
     * Function to get the OOS Title based on corporate status
     * @returns {string | undefined} - returns OOS Title
     */
    const getOOSText = (): string | undefined => {
        return isProductFullyDiscontinued ? fullyDiscontinuedTitle : outOfStockTitle
    }

    /**
     * Function to get the OOS Title
     * @returns {string | undefined} - returns OOS Title
     */
    const getOOSTitle = (): string | undefined => {
        return !isVariantsHidden ? getOOSText() : hidingAllFirstVariantOOSMessage
    }

    /**
     * Function to render OOS message
     * @returns {JSX.Element | null} renderOOSAtAllStores
     */
    const renderOutOfStockMsg = (): JSX.Element | null => {
        return !isVariantsHidden ? (
            <span className={`${componentClassName}__fulfillment-text`}>{outOfStockText}</span>
        ) : null
    }

    /**
     * Function to render OOS everywhere component
     * @returns {JSX.Element} renderOOSAtAllStores
     */
    const renderOOSAtAllStores = (): JSX.Element => {
        return (
            <div className={`${componentClassName}__fulfillment`}>
                <span
                    className={`${componentClassName}__fulfillment-title`}
                    dangerouslySetInnerHTML={{
                        __html: getOOSTitle() as string,
                    }}
                />
                {renderOutOfStockMsg()}
            </div>
        )
    }

    /**
     * Renders out of stock or in store purchase component based on sellable or isBopis flag or OOS
     * @returns {JSX.Element} returns inStorePurchaseOrOOS message.
     */
    const renderInStorePurchaseOrOOS = (): JSX.Element => {
        if (isOutOfStockInAllStore || isProductLevel) {
            return renderOOSAtAllStores()
        } else {
            return (
                <>
                    <div className={`${sellableLabelClassName}`}>
                        {(!isSellableProduct || isBopisOnlySkuWithStoreBopisUnavailable) && selectedStoreQty > 0 ? (
                            <div className={`${componentClassName}__fulfillment__in-store-available`}>
                                <span className={`${sellableLabelClassName}__title`}>{inStorePurchase}</span>
                            </div>
                        ) : (
                            <div className={`${componentClassName}__fulfillment__check-store-availablility`}>
                                <div className={`${sellableLabelClassName}__title`}>{inStorePurchaseOnly}</div>
                                <div className={`${sellableLabelClassName}__description`}>{checkStoreAvailability}</div>
                            </div>
                        )}
                    </div>
                </>
            )
        }
    }

    /**
     * Renders store doesn't accept online orders based onlineOrdering flag
     * @returns {JSX.Element} returns online orders message.
     */
    const renderNoOnlineOrder = (): JSX.Element => {
        return (
            <div className={`${noOnlineOrderClass}`}>
                <div className={`${PREFIX}-warning-toast__text-container`}>
                    <span className={`${PREFIX}-warning-toast__icon`}>
                        <Icon type="ct-warning-orange" size="lg" />
                    </span>
                    <div>
                        <p className={`${PREFIX}-warning-toast__label`}>{onlineOrdersNotAcceptedBuyBoxMsg}</p>
                    </div>
                </div>
            </div>
        )
    }

    /**
     * Function to render fulfillment message
     * @returns {JSX.Element} renderFulfillmentMessage
     */
    const renderFulfillmentUnAvailableComp = (): JSX.Element => {
        return renderOOSAtAllStores()
    }

    const isEstimatedDeliveryDateAvailable = shouldFetchEstimateDeliveryDate(
        isOnlineOnlyCompared,
        Boolean(isProductBulk),
        Boolean(repairOrderRequiredCompared),
        Boolean(homeService),
        Boolean(inStockInSelectedStore),
        selectedStoreQty,
        estimatedDeliveryDateEnabled,
    )

    /**
     * function to get estimate delivery date
     */
    const fetchEstimateDeliveryDateData = useCallback(() => {
        const payload = {
            sku: libUtils.getStringOrDefault(sku),
            postalCode: libUtils.getStringOrDefault(postalCodeVal),
            selectedStoreId: selectedPreferredStoreId,
            quantity: qtySelectorInput,
        }
        dispatch(getEstimateDeliveryDate(payload))
    }, [dispatch, sku, selectedPreferredStoreId, qtySelectorInput, postalCodeVal])

    /**
     * To reset the runEstimateDeliveryDateApiOnce.current to zero, when user updates the store, sku options or quantity.
     */
    useEffect(() => {
        if (sku && postalCodeVal && runEstimateDeliveryDateApiOnce.current === 1) {
            runEstimateDeliveryDateApiOnce.current = 0
        }
    }, [postalCodeVal, sku, selectedPreferredStoreId, qtySelectorInput])

    // useEffect to trigger fetchEstimateDeliveryDateData
    useEffect(() => {
        if (
            areAllParamsValid(
                isDeliveryOptionsApiDone,
                postalCodeRegex.test(libUtils.getStringOrDefault(postalCodeVal)),
                isShipToHome,
                runEstimateDeliveryDateApiOnce.current === 0,
                estimatedDeliveryDateEnabled,
                isEstimatedDeliveryDateAvailable,
                !isProductNotEligibleForSTH,
            )
        ) {
            fetchEstimateDeliveryDateData()
            runEstimateDeliveryDateApiOnce.current = 1
        }
    }, [
        dispatch,
        fetchEstimateDeliveryDateData,
        estimatedDeliveryDateEnabled,
        postalCodeVal,
        isShipToHome,
        isEstimatedDeliveryDateAvailable,
        isProductNotEligibleForSTH,
        isDeliveryOptionsApiDone,
    ])

    // useEffect to set EstimatedDeliveryDateStart and EstimatedDeliveryDateEnd
    useEffect(() => {
        if (checkEstimateDeliveryDateAvailability(estimateDeliveryDateSuccess, estimateDeliveryDateData)) {
            setEstimatedDeliveryDateStart(libUtils.getStringOrDefault(estimateDeliveryDateData?.startDate))
            setEstimatedDeliveryDateEnd(libUtils.getStringOrDefault(estimateDeliveryDateData?.endDate))
        }
    }, [estimateDeliveryDateSuccess, estimateDeliveryDateFailure, estimateDeliveryDateData])

    const sthEstimateLabelClass = `${PREFIX}-buy-online__sth-estimate-shipping-label`
    const sthSplitClass = `${PREFIX}-buy-online__split-qty`
    const bopisEstimateLabelClass = `${componentClassName}__free-pickup-container__availability-label`
    const bopisDateTimeClass = `${componentClassName}__free-pickup-container__date-time`

    /**
     * Renders the STH and BOPIS estimate label.
     * @param {string} estimateLabel - The label for the estimate.
     * @param {string} estimateDate - The estimated date.
     * @param {boolean} isETADateAvailable - checks if ETA Date Available.
     * @param {number} qty - quantity to display for split ETA.
     * @returns {JSX.Element | null} The JSX element representing the estimate label or null if ETA Date unavailable.
     */
    const renderEstimateLabel = (
        estimateLabel: string,
        estimateDate: string,
        isETADateAvailable: boolean,
        qty?: number,
    ): JSX.Element | null => {
        return isETADateAvailable ? (
            <p
                className={
                    isFreePickUpSection
                        ? `${bopisEstimateLabelClass} ${
                              // eslint-disable-next-line sonar/no-nested-conditional
                              isHeliumInflationSelected ? `${bopisEstimateLabelClass}--hidden` : emptySpace
                          }`
                        : `${sthEstimateLabelClass}`
                }>
                {isSplitEta && qty && <span>{`${qty} `}</span>}
                {`${estimateLabel} `}
                <span className={isFreePickUpSection ? `${bopisDateTimeClass}` : ``}>{estimateDate}</span>
            </p>
        ) : null
    }

    /**
     * Renders the estimated delivery date label.
     * @returns {JSX.Element | null} The JSX element representing the rendered estimated delivery date label or null.
     */
    const rendersEstimatedDeliveryDateLabel = (): JSX.Element | null => {
        return estimateDeliveryDateSuccess ? (
            // eslint-disable-next-line sonar/no-nested-conditional
            isSplitEta ? (
                renderEstimateLabel(deliveryLabel, estimatedDeliveryDate, isEstimatedDateAvailable, selectedStoreQty)
            ) : (
                renderEstimateLabel(deliveryLabel, estimatedDeliveryDate, isEstimatedDateAvailable)
            )
        ) : (
            <SkeletonComponent
                skeletonClass={`${componentClassName}__ship-to-home-container__estimated-delivery-date__skeleton`}
            />
        )
    }

    /**
     * function to render estimate delivery date store value for split case
     * @returns {JSX.Element | null} render estimate delivery date / time section
     */
    const renderSplitETAStoreValue = (): JSX.Element | null => {
        if (isFreePickUpSection) {
            return renderEstimateLabel(
                availableByLabel as string,
                friendlyStorePickupDate,
                friendlyStorePickupDate as unknown as boolean,
                selectedStoreQty,
            )
        }
        return postalCodeVal && isEstimatedDeliveryDateAvailable
            ? rendersEstimatedDeliveryDateLabel()
            : renderEstimateLabel(shipsByLabel, friendlySTHDate, etaEarliest as unknown as boolean, selectedStoreQty)
    }

    /**
     * function to render estimate delivery date corporate value for split case
     * @returns {JSX.Element | null} render estimate delivery date / time section
     */
    const renderSplitETACorporateValue = (): JSX.Element | null => {
        const corporateQty = (qtySelectorInput as number) - selectedStoreQty
        if (isFreePickUpSection) {
            return renderEstimateLabel(
                pickUpBetweenLabel as string,
                friendlyDcBopisDate,
                friendlyStorePickupDate as unknown as boolean,
                corporateQty,
            )
        }
        return renderEstimateLabel(shipsBetween, friendlyDcSthDate, isSTHEtaAvailable, corporateQty)
    }

    /**
     * function to render estimate delivery date section for split case
     * @returns {JSX.Element} render estimate delivery date and qty section
     */
    const renderSplitEstimateLabel = (): JSX.Element => {
        return (
            <ul className={`${sthSplitClass}`}>
                <li className={`${sthSplitClass}-store`}>{renderSplitETAStoreValue()}</li>
                <li className={`${sthSplitClass}-corporate`}>{renderSplitETACorporateValue()}</li>
            </ul>
        )
    }

    /**
     * Renders the check other store btn and store flyout section.
     * @returns {JSX.Element} The JSX element representing the check other store btn and store flyout section.
     */
    const renderCheckOtherStoreAndStoreFlyOutComp = (): JSX.Element => {
        return (
            <>
                <Button
                    type="tertiary"
                    modifierClass={
                        isShipToHome
                            ? `${componentClassName}__ship-to-home-container__check-other-stores`
                            : `${componentClassName}__free-pickup-container--other-stores`
                    }
                    id="pick-up-check-other-store"
                    onClick={checkNearbyStoreCTAHandler}>
                    {checkOtherStoresLabel}
                </Button>
                {shouldStoreFlyoutOpen && rendersStoreFlyoutComp ? rendersStoreFlyoutComp() : null}
            </>
        )
    }

    /**
     * useEffect to set postal code value in case of saved postal code in userProfile address.
     */
    useEffect(() => {
        let currentPostalCode = appCacheService.postalCode.get()
        if (!currentPostalCode && isLoggedIn && userProfileData?.addresses?.postalCode) {
            currentPostalCode = userProfileData?.addresses?.postalCode
        }
        setPostalCodeVal(currentPostalCode)
        appCacheService.postalCode.set(currentPostalCode)
    }, [isLoggedIn, userProfileData, setPostalCodeVal, preferredStoreDetails])

    // on click handler for estimate shipping fees update btn
    const estimateFeesUpdateBtnOnclickHandler = () => {
        setEstimateFeesModal()
        setShowEstimatedFeesEFModal(false)
    }

    const estimateShippingFeeTooltipHeaderText = getFormattedEstimatedFee(
        isShipToHome,
        estimateStandardShippingFeesTooltipHeaderText as string,
        delFeesToolTipHeaderText as string,
    )

    const estimateShippingFeeTooltipBodyText = getFormattedEstimatedFee(
        isShipToHome,
        estimateStandardShippingFeesTooltipBodyText as string,
        delFeesToolTipBodyText as string,
    )

    /**
     * Renders estimate fees component
     * @returns {JSX.Element} returns store estimate fees component text
     */
    const renderEstimateFeesComp = (): JSX.Element => {
        return postalCodeVal ? (
            <>
                <div className={`${componentClassName}__estimate-shipping-fee`}>
                    {formattedEstimatedFee ? (
                        <div className={`${componentClassName}__estimate-shipping-fee__price-text`}>
                            <>
                                {deliveryFeeLabel} <span>{formattedEstimatedFee}</span>
                            </>
                            <Button
                                modifierClass={`${componentClassName}__estimate-shipping-fee__tooltip`}
                                type="tertiary"
                                ref={estimateShippingFeeTooltipIconRef}
                                onClick={changeEstimateShippingFeeTooltipVisibility}
                                aria-label={a11yEstimateFeesTooltipIcon}
                                data-testid="estimate-shipping-fee-tooltip">
                                <Icon size="md" type="ct-information-details" />
                            </Button>
                            {estimateShippingFeeTooltipVisibility && (
                                <Tooltip
                                    visibility={estimateShippingFeeTooltipVisibility}
                                    setVisibility={setEstimateShippingFeeTooltipVisibility}
                                    iconID="ct-close"
                                    headerText={estimateShippingFeeTooltipHeaderText}
                                    bodyText={
                                        <div
                                            data-testid="tooltip-text"
                                            dangerouslySetInnerHTML={{
                                                __html: estimateShippingFeeTooltipBodyText || '',
                                            }}></div>
                                    }
                                    coords={estimateShippingFeeTooltipIconRef.current}
                                    tooltipModifiedStyles={`${PREFIX}-tooltip--express-styled`}
                                    a11yCloseIconLabel={closeIconLabel}
                                    isModalPresent={isInFlyout}
                                />
                            )}
                        </div>
                    ) : (
                        <SkeletonComponent skeletonClass={`${componentClassName}__estimate-shipping-fee__skeleton`} />
                    )}
                </div>
                <div className={`${componentClassName}__postal-code__info`}>
                    <p>
                        {deliverToLabel}
                        <span className={`${componentClassName}__postal-code__value`}>
                            {!showEstimateFeesModal && postalCodeVal}
                        </span>
                    </p>
                    <Button
                        modifierClass={`${componentClassName}__postal-code__update-link-btn ${componentClassName}__postal-code__update-link`}
                        type="tertiary"
                        data-qm-allow="true"
                        data-testid="change-link"
                        onClick={estimateFeesUpdateBtnOnclickHandler}
                        ref={updateButtonRef}>
                        {changeLabel}
                    </Button>
                </div>
            </>
        ) : (
            <>
                {(!showEstimatedFees || isPostalCode) && (
                    <Button
                        modifierClass={`${componentClassName}__estimate-fees-link-btn ${componentClassName}__estimate-fees-link`}
                        type="tertiary"
                        data-qm-allow="true"
                        data-testid="estimate-shipping-fees-link"
                        onClick={estimateFeesUpdateBtnOnclickHandler}>
                        {postalCodeForEligFeesLabel}
                    </Button>
                )}
            </>
        )
    }

    /**
     * function to set state to show and hide need more information section on click of need more
     * @returns {void}
     */
    const toggleNeedMoreSection = (): void => {
        isFreePickUpSection
            ? setShowNeedMoreDescriptionBopis(!showNeedMoreDescriptionBopis)
            : setShowNeedMoreDescriptionSTH(!showNeedMoreDescriptionSTH)
    }

    /**
     * function to return boolean for need more description visibility based on bopis and STH
     * @returns {boolean}
     */
    const showNeedMoreDescription = (): boolean => {
        if (
            showNeedMore(
                orderable,
                selectedStoreQty,
                qtySelectorInput as number,
                isProductDiscontinued,
                freeShippingEnabled,
                selectedProductDCQuantity as number,
                isStaggeredFitmentProduct,
            )
        ) {
            return isFreePickUpSection ? showNeedMoreDescriptionBopis : showNeedMoreDescriptionSTH
        } else return false
    }

    /**
     * function to render need more button
     * @returns {JSX.Element} render need more label
     */
    const showNeedMoreBtn = (): JSX.Element => {
        return (
            <div className={`${componentClassName}__free-pickup-container__need-more`}>
                <Button
                    type="tertiary"
                    modifierClass={`${componentClassName}__free-pickup-container__need-more-label`}
                    onClick={toggleNeedMoreSection}
                    ariaExpanded={showNeedMoreDescription()}>
                    {needMoreLabel}
                    {showNeedMoreDescription() ? (
                        <Icon type="ct-chevron-up" size="sm" />
                    ) : (
                        <Icon type="ct-chevron-down" size="sm" />
                    )}
                </Button>
            </div>
        )
    }

    /**
     * function to render store details tool tip icon btn
     * @returns {JSX.Element} returns tool tip icon btn
     */
    const renderStoreDetailsTooltipBtn = (): JSX.Element => {
        return (
            <>
                <Button
                    modifierClass={`${componentClassName}__store-details-tooltip--icon`}
                    data-qm-allow="true"
                    type="tertiary"
                    ref={buttonRef}
                    onClick={() => {
                        setTooltipVisibility(!tooltipvisibility)
                    }}
                    aria-describedby={`${componentClassName}__store-details-tooltip--id`}>
                    <Icon size="md" type="ct-information-details"></Icon>
                </Button>
                {rendersTooltipComp(
                    tooltipvisibility,
                    setTooltipVisibility,
                    preferredStoreDetails?.displayName,
                    <StoreTooltip
                        storeData={preferredStoreDetails}
                        openLabel={openLabel}
                        opensLabel={openingAtLabel}
                        closesLabel={closesAtLabel}
                        closeLabel={closeLabel}
                        storeDetailsCTALabel={storeDetailsCTALabel}
                        onlineOrdering={preferredStoreDetails.onlineOrdering}
                        isPDPPage={isPDPPage}
                    />,
                    closeIconLabel,
                    buttonRef,
                    isInFlyout,
                )}
            </>
        )
    }

    /** function to render estimate delivery date section for STH non split case
     * @returns {JSX.Element} render estimate delivery date and qty section for STH
     */
    const renderEstimateDateSthWithoutSplit = (): JSX.Element => {
        return (
            <>
                {renderSTHEstimateLabel(
                    selectedStoreQty,
                    orderable,
                    isOutOfStockInAllStore,
                    renderEstimateLabel(shipsBetween, friendlyDcSthDate, isSTHEtaAvailable),
                    postalCodeVal,
                    isEstimatedDeliveryDateAvailable,
                    rendersEstimatedDeliveryDateLabel(),
                    renderEstimateLabel(shipsByLabel, friendlySTHDate, etaEarliest as unknown as boolean),
                )}

                {selectedStoreQty > 0 && (
                    <div className={`${componentClassName}__ship-to-home-container--tag`}>
                        <Tag type="availability-bullet" subType={`availability-bullet--in-stock`}>
                            {`${selectedStoreQty} ${inStockLabel}`}
                        </Tag>
                        {showNeedMore(
                            orderable,
                            selectedStoreQty,
                            qtySelectorInput as number,
                            isProductDiscontinued,
                            freeShippingEnabled,
                            selectedProductDCQuantity as number,
                            isStaggeredFitmentProduct,
                        ) && showNeedMoreBtn()}
                    </div>
                )}
            </>
        )
    }

    /** function to render estimate delivery date section for non split case for both bopis and sth
     * @returns {JSX.Element | null} render estimate delivery date and qty section
     */
    const renderEstimateDateWithoutSplit = (): JSX.Element | null => {
        return isFreePickUpSection
            ? renderEstimateLabel(
                  availableByLabel as string,
                  friendlyStorePickupDate,
                  friendlyStorePickupDate as unknown as boolean,
              )
            : renderEstimateDateSthWithoutSplit()
    }

    /**
     * Function to render staggered sku availability
     * @param {FulfillmentDTOP} fulfillmentObj - fulfillment object
     * @param {number} inputQty - input quantity
     * @returns {JSX.Element} returns staggered availability
     */
    const renderStaggeredAvailability = (fulfillmentObj: FulfillmentDTOP, inputQty: number): JSX.Element => {
        return (
            <li>
                <span className={`${componentClassName}__free-pickup-container__qty`}>{inputQty}</span>{' '}
                {availableByLabel}
                <span className={`${componentClassName}__free-pickup-container__date-time`}>
                    {' '}
                    {getFormattedTimeDate(fulfillmentObj.storePickUp.etaEarliest, getBOPISDateFormat())}
                </span>
            </li>
        )
    }

    /**
     * Function to render staggered sku store availability
     * @returns {JSX.Element} returns staggered availability
     */
    const renderStaggeredSkuStoreAvailability = (): JSX.Element => {
        return (
            <div>
                {availableByLabel}
                <span className={`${componentClassName}__free-pickup-container__date-time`}>
                    {' '}
                    {friendlyStorePickupDate}
                </span>
            </div>
        )
    }

    /**
     * Function to render staggered sku DC availability
     * @returns {JSX.Element} returns staggered availability
     */
    const renderStaggeredSkuDCAvailability = (): JSX.Element => {
        const dcMinETA = isShipToHome ? sthETA?.MinETA : bopisETA?.MinETA
        const dcMaxETA = isShipToHome ? sthETA?.MaxETA : bopisETA?.MaxETA
        return (
            <div>
                {pickUpBetweenLabel}
                <span className={`${componentClassName}__free-pickup-container__date-time`}>
                    {' '}
                    <span>{`${getFormattedDateTime(dcMinETA as string)[0]} - ${
                        getFormattedDateTime(dcMaxETA as string)[0]
                    }`}</span>
                </span>
            </div>
        )
    }

    /**
     * Function to render staggered sku availability
     * @param {FulfillmentDTOP} frontFulfillment - fulfillment object
     * @param {FulfillmentDTOP} rearFulfillment - fulfillment object
     * @param {boolean} isFrontFulfillmentOrderable - input quantity
     * @param {boolean} isRearFulfillmentOrderable - input quantity
     * @returns {JSX.Element} returns staggered availability
     */
    const renderStoreAndDCAvailability = (
        frontFulfillment: FulfillmentDTOP,
        rearFulfillment: FulfillmentDTOP,
        isFrontFulfillmentOrderable: boolean,
        isRearFulfillmentOrderable: boolean,
    ): JSX.Element => {
        return (
            <>
                <span className={`${componentClassName}__free-pickup-container__auto-label`}>
                    {attributeFrontLabel}
                </span>
                <ul className={`${componentClassName}__free-pickup-container__eta-list`}>
                    {frontFulfillment.availability?.quantity > 0 &&
                        (libUtils.getNumberOrDefault(qtySelectorInputFront) > frontFulfillment.availability?.quantity
                            ? renderStaggeredAvailability(frontFulfillment, frontFulfillment.availability?.quantity)
                            : renderStaggeredAvailability(
                                  frontFulfillment,
                                  libUtils.getNumberOrDefault(qtySelectorInputFront),
                              ))}
                    {renderStaggeredDCAvailability(
                        libUtils.getNumberOrDefault(qtySelectorInputFront),
                        frontFulfillment,
                    )}
                </ul>
                {isOOSAtStoreAndDC(frontFulfillment, isFrontFulfillmentOrderable) && renderOOSWarningComp()}
                {isOOSAtStoreAndDC(frontFulfillment, isFrontFulfillmentOrderable) && nearByStoresList}
                <span className={`${componentClassName}__free-pickup-container__auto-label`}>{attributeRearLabel}</span>
                <ul className={`${componentClassName}__free-pickup-container__eta-list`}>
                    {rearFulfillment.availability?.quantity > 0 &&
                        (rearFulfillment.availability?.quantity &&
                        libUtils.getNumberOrDefault(qtySelectorInputRear) > rearFulfillment.availability?.quantity
                            ? renderStaggeredAvailability(rearFulfillment, rearFulfillment.availability?.quantity)
                            : renderStaggeredAvailability(
                                  rearFulfillment,
                                  libUtils.getNumberOrDefault(qtySelectorInputRear),
                              ))}
                    {renderStaggeredDCAvailability(libUtils.getNumberOrDefault(qtySelectorInputRear), rearFulfillment)}
                </ul>
                {isOOSAtStoreAndDC(rearFulfillment, isRearFulfillmentOrderable) && renderOOSWarningComp()}
                {isOOSAtStoreAndDC(rearFulfillment, isRearFulfillmentOrderable) && nearByStoresList}
            </>
        )
    }

    /**
     * Function to check if skus are available only at DC
     * @param {FulfillmentDTOP} frontFulfillment - fulfillment object
     * @param {FulfillmentDTOP} rearFulfillment - fulfillment object
     * @param {number} qtySelectorFront - front quantity
     * @param {number} qtySelectortRear - rear quantity
     * @returns {boolean} returns boolean
     */
    const isOnlyAvailableAtDC = (
        frontFulfillment: FulfillmentDTOP,
        rearFulfillment: FulfillmentDTOP,
        qtySelectorFront: number,
        qtySelectortRear: number,
    ): boolean => {
        return (
            frontFulfillment?.availability?.quantity === 0 &&
            rearFulfillment?.availability?.quantity === 0 &&
            (frontFulfillment?.availability?.Corporate?.Quantity as number) > qtySelectorFront &&
            (rearFulfillment?.availability?.Corporate?.Quantity as number) > qtySelectortRear
        )
    }

    /**
     * Function to render staggered automotive availability
     * @returns {JSX.Element} render staggered automotive availability
     */
    const autoStaggeredAvailability = (): JSX.Element => {
        let frontFulfillment = {} as FulfillmentDTOP
        let rearFulfillment = {} as FulfillmentDTOP
        let isFrontFulfillmentOrderable = false
        let isRearFulfillmentOrderable = false

        skus?.forEach((skuObj: ProductSku) => {
            if (skuObj.isFrontTire) {
                frontFulfillment = skuObj.fulfillment
                isFrontFulfillmentOrderable = skuObj.orderable
            } else {
                rearFulfillment = skuObj.fulfillment
                isRearFulfillmentOrderable = skuObj.orderable
            }
        })

        return (
            <div className={`${componentClassName}__free-pickup-container__availability`}>
                {isAvailableAtStore(
                    frontFulfillment,
                    rearFulfillment,
                    qtySelectorInputFront as number,
                    qtySelectorInputRear as number,
                )
                    ? renderStaggeredSkuStoreAvailability()
                    : // eslint-disable-next-line sonar/no-nested-conditional
                    isOnlyAvailableAtDC(
                          frontFulfillment,
                          rearFulfillment,
                          qtySelectorInputFront as number,
                          qtySelectorInputRear as number,
                      )
                    ? renderStaggeredSkuDCAvailability()
                    : renderStoreAndDCAvailability(
                          frontFulfillment,
                          rearFulfillment,
                          isFrontFulfillmentOrderable,
                          isRearFulfillmentOrderable,
                      )}
            </div>
        )
    }

    /**
     * Function to render staggered DC availability
     * @param {number} selectorInput - selector input
     * @param {FulfillmentDTOP} fulfilment - fulfillment object
     * @returns {JSX.Element | null} returns staggered DC availability
     */
    const renderStaggeredDCAvailability = (
        selectorInput: number,
        fulfilment: FulfillmentDTOP,
    ): JSX.Element | null | boolean => {
        const availabilityData: ItemAvailability = fulfilment.availability
        const dcMinETA = isShipToHome ? sthETA?.MinETA : bopisETA?.MinETA
        const dcMaxETA = isShipToHome ? sthETA?.MaxETA : bopisETA?.MaxETA
        return availabilityData?.Corporate?.Quantity
            ? selectorInput > availabilityData.quantity && (
                  <li>
                      <span className={`${componentClassName}__free-pickup-container__qty`}>
                          {selectorInput - availabilityData.quantity}
                      </span>{' '}
                      {pickUpBetweenLabel}
                      {
                          <span className={`${componentClassName}__free-pickup-container__date-time`}>
                              {' '}
                              {`${getFormattedDateTime(dcMinETA as string)[0]} - ${
                                  getFormattedDateTime(dcMaxETA as string)[0]
                              }`}
                          </span>
                      }
                  </li>
              )
            : null
    }

    /**
     * function to render estimate delivery date section
     * @returns {JSX.Element | null } render estimate delivery date and qty section
     */
    const renderEstimateDateInfo = (): JSX.Element | null => {
        if (isStaggeredFitmentProduct) {
            return autoStaggeredAvailability()
        } else {
            return isSplitEta ? renderSplitEstimateLabel() : renderEstimateDateWithoutSplit()
        }
    }

    /**
     * Renders Heavy Oversize Tooltip component
     * @returns {JSX.Element} returns Heavy Oversize tooltip component
     */
    const rendersHeavyOversizeTooltipComp = (): JSX.Element => {
        return (
            <Tooltip
                visibility={heavyOversizeTooltipVisibility}
                setVisibility={setHeavyOversizeTooltipVisibility}
                iconID="ct-close"
                headerText={heavyOversizeHeader}
                bodyText={<div dangerouslySetInnerHTML={{ __html: heavyOversizeBody || '' }}></div>}
                coords={heavyOversizeButtonRef.current as unknown as HTMLElement}
                tooltipModifiedStyles={`${PREFIX}-tooltip__bulk-item`}
                a11yCloseIconLabel={closeIconLabel}
                isModalPresent={isInFlyout}
            />
        )
    }

    /**
     * Renders Heavy Oversize Tooltip Icon
     * @returns {JSX.Element} returns Renders Heavy Oversize Tooltip Icon (button)
     */
    const rendersHeavyOversizeTooltipIcon = (): JSX.Element => {
        return (
            <Button
                modifierClass={`${PREFIX}-buy-online__heavy-oversize__info-icon`}
                type="tertiary"
                data-qm-allow="true"
                ref={heavyOversizeButtonRef}
                onClick={changeHandlingHeavyOversizeTooltipVisibility}
                ariaLabel={informationLabel}>
                <Icon size="md" type="ct-information-details"></Icon>
            </Button>
        )
    }

    /**
     * Renders Heavy Oversize component
     * @returns {JSX.Element} returns Heavy Oversize component
     */
    const rendersHeavyOversizeComp = (): JSX.Element => {
        return (
            <div className={`${PREFIX}-buy-online__heavy-oversize`}>
                <span className={`${PREFIX}-buy-online__heavy-oversize__text`}>{heavyOversizeItemText}</span>
                {rendersHeavyOversizeTooltipIcon()}
                {heavyOversizeTooltipVisibility && rendersHeavyOversizeTooltipComp()}
            </div>
        )
    }

    /**
     * Function to render ship to home component
     * @returns {JSX.Element} render ship to home
     */
    const renderShipToHome = (): JSX.Element => {
        return (
            <div className={`${componentClassName}__ship-home-container`}>
                {
                    // eslint-disable-next-line sonar/expression-complexity
                    productNotForSth ? (
                        <div className={`${componentClassName}__ship-home-container--not-for-ship-to-home`}>
                            {shipToHomeUnavailable}
                        </div>
                    ) : // eslint-disable-next-line sonar/no-nested-conditional
                    isShippingPostalCodeHide ? (
                        <>
                            <div className={`${componentClassName}__ship-home-container--shipping-label`}>
                                <div>
                                    {getSTHFreeShippingLabel()}
                                    {renderFreeShippingTooltipIcon()}
                                    {freeShippingTooltipVisibility && renderFreeShippingTooltip()}
                                </div>
                                {rendersAvailabilityLabel()}
                            </div>
                            <div className={`${componentClassName}__ship-home-container--ships-in-label`}>
                                <span>{shipsInLabel}</span>
                                <span className={`${componentClassName}__ship-home-container--business-days`}>
                                    {shippingEstimation}
                                </span>
                            </div>
                            <div className={`${componentClassName}__ship-home-container--shipping-fee-label`}>
                                {shippingFeeCalculation}
                            </div>
                        </>
                    ) : (
                        <>
                            <div className={`${componentClassName}__ship-to-home-container__shipping-label`}>
                                {isOutOfStockAtStoreAndDC ? renderOOSWarningComp() : renderEstimateDateInfo()}
                            </div>
                            {showNeedMoreDescription() && (
                                <div className={`${componentClassName}__free-pickup-container__need-more-description`}>
                                    {needMoreItemsDescLabel}
                                </div>
                            )}
                            {!isOutOfStockAtStoreAndDC && renderEstimateFeesComp()}
                            {isProductBulk && rendersHeavyOversizeComp()}
                            {renderStoreWarningMessage()}
                            {showNearByStoreslist && (
                                <div className={`${componentClassName}__ship-to-home-container__need-faster-shipping`}>
                                    {nearByStoresList}
                                    {renderCheckOtherStoreAndStoreFlyOutComp()}
                                </div>
                            )}
                        </>
                    )
                }
            </div>
        )
    }

    /**
     * Function to render express delivery component
     * @returns {JSX.Element} return express delivery
     */
    const renderExpressDeliveryInfo = (): JSX.Element => {
        return (
            <>
                <div className={`${componentClassName}__express-delivery-container--express-label`}>
                    <div>
                        <span className={`${componentClassName}__express-delivery-container--store-label`}>
                            {renderExpressDeliveryAvailabilityDate(
                                skuExpressDeliveryFulfillmentData,
                                preferredStoreDetails,
                                getItTodayLabelff,
                                getItTomorrowLabelff,
                                getItByLabelff,
                            )}
                        </span>
                        {renderExpressDeliveryNotEligibleTooltipIcon()}
                        {notEligibleExpressDeliveryTooltipVisibility &&
                            renderExpressDeliveryNotEligibleTooltip('', sameDayDeliverySLETooltipBodyText as string)}
                    </div>
                    <Tag type="availability-bullet" subType={`availability-bullet--in-stock`}>
                        {selectedStoreQty} {inStockLabel}
                    </Tag>
                </div>
                <div className={`${componentClassName}__express-delivery-container--eligibility-fee`}>
                    {isShippingPostalCodeHide ? eligibilityAndShippingInfoLabel : renderEstimateFeesComp()}
                </div>
            </>
        )
    }

    /**
     * Function to set express delivery tooltip visibility.
     * @param {React.MouseEvent} event event
     * @returns {void} returns nothing
     */
    const expressDeliveryNotEligibleTooltip = (event: React.MouseEvent): void => {
        event.stopPropagation()
        setNotEligibleExpressDeliveryTooltipVisibility(flag => !flag)
    }

    /**
     * This function renders the express delivery tooltip
     * @returns {JSX.Element} renders express delivery not eligible tooltip
     */
    const renderExpressDeliveryNotEligibleTooltip = (header: string, bodyText: string): JSX.Element => {
        const bodyContent = (
            <div data-testid="tooltip-ineligible-body" dangerouslySetInnerHTML={{ __html: bodyText || '' }} />
        )
        return (
            <Tooltip
                visibility={notEligibleExpressDeliveryTooltipVisibility}
                setVisibility={setNotEligibleExpressDeliveryTooltipVisibility}
                iconID="ct-close"
                headerText={header}
                bodyText={bodyContent}
                coords={notEligibleExpressDeliveryTooltipIconRef.current as unknown as HTMLElement}
                isModalPresent={isInFlyout}
            />
        )
    }

    /**
     * This function renders the express delivery tooltip icon
     * @returns {JSX.Element} renders express delivery not eligible tooltip icon
     */
    const renderExpressDeliveryNotEligibleTooltipIcon = (): JSX.Element => {
        return (
            <Button
                modifierClass={`${componentClassName}__express-delivery-container--info-details`}
                ref={notEligibleExpressDeliveryTooltipIconRef}
                onClick={expressDeliveryNotEligibleTooltip}
                aria-label={a11yEstimateFeesTooltipIcon}
                data-testid="express-delivery-ineligible-tooltip">
                <Icon size="md" type="ct-information-details" />
            </Button>
        )
    }

    /**
     * function to render express delivery not available message
     * @returns {JSX.Element} return express delivery
     */
    const renderExpressDeliveryNotAvailable = (): JSX.Element => {
        return (
            <div className={`${componentClassName}__express-delivery-container--oos-label`}>
                <div>
                    {isProductEligibleForExpressDelivery(fulfillment, freeShippingEnabled) &&
                    isStoreEligibleForED(preferredStoreDetails, freeShippingEnabled)
                        ? getEDUnavailableLabel(
                              inStockInSelectedStore,
                              expressDeliveryUnavailableOOS,
                              isExpressDeliveryEligibleForPostalCode,
                              expressDeliveryUnavailable,
                              productContent.postalCodeNotEligibleExpressDeliveryLabel as string,
                          )
                        : expressDeliveryUnavailable}
                    {renderExpressDeliveryNotEligibleTooltipIcon()}
                    {notEligibleExpressDeliveryTooltipVisibility &&
                        renderExpressDeliveryNotEligibleTooltip(
                            productContent.notEligibleExpressDeliveryTooltipHeaderText as string,
                            productContent.notEligibleExpressDeliveryTooltipBodyText as string,
                        )}
                </div>
                {!isExpressDeliveryEligibleForPostalCode && (
                    <Button
                        modifierClass={`${componentClassName}__estimate-fees-link-btn ${componentClassName}__estimate-fees-link`}
                        type="tertiary"
                        data-qm-allow="true"
                        data-testid="estimate-shipping-fees-link"
                        onClick={setEstimateFeesModal}>
                        {postalCodeForEligFeesLabel}
                    </Button>
                )}
            </div>
        )
    }

    /**
     * function to render express delivery component
     * @returns {JSX.Element} return express delivery
     */
    const renderProductEligibilityForED = (): JSX.Element => {
        if (isProductEligibleForExpressDelivery(fulfillment, freeShippingEnabled)) {
            return inStockInSelectedStore && isExpressDeliveryEligibleForPostalCode
                ? renderExpressDeliveryInfo()
                : renderExpressDeliveryNotAvailable()
        } else return renderExpressDeliveryNotAvailable()
    }

    /**
     * function to render express delivery component
     * @returns {JSX.Element} return express delivery
     */
    const renderExpressDelivery = (): JSX.Element => {
        return (
            <div className={`${componentClassName}__express-delivery-container`}>
                {areAllParamsValid(
                    preferredStoreDetails.isExpressDeliveryEligible,
                    // eslint-disable-next-line sonar/no-nested-conditional
                    freeShippingEnabled ? Boolean(preferredStoreDetails.bopisAvailable) : true,
                    checkIfAllVariantsSelected,
                    !isProductBulk,
                    !isExpressQuantityExceeded,
                )
                    ? renderProductEligibilityForED()
                    : renderExpressDeliveryNotAvailable()}
            </div>
        )
    }

    /**
     * Renders curbside pickup label
     * @returns {JSX.Element} returns curbside Label text
     */
    const renderCurbsidePickupLabel = (): JSX.Element | null => {
        return isEligibleForCurbside && onlineOrdering ? (
            <div className={`${componentClassName}__free-pickup-container__curbside-label`}>
                {curbsidePickupAvailableLabel}
            </div>
        ) : null
    }

    /**
     * This function checks the availability of displaying threshold
     * @returns {boolean} isDisplayThresholdAvailable
     */
    const isDisplayThresholdAvailable = (): boolean => {
        return !!threshold && inStorePickUp && !repairOrderRequired
    }

    /**
     * Function to change tooltip visibility
     * @param {React.MouseEvent<HTMLButtonElement>} event React click event
     * @returns {void} returns void
     */
    const changeHandlingFeeTooltipVisibility = (event: React.MouseEvent<HTMLButtonElement>): void => {
        event.stopPropagation()
        setThresholdTooltipVisibility(!thresholdTooltipVisibility)
    }

    /**
     * This function renders the pick up at store threshold tooltip
     * @returns {JSX.Element} renderThresholdTooltip
     */
    const renderThresholdTooltip = (): JSX.Element => {
        const formattedThreshold = getFormattedPrice(threshold, libUtils.getLanguage())
        const formattedHandlingFee = getFormattedPrice(libUtils.getNumberOrDefault(fee), libUtils.getLanguage())
        const tooltipHeaderText = replaceStrWithDynamicVal(
            libUtils.getStringOrDefault(thresholdTooltipHeaderText),
            formattedThreshold,
        )
        const tooltipConditionText = replaceStrWithDynamicVal(
            replaceStrWithDynamicVal(
                libUtils.getStringOrDefault(thresholdTooltipThresholdConditionText),
                formattedHandlingFee,
            ),
            formattedThreshold,
        )

        return (
            <Tooltip
                visibility={thresholdTooltipVisibility}
                setVisibility={setThresholdTooltipVisibility}
                iconID="ct-close"
                headerText={tooltipHeaderText}
                bodyText={
                    <>
                        <span>{tooltipConditionText}</span>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: libUtils.getStringOrDefault(thresholdTooltipDescription),
                            }}
                        />
                    </>
                }
                coords={thresholdTooltipIconRef.current}
                a11yCloseIconLabel={closeIconLabel}
                isModalPresent={isInFlyout}
            />
        )
    }

    /**
     * This function renders the pick up at store threshold tooltip icon
     * @returns {JSX.Element} renderThresholdTooltipIcon
     */
    const renderThresholdTooltipIcon = (): JSX.Element => {
        return (
            <Button
                modifierClass={`${componentClassName}__free-pickup-container--icon`}
                type="tertiary"
                data-qm-allow="true"
                ref={thresholdTooltipIconRef}
                onClick={changeHandlingFeeTooltipVisibility}
                aria-describedby={`${componentClassName}__free-pickup-container--id`}>
                <Icon size="md" type="ct-information-details"></Icon>
            </Button>
        )
    }

    /**
     * Renders bopis threshold label
     * @returns {JSX.Element} returns bopis threshold Label
     */
    const renderBopisThresholdLabel = (): JSX.Element | null => {
        const displayThreshold = isDisplayThresholdAvailable()
        if (displayThreshold) {
            const formattedThreshold = getFormattedPrice(threshold, libUtils.getLanguage())
            const titleText = replaceStrWithDynamicVal(
                libUtils.getStringOrDefault(freePickUpInStoreOverLabel),
                formattedThreshold,
            )

            return (
                <div className={`${componentClassName}__free-pickup-container__bopis-threshold`}>
                    {titleText}
                    {renderThresholdTooltipIcon()}
                    {thresholdTooltipVisibility && renderThresholdTooltip()}
                </div>
            )
        }
        return null
    }

    /**
     * Renders Urgency message text
     * @returns {string | undefined} returns Urgency message text
     */
    const getUrgencyMsg = (): string | undefined => {
        const checkIfToday = getFormattedDateWithComparison(storePickUpDate, storeTimezone as string)
        return checkIfToday.isToday ? urgencyMessageLabel : ''
    }

    /**
     * Function to render Bopis availability label
     * @returns {JSX.Element} returns availability info
     */
    const renderBopisAvailabilityLabel = (): JSX.Element => {
        return (
            <>
                {freeShippingEnabled ? (
                    <div className={`${componentClassName}__free-pickup-container--estimation`}>
                        {availableByLabel} <b>{timeLabelWhenPickUpAvailable}</b>
                    </div>
                ) : (
                    renderEstimateDateInfo()
                )}
            </>
        )
    }

    /**
     * Function to render Bopis availability info
     * @returns {JSX.Element} returns availability info
     */
    const renderBopisAvailabilityInfo = (): JSX.Element => {
        return (
            <>
                {areAllParamsValid(getUrgencyMsg() !== emptySpace, !isSplitEta, !isStaggeredFitmentProduct) ? (
                    <span
                        className={`${urgencyTextClassName} ${
                            isHeliumInflationSelected ? `${urgencyTextClassName}--hidden` : emptySpace
                        }`}>
                        {getUrgencyMsg()}
                    </span>
                ) : null}
                {renderBopisAvailabilityLabel()}
            </>
        )
    }

    /**
     * Function to render free pick available label component
     * @returns {JSX.Element} return free pick up available label
     */
    const freePickUpLabelContainer = (): JSX.Element => {
        return (
            <div
                className={`${componentClassName}__free-pickup-container--label ${freePickUpSplitLabelClass(
                    isSplitEta,
                )}`}>
                <span className={`${componentClassName}__free-pickup-container--estimation`}>
                    {pickUpAtStoreLabel} {preferredStoreDetails.displayName}
                    {renderStoreDetailsTooltipBtn()}
                </span>
                {!isSplitEta && !getCriticalFitmentProducts(criticalFitment).isStaggered && (
                    <div className={`${componentClassName}__free-pickup-container--tag`}>
                        <Tag type="availability-bullet" subType={`availability-bullet--in-stock`}>
                            {selectedStoreQty} {inStockLabel}
                        </Tag>
                        {showNeedMore(
                            orderable,
                            selectedStoreQty,
                            qtySelectorInput as number,
                            isProductDiscontinued,
                            freeShippingEnabled,
                            selectedProductDCQuantity as number,
                            isStaggeredFitmentProduct,
                        ) && showNeedMoreBtn()}
                    </div>
                )}
            </div>
        )
    }

    /**
     * Function to render free pick available component
     * @returns {JSX.Element} return free pick up available
     */

    const renderFreePickUpAvialable = useCallback((): JSX.Element => {
        const showNearByStores = areAllParamsValid(isNearByStoresAvailable, !isNearbyStoresListEmpty)
        return (
            <>
                {freePickUpLabelContainer()}
                {showNeedMoreDescription() && (
                    <div className={`${componentClassName}__free-pickup-container__need-more-description`}>
                        {needMoreItemsDescLabel}
                    </div>
                )}

                {renderBopisThresholdLabel()}
                {renderBopisAvailabilityInfo()}
                {showAutoServiceUnavailableMsg}
                {showNearByStores && !isStaggeredFitmentProduct && nearByStoresList}
            </>
        )
    }, [
        componentClassName,
        freePickUpLabelContainer,
        isNearByStoresAvailable,
        isNearbyStoresListEmpty,
        isStaggeredFitmentProduct,
        nearByStoresList,
        needMoreItemsDescLabel,
        renderBopisAvailabilityInfo,
        renderBopisThresholdLabel,
        showAutoServiceUnavailableMsg,
        showNeedMoreDescription,
    ])

    /**
     * Function to render DC availability info
     * @returns {JSX.Element} returns DC availability info
     */
    const renderDCAvailabilityInfo = (): JSX.Element => {
        return (
            <div className={`${componentClassName}__free-pickup-container__pickup-between`}>
                <div className={`${componentClassName}__free-pickup-container--pickup-label`}>
                    {pickUpAtStoreLabel} {preferredStoreDetails.displayName}
                    {renderStoreDetailsTooltipBtn()}
                </div>
                <div className={`${componentClassName}__free-pickup-container__pickup-between-label`}>
                    {pickUpBetweenLabel}{' '}
                    <span className={`${componentClassName}__free-pickup-container__date-time`}>
                        {friendlyDcBopisDate}
                    </span>
                </div>
            </div>
        )
    }

    /**
     * Function to render OOS warning
     * @returns {JSX.Element} render OOSWarning Component
     */
    const renderOOSWarningComp = (): JSX.Element => {
        return (
            <div className={`${PREFIX}-row`}>
                <div className={`${componentClassName}__display-out-of-stock-warning`}>
                    <div
                        className={`${PREFIX}-warning-toast ${PREFIX}-warning-toast__white`}
                        role="alert"
                        aria-atomic="true">
                        <div className={`${PREFIX}-warning-toast__text-container`}>
                            <span className={`${PREFIX}-warning-toast__icon`}>
                                <Icon type="ct-warning-orange" size="lg" />
                            </span>
                            <div>
                                <p className={`${PREFIX}-warning-toast__label`}>{outOfStockAtStoreLabel}</p>
                                {renderStoreDetailsTooltipBtn()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    /**
     * Function to render free pick out of stock component
     * @returns {JSX.Element} return free pick up out of stock
     */
    const renderFreePickUpOutOfStock = (): JSX.Element => {
        return (
            <>
                {
                    // eslint-disable-next-line sonar/expression-complexity
                    freeShippingEnabled ? (
                        <>
                            {renderOOSWarningComp()}
                            {renderStoreWarningMessage()}
                        </>
                    ) : (
                        <>
                            {!inStockInSelectedStore && !inStockAtDC ? <>{renderOOSWarningComp()}</> : null}
                            {renderStoreWarningMessage()}
                            {inStockAtDC && renderDCAvailabilityInfo()}
                            {showAutoServiceUnavailableMsg}
                        </>
                    )
                }

                {nearByStoresList}
            </>
        )
    }

    const outOfStockAtStoreLabel = replaceStrWithDynamicVal(outOfStockAtStoreff, preferredStoreDetails.displayName)
    /**
     * Store selection warning message component
     * @returns {JSX.Element | null} returns Store selection warning message component
     */
    const renderStoreWarningMessage = (): JSX.Element | null => {
        const isMessageNeeded = isNeededStoreWarningMessage(
            selectedStoreName,
            isAddToCartClicked,
            inStockInSelectedStore,
            !isNearbyStoresListEmpty,
            inStockAtDC,
            preferredStoreDetails.onlineOrdering,
        )

        return isMessageNeeded ? (
            <div className={`${PREFIX}-switch-fulfillment__store-label ${PREFIX}-switch-fulfillment__store-error`}>
                <Icon type="ct-warning" size="lg"></Icon>
                <div
                    className={`${PREFIX}-body-sm ${PREFIX}-switch-fulfillment__store-warning-text`}
                    role="alert"
                    aria-atomic="true">
                    {selectStoreLabel}
                </div>
            </div>
        ) : null
    }

    /**
     * Function to render bopis not available
     * @returns {JSX.Element} return bopis not available
     */
    const renderBopisNotAvailable = (): JSX.Element => {
        return (
            <div className={`${componentClassName}__bopisnotavailable`}>
                <div className={`${componentClassName}__bopisnotavailable--msg`}>
                    {freePickUpUnavailableAtSelectedStore}
                </div>
            </div>
        )
    }

    /**
     * Function to render bopis not avaialble msg comp
     * @returns {JSX.Element} return bopis not available msg
     */
    const renderBopisNotAvailableMsg = (): JSX.Element => {
        return (
            <div className={`${componentClassName}__free-pickup-container__bopisnotavailable`}>
                {freePickUpUnavailable}
            </div>
        )
    }

    /**
     * Function to render free pick up component
     * @returns {JSX.Element} return free pick up comp
     */
    const renderFreePickUpComp = useCallback((): JSX.Element => {
        // eslint-disable-next-line sonar/expression-complexity
        return bopisUnavailable
            ? renderBopisNotAvailableMsg()
            : // eslint-disable-next-line sonar/no-nested-conditional
            selectedStoreQty || (isStaggeredFitmentProduct && !skus?.every(isSkuOutOfStock))
            ? renderFreePickUpAvialable()
            : renderFreePickUpOutOfStock()
    }, [
        bopisUnavailable,
        isStaggeredFitmentProduct,
        renderBopisNotAvailableMsg,
        renderFreePickUpAvialable,
        renderFreePickUpOutOfStock,
        selectedStoreQty,
        skus,
    ])

    /**
     * Function to render free pick up container
     * @returns {JSX.Element} return freePickUp Container
     */
    const renderFreePickUp = (): JSX.Element => {
        return (
            <div className={`${componentClassName}__free-pickup-container`}>
                {renderFreePickUpComp()}
                {renderCheckOtherStoreAndStoreFlyOutComp()}
                {!isCurbsideAndAvailableByLabels && renderCurbsidePickupLabel()}
            </div>
        )
    }

    /**
     * Function to open store selector modal and send analytics.
     * @returns {void} - returns nothing
     */
    const checkNearbyStoreCTAHandler = (): void => {
        sendRequestByClickNearByStores()
        closeFlyout()
        selectedStoreQty > 0 && isNearbyStoresListEmpty && setIsNearByStoresAvailable(false)
    }

    /**
     * Function to get variant warning message
     * @returns {JSX.Element} returns variant warning message
     */
    const showVariantWarningMessage = (): JSX.Element => {
        return (
            <div className={`${PREFIX}-variants__warning`}>
                <Icon type="ct-warning" size="lg"></Icon>
                <p className={`${PREFIX}-body-sm ${PREFIX}-variants__warning__text`}>
                    {
                        getVariantMessage(
                            selectedFirstVariant,
                            selectedSecondVariant,
                            selectedThirdVariant,
                            availabilitySelectVariantGenericMsg,
                            variantDetails,
                            availabilitySelectVariantMsg,
                            isShipToHome,
                            sthAvailabilitySelectVariantMsg,
                            pickupAvailabilitySelectVariantMsg,
                        )?.variantMessage
                    }
                </p>
            </div>
        )
    }

    /**
     * Function to render availability section based on box click
     * @returns {JSX.Element} returns availability section
     */
    const renderAvailabilitySection = (): JSX.Element => {
        if (isSthSection) {
            return renderShipToHome()
        } else if (isFreePickUpSection) {
            return renderFreePickUp()
        } else {
            return renderExpressDelivery()
        }
    }

    /**
     * Function to render bopis not available
     * @returns {JSX.Element | undefined} returns bopis not available
     */

    // eslint-disable-next-line consistent-return
    const renderBopisNotAvailableComp = (): JSX.Element | undefined => {
        if (
            areAllParamsValid(
                checkIfAllVariantsSelected,
                bopisUnavailable,
                !isBopisOnlySkuWithStoreBopisUnavailable,
                Boolean(isSellableProduct),
            )
        ) {
            return renderBopisNotAvailable()
        }
    }

    /**
     * Function to render variant or availability section
     * @returns {JSX.Element | null} returns variant or availability section
     */

    const renderVariantOrAvailabiltySection = (): JSX.Element | null => {
        if (!checkIfAllVariantsSelected && onlineOrdering) {
            return showVariantWarningMessage()
        } else {
            return isAtleastOneParamValid(
                !isSellableProduct,
                Boolean(isBopisOnlySkuWithStoreBopisUnavailable),
                !onlineOrdering,
            )
                ? null
                : renderAvailabilitySection()
        }
    }

    /**
     * Function to render in store component or fulfillment component
     * @returns {JSX.Element} returns variant or availability section
     */
    const renderInStoreOrFulFillmentComp = (): JSX.Element => {
        if (!onlineOrdering && (!isOutOfStockInAllStore || !checkIfAllVariantsSelected)) return renderNoOnlineOrder()
        else if (
            areAllParamsValid(!isSellableProduct, checkIfAllVariantsSelected) ||
            isBopisOnlySkuWithStoreBopisUnavailable
        ) {
            return renderInStorePurchaseOrOOS()
        } else {
            return renderFulfillmentMethodSection()
        }
    }

    /**
     * Render general composition of component when it is available to render
     * @returns {JSX.Element}
     */
    const renderBuyOnline = () =>
        showFulfillment ? (
            <div className={componentClassName}>
                {renderBuyOnlineLabel()}
                {renderBopisNotAvailableComp()}
                {renderInStoreOrFulFillmentComp()}
                {renderVariantOrAvailabiltySection()}
            </div>
        ) : (
            renderFulfillmentUnAvailableComp()
        )

    /**
     * Gets true when skeleton should be hidden
     * @returns {boolean}
     */
    const hideSkeleton = () => isNearbyStoreListApiDone || inStockInSelectedStore
    return (
        <>
            {hideSkeleton() ? (
                <>{renderBuyOnline()}</>
            ) : (
                <SkeletonComponent
                    skeletonClass={`${PREFIX}-buy-box__skeleton ${PREFIX}-buy-box__skeleton-fulfilment`}
                />
            )}
        </>
    )
}

BuyOnline.propTypes = {
    buyOnlineHeaderText: PropTypes.string,
    shipToHomeLabel: PropTypes.string,
    freeShippingEnabled: PropTypes.bool,
    freePickUpInStoreLabel: PropTypes.string,
    availableLabel: PropTypes.string,
    checkIfAllVariantsSelected: PropTypes.bool,
    freeShippingOverLabel: PropTypes.string,
    regularShippingThreshold: PropTypes.number,
    shipsInLabel: PropTypes.string,
    isShipToHome: PropTypes.bool,
    availabilitySelectVariantMsg: PropTypes.string,
    availabilitySelectVariantGenericMsg: PropTypes.string,
    pickupAvailabilitySelectVariantMsg: PropTypes.string,
    sthAvailabilitySelectVariantMsg: PropTypes.string,
    isShowNotInStock: PropTypes.bool,
    needMoreLabel: PropTypes.string,
    pickUpBetweenLabel: PropTypes.string,
    getItByLabelff: PropTypes.string,
    getItTomorrowLabelff: PropTypes.string,
    getItTodayLabelff: PropTypes.string,
    fulfillmentTooltip: PropTypes.string,
    inStockInSelectedStore: PropTypes.bool,
    inStockAtDC: PropTypes.bool,
    shippingFeeCalculation: PropTypes.string,
    fulfillment: PropTypes.any.isRequired,
    triangleShippingThreshold: PropTypes.number,
    a11yTooltipIcon: PropTypes.string,
    outOfStockTitle: PropTypes.string,
    outOfStockText: PropTypes.string,
    isAllSkusOOS: PropTypes.bool,
    enableHidingFirstVariantWhereAllSkusOOS: PropTypes.bool,
    isVariantsHidden: PropTypes.bool,
    isProductLevel: PropTypes.bool.isRequired,
    isOutOfStockInAllStore: PropTypes.bool.isRequired,
    hidingAllFirstVariantOOSMessage: PropTypes.string,
    productNotForSth: PropTypes.bool.isRequired,
    availableByLabel: PropTypes.string,
    timeLabelWhenPickUpAvailable: PropTypes.string,
    selectedStoreQty: PropTypes.number,
    closeFlyout: PropTypes.func.isRequired,
    shouldStoreFlyoutOpen: PropTypes.bool,
    setIsShipToHome: PropTypes.func.isRequired,
    isAddToCartClicked: PropTypes.bool.isRequired,
    inStockAtNearbyStores: PropTypes.bool.isRequired,
    outOfStockAtStoreff: PropTypes.string,
    unavailableLabel: PropTypes.string,
    shipToHomeUnavailable: PropTypes.string,
    setSelectedFulfillment: PropTypes.func.isRequired,
    checkOtherStoresLabel: PropTypes.string,
    isOnlineOnly: PropTypes.bool,
    bopisUnavailable: PropTypes.bool,
    freePickUpUnavailable: PropTypes.string,
    eligibilityAndShippingInfoLabel: PropTypes.string,
    isShippingPostalCodeHide: PropTypes.bool,
    changeLabel: PropTypes.string,
    postalCodeForEligFeesLabel: PropTypes.string,
    deliveryFeeLabel: PropTypes.string,
    deliverToLabel: PropTypes.string,
    isAutomotiveProduct: PropTypes.bool,
    heavyOversizeHeader: PropTypes.string.isRequired,
    heavyOversizeBody: PropTypes.string.isRequired,
    isSeparatedErrorHandling: PropTypes.bool,
}

BuyOnline.displayName = 'BuyOnline'

export default BuyOnline
