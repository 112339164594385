import { checkInstanceOfMaxPrice, checkInstanceOfPrice } from '../components/ProductReusableCard/instanceCheckingUtil'
import { Price } from '../components/Price/Price.types'
import { ONE_CENT } from '../components/Price/Price.constant'
import { CartOrderEntries } from '../components/ProductCartItem/ProductCartItem.type'
import { isArrayNotEmpty } from '../utils'

/**
 * Checks if all given prices are approximately equal.
 * It can handle both numbers and strings. When handling numbers, it considers them equal if the difference is less than 0.01.
 * When handling strings, it checks for strict equality.
 *
 * @param {...(number|string)[]} prices - An array of prices, which can be numbers or strings.
 * @returns {boolean} - Returns true if all prices are considered equal, otherwise false.
 */
export const isPriceNumbersEquil = (...prices: (number | string)[]) => {
    const areAllNumbers = prices.every(item => typeof item === 'number')
    const priceCopy = areAllNumbers ? prices : prices.map(item => item?.toString())
    const equality: (item: number | string, item2: number | string) => boolean = areAllNumbers
        ? (x: number, y: number): boolean => Math.abs(x - y) < ONE_CENT
        : (a: string, b: string): boolean => a === b
    return priceCopy.reduce(
        (accumulator: boolean, item: number | string) =>
            accumulator && !priceCopy.find(item2 => !equality(item, item2)),
        true,
    )
}

/**
 * Return true is price has defined min and max values.
 * @param price - price
 * @returns {boolean}
 */
export const isRangePrice = (price: Price): boolean => Boolean(price.maxPrice && price.minPrice)

/**
 * Converts a price object with the same minPrice and maxPrice into a single value.
 * If minPrice and maxPrice are equal and value is not set, it sets the value to minPrice/maxPrice and nullifies minPrice and maxPrice.
 *
 * @param {Price} price - The price object to be normalized.
 * @returns {Price} - The normalized price object.
 */
export const convertZeroRangeIntoMonoPrice = (price: Price) => {
    let newPrice = price
    if (price && isRangePrice(price) && isPriceNumbersEquil(price.maxPrice, price.minPrice)) {
        newPrice = {
            minPrice: null,
            maxPrice: null,
            value: price.minPrice,
        } as Price
    }
    return newPrice
}

/**
 * Normalizes a price object. Currently, it only converts a price range into a single price if applicable.
 * This function is a placeholder for additional normalization logic that may be added in the future.
 *
 * @param {Price} price - The price object to be normalized.
 * @returns {Price} - The normalized price object.
 */
export const normalizePrice = (price: Price) => {
    return convertZeroRangeIntoMonoPrice(price)
}

/**
 * Helper function to calculate savings based on discount
 * @param { Price } original
 * @param { Price } discount
 * @return {string} - returns discount for the user
 */
export const calculateSavings = (original: Price, discount: Price): string => {
    const { value, minPrice, maxPrice } = original || {}
    const { value: discountValue, minPrice: discountMinPrice, maxPrice: discountMaxPrice } = discount || {}

    const percentageMultiplier = 100

    if (value && discountValue) {
        const percentageDifference = (discountValue / value) * percentageMultiplier
        return `${roundToFivePercent(percentageDifference)}%`
    } else if (minPrice && maxPrice && discountMinPrice && discountMaxPrice) {
        const maxPriceDiff = (discountMaxPrice / maxPrice) * percentageMultiplier
        const minPriceDiff = (discountMinPrice / minPrice) * percentageMultiplier
        return `${roundToFivePercent(minPriceDiff)}% - ${roundToFivePercent(maxPriceDiff)}%`
    } else {
        return undefined
    }
}

/**
 *
 * @param {number} value - number to be rounded off
 * @return {number} - rounded off values
 */
const roundToFivePercent = (value: number): number => {
    const roundOffValue = 5
    /**
     * As per the discussion with BA team-> the round off should be nearest 5%
     *  i.e. 23% -> 20%, 24% -> 20% , 26-27% -> 25%
     */
    return value % roundOffValue >= roundOffValue // value should be 5 to satisfy the above condition
        ? Math.floor(value / roundOffValue) * roundOffValue + roundOffValue
        : Math.floor(value / roundOffValue) * roundOffValue
}

/**
 * Function to get the current price value
 * @param {Price} currentPrice - current price
 * @param {Price} originalPrice - original price
 * @param {string} language - language
 * @return {string} returns price value
 */
export const checkWasCurrentPrice = (currentPrice: Price, originalPrice: Price, language: string): string => {
    return currentPrice && Object.keys(currentPrice).length
        ? checkInstanceOfPrice(currentPrice, language)
        : checkInstanceOfPrice(originalPrice, language)
}

/**
 * To display total price in cart
 *@param {boolean} isMoreQuantityCartPage - current price
 *@param {Price} priceValue - current price
 *@param {Price} currentPrice - current price
 *@param {Price} originalPrice - current price
 *@param {string} language - current price
 *@param {boolean} showDiscountedPrice - flag to display price that does not include discount amount
 *@param {Price} totalPrice - total price value
 *@param {boolean} isZeroPriceShown - is zero price shown
 * @return {string} returns price value
 */

export const checkTotalPriceComponent = (
    isMoreQuantityCartPage: boolean,
    priceValue: Price,
    currentPrice: Price,
    originalPrice: Price,
    language: string,
    showDiscountedPrice?: boolean,
    totalPrice?: Price,
    isZeroPriceShown = false,
    // eslint-disable-next-line max-params
): string => {
    // eslint-disable-next-line sonar/expression-complexity
    return isMoreQuantityCartPage
        ? checkInstanceOfPrice(priceValue, language, undefined, isZeroPriceShown)
        : // eslint-disable-next-line sonar/no-nested-conditional
        showDiscountedPrice && isValidPrice(totalPrice)
        ? checkInstanceOfPrice(totalPrice, language, undefined, isZeroPriceShown)
        : // eslint-disable-next-line sonar/no-nested-conditional
        currentPrice && Object.keys(currentPrice).length
        ? checkInstanceOfPrice(currentPrice, language, undefined, isZeroPriceShown)
        : checkInstanceOfPrice(originalPrice, language, undefined, isZeroPriceShown)
}

export const getTotalPriceMax = (
    isMoreQuantityCartPage: boolean,
    priceValue: Price,
    currentPrice: Price,
    originalPrice: Price,
    language: string,
    showDiscountedPrice?: boolean,
    totalPrice?: Price,
): string => {
    // eslint-disable-next-line sonar/expression-complexity
    return isMoreQuantityCartPage
        ? checkInstanceOfMaxPrice(priceValue, language)
        : // eslint-disable-next-line sonar/no-nested-conditional
        showDiscountedPrice && isValidPrice(totalPrice)
        ? checkInstanceOfMaxPrice(totalPrice, language)
        : // eslint-disable-next-line sonar/no-nested-conditional
        currentPrice && Object.keys(currentPrice).length
        ? checkInstanceOfMaxPrice(currentPrice, language)
        : checkInstanceOfMaxPrice(originalPrice, language)
}

/**
 * To display was and was from label
 *@param {boolean} displayWasLabel
 *@param {Price} originalPrice
 *@param {string} unitPriceLabel
 *@param {string} wasFromLabel
 *@param {string} fromLabel
 *
 * @return {string}
 */
export const getNowWasLabel = (
    displayWasLabel: boolean,
    originalPrice: Price,
    unitPriceLabel: string,
    wasFromLabel: string,
    fromLabel: string,
    isRangeView?: boolean,
    showToLabel?: boolean,
): string => {
    const fromApplicable = !originalPrice?.value && (showToLabel || !isRangeView || !originalPrice?.maxPrice)
    // eslint-disable-next-line sonar/no-nested-conditional
    return displayWasLabel ? (fromApplicable ? wasFromLabel : unitPriceLabel) : fromApplicable ? fromLabel : ''
}

/**
 * To display save and save from label
 *@param {Price} discountValue
 *@param {string} promotionalPriceLabel
 *@param {string} saveFromLabel
 * @return {string}
 */
export const getSaveLabel = (discountValue: Price, promotionalPriceLabel: string, saveFromLabel: string): string => {
    return !discountValue?.value ? saveFromLabel : promotionalPriceLabel
}

/**
 * Check that price is available to render
 *@param {Price | null | undefined} price
 * @return  {boolean}
 */
export const isValidPrice = (price: Price | null | undefined): boolean => {
    return typeof (price?.value ?? price?.minPrice) === 'number'
}

/**
 * Determines the price value for FOMO from the given product data object.
 * Returns the total price divided by its quantity if promotional messages are applied, otherwise returns the current price to the FOMO.
 * @param {CartOrderEntries} data - The data object containing price information.
 * @returns {number | null} The price value or null if neither price is available.
 */
export const getFomoPriceData = (data: CartOrderEntries): number => {
    const totalPrice = data?.totalPrice?.value || 0
    const quantity = data?.quantity || 0
    const currentPrice = data?.currentPrice?.value || 0
    const hasPromoMessagesAndTotalPrice = isArrayNotEmpty(data?.appliedPromoMessages) && quantity && totalPrice

    if (hasPromoMessagesAndTotalPrice) {
        return totalPrice / quantity
    } else {
        return currentPrice
    }
}
