import React from 'react'
import { AnyAction, Dispatch } from 'redux'
import { BannerImagePath } from '../components/Accounts/Rewards/Offers/OffersCard.type'
import {
    checkDataLength,
    offerComponentLocation,
    offerConstants,
    replaceStrWithDynamicVal,
    CertonaConstants,
    libUtils,
    isArrayNotEmpty,
    statusLabel,
    magicNumber,
    replaceMultipleStrWithDynamicVal,
    ButtonStatusType,
    getDateOfBirth,
    dateOptions,
    excludeQuote,
    getDaysDifference,
    isAtleastOneParamValid,
} from '@nl/lib'
import { MagicNumber } from '../analytics/analytics.type'
import {
    emailActivationUrl,
    lmsProfileIdParameterName,
    nppBaseRewardValue,
} from '../components/EmailOfferActivation/EmailOfferActivation.constant'
import {
    AwardValuesType,
    BenefitsValue,
    NppTieredProp,
    RewardsBoostValueType,
    RewardsVal,
} from '../components/PDPOfferBenefits/PDPOfferBenefits.type'
import { activateCustomerOffers, fetchCustomerOffers, fetchRecommendationsData } from '../redux/actions'
import { Offers } from '../redux/models/offers.interface'
import store from '../store'
import { getBannerId, getOfferBannerId } from '../utils/getCurrentBannerId'
import { getOffersPayload } from '../utils/offersCache.helper'
import { CurrentPrice, Price } from '../redux/models/cart.interface'
import { CertonaInitialization } from '../certona/certona.service'
import appCacheService from '../utils/appCacheService'
import { certonaEventConst } from '../certona/certona.constant'
import getEnvironment from '../utils/getEnvironment'
import { pageTypes } from '../config'
import getPageType from '../utils/getPageType'
import { roundOfToTwoDecimals } from '@nl/lib/src/utils/roundOfToTwoDecimals'
import { isHomeServiceItem } from '../components/BuyBox/BuyBox.helper'
import { isAutomotivePDP } from '../components/Vehicles/Vehicle.helper'
import {
    AddToCartRecommendationDataType,
    ProductCardType,
    OfferRecommendation,
    RecommendationResponseDataDTO,
} from '../redux/models/recommendations.interface'
import { CertonaSchemaType } from '../certona/certona.type'
import { IGlobalProps } from '../redux/models/commonContent.interface'
import { getComboExclusionsList } from '../components/LoyaltyOffers/LoyaltyOffers.helper'
import { ItemListType } from '../global.type'
import { ProductLevel, SkuCode } from '../components/BuyBox/BuyBox.type'
import { UserProfileData } from '../redux/models/user.profile.interface'
import { CoachMarkOffer, CoachMarkOfferCard } from '../redux/models/offerCoachMark.interface'
import { TriangleBenefitList } from '../redux/models/product.interface'
import { getFormattedPriceWithLocale } from '../utils/formatPrice'

/**
 * function render card title
 * @param {boolean} isRewardCard is Reward Card
 * @param {string} pteTriangleRewardsLabel rewards label
 * @param {string} pteTriangleMCBenefitsLabel mastercard benefits label
 * @returns {string} returns the card title based on card type
 */
export const renderPTETitle = (
    isRewardCard: boolean,
    pteTriangleRewardsLabel: string,
    pteTriangleMCBenefitsLabel: string,
): string => {
    if (isRewardCard) {
        return pteTriangleRewardsLabel
    } else {
        return pteTriangleMCBenefitsLabel // for mastercard
    }
}

/**
 * function render card subtitle
 * @param {boolean} isRewardCard is Reward Card
 * @param {string} pteEverydayBonuses everyday bonuses label
 * @param {string} ptTriangleEarnCTMoneyText earn ct money label
 * @param {number} rewardsPercentage rewards percentage
 * @returns {string} returns the card subtitle based on card type
 */
export const renderPTESubtitle = (
    isRewardCard: boolean,
    pteEverydayBonuses: string,
    ptTriangleEarnCTMoneyText: string,
    rewardsPercentage: number,
): string => {
    const cartSubtitle = isRewardCard ? pteEverydayBonuses : ptTriangleEarnCTMoneyText
    return replaceStrWithDynamicVal(cartSubtitle, `${rewardsPercentage}%`)
}

/**
 * function return sum of all award values
 * @param {number[]} awardValues award values
 * @returns {number} returns sum of all award values
 */
export const renderPTEAmount = (awardValues: number[]): number => {
    return awardValues.reduce((a, c) => a + c, 0)
}

/**
 * function to check if offer status activated or not
 * @param {Offers} offer offer data
 * @param {string} offerStatus offer status
 * @returns {boolean} returns true/false based on offer status
 */
export const checkOfferStatus = (offer: Offers | null, offerStatus: string): boolean => {
    return offer?.offerStatus?.toLowerCase() === offerStatus.toLowerCase()
}

/**
 * function calculating Reward Boost value
 * @param {number} multiplierValue multiplier value
 * @param {number} currentPrice current price
 * @param {number} quantity quantity
 * @returns {number} returns rewards boost value
 */
export const renderPTERewardsBoostValue = (multiplierValue: number, currentPrice: number, quantity: number): number => {
    return roundOfToTwoDecimals(Number(multiplierValue * nppBaseRewardValue * currentPrice * quantity))
}

/**
 * function calculating Reward Boost % value
 * @param {number} multiplierValue multiplier value
 * @returns {number} returns rewards boost percentage value
 */
export const rewardsBoostPercentageValue = (multiplierValue: number): number => {
    return roundOfToTwoDecimals(Number(multiplierValue * nppBaseRewardValue * MagicNumber.HUNDRED))
}

/**
 * function to return total award value array
 * @param {Offers[]} offersData offers data
 * @returns {number[]} returns total award value array
 */
export const getAwardValues = (offersData: Offers[]): AwardValuesType => {
    const totalAwardValue: number[] = []
    const totalAwardValueForActivatedOffers: number[] = []
    offersData?.forEach((offerObj: Offers) => {
        const awardValue = Number(offerObj.details.awardValue)
        totalAwardValue.push(awardValue)
        !!checkOfferStatus(offerObj, offerConstants.activated) && totalAwardValueForActivatedOffers.push(awardValue)
    })
    return { totalAwardValue, totalAwardValueForActivatedOffers }
}

/**
 * function to return class based on offer status
 * @param {boolean} isActivatedOffer is activated offer
 * @param {string} prefixClass prefix class
 * @returns {string} function to return class based on offer status
 */
export const getOfferStatusClass = (isActivatedOffer: boolean, prefixClass: string): string => {
    return isActivatedOffer ? `${prefixClass}--activated` : `${prefixClass}--not-activated`
}

/**
 * Function to check if offer is activated or not
 * @param {Offers[]} allOffers of offers to be activated -- can be one/many/all
 * @returns {Offers[] | undefined} return offers that was set from ACTIVATE -> ACTIVATED
 */
export const handleOffersToActivated = (allOffers?: Offers[]): Offers[] | undefined => {
    return allOffers?.map((offer: Offers) => {
        return checkOfferStatus(offer, offerConstants.activate)
            ? {
                  ...offer,
                  offerStatus: offerConstants.activated,
              }
            : offer
    })
}

/**
 * Function to render number of the offers which are unactivated
 * @param {Offers[]} offers offers list
 * @returns {number} returns number of offers that are unactivated
 */
export const getUnactivatedOffersCount = (offers?: Offers[]): number => {
    const inActivatedOffers = offers?.filter(offer => offer.offerStatus === offerConstants.activate)
    return inActivatedOffers?.length || MagicNumber.ZERO
}

/**
 * Function to render npp offer title based on offer type eg: MULTIPLIER/SPENDANDGET
 * @param {boolean} isMultiplierOffer is Multiplier Offer Type
 * @param {string} pteNppSpendAndGetOfferTitle spendAndGet title
 * @param {string} pteNppMultiplierOfferTitle multiplier title
 * @param {number} pteNppMultiplierAmount multiplier amount based on usertype
 * @returns {Offers[]} return offers that was set from ACTIVATE -> ACTIVATED
 */
export const renderNPPOfferTitle = (
    isMultiplierOffer: boolean,
    pteNppSpendAndGetOfferTitle: string,
    pteNppMultiplierOfferTitle: string,
    pteNppMultiplierAmount: number,
): string => {
    if (isMultiplierOffer) {
        return replaceStrWithDynamicVal(pteNppMultiplierOfferTitle, pteNppMultiplierAmount)
    } else {
        return pteNppSpendAndGetOfferTitle
    }
}

/**
 * Function to render npp offer value based on offer type eg: MULTIPLIER/SPENDANDGET
 * @param {boolean} isMultiplierOffer is Multiplier Offer Type
 * @param {number} pteNppSpendAndGetOfferVal spendAndGet value
 * @param {number} pteNppMultiplierVal multiplier value
 * @param {boolean} isQuebecRegion  store is in quebec region
 * @returns {Offers[]} return offers that was set from ACTIVATE -> ACTIVATED
 */
export const renderNPPOfferValue = (
    isMultiplierOffer: boolean,
    pteNppSpendAndGetOfferVal: number,
    pteNppMultiplierVal: number,
    isQuebecRegion: boolean,
): number => {
    if (isMultiplierOffer) {
        return pteNppMultiplierVal
    } else {
        return isQuebecRegion ? 0 : pteNppSpendAndGetOfferVal
    }
}

/**
 * Extracts all offer codes based on the provided offer and filteredOffers
 * @param {Offers} offer - Offer to activate
 * @param {React.Dispatch<React.SetStateAction<Offers>>} [updateOfferState] - Function to update offer status
 * @param {Offers[]} [filteredOffers] - List of offers
 * @param {string} [offerComponent] - Component name
 * @returns {string[]} - Array of offer codes
 */
function getAllOfferCodes(
    offer: Offers | null,
    updateOfferState?: React.Dispatch<React.SetStateAction<Offers>>,
    filteredOffers?: Offers[],
    offerComponent?: string,
): string[] {
    if (offer && checkDataLength(offer)) {
        const shouldActivateOffer =
            offerComponent !== offerComponentLocation.loyaltyOffer || checkOfferStatus(offer, offerConstants.activate)

        if (shouldActivateOffer) {
            if (updateOfferState) {
                updateOfferState(offer)
            }
            return [offer.offerCode]
        }
    }

    if (filteredOffers && filteredOffers.length > 0) {
        return (
            filteredOffers
                .map((filterOffer: Offers) => filterOffer.offerCode)
                // eslint-disable-next-line sonar/different-types-comparison
                .filter((code): code is string => code !== undefined)
        )
    }

    return []
}

/**
 * Callback function to activate offers and dispatch offersActivatedData
 * If an offer parameter is passed, it activates a single offer; otherwise, it activates all offers
 * @param {Offers} offer - Offer to activate
 * @param {React.Dispatch<React.SetStateAction<Offers>>} [updateOfferState] - Function to update offer status
 * @param {Offers[]} [filteredOffers] - List of offers
 * @param {string} [offerComponent] - Component name
 * @param {string[]} [offersSortBy] - Offers sort list based on banner IDs
 * @param {boolean} [needToFetchOffers] - Default true. True if need Offers call after activation
 * @param {string} [loyaltyCardNumber] - Loyalty ID for guest user
 * @returns {void} - Returns nothing
 */
export const activateOffer = (
    offer: Offers | null,
    updateOfferState?: React.Dispatch<React.SetStateAction<Offers>>,
    filteredOffers?: Offers[],
    offerComponent?: string,
    offersSortBy?: string[],
    // eslint-disable-next-line default-param-last
    needToFetchOffers = true,
    loyaltyCardNumber?: string | null,
): void => {
    const allOfferCodes = getAllOfferCodes(offer, updateOfferState, filteredOffers, offerComponent)

    if (allOfferCodes.length === 0) {
        return
    }

    const urlParams = new URLSearchParams(window.location.search)
    const activationUrl = window.location.href.includes(emailActivationUrl)
    const lmsProfileIdParam = activationUrl ? urlParams.get(lmsProfileIdParameterName) : null

    const payload = {
        offerCodes: allOfferCodes,
    }

    const action = lmsProfileIdParam
        ? activateCustomerOffers(payload, needToFetchOffers, lmsProfileIdParam, offerComponent, offersSortBy)
        : activateCustomerOffers(
              payload,
              needToFetchOffers,
              '',
              offerComponent,
              offersSortBy,
              loyaltyCardNumber as string,
          )

    store.dispatch(action as unknown as AnyAction)
}

/**
 * Function to display offers after filtering with offer banner
 * @param {Offers[]} offersData offers data
 * @param {string[]} offerBannerList offer banner list
 * @returns {Offers[]} returns offers which are filtered
 */
export const getFilteredOffersBanner = (offersData?: Offers[], offerBannerList?: string[]): Offers[] => {
    return offersData?.filter(offer => offerBannerList?.some((banner: string) => offer?.banner.includes(banner))) || []
}

/**
 * function to render NPP multiplier offer award value
 * @param {number} pteNppMultiplierAmount multiplier amount
 * @param {number} currentPrice current price
 * @param {number} quantity quantity
 * @returns {number} returns NPP multiplier offer award value
 */
export const renderMultiplierAwardValue = (
    pteNppMultiplierAmount: number,
    currentPrice: number,
    quantity: number,
): number => {
    return Number(nppBaseRewardValue * pteNppMultiplierAmount * currentPrice * quantity) || MagicNumber.ZERO
}

/**
 * Function to render bonus earn value
 * @param {boolean} isRewardCard is Reward Card
 * @param {boolean} isMultiplierOffer is Multiplier Offer
 * @param {number} pteNppMultiplierAmount multiplier amount
 * @param {number} pteNppMultiplierAmountMCUser multiplier amount for MC user
 * @param {number} pteNppSpendAndGetOfferVal spendAndGet value
 * @param {number} subtotal subtotal
 * @returns {number} return bonus earn value
 */
export const getBonusValue = (
    isRewardCard: boolean,
    isMultiplierOffer: boolean,
    pteNppMultiplierAmount: number,
    pteNppMultiplierAmountMCUser: number,
    pteNppSpendAndGetOfferVal: number,
    subtotal: number,
): number => {
    const nppMultiplierAmount = isRewardCard ? pteNppMultiplierAmount : pteNppMultiplierAmountMCUser
    const nppMultiplierAwardValue = renderMultiplierAwardValue(nppMultiplierAmount, subtotal, MagicNumber.ONE)
    const bonusEarnValue = isMultiplierOffer ? nppMultiplierAwardValue : pteNppSpendAndGetOfferVal
    return bonusEarnValue ? bonusEarnValue : MagicNumber.ZERO
}

/**
 * function to check whether bonus satisfy minimum purchase criteria
 * @param {boolean} isMultiplierOffer is Multiplier Offer
 * @param {number} pteMinMultiplierVal minimum multiplier value
 * @param {number} pteMinSpendAndGetVal minimum spendAndGet value
 * @param {number} subtotal subtotal
 * @returns {boolean} returns true/false based on condition
 */
export const bonusToDisplay = (
    isMultiplierOffer: boolean,
    pteMinMultiplierVal: number,
    pteMinSpendAndGetVal: number,
    subtotal: number,
): boolean => {
    const pteMinVal = isMultiplierOffer ? pteMinMultiplierVal : pteMinSpendAndGetVal
    return Boolean(subtotal >= pteMinVal)
}

/**
 * function to display notification Badge
 * @param {number} unActivatedOffersCount unactivated offers count
 * @param {number} offersBadgeCountThreshold offers badge count threshold
 * @returns {boolean} returns true/false based on condition
 */
export const showBadge = (unActivatedOffersCount: number, offersBadgeCountThreshold: number): boolean => {
    return Boolean(!!unActivatedOffersCount && unActivatedOffersCount <= offersBadgeCountThreshold)
}

/**
 * Function to check NotificationBadge should display or not display
 * @param { Offers[] } offersToDisplay - offers to display
 * @param { number } offersBadgeCountThreshold - offers badge count threshold
 * @returns {boolean} - Returns true/false based on condition
 */
export const showNotificationBadge = (offersToDisplay?: Offers[], offersBadgeCountThreshold?: number): boolean => {
    return getUnactivatedOffersCount(offersToDisplay) && offersBadgeCountThreshold
        ? showBadge(getUnactivatedOffersCount(offersToDisplay), offersBadgeCountThreshold)
        : false
}

/**
 * Function to filter offers which are Spend and Get and not redeemed offers
 * @param {Offers[]} offersData offers data
 * @param {string} spendAndGetText spendAndGet text
 * @returns {Offers[]} return offers that are only Spend and Get and not redeemed offers
 */
export const spendAndGetFilteredOffers = (offersData: Offers[], spendAndGetText: string): Offers[] => {
    return (
        offersData?.filter(
            offer =>
                // eslint-disable-next-line sonar/expression-complexity
                offer?.details.bonusType === offerConstants.flatrate &&
                offer?.offerStatus !== offerConstants.redeemed &&
                offer?.redeemed !== offerConstants.yes &&
                offer?.details?.categoryLevel1?.trim().toLowerCase() === spendAndGetText?.trim().toLowerCase() &&
                offer?.banner?.includes(getOfferBannerId() || getBannerId()),
        ) || []
    )
}

/**
 * Function to call offers api
 * @param {Dispatch} dispatch - Dispatch function
 * @param {string[]} offersSortBy - Offers sort list based on banner IDs
 * @param {string | null} loyaltyCardNumber - Loyalty ID for guest user
 * @returns {void} - Returns nothing
 */
export const loadOffers = (dispatch: Dispatch, offersSortBy?: string[], loyaltyCardNumber?: string | null): void => {
    dispatch(
        fetchCustomerOffers(
            getOffersPayload(offersSortBy),
            undefined,
            null,
            undefined,
            loyaltyCardNumber as string,
        ) as unknown as AnyAction,
    )
}

const environment = getEnvironment()

/**
 * Function to call certona to get offer recommendation list
 * @param {string} event - event name
 * @returns {void} - Returns nothing
 */
export const certonaOfferRecommendations = (event: string): void => {
    CertonaInitialization.triggerCertona({
        event: event,
        environment: environment,
        recommendations: true,
        filter: {
            storeid: appCacheService.preferredStoreId.get() || '',
            currencycode: CertonaConstants.CURRENCY,
            language: libUtils.getLanguage(),
        },
    })
}

/**
 * Function to get certona event based on pagetype
 * @returns {string} - Returns certona event name
 */
export const getCertonaEvent = (): string => {
    const currentPageType = getPageType()
    const { emailOfferActivation, weeklyOffer, accountDasboard } = pageTypes
    switch (currentPageType) {
        case weeklyOffer:
            return certonaEventConst.weekelyOffer
        case accountDasboard:
            return certonaEventConst.dashboardOffer
        case emailOfferActivation:
            return certonaEventConst.emailOffer
        default:
            return ''
    }
}

/**
 * Function to update current price
 * @param {Price} priceValue - price value
 * @returns {CurrentPrice} - Returns updated price value
 */
export const updatingPriceValue = (priceValue: Price): CurrentPrice => {
    let updatePriceStructure
    const { value, maxPrice, minPrice } = !!priceValue && priceValue
    if (value) {
        updatePriceStructure = { value: value }
    } else if (minPrice) {
        updatePriceStructure = { value: minPrice }
    } else {
        updatePriceStructure = maxPrice ? { value: maxPrice } : { value: MagicNumber.ZERO }
    }
    return updatePriceStructure
}

/**
 * Function to get earned total amount
 * @param { number } baseValue - Base value
 * @param { number } nppOfferValue - NPP offer value
 * @param { number } selectCardRewardsValue - Select card rewards value
 * @param { number } bonusValue - Bonus value
 * @returns { number } - Returns total earned amount
 */
export const earnedTotalAmount = (
    baseValue: number,
    nppOfferValue: number,
    selectCardRewardsValue: number,
    bonusValue: number,
): number => {
    return Number(baseValue) + Number(nppOfferValue) + Number(selectCardRewardsValue) + Number(bonusValue)
}

/**
 * Fetching and filtering reward boost value
 * @param {RewardsVal} skuValidParam - SKU valid param
 * @param {number} quantity - Quantity
 * @returns {RewardsBoostValueType} - Returns reward boost value
 */
export const getSkuValidRewardBoost = (skuValidParam: RewardsVal, quantity: number): RewardsBoostValueType => {
    const selectedSKU =
        isArrayNotEmpty(skuValidParam.skuList.list) &&
        skuValidParam.productLevel?.isAllVariantSelected &&
        skuValidParam?.skuList?.code
            ? skuValidParam?.skuList?.list?.find(skuData => skuData?.code === skuValidParam?.skuList?.code)
            : skuValidParam.productLevel?.data
    let badgeValue = ''
    let pteRewardsBoosterValue = 0
    let pteRewardsBoostPercentage = 0
    const { OfferRewardValue = '', RewardPercentage, OfferRule = '' } = selectedSKU?.loyalty?.Bonus || {}
    const RewardVal = Number(OfferRewardValue) * quantity

    badgeValue = RewardPercentage
        ? OfferRule.toLowerCase()
        : // eslint-disable-next-line no-magic-numbers
          getFormattedPriceWithLocale(Number(OfferRewardValue), 2, 0)
    pteRewardsBoosterValue = RewardPercentage ? Number(RewardVal) : Number(OfferRewardValue)
    pteRewardsBoostPercentage = Number(RewardPercentage) || 0
    return { badgeValue, pteRewardsBoosterValue, pteRewardsBoostPercentage }
}

/**
 * Function to get whether product is genmerch or not
 * @param {string} fitmentTypeCode - Fitment type code
 * @param {string} productWheelType - Product wheel type
 * @param {string} productType - Product type
 * @param {number} quantitySelected - Quantity selected
 * @returns {number} - Returns quantity for genmerch products
 */
export const getQuantityforGenMerchProducts = (
    fitmentTypeCode: string,
    productWheelType: string,
    productType: string,
    quantitySelected: number,
): number => {
    return !isAutomotivePDP(fitmentTypeCode, productWheelType) && !isHomeServiceItem(productType)
        ? quantitySelected
        : MagicNumber.ONE
}

/**
 * Extract pcodes from the certona response.
 * @param {AddToCartRecommendationDataType[]} offerRecommendationData - Offer recommendation data
 * @param {RecommendationResponseDataDTO[]} recommendationsData - Recommendations data
 * @param {Dispatch} dispatch - Dispatch function
 * @returns {void} - Returns nothing
 */
export const dispatchCertonaOffers = (
    offerRecommendationData: AddToCartRecommendationDataType[],
    recommendationsData: RecommendationResponseDataDTO[],
    dispatch: Dispatch,
): void => {
    offerRecommendationData.forEach(
        (
            schemeObj: AddToCartRecommendationDataType,
            index: number,
            recommendationArray: AddToCartRecommendationDataType[],
        ) => {
            const selectedSchemeId: string = Object.keys(schemeObj)[MagicNumber.ZERO]
            const pcode = recommendationArray[index][selectedSchemeId].pCode as unknown as string[]
            if (isArrayNotEmpty(pcode)) {
                dispatch(fetchRecommendationsData(pcode, recommendationsData, selectedSchemeId) as unknown as AnyAction)
            }
        },
    )
}

/**
 * Get Recommendation array.
 * @param {CertonaSchemaType[]} certonaSchemaIdList - Certona schema id list
 * @param {string} schemaId - Schema id
 * @returns {AddToCartRecommendationDataType[]} - Returns recommendation array
 */
export const getofferRecommendationObj = (
    certonaSchemaIdList: CertonaSchemaType[],
    schemaId: string,
): AddToCartRecommendationDataType[] => {
    const offerRecommendationObj = []
    const { getPCodes, getTitle, getItems } = CertonaInitialization.extractSchemeDetails(certonaSchemaIdList, schemaId)

    isArrayNotEmpty(getPCodes) &&
        offerRecommendationObj.push({
            [schemaId]: {
                pCode: getPCodes,
                title: getTitle,
                items: getItems,
            },
        })
    return offerRecommendationObj
}

/**
 * Get Recommendation List.
 * @param {CertonaSchemaType[]} certonaSchemaIdList - Certona schema id list
 * @param {string} schemaId - Schema id
 * @param {IGlobalProps} commonContentAvailable - Common content available
 * @param {string | undefined} recommendedProductsLabel - Recommended products label
 * @param {ProductCardType[]} productList - Product list
 * @param {number} maxNumberOfProductRecommendations - Maximum number of product recommendations
 * @returns {OfferRecommendation} - Returns recommendation list
 */
export const getRecommendationList = (
    certonaSchemaIdList: CertonaSchemaType[],
    schemaId: string,
    commonContentAvailable: IGlobalProps,
    recommendedProductsLabel: string,
    productList: ProductCardType[],
    maxNumberOfProductRecommendations?: number,
): OfferRecommendation => {
    const recommendationTitle = certonaSchemaIdList?.find((item: { scheme: string }) => item.scheme === schemaId)
        ?.explanation as string
    const language = libUtils.getLanguage()
    const priceProps = { ...commonContentAvailable?.product }
    const a11yStrikeOutPrice = commonContentAvailable?.accessibility?.a11yStrikeOutPrice
    const a11yStrikeOutPriceRange = commonContentAvailable?.accessibility?.a11yStrikeOutPriceRange
    return {
        title: recommendedProductsLabel || recommendationTitle,
        productList: productList,
        priceProps: priceProps || {},
        a11yStrikeOutPrice,
        a11yStrikeOutPriceRange,
        language,
        selectedSchemaId: schemaId,
        isYouNeedThis: false,
        badgePriorities: commonContentAvailable?.badges,
        maximumNumberOfRecommendations: maxNumberOfProductRecommendations,
    }
}

/**
 * Get header button Label.
 * @param {Offers[]} filteredOffers - Filtered offers
 * @param {ButtonStatusType} allOffersActivated - All offers activated
 * @param {string} activateOfferLabel - Activate offer label
 * @param {string} activateAllOffersLabel - Activate all offers label
 * @returns {string} - Returns header button label
 */
export const getHeaderButtonLabel = (
    filteredOffers: Offers[],
    allOffersActivated: ButtonStatusType,
    activateOfferLabel: string,
    activateAllOffersLabel: string,
): string => {
    // Offer object/s that are in activate status
    const offersInActivateStatus =
        filteredOffers &&
        filteredOffers.filter(
            item => item?.offerStatus?.toLocaleLowerCase() === statusLabel.activateStatus?.toLocaleLowerCase(),
        )
    return allOffersActivated
        ? allOffersActivated?.label
        : replaceMultipleStrWithDynamicVal(
              // eslint-disable-next-line sonar/no-nested-conditional
              offersInActivateStatus?.length === magicNumber.ONE ? activateOfferLabel : activateAllOffersLabel,
              [offersInActivateStatus?.length],
          )
}

/**
 * Get brand banner list
 * @param {boolean} enableOffersBrandFiltering - enable offers brand filtering
 * @param {BannerImagePath[]} bannerIdToNameList - banner id to name list
 * @returns {BannerImagePath[]} - Returns brand banner list
 */
export const getBrandBannerList = (
    enableOffersBrandFiltering: boolean,
    bannerIdToNameList: BannerImagePath[],
): BannerImagePath[] | null => {
    return enableOffersBrandFiltering ? bannerIdToNameList : null
}

/**
 * Get Offers Expiry Date
 * @param {Offers} offer - Offer data
 * @param {string} offerEndsTodayLabel - Offer ends today label
 * @param {string} offerEndsInLabel - Offer ends in label
 * @param {string} offerEndsLabel - Offer ends label
 * @returns {string} returns offer expiry date
 */
export const getOfferCardExpiryDate = (
    offer: Offers,
    offerEndsTodayLabel: string,
    offerEndsInLabel: string,
    offerEndsLabel: string,
): string => {
    const offerExpiryInDaysForm =
        Number(offer.daysLeft) <= magicNumber.ONE
            ? offerEndsTodayLabel
            : replaceMultipleStrWithDynamicVal(offerEndsInLabel, [offer.daysLeft])
    const offerExpiryInDateForm = getDateOfBirth(offer?.offerEndDate, dateOptions.style1.month)
    return Number(offer.daysLeft) > magicNumber.SEVEN
        ? `${offerEndsLabel} ${offerExpiryInDateForm}`
        : offerExpiryInDaysForm
}

/**
 * get swapable data based on offerswapflag
 * @param {Offers[]} offers - offers data
 * @returns {Offers[] | []} return swappable filtered offer
 */
export const getSwappableOffer = (offers?: Offers[]): Offers[] | [] => {
    return (
        offers?.filter(
            offer => offer?.offerStatus !== offerConstants.redeemed && offer?.swappableFlag === offerConstants.yes,
        ) || []
    )
}

/**
 * Function will exclude particular banner combo coming from AEM from OfferList if toggle is enabled
 * @param {Offers[]} offers - offers data
 * @param {boolean} enableComboExclusions - enable combo exclusions
 * @param {string} comboExclusions - combo exclusions
 * @returns {Offers[]} return combo filtered offer
 */
export const getComboFilterList = (
    offers: Offers[],
    enableComboExclusions?: boolean,
    comboExclusions?: string,
): Offers[] => {
    return isArrayNotEmpty(offers)
        ? // eslint-disable-next-line sonar/no-nested-conditional
          enableComboExclusions
            ? getComboExclusionsList(offers, excludeQuote(comboExclusions))
            : offers
        : []
}

/**
 * function to get tiered event details based on Qualifier amount
 * @param {number} totalPrice total price calculation based on quantity
 * @param {NppTieredProp} nppTieredProps all qualifier amount and reward amount
 * @returns {ItemListType} returns tiered event title and value
 */
export const renderTieredEventValue = (totalPrice: number, nppTieredProps: NppTieredProp): ItemListType => {
    const {
        nppTieredEventOfferText,
        nppTieredEventQAmtT2,
        nppTieredEventRewardsValT1,
        nppTieredEventRewardsValT2,
        nppTieredEventQAmtT1,
    } = nppTieredProps
    if (totalPrice >= nppTieredEventQAmtT2) {
        return {
            title: replaceMultipleStrWithDynamicVal(nppTieredEventOfferText, [
                nppTieredEventQAmtT2,
                nppTieredEventRewardsValT2,
            ]),
            value: nppTieredEventRewardsValT2,
        }
    } else {
        return {
            title: replaceMultipleStrWithDynamicVal(nppTieredEventOfferText, [
                nppTieredEventQAmtT1,
                nppTieredEventRewardsValT1,
            ]),
            value: nppTieredEventRewardsValT1,
        }
    }
}

/**
 * function to get npp offer list
 * @param {boolean} isMultiplierOffer is Multiplier Offer Type
 * @param {ItemListType} pteSpendAndGet spendAndGet data
 * @param {number} nppMultiAmount npp multiplier amount based on usertype
 * @param {string} pteNppMultiplierOfferTitle npp multiplier title
 * @param {number} nppMultiplierAwardValue npp multiplier value
 * @param {boolean} isQuebecRegion  store is in quebec region
 * @returns {ItemListType} returns npp offer list
 */
export const getNppOfferVal = (
    isMultiplierOffer: boolean,
    pteSpendAndGet: ItemListType,
    nppMultiAmount: number,
    pteNppMultiplierOfferTitle: string,
    nppMultiplierAwardValue: number,
    isQuebecRegion: boolean,
): ItemListType => {
    const nppOfferTitle = renderNPPOfferTitle(
        isMultiplierOffer,
        pteSpendAndGet?.title as string,
        pteNppMultiplierOfferTitle,
        nppMultiAmount,
    )
    const nppOfferValue = renderNPPOfferValue(
        isMultiplierOffer,
        pteSpendAndGet?.value as number,
        nppMultiplierAwardValue,
        isQuebecRegion,
    )
    return {
        title: nppOfferTitle,
        value: nppOfferValue,
    }
}

/**
 * Function to get trianglemaster,trianglebase or triangleselect benefits
 * @param { SkuCode } sku selected sku
 * @param {ProductLevel} defaultSelect default select
 * @returns {TriangleBenefitList | undefined} return triangle Benefit
 */
const getTriangleBenefits = (sku: SkuCode, defaultSelect?: ProductLevel): TriangleBenefitList | undefined => {
    const selectedSKU = sku?.list?.find(skuData => skuData?.code === sku?.code)
    return defaultSelect?.isAllVariantSelected ? selectedSKU : defaultSelect?.data
}

/**
 * Function to calculate RewardsBaseLoyaltyValue
 * @param { RewardsVal } info card details
 * @returns {number} return RewardsBaseLoyaltyValue
 */
export const getRewardsBaseLoyaltyValue = (info: RewardsVal): number => {
    const { skuList, productLevel, selectedQty = 0 } = info || {}
    const selectedSKU = getTriangleBenefits(skuList, productLevel)
    if (selectedSKU?.triangleBenefits?.tsRewardsBaseLoyaltyValue) {
        return selectedSKU.triangleBenefits.tsRewardsBaseLoyaltyValue * selectedQty
    }
    return 0
}

/**
 * Function to calculate triangle Select Benefits or mastercard reward value
 * @param {RewardsVal} info card details
 * @param {boolean} isLoyaltyUser should set card for loyalty user
 * @returns {BenefitsValue} return triangle Select Benefits or mastercard reward value object
 */
export const getTriangleBenefitsValue = (info: RewardsVal, isLoyaltyUser?: boolean): BenefitsValue => {
    let benefitValue: BenefitsValue = {
        masterCardValue: 0,
        selectCardValue: 0,
    }
    const { selectedPtE, selectedQty = 0, skuList, productLevel } = info || {}
    const selectedSKU = getTriangleBenefits(skuList, productLevel)
    const { triangleBenefits, triangleSelectBenefits } = selectedSKU || {}
    const { tsTriangleMastercardBenefitsValue = 0 } = triangleBenefits || {}
    const { tsEverydayDiscountValue = 0, tsBrandDiscountValue = 0, tsBrand } = triangleSelectBenefits || {}

    if (
        isAtleastOneParamValid(selectedPtE?.isTriangleMasterCard as boolean, isLoyaltyUser as boolean) &&
        tsTriangleMastercardBenefitsValue
    ) {
        benefitValue = {
            ...benefitValue,
            masterCardValue: Number(tsTriangleMastercardBenefitsValue) * selectedQty,
        }
    }
    if (
        isAtleastOneParamValid(selectedPtE?.isTriangleSelect as boolean, isLoyaltyUser as boolean) &&
        tsEverydayDiscountValue
    ) {
        const everydayDiscount = Number(tsEverydayDiscountValue)
        const brandDiscount = tsBrand ? Number(tsBrandDiscountValue) : 0
        benefitValue = {
            ...benefitValue,
            selectCardValue: (everydayDiscount + brandDiscount) * selectedQty,
        }
    }
    return benefitValue
}

/**
 * Function to calculate total reward value of triangle Select Benefits and mastercard reward value
 * @param {RewardsVal} info card details
 * @returns {number} return total reward value of triangle Select Benefits and mastercard reward value
 */
export const getTriangleMCAndSelectTotal = (info: RewardsVal): number => {
    const triangleMC = info?.selectedPtE?.isTriangleMasterCard ? getTriangleBenefitsValue(info).masterCardValue : 0
    const triangleSelect = info?.selectedPtE?.isTriangleSelect ? getTriangleBenefitsValue(info).selectCardValue : 0
    return Number(triangleMC + triangleSelect)
}

/**
 * Function to getdate difference lable for offers
 * @param { string } offerEndsTodayLabel offer end today label
 * @param {string} offerEndsLabel offer end in [0] days label
 * @param {string} endDate offer end date
 * @returns {string} return offer date label
 */
export const getOfferDateLabel = (offerEndsTodayLabel: string, offerEndsLabel: string, endDate: string): string => {
    return getDaysDifference(endDate) === String(0)
        ? offerEndsTodayLabel
        : `${replaceStrWithDynamicVal(offerEndsLabel, getDaysDifference(endDate))}`
}

/**
 * Function to return description with a11y
 * @param { string } pteTriangleEarnCTMoneyText
 * @param {string} pteTriangleEarnCTMoneyA11yText
 * @returns {string} getA11yPteTriangleEarnCTMoneyText return rich text of combination along with a11y
 */
export const getA11yPteTriangleEarnCTMoneyText = (
    pteTriangleEarnCTMoneyText: string,
    pteTriangleEarnCTMoneyA11yText: string,
): string => {
    return `<span aria-hidden='true'>${pteTriangleEarnCTMoneyText}</span><span class='sr-only'>${pteTriangleEarnCTMoneyA11yText}</span>`
}

/**
 * Function to set Loyalty id to local storage
 * @param {UserProfileData} profileData user profile data from API
 * @returns {void} return nothing
 */
export const setLoyaltyId = (profileData: UserProfileData): void => {
    const loyaltyId = profileData?.loyalty?.id ?? profileData?.id
    if (loyaltyId) {
        const isLoyaltyIdExist = appCacheService.lmsId.get()
        !isLoyaltyIdExist && appCacheService.lmsId.set(loyaltyId)
    }
}

/**
 * function to render offers list
 * @param {string} bannerId selected banner
 * @param {CoachMarkOffer} swapOffersTourBannerList selected banner List
 * @returns {CoachMarkOfferCard} sorted list based on isSwapOffer flag
 */
export const templateOffersByBannerId = (
    bannerId: string,
    swapOffersTourBannerList: CoachMarkOffer[],
): CoachMarkOfferCard[] => {
    const result = swapOffersTourBannerList?.find(item => item.bannerId === bannerId)
    return result ? result.swapOffersTourList.sort((a, b) => Number(a.isSwapOffer) - Number(b.isSwapOffer)) : []
}
