import { magicNumber } from './magicNumber'
import { dateOptions, daysCalculationConstants, monthList } from '../globalConstants/global.constant'

/**
 * Is used to get date from "MMDDYYYY" string
 * @param {String} dateString - date in format "MMDDYYYY"
 * @param {Number} daysToAdd - add days to date if needed
 * @return {Date} - Date recognizable string format (ISO string in this case)
 */
// eslint-disable-next-line default-param-last
export const convertEpsilonDate = (dateString = '', daysToAdd?: number): Date => {
    const month = dateString.slice(0, magicNumber.TWO)
    const day = dateString.slice(magicNumber.TWO, magicNumber.FOUR)
    const year = dateString.slice(magicNumber.FOUR)
    // -1 for month is needed because backend returns month in format of a number from 1 to 12 instead of 0 to 11
    return new Date(Date.UTC(Number(year), Number(month) - magicNumber.ONE, Number(day) + (daysToAdd || 0)))
}

/**
 * Returns array of 31 days in dropdownList format
 * @return {Array}
 */
export const maxDaysInMonth = (): {
    label: string
    id: string
}[] => {
    return Array.from(new Array(magicNumber.THIRTYONE), (_, index) =>
        // eslint-disable-next-line sonar/no-nested-incdec
        (++index).toString().padStart(magicNumber.TWO, '0'),
    ).map(day => {
        return { label: `${day}`, id: `${day}` }
    })
}

/**
 * Is used to get list of months in dropdownList format - { 'label': '01', 'id':'01 }
 * @return {Array}
 */
export const getMonthList = (): {
    label: string
    id: string
}[] => {
    return monthList.map(month => {
        return { label: `${month.label}`, id: `${month.value}` }
    })
}

/**
 * @method isDateValid : Validates if date exists
 * @param {string} selectedYear
 * @param {string} selectedMonth
 * @param {string} selectedDay
 * @returns {boolean}
 */

export const isDateValid = (selectedYear: string, selectedMonth: string, selectedDay: string): boolean => {
    const year = Number(selectedYear)
    const month = Number(selectedMonth) - magicNumber.ONE
    const day = Number(selectedDay)
    const date = new Date(year, month, day)
    return !(date.getMonth() !== month || date.getDate() !== day || date.getFullYear() !== year)
}

export const getDateOptions = () => dateOptions

/**
 * Function to check if date is between 2 specific dates
 * @param {Date} fromDate - from date
 * @param {Date} toDate - to date
 * @param {Date} currentDate - current date
 * @return {boolean}
 */
export const validateDateBetweenTwoDates = (
    fromDate: number | string | Date,
    toDate: number | string | Date,
    currentDate: number | string | Date,
): boolean => {
    return new Date(currentDate) >= new Date(fromDate) && new Date(currentDate) <= new Date(toDate)
}

/**
 * Function to get Days Difference
 * @param {string} timeStamp
 * @returns {string}
 */
export const getDaysDifference = (timeStamp: string): string => {
    const dateObj = new Date(timeStamp)
    const todayDate = new Date()
    const differenceInTime = dateObj.getTime() - todayDate.getTime()
    const { thousand, totalSeconds, totalHours } = daysCalculationConstants
    const differenceInDays = Math.floor(differenceInTime / (thousand * totalSeconds * totalHours))
    const resultDifference = differenceInDays < 0 ? 0 : differenceInDays
    return timeStamp ? resultDifference.toString() : ''
}
