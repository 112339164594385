import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { BadgesProps, priorityAndColourProps } from './Badges.type'
import Tag from '../Tag'
import { getMaxPrioritizedBadges, updateDynamicBadges } from './badgeService'
import { PREFIX } from '../config'
import { sanitizeStringContent } from '../../utils/sanitizeStringContent'
import { renderSaleEndDaySoonMessageComp } from './Badges.helper'

/**
 * Badges component
 * @param {BadgesProps} props
 * @returns {JSX.Element} returns badges component
 */
const Badges: React.FC<BadgesProps> = ({ ...props }) => {
    const {
        badges,
        badgesAndPriorities,
        hideDisclaimer = false,
        clickable = false,
        saleEndDaySoonMessage = null,
        salePriceEndDate = null,
        isShoppingCart = false,
        analyticsLabelValue = null,
        saleEndDisableShift = false,
        hideSaleEndDaySoonMessage,
        isOnSale = false,
        isUrgentLowStock = false,
        loyaltyValue,
    } = props

    const [badgesToShow, setBadgesToShow] = useState<priorityAndColourProps[]>([])

    /**
     * set capped badges max number as per priorities
     */
    useEffect(() => {
        if (badges && badges.length) {
            const { maxNumberToDisplay, priorityAndColour = [], enableRewardValBadge } = badgesAndPriorities || {}
            const { updatedBadges, updatedpriorityAndColour } = updateDynamicBadges(
                badges,
                priorityAndColour,
                enableRewardValBadge,
                loyaltyValue,
            )
            setBadgesToShow(getMaxPrioritizedBadges(updatedBadges, updatedpriorityAndColour, maxNumberToDisplay))
        } else {
            setBadgesToShow([])
        }
    }, [badges, badgesAndPriorities, loyaltyValue])

    const badgeWithDisclaimer = badgesToShow.filter(badge => badge.disclaimer)[0]

    return (
        <>
            {badgesToShow.map(badge => (
                <Tag
                    type="product"
                    subType={badge.colorId ? `product--${badge.colorId}` : ''}
                    key={badge.badgeId}
                    withBorder={badge.colorId === 'tested'}
                    badgeUrl={clickable ? badge.badgeUrl : null}
                    analyticsLabelValue={
                        analyticsLabelValue?.[badge.badgeId as keyof typeof analyticsLabelValue] ?? ''
                    }>
                    {badge.badgeLabel}
                </Tag>
            ))}
            {!isOnSale &&
                renderSaleEndDaySoonMessageComp(
                    salePriceEndDate,
                    isOnSale,
                    isUrgentLowStock,
                    isShoppingCart,
                    hideSaleEndDaySoonMessage,
                    saleEndDaySoonMessage,
                    saleEndDisableShift,
                )}
            {!hideDisclaimer && badgeWithDisclaimer && (
                <div
                    className={`${PREFIX}-badge-disclaimer`}
                    dangerouslySetInnerHTML={{ __html: sanitizeStringContent(badgeWithDisclaimer.disclaimer) }}></div>
            )}
        </>
    )
}

Badges.propTypes = {
    badges: PropTypes.arrayOf(PropTypes.string).isRequired,
    badgesAndPriorities: PropTypes.any,
    hideDisclaimer: PropTypes.bool,
    clickable: PropTypes.bool,
    saleEndDaySoonMessage: PropTypes.string,
    salePriceEndDate: PropTypes.string,
    isShoppingCart: PropTypes.bool,
    analyticsLabelValue: PropTypes.any,
    saleEndDisableShift: PropTypes.bool,
}

export default Badges
