import React, { useCallback, useEffect, useRef, useState, Validator } from 'react'
import PropTypes from 'prop-types'

import { BREAKPOINTS, PREFIX } from '../config'
import { Store, HeaderStoreLocatorProps, SortedStoreDataType } from './HeaderStoreLocator.type'
import Icon from '../Icon'
import { isArrayNotEmpty } from '../../utils/isArrayNotEmpty'
import { noOfOtherStoresToShow } from './HeaderStoreLocator.constant'
import {
    generateGoogleMapOnAddress,
    useGlobalResizeEvent,
    getFormattedPhoneNumber,
    replaceMultipleStrWithDynamicVal,
    replaceStrWithDynamicVal,
} from '../../utils'
import { useClickOutsideClose } from '../Tooltip/useClickOutside'
import { calculateHeaderHeightWithStickyStoreLocator } from '../StickyTOC/CalculateHeaderHeight'
import GoogleAutocomplete from '../GoogleAutocomplete'
import Toast from '../Toast'
import { isAutoServiceAvailableInStore } from '../../helpers/Store.helper'
import { getNextDayStoreTimings, getStoreTimings } from '../StoreSelectorModal/StoreSelectorHelper'
import { StoreWithAvailability, WeekDayOpeningList } from '../StoreSelectorModal/StoreSelectorModal.type'
import { enableDestructOnUndefinedData } from '../../utils/enableDestructOnUndefinedData'
import SanitizeStringContentWrapper from '../../utils/sanitizeStringContent'
import { StoreGeoPoint } from '../Map/Map.type'

/**
 * Store Locator component
 * @param {HeaderStoreLocatorProps} props
 * @return {JSX.Element} returns Icon , storeLocatorList components
 */
const HeaderStoreLocator: React.FC<HeaderStoreLocatorProps> = props => {
    const {
        path,
        openLabel,
        opensLabel,
        closedLabel,
        closesLabel,
        a11yHeaderStorePreferred,
        a11yHeaderStoreCurrently,
        a11yHeaderStoreAt,
        preferredStoreId,
        onlineOrdersNotAcceptedMsg,
        storeLocatorList,
        fetchNearbyStoreListOnSearch,
        fetchStoreListOnLoad,
        inputMaxLength,
        viewMoreAnalytics,
        mapIconOnClickHandler,
        windowRef,
        setPreferredStore,
        searchStoreAnalyticsHandler,
        storeSuccessfullySelectedMsg,
        renderAutoAppointmentButton,
        baseStoreId,
        storeAPITriangulationFail,
        forcePreferredStoreMsg,
        selectAStoreLabel,
        storeLocationLabel,
        currentUserLocation,
        sideNavigationIsOpen,
        dispatchStoreLocatorPopupState,
        maxNumberOfStores,
    } = props

    const [showFlyOut, setShowFlyOut] = useState(false)
    const [selectedStore, setSelectedStore] = useState<Store>(null)
    const [sortedStoreData, setSortedStoreData] = useState({
        preferredStore: {} as Store,
        otherStores: [] as Store[],
    })
    const [isFlyoutAlreadyOpen, setIsFlyoutAlreadyOpen] = useState(false)

    const flyoutRef = useRef<HTMLDivElement>(null)

    const componentName = `${PREFIX}-store-locator`

    const {
        setPreferredStoreCTALabel,
        preferredStoreLabel,
        searchInputPH,
        a11ySearchIconAriaLabel,
        a11yCurrentLocationCTAAriaLabel,
        seeMoreCTALabel,
        seeMoreCTAURL,
        closeLabel,
        searchInputErrorMsg,
    } = props.storeLocatorGlobalPropData || {}

    const [isMobile, setIsMobile] = useState(window.innerWidth <= BREAKPOINTS.mobileMaxWidth)
    const handleResize = () => {
        setIsMobile(window.innerWidth <= BREAKPOINTS.mobileMaxWidth)
    }

    useGlobalResizeEvent(handleResize)

    const [isPreferredStoreChanged, setIsPreferredStoreChanged] = useState(false)
    const [showSuccess, setShowSucces] = useState(false)
    const [showToast, setShowToast] = useState(false)
    const weekdayHours = selectedStore && getStoreTimings(selectedStore as unknown as StoreWithAvailability)
    const nextDayHours = selectedStore && getNextDayStoreTimings(selectedStore as unknown as StoreWithAvailability)
    const { closingTime, closed } = enableDestructOnUndefinedData(weekdayHours) as WeekDayOpeningList
    const { openingTime } = enableDestructOnUndefinedData(nextDayHours) as WeekDayOpeningList
    const timeText = closed
        ? `⋅ ${opensLabel} ${' '} ${openingTime?.formattedHour}`
        : `⋅ ${closesLabel} ${' '} ${closingTime?.formattedHour}`
    const storeTimingsPresent = closed ? openingTime?.formattedHour : closingTime?.formattedHour
    /**
     * sets hide Flyout as false to show the Store Locator Flyout
     * @param {boolean} closeFlyout
     */
    const hideStoreLocatorFlyOut = useCallback(
        (closeFlyout?: boolean): void => {
            dispatchStoreLocatorPopupState(false)

            if (!isFlyoutAlreadyOpen || closeFlyout) {
                setShowFlyOut(false)
            }
        },
        [dispatchStoreLocatorPopupState, isFlyoutAlreadyOpen],
    )
    /**
     * Function to check if no preferred store in localstorage and stores api fails with error code "00114"
     * @return {boolean}
     */
    const checkIfStoresApiFails = (): boolean => {
        return !props.preferredStoreDetails?.id && !!storeAPITriangulationFail
    }
    const isStoresApiFailed = checkIfStoresApiFails()

    // useEffect to open header store locator flyout if there is no prefered store id in localstorage & if stores api fails with error code "00114"
    useEffect(() => {
        if (isStoresApiFailed) {
            setShowFlyOut(true)
        }
    }, [isStoresApiFailed])

    useEffect(() => {
        if (sideNavigationIsOpen) {
            hideStoreLocatorFlyOut(true)
        }
    }, [sideNavigationIsOpen, hideStoreLocatorFlyOut])

    /**
     * Return Store Locator Ally Label
     * @return {string}
     */
    const getAllyStoreLocatorLabel = (): string => {
        const a11yHeaderStorePreferredLabel = replaceStrWithDynamicVal(a11yHeaderStorePreferred, selectedStore?.name)
        const a11yHeaderStoreCurrentlyLabel = replaceStrWithDynamicVal(
            a11yHeaderStoreCurrently,
            closed ? closedLabel : openLabel,
        )
        const a11yHeaderStoreAtLabel = replaceMultipleStrWithDynamicVal(a11yHeaderStoreAt, [
            closed ? opensLabel : closesLabel,
            closed ? openingTime?.formattedHour : closingTime?.formattedHour,
        ])
        return `${a11yHeaderStorePreferredLabel}, ${a11yHeaderStoreCurrentlyLabel}, ${a11yHeaderStoreAtLabel}`
    }

    /**
     * sets setShowFlyOut as true to show the Store Locator Flyout
     */
    const showStoreLocatorFlyOut = (): void => {
        dispatchStoreLocatorPopupState(true)
        setShowFlyOut(true)
        setIsFlyoutAlreadyOpen(true)
        if (!isArrayNotEmpty(storeLocatorList) || storeLocatorList.length < maxNumberOfStores) {
            if (currentUserLocation) {
                const { latitude, longitude } = currentUserLocation
                fetchNearbyStoreListOnSearch(latitude, longitude)
            } else {
                fetchStoreListOnLoad()
            }
        }
    }

    /**
     * Function to separate preferred store and the other stores.
     *
     * @param {Store[]} storeList - Nearby store list,
     * @param {Store} preferredStore
     * @return {SortedStoreDataType} Sorted array i.e preferred store and the other stores.
     */
    const sortStoreDataList = useCallback(
        (storeList: Store[], preferredStore: Store): SortedStoreDataType => {
            // eslint-disable-next-line consistent-return
            const excludePreferredStoreFromList = storeList?.filter((storeItem: Store) => {
                if (storeItem?.id?.toString() === preferredStoreId) {
                    if (isPreferredStoreChanged && isMobile) {
                        hideStoreLocatorFlyOut(true)
                        setShowSucces(true)
                        setShowToast(true)
                    }
                } else {
                    return storeItem
                }
            })

            return {
                preferredStore: preferredStore,
                otherStores: excludePreferredStoreFromList?.slice(0, noOfOtherStoresToShow),
            }
        },
        [hideStoreLocatorFlyOut, isMobile, isPreferredStoreChanged, preferredStoreId],
    )

    const displayToast = (): JSX.Element => {
        const options = {
            toastSuccessMessage: storeSuccessfullySelectedMsg,
            toastSuccessIcon: 'ct-notification-success-green',
            toastErrorIcon: 'ct-notification-caution',
            toastCloseLabel: 'Close',
        }
        return showToast && options.toastSuccessMessage ? (
            <Toast
                success={showSuccess}
                failed={!showSuccess}
                options={options}
                resetPageVariables={() => setShowToast(false)}
                toastCloseFunction={() => {
                    setShowToast(false)
                }}
                hideCTA={showSuccess}
                enableTimer={true}
            />
        ) : (
            <></>
        )
    }

    const handlePreferredStoreChange = store => {
        setPreferredStore(store, setSortedStoreData, sortStoreDataList)
        setIsPreferredStoreChanged(true)
        hideStoreLocatorFlyOut(true)
    }

    const calculateTopPosition = (): number => {
        return calculateHeaderHeightWithStickyStoreLocator()
    }

    useEffect(() => {
        setSelectedStore(props.preferredStoreDetails)
        setSortedStoreData(sortStoreDataList(storeLocatorList, props.preferredStoreDetails))
    }, [sortStoreDataList, preferredStoreId, storeLocatorList, props.preferredStoreDetails])

    // for outside click on header store locator flyout.
    useClickOutsideClose(
        flyoutRef,
        () => hideStoreLocatorFlyOut(true),
        showFlyOut,
        !storeAPITriangulationFail && !isMobile,
    )

    /**
     * Render address string
     * @param {string} line1
     * @param {string} line2
     * @param {string} town
     * @param {string} regionName
     * @param {string} postalCode
     *
     * @returns {string} returns address string
     */
    const formatAddress = (
        line1: string,
        line2: string,
        town: string,
        regionName: string,
        postalCode: string,
    ): string => {
        // eslint-disable-next-line sonarjs/no-nested-template-literals
        return `${line1 ?? ''} ${line2 ?? ''}, ${town ? `${town},` : ''} ${regionName ? `${regionName},` : ''} ${
            postalCode ?? ''
        }`
    }

    /**
     * Render single store.
     * @param {boolean} showCTA - true : button, false: phone number.
     * @param {Store} store - single store data.
     * @param {number} index
     *
     * @return {JSX.Element} returns single store UI.
     */
    const renderStoreDetails = (showCTA: boolean, store: Store, index?: number): JSX.Element => {
        const {
            id,
            name,
            address: { phone, line1, line2, town, region, postalCode },
            onlineOrdering,
            url,
        } = store

        const storeLocatorListClassName = `${componentName}__list`
        const preferredStoreClass = showCTA
            ? `${storeLocatorListClassName}__store--normal`
            : `${storeLocatorListClassName}__store--preferred`

        return (
            <div className={`${storeLocatorListClassName} ${preferredStoreClass}`} key={id}>
                {/* Open store detail page with id when clicked on store name */}
                <a className={`${storeLocatorListClassName}-item__name`} href={`${url}`} data-link-value={name}>
                    {name}
                </a>
                <SanitizeStringContentWrapper
                    stringContent={generateGoogleMapOnAddress(`${line1 ?? ''} ${line2 ?? ''},${postalCode ?? ''}`)}>
                    {memoizedStringContent => (
                        <a
                            className={`${storeLocatorListClassName}-item__address__line`}
                            target="_blank"
                            rel="noreferrer"
                            href={memoizedStringContent}>
                            {formatAddress(line1, line2, town, region?.name, postalCode)}
                        </a>
                    )}
                </SanitizeStringContentWrapper>
                {!onlineOrdering && (
                    <div className={`${storeLocatorListClassName}__store--status`}>
                        <div>
                            <Icon type="ct-notification-caution-stroked" size="md" />
                        </div>
                        <span className={`${storeLocatorListClassName}__store--status-message`}>
                            {onlineOrdersNotAcceptedMsg}
                        </span>
                    </div>
                )}
                {showCTA ? (
                    baseStoreId &&
                    baseStoreId === store.baseStoreId && (
                        <div>
                            <button
                                className={`${storeLocatorListClassName}-item__other-store`}
                                aria-label={setPreferredStoreCTALabel}
                                onClick={() => handlePreferredStoreChange(store)}
                                data-testid={`store-locator-preferred${index}`}>
                                {setPreferredStoreCTALabel}
                            </button>
                        </div>
                    )
                ) : (
                    <div className={`${storeLocatorListClassName}-item__number`}>
                        <SanitizeStringContentWrapper stringContent={phone}>
                            {memoizedStringContent => (
                                <a href={`tel:${memoizedStringContent}`} data-link-value={phone}>
                                    {getFormattedPhoneNumber(phone)}
                                </a>
                            )}
                        </SanitizeStringContentWrapper>
                    </div>
                )}
            </div>
        )
    }

    /**
     * Render store flyout title.
     *
     * @return {JSX.Element} returns store flyout title.
     */
    const renderStoreFlyoutTitle = (): JSX.Element => {
        return (
            <div className={`${componentName}__fly-out__mobile-close-bar__store_flyout_title`}>
                {isMobile && !isStoresApiFailed ? storeLocationLabel : selectAStoreLabel}
            </div>
        )
    }

    const onFocusHandler = (event: React.FocusEvent<HTMLInputElement>) => {
        if (flyoutRef && flyoutRef.current && isMobile) {
            flyoutRef.current.style.top = '0px'
            flyoutRef.current.style.height = '100vh'
            event.currentTarget.classList.add(`${PREFIX}-textinput__input_outline`)
        }
    }

    /**
     * Render store Locator Dropdown
     * @return {JSX.Element}
     */
    // eslint-disable-next-line complexity, sonar/cyclomatic-complexity
    const StoreLocatorList = (): JSX.Element => {
        const [isInputInValid, setIsInputInValid] = useState(false)

        const googleAutoCompleteProps = {
            a11ySearchIconAriaLabel,
            searchInputPH,
            fetchNearbyStoreListOnSearch,
            inputMaxLength,
            setIsInputInValid,
            windowRef,
            searchStoreAnalyticsHandler,
            onFocusHandler,
        }

        /**
         * Invalid postal code or city warning message component
         * @return {JSX.Element | null} returns invalid postal code or city warning message component
         */
        // re using the store selector flyout warning message.
        const renderInvalidAddressWarningMessage = (): JSX.Element | null => {
            return isInputInValid ? (
                <div className={`${PREFIX}-store-selector-flyout__error-message`}>
                    <Icon type="ct-warning" size="lg"></Icon>
                    <p
                        className={`${PREFIX}-body-sm ${PREFIX}-store-selector-flyout__error-message__text`}
                        role="alert"
                        aria-atomic="true">
                        {searchInputErrorMsg}
                    </p>
                </div>
            ) : null
        }

        /**
         * Below use effect to stop background scrolling when the flyout is open
         */
        // eslint-disable-next-line consistent-return
        useEffect(() => {
            // Add overflow hidden only in the mobile.
            if (isMobile) {
                const overFlowHiddenClass = 'modal-open-not-scroll'
                document.body.classList.add(overFlowHiddenClass)
                return () => {
                    document.body.classList.remove(overFlowHiddenClass)
                }
            }
        }, [])

        /**
         * Render store flyout title.
         * @return {JSX.Element} returns store flyout title.
         */
        const showPreferredStoreErrorMsg = (): JSX.Element | null => {
            return !!isStoresApiFailed ? (
                <div className={`${componentName}__fly-out__error`}>
                    <Icon type="ct-warning" size="md" path={path}></Icon>
                    <span className={`${componentName}__fly-out__error__description`}>{forcePreferredStoreMsg}</span>
                </div>
            ) : null
        }

        const hasInfoBanner = document.querySelector(`.${PREFIX}-information-banner`) !== null
        const mobileWithBanner = hasInfoBanner && isMobile ? `${componentName}__fly-out--with-banner` : ''
        const mobileWExpanded = isMobile ? `${componentName}__fly-out--expanded` : ''
        const errorClass = storeAPITriangulationFail ? `${componentName}__search-error` : ''
        const showSearchCloseBar = (!isMobile && !!isStoresApiFailed) || isMobile

        return (
            <>
                {!!isStoresApiFailed && <div className="nl-overlay"></div>}
                <div
                    className={`${componentName}__fly-out ${mobileWithBanner} ${mobileWExpanded}`}
                    ref={flyoutRef}
                    id="headerStoreLocatorFlyout"
                    style={mobileWExpanded ? { top: `${calculateTopPosition()}px` } : {}}>
                    {showSearchCloseBar && (
                        <div className={`${componentName}__fly-out__mobile-close-bar`}>
                            {renderStoreFlyoutTitle()}
                            {!isStoresApiFailed && (
                                <button
                                    className={`${componentName}__fly-out-close--mobile`}
                                    aria-label={closeLabel}
                                    onClick={() => hideStoreLocatorFlyOut(true)}
                                    data-testid="store-locator-close-mobile">
                                    <Icon type="ct-close" size="md" path={path} />
                                </button>
                            )}
                        </div>
                    )}
                    <div className={`${componentName}__fly-out__content`}>
                        {!storeAPITriangulationFail && (
                            <div className={`${componentName}__preferred-store`}>
                                <div className={`${componentName}__fly-out-row`}>
                                    <div className={`${componentName}__fly-out-row__title-section`}>
                                        <Icon type="ct-star" size="md" path={path} />
                                        <span className={`${componentName}__preferred-store-title`}>
                                            {preferredStoreLabel}
                                        </span>
                                    </div>
                                    <button
                                        className={`${componentName}__fly-out-close--desktop`}
                                        aria-label={closeLabel}
                                        onClick={() => hideStoreLocatorFlyOut(true)}
                                        data-testid="store-locator-close">
                                        <Icon type="ct-close" size="md" path={path} />
                                    </button>
                                </div>
                                {sortedStoreData.preferredStore &&
                                    Object.keys(sortedStoreData.preferredStore).length > 0 &&
                                    renderStoreDetails(false, sortedStoreData.preferredStore)}
                                {isAutoServiceAvailableInStore(sortedStoreData.preferredStore) &&
                                    renderAutoAppointmentButton()}
                            </div>
                        )}
                        <div className={`${componentName}__store-list ${errorClass}`}>
                            <div className={`${componentName}__search-bar-section`}>
                                <GoogleAutocomplete {...googleAutoCompleteProps} />
                                {!isStoresApiFailed && (
                                    <button
                                        className={`${componentName}__search-bar-section__map-icon`}
                                        onClick={() => mapIconOnClickHandler(true)}
                                        aria-label={a11yCurrentLocationCTAAriaLabel}>
                                        <Icon type="ct-location" size="md" path={path} />
                                    </button>
                                )}
                            </div>
                            {renderInvalidAddressWarningMessage()}
                            {showPreferredStoreErrorMsg()}
                            {isArrayNotEmpty(sortedStoreData.otherStores) &&
                                sortedStoreData.otherStores.map((store: Store, index: number) =>
                                    renderStoreDetails(true, store, index),
                                )}
                        </div>
                        {isArrayNotEmpty(sortedStoreData.otherStores) && !isStoresApiFailed && (
                            <div className={`${componentName}__see-more`}>
                                <a
                                    href={seeMoreCTAURL}
                                    onClick={() => viewMoreAnalytics(seeMoreCTALabel)}
                                    data-link-value={seeMoreCTALabel}>
                                    {seeMoreCTALabel}
                                </a>
                                <Icon path={path} size="md" type="ct-chevron-right" />
                            </div>
                        )}
                    </div>
                </div>
            </>
        )
    }

    return (
        <div className={`${PREFIX}-store-locator`}>
            <button
                dap-wac-link-section="top-nav"
                dap-wac-value="Store Locator"
                className={`${PREFIX}-store-locator__icon ${PREFIX}-md-none`}
                aria-label="Store Locator Icon"
                onClick={showStoreLocatorFlyOut}
                data-testid="header-store-locator-icon">
                <Icon type="ct-location" size="lg" path={path} />
            </button>
            <div className={`${PREFIX}-store-locator__row`} data-testid="header-store-locator-section">
                <div className={`${PREFIX}-store-locator--section`}>
                    <button
                        dap-wac-link-section="top-nav"
                        dap-wac-value="Store Locator"
                        className={`${PREFIX}-store-locator--section-button`}
                        data-testid="header-store-locator-section-button"
                        onClick={showStoreLocatorFlyOut}>
                        <span className="sr-only">{getAllyStoreLocatorLabel()}</span>
                        <span
                            className={`${PREFIX}-store-locator__icon 
                            ${PREFIX}-store-locator__icon_md ${PREFIX}-md-inline`}>
                            <Icon type="ct-location" size="lg" path={path} />
                        </span>
                        <div className={`${PREFIX}-store-locator--section-name`}>
                            <span aria-hidden="true" className={`${PREFIX}-store-locator--selected-store`}>
                                {selectedStore?.name}
                            </span>
                            {storeTimingsPresent && (
                                <div className={`${PREFIX}-store-locator--timing`}>
                                    <span aria-hidden="true">{closed ? closedLabel : openLabel}</span>
                                    <span aria-hidden="true" className={`${PREFIX}-store-locator--timing-end`}>
                                        {timeText}
                                    </span>
                                </div>
                            )}
                        </div>
                    </button>
                </div>
            </div>
            {showFlyOut && <StoreLocatorList />}
            {displayToast()}
        </div>
    )
}

HeaderStoreLocator.propTypes = {
    path: PropTypes.string,
    openLabel: PropTypes.string,
    opensLabel: PropTypes.string,
    closedLabel: PropTypes.string,
    closesLabel: PropTypes.string,
    a11yHeaderStorePreferred: PropTypes.string.isRequired,
    a11yHeaderStoreCurrently: PropTypes.string.isRequired,
    a11yHeaderStoreAt: PropTypes.string.isRequired,
    storeLocatorList: PropTypes.array,
    preferredStoreId: PropTypes.string,
    onlineOrdersNotAcceptedMsg: PropTypes.string.isRequired,
    storeLocatorGlobalPropData: PropTypes.any,
    fetchNearbyStoreListOnSearch: PropTypes.func.isRequired,
    fetchStoreListOnLoad: PropTypes.func.isRequired,
    inputMaxLength: PropTypes.number.isRequired,
    viewMoreAnalytics: PropTypes.func,
    mapIconOnClickHandler: PropTypes.func.isRequired,
    windowRef: PropTypes.object.isRequired as Validator<typeof window>,
    setPreferredStore: PropTypes.func.isRequired,
    searchStoreAnalyticsHandler: PropTypes.func.isRequired,
    storeSuccessfullySelectedMsg: PropTypes.string.isRequired,
    renderAutoAppointmentButton: PropTypes.func.isRequired,
    preferredStoreDetails: PropTypes.object.isRequired as Validator<Store>,
    currentUserLocation: PropTypes.object.isRequired as Validator<StoreGeoPoint>,
    baseStoreId: PropTypes.string,
    storeAPITriangulationFail: PropTypes.bool,
    forcePreferredStoreMsg: PropTypes.string,
    selectAStoreLabel: PropTypes.string,
    sideNavigationIsOpen: PropTypes.bool.isRequired,
    dispatchStoreLocatorPopupState: PropTypes.func.isRequired,
}

export default HeaderStoreLocator
