import React from 'react'
import { Url } from './MegaNavigation.type'
import getLanguage from '../../utils/getLanguage'
import { categoryURLIdentifier, megaNavIdentifier } from './MegaNavigation.constants'
import { FORWARD_SLASH } from '../../globalConstants/global.constant'
import SanitizeStringContentWrapper from '../../utils/sanitizeStringContent'
/**
 * function to create category shop all URL
 * @param {string | Url} categoryUrl
 * @param {string} shopAllPlaceholderUrl
 **/
export const createShopAllUrl = (categoryUrl: string | Url, shopAllPlaceholderUrl: string): string => {
    return categoryUrl
        .toString()
        .replace(
            `${FORWARD_SLASH}${language}${FORWARD_SLASH}cat${FORWARD_SLASH}`,
            `${FORWARD_SLASH}${language}${FORWARD_SLASH}${shopAllPlaceholderUrl}${FORWARD_SLASH}cat${FORWARD_SLASH}`,
        )
}

/**
 * function to create category shop all link for hamburger menu
 * @param {string} categoryId
 * @param {string | Url} categoryUrl
 * @param {string} shopAllPlaceholderUrl
 * @param {boolean} enableShopAllLabel
 * @param {string[]} shopAllExclusionIds
 * @param {string} shopAllLinkLabel
 * @param {string} ariaLabelShopAll
 * @param {string} shopAllLinkStyle
 * @returns {JSX.Element} return the shop all link if enableShopAllLabel is true and not part of exclusion Ids from AEM
 */
const language = getLanguage()
const currentPath = window.location.pathname

export const categoryShopAllLink = (
    categoryId: string,
    categoryUrl: string | Url,
    shopAllPlaceholderUrl: string,
    enableShopAllLabel: boolean,
    shopAllExclusionIds: string[],
    shopAllLinkLabel: string,
    ariaLabelShopAll: string,
    shopAllLinkStyle: string,
    // eslint-disable-next-line max-params
): JSX.Element | null => {
    const shopAllUrl = shopAllPlaceholderUrl && createShopAllUrl(categoryUrl, shopAllPlaceholderUrl)

    return enableShopAllLabel && !shopAllExclusionIds?.includes(categoryId) ? (
        <SanitizeStringContentWrapper stringContent={shopAllUrl}>
            {memoizedStringContent => (
                <a
                    className={shopAllLinkStyle}
                    aria-label={ariaLabelShopAll}
                    data-automationid={`${megaNavIdentifier}${categoryId}`}
                    href={memoizedStringContent}>
                    {shopAllLinkLabel}
                </a>
            )}
        </SanitizeStringContentWrapper>
    ) : null
}

export default categoryShopAllLink

/**
 * function to check if current path is L1 shop all page
 * @param {string} shopAllLabel
 * @returns {boolean} return true if L1 shop-all page
 */
export const isL1ShopAllPage = (shopAllLabel: string): boolean => {
    const L1shopAllRegExPattern = new RegExp(`${language}/${shopAllLabel}/${categoryURLIdentifier}/(?:(?!/).)+\.html$`)
    return L1shopAllRegExPattern.test(currentPath)
}

/**
 * function to create shop all link for L1 categories
 * @param {string} url
 * @param {string} shopAllLabel
 * @returns {string} return the newly created shop all L1 link
 */
export const createShopAllLink = (url: string, shopAllLabel: string): string => {
    const patternToBeReplaced = `${language}/`
    const patternReplacer = `${language}/${shopAllLabel}/`
    return isL1ShopAllPage(shopAllLabel) ? url.replace(patternToBeReplaced, patternReplacer) : url
}
