import { magicNumber } from './magicNumber'

/**
 * Starts a countdown timer that ticks every second until the end time is reached.
 * @param {number} endTime - The end time in milliseconds since the Unix epoch.
 * @param {(remainingTime: number) => void} timerTickCallback - Callback function to be called on each tick with the remaining time.
 * @param {() => void} timerEndCallback - Callback function to be called when the timer ends.
 * @returns {NodeJS.Timeout} The interval timer ID.
 */
export const countDownTimer = (
    endTime: number,
    timerTickCallback: (remainingTime: number) => void,
    timerEndCallback: () => void,
): NodeJS.Timeout => {
    let currentTime = new Date().getTime()
    let difference = endTime - currentTime

    const timer = setInterval(() => {
        currentTime = new Date().getTime()
        difference = endTime - currentTime

        if (difference > 0) {
            timerTickCallback(difference)
        } else {
            clearInterval(timer)
            timerEndCallback()
        }
    }, magicNumber.ONETHOUSAND)

    return timer
}

/**
 * Calculates the days, hours, minutes, and seconds left from a given time difference.
 * @param {number} difference - The time difference in milliseconds.
 * @returns {{ daysLeft: number, hoursLeft: number, minutesLeft: number, secondsLeft: number }} The calculated time left.
 */
export const calculateTimeLeft = (
    difference: number,
): { daysLeft: number; hoursLeft: number; minutesLeft: number; secondsLeft: number } => {
    const daysLeft = Math.floor(difference / magicNumber.MILLISINDAY)
    const hoursLeft = Math.floor((difference - daysLeft * magicNumber.MILLISINDAY) / magicNumber.MILLISINHOUR)
    const minutesLeft = Math.floor(
        (difference - daysLeft * magicNumber.MILLISINDAY - hoursLeft * magicNumber.MILLISINHOUR) /
            magicNumber.MILLISINMINUTE,
    )
    const secondsLeft = Math.floor(
        (difference -
            daysLeft * magicNumber.MILLISINDAY -
            hoursLeft * magicNumber.MILLISINHOUR -
            minutesLeft * magicNumber.MILLISINMINUTE) /
            magicNumber.ONETHOUSAND,
    )

    return { daysLeft, hoursLeft, minutesLeft, secondsLeft }
}
