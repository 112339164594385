import ReactDOM from 'react-dom/client'
import React from 'react'
import { AkamaiCarousel } from './Akamai.Carousel'
import { Akamai } from './Akamai.type'
import Spinner from '../../Spinner'
import { PREFIX } from '../../config'
import { AkamaiViewerExtended, TagImage, ViewerTriggerReturnFunc } from './Akamai.Viewer'
import {
    ALL_MEDIA_SELECTOR,
    ELEMENT_NOT_FOUND,
    ONE_ELEMENT_LENGTH,
    THUMBNAIL_MEDIA_SELECTOR,
    AKAMAI_IMAGE_LOAD_EVENT,
} from '../MediaGalleryViewer.constant'
import { WindowRefType } from '../MediaGalleryViewer.type'

/**
 * function allows modify Akamai library to add custom functionality
 * @param {Akamai} akamai third party library object
 * @return {Akamai} returns customized Akamai object
 */
export const customizeAkamai = (akamai: Akamai): Akamai => {
    const activeItemAttributes = akamai.Carousel.prototype._activeItemAttributes

    /**
     * set values to the image `src` and `srcset` attributes to display an image after thumbnail click or other slide change action
     * @param {AkamaiCarousel} this Typescript typing parameter that allows set type of the context
     */
    akamai.Carousel.prototype._activeItemAttributes = function (this: AkamaiCarousel) {
        activeItemAttributes.call(this)

        const $img = this._$el.find('.snapper_item').eq(this.getIndex()).find('img')

        if (!$img.attr('src')) {
            const src = $img.attr('data-src')
            const srcset = $img.attr('data-srcset')
            const alt = $img.attr('data-alt')

            $img.attr({ src, srcset, alt })

            $img.on('load', (): void => {
                const akamaiImageLoadEvent = new CustomEvent(AKAMAI_IMAGE_LOAD_EVENT)
                ;(window as WindowRefType).dispatchEvent(akamaiImageLoadEvent)
            })
        }
    }

    const magnifierRender = akamai.Magnifier.render

    /**
     * cut values from the image `src` and `srcset` attributes and paste them to `data` attributes to prevent images loading on render
     * @param {TagImage} imageData Image data
     * @param {Record<string, unknown>} options Magnifier options
     * @return {String} string representation (innerHTML) of the snapper-item content
     */
    akamai.Magnifier.render = function (imageData: TagImage, options: Record<string, unknown>) {
        const snapperItemInnerHtml = magnifierRender(imageData, options)

        if (!snapperItemInnerHtml.includes('data-src') && !imageData.isSingleImage) {
            return snapperItemInnerHtml
                .replace('src=', 'src="" data-src=')
                .replace('srcset=', 'srcset="" data-srcset=')
                .replace('alt=', 'alt="" data-alt=')
        }

        return snapperItemInnerHtml
    }

    const viewerTrigger = akamai.Viewer.prototype._trigger

    /**
     * add spinner that will be displayed during image loading, and will be removed on image `load` or `error` event
     * @param {string} eventName triggered event name
     * @return {function} function returned from Util.trigger
     */
    akamai.Viewer.prototype._trigger = function (eventName: string): ViewerTriggerReturnFunc {
        const spinnerContainerClass = `${PREFIX}-spinner-container`
        const addedSpinners = document.querySelectorAll(`.${spinnerContainerClass}`)

        if (!addedSpinners.length) {
            const snapperImgs = document.querySelectorAll('.snapper_item img')

            snapperImgs.forEach(img => {
                if (img.getAttribute('src')) return

                const spinnerContainer = document.createElement('div')
                spinnerContainer.className = spinnerContainerClass

                const root = ReactDOM.createRoot(spinnerContainer)
                root.render(<Spinner />)

                img.before(spinnerContainer)

                img.addEventListener('load', function () {
                    spinnerContainer.remove()
                })

                img.addEventListener('error', function (event: Event) {
                    const srcValue = (event.currentTarget as Element).getAttribute('src')

                    if (srcValue) spinnerContainer.remove()
                })
            })
        }

        return viewerTrigger.call(this, eventName) as ViewerTriggerReturnFunc
    }

    /**
     * This snippet is a part of the original akamai-viewer package (akamai-viewer.unmin.js).
     * The only change is an update of the selector `'img, video' > '.snapper_nav img, video'`, that allows select only thumbnail images.
     * @param {AkamaiViewerExtended} this Typescript typing parameter that allows set type of the context
     * @param {string} tag value of the selected tag
     */
    akamai.Viewer.prototype.switchTag = function (this: AkamaiViewerExtended, tag) {
        const tags = this.getTags()
        const tagEscapedValue = akamai.Util.escapeJSONVals(tag)

        // if the passed tag isn't in the data set return early and log an error in
        // the console
        if (tags.indexOf(tagEscapedValue) === ELEMENT_NOT_FOUND) {
            akamai.Util.log('tag: ' + tag + ' is not present in the data for this viewer', 'error')
            return
        }

        const selector = `[${this.constructor._tagAttr}="${tag}"]`

        // try to find an existing element with the tag
        const $taggedViewer = this._$el.find(selector)

        // if there's no element append the newly rendered tag markup
        if (!$taggedViewer.length) {
            const $currViewer = this._$el.find(`[${this.constructor._tagAttr}]`)
            // make sure the config takes into account the current breakpoint
            const options = this._resolveBreakpointOptions()
            const carouselOptions = akamai.Viewer._extendCarouselOptions(options)

            const tagImages = this._tagMapping[tagEscapedValue]
            const tagSingleImage = tagImages.length === ONE_ELEMENT_LENGTH

            if (tagSingleImage) {
                tagImages[0].isSingleImage = true
            }

            // create the markup that will be inserted
            const $markup = (window as WindowRefType).$(this.constructor._renderTag(tagImages, carouselOptions, true))
            const mediaSelector = tagSingleImage ? ALL_MEDIA_SELECTOR : THUMBNAIL_MEDIA_SELECTOR
            const $loadables = $markup.find(mediaSelector)
            const loadableCount = $loadables.length
            let loaded = 0

            // hide the new carousel initially
            $markup.css('display', 'none')

            $loadables.bind(
                'load loadedmetadata',
                function (this: AkamaiViewerExtended) {
                    // eslint-disable-next-line sonar/no-nested-incdec
                    if (++loaded !== loadableCount) {
                        return
                    }

                    $loadables.unbind('load loadedmetadata')

                    const carousels = akamai.Carousel.createMany($markup[0], carouselOptions)

                    carousels.forEach(function (carousel) {
                        carousel.goto(carousel.getIndex())
                    })

                    if (this._options.carousel.removeOnTagSwitch) {
                        $currViewer.remove()
                        this._carousels.pop()
                    }
                    // create and store the new carousels (should be one)
                    this._carousels = this._carousels.concat(carousels)
                    this._showViewer($markup, carousels)
                }.bind(this),
            )

            // append the new markup to the existing viewer
            this._$el.append($markup)
        } else {
            this._showViewer($taggedViewer)
        }
    }

    return akamai
}
