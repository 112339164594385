import React, { useCallback, useEffect, useState, FocusEvent, useRef } from 'react'
import PropTypes from 'prop-types'

import Button from '../Button'
import Dropdown from '../Dropdown'
import TextInput from '../TextInput'
import TextArea from '../TextArea'
import Icon from '../Icon'
import {
    AccordionItemsType,
    ContactUsByEmailType,
    ContactUsFormDetailsType,
    DropDownItemsType,
} from './ContactUsByEmail.type'
import { PREFIX } from './../config'
import ConfirmationScreen from './ConfirmationScreen'
import { orderNumberRegex } from './ContactUsByEmail.constant'
import { scrollToTop } from '../../utils/scrollToTop'

const validateTextFieldHandler = (
    selectedVal: string,
    regex: RegExp,
    errorSetter: (val?: string) => void,
    emptyErrorMsg?: string,
    incorrectErrorMsg?: string,
): void => {
    let errorMsg = ''
    if (!selectedVal) {
        errorMsg = emptyErrorMsg
        // eslint-disable-next-line sonarjs/elseif-without-else
    } else if (regex) {
        const validate = regex.test(selectedVal)
        errorMsg = !validate ? incorrectErrorMsg : ''
    }
    errorSetter(errorMsg)
}

const validateDropdownHandler = (
    selectedVal: string | number,
    errorSetter: (val?: string) => void,
    emptyErrorMsg?: string,
): void => {
    const errorMsg = !selectedVal ? emptyErrorMsg : ''
    errorSetter(errorMsg)
}

const updateDropDownObjectFn = (
    selectedDropDownLabel: string,
    setPersistDropDown: (val?: DropDownItemsType[]) => void,
    persistDropDown: DropDownItemsType[],
): void => {
    const dropDownList = persistDropDown
    dropDownList.forEach(dropDownItem => {
        dropDownItem.selected = dropDownItem.id === selectedDropDownLabel
    })
    setPersistDropDown(dropDownList)
}

const getNextAccordionItems = ({
    selectedValue,
}: {
    selectedValue: DropDownItemsType
}): AccordionItemsType[] | null => {
    if (selectedValue.accordionItems2) {
        return selectedValue.accordionItems2
    }
    if (selectedValue.accordionItems3) {
        return selectedValue.accordionItems3
    }
    return null
}
/**
 * ContactUsByEmail component
 * @param {ContactUsByEmailType} props
 * @return {JSX.Element}
 */
// eslint-disable-next-line complexity, sonar/cyclomatic-complexity
const ContactUsByEmail: React.FC<ContactUsByEmailType> = (props: ContactUsByEmailType): JSX.Element => {
    const {
        title,
        firstNameLabel,
        lastNameLabel,
        emailLabel,
        emailPatternLabel,
        isAssistiveTextAlwaysVisible,
        yourMessageTitle,
        subjectLabel,
        orderNumberLabel,
        additionalInfoLabel,
        additionalInfoMaxCount,
        noteText,
        submitCTALabel,
        esDescription,
        ssTitle,
        ssDescription,
        esTitle,
        ssCTAPath,
        esImagePath,
        esImageAlt,
        ssImagePath,
        esCTALabel,
        ssImageAlt,
        ssCTALabel,
        accordionItems,
        ssCTATarget,
        icon,
        emailMessageTitle,
        headingLevel,
        content,
        submitContactUsCallBack,
        resetContactUsCallBack,
        contactUsSuccess,
        contactUsFailure,
        firstNameEmptyMessage,
        firstNameInvalidMessage,
        lastNameEmptyMessage,
        lastNameInvalidMessage,
        emailEmptyMessage,
        emailInvalidMessage,
        additionalInfoEmptyMsg,
        firstNameRegEx,
        lastNameRegEx,
        emailRegEx,
        orderNumberEmptyMessage,
        optionEmptyMessage1,
        optionEmptyMessage2,
        optionEmptyMessage3,
        invalidOrderNumberMessage,
    } = props

    const [isDropdownOptions] = useState(!!accordionItems.length)

    // create an array with the keys which are acceptable by the dropdown
    const dropdownListMapFn = ({ subjectDropDownLabel, subjectDropDownID, ...others }: AccordionItemsType) => {
        return { label: subjectDropDownLabel, id: subjectDropDownID, selected: false, ...others }
    }

    const dropdownList: DropDownItemsType[] = isDropdownOptions ? accordionItems.map(dropdownListMapFn) : []

    const [dropdownList2, setDropdownList2] = useState<DropDownItemsType[]>([])
    const [dropdownList3, setDropdownList3] = useState<DropDownItemsType[]>([])

    const [displayOrderNumber, setDisplayOrderNumber] = useState('false')
    const [displayText, setDisplayText] = useState<string>(null)

    const [level2Display, setLevel2Display] = useState('false')
    const [level3Display, setLevel3Display] = useState('false')

    const [formDetails, setFormDetails] = useState({
        firstName: '',
        lastName: '',
        email: '',
        emailSubject: '',
        subject: '',
        subjectOptions: '',
        subjectOptionDetails: '',
        comment: '',
    } as ContactUsFormDetailsType)

    const [emailSubjectItems, setEmailSubjectItems] = useState({
        subject1: '',
        subject2: '',
        subject3: '',
    })

    const [changedFormField, setChangedFormField] = useState({
        firstName: false,
        lastName: false,
        email: false,
        emailSubject: false,
        subject: false,
        subjectOptions: false,
        subjectOptionDetails: false,
        orderNumber: false,
        comment: false,
    })

    const [showConfirmationScreen, setShowConfirmationScreen] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    const [persistDropDown, setPersistDropDown] = useState(dropdownList)
    const [firstNameError, setFirstNameError] = useState<string | undefined>('')
    const [lastNameError, setLastNameError] = useState<string | undefined>('')
    const [emailError, setEmailError] = useState<string | undefined>('')
    const [subjectError, setSubjectError] = useState<string | undefined>('')
    const [subject2Error, setSubject2Error] = useState<string | undefined>('')
    const [subject3Error, setSubject3Error] = useState<string | undefined>('')
    const [orderNumberError, setOrderNumberError] = useState<string | undefined>('')
    const [additionalInfoError, setAdditionalInfoError] = useState<string | undefined>('')
    const [isSendButtonClicked, setIsSendButtonClicked] = useState(false)
    const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false)
    const [isSendButtonTriggered, setIsSendButtonTriggered] = useState(false)

    const componentName = `${PREFIX}-contact-us-by-email`
    const emailBodyClassName = `${componentName}-email-body`

    const [showSpinner, setShowSpinner] = useState(false)
    /**
     * Onselect Handler for dropdown.
     * @param {object} level - Dropdown level value.
     * @return {void}
     */
    const onSelectHandler = (level: number) => {
        return (selectedValue: DropDownItemsType): void => {
            handleDropdownCall(level, selectedValue)
        }
    }
    const dropdownIcon = 'ct-chevron-down'
    const dropdownLevel1 = 1
    const dropdownLevel2 = 2
    const dropdownLevel3 = 3
    const [dropdownProps2, setDropdownProps2] = useState({
        dropdownTitle: subjectLabel,
        dropdownList: dropdownList2,
        dropdownIcon: dropdownIcon,
        size: 'default',
        dropdownId: 'subjectOptions',
        selectedItem: onSelectHandler(dropdownLevel2),
        isFilter: true,
        filterTitle: subjectLabel,
        type: 'primary',
    })
    const [dropdownProps3, setDropdownProps3] = useState({
        dropdownTitle: subjectLabel,
        dropdownList: dropdownList3,
        dropdownIcon: dropdownIcon,
        size: 'default',
        dropdownId: 'subjectOptionDetails',
        selectedItem: onSelectHandler(dropdownLevel3),
        isFilter: true,
        filterTitle: subjectLabel,
        type: 'primary',
    })

    const contactUsByEmailFormRef = useRef<HTMLFormElement>(null)

    // UseEffect to show the confirmation screen and once the confirmation screen open, identify type of screen it is i.e. error or success
    useEffect(() => {
        setShowConfirmationScreen(contactUsSuccess || contactUsFailure)
        setIsSuccess(contactUsSuccess)
        if (contactUsFailure) {
            setShowSpinner(false)
        }
    }, [contactUsSuccess, contactUsFailure])

    /**
     * function to validate text field
     * @param {string} selectedVal associated field value
     * @param {RegExp} regex associated field regex
     * @param {string} errorSetter associated setter for error
     * @param {string} emptyErrorMsg error message for empty value in the field
     * @param {string} incorrectErrorMsg error message for invalid value in the field
     */
    const validateTextField = useCallback(validateTextFieldHandler, [])

    /**
     * function to validate dropdown
     * @param {string} selectedVal associated field value
     * @param {string} errorSetter associated setter for error
     * @param {string} emptyErrorMsg error message for empty value in the field
     */

    const validateDropdown = useCallback(validateDropdownHandler, [])

    const generateEmailSubject = useCallback(
        (subject1: string, subject2: string, subject3: string, subject: string): string => {
            const secondSubject = subject2.length ? '- ' + subject2 : ''
            const thirdSubject = subject3.length ? '- ' + subject3 : ''
            return isDropdownOptions ? `${subject1} ${secondSubject} ${thirdSubject}` : subject
        },
        [isDropdownOptions],
    )

    useEffect(() => {
        setFormDetails(prevState => {
            return {
                ...prevState,
                emailSubject: generateEmailSubject(
                    emailSubjectItems.subject1,
                    emailSubjectItems.subject2,
                    emailSubjectItems.subject3,
                    formDetails.subject,
                ),
            }
        })
    }, [
        generateEmailSubject,
        formDetails.subject,
        formDetails.subjectOptions,
        formDetails.subjectOptionDetails,
        emailSubjectItems.subject1,
        emailSubjectItems.subject2,
        emailSubjectItems.subject3,
    ])

    useEffect(() => {
        validateTextField(
            formDetails.firstName,
            firstNameRegEx,
            setFirstNameError,
            firstNameEmptyMessage,
            firstNameInvalidMessage,
        )
    }, [formDetails.firstName, firstNameEmptyMessage, firstNameInvalidMessage, validateTextField, firstNameRegEx])

    useEffect(() => {
        validateTextField(
            formDetails.lastName,
            lastNameRegEx,
            setLastNameError,
            lastNameEmptyMessage,
            lastNameInvalidMessage,
        )
    }, [formDetails.lastName, lastNameEmptyMessage, lastNameInvalidMessage, validateTextField, lastNameRegEx])

    useEffect(() => {
        validateTextField(formDetails.email, emailRegEx, setEmailError, emailEmptyMessage, emailInvalidMessage)
    }, [formDetails.email, emailEmptyMessage, emailInvalidMessage, validateTextField, emailRegEx])

    useEffect(() => {
        if (displayOrderNumber === 'true') {
            validateTextField(
                formDetails.orderNumber,
                orderNumberRegex,
                setOrderNumberError,
                orderNumberEmptyMessage,
                invalidOrderNumberMessage,
            )
        } else {
            setOrderNumberError(null)
        }
    }, [
        formDetails.orderNumber,
        orderNumberEmptyMessage,
        invalidOrderNumberMessage,
        validateTextField,
        displayOrderNumber,
    ])

    useEffect(() => {
        validateTextField(formDetails.comment, null, setAdditionalInfoError, additionalInfoEmptyMsg)
    }, [formDetails.comment, additionalInfoEmptyMsg, validateTextField])

    useEffect(() => {
        validateDropdown(formDetails.subject, setSubjectError, optionEmptyMessage1)
    }, [formDetails.subject, optionEmptyMessage1, validateDropdown])

    useEffect(() => {
        if (level2Display === 'true') {
            validateDropdown(formDetails.subjectOptions, setSubject2Error, optionEmptyMessage2)
        } else {
            setSubject2Error(null)
        }
    }, [formDetails.subjectOptions, optionEmptyMessage2, validateDropdown, level2Display])

    useEffect(() => {
        if (level3Display === 'true') {
            validateDropdown(formDetails.subjectOptionDetails, setSubject3Error, optionEmptyMessage3)
        } else {
            setSubject3Error(null)
        }
    }, [formDetails.subjectOptionDetails, optionEmptyMessage3, validateDropdown, level3Display])

    useEffect(() => {
        setIsSendButtonClicked(false)
    }, [formDetails])

    /**
     * function to send error message to the setter
     * @param {string} changedField
     * @param {string} errorMsg
     * @return {string} error message
     */
    const sendError = (changedField?: string, errorMsg?: string): string => {
        if ((isFormErrorExist().includes(errorMsg) && isSubmitButtonClicked) || changedFormField[changedField]) {
            return errorMsg
        }
        return ''
    }

    /**
     * Change Handler for the text input lib component
     * @param {string} inputFieldName - name of the input which is currently being updated.
     * @param {unknown} value - text input value.
     */
    const onChangeHandler = (inputFieldName: string, value: string): void => {
        value &&
            setChangedFormField(prevState => {
                return {
                    ...prevState,
                    [inputFieldName]: true,
                }
            })
        setFormDetails(prevState => {
            return {
                ...prevState,
                [inputFieldName]: value,
            }
        })
    }

    /**
     * Blur Handler for the text input lib component
     * @param {string} inputFieldName - name of the input which is currently being updated.
     * @param {FocusEvent<HTMLInputElement>} e - event from onBlur.
     */
    const onBlurHandler = (inputFieldName: string, e: FocusEvent<HTMLInputElement>): void => {
        const { value } = e.target
        if (value) {
            setFormDetails(prevState => {
                return {
                    ...prevState,
                    [inputFieldName]: value.trim(),
                }
            })
        }
    }

    /**
     * Set values for the next dropdown level after level 1 option is selected.
     * @param {object} nextLevelDropdownItems - AccordionItemsType which get mapped into a DropDownItemsType structure.
     * @param {number} nextLevel - Next level Dropdown to make list and props to
     * @param {string} nextDropdownHeading - Next level Dropdown filterTitle
     * @param {boolean} nextDisplay - Flag to know if the next dropdown level is available or not
     * @return {void}
     */
    const setNextLevelDropdown = useCallback(
        (nextLevelDropdownItems: AccordionItemsType[], nextLevel: number, nextDropdownHeading: string): void => {
            const items = nextLevelDropdownItems.map(dropdownListMapFn)
            const nextLevelProps = {
                2: {
                    ...dropdownProps2,
                    dropdownList: items,
                    filterTitle: nextDropdownHeading,
                },
                3: {
                    ...dropdownProps3,
                    dropdownList: items,
                    filterTitle: nextDropdownHeading,
                },
            }
            const listHandlers = {
                2: setDropdownList2,
                3: setDropdownList3,
            }

            const propsHandlers = {
                2: setDropdownProps2,
                3: setDropdownProps3,
            }
            listHandlers[nextLevel as keyof typeof listHandlers](items)
            if (
                (nextLevel === dropdownLevel2 &&
                    nextLevelProps[nextLevel]?.filterTitle !== dropdownProps2.filterTitle) ||
                (nextLevel === dropdownLevel3 && nextLevelProps[nextLevel]?.filterTitle !== dropdownProps3.filterTitle)
            ) {
                propsHandlers[nextLevel](nextLevelProps[nextLevel])
            }
            // propsHandlers[nextLevel as keyof typeof propsHandlers](nextLevelProps[nextLevel])
        },
        [dropdownProps2, dropdownProps3],
    )

    /**
     * Handler for dropdown level 1.
     * @param {object} selectedValue - Dropdown properties.
     * @return {void}
     */
    const handleDropdownLevel1 = (selectedValue: DropDownItemsType): void => {
        setFormDetails(prevState => {
            return {
                ...prevState,
                subject: selectedValue.label,
                subjectOptions: '',
                subjectOptionDetails: '',
            }
        })

        setEmailSubjectItems(prevState => {
            return {
                ...prevState,
                subject1: selectedValue.id,
                subject2: '',
                subject3: '',
            }
        })

        setLevel3Display('false')

        setDropdownList2([])
        setDropdownList3([])
        setDropDownSelectedValues({
            level: 2,
            selectedValue,
        })
        setDisplayText(selectedValue.displayText)
    }

    /**
     * Handler for dropdown level 2.
     * @param {object} selectedValue - Dropdown properties.
     * @return {void}
     */
    const handleDropdownLevel2 = (selectedValue: DropDownItemsType): void => {
        setFormDetails(prevState => {
            return {
                ...prevState,
                subjectOptions: selectedValue.label,
                subjectOptionDetails: '',
            }
        })
        setEmailSubjectItems(prevState => {
            return {
                ...prevState,
                subject2: selectedValue.id,
                subject3: '',
            }
        })
        setDropdownList3([])
        setDropDownSelectedValues({
            level: 3,
            selectedValue,
        })
    }

    /**
     * Handler for dropdown level 3.
     * @param {object} selectedValue - Dropdown properties.
     * @return {void}
     */

    const handleDropdownLevel3 = (selectedValue: DropDownItemsType): void => {
        setFormDetails(prevState => {
            return {
                ...prevState,
                subjectOptionDetails: selectedValue.label,
            }
        })
        setEmailSubjectItems(prevState => {
            return {
                ...prevState,
                subject3: selectedValue.id,
            }
        })
    }

    const handleDropdownCall = (level: number, selectedValue: DropDownItemsType): void => {
        const handlers = {
            1: handleDropdownLevel1,
            2: handleDropdownLevel2,
            3: handleDropdownLevel3,
        }
        handlers[level as keyof typeof handlers](selectedValue)

        if (selectedValue.displayOrderNumber !== undefined) {
            setDisplayOrderNumber(selectedValue.displayOrderNumber)
        } else {
            setDisplayOrderNumber('false')
        }
    }

    const [dropDownSelectedValues, setDropDownSelectedValues] = useState<{
        level: number
        selectedValue: DropDownItemsType
    }>(null)

    useEffect(() => {
        /**
         * Handle what dropdown level to set next next, if not undefined
         * @param {DropDownItemsType} selectedValue - The current Dropdown shown, which can contain another level.
         * @param {number} level - The next level dropdown
         */
        const showNextLevelContact = ({
            selectedValue,
            level,
        }: {
            selectedValue: DropDownItemsType
            level: number
        }): void => {
            const nextLevelHeading = {
                2: selectedValue.level2Heading,
                3: selectedValue.level3Heading,
            }
            const nextLevelDisplayProp = {
                2: selectedValue.level2Available,
                3: selectedValue.level3Available,
            }

            const nextLevelDisplayFn = {
                2: setLevel2Display,
                3: setLevel3Display,
            }

            const nextHeading = nextLevelHeading[level as keyof typeof nextLevelHeading]

            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            nextLevelDisplayFn[level as keyof typeof nextLevelDisplayFn](nextLevelDisplayProp[level])

            const nextLevelItems = getNextAccordionItems({ selectedValue })
            if (nextLevelItems) {
                setNextLevelDropdown(nextLevelItems, level, nextHeading)
            }
        }
        dropDownSelectedValues && showNextLevelContact(dropDownSelectedValues)
    }, [dropDownSelectedValues, setNextLevelDropdown])

    /**
     * function to check error exist in the form
     * @return {string[]} error message
     */
    const isFormErrorExist = (): string[] => {
        return [
            firstNameError,
            lastNameError,
            emailError,
            subjectError,
            subject2Error,
            subject3Error,
            orderNumberError,
            additionalInfoError,
        ].filter(err => err)
    }

    const focusFirstErrorElement = useCallback(() => {
        const firstErrorElement = contactUsByEmailFormRef.current?.querySelector(
            `.${PREFIX}-accessibility-field--error`,
        ) as unknown as HTMLElement
        firstErrorElement && firstErrorElement.focus()
    }, [])

    useEffect(() => {
        focusFirstErrorElement()
    }, [isSendButtonTriggered, focusFirstErrorElement])

    /**
     * Form submit handler
     * @param {React.MouseEvent} e - Event to prevent the default form behavior.
     * @return {void}
     */
    const onSubmitHandler = (e: React.MouseEvent<Element, MouseEvent>): void => {
        e.preventDefault()
        e.stopPropagation()
        if (!isSendButtonClicked) {
            setIsSubmitButtonClicked(true)
            setIsSendButtonClicked(true)
        }
        const isError = isFormErrorExist().length

        if (!isError) {
            setShowSpinner(true)
            submitContactUsCallBack(formDetails)
            scrollToTop()
        }
        setIsSendButtonTriggered(prevState => !prevState)
    }
    /**
     * This functions checks the conditions to display error on form
     * @param {string} fieldName
     * @param {string} errorText
     * @return {string | boolean}
     */
    const showValidationError = (fieldName: string, errorText: string): string => {
        return isSendButtonClicked ? errorText : sendError(fieldName, errorText)
    }

    const dropdownProps = {
        dropdownTitle: subjectLabel,
        dropdownList: persistDropDown,
        dropdownIcon: dropdownIcon,
        size: 'default',
        dropdownId: 'subject',
        selectedItem: onSelectHandler(dropdownLevel1),
        isFilter: true,
        filterTitle: subjectLabel,
        type: 'primary',
        error: showValidationError('subject', subjectError),
    }

    /**
     * Function to persist the dropdown when the user comes back from the error screen
     *
     * @param {string} selectedDropDownLabel - selected drop down label.
     * @return {void}
     */
    const updateDropDownObject = (selectedDropDownLabel: string): void =>
        updateDropDownObjectFn(selectedDropDownLabel, setPersistDropDown, persistDropDown)

    /**
     * Function to persists the form information when contact form throws error screen.
     *
     * @param {ContactUsFormDetailsType} userFormDetails - form details entered by the user.
     * @return {void}
     */
    const contactFormErrorHandler = (userFormDetails: ContactUsFormDetailsType): void => {
        resetContactUsCallBack() // Reset the contact success and failure store props.
        setShowConfirmationScreen(false) // Remove the error screen and show the form screen
        setFormDetails(userFormDetails) // For persistance of text inputs
        updateDropDownObject(userFormDetails.subject) // Persisting DropDown selection
    }

    const confirmationScreenProps = {
        title: isSuccess ? ssTitle : esTitle,
        description: isSuccess ? ssDescription : esDescription,
        imagePath: isSuccess ? ssImagePath : esImagePath,
        imageAlt: isSuccess ? ssImageAlt : esImageAlt,
        buttonLabel: isSuccess ? ssCTALabel : esCTALabel,
        buttonLink: ssCTAPath,
        success: isSuccess,
        successLinkTarget: ssCTATarget,
        formDetails: formDetails,
        errorCallback: contactFormErrorHandler,
    }

    /**
     * function to get contact us email form heading with custom heading tags
     * @return {JSX.Element} email form heading with h1/h2/h3/h4/h5/h6
     */
    const getEmailHeadingComp = (): JSX.Element => {
        const HeadingTagLevel = headingLevel as keyof JSX.IntrinsicElements
        return (
            <HeadingTagLevel
                className={`${PREFIX}-customer-service__title ${PREFIX}-customer-service__title--with-icon`}>
                {emailMessageTitle}
            </HeadingTagLevel>
        )
    }

    const fieldsetContainer = isDropdownOptions ? (
        <fieldset>
            <div className={emailBodyClassName}>
                <h2 className={`${emailBodyClassName}-title`}>{yourMessageTitle}</h2>
                <Dropdown {...dropdownProps} />
                {displayText ? (
                    <div
                        className={`${componentName}-dropdown-info`}
                        dangerouslySetInnerHTML={{ __html: displayText }}></div>
                ) : null}
                {dropdownList2.length && level2Display === 'true' ? (
                    <Dropdown {...dropdownProps2} error={showValidationError('subjectOptions', subject2Error)} />
                ) : null}
                {dropdownList3.length && level3Display === 'true' ? (
                    <Dropdown {...dropdownProps3} error={showValidationError('subjectOptionDetail', subject3Error)} />
                ) : null}
                {displayOrderNumber === 'true' ? (
                    <TextInput
                        value={formDetails.orderNumber}
                        id="orderNumber"
                        label={orderNumberLabel}
                        onChange={(val: string) => onChangeHandler('orderNumber', val)}
                        maxLength={16}
                        error={showValidationError('orderNumber', orderNumberError)}
                        isErrorFocus={true}
                        quantumMetricAttribute={{ type: 'encrypt', value: 'true' }}
                    />
                ) : null}
                <TextArea
                    id="comment"
                    label={additionalInfoLabel}
                    onInputChange={(val: string) => onChangeHandler('comment', val)}
                    maxCharacters={Number(additionalInfoMaxCount)}
                    hasMaxLimit={true}
                    value={formDetails.comment}
                    error={showValidationError('comment', additionalInfoError)}
                    isErrorFocus={true}
                />
                <article
                    className={`${emailBodyClassName}-note`}
                    dangerouslySetInnerHTML={{ __html: noteText }}></article>
            </div>
        </fieldset>
    ) : (
        <fieldset>
            <div className={emailBodyClassName}>
                <h2 className={`${emailBodyClassName}-title`}>{yourMessageTitle}</h2>
                <div className={`${componentName}-subject`}>
                    <TextInput
                        value={formDetails.subject}
                        id="subject"
                        label={subjectLabel}
                        onChange={(val: string) => onChangeHandler('subject', val)}
                        onBlur={(val: React.FocusEvent<HTMLInputElement>) => onBlurHandler('subject', val)}
                        maxLength={50}
                        error={showValidationError('subject', subjectError)}
                        isErrorFocus={true}
                        quantumMetricAttribute={{ type: 'encrypt', value: 'true' }}
                    />
                </div>
                <TextArea
                    id="comment"
                    label={additionalInfoLabel}
                    onInputChange={(val: string) => onChangeHandler('comment', val)}
                    maxCharacters={Number(additionalInfoMaxCount)}
                    hasMaxLimit={true}
                    value={formDetails.comment}
                    error={showValidationError('comment', additionalInfoError)}
                    isErrorFocus={true}
                />
                <article
                    className={`${emailBodyClassName}-note`}
                    dangerouslySetInnerHTML={{ __html: noteText }}></article>
            </div>
        </fieldset>
    )

    return (
        <>
            {showConfirmationScreen ? (
                <ConfirmationScreen {...confirmationScreenProps} />
            ) : (
                <>
                    <div className={componentName}>
                        <div className={`${PREFIX}-customer-service__heading`}>
                            {!!icon && (
                                <div className={`${PREFIX}-customer-service__icon-container`}>
                                    <Icon type={icon} />
                                </div>
                            )}
                            {getEmailHeadingComp()}
                            <div
                                className={`${PREFIX}-customer-service__subtitle`}
                                dangerouslySetInnerHTML={{ __html: content }}></div>
                        </div>
                        <form ref={contactUsByEmailFormRef}>
                            <fieldset>
                                <h2 className={`${componentName}-form-title`}>{title}</h2>
                                <div className={`${componentName}-name-details`}>
                                    <TextInput
                                        value={formDetails.firstName}
                                        id="firstName"
                                        label={firstNameLabel}
                                        onChange={(val: string) => onChangeHandler('firstName', val)}
                                        onBlur={(val: React.FocusEvent<HTMLInputElement>) =>
                                            onBlurHandler('firstName', val)
                                        }
                                        maxLength={50}
                                        error={showValidationError('firstName', firstNameError)}
                                        isErrorFocus={true}
                                        quantumMetricAttribute={{ type: 'encrypt', value: 'true' }}
                                    />
                                    <TextInput
                                        value={formDetails.lastName}
                                        id="lastName"
                                        label={lastNameLabel}
                                        onChange={(val: string) => onChangeHandler('lastName', val)}
                                        onBlur={(val: React.FocusEvent<HTMLInputElement>) =>
                                            onBlurHandler('lastName', val)
                                        }
                                        maxLength={50}
                                        error={showValidationError('lastName', lastNameError)}
                                        isErrorFocus={true}
                                        quantumMetricAttribute={{ type: 'encrypt', value: 'true' }}
                                    />
                                </div>
                                <div className={`${componentName}-email`}>
                                    <TextInput
                                        value={formDetails.email}
                                        type="email"
                                        id="email"
                                        label={emailLabel}
                                        onChange={(val: string) => onChangeHandler('email', val)}
                                        onBlur={(val: React.FocusEvent<HTMLInputElement>) =>
                                            onBlurHandler('email', val)
                                        }
                                        maxLength={256}
                                        error={showValidationError('email', emailError)}
                                        isErrorFocus={true}
                                        quantumMetricAttribute={{ type: 'encrypt', value: 'true' }}
                                        assistiveText={emailPatternLabel}
                                        isAssistiveTextAlwaysVisible={isAssistiveTextAlwaysVisible}
                                    />
                                </div>
                            </fieldset>
                            {fieldsetContainer}
                            <Button
                                size="small"
                                onClick={onSubmitHandler}
                                buttonType="submit"
                                label={submitCTALabel}
                                showSpinner={showSpinner}></Button>
                        </form>
                    </div>
                </>
            )}
        </>
    )
}

ContactUsByEmail.propTypes = {
    title: PropTypes.string,
    firstNameLabel: PropTypes.string,
    lastNameLabel: PropTypes.string,
    emailLabel: PropTypes.string,
    yourMessageTitle: PropTypes.string,
    subjectLabel: PropTypes.string,
    orderNumberLabel: PropTypes.string,
    additionalInfoLabel: PropTypes.string,
    additionalInfoMaxCount: PropTypes.number,
    noteText: PropTypes.string,
    submitCTALabel: PropTypes.string,
    ssCTATarget: PropTypes.string,
    esDescription: PropTypes.string,
    ssTitle: PropTypes.string,
    esCTAPath: PropTypes.string,
    ssDescription: PropTypes.string,
    additionalInfoEmptyMsg: PropTypes.string,
    lastNameInvalidMessage: PropTypes.string,
    esTitle: PropTypes.string,
    ssCTAPath: PropTypes.string,
    esImagePath: PropTypes.string,
    emailInvalidMessage: PropTypes.string,
    firstNameEmptyMessage: PropTypes.string,
    lastNameEmptyMessage: PropTypes.string,
    esImageAlt: PropTypes.string,
    ssImagePath: PropTypes.string,
    esCTALabel: PropTypes.string,
    ssImageAlt: PropTypes.string,
    destinationEmail: PropTypes.string,
    ssCTALabel: PropTypes.string,
    esCTATarget: PropTypes.string,
    emailEmptyMessage: PropTypes.string,
    firstNameInvalidMessage: PropTypes.string,
    accordionItems: PropTypes.array,
    icon: PropTypes.string,
    emailMessageTitle: PropTypes.string,
    headingLevel: PropTypes.string,
    content: PropTypes.string,
    submitContactUsCallBack: PropTypes.func,
    resetContactUsCallBack: PropTypes.func,
    contactUsSuccess: PropTypes.bool,
    contactUsFailure: PropTypes.bool,
    firstNameRegEx: PropTypes.any,
    lastNameRegEx: PropTypes.any,
    emailRegEx: PropTypes.any,
    orderNumberEmptyMessage: PropTypes.string,
    optionEmptyMessage1: PropTypes.string,
    optionEmptyMessage2: PropTypes.string,
    optionEmptyMessage3: PropTypes.string,
    invalidOrderNumberMessage: PropTypes.string,
}

export default ContactUsByEmail
