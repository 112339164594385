import { magicNumber, getSourceTypeData, replaceMultipleStrWithDynamicVal, getFormattedDateTime } from '@nl/lib'
import { deliveryOptions } from '../../config'
import {
    BulkDeliveryOptionDTO,
    AddProductToCartGuestRequestDTO,
    CartOrderDeliveryModes,
    CartOrderEntries,
    AvailableDeliveryModesType,
    ProductSourceTypes,
    EstimatedDeliveryDate,
} from '../../redux/models/cart.interface'
import { GroupedProductsReturnType, ProductsSTHEligibleCount } from './CartItems.type'
import { SelectedDeliveryMode } from './ShoppingCart.type'
export const checkIfWGDeliveryEligible = (bulkDeliveryOptions: BulkDeliveryOptionDTO[]): boolean => {
    return (
        bulkDeliveryOptions?.some(
            // eslint-disable-next-line sonar/different-types-comparison
            bulkDeliveryOption => bulkDeliveryOption.deliveryOptionType === deliveryOptions.IN_HOME_DELIVERY,
        ) &&
        bulkDeliveryOptions?.some(
            // eslint-disable-next-line sonar/different-types-comparison
            bulkDeliveryOption => bulkDeliveryOption.deliveryOptionType === deliveryOptions.IN_HOME_DELIVERY_AND_UNPACK,
        )
    )
}

export const combineLabelsForOrderDetailsPageEDD = (orderStatusLabel: string, estimatedDeliveryDateBetween: string) => {
    orderStatusLabel = orderStatusLabel.split(':')[magicNumber.ZERO].trim()
    return `${estimatedDeliveryDateBetween.slice(
        magicNumber.ZERO,
        magicNumber.THREE,
    )}${orderStatusLabel}: ${estimatedDeliveryDateBetween.slice(magicNumber.THREE)}`
}

export const getSelectedDeliveryOption = (bulkDeliveryOptions: BulkDeliveryOptionDTO[]) => {
    return bulkDeliveryOptions?.find(bulkDeliveryOption => bulkDeliveryOption.isSelected)?.deliveryOptionType
}

export const getGroupedProductsByDeliveryMode = (
    groupedProducts: Record<string, CartOrderEntries[]>,
): GroupedProductsReturnType => {
    const sthGroupedProducts = {} as Record<string, CartOrderEntries[]>
    const expressGroupedProducts = {} as Record<string, CartOrderEntries[]>
    Object.entries(groupedProducts)?.forEach(grouped => {
        grouped[magicNumber.ONE]?.forEach((product: CartOrderEntries) => {
            const key = grouped[magicNumber.ZERO]
            if (product?.fulfillment?.deliveryMode === CartOrderDeliveryModes.STH) {
                !sthGroupedProducts[key] && (sthGroupedProducts[key] = [])
                sthGroupedProducts[key].push(product)
            }

            if (product?.fulfillment?.deliveryMode === CartOrderDeliveryModes.EXPRESS) {
                !expressGroupedProducts[key] && (expressGroupedProducts[key] = [])
                expressGroupedProducts[key].push(product)
            }
        })
    })
    return { sthGroupedProducts, expressGroupedProducts }
}

/**
 * There are cases on WODP banners when the cart delivery mode or item delivery mode can be forcibly
 * changed due to Ship to Home delivery mode priority:
 * 1 - When the user adds BOPIS item to STH cart then BOPIS item changed to STH delivery mode
 * 2 - When the user adds STH item to BOPIS cart then BOPIS cart changed to STH delivery mode and all
 * BOPIS items also changed to STH delivery mode
 * @param {AddProductToCartGuestRequestDTO} requestPayload - data that contains the product to be added
 * @param {string} lastCartDeliveryMode - cart delivery mode before new product be added
 * @return {boolean}
 */
export const isDeliveryModeForciblyChanged = (
    requestPayload: AddProductToCartGuestRequestDTO,
    lastCartDeliveryMode: string,
): boolean => {
    const desiredItemDeliveryMode = requestPayload.entries.orderEntries[0].deliveryMode.code
    const isItemDeliveryModeForciblyChanged =
        desiredItemDeliveryMode === CartOrderDeliveryModes.BOPIS && lastCartDeliveryMode === CartOrderDeliveryModes.STH
    const isCartDeliveryModeForciblyChanged =
        desiredItemDeliveryMode === CartOrderDeliveryModes.STH && lastCartDeliveryMode === CartOrderDeliveryModes.BOPIS

    return isItemDeliveryModeForciblyChanged || isCartDeliveryModeForciblyChanged
}

/**
 * @param {CartOrderEntries[]} sth - cart ship to home products data
 * @return {ProductsSTHEligibleCount} - number of express delivery eligibily and ship to home eligibility
 */

export const getEligibleProductsCount = (sth: CartOrderEntries[]): ProductsSTHEligibleCount => {
    let numberOfSthEligible: number = magicNumber.ZERO
    let numberOfExpressEligible: number = magicNumber.ZERO
    sth?.forEach(data => {
        if (data.fulfillment?.isEligibleForExpressDelivery) numberOfExpressEligible += magicNumber.ONE
        if (data.fulfillment?.isEligibleToShipHome) numberOfSthEligible += magicNumber.ONE
    })
    return { numberOfSthEligible, numberOfExpressEligible }
}

/**
 * Function to get time for deliver- product item depends on the type of delivery
 * @param {CartOrderEntries} productData
 * @param {SelectedDeliveryMode} selectedDeliveryMode
 * @param {AvailableDeliveryModesType} expressDeliveryMode
 * @param {AvailableDeliveryModesType} sthDeliveryMode
 * @return {string}
 */
export const getEtaTimeForDelivery = (
    productData: CartOrderEntries,
    selectedDeliveryMode: SelectedDeliveryMode,
    expressDeliveryMode: AvailableDeliveryModesType,
    sthDeliveryMode: AvailableDeliveryModesType,
): string | null | undefined => {
    const { sourceTypes = [] } = productData.fulfillment || {}
    const storePickupInStore = getSourceTypeData(sourceTypes, ProductSourceTypes.INSTORE)
    return storePickupInStore.etaEarliest
        ? storePickupInStore?.etaEarliest
        : // eslint-disable-next-line sonar/no-nested-conditional
        selectedDeliveryMode?.isExpress
        ? expressDeliveryMode?.etaEarliest
        : // eslint-disable-next-line sonar/no-nested-conditional
        selectedDeliveryMode?.isSTH
        ? sthDeliveryMode?.etaEarliest
        : null
}

/**
 * Function return Estimated Delivery Date label with date from payload
 * @param {string} estimatedDeliveryDateBetween
 * @param {EstimatedDeliveryDate} estimatedDeliveryDate
 * @return {string}
 */
export const getEstimatedDelivaryDateLabel = (
    estimatedDeliveryDateBetween: string,
    estimatedDeliveryDate: EstimatedDeliveryDate,
): string => {
    return replaceMultipleStrWithDynamicVal(estimatedDeliveryDateBetween, [
        getFormattedDateTime(estimatedDeliveryDate?.startDate)[magicNumber.ZERO],
        getFormattedDateTime(estimatedDeliveryDate?.endDate)[magicNumber.ZERO],
    ])
}
