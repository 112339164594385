import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { HandleToastProps } from './HandleToast.type'
import { productSelector } from '../../../redux/selectors/product.selectors'
import { cartStateSelector } from '../../../redux/selectors/cart.selectors'
import { wishlistDataSelector, wishlistStateSelector } from '../../../redux/selectors/wishlist.selectors'
import { toastMessageDataSelector } from '../../../redux/selectors/toastMessageData.selectors'
import { RootState } from '../../../redux/reducers'
import { commonContentSelector } from '../../../redux/selectors/commonContent.selectors'
import {
    IErrors,
    IFeatureFlag,
    IFedErrors,
    IPostalCodeModal,
    IWishlist,
} from '../../../redux/models/commonContent.interface'
import { isObjectNotEmpty } from '../../../utils/isObjectNotEmpty'
import {
    addToCartErrorCodes,
    addToCartErrorsMap,
    addToCartFailureCode,
    cautionToastIcon,
    errorCodeForInventoryError,
    failureToastIcon,
    heliumInflationGlobalToggleOffErrorCode,
    heliumInflationStoreToggleOffErrorCode,
    listOfErrorCodes,
    successStatusCode,
} from '../BuyBox.constant'
import { changeSuccessValue, resetSignInForSalesAlertsAction, resetWishlistActive } from '../../../redux/actionCreators'
import { Variant, VariantGroup } from '../../../redux/models/product.interface'
import { isArrayNotEmpty, replaceStrWithDynamicVal, Vehicle, areAllParamsValid } from '@nl/lib'
import appCacheService from '../../../utils/appCacheService'
import { getNoVariantErrorMesg, returnAddToWishListPayload } from '../BuyBox.helper'
import {
    clearAddToCartErrorData,
    fetchProductData,
    fetchProductDataBySku,
    removeWishListItems,
} from '../../../redux/actions'
import { MagicNumber } from '../../../analytics/analytics.type'
import { emptySpace, wishlistErrorObject } from '../../../globalConstants'
import { dispatchToast } from '../../ToastMessage/ToastMessage.helper'
import { ToastComponentNames } from '../../../redux/models/toastMessage.interface'
import { userProfileVehicleSelector } from '../../../redux/selectors/userProfile.selectors'
import { storeDetailsDataSelector } from '../../../redux/selectors/storeDetails.selectors'
import toastService from '../../../services/toastService/toast.service'
import { isTirePDP } from '../../Vehicles/Vehicle.helper'
import { useAuth } from '../../../hooks/useAuth.hook'
import { signInForSalesAlertsSelector } from '../../../redux/selectors/addToSalesAlerts.selectors'
import { useAppDispatch, useAppSelector } from '../../../hooks/react-redux.hook'

const HandleToast: React.FC<HandleToastProps> = (props: HandleToastProps): null => {
    const {
        isAddedToWishlist,
        isAddToWishlistClicked,
        isTireSizePresent,
        isStaggered,
        isAutomotive,
        isShipToHome,
        isNoSkuFindStoreClicked,
        isAddToCartClicked,
        quantityExceeded,
        showErrorMsgForVariantSelection,
        showUnavailableFulfillmentToast,
        toastErrorMessage,
        postalCodeErrorMessage,
        qtySelectorErrorMsg,
        productObject,
        allSelectedSkus,
        checkIfAllVariantsSelected,
        inStockAtDC,
        isProductDiscontinued,
        inStockInSelectedStore,
        getMaxQuantityAvailable,
        setToastErrorMessage,
        setAddToCartErrorMessage,
        setQtySelectorErrorMsg,
        setPostalCodeErrorMessage,
        setShowErrorMsgForVariantSelection,
        setIsAddToCartClicked,
        setIsAddToWishlistClicked,
        setIsNoSkuFindStoreClicked,
        setShowUnavailableFulfillmentToast,
        // BoyBox props
        exceedMaximumSellQuantityMessage,
        wishlistMaxLimitMessage,
        viewWishlistLink,
        isATCErrorHandling,
        exceedMaximumPurchableMessage,
        stgFitmentWarningMsg,
        variantNotSelectedMessage,
        isSeparatedErrorHandling,
        shouldShowSelectStoreToast,
        prodOOSErrMsg,
        setShouldShowSelectStoreToast,
        signInForSalesAlerts,
        isProductNotEligibleForSTH,
        orderable,
        isExpressDeliveryEligible,
    } = props

    const dispatch = useAppDispatch()

    const { isAuthenticated } = useAuth()

    const { productData, selectedProductCode, productSkuData } = useAppSelector(productSelector)
    const { addToCartErrorData } = useAppSelector(cartStateSelector)
    // Getting state of wishlist whether active or inactive
    const wishlistData = useAppSelector(wishlistDataSelector)
    const toastMessageData = useAppSelector(toastMessageDataSelector)
    // getting object on put and delete error operations.
    const { successErrorData } = useAppSelector((state: RootState) => state.successErrorWishlist)
    const { commonContentAvailable } = useAppSelector(commonContentSelector)
    const { defaultVehicle } = useAppSelector(userProfileVehicleSelector)
    const { selectedPreferredStoreId } = useAppSelector(storeDetailsDataSelector)
    const signInForSalesAlertsData = useAppSelector(signInForSalesAlertsSelector)
    const { wishlistRemoveSuccess } = useAppSelector(wishlistStateSelector)

    const [showAddToCartFailureToast, setShowAddToCartFailureToast] = useState(false)

    const {
        fedErrors = {} as IFedErrors,
        postalCodeModal = {} as IPostalCodeModal,
        featureFlag = {} as IFeatureFlag,
        errors = {} as IErrors,
        wishlist = {} as IWishlist,
    } = commonContentAvailable
    const { enableVehicleInformationOnWishlist, enableLightPdpLoading } = featureFlag
    const { shoppingCartHeliumInflationStoreToggleOffError, pdpHeliumInflationGlobalToggleOffError } = errors
    const {
        itemRemovedFromWishlistMsg,
        addToWishlistMessageGuest,
        wishlistItemAddedMessage,
        viewWishlistLabel,
        closeLabel,
        undoLabel,
    } = wishlist

    const { outOfRangeMsg: deliveryIneligibleErrorMsg, outOfRangeErrorCode } = postalCodeModal
    const { availability: availabilityObj } = productSkuData?.skus?.[0]?.fulfillment || {}

    const isTire = isTirePDP(productData?.productWheelType)

    /**
     * function to check whether add to cart api failure
     */
    const isAddToCartApiFailed = useCallback(() => {
        return isObjectNotEmpty(addToCartErrorData as never) && addToCartErrorData.statusCode !== successStatusCode
    }, [addToCartErrorData])

    /**
     * function to get list of variant items
     */
    const getItemVariantsList = useCallback(() => {
        return Object.values(productData.itemVariants)
            .map((item: VariantGroup) => {
                return item.items
            })
            .reduce(function (previous: Variant[], current: Variant[]) {
                return previous.concat(current)
            })
    }, [productData.itemVariants])

    /**
     * function to get variant based on selected product code or product code
     */
    const getVariantFromSKU = useCallback(() => {
        let variantData: Variant | undefined
        if (isArrayNotEmpty(productData.itemVariants)) {
            variantData = getItemVariantsList()?.find(
                inv => inv.sku === selectedProductCode || inv.sku === productData.code,
            )
        }
        return variantData
    }, [productData.code, productData.itemVariants, selectedProductCode, getItemVariantsList])

    /**
     * function to update toast error message and inline error message when add to cart api failed
     */
    const updateAddToCartErrorData = useCallback(
        (addToCartErrorCode: string, errorCode: string, isCartApiFailed: boolean) => {
            const errCode = addToCartErrorData?.data?.errCode
            const toastErrPropertyName =
                addToCartErrorCode === addToCartErrorCodes[0] ? 'inventory' : 'maxPurchasableQty'
            const toastErrData = getVariantFromSKU()?.[toastErrPropertyName]

            let pcErrorMsg = postalCodeErrorMessage
                ? `${toastMessageData[addToCartErrorCode]} ${postalCodeErrorMessage}`
                : toastMessageData[addToCartErrorCode]
            let addToCartError = isCartApiFailed
                ? emptySpace
                : // eslint-disable-next-line sonar/no-nested-conditional
                addToCartErrorCodes.includes(errorCode)
                ? toastErrData
                : emptySpace
            if (errCode === heliumInflationStoreToggleOffErrorCode) {
                addToCartError = shoppingCartHeliumInflationStoreToggleOffError
                pcErrorMsg = shoppingCartHeliumInflationStoreToggleOffError
                // eslint-disable-next-line sonarjs/elseif-without-else
            } else if (errCode === heliumInflationGlobalToggleOffErrorCode) {
                addToCartError = pdpHeliumInflationGlobalToggleOffError
                pcErrorMsg = pdpHeliumInflationGlobalToggleOffError
            }
            pcErrorMsg && setToastErrorMessage(replaceStrWithDynamicVal(pcErrorMsg, toastErrData as number))
            setAddToCartErrorMessage(addToCartError as string)
        },
        [
            addToCartErrorData?.data?.errCode,
            getVariantFromSKU,
            postalCodeErrorMessage,
            toastMessageData,
            setToastErrorMessage,
            setAddToCartErrorMessage,
            shoppingCartHeliumInflationStoreToggleOffError,
            pdpHeliumInflationGlobalToggleOffError,
        ],
    )

    /**
     * function to update toast error message when add to cart api failed
     * It is west specific
     */
    const updateErrorForToastATC = useCallback(
        (addToCartErrorCode: string, errorMessage: string) => {
            if (addToCartErrorCode === errorCodeForInventoryError) {
                const productQuantity = addToCartErrorData?.data?.data?.inventory

                if (!productQuantity) {
                    setToastErrorMessage(toastMessageData.productOutOfStockError)
                } else {
                    setToastErrorMessage(
                        replaceStrWithDynamicVal(toastMessageData.availableQuantityExceedError, productQuantity),
                    )
                    setQtySelectorErrorMsg(exceedMaximumSellQuantityMessage as string)
                    setAddToCartErrorMessage(String(productQuantity))
                }
            } else {
                const toastErrorCode = addToCartErrorsMap.get(addToCartErrorCode) as string
                const matchArray = /\d+/.exec(errorMessage)
                const maxOrderQuantity = matchArray ? matchArray[0] : MagicNumber.ZERO

                if (toastErrorCode === addToCartErrorCodes[0]) {
                    setToastErrorMessage(replaceStrWithDynamicVal(toastMessageData[toastErrorCode], maxOrderQuantity))
                } else {
                    setToastErrorMessage(toastMessageData[toastErrorCode])
                }
            }
        },
        [
            addToCartErrorData,
            setToastErrorMessage,
            toastMessageData,
            setQtySelectorErrorMsg,
            exceedMaximumSellQuantityMessage,
            setAddToCartErrorMessage,
        ],
    )

    useEffect(() => {
        if (!isATCErrorHandling && addToCartErrorData && Object.keys(addToCartErrorData).length !== 0) {
            const isCartApiFailed = isAddToCartApiFailed()
            const errorCode = addToCartErrorData?.data?.errCode
            if (errorCode === outOfRangeErrorCode) {
                setPostalCodeErrorMessage(deliveryIneligibleErrorMsg)
            }
            const addToCartErrorCode = isCartApiFailed ? addToCartFailureCode : errorCode
            setShowAddToCartFailureToast(Boolean(addToCartErrorCode))
            updateAddToCartErrorData(addToCartErrorCode, errorCode, isCartApiFailed)
        }
    }, [
        addToCartErrorData,
        toastMessageData,
        productData.itemVariants,
        isAddToCartApiFailed,
        updateAddToCartErrorData,
        isATCErrorHandling,
        outOfRangeErrorCode,
        deliveryIneligibleErrorMsg,
        setPostalCodeErrorMessage,
    ])

    useEffect(() => {
        const errorMessage = addToCartErrorData?.data?.data?.message
        !errorMessage && setAddToCartErrorMessage('')

        if (isATCErrorHandling || showUnavailableFulfillmentToast) {
            const isCartApiFailed = isAddToCartApiFailed()
            setShowAddToCartFailureToast(isCartApiFailed)

            if (errorMessage) {
                const errorCode = listOfErrorCodes.find(error => errorMessage.includes(error)) as string

                updateErrorForToastATC(errorCode, errorMessage)
                // eslint-disable-next-line sonarjs/elseif-without-else
            } else if (
                (isCartApiFailed && addToCartErrorData && Object.keys(addToCartErrorData).length) ||
                showUnavailableFulfillmentToast
            ) {
                setToastErrorMessage(toastMessageData.addToCartFailureError)
            }
        }
    }, [
        addToCartErrorData,
        isAddToCartApiFailed,
        isATCErrorHandling,
        updateErrorForToastATC,
        toastMessageData,
        setAddToCartErrorMessage,
        setToastErrorMessage,
        showUnavailableFulfillmentToast,
    ])

    const isInStockOnline =
        (availabilityObj?.Corporate?.Quantity ?? 0) > MagicNumber.ZERO &&
        orderable &&
        availabilityObj?.quantity === MagicNumber.ZERO
    const isAvailableOnline = areAllParamsValid(checkIfAllVariantsSelected(), !isInStockOnline)

    // Returns wishlist exceeded error message
    const getWishlistExceededErrorMsg = useCallback((): string | undefined => {
        return successErrorData.type !== wishlistErrorObject.maxLimit
            ? successErrorData.message
            : replaceStrWithDynamicVal(
                  wishlistMaxLimitMessage as string,
                  successErrorData.message?.split(' ').slice(-1)[0] as string,
              )
    }, [successErrorData.type, wishlistMaxLimitMessage, successErrorData.message])

    /**
     * Get Toast Error Message
     * @param {boolean} isWishlist - is wishlist clicked
     * @param {boolean} isQtyExceeded - is quantity exceeded
     * @param {boolean} isNotAllVariants - is not all variants selected
     * @param {boolean} isOutOfStockInSelectedStore - is OOS in selected store
     * @returns {string | undefined} - returns toast error message
     */
    const getToastErrorMessage = useCallback(
        (
            isWishlist: boolean,
            isQtyExceeded: boolean,
            isNotAllVariants: boolean,
            isOutOfStockInSelectedStore: boolean,
            isNoSkuFindStore: boolean,
        ): string | undefined => {
            let errorMessage: string | undefined

            // do not show toast err message if item is added to wishlist and sign in for sales alerts btn is clicked
            if (isWishlist && !signInForSalesAlerts) {
                return getWishlistExceededErrorMsg(errorMessage)
            }

            const getQtyExceededErrorMsg = (): string => {
                let err = qtySelectorErrorMsg
                if (qtySelectorErrorMsg === exceedMaximumSellQuantityMessage) {
                    err = toastMessageData['availableQuantityExceedError']
                    // eslint-disable-next-line sonarjs/elseif-without-else
                } else if (qtySelectorErrorMsg === exceedMaximumPurchableMessage) {
                    err = toastMessageData['maxQuantityExceedError']
                }
                return replaceStrWithDynamicVal(err, getMaxQuantityAvailable())
            }

            if (isQtyExceeded) {
                if (!isStaggered) {
                    errorMessage = getQtyExceededErrorMsg()
                } else errorMessage = stgFitmentWarningMsg
            }

            // Function returns toast error message if product is OOS or Discontinued
            const getOOSOrDiscontinueErrorMsg = (): string => {
                let errMsg = toastMessageData['productOutOfStockError']
                if (isProductDiscontinued()) {
                    errMsg = toastMessageData['productDiscontinuedError']
                }
                return errMsg
            }

            if (isOutOfStockInSelectedStore && isAvailableOnline) {
                errorMessage = getOOSOrDiscontinueErrorMsg()
            }

            if (isNotAllVariants) {
                errorMessage = getNoVariantErrorMesg(isAddToWishlistClicked, variantNotSelectedMessage, fedErrors)
            }

            if (isNoSkuFindStore) {
                errorMessage = fedErrors?.errorSkuIsNotSelectedFindStoresMsg
            }

            if (shouldShowSelectStoreToast) {
                errorMessage = prodOOSErrMsg
            }

            return errorMessage
        },
        [
            isAvailableOnline,
            qtySelectorErrorMsg,
            exceedMaximumSellQuantityMessage,
            exceedMaximumPurchableMessage,
            getMaxQuantityAvailable,
            toastMessageData,
            isStaggered,
            stgFitmentWarningMsg,
            isProductDiscontinued,
            isAddToWishlistClicked,
            variantNotSelectedMessage,
            fedErrors,
            shouldShowSelectStoreToast,
            prodOOSErrMsg,
            signInForSalesAlerts,
            getWishlistExceededErrorMsg,
        ],
    )

    const toastErrorMsgProps = useMemo(
        () => ({
            options: {
                toastCloseLabel: closeLabel,
                toastErrorIcon: cautionToastIcon,
            },
            success: false,
            enableTimer: true,
        }),
        [closeLabel],
    )

    // Check if product is out of stock in selected store and ATC is clicked
    const isATCorWishlistClickedAndOutOfStock =
        (isAddToCartClicked || isAddToWishlistClicked) && !inStockInSelectedStore()

    const showOOSToastErrorMessage =
        isShipToHome && isSeparatedErrorHandling
            ? isATCorWishlistClickedAndOutOfStock && !inStockAtDC()
            : isATCorWishlistClickedAndOutOfStock

    /**
     * Toast Error Message Component
     */
    const toastErrorMsg = useCallback(() => {
        const errorMessage = getToastErrorMessage(
            !successErrorData.success,
            (isAddToCartClicked || isAddToWishlistClicked) && quantityExceeded,
            // eslint-disable-next-line sonar/expression-complexity
            (isAddToWishlistClicked || isAddToCartClicked) &&
                showErrorMsgForVariantSelection &&
                !checkIfAllVariantsSelected() &&
                !isTire,
            showOOSToastErrorMessage,
            isNoSkuFindStoreClicked,
        )
        if (!!errorMessage) {
            const newToastProps = {
                ...toastErrorMsgProps,
                options: { ...toastErrorMsgProps.options, toastErrorMessage: errorMessage },
            }
            dispatchToast(true, newToastProps, ToastComponentNames.BUYBOX_ERROR, dispatch)
        }
    }, [
        checkIfAllVariantsSelected,
        dispatch,
        getToastErrorMessage,
        isAddToCartClicked,
        isAddToWishlistClicked,
        isTire,
        quantityExceeded,
        showErrorMsgForVariantSelection,
        successErrorData.success,
        toastErrorMsgProps,
        showOOSToastErrorMessage,
        isNoSkuFindStoreClicked,
    ])

    useEffect(() => {
        toastErrorMsg()
    }, [toastErrorMsg])

    const wishlistSuccessToastProps = useMemo(
        () => ({
            options: {
                toastSuccessMessage:
                    !isAuthenticated && isAddedToWishlist ? addToWishlistMessageGuest : wishlistItemAddedMessage,
                toastCloseLabel: closeLabel,
                toastUndoLabel: !isAuthenticated ? closeLabel : undoLabel,
                toastErrorMessage: wishlistMaxLimitMessage,
                toastWishListLabel: viewWishlistLabel,
                toastWishListLabelURL: viewWishlistLink,
                toastErrorIcon: cautionToastIcon,
                toastSuccessIcon: 'ct-notification-success-green',
            },
            success: true,
        }),
        [
            isAddedToWishlist,
            isAuthenticated,
            wishlistItemAddedMessage,
            addToWishlistMessageGuest,
            closeLabel,
            undoLabel,
            viewWishlistLink,
            viewWishlistLabel,
            wishlistMaxLimitMessage,
        ],
    )

    // Toast message for removal of product from wishlist
    const wishlistRemovedSuccessToastProps = useMemo(
        () => ({
            options: {
                toastSuccessMessage: itemRemovedFromWishlistMsg,
                toastUndoLabel: closeLabel,
                toastSuccessIcon: 'ct-notification-success-green',
            },
            success: true,
        }),
        [closeLabel, itemRemovedFromWishlistMsg],
    )

    const toastCloseFunctionality = useCallback(() => {
        setShowErrorMsgForVariantSelection(false)
        setIsAddToCartClicked(false)
        dispatch(
            changeSuccessValue({
                code: 0,
                errorCode: '0',
                message: '',
                success: true,
            }),
        )
        setIsAddToWishlistClicked(false)
        setIsNoSkuFindStoreClicked(false)
        setShouldShowSelectStoreToast(false)
    }, [
        dispatch,
        setIsAddToCartClicked,
        setIsAddToWishlistClicked,
        setIsNoSkuFindStoreClicked,
        setShowErrorMsgForVariantSelection,
        setShouldShowSelectStoreToast,
    ])

    const resetWishlistActiveHandler = useCallback(() => {
        dispatch(resetWishlistActive())
        // remove local storage value
        appCacheService.isSignInForSalesAlerts.remove()
        // reset clicked state
        dispatch(resetSignInForSalesAlertsAction({ isClicked: false }))
    }, [dispatch])

    const toastUndoFunctionality = useCallback(() => {
        const productCode = selectedProductCode && 'options' in productData ? selectedProductCode : productData.code
        const guid = appCacheService.wishlistGuid.get()
        const wishlistRequestPayload = returnAddToWishListPayload(
            (isStaggered ? productCode : [productCode]) as string[],
            isAutomotive,
            defaultVehicle as Vehicle,
            isTireSizePresent,
            enableVehicleInformationOnWishlist,
        )
        if (guid) {
            dispatch(
                removeWishListItems(
                    { wishlistRequestPayload, guid, analyticsParams: productObject },
                    () => false,
                    undefined,
                    true,
                ),
            )
        }
    }, [
        defaultVehicle,
        dispatch,
        enableVehicleInformationOnWishlist,
        isAutomotive,
        isStaggered,
        isTireSizePresent,
        productData,
        productObject,
        selectedProductCode,
    ])

    const resetProductData = useCallback((): void => {
        setIsAddToCartClicked(false)
        setShowAddToCartFailureToast(false)
        setShowUnavailableFulfillmentToast(false)
        // Added a check to avoid an unnecessary API call when the "Add to Cart" button is clicked for a product that is not eligible for ship to home.
        !isProductNotEligibleForSTH &&
            isExpressDeliveryEligible &&
            dispatch(fetchProductData(productData.code, selectedPreferredStoreId, enableLightPdpLoading))
        dispatch(clearAddToCartErrorData())

        const thresholdValues = {
            hotDealThreshold: productData?.hotDealThreshold,
            limitedTimeThreshold: productData?.limitedTimeThreshold,
            lowStockThreshold: productData?.lowStockThreshold,
        }
        // Added a check to avoid an unnecessary API call when the "Add to Cart" button is clicked for a product that is not eligible for ship to home.
        !isProductNotEligibleForSTH &&
            isExpressDeliveryEligible &&
            dispatch(
                fetchProductDataBySku(
                    allSelectedSkus,
                    selectedPreferredStoreId,
                    thresholdValues,
                    productData?.brand?.label,
                ),
            )
    }, [
        setIsAddToCartClicked,
        setShowUnavailableFulfillmentToast,
        dispatch,
        productData,
        selectedPreferredStoreId,
        enableLightPdpLoading,
        allSelectedSkus,
        isProductNotEligibleForSTH,
        isExpressDeliveryEligible,
    ])

    useEffect(() => {
        toastService.setCallback({
            [ToastComponentNames.BUYBOX_WISHLIST_SUCCESS]: {
                toastUndoFunction:
                    !isAuthenticated || (isAuthenticated && wishlistRemoveSuccess)
                        ? toastCloseFunctionality
                        : toastUndoFunctionality,
                resetPageVariables: resetWishlistActiveHandler,
            },
            [ToastComponentNames.BUYBOX_ERROR]: {
                toastCloseFunction: toastCloseFunctionality,
            },
            [ToastComponentNames.BUYBOX_ATC_ERROR]: {
                toastCloseFunction: resetProductData,
            },
        })
    }, [
        resetProductData,
        resetWishlistActiveHandler,
        toastCloseFunctionality,
        toastUndoFunctionality,
        isAuthenticated,
        wishlistRemoveSuccess,
    ])

    const toastHandler = useCallback(() => {
        // should only dispatch toast if item is added to wishlist (isAddedToWishlist or wishlistData.active)...
        // ...AND signInForSalesAlerts is not clicked (if clicked, redirect to sign in page and show toast when user returns to PDP)
        if ((isAddedToWishlist || wishlistData.active) && !signInForSalesAlerts) {
            dispatchToast(
                wishlistData.active || signInForSalesAlertsData.isClicked,
                wishlistSuccessToastProps,
                ToastComponentNames.BUYBOX_WISHLIST_SUCCESS,
                dispatch,
            )
        } else if (wishlistRemoveSuccess) {
            dispatchToast(true, wishlistRemovedSuccessToastProps, ToastComponentNames.BUYBOX_WISHLIST_SUCCESS, dispatch)
        } else {
            dispatchToast(false, {}, ToastComponentNames.NONE, dispatch)
        }
    }, [
        dispatch,
        isAddedToWishlist,
        wishlistData.active,
        signInForSalesAlertsData.isClicked,
        wishlistSuccessToastProps,
        signInForSalesAlerts,
        wishlistRemovedSuccessToastProps,
        wishlistRemoveSuccess,
    ])

    useEffect(() => {
        toastHandler()
    }, [toastHandler])

    const addToCartFailureToastCompProps = useMemo(
        () => ({
            options: {
                toastErrorMessage: toastErrorMessage,
                toastCloseLabel: closeLabel,
                toastErrorIcon:
                    isAddToCartApiFailed() || showUnavailableFulfillmentToast ? failureToastIcon : cautionToastIcon,
            },
            success: false,
            failed: isAddToCartApiFailed() || showUnavailableFulfillmentToast,
            hideCTA: false,
        }),
        [toastErrorMessage, isAddToCartApiFailed, showUnavailableFulfillmentToast],
    )

    /**
     * function to show toast message on cart failure
     */
    const addToCartFailureToastComp = useCallback(() => {
        if (showAddToCartFailureToast || showUnavailableFulfillmentToast) {
            dispatchToast(true, addToCartFailureToastCompProps, ToastComponentNames.BUYBOX_ATC_ERROR, dispatch)
        } else {
            dispatchToast(false, {}, ToastComponentNames.BUYBOX_ATC_ERROR, dispatch)
        }
    }, [showAddToCartFailureToast, dispatch, addToCartFailureToastCompProps, showUnavailableFulfillmentToast])

    useEffect(() => {
        addToCartFailureToastComp()
    }, [addToCartFailureToastComp])

    return null
}

export default HandleToast
