import { ProductDataType, ProductDataTypeObj } from '../../../redux/models/productData.interface'
import { staggeredSkuLength } from '@nl/lib'
import { AutoPackages } from './PackageLanding.type'
import { analyticsAttributes } from '../../../globalConstants'

/**
 * Function returns true if added vehicle is staggred or not
 * @param {ProductDataType} productData
 * @return {boolean}
 * This function returns true if staggered fit product
 */

export const isStaggeredFitmentVehicle = (productData: ProductDataType): boolean => {
    return Boolean(
        productData?.products?.find((product: ProductDataTypeObj) => product?.skus?.length === staggeredSkuLength),
    )
}

/**
 * We are passing user selected value and preparing array for autoPackage array.
 * @param {string | undefined} selectedValue TIRE/WHEEL
 * @param {AutoPackages[]} autoPackages
 * @return {AutoPackages[]}
 */
export const prepareAutoPackageDataList = (
    selectedValue: string | undefined,
    autoPackages: AutoPackages[],
): AutoPackages[] => {
    const newPackageData = [{ currentSelectedContainer: selectedValue, vehicleInfo: {} }]
    if (autoPackages.length) {
        const createdAutoPackage = autoPackages.reduce((initialValue: AutoPackages[], currentValue: AutoPackages) => {
            if (!currentValue.packageId) {
                return [...initialValue, { currentSelectedContainer: selectedValue }]
            }
            return [...initialValue, currentValue]
        }, [])
        return [...createdAutoPackage, ...newPackageData]
    }
    return newPackageData
}

/**
 * Returns label value for no result package modal.
 * @param {boolean} tiresDataNotAvailable
 * @param {boolean} wheelsDataNotAvailable
 * @return {string}
 */
export const packageNoResultsModalLabel = (tiresDataNotAvailable: boolean, wheelsDataNotAvailable: boolean): string => {
    const {
        eventParameters: {
            labels: { noTiresOrWheelsThatFit, noTiresThatFit, noWheelsThatFit },
        },
    } = analyticsAttributes
    if (tiresDataNotAvailable && wheelsDataNotAvailable) {
        return noTiresOrWheelsThatFit
    } else if (tiresDataNotAvailable) {
        return noTiresThatFit
    } else if (wheelsDataNotAvailable) {
        return noWheelsThatFit
    } else {
        return ''
    }
}
