import querystring, { ParsedUrlQueryInput } from 'querystring'
import { AxiosPromise } from 'axios'

import { BaseService } from '../base.service'
import { getEnvironment } from '../../environments'
import { getHttpClient } from '../../httpClient'
import { ProductDetailsRequestDTO, ProductsRecommendationResponse } from './productDetailsService.type'
import { ProductCardType, RecommendationResponseDataDTO } from '../../redux/models/recommendations.interface'
import { BreadcrumbLinkData } from '../../redux/models/productData.interface'
import appCacheService from '../../utils/appCacheService'
import { FeaturedListAuthorableScheme } from '../../components/FeaturedProductList/FeaturedProductList.constant'
import { SkuListType } from '../../components/FrequentlyBoughtTogether/FrequentlyBoughtTogether.type'
import { ThresholdValuesType } from '../../components/BuyBox/BuyBox.type'
import { RequestBodyType } from '../../httpClient/client.type'
import extractPCodeFromUrl from '../../utils/PDP/extractPCodeFromUrl'
import { emptySpace } from '../../globalConstants'
import { getPageType, queryParameters } from '@nl/lib'
import { SizeChartDataType } from '../../redux/models/product.interface'
import { pageTypes } from '../../config'

const httpClient = getHttpClient()
const environment = getEnvironment()
const SINGLE_SKU_MODE = 'singleSkuOnly'

/**
 * Service to get product details
 */
class ProductDetailsService extends BaseService {
    /**
     * Function used to construct the query params
     * @param {ProductDetailsRequestDTO} requestParams - request query params.
     * @return {string} return a=b&c=d&e=f
     */
    private constructQueryParams(requestParams: ProductDetailsRequestDTO): string {
        return querystring.stringify(requestParams as unknown as ParsedUrlQueryInput)
    }

    /**
     * Check and update the root state using scheme id
     * @param {RecommendationResponseDataDTO[]} storeRecommendationData - current Root state value for recommendation.
     * @param {RecommendationResponseDataDTO[]} scheme - id to which id should be added/updated.
     * @param {RecommendationResponseDataDTO[]} productData - cds response data for the pcodes.
     *
     * @return {RecommendationResponseDataDTO[]} return updated root state.
     */
    public updateRecommendationRootStateUsingSchemeId = (
        storeRecommendationData: RecommendationResponseDataDTO[],
        scheme: string,
        productData: ProductCardType[],
    ): RecommendationResponseDataDTO[] => {
        /**
         * If the scheme is already present, update its product data with the response
         * If the scheme is not present, append it.
         */
        const doesSchemeExist = storeRecommendationData.some(singleItem => singleItem.scheme === scheme)
        if (doesSchemeExist) {
            return [
                ...storeRecommendationData.map(singleRecommendationData => {
                    if (singleRecommendationData.scheme === scheme) {
                        return {
                            scheme: singleRecommendationData.scheme,
                            productData,
                        }
                    }
                    return singleRecommendationData
                }),
            ]
        } else {
            storeRecommendationData.push({
                scheme,
                productData,
            })
            return [...storeRecommendationData]
        }
    }

    /**
     * Create the request for recommendations.
     * @param {string} queryParams - Query param string
     * @return {URL} returns the request url
     */
    public createRequestURL(queryParams: string): URL {
        const { getRecommendations } = environment.API_ENDPOINTS

        return new URL(`${environment.API_BASE_URL}${getRecommendations}?${queryParams}`)
    }

    /**
     * Function to fetch data for the product codes acquired from certona
     * @param {RecommendationResponseDataDTO[]} storeData - store recommendation data.
     * @param {string[]} pCodes - List of p codes.
     * @param {string} scheme - id for which u want to extract the p codes from certona response.
     * @param {string} preferredStoreId - preferred store id.
     * @param includeSpecifications - flag whether to include the specification array in the response
     * @return {AxiosPromise} returns promise.
     */
    public getProductDetailsUsingPCodes(
        storeData: RecommendationResponseDataDTO[],
        pCodes: string[],
        scheme: string,
        preferredStoreId: string,
        includeSpecifications: boolean,
        isSingleSkuOnlyModeEnabled: boolean,
    ) {
        const requestBody = JSON.stringify({
            productCodes: pCodes,
        })

        const { baseSiteId: baseStoreId, language: lang } = environment

        /**
         * API Request Query Params.
         */
        const queryParams: ProductDetailsRequestDTO = {
            storeName: preferredStoreId || appCacheService.preferredStoreId.get(),
            baseStoreId,
            lang,
            includeSpecifications: includeSpecifications,
        }
        isSingleSkuOnlyModeEnabled &&
            Object.assign(queryParams, {
                inventoryMode: SINGLE_SKU_MODE,
            })

        /**
         * Create the request url.
         */
        const url: string = this.createRequestURL(this.constructQueryParams(queryParams)).toString()

        const apiMethod = httpClient.apiPost<ProductsRecommendationResponse>(url, requestBody)

        return new Promise<RecommendationResponseDataDTO[]>((resolve, reject) => {
            apiMethod
                .then(data =>
                    !!scheme
                        ? resolve(
                              this.updateRecommendationRootStateUsingSchemeId(storeData, scheme, data.data.products),
                          )
                        : resolve([
                              {
                                  scheme: FeaturedListAuthorableScheme,
                                  productData: data.data.products,
                              },
                          ]),
                )
                .catch(data => reject(data))
        })
    }

    /**
     * Function to fetch data for the product codes
     * @param {string[]} pCodes - List of PCodes.
     * @param {string} preferredStoreId - preferred store id
     * @param {boolean} includeSpecifications - flag whether to include the specification array in the response
     * @returns {AxiosPromise} - returns AxiosPromise
     */
    getProductInfoUsingPCodesBasic(
        pCodes: string[],
        preferredStoreId: string,
        includeSpecifications: boolean,
    ): AxiosPromise<ProductsRecommendationResponse> {
        const requestBody = JSON.stringify({
            productCodes: pCodes,
        })

        const { baseSiteId: baseStoreId, language: lang } = environment

        const queryParams = {
            storeName: preferredStoreId || appCacheService.preferredStoreId.get(),
            baseStoreId,
            lang,
            includeSpecifications,
        }

        const url = this.createRequestURL(this.constructQueryParams(queryParams))

        return httpClient.apiPost<ProductsRecommendationResponse>(url, requestBody)
    }

    /**
     * Function to get product details
     * @param {string} productCode
     * @param {string} selectedPreferredStoreId
     * @param {boolean} lightLoadingEnabled
     * @return {AxiosPromise}
     */
    getProductDetails(
        productCode: string,
        selectedPreferredStoreId: string,
        lightLoadingEnabled?: boolean,
    ): AxiosPromise<unknown> {
        return httpClient.apiGet(
            this.createPDPUrl(productCode, selectedPreferredStoreId, lightLoadingEnabled).toString(),
        )
    }

    /**
     * Create url for the product details api
     * @param {string} productCode
     * @param {string} selectedPreferredStoreId
     * @param {boolean} lightLoadingEnabled
     * @return {URL}
     */
    createPDPUrl(productCode: string, selectedPreferredStoreId: string, lightLoadingEnabled?: boolean): URL {
        const language = ProductDetailsService.language
        const lightLoading = lightLoadingEnabled ? `&${queryParameters.light}=${lightLoadingEnabled.toString()}` : ''

        const {
            API_BASE_URL,
            baseSiteId,
            API_ENDPOINTS: { productDetails },
        } = environment
        const pdpCDSUrl = `${productCode}?baseStoreId=${baseSiteId}&lang=${language}&storeId=${selectedPreferredStoreId}${lightLoading}`
        return new URL(`${API_BASE_URL}${productDetails}/${pdpCDSUrl}`)
    }

    /**
     * Function to get sku product details
     * @param {string} skuCode
     * @param {string} selectedPreferredStoreId
     * @param {ThresholdValuesType} thresholdValues
     * @param {boolean} isFBT
     * @param {string} brandName
     * @return {AxiosPromise}
     */
    getProductDetailsOfSku(
        skuCode: string[] | SkuListType[],
        selectedPreferredStoreId: string,
        thresholdValues?: ThresholdValuesType,
        isFBT?: boolean,
        brandName?: string,
    ): AxiosPromise<unknown> {
        const { hotDealThreshold, limitedTimeThreshold, lowStockThreshold } = thresholdValues || {}

        const checkThresholdForNullType: RequestBodyType = {
            hotDealThreshold,
            limitedTimeThreshold,
        }

        Object.keys(checkThresholdForNullType).forEach(
            // eslint-disable-next-line eqeqeq
            key => checkThresholdForNullType[key] == null && delete checkThresholdForNullType[key],
        )

        const skus: SkuListType[] = (isFBT
            ? skuCode
            : skuCode?.map(
                  (sku: string | SkuListType): SkuListType => ({
                      code: String((sku as SkuListType)?.code || sku),
                      lowStockThreshold: lowStockThreshold as number,
                      brand: brandName || '',
                  }),
              ) || []) as unknown as SkuListType[]

        const requestBody = JSON.stringify({
            ...checkThresholdForNullType,
            skus,
        })

        return httpClient.apiPost(this.createPDPUrlForSku(selectedPreferredStoreId).toString(), requestBody)
    }

    /**
     * Create url for the product details api
     * @param {string} selectedPreferredStoreId
     * @return {URL}
     */
    createPDPUrlForSku(selectedPreferredStoreId: string): URL {
        const language = ProductDetailsService.language

        // Add pCode to the query params if it is present in the URL
        const pCode = extractPCodeFromUrl()?.pCode || emptySpace
        const isPDPPage = getPageType() === pageTypes.pdpPage
        const pCodeQueryParam = pCode && isPDPPage ? `&pCode=${pCode}` : emptySpace

        const {
            API_BASE_URL,
            API_ENDPOINTS: { priceAvailability },
        } = environment

        const pdpSkuCDSUrl = `?lang=${language}&storeId=${selectedPreferredStoreId}&cache=true${pCodeQueryParam}`

        return new URL(`${API_BASE_URL}${priceAvailability}${pdpSkuCDSUrl}`)
    }

    /**
     * function to fetch size chart for current product
     * @param {BreadcrumbLinkData} breadcrumbList
     * @param {string} brand
     * @param {string} language
     * @return {AxiosPromise}
     */
    getSizeChartData(
        breadcrumbList: BreadcrumbLinkData[],
        brand: string,
        language: string,
        enableNewSizeChart?: boolean,
    ): AxiosPromise<SizeChartDataType> {
        const categoryIdList: string = breadcrumbList
            ?.filter(breadcrumb => !!breadcrumb.categoryId)
            ?.reduce<string[]>(
                (previousValue, breadcrumb): string[] => [...previousValue, breadcrumb.categoryId as string],
                [],
            )
            .join('/')
        const brandWithoutSpecialCharacters: string = brand.replace(/[^a-zA-Z0-9]/g, '')
        const RELATIVE_URL = window.location.origin
        const sizeChartEndpointVersion = enableNewSizeChart ? '/v2' : ''
        const url = `${RELATIVE_URL}/${language}${sizeChartEndpointVersion}/sizechart/${brandWithoutSpecialCharacters}/${categoryIdList}.json`

        return httpClient.apiGet(url)
    }
}

export const productDetailsService = new ProductDetailsService()
