import { magicNumber } from './magicNumber'

/**
 * Returns Date Format with or without time
 * @param {boolean} value - value to set parameter to return to empty or not
 * @param {object} item - item object
 * @return {string} - returns empty string or updated title
 */

export const generateExternalLink = (value: boolean, item: Array<Record<string, string>>) => {
    // eslint-disable-next-line no-unsafe-optional-chaining
    return value ? item && (item?.slice(magicNumber.MINUS_ONE))[magicNumber.ZERO].title : ''
}

export const externalNavigation = (value: string): boolean => {
    return !value?.startsWith('/')
}
