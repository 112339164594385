/* eslint-disable complexity */
import { ActionType, getType } from 'typesafe-actions'
import {
    addToCartGuestSuccessAction,
    addToCartGuestFailureAction,
    resetCartGUIDCreatedFlagAction,
    clearAddToCartResponse,
    addServiceToCartSuccessAction,
    addServiceToCartFailureAction,
    getCartItemsSuccessAction,
    updateCartDataSuccessAction,
    reservationRequestInProgress,
    reservationRequestFinished,
    resetReservationVariables,
    setSelectedServiceAction,
    resetSelectedServiceAction,
    removeCartItemSuccessAction,
    resetRemoveSuccess,
    resetBalloonPackageItemRemoveSuccess,
    removeCartItemFailureAction,
    changeCartDeliveryModeFailure,
    changeCartDeliveryModeReset,
    updateDeliveryMethodSuccessAction,
    updatePostalCodePartialSuccessAction,
    updatePostalCodeSuccessAction,
    setSelectedFulFillmentOption,
    updatePostalCodeErrorAction,
    getPostalCodeDataAction,
    getPostalCodeDataError,
    resetCartValidationsAction,
    updateAnalyticsInfo,
    getMergeCartSuccessAction,
    setSelectedAuthCartItems,
    setXhrInfoForGetCart,
    setErrorInfoOnCart,
    mergeCartSuccessAction,
    getCartItemsForAuthForMergeSuccessAction,
    updatePostalCodeDispatchStartedAction,
    updatePostalCodeFailureAction,
    setSflToastVariables,
    shippingEstimationInProgress,
    shippingEstimationSuccess,
    shippingEstimationError,
    resetShippingEstimation,
    applyPromoCodeSuccessAction,
    applyPromoCodeErrorAction,
    resetPromoCodeDataAction,
    setCartPromoCodeErrorToastMsg,
    clearAddToCartErrorDataAction,
    resetShowAvailabilitiesChangeToast,
    resetPostalCodeChangeSuccess,
    resetInitCheckoutError,
    initCheckoutFailure,
    getMiniCartItemsSuccessAction,
    getCheckoutCartItemsSuccessAction,
    setExpressDeliverySelection,
    setExpressDeliveryPostalCodeOutOfRange,
    setShoppingCartLimitItemsFailure,
    clearShoppingCartLimitItemsFailure,
    renderEstimateShippingModal,
    setNoPostalCodeError,
    setCheckoutAuthCartLoaded,
    addToCartBundleSuccess,
    addToCartBundleFailure,
    resetAddToCartBundleState,
    removeCartBalloonPackageItemSuccessAction,
    setCheckErrorsInCart,
    getMiniCartPromoDataSuccessAction,
} from '../actionCreators'
import * as actions from '../actionCreators'
import {
    CartModificationsDTO,
    OrderEntries,
    ServiceDTO,
    FilteredCartData,
    CartModificationDTO,
    PostalCodeData,
    CartResponseErrorDTO,
    CartItemsData,
    CartOrderEntries,
    SwitchFulfillmentType,
    ShippingEstimation,
    AddedServiceToCart,
    XhrInfo,
    sflAddAndMoveCart,
    MiniCartData,
    DeliveryCostStatus,
    IAddToCartBundleRequestError,
    CartResponseErrorData,
    VersionedMiniCartData,
    MiniCartPromoData,
} from '../models/cart.interface'
import { HttpRequestState } from '../../httpClient/client.type'
import { CartPageAnalyticsInfo } from '../../analytics/components/Cart/cartPage.type'
import { CartUpdateType } from '../../components/ShoppingCart/ShoppingCart.type'
import { MagicNumber } from '../../analytics/analytics.type'
import { hasCartItemsBecomeUnavailable } from '../../components/ShoppingCart/CartItem.util'
import { convertToMiniCartData, convertToMiniCartDataWithoutVersion } from '../../utils/convertToMiniCartData'
import isActionPartOfList from '../utils/isActionPartOfList'
import { EntryGroups } from '../models/orderConfirmation.interface'

type Action = ActionType<typeof actions>

export interface CartState {
    cartGUIDCreated: boolean
    finalCartModifications: CartModificationsDTO[]
    cartModifications: CartModificationsDTO
    cartServices: ServiceDTO[]
    orderEntries: OrderEntries[]
    addedServiceToCart: AddedServiceToCart
    cartItemsData: FilteredCartData
    miniCartItemsData: MiniCartData
    checkoutCartItemsData: FilteredCartData
    cartValidations: CartResponseErrorDTO
    serviceData: ServiceDTO
    removeCartItemFailureData: {
        errorFlag: boolean
        errorMessage: string
    }
    deliveryMethodChangeError: CartResponseErrorDTO
    postalCodeSuccess: boolean
    postalCodePartialSuccess: boolean
    postalCodeFailure: boolean
    removeSuccess: boolean
    removeBalloonPackageItemSuccess: boolean
    fulFillmentOption: SwitchFulfillmentType
    postalCodeError: boolean
    postalCodeData: PostalCodeData
    postalCodeDispatchStarted: boolean
    postalCodeDataError: CartModificationDTO
    addToCartErrorData: CartResponseErrorDTO
    // ajax calls status info
    xhrInfo?: XhrInfo
    analyticsInfo: CartPageAnalyticsInfo
    mergedCart: CartItemsData
    mergedCartItems: FilteredCartData
    selectedAuthCart: CartOrderEntries[]
    changeShippingFeeSuccess: boolean
    areErrorsPresentOnCart: boolean
    isReservationRequestInProgress: boolean
    isReservationRequestFinished: boolean
    mergeCartSuccess: boolean
    cartItemsDataForAuth: FilteredCartData
    isEtaError: boolean
    shippingEstimation: ShippingEstimation
    applyPromoCodeSuccess: string
    applyPromoCodeError: CartResponseErrorData | null
    cartPromoCodeErrorToastMsg: string
    showAvailabilityChangedToast: boolean
    sflAddAndMoveCart: sflAddAndMoveCart
    isInitCheckoutError: boolean
    isExpressDeliverySelected: boolean
    checkoutAuthCartLoaded: boolean
    addToCartBundleSuccess?: boolean
    addToCartBundleFailure?: IAddToCartBundleRequestError | null
    isExpressDeliveryOutOfRange?: boolean
    cartLimitItemsFailure: CartResponseErrorDTO
    showEstimateShippingModal?: boolean
    showNoPostalCodeError?: boolean
    entryGroups: EntryGroups[]
    heliumInflationAddToCartEnabled?: boolean
    miniCartPromoItemsData: MiniCartPromoData
    checkErrorsInCart: () => boolean
}

export const initialState = {
    cartGUIDCreated: false,
    cartModifications: {} as CartModificationsDTO,
    finalCartModifications: [] as CartModificationsDTO[],
    orderEntries: [] as OrderEntries[],
    cartServices: [] as ServiceDTO[],
    addedServiceToCart: {
        success: false,
        showErrorToast: false,
        selectedService: {} as ServiceDTO,
    },
    // eslint-disable-next-line no-warning-comments
    // TODO: cart information should be listed at root level
    cartItemsData: {} as FilteredCartData,
    miniCartItemsData: {} as MiniCartData,
    checkoutCartItemsData: {} as FilteredCartData,
    cartValidations: {} as CartResponseErrorDTO,

    serviceData: {} as ServiceDTO,
    removeCartItemFailureData: {
        errorFlag: false,
        errorMessage: '',
    },
    deliveryMethodChangeError: {} as CartResponseErrorDTO,
    postalCodeSuccess: false,
    postalCodePartialSuccess: false,
    postalCodeFailure: false,
    removeSuccess: false,
    removeBalloonPackageItemSuccess: false,
    fulFillmentOption: {} as SwitchFulfillmentType,
    postalCodeError: false,
    postalCodeData: {} as PostalCodeData,
    postalCodeDataError: {} as CartModificationDTO,
    postalCodeDispatchStarted: false,
    addToCartErrorData: {} as CartResponseErrorDTO,
    xhrInfo: {
        getCartInfo: HttpRequestState.notStarted,
    },
    analyticsInfo: {
        isPageLoadSent: false,
        updateType: CartUpdateType.NO_UPDATE,
    },
    mergedCart: {} as CartItemsData,
    mergedCartItems: {} as FilteredCartData,
    selectedAuthCart: [] as CartOrderEntries[],
    changeShippingFeeSuccess: false,
    areErrorsPresentOnCart: false,
    isReservationRequestFinished: false,
    isInitCheckoutError: false,
    mergeCartSuccess: false,
    cartItemsDataForAuth: {} as FilteredCartData,
    isEtaError: false,
    sflAddAndMoveCart: {
        addedToSflSuccess: false,
        addedToSflFailure: false,
        moveToSflSuccess: false,
        moveToSflFailure: false,
        removeSflFailure: false,
        getSflFailure: false,
    },
    shippingEstimation: { isFetching: false, result: null, error: null },
    applyPromoCodeSuccess: '',
    applyPromoCodeError: null,
    showAvailabilityChangedToast: false,
    isExpressDeliveryOutOfRange: false,
    cartLimitItemsFailure: {} as CartResponseErrorDTO,
    showEstimateShippingModal: false,
    showNoPostalCodeError: false,
    checkoutAuthCartLoaded: false,
    entryGroups: [],
    addToCartBundleSuccess: false,
    addToCartBundleFailure: null,
    heliumInflationAddToCartEnabled: false,
    checkErrorsInCart: () => false,
} as unknown as CartState

const addToCartActionsList = [
    addToCartGuestSuccessAction,
    addToCartGuestFailureAction,
    clearAddToCartErrorDataAction,
    clearAddToCartResponse,
    addServiceToCartSuccessAction,
    addServiceToCartFailureAction,
    setShoppingCartLimitItemsFailure,
    clearShoppingCartLimitItemsFailure,
    addToCartBundleSuccess,
    addToCartBundleFailure,
    resetAddToCartBundleState,
]

const postalCodeActionsList = [
    updatePostalCodeFailureAction,
    updatePostalCodeSuccessAction,
    updatePostalCodePartialSuccessAction,
    updatePostalCodeErrorAction,
    updatePostalCodeDispatchStartedAction,
    getPostalCodeDataAction,
    getPostalCodeDataError,
    resetPostalCodeChangeSuccess,
    setExpressDeliveryPostalCodeOutOfRange,
]
const promoCodeActionsList = [
    applyPromoCodeSuccessAction,
    applyPromoCodeErrorAction,
    resetPromoCodeDataAction,
    setCartPromoCodeErrorToastMsg,
]
const shippingEstimationActionsList = [
    shippingEstimationInProgress,
    shippingEstimationSuccess,
    shippingEstimationError,
    resetShippingEstimation,
]

const cartItemsDataActionsList = [
    getCartItemsSuccessAction,
    getMiniCartItemsSuccessAction,
    getMiniCartPromoDataSuccessAction,
    getCheckoutCartItemsSuccessAction,
    updateCartDataSuccessAction,
    updateDeliveryMethodSuccessAction,
    removeCartItemSuccessAction,
    removeCartBalloonPackageItemSuccessAction,
]

/**
 * Function to perform actions with respect to postal code
 * @param {CartState} state
 * @param {Action} action
 * @returns {CartState}
 */
// eslint-disable-next-line sonar/cyclomatic-complexity
const postalCodeActions = (state: CartState = initialState, action: Action): CartState => {
    if (action.type === getType(updatePostalCodeFailureAction)) {
        return {
            ...state,
            cartValidations: action.payload,
            postalCodeFailure: true,
            postalCodeDispatchStarted: false,
            postalCodePartialSuccess: false,
        }
    } else if (action.type === getType(updatePostalCodeSuccessAction)) {
        return {
            ...state,
            cartItemsData: action.payload,
            checkoutCartItemsData: action.payload,
            miniCartItemsData: convertToMiniCartData(action.payload.cart, false),
            areErrorsPresentOnCart: false,
            postalCodeSuccess: !action.payload.isPostalCodePartialSuccess,
            postalCodeFailure: false,
            postalCodePartialSuccess: false,
            postalCodeError: action.payload?.isPostalCodePartialSuccess || false,
            postalCodeDispatchStarted: false,
            changeShippingFeeSuccess:
                action.payload.cart.deliveryCostStatus === DeliveryCostStatus.APPLIED ||
                action.payload.cart.deliveryCostStatus === DeliveryCostStatus.UPDATED,
            isEtaError: false,
            showAvailabilityChangedToast: hasCartItemsBecomeUnavailable(
                state.cartItemsData.cart?.orderEntries,
                action.payload.cart.orderEntries,
            ),
        }
    } else if (action.type === getType(updatePostalCodePartialSuccessAction)) {
        const xhrInfo = action.payload?.xhrInfo
        return {
            ...state,
            cartItemsData: action.payload,
            miniCartItemsData: convertToMiniCartData(action.payload.cart, false),
            postalCodeFailure: true,
            postalCodeDispatchStarted: false,
            postalCodePartialSuccess: true,
            xhrInfo,
        }
    } else if (action.type === getType(updatePostalCodeErrorAction)) {
        return {
            ...state,
            cartValidations: action.payload,
            postalCodeError: true,
            postalCodeDispatchStarted: false,
        }
    } else if (action.type === getType(updatePostalCodeDispatchStartedAction)) {
        return { ...state, postalCodeDispatchStarted: action.payload }
    } else if (action.type === getType(getPostalCodeDataAction)) {
        return { ...state, postalCodeData: action.payload }
    } else if (action.type === getType(getPostalCodeDataError)) {
        return { ...state, postalCodeDataError: action.payload }
    } else if (action.type === getType(resetPostalCodeChangeSuccess)) {
        return { ...state, postalCodeSuccess: false }
        // eslint-disable-next-line sonarjs/elseif-without-else
    } else if (action.type === getType(setExpressDeliveryPostalCodeOutOfRange)) {
        return { ...state, isExpressDeliveryOutOfRange: action.payload }
    }
    return state
}

/**
 * Function to perform actions with respect to promo code
 * @param {CartState} state
 * @param {Action} action
 * @returns {CartState}
 */
const promoCodeActions = (state: CartState = initialState, action: Action): CartState => {
    if (action.type === getType(applyPromoCodeSuccessAction)) {
        return { ...state, applyPromoCodeSuccess: action.payload, applyPromoCodeError: null }
    } else if (action.type === getType(applyPromoCodeErrorAction)) {
        return { ...state, applyPromoCodeSuccess: '', applyPromoCodeError: action.payload }
    } else if (action.type === getType(resetPromoCodeDataAction)) {
        return { ...state, applyPromoCodeSuccess: '', applyPromoCodeError: null }
        // eslint-disable-next-line sonarjs/elseif-without-else
    } else if (action.type === getType(setCartPromoCodeErrorToastMsg)) {
        return { ...state, cartPromoCodeErrorToastMsg: action.payload }
    }
    return state
}

/**
 * Function to perform actions with respect to adding items or service to cart
 * @param {CartState} state
 * @param {Action} action
 * @returns {CartState}
 */
// eslint-disable-next-line sonar/cyclomatic-complexity
const addToCartActions = (state: CartState = initialState, action: Action): CartState => {
    if (action.type === getType(addToCartGuestSuccessAction)) {
        return {
            ...state,
            cartModifications: action.payload.cartModifications[0],
            finalCartModifications: action.payload.cartModifications,
            cartServices: action.payload.cartModifications[0].entry.servicesList,
            entryGroups: action.payload.entryGroups,
            heliumInflationAddToCartEnabled: action.payload.heliumInflationAddToCartEnabled,
        }
    } else if (action.type === getType(addToCartGuestFailureAction)) {
        return { ...state, addToCartErrorData: action.payload }
    } else if (action.type === getType(clearAddToCartErrorDataAction)) {
        return { ...state, addToCartErrorData: {} as CartResponseErrorDTO }
    } else if (action.type === getType(clearAddToCartResponse)) {
        return {
            ...state,
            cartModifications: {} as CartModificationsDTO,
            cartServices: [] as ServiceDTO[],
            addedServiceToCart: {
                success: false,
                showErrorToast: false,
                selectedService: {} as ServiceDTO,
            },
        }
    } else if (action.type === getType(addServiceToCartSuccessAction)) {
        return {
            ...state,
            addedServiceToCart: {
                success: action.payload.success,
                showErrorToast: action.payload.showErrorToast,
                selectedService: action.payload.selectedService,
            },
        }
    } else if (action.type === getType(addServiceToCartFailureAction)) {
        return {
            ...state,
            addedServiceToCart: {
                success: action.payload.success,
                showErrorToast: action.payload.showErrorToast,
                selectedService: {} as ServiceDTO,
            },
        }
    } else if (action.type === getType(setShoppingCartLimitItemsFailure)) {
        return { ...state, cartLimitItemsFailure: action.payload }
    } else if (action.type === getType(clearShoppingCartLimitItemsFailure)) {
        return { ...state, cartLimitItemsFailure: {} as CartResponseErrorDTO }
    } else if (action.type === getType(addToCartBundleSuccess)) {
        return { ...state, addToCartBundleSuccess: true, addToCartBundleFailure: null }
    } else if (action.type === getType(actions.addToCartBundleFailure)) {
        return { ...state, addToCartBundleFailure: action.payload, addToCartBundleSuccess: false }
        // eslint-disable-next-line sonarjs/elseif-without-else
    } else if (action.type === getType(actions.resetAddToCartBundleState)) {
        return { ...state, addToCartBundleFailure: null, addToCartBundleSuccess: false }
    }
    return state
}

/**
 * Function to perform actions with respect to shipping estimations
 * @param {CartState} state
 * @param {Action} action
 * @returns {CartState}
 */
const shippingEstimationActions = (state: CartState = initialState, action: Action): CartState => {
    if (action.type === getType(shippingEstimationInProgress)) {
        return {
            ...state,
            shippingEstimation: {
                isFetching: action.payload,
                result: state.shippingEstimation.result,
                error: null,
            },
        }
    } else if (action.type === getType(shippingEstimationSuccess)) {
        return { ...state, shippingEstimation: { isFetching: false, result: action.payload, error: null } }
    } else if (action.type === getType(shippingEstimationError)) {
        return { ...state, shippingEstimation: { isFetching: false, result: null, error: action.payload } }
        // eslint-disable-next-line sonarjs/elseif-without-else
    } else if (action.type === getType(resetShippingEstimation)) {
        return { ...state, shippingEstimation: { isFetching: false, result: null, error: null } }
    }
    return state
}

/**
 * Function to perform actions with respect to cart items - get, update, removal
 * @param {CartState} state
 * @param {Action} action
 * @returns {CartState}
 */
const cartItemsDataActions = (state: CartState = initialState, action: Action): CartState => {
    if (action.type === getType(getCartItemsSuccessAction)) {
        // just a heck to avoid big flow
        const xhrInfo = action.payload?.xhrInfo
        delete action.payload?.xhrInfo
        return Object.assign(
            {},
            { ...state },
            { cartItemsData: action.payload, xhrInfo, areErrorsPresentOnCart: false, isEtaError: false },
            { miniCartItemsData: convertToMiniCartData(action.payload.cart, false) },
        )
    } else if (action.type === getType(getMiniCartItemsSuccessAction)) {
        return {
            ...state,
            miniCartItemsData: convertToMiniCartDataWithoutVersion(action.payload as VersionedMiniCartData),
        }
    } else if (action.type === getType(getMiniCartPromoDataSuccessAction)) {
        return {
            ...state,
            miniCartPromoItemsData: action.payload,
        }
    } else if (action.type === getType(getCheckoutCartItemsSuccessAction)) {
        const xhrInfo = action.payload?.xhrInfo
        delete action.payload?.xhrInfo
        return {
            ...state,
            xhrInfo,
            checkoutCartItemsData: action.payload,
            miniCartItemsData: convertToMiniCartData(action.payload.cart, false),
        }
    } else if (action.type === getType(updateCartDataSuccessAction)) {
        return {
            ...state,
            cartItemsData: action.payload,
            checkoutCartItemsData: action.payload,
            miniCartItemsData: convertToMiniCartData(action.payload.cart, false),
            areErrorsPresentOnCart: false,
            isEtaError: false,
        }
    } else if (action.type === getType(updateDeliveryMethodSuccessAction)) {
        return {
            ...state,
            cartItemsData: action.payload,
            areErrorsPresentOnCart: false,
            isEtaError: false,
            miniCartItemsData: convertToMiniCartData(action.payload.cart, false),
        }
    } else if (action.type === getType(removeCartItemSuccessAction)) {
        return {
            ...state,
            cartItemsData: action.payload,
            miniCartItemsData: convertToMiniCartData(action.payload.cart, false),
            removeSuccess: true,
            areErrorsPresentOnCart: false,
            isEtaError: false,
        }
        // eslint-disable-next-line sonarjs/elseif-without-else
    } else if (action.type === getType(removeCartBalloonPackageItemSuccessAction)) {
        return {
            ...state,
            cartItemsData: action.payload,
            miniCartItemsData: convertToMiniCartData(action.payload.cart, false),
            removeBalloonPackageItemSuccess: true,
            areErrorsPresentOnCart: false,
            isEtaError: false,
        }
    }
    return state
}

/**
 * Function to perform actions with respect to shopping cart
 * @param {CartState} state
 * @param {Action} action
 * @returns {CartState}
 */
// eslint-disable-next-line sonar/cyclomatic-complexity
export const cartReducer = (state: CartState = initialState, action: Action): CartState => {
    if (action.type === getType(resetRemoveSuccess)) {
        return { ...state, removeSuccess: false }
    } else if (action.type === getType(resetBalloonPackageItemRemoveSuccess)) {
        return { ...state, removeBalloonPackageItemSuccess: false }
    } else if (action.type === getType(setSflToastVariables)) {
        return {
            ...state,
            sflAddAndMoveCart: { ...action.payload },
        }
    } else if (isActionPartOfList(addToCartActionsList, action.type)) {
        return addToCartActions(state, action)
    } else if (isActionPartOfList(postalCodeActionsList, action.type)) {
        return postalCodeActions(state, action)
    } else if (isActionPartOfList(promoCodeActionsList, action.type)) {
        return promoCodeActions(state, action)
    } else if (isActionPartOfList(shippingEstimationActionsList, action.type)) {
        return shippingEstimationActions(state, action)
        // eslint-disable-next-line sonarjs/elseif-without-else
    } else if (isActionPartOfList(cartItemsDataActionsList, action.type)) {
        return cartItemsDataActions(state, action)
    }
    switch (action.type) {
        case getType(resetCartGUIDCreatedFlagAction): {
            return { ...state, cartGUIDCreated: false }
        }
        case getType(resetReservationVariables): {
            return {
                ...state,
                isReservationRequestInProgress: false,
                isReservationRequestFinished: false,
            }
        }
        case getType(reservationRequestInProgress): {
            return { ...state, isReservationRequestInProgress: true }
        }
        case getType(reservationRequestFinished): {
            return {
                ...state,
                isReservationRequestInProgress: false,
                isReservationRequestFinished: true,
            }
        }
        case getType(resetInitCheckoutError): {
            return { ...state, isInitCheckoutError: false }
        }
        case getType(initCheckoutFailure): {
            return { ...state, isInitCheckoutError: true }
        }
        case getType(setSelectedServiceAction): {
            return { ...state, serviceData: action.payload as unknown as ServiceDTO }
        }
        case getType(resetSelectedServiceAction): {
            return { ...state, serviceData: {} as ServiceDTO }
        }
        case getType(removeCartItemFailureAction): {
            return { ...state, removeCartItemFailureData: action.payload }
        }
        case getType(changeCartDeliveryModeFailure): {
            return { ...state, deliveryMethodChangeError: action.payload }
        }
        case getType(changeCartDeliveryModeReset): {
            return { ...state, deliveryMethodChangeError: {} as CartResponseErrorDTO }
        }
        case getType(setSelectedFulFillmentOption): {
            return { ...state, fulFillmentOption: action.payload }
        }
        case getType(resetCartValidationsAction): {
            return {
                ...state,
                cartValidations: { successFlag: false } as unknown as CartResponseErrorDTO,
                postalCodeError: false,
                postalCodeSuccess: false,
                postalCodeFailure: false,
                postalCodePartialSuccess: false,
                addedServiceToCart: {
                    success: false,
                    showErrorToast: false,
                    selectedService: {} as ServiceDTO,
                },
                changeShippingFeeSuccess: false,
                sflAddAndMoveCart: {
                    addedToSflSuccess: false,
                    addedToSflFailure: false,
                    moveToSflSuccess: false,
                    moveToSflFailure: false,
                    removeSflFailure: false,
                    getSflFailure: false,
                },
            }
        }
        case getType(updateAnalyticsInfo): {
            return {
                ...state,
                analyticsInfo: Object.assign({}, { ...state.analyticsInfo }, action.payload),
            }
        }
        case getType(getMergeCartSuccessAction): {
            return { ...state, mergedCart: action.payload }
        }
        case getType(setSelectedAuthCartItems): {
            return { ...state, selectedAuthCart: action.payload }
        }
        case getType(setXhrInfoForGetCart): {
            return { ...state, xhrInfo: { getCartInfo: action.payload } }
        }
        case getType(setErrorInfoOnCart): {
            return {
                ...state,
                areErrorsPresentOnCart: action.payload[0] ?? state.areErrorsPresentOnCart,
                isEtaError: action.payload[MagicNumber.ONE],
            }
        }
        case getType(mergeCartSuccessAction): {
            return { ...state, mergeCartSuccess: action.payload }
        }
        case getType(getCartItemsForAuthForMergeSuccessAction): {
            return { ...state, cartItemsDataForAuth: action.payload }
        }
        case getType(resetShowAvailabilitiesChangeToast): {
            return { ...state, showAvailabilityChangedToast: false }
        }
        case getType(setExpressDeliverySelection): {
            return { ...state, isExpressDeliverySelected: action.payload }
        }
        case getType(renderEstimateShippingModal): {
            return { ...state, showEstimateShippingModal: action.payload }
        }
        case getType(setNoPostalCodeError): {
            return { ...state, showNoPostalCodeError: action.payload }
        }
        case getType(setCheckoutAuthCartLoaded): {
            return { ...state, checkoutAuthCartLoaded: action.payload }
        }
        case getType(setCheckErrorsInCart): {
            return { ...state, checkErrorsInCart: action.payload }
        }
        default:
            return state
    }
}
