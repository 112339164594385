import httpClient from '../utils/httpClient'
import { Dispatch } from 'redux'
import { setRoadRatingFetchSuccess } from '../actionCreators'
import localStorageService from '../../utils/localStorageService'
import { libUtils } from '@nl/lib'

const language = libUtils.getLanguage()

const getRoadRatingEndpoint = (): string =>
    document.getElementById('tiresRoadRatingEndpoint')?.getAttribute('data-tiresroadratingsendpoint') || ''

export const setRoadRatings =
    () =>
    // eslint-disable-next-line consistent-return
    (dispatch: Dispatch): Promise<void> | void => {
        const payloadEndpoint = getRoadRatingEndpoint()
        if (payloadEndpoint) {
            return httpClient.apiGet(payloadEndpoint, {}, 'AEM_EXP_FRAG').then(data => {
                if (data.data) {
                    localStorageService.setItem(`road_rating_props_${language}`, JSON.stringify(data.data))
                    dispatch(setRoadRatingFetchSuccess(data.data))
                }
            })
        }
    }
