import React, { useRef, useEffect } from 'react'

import ReactModal from '../ReactModal'
import { previousElementName } from '../config'
import { useClickOutsideClose } from '../Tooltip/useClickOutside'
import { PREFIX } from '../config'
import { applyAkamaiPolicy, modalAccessibilityHandler } from '../../utils'
import { SwapConfirmationModalType } from './SwapConfirmantionModal.type'
import Icon, { BaseIcon } from '../Icon'
import Button from '../Button'
import HeaderBlock from '../HeaderBlock/HeaderBlock'
import { ctaType } from '../HeaderBlock/HeaderBlock.type'
import { offerConstants } from '../OfferCard/OfferCard.constant'
import { disableFocusLock, enableFocusLock } from '../../utils/focusLock'
import ButtonV2 from '../ButtonV2'
import { Animation, Size, Variation } from '../../types'

const SwapConfirmationModal: React.FC<SwapConfirmationModalType> = ({ ...props }) => {
    const {
        isModalOpen,
        title,
        subtitle,
        swapRow,
        swappableRow,
        ctaList,
        closeModal,
        returnPolicy,
        displayCloseButton = true,
    } = props

    const contentRef = useRef(null)

    // Below code is for handling the accessibility when clicked on close button
    const modalClassName = `${PREFIX}-react-modal`
    const focusLockEventRef = useRef<EventListener>()

    const previousElement: HTMLElement = document.querySelector(`button[${previousElementName}]`)
    const componentClassName = `${PREFIX}-swap-modal`
    /**
     * To handle accessibility on keyboard tab. Enables the modal accessibility when clicked on the trigger element.
     * @return {void}
     */
    const closeHandler = (): void => {
        closeModal()
        modalAccessibilityHandler({ modalOpen: false, modalClassName })
        if (previousElement) {
            previousElement.focus() // Highlight the initiated button
            previousElement.removeAttribute(previousElementName) // Removing it when user close it.
        }
    }

    useEffect(() => {
        if (isModalOpen) {
            focusLockEventRef.current = enableFocusLock(contentRef)
        } else {
            disableFocusLock(focusLockEventRef.current)
        }
    }, [isModalOpen])

    /**
     * renders ctaList
     * @returns {JSX.Element[]}
     */
    const renderCTA = (): JSX.Element[] => {
        return ctaList?.map((cta: ctaType, i: number): JSX.Element => {
            // eslint-disable-next-line no-magic-numbers
            return Boolean(cta.btnVersion === 2) ? (
                <ButtonV2
                    key={i}
                    variant={Variation.PRIMARY}
                    size={Size.MEDIUM}
                    color={cta.color}
                    customClass={cta?.modifierClass ? `${componentClassName}__${cta?.modifierClass}` : ''}
                    onClick={() => cta?.ctaClickedCallBack()}>
                    {cta?.showBaseIcon && (
                        <BaseIcon animation={{ type: Animation.PULSE }} size={Size.XL} outline={true} />
                    )}
                    {cta?.ctaLabel}
                </ButtonV2>
            ) : (
                <Button
                    key={i}
                    type={cta?.ctaType as 'primary' | 'secondary' | 'secondary_reverse'}
                    size="small"
                    modifierClass={cta?.modifierClass ? `${componentClassName}__${cta?.modifierClass}` : ''}
                    onClick={() => cta?.ctaClickedCallBack()}>
                    {cta?.ctaLabel}
                </Button>
            )
        })
    }

    useClickOutsideClose(contentRef, () => closeHandler(), isModalOpen, true)

    return (
        <ReactModal isOpen={isModalOpen} closeHandler={() => closeHandler()}>
            <section ref={contentRef} className={componentClassName}>
                {displayCloseButton && (
                    <div className={`${componentClassName}__close-container`}>
                        <Button id="info-button" type="icon_button" onClick={() => closeHandler()}>
                            <Icon type="ct-close" size="lg" />
                        </Button>
                    </div>
                )}
                <div className={`${PREFIX}-xs-align-items-center ${componentClassName}__container`}>
                    <HeaderBlock title={title} subtitle={subtitle} />
                    <div className={`${componentClassName}__body`}>
                        <p className={`${componentClassName}__body__title`}>{swapRow?.label}</p>
                        <div
                            className={`${PREFIX}-row ${PREFIX}-xs-align-items-center ${componentClassName}__swap-card`}>
                            <img
                                className={`${componentClassName}__swap-card__img`}
                                alt={swapRow?.imgAttribute?.alt}
                                {...applyAkamaiPolicy(
                                    swapRow?.imgAttribute?.src,
                                    offerConstants.bannerLogoImgComponentName,
                                    false,
                                    returnPolicy,
                                )}
                            />
                            <span className={`${componentClassName}__swap-card__desc`}>{swapRow?.desc}</span>
                        </div>
                        <p>{swappableRow?.label}</p>
                        <div
                            className={`${PREFIX}-row ${PREFIX}-xs-align-items-center ${componentClassName}__swap-card`}>
                            <img
                                className={`${componentClassName}__swap-card__img`}
                                alt={swappableRow?.imgAttribute?.alt}
                                {...applyAkamaiPolicy(
                                    swappableRow?.imgAttribute?.src,
                                    offerConstants.bannerLogoImgComponentName,
                                    false,
                                    returnPolicy,
                                )}
                            />
                            <span className={`${componentClassName}__swap-card__detail`}>{swappableRow?.desc}</span>
                        </div>
                    </div>
                    <div className={`${componentClassName}__footer`}>{renderCTA()}</div>
                </div>
            </section>
        </ReactModal>
    )
}

export default SwapConfirmationModal
