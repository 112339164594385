import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { BannerImagePath, EmailActivationType } from './EmailActivationCard.type'
import OfferActivationMessage from './ActivationMessage'
import OfferBanner from '../OfferBanner'
import { magicNumber } from '../../utils/magicNumber'
import { getButtonStatus } from '../../utils/ButtonStatus/getButtonStatus'
import { PREFIX, previousElementName } from '../config'
import { Offers } from '../OfferCard/OfferCard.type'
import OfferCard from '../OfferCard'
import OfferFlyout from '../OfferFlyout'
import { checkDataLength } from '../../utils/checkDataLength'
import { offerConstants } from '../OfferCard/OfferCard.constant'
import { applyAkamaiPolicy } from '../../utils/getAkamaiPolicy'
import { stringKeyCodes } from '../../utils/stringKeyCodes'

const EmailActivationCard: React.FC<EmailActivationType> = (props: EmailActivationType): JSX.Element => {
    const {
        ClassName,
        selectedCard,
        isCardVisible,
        bannerImageList,
        failedActivationImage,
        failedActivationImageAltText,
        title,
        multiOffer,
        selectedOfferArray,
        activatedLabel,
        offerDetailsLabel,
        multipleOfferTilesSuccessMessage,
        offerEndsMessage,
        offerEndsTodayMessage,
        offerEndsDateMessage,
        akamaiPolicy,
        activateLabel,
        redeemedLabel,
        moreDetailsLabel,
        callCertona,
        recomendationList,
        a11yLabelForOfferDetails,
    } = props
    const {
        returnPolicy,
        fallbackOfferImageUrl,
        offersCardImgComponentName,
        fallbackImageForBrandLogo,
        fallbackImageForBrandLogoAltText,
        bannerLogoImgComponentName,
    } = akamaiPolicy || {}

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [selectedOffer, setSelectedOffer] = useState<Offers>({} as Offers)

    const openOfferModal = (
        offerInModal: Offers,
        event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<HTMLElement>,
    ): void => {
        const viewAllOffersCTAEle = event.currentTarget as HTMLElement
        viewAllOffersCTAEle.setAttribute(previousElementName, 'true')
        setIsModalOpen(true)
        setSelectedOffer(offerInModal)
        callCertona && callCertona()
    }

    const columns = {
        md: magicNumber.TWELVE / magicNumber.FOUR,
        sm: magicNumber.TWELVE / magicNumber.THREE,
        xs: magicNumber.THREE,
    }

    const labels = {
        activatedLabel,
        activateLabel,
        redeemedLabel,
    }

    const offerDetailsCardElement = (): JSX.Element => {
        if (isCardVisible) {
            const { imageUrl, badgeImageUrl, displayBanner } = selectedCard

            const setBannerLogo = (bannerName: string): BannerImagePath | undefined => {
                const getBannerName = bannerName?.toString()
                return (
                    bannerImageList &&
                    bannerImageList.find((imageList: BannerImagePath) => getBannerName === imageList.bannerId)
                )
            }
            const { bannerImagePath, bannerImageAlt } = setBannerLogo(displayBanner) || {}
            const mutipleBanner = displayBanner?.includes(',')

            const brandImage = mutipleBanner ? fallbackImageForBrandLogo : bannerImagePath
            const brandImageAlt = mutipleBanner ? fallbackImageForBrandLogoAltText : bannerImageAlt
            return (
                <div
                    className={`${ClassName}_offer-card`}
                    onClick={(e: React.MouseEvent<Element, MouseEvent>) => openOfferModal(selectedOfferArray[0], e)}
                    onKeyDown={(e: React.KeyboardEvent<HTMLElement>) => {
                        if (e.key === stringKeyCodes.space || e.key === stringKeyCodes.enter) {
                            e.preventDefault()
                            openOfferModal(selectedOfferArray[0], e)
                        }
                    }}
                    role="button"
                    tabIndex={0}>
                    <OfferBanner
                        bannerImagePath={brandImage}
                        bannerImageAlt={brandImageAlt}
                        badgeImageAlt="Banner Image"
                        badgeImageUrl={badgeImageUrl}
                        returnPolicy={returnPolicy}
                        bannerLogoImgComponentName={bannerLogoImgComponentName}
                    />
                    <picture>
                        <source
                            type="image/png"
                            {...applyAkamaiPolicy(imageUrl, offersCardImgComponentName, false, returnPolicy)}
                        />
                        <img
                            className={`${ClassName}_offer-card_image`}
                            alt=""
                            {...applyAkamaiPolicy(
                                fallbackOfferImageUrl,
                                offersCardImgComponentName,
                                false,
                                returnPolicy,
                            )}
                        />
                    </picture>
                    <span className="sr-only">{a11yLabelForOfferDetails}</span>
                </div>
            )
        } else if (title) {
            return (
                <div className={`${ClassName}_offer-card ${ClassName}_offer-card_error`}>
                    <img
                        className={`${ClassName}_offer-card_image`}
                        src={failedActivationImage}
                        alt={failedActivationImageAltText}
                    />
                </div>
            )
        } else {
            return null
        }
    }

    const renderOfferCard = (): JSX.Element => {
        return (
            <div className={`${ClassName}_offer-container`}>
                <div
                    data-testid="rendermultipleoffers"
                    dangerouslySetInnerHTML={{ __html: multipleOfferTilesSuccessMessage }}
                />
                {selectedOfferArray?.map((offer: Offers, index: number) => (
                    <OfferCard
                        key={`offer_${index}`}
                        bannerImageList={bannerImageList}
                        offerEndsLabel={offerEndsMessage}
                        offerEndsTodayLabel={offerEndsTodayMessage}
                        offerEndsDateLabel={offerEndsDateMessage}
                        offer={offer}
                        openModal={openOfferModal}
                        activateOffer={() => {
                            return null
                        }}
                        modalLabel={offerDetailsLabel}
                        moreDetailsLabel={moreDetailsLabel}
                        status={getButtonStatus(offer?.offerStatus, offerConstants, labels)}
                        columns={columns}
                        akamaiPolicy={akamaiPolicy}
                    />
                ))}
            </div>
        )
    }

    /**
     * function to render OfferFlyout
     * @returns {JSX.Element}
     */
    const offerModalWrapper = () => {
        return (
            <>
                {checkDataLength(selectedOffer) && isModalOpen && (
                    <div className={`${PREFIX}__offers_details`}>
                        <OfferFlyout
                            modalLabel={offerDetailsLabel}
                            moreDetailsLabel={moreDetailsLabel}
                            activatedLabel={activatedLabel}
                            offerEndsTodayLabel={''}
                            offerEndsLabel={''}
                            redeemedLabel={''}
                            {...props}
                            isOpen={isModalOpen}
                            setIsFlyoutOpen={value => setIsModalOpen(value)}
                            offer={selectedOffer}
                            activateOffer={() => {
                                return null
                            }}
                            status={getButtonStatus(selectedOffer?.offerStatus, offerConstants, labels)}
                            columns={columns}
                            akamaiPolicy={akamaiPolicy}
                            recomendationList={recomendationList}
                        />
                    </div>
                )}
            </>
        )
    }

    /**
     * Function to render Email activation cards multiple offer activation card or single offer activation card
     * @returns {JSX.Element}
     */
    const renderEmailActivationCard = (): JSX.Element => {
        return !isCardVisible && multiOffer && selectedOfferArray ? (
            <div className={`${ClassName}_multiple`}>{renderOfferCard()}</div>
        ) : (
            <div className={ClassName}>
                {offerDetailsCardElement()}
                <OfferActivationMessage {...props} openModal={openOfferModal} />
            </div>
        )
    }

    return (
        <>
            {renderEmailActivationCard()}
            {offerModalWrapper()}
        </>
    )
}

EmailActivationCard.propTypes = {
    ClassName: PropTypes.string,
    messages: PropTypes.object,
    isCardVisible: PropTypes.bool,
    selectedCard: PropTypes.object,
    selectedOfferArray: PropTypes.array,
}

export default EmailActivationCard
