/* eslint-disable max-lines */
/**
 * This file contains all imports and exports of lib folder.
 */
import DatePicker from './components/DatePicker'
import TimePicker from './components/TimePicker'
import Button from './components/Button'
import ButtonV2, { IconButton, SpinnerButton } from './components/ButtonV2'
import {
    Variation,
    Size,
    Status,
    ITitleDesc,
    MultiField,
    Animation as GlobalAnimation,
    BreakpointKeys,
    BreakpointSettings,
} from './types'
import { ButtonColors, ButtonType, ButtonProps } from './components/ButtonV2/Button.types'
import { CommonButtonClass } from './components/ButtonV2/Button.constants'
import Icon, { BaseIcon } from './components/Icon'
import Price from './components/Price'
import TextInput from './components/TextInput'
import Switch from './components/Switch'
import Tag from './components/Tag'
import Table from './components/Table'
import Modal from './components/Modal'
import Resources from './components/Resources'
import Variants from './components/Variants'
import Dropdown from './components/Dropdown'
import JumpList from './components/JumpList'
import CascadingDropdown from './components/CascadingDropdown'
import ServiceInstallation from './components/ServiceInstallation'
import StickyTOC from './components/StickyTOC'
import Checkbox from './components/Checkbox'
import Radio from './components/Radio'
import CustomDropdown from './components/CustomDropdown'
import StickyBuyBar from './components/StickyBuyBar'
import RecommendationsList from './components/RecommendationsList'
import UrgencyMessage from './components/UrgencyMessage'
import Tooltip from './components/Tooltip'
import Overview from './components/Overview'
import Specifications from './components/Specifications'
import SeoTable from './components/SeoTable'
import RoadRating from './components/RoadRating'
import Features from './components/Features'
import VariantsWrapper from './components/VariantsWrapper'
import ProductInformation from './components/ProductInfo'
import StaticProductTitle from './components/StaticProductTitle'
import Review from './components/Review'
import ProductGridView from './components/ProductGridView'
import ButtonToggle from './components/GridListToggle'
import Toast from './components/Toast'
import Badges from './components/Badges'
import SearchBar from './components/SearchBar'
import DataGrid from './components/DataGrid'
import Pagination from './components/Pagination'

import {
    searchConstants,
    Suggest,
    SearchAnalytics,
    SearchBarProps,
    Suggestion,
    CacheData,
    HistorySuggestion,
} from './components/SearchBar/SearchBar.type'
import HeaderStoreLocator from './components/HeaderStoreLocator'
import SearchTitle from './components/SearchTitle'
import QuantitySelector from './components/QuantitySelector'
import Overlay from './components/Overlay'
import ReactModal from './components/ReactModal'
import RichTextModal from './components/RichTextModal'
import LinkCard from './components/LinkCommonCard'
import CardInput from './components/CardInput'
import Wishlist from './components/Wishlist'
import Cart from './components/Cart'
import ClampLinesEllipsis from './components/ClampLinesEllipsis'
import CartFlyout from './components/CartFlyout'
import NavigationFlyout from './components/NavigationFlyout'
import Accordion from './components/Accordion'
import Pill from './components/Pill'
import BannerDisclaimer from './components/BannerDisclaimer/BannerDisclaimer'
import { collapseControl } from './utils/CollapseControl/collapseControl'
import {
    checkInstanceOf,
    checkInstanceOfPrice,
    getFormattedPriceValue,
} from './components/ProductReusableCard/instanceCheckingUtil'
import CommonCardWrapper from './components/CommonCardWrapper'
import CommonCard from './components/CommonCard'
import StoreDetails from './components/StoreDetails'
import ProductCartItem from './components/ProductCartItem'
import FacetPanelModal from './components/FacetPanelModal'
import FlyoutModalComponent from './components/FlyoutModalComponent'
import CartServicesComponent from './components/CartServices'
// eslint-disable-next-line sonar/no-built-in-override
import Map from './components/Map'
import { MapLatLng, MapPosition } from './components/Map/Map.type'
import {
    modalAccessibilityHandler,
    replaceStrWithDynamicVal,
    replaceMultipleStrWithDynamicVal,
    addDynamicToken,
    removeHtmlTagsFromString,
    removeHtmlTagsFromStringByName,
    validatePostalCode,
    currentYear,
    yearDropdownCalculation,
    daysInMonth,
    getToastIconType,
    applyAkamaiPolicy,
    getBannerLogo,
    getStringBasedOnCondition,
    replaceStringCharacter,
    isPetroConversionTransaction,
    isAnnualTopupTransaction,
    isPetroOrAnnualTopUpTransactions,
    getTransactionName,
} from './utils'
import { useGlobalResizeEvent, useGlobalScrollEvent, useGlobalBeforeUnloadEvent } from './utils'
import ConfirmationModal from './components/ConfirmationModal'
import TextArea from './components/TextArea'
import EmptyCartWishlist from './components/EmptyCart'
import PostalCodeModal from './components/PostalCodeModal'
import EstimateFeesModal from './components/EstimateFeesModal'
import StoreTooltip from './components/StoreTooltip'
import { getPriceRangePill, getFormattedPrice } from './utils'
import ContactUsByEmail from './components/ContactUsByEmail/ContactUsByEmail'
import SkeletonComponent from './components/Skeleton/SkeletonComponent'
import Autocomplete from './components/Autocomplete/Autocomplete'
import ErrorScreen from './components/ErrorScreen'
import { isArrayNotEmpty } from './utils'
import { isArrayEmpty } from './utils/isArrayEmpty'
import { customDebounce } from './utils/customDebounce'
import { promiseQueue } from './utils/promiseQueue'
import { popUpAEMHandler } from './utils/popUpAEMHandler'
import StoreSelectorModal, {
    getStoreTimings,
    getNextDayStoreTimings,
    checkNeedUpdateWeekdayHours,
} from './components/StoreSelectorModal'
import MediaGalleryViewer from './components/MediaGalleryViewer'
import Rebate from './components/Rebate'
import MergeCartModal from './components/MergeCartModal'
import { getCurrentDay } from './utils/getCurrentDay'
import MegaNavigation from './components/MegaNavigation/MegaNavigation'
import { MegaNavigationDesktop } from './components/MegaNavigationDesktop'
import StoreDetailsModal from './components/StoreDetailsModal'
import AccountsDropdown from './components/AccountsDropdown'
import { generateGoogleMapOnAddress } from './utils/generateGoogleMapOnAddress'
import googlePlaceServices from './utils/GooglePlaceServices/GooglePlaceService'
import { getCapitalizeString } from './utils/getCapitalizeString'
import { BREAKPOINTS, FeeType, CardType, CommonClass } from './components/config'
import Breadcrumb from './components/Breadcrumb'
import VideoGalleryViewer from './components/VideoGalleryViewer'
import { ReferenceComponent } from './components/ReferenceComponent'
import { localization } from './helpers/Localization.helper'
import { compareTwoArrays } from './helpers/getSkuArray.helper'
import { extractKeyValues } from './utils/extractKeyValues'
import CommonStatusToast from './components/CommonStatusToast'
import {
    getFormattedPhoneNumber,
    formatedPhoneNumberAccessibility,
    getPhoneNumber,
} from './utils/getFormattedPhoneNumber'

// eslint-disable-next-line sonar/no-wildcard-import
import * as libUtils from './utils'
import LanguageToggle from './components/LanguageToggle'
import ProductImageComponent from './components/ProductCartItem/ProductImageComponent'
import CommonWarningToast from './components/ProductCartItem/CommonWarningToast'
import ProductCard from './components/ProductReusableCard'
import { checkWasCurrentPrice, isValidPrice } from './helpers/Price.helper'
import { filterSpecification, getFeeTitle } from './components/ProductCartItem/ProductCartItem.helper'
import CategoryCard from './components/CategoryCard'
import { VehicleBanner, BannerType } from './components/VehicleBanner'
import VehicleHeader from './components/VehicleBanner/VehicleHeader'
import NewVehicleBanner from './components/VehicleBanner/NewVehicleBanner'
import { MediaTypeValue } from './components/MediaGalleryViewer/MediaGalleryViewer.type'
import FullPageSpinner from './components/FullPageSpinner/FullPageSpinner'
import InformationModal from './components/InformationModal/'
import { DisplayViewType } from './components/GridListToggle/buttonConstant.type'
import ProductCardVehicle from './components/VehicleProductCard'
// eslint-disable-next-line max-len
import AutomotiveProductCardsVehicleContextWrapper from './components/AutomotiveProductCards/AutomotiveProductCardsVehicleContextWrapper'
import { staggeredSkuLength } from './components/VehicleProductCard/VehicleProduct.constant'
import Tabs from './components/Tabs'
import { useClickOutsideClose } from './components/Tooltip/useClickOutside'
import ProductDoesNotFit from './components/AutomotivePDP/ProductDoesNotFit'
import RoadRatingBadge from './components/RoadRatingBadge/RoadRatingBadge'
import { variantsConstant } from './components/Variants/Variants.constant'
import { VariantsType } from './components/VariantsWrapper/VariantsWrapper.type'
import { scrollToFooter, scrollToElement } from './utils/scrollToFooter'
import { scrollToElementIfExists } from './utils/scrollToTop'
import { scrollToTop } from './utils/scrollToTop'
import {
    isUrl,
    appendReturnUrl,
    urlRedirection,
    appendTrackingInfo,
    checkReturnURL,
    redirectToPage,
    checkPathname,
    modifyRedirectUrlByParams,
    appendQueryParam,
    checkCurrentPage,
} from './utils/isUrl'
import { magicNumber } from './utils/magicNumber'
import { getSourceTypeData } from './utils/getSourceTypeData'
import { checkNestedProps } from './utils/checkNestedProps'
import { getQueryString, getQueryStringParams } from './utils/getQueryString'
import { getPageType, checkNavigationUrl } from './utils/getPageType'
import { getParamValue } from './utils/getParamValue'
import { getPageTitle } from './utils/getPageTitle'
import { getFileNameFromUrl } from './utils/getFileNameFromUrl'
import { isAccountPageType, pageNumberConstants, pageTypes } from './utils/pageTypes'
import getDateTime from './utils/getDateTime'
import {
    getFormattedDateTime,
    getFormattedDateWithComparison,
    getFormattedTimeDate,
    getFormattedDateTimeWithYear,
    getEstimatedTimeOfSendingProduct,
    checkIfDateIsOverdue,
    getCurrentDate,
    linkRewardsAnalytics,
    FormatDateComparisionType,
} from './utils'
import { enableDestructOnUndefinedData } from './utils/enableDestructOnUndefinedData'
import { generateDOBStr, generateDayMonthYearFromDOBStr } from './utils/birthDayService'
import { isBalloonProduct } from './components/ProductCartItem/ProductCartItem.helper'
import { areAllParamsValid, isAtleastOneParamValid } from './utils/validParams'
import CustomTextArea from './components/CustomTextArea'

import {
    fitmentTypeCodeValue,
    VehicleImage,
    CriticalFitment,
    FitmentNote,
    ProductCardType,
    AutoAttributes,
    HybrisMedia,
    RoadRating as RoadRatingType,
    RoadRatingDetail,
    Vehicle,
    iProductFulfillment,
} from './components/ProductGridView/ProductGrid.types'
import { FeeMessage, PriceMessage, SaleMessagesRule } from './components/Price/Price.types'
import { MediaImages } from './components/MediaGalleryViewer/MediaGalleryViewer.type'
import { checkKeyPresenceInArray } from './utils/checkKeyPresenceInArray'
import { totalCurrentPrice, getSetOfFourPriceForStaggered } from './components/VehicleProductCard/VehicleProduct.helper'
import ServiceInstallationMessage from './components/ServiceInstallationMessage'
import { extractServiceBasedOnSku, getPriceValue } from './components/ProductCartItem/ProductCartItem.util'
import AutomotiveErrorCard from './components/AutomotiveErrorCard'
import ProductItemInfo from './components/ProductCartItem/ProductItemInfo'
import { ProductDataDTO, eEntryType, ProductCartItemProps } from './components/ProductCartItem/ProductItemInfo.type'
import { renderItemPrice } from './components/AutomotiveProductCards/AutomotiveProductCards.helper'
import UnfinishedPackageCard from './components/UnfinishedPackageCard/UnfinishedPackageCard'
import { isOrderSTH, isOrderBOPIS } from './components/ProductCartItem/ProductCartItem.helper'
import { QuantitySelectorProps, InteractiveElementEvents } from './components/QuantitySelector/QuantitySelector.types'
import { combineBadges, selectedSKUBadge } from './utils/badgeUtil'
import { isAutoServiceAvailableInStore } from './helpers/Store.helper'
import { checkLastItemArray, checkDataLength, isFalse } from './utils/checkDataLength'
import { getETATimeOptions } from './utils/getETATimeOptions'
import { addClass } from './utils/addClass'
import { removeClass, addAndRemoveHideClass } from './utils/removeClass'
import UserGreeting from './components/UserGreeting'
import LoyaltyCards from './components/LoyaltyCards'
import SignOut from './components/SignOut'
import SignInDropDown from './components/SignInDropDown'
import SelectBanner from './components/SelectBanner'
import LinkOptionsCard from './components/LinkRewards/LinkOptions'
import TermsAndConditions from './components/TermsAndConditions'
import ActiveOwnership from './components/LinkRewards/ActiveOwnership'
import SuccessScreen from './components/SuccessScreen'
import SuccessScreenModal from './components/SuccessScreenModal'
import {
    cardNumberFormatted,
    hashCreditCard,
    formatCreditCard,
    getCardErrorCheck,
    validateCardInput,
    sendError,
    getErrorMessage,
} from './utils/FormatCardNumber/formatCardNumber'
import ProgressBar from './components/ProgressBar'
import Step from './components/Step'
import { getButtonStatus } from './utils/ButtonStatus/getButtonStatus'
import { isOtpErrorCode, getValueByComponent } from './utils/otp.utils'
import { offerComponentLocation, offerConstants, learnMoreModalData } from './components/OfferCard/OfferCard.constant'
import EmptyScreen from './components/EmptyScreen'
import OfferCard from './components/OfferCard'
import OfferFlyout from './components/OfferFlyout'
import getOfferSorting from './utils/getOfferSorting'
import getLoyaltyCards from './utils/LoyaltyCards/getLoyaltyCards'
import { getBalance, isAmountGreaterThan, getDivideByAmount, getMultipleByAmount } from './utils/getBalance'
import InformationCard from './components/InformationCard'
import MessageCard from './components/MessageCard/MessageCard'
import { isMultiplierOffer, sortOffers } from './utils/offersSorter'
import {
    canadaPostApi,
    checkToggle,
    OSType,
    quebecIsocode,
    BannerViewType,
    baseCTFSInputStyles,
    shippingAddressErrorCodes,
    technicalDifficultiesErrCode,
    localeCookieName,
    errorCodes,
    BrandingColors,
    balanceCheckerScreen,
    dateOptions,
    recommendationQueryParam,
    statusLabel,
    TextInputModes,
    errorOrderModificationCodes,
    dashboardBannerTypes,
    Symbols,
    CertonaConstants,
    commonIcons,
    PURCHASE_TYPE,
    REDEEM_TYPE,
    FORWARD_SLASH,
    OtpErrorType,
    otpErrorCodes,
    deviceTypes,
    applyGiftCardErrorCodes,
    daysCalculationConstants,
    linkTargetOptions,
} from './globalConstants/global.constant'
import PreviewDetail from './components/PreviewDetail'
import { signoutRedirection, signoutRedirectionList } from './utils/signoutRedirection'
import { preProcessSvgUseTag } from './utils/preProcessSvgUseTag'
import { updateFilterFacet, filterOffersData, sortByBrandsOrCategory } from './utils/updateFilterFacet'
import TransactionColumn from './components/TransactionColumn'
import TransactionColumnBadge from './components/TransactionColumnBadge'
import TransactionColumnLogo from './components/TransactionColumnLogo'
import { isTriangleSelectMember } from './utils/isTriangleSelectMember'
import { getDateOfBirth } from './utils/getDateOfBirth'
import { DOBFormatConstant } from './utils/DOBFormatConstant.constant'
import { formEmpty } from './utils/formEmpty.util'
import { renderVehicleInfo } from './utils/renderVehicleInfo'
import HeaderBlock from './components/HeaderBlock'
import Barcode from './components/Barcode'
import { getMod10CheckDigit } from './utils/getMod10CheckDigit'
import GiftCard from './components/GiftCard'
import PromoMessages from './components/PromoMessages'
import { RebateProps, RebateData } from './components/Rebate/Rebate.type'
import { excludeQuote, parseQuery } from './utils/parseQuery'

import Filters from './components/FacetPanelModal/Filters'
import GuestCheckout from './components/GuestCheckout'
import CTMoneySnippet from './components/CTMoneySnippet'
import TransactionDetail from './components/TransactionDetail'

// Fix for gatsby build, "window" is not available during server side rendering
import CarouselComp from './components/Carousel'
import RecommendationsComp from './components/Recommendations'
import FeaturedProductListComp from './components/FeaturedProductList'
let Carousel = null
let Recommendations = null
let FeaturedProductList = null
;(() => {
    if (typeof window !== 'undefined') {
        Carousel = CarouselComp
        Recommendations = RecommendationsComp
        FeaturedProductList = FeaturedProductListComp
    }
})()
import { Url } from './components/MegaNavigation/MegaNavigation.type'
import { cardPropTypes, FBTRecommendation } from './components/ProductReusableCard/product.types'
import EmailActivationCard from './components/EmailActivationCard'
import { getCookieValue } from './utils/getCookieValue'
import PreviewCard from './components/PreviewCard'
import LineSummary from './components/LineSummary'
import {
    getFormattedPriceWithLocale,
    getNumberFromFormattedPrice,
    getNumberFromFormattedPriceWithLocale,
} from './utils/formatPrice'
import { ProductBrand } from './components/ProductInfo/product.types'
import { Facet, ParentCategory } from './components/FacetPanelModal/FacetPanelModal.type'
import { Banner } from './components/Banner/Banner'
import Spinner from './components/Spinner/Spinner'
import { getCardNameAndNumber, getDateFromCreditCard, getCardLogo, getCardNumber } from './utils/getCardDetails'
import SnippetCard from './components/SnippetCard'
import { useGenerateSaveMessage } from './components/Price/Price.helper'
import { checkStateProvince, checkAddressChange } from './utils/checkStateProvince'
import CardColumn from './components/CardColumn'
import BrandFooter from './components/BrandFooter'
import HeroBannerSplit from './components/HeroBannerSplit'
import { Calendar } from './components/Calendar/Calendar'
import SkinnyBannerModal from './components/SkinnyBannerModal'
import { FeatureTypes, ChartTypes } from './components/Charts/Charts.types'
import checkPlugInType from './helpers/chartPlugins.helper'
import { createCanvasPattern } from './utils/createCanvasPattern'
import { getDeviceOS } from './utils/getDeviceOS'
import InlineToast from './components/InlineToast'
import { InlineToastType } from './components/InlineToast/InlineToast.type'
import { isCreditCardExpired } from './utils/CreditCardExpired/isCreditCardExpired'
import { getDateDetails } from './helpers/getDateDetails'
import SummaryCard from './components/SummaryCard'
import { getButtonType } from './helpers/getButtonType.helper'
import { badgesAndPrioritiesType } from './components/Badges/Badges.type'

// eslint-disable-next-line sonar/no-wildcard-import
export * from './components/SummaryCard/SummaryCard.type'
// eslint-disable-next-line sonar/no-wildcard-import
export * from '../src/components/VehicleBanner/VehicleBanner.type'
// eslint-disable-next-line sonar/no-wildcard-import
export * from './utils/QueryParameters'
// eslint-disable-next-line sonar/no-wildcard-import
export * from './utils/sanitizeStringContent'
// eslint-disable-next-line sonar/no-wildcard-import
export * from './utils/string.util'

import { MODAL_OPEN_CLASS_NOT_SCROLL } from './components/ReactModal/ReactModal.constant'
import { getAccessibilityId } from './utils/getAccessibilityId'
import Separator from './components/Separator'
// eslint-disable-next-line sonar/no-wildcard-import
export * from './components/Separator/Separator.types'
import BalanceScreen from './components/BalanceScreen/BalanceScreen'
import NotificationBadge from './components/NotificationBadge'
import { getPTEBaseTotal, getTotalPteReward } from './utils/getPTEBaseTotal'
import { LabelType } from './utils/ButtonStatus/getButtonStatus.type'
import ExpandCard from './components/ExpandCard'
import { checkoutRegexPatterns, verificationCodeRegex } from './globalConstants/regexPatterns.constant'
import { getCTA } from './utils/GetCTA/getCTA'
import { getTooltipText } from './utils/GetTooltipText/getTooltipText'
import { checkNotNullAndUndefined, checkUndefined } from './utils/checkNotNullAndUndefined'
import { getAkamaiPolicy } from './utils/akamaiPolicy'
import { useDesktopLayoutState, useMobileLayoutState } from './hooks/layout.hook'
import { stringKeyCodes } from './utils/stringKeyCodes'
import {
    isDateValid,
    maxDaysInMonth,
    getMonthList,
    validateDateBetweenTwoDates,
    getDaysDifference,
} from './utils/date.util'
import InfoMessage from './components/InfoMessage/InfoMessage'
import { isEscPressed, isTabPressed, isEnterPressed, isSpacePressed } from './helpers/checkKeyboardKey.helper'
import { useFocusedTable } from './hooks/useFocusedTable.hook'
import { OptionDescriptors } from './globalConstants/OptionDescriptors.constant'
import { useOnKeyDownOutside } from './utils/useOnKeyDownOutside'
import { useOnKeyUpOutside } from './utils/useOnKeyUpOutside'
import { useOnClickOutside } from './utils/useOnClickOutside'
import { dynamicBackgroundPropTypes } from './components/LinkCommonCard/LinkRewardsCard.types'
import { carouselSettingValues } from './components/Carousel/Carousel.helper'
import arraysElementsAreEqual from './utils/arraysElementsAreEqual'
import { getErrorCodeMessage } from './utils/getErrorCodeMessage'
import { saleOrClearanceBadge } from './components/ProductReusableCard/productCard.constant'
import QcLanguageTab from './components/QcLanguageTab'
import { IQcCheckboxTabType } from './components/QcLanguageTab/QcTabProps.type'
import { getDateFormatNumericYear, getSubscriptionDateTime } from './utils/getDateTime'
import { useIsMobile } from './hooks/useIsMobile.hook'
import { isPrerenderOrNot } from './utils/isPrerender'
import { SuccessScreenPropsData } from './components/SuccessScreen/SuccessScreen.type'
import { ISuccessScreenModalProps } from './components/SuccessScreenModal/SuccessScreenModal.type'
import { IVariant } from './components/Heading/Heading.types'
import { OptionListSize } from './components/JumpList/JumpList.constant'
import { useFocusOnce, useFocusOnceRef, useHandleFocusTrap } from './hooks/useFocusOnce.hook'
import useIsChanged from './hooks/useIsChanged.hook'
import { ISignOutRedirection } from './utils/signoutRedirection.type'
import NavItem from './components/NavItem'
import NavExpansionPanel from './components/NavExpansionPanel'
import Heading from './components/Heading'
import { getNameDetails } from './utils/getAccountHolderName'
import DataTable from './components/DataTable/DataTable'
import TextInputGroup from './components/TextInputGroup'
import TextInputGroupHeading from './components/TextInputGroup/TextInputGroupHeading'
import TextInputGroupPreview from './components/TextInputGroup/TextInputGroupPreview'
import PreviewDetailsList from './components/PreviewDetailsList'
import { ImageAlignment } from './components/SnippetCard/SnippetCard.type'
import SpinnerModal from './components/SpinnerModal'
import VehicleAvailabilitySection from './components/VehicleAvailabilitySection'
import useCallbackOnce from './hooks/useCallbackOnce.hook'
import PillsList from './components/PillsList/PillsList'
import { getInteractiveElements } from './helpers/getInteractiveElements.helper'
import { setFocusToElement } from './utils/setFocusToElement'
import { ButtonStatusType } from './utils/ButtonStatus/getButtonStatus.type'
import { getHeaderButtonLabel } from './utils/getHeaderButtonLabel'
import getUserProfileStatus from './utils/GetUserProfileStatus/GetUserProfileStatus'
import { IDataGrid, IRowData, IDataGridConfig } from './components/DataGrid/DataGrid.type'
import ErrorCard from './components/ErrorCard/ErrorCard'
import SwapConfirmationModal from './components/SwapConfirmationModal'
import { SwapOfferLimit } from './components/OfferCard/OfferCard.type'
import InformativeModal from './components/InformativeModal'
import CircularProgress from './components/CircularProgress'
import { SwapCardContent } from './components/SwapConfirmationModal/SwapConfirmantionModal.type'
import { ProgressType } from './components/CircularProgress/CircularProgress.types'
import { ProgressSizes } from './components/CircularProgress/CircularProgress.types'
import { onElementAvailable } from './utils/elementAvailable'
import CarouselSwiper from './components/CarouselSwiper'
import { getOfferCodesParam, getColumns } from './helpers/loyaltyOffers.helper'
import { TransactionAmountType } from './components/TransactionDetail/TransactionDetail.type'
import {
    handleArrowsAccessibility,
    arrowActionIdentifier,
    handleClickOrEnterAccessibility,
} from './helpers/keyboardAccessibility.helper'
import { Animation, SpinnerStyle, SpinnerTypes } from '@nl/lib/src/components/Spinner/Spinner.types'
import { iconSizes } from '@nl/lib/src/components/Icon/Icon.types'
import { brazeCustomEvents, brazeCustomEventsPages } from './globalConstants/brazeCustomEvents.constant'
import { plaCarouselSchemaId } from './components/Recommendations/Recommendations.constant'
import { disableFocusLock, enableFocusLock } from '@nl/lib/src/utils/focusLock'
import TabsItemContainer from './components/Tabs/TabsItemContainer'
import choose from './utils/choose'
import { getCardType } from './utils/getCardDetails'
import ComparisonTable from './components/ComparisonTable/ComparisonTable'
import { renderClearanceBadge } from './utils/renderClearanceBadge'
import { ProductSourceTypes } from './components/ProductCartItem/ProductItemInfo.type'
import {
    getOfferParams,
    getSourceParam,
    getProductParams,
    getCategoryParams,
    getBaseProductParams,
    getStoreParams,
} from './helpers/braze.helper'
import VerificationScreen from './components/VerificationScreen'
import { VerificationScreenProps } from './components/VerificationScreen/VerificationScreen.type'
import MultiSelectCheckBoxes from './components/MultiSelectCheckBoxes'
import TabsV2 from './components/Tabs/TabsV2'
import TabV2 from './components/Tabs/TabV2'
import { MultiSelectItem } from './components/MultiSelectCheckBoxes/MultiSelectCheckBoxes.types'
import { isL1ShopAllPage, createShopAllLink } from './components/MegaNavigation/ShopAllUrl.helper'
import { ErrorScreenData } from './components/ErrorScreen/ErrorScreen.type'
import { GiftCardInterface, DeleteGiftCard, GiftCardProps } from './components/GiftCard/GiftCard.types'
import ButtonGroup from './components/ButtonGroup'
import { isVerticalFilters } from './components/FacetPanelModal/FacetPanelModal.helper'
import SkipLink from './components/SkipLink'
import SideNavIcon from './components/SideNavIcon'
import TemplateCard from './components/OfferCard/TemplateCard'
import { AkamaiProp, ColumnsType } from './components/OfferCard/OfferCard.type'
import CustomContentCard from './components/CustomContentCard/CustomContentCard'
import {
    BrazeCardType,
    BrazeContentPropsType,
    ContentCardType,
} from './components/CustomContentCard/CustomContentType.type'
import TransactionBackgroundImage from './components/TransactionBackgroundImage'
import CompareProductsFlyout from './components/CompareProductFlyout'
import { ContentVariation } from './components/CustomContentCard/CustomContentType.type'
import { getIsMobile } from './utils/getIsMobile'
import { getFormatValueWithIcon } from './utils/getFormatValueWithIcon'
import { truncateWithEllipsis } from './utils/characterEllipsis'
import { useAnimatedNumber } from './hooks/useAnimatedNumber.hook'

export {
    VehicleAvailabilitySection,
    isPrerenderOrNot,
    Button,
    ButtonV2,
    IconButton,
    SpinnerButton,
    Variation,
    ButtonColors,
    ButtonType,
    ButtonProps,
    CommonButtonClass,
    CommonClass,
    Icon,
    SideNavIcon,
    Price,
    TextInput,
    Switch,
    Tag,
    Table,
    Modal,
    Resources,
    Variants,
    Dropdown,
    JumpList,
    ServiceInstallation,
    Tooltip,
    StickyTOC,
    Checkbox,
    Radio,
    StickyBuyBar,
    RecommendationsList,
    UrgencyMessage,
    Overview,
    Specifications,
    SeoTable,
    RoadRating,
    Features,
    VariantsWrapper,
    ProductInformation,
    Review,
    ProductGridView,
    ButtonToggle,
    Badges,
    Toast,
    SearchBar,
    searchConstants,
    Suggest,
    HeaderStoreLocator,
    SearchTitle,
    QuantitySelector,
    Overlay,
    ReactModal,
    RichTextModal,
    Carousel,
    Recommendations,
    LinkCard,
    CardInput,
    Wishlist,
    Cart,
    ClampLinesEllipsis,
    collapseControl,
    checkInstanceOf,
    checkInstanceOfPrice,
    CartFlyout,
    Pagination,
    DataGrid,
    IDataGrid,
    IRowData,
    IDataGridConfig,
    FeaturedProductList,
    Accordion,
    CommonCardWrapper,
    CommonCard,
    StoreDetails,
    ProductCartItem,
    FacetPanelModal,
    Pill,
    FlyoutModalComponent,
    CartServicesComponent,
    Map,
    MapLatLng,
    replaceStrWithDynamicVal,
    replaceMultipleStrWithDynamicVal,
    addDynamicToken,
    useGlobalResizeEvent,
    useGlobalScrollEvent,
    useGlobalBeforeUnloadEvent,
    ConfirmationModal,
    TextArea,
    EmptyCartWishlist,
    PostalCodeModal,
    EstimateFeesModal,
    StoreTooltip,
    getPriceRangePill,
    getFormattedPrice,
    ContactUsByEmail,
    SkeletonComponent,
    Autocomplete,
    ErrorScreen,
    isArrayNotEmpty,
    isArrayEmpty,
    customDebounce,
    promiseQueue,
    popUpAEMHandler,
    StoreSelectorModal,
    MediaGalleryViewer,
    Rebate,
    MergeCartModal,
    getCurrentDay,
    MegaNavigation,
    MegaNavigationDesktop,
    StoreDetailsModal,
    AccountsDropdown,
    generateGoogleMapOnAddress,
    googlePlaceServices,
    getCapitalizeString,
    BREAKPOINTS,
    Breadcrumb,
    VideoGalleryViewer,
    ReferenceComponent,
    localization,
    compareTwoArrays,
    libUtils,
    LanguageToggle,
    ProductImageComponent,
    CommonWarningToast,
    ProductCard,
    checkWasCurrentPrice,
    extractKeyValues,
    CategoryCard,
    VehicleBanner,
    MediaTypeValue,
    modalAccessibilityHandler,
    FullPageSpinner,
    InformationModal,
    DisplayViewType,
    CommonStatusToast,
    ProductCardVehicle,
    AutomotiveProductCardsVehicleContextWrapper,
    staggeredSkuLength,
    Tabs,
    TabsItemContainer,
    useClickOutsideClose,
    ProductDoesNotFit,
    RoadRatingBadge,
    variantsConstant,
    VariantsType,
    scrollToFooter,
    scrollToElement,
    scrollToElementIfExists,
    scrollToTop,
    FeeType,
    fitmentTypeCodeValue,
    FeeMessage,
    removeHtmlTagsFromString,
    removeHtmlTagsFromStringByName,
    MediaImages,
    checkKeyPresenceInArray,
    totalCurrentPrice,
    getSetOfFourPriceForStaggered,
    ServiceInstallationMessage,
    extractServiceBasedOnSku,
    getPriceValue,
    AutomotiveErrorCard,
    ErrorCard,
    ProductItemInfo,
    ProductDataDTO,
    eEntryType,
    ProductCartItemProps,
    renderItemPrice,
    UnfinishedPackageCard,
    VehicleImage,
    isOrderSTH,
    isOrderBOPIS,
    QuantitySelectorProps,
    InteractiveElementEvents,
    CriticalFitment,
    FitmentNote,
    getStoreTimings,
    getNextDayStoreTimings,
    checkNeedUpdateWeekdayHours,
    ProductCardType,
    combineBadges,
    getFormattedPhoneNumber,
    formatedPhoneNumberAccessibility,
    getFormattedPriceValue,
    isAutoServiceAvailableInStore,
    checkDataLength,
    isUrl,
    magicNumber,
    checkNestedProps,
    getQueryString,
    getQueryStringParams,
    getPageType,
    checkNavigationUrl,
    getPageTitle,
    pageNumberConstants,
    getDateTime,
    getFormattedDateTime,
    FormatDateComparisionType,
    getFormattedDateWithComparison,
    getFormattedTimeDate,
    getFormattedDateTimeWithYear,
    getEstimatedTimeOfSendingProduct,
    checkIfDateIsOverdue,
    generateDOBStr,
    generateDayMonthYearFromDOBStr,
    getETATimeOptions,
    addClass,
    removeClass,
    addAndRemoveHideClass,
    UserGreeting,
    LoyaltyCards,
    SignOut,
    SignInDropDown,
    SelectBanner,
    appendReturnUrl,
    LinkOptionsCard,
    TermsAndConditions,
    ActiveOwnership,
    SuccessScreen,
    SuccessScreenModal,
    cardNumberFormatted,
    DatePicker,
    TimePicker,
    ProgressBar,
    Step,
    getButtonStatus,
    offerConstants,
    EmptyScreen,
    OfferCard,
    OfferFlyout,
    getOfferSorting,
    getLoyaltyCards,
    getBalance,
    InformationCard,
    MessageCard,
    sortOffers,
    checkToggle,
    linkTargetOptions,
    canadaPostApi,
    updateFilterFacet,
    validatePostalCode,
    PreviewDetail,
    CascadingDropdown,
    NavigationFlyout,
    signoutRedirection,
    ISignOutRedirection,
    signoutRedirectionList,
    preProcessSvgUseTag,
    filterOffersData,
    TransactionColumn,
    TransactionColumnBadge,
    TransactionColumnLogo,
    getDateOfBirth,
    DOBFormatConstant,
    formEmpty,
    recommendationQueryParam,
    currentYear,
    yearDropdownCalculation,
    renderVehicleInfo,
    HeaderBlock,
    isTriangleSelectMember,
    Barcode,
    getMod10CheckDigit,
    GiftCard,
    PromoMessages,
    filterSpecification,
    getFeeTitle,
    RebateProps,
    RebateData,
    Vehicle,
    Url,
    cardPropTypes,
    FBTRecommendation,
    isBalloonProduct,
    SearchAnalytics,
    isValidPrice,
    HybrisMedia,
    RoadRatingType,
    RoadRatingDetail,
    PriceMessage,
    SearchBarProps,
    Suggestion,
    CacheData,
    HistorySuggestion,
    getCookieValue,
    EmailActivationCard,
    urlRedirection,
    appendTrackingInfo,
    PreviewCard,
    enableDestructOnUndefinedData,
    LineSummary,
    getFormattedPriceWithLocale,
    getNumberFromFormattedPrice,
    getNumberFromFormattedPriceWithLocale,
    getFileNameFromUrl,
    BannerDisclaimer,
    CustomDropdown,
    Filters,
    AutoAttributes,
    MapPosition,
    ProductBrand,
    ParentCategory,
    parseQuery,
    Banner,
    GuestCheckout,
    getParamValue,
    statusLabel,
    Spinner,
    CTMoneySnippet,
    areAllParamsValid,
    isAtleastOneParamValid,
    getCardNameAndNumber,
    CustomTextArea,
    SnippetCard,
    useGenerateSaveMessage,
    SaleMessagesRule,
    checkStateProvince,
    getCardLogo,
    getCardNumber,
    hashCreditCard,
    formatCreditCard,
    CardColumn,
    NewVehicleBanner,
    BrandFooter,
    HeroBannerSplit,
    Calendar,
    SkinnyBannerModal,
    FeatureTypes,
    ChartTypes,
    checkPlugInType,
    createCanvasPattern,
    getDeviceOS,
    OSType,
    BannerViewType,
    InlineToast,
    InlineToastType,
    baseCTFSInputStyles,
    quebecIsocode,
    isCreditCardExpired,
    getDateDetails,
    shippingAddressErrorCodes,
    errorCodes,
    technicalDifficultiesErrCode,
    localeCookieName,
    SummaryCard,
    checkLastItemArray,
    MODAL_OPEN_CLASS_NOT_SCROLL,
    getButtonType,
    getDateFromCreditCard,
    getCardErrorCheck,
    validateCardInput,
    sendError,
    getErrorMessage,
    getAccessibilityId,
    Separator,
    NotificationBadge,
    BrandingColors,
    BalanceScreen,
    balanceCheckerScreen,
    redirectToPage,
    checkPathname,
    checkCurrentPage,
    checkReturnURL,
    modifyRedirectUrlByParams,
    dateOptions,
    getPTEBaseTotal,
    getCurrentDate,
    isAmountGreaterThan,
    ExpandCard,
    getDivideByAmount,
    getMultipleByAmount,
    TextInputModes,
    LabelType,
    offerComponentLocation,
    checkoutRegexPatterns,
    getCTA,
    getTooltipText,
    checkNotNullAndUndefined,
    getAkamaiPolicy,
    useMobileLayoutState,
    stringKeyCodes,
    isDateValid,
    maxDaysInMonth,
    getMonthList,
    InfoMessage,
    isEscPressed,
    isTabPressed,
    isEnterPressed,
    isSpacePressed,
    useFocusedTable,
    OptionDescriptors,
    getSourceTypeData,
    useOnKeyDownOutside,
    useOnKeyUpOutside,
    useOnClickOutside,
    dynamicBackgroundPropTypes,
    carouselSettingValues,
    daysInMonth,
    getToastIconType,
    arraysElementsAreEqual,
    getErrorCodeMessage,
    linkRewardsAnalytics,
    saleOrClearanceBadge,
    QcLanguageTab,
    IQcCheckboxTabType,
    getDateFormatNumericYear,
    errorOrderModificationCodes,
    VehicleHeader,
    validateDateBetweenTwoDates,
    Symbols,
    dashboardBannerTypes,
    sortByBrandsOrCategory,
    useIsMobile,
    StaticProductTitle,
    SuccessScreenPropsData,
    ISuccessScreenModalProps,
    IVariant,
    OptionListSize,
    getSubscriptionDateTime,
    useFocusOnce,
    useFocusOnceRef,
    useDesktopLayoutState,
    NavItem,
    NavExpansionPanel,
    Heading,
    getPhoneNumber,
    getNameDetails,
    DataTable,
    TextInputGroup,
    TextInputGroupHeading,
    TextInputGroupPreview,
    PreviewDetailsList,
    CertonaConstants,
    ImageAlignment,
    SpinnerModal,
    isAccountPageType,
    useIsChanged,
    useCallbackOnce,
    checkAddressChange,
    isMultiplierOffer,
    PillsList,
    getInteractiveElements,
    setFocusToElement,
    checkUndefined,
    ButtonStatusType,
    getHeaderButtonLabel,
    getUserProfileStatus,
    SwapConfirmationModal,
    isFalse,
    SwapOfferLimit,
    InformativeModal,
    CircularProgress,
    getBannerLogo,
    applyAkamaiPolicy,
    SwapCardContent,
    ProgressType,
    ProgressSizes,
    getStringBasedOnCondition,
    isPetroConversionTransaction,
    isAnnualTopupTransaction,
    isPetroOrAnnualTopUpTransactions,
    getTransactionName,
    onElementAvailable,
    TransactionDetail,
    excludeQuote,
    CarouselSwiper,
    getOfferCodesParam,
    getColumns,
    iProductFulfillment,
    TransactionAmountType,
    getOfferParams,
    handleArrowsAccessibility,
    arrowActionIdentifier,
    handleClickOrEnterAccessibility,
    Animation,
    SpinnerStyle,
    iconSizes,
    SpinnerTypes,
    brazeCustomEvents,
    pageTypes,
    plaCarouselSchemaId,
    disableFocusLock,
    enableFocusLock,
    Facet,
    commonIcons,
    choose,
    badgesAndPrioritiesType,
    getCardType,
    CardType,
    ComparisonTable,
    renderClearanceBadge,
    PURCHASE_TYPE,
    REDEEM_TYPE,
    getSourceParam,
    FORWARD_SLASH,
    Size,
    Status,
    ProductSourceTypes,
    getCategoryParams,
    getProductParams,
    getBaseProductParams,
    deviceTypes,
    isOtpErrorCode,
    getValueByComponent,
    MultiField,
    BreakpointKeys,
    BreakpointSettings,
    OtpErrorType,
    otpErrorCodes,
    VerificationScreen,
    VerificationScreenProps,
    MultiSelectCheckBoxes,
    appendQueryParam,
    verificationCodeRegex,
    TabsV2,
    TabV2,
    learnMoreModalData,
    useHandleFocusTrap,
    MultiSelectItem,
    replaceStringCharacter,
    applyGiftCardErrorCodes,
    daysCalculationConstants,
    isL1ShopAllPage,
    createShopAllLink,
    brazeCustomEventsPages,
    getStoreParams,
    ErrorScreenData,
    GiftCardInterface,
    DeleteGiftCard,
    GiftCardProps,
    ButtonGroup,
    getDaysDifference,
    isVerticalFilters,
    SkipLink,
    ITitleDesc,
    selectedSKUBadge,
    GlobalAnimation,
    BaseIcon,
    getTotalPteReward,
    TemplateCard,
    AkamaiProp,
    ColumnsType,
    BannerType,
    CustomContentCard,
    BrazeCardType,
    BrazeContentPropsType,
    ContentCardType,
    TransactionBackgroundImage,
    CompareProductsFlyout,
    ContentVariation,
    getIsMobile,
    getFormatValueWithIcon,
    truncateWithEllipsis,
    useAnimatedNumber,
}
