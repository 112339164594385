import { ProductCardType } from '../../redux/models/recommendations.interface'
import { getProvider } from '../providers/index'
import { getProductAnalyticsItem, getProductAnalyticsClickItem } from './frequentlyBoughtTogetherAnalytics'
import { constantValues } from '../../components/ProductGridView/ProductCard.constant'
import { ProductRecordInterface } from '../providers/provider.type'
import { ProductDataTypeObj } from '../../redux/models/productData.interface'
import { RecommendationDataForAnalytics } from '../analytics.type'
import { ProductResponseData } from '../../redux/models/product.interface'

export const recommendationAnalytics = (
    event: string,
    recommendationsData: ProductCardType[],
    title: string,
    recommendationType?: string,
    productIndex?: number,
    productData?: ProductCardType | ProductDataTypeObj,
): void => {
    const productList =
        productIndex && productIndex >= 0
            ? getProductAnalyticsClickItem(
                  recommendationsData as RecommendationDataForAnalytics[],
                  productIndex + constantValues.positionIncrementValue,
                  title,
                  recommendationType,
              )
            : getProductAnalyticsItem(
                  recommendationsData as RecommendationDataForAnalytics[],
                  title,
                  recommendationType,
                  productData as unknown as ProductResponseData,
              )

    getProvider().push({
        event: event,
        products: productList as ProductRecordInterface[],
    })
}
