/* eslint-disable @typescript-eslint/restrict-template-expressions */
import appCacheService from '../../utils/appCacheService'
import { MagicNumber } from '../../analytics/analytics.type'
import { zonedTimeToUtc } from 'date-fns-tz'
import { CountdownBannerData, SwiperMethods } from './HeadbandBannerComponent.type'
import { PREFIX } from '../../config'
import { libUtils } from '@nl/lib'

/**
 * Retrieves time data from a timer element.
 * @param {string} timerElementClass - The CSS class of the timer element.
 * @returns {{ startTime: number; endDateTime: Date; timerData: CountdownBannerData | undefined }} The time data including start time, end date time, and timer data.
 */
export const getTimeData = (
    timerElementClass: string,
): { startTime: number; endDateTime: Date; timerData: CountdownBannerData | undefined } => {
    const timerElement = document.querySelector(timerElementClass)
    const timerProp = (timerElement as HTMLElement).dataset.props
    const timerData: CountdownBannerData | undefined = timerProp
        ? (JSON.parse(timerProp) as CountdownBannerData)
        : undefined
    const startTime = new Date(timerData?.startDateTime as string).getTime()
    const endDateTime = new Date(timerData?.endDateTime as string)
    return { startTime, endDateTime, timerData }
}

/**
 * Initializes and starts the headband banner countdown timer.
 * @param {string} timerElementClass - The CSS class of the timer element.
 * @param {SwiperMethods} swiper - The Swiper instance used for the banner.
 * @returns {void}
 */
export const headbandBannerCountDownTimer = (timerElementClass: string, swiper: SwiperMethods): void => {
    const { startTime, endDateTime, timerData } = getTimeData(timerElementClass)
    const slides = document.querySelectorAll(`.${PREFIX}-headband-banner .swiper-slide`)
    const slideCount = slides.length

    /**
     * Finds the index of the slide that contains the timer element.
     * @returns {number | null} The index of the slide with the timer element, or null if not found.
     */
    const findSlideIndexWithTimer = (): number | null => {
        for (let i = 0; i < slideCount; i++) {
            const slide = slides[i]
            const timerContainer = slide.querySelector(timerElementClass)
            if (timerContainer) {
                return i
            }
        }
        return null
    }

    let storeTimeZone = appCacheService.storeTimeZone.get()
    let endTime = storeTimeZone ? zonedTimeToUtc(endDateTime, storeTimeZone).getTime() : endDateTime.getTime()

    /**
     * Callback function to be called on each tick of the countdown timer.
     * @param {number} difference - The remaining time in milliseconds.
     */
    const timerTickCallback = (difference: number) => {
        if (new Date().getTime() > startTime) {
            // Time calculations for days, hours, minutes and seconds
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
            const { daysLeft, hoursLeft, minutesLeft, secondsLeft } = libUtils.calculateTimeLeft(difference)
            // eslint-disable-next-line sonar/no-nested-conditional
            const timerDayLabel = timerData ? (daysLeft === MagicNumber.ONE ? timerData.day : timerData.days) : ''
            const dateTimeTillMins =
                `<span class="${PREFIX}-headband-banner__slide-timer-item days">${daysLeft}</span>` +
                `${timerDayLabel} : ` +
                `<span class="${PREFIX}-headband-banner__slide-timer-item hours">${hoursLeft}</span>` +
                `${timerData?.hours as string} : ` +
                `<span class="${PREFIX}-headband-banner__slide-timer-item minutes">${minutesLeft}</span>` +
                `${timerData?.mins as string}`

            // Output the timer
            ;(
                document.querySelector(`.${PREFIX}-headband-banner__slide-timer`) as HTMLInputElement
            ).innerHTML = `${dateTimeTillMins} : <span class="${PREFIX}-headband-banner__slide-timer-item seconds">${secondsLeft}</span>${
                timerData?.secs as string
            }`
        }
    }

    /**
     * Callback function to be called when the countdown timer ends.
     */
    const timerEndCallback = () => {
        const needToContinueAutoPlay = swiper.autoplay.running
        if (slideCount > MagicNumber.ONE) {
            swiper.removeSlide(findSlideIndexWithTimer())
            if (needToContinueAutoPlay) swiper.autoplay.start()
        } else {
            const headbandBanner = document.querySelector(`.${PREFIX}-headband-banner`) as HTMLInputElement
            headbandBanner.classList.remove(`${PREFIX}-xs-flex`)
        }
    }

    // Create the timer using the reusable function
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-assignment
    let timer = libUtils.countDownTimer(endTime, timerTickCallback, timerEndCallback)

    // Listen for storage events to reset the timer if timezone changes
    window.addEventListener('storage', event => {
        if (event.oldValue !== event.newValue) {
            clearInterval(timer)
            storeTimeZone = appCacheService.storeTimeZone.get()
            endTime = storeTimeZone ? zonedTimeToUtc(endDateTime, storeTimeZone).getTime() : endDateTime.getTime()
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-assignment
            timer = libUtils.countDownTimer(endTime, timerTickCallback, timerEndCallback)
        }
    })
}
