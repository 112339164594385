import React from 'react'
import PropTypes from 'prop-types'

import Icon from '../Icon'
import { PREFIX } from '../config'
import { MegaNavigationListProps, CategoryDataProps, ItemColorType, Url } from './MegaNavigation.type'
import { isArrayNotEmpty } from '../../utils'
import { getAriaPath } from './MegaNavigation.helper'
import { ariaType } from '../../globalConstants/global.constant'

/**
 * Scroll to the top to container. IF menu contains multiple menu links, and user clicks on bottom / last links
 * then in case of L2/L3 categories, needs to show contents from top.
 * @return { void }
 */
const scrollToTop = (): void => {
    const sideMenuContainerElem = document.querySelector(`.${PREFIX}-side-menu__container`)
    if (!!sideMenuContainerElem) {
        sideMenuContainerElem.scrollTop = 0
    }
}

/* MegaNavigationList component
 * @param {MegaNavigationListProps} props
 * @return {JSX.Element} returns MediaGalleryViewer component
 */
const MegaNavigationList: React.FC<MegaNavigationListProps> = ({ ...props }): JSX.Element => {
    const {
        categoryMapData,
        path,
        onCategoryClicked,
        currentView,
        style,
        megaNavClicked,
        listRef,
        currentIndexRef,
        forwardActionRef,
    } = props

    /**
     * function to create navigation list item
     * @param {number} index
     * @param {string} value
     * @return {JSX.Element}
     */
    const renderListOfUrl = (index: number, value: CategoryDataProps): JSX.Element => {
        return (
            <a
                className={`${PREFIX}-mega-navigation__no-button ${PREFIX}-mega-navigation__link ${
                    style === 'bold' && 'text-bold'
                } ${getActiveCategoryClass(value, true)}`}
                href={typeof value.url === 'string' ? encodeURI(value.url) : encodeURI(value.url?.url)}
                tabIndex={0}
                data-testid={`mega-navigation-list-item-${currentView}-${index}`}
                onClick={() => {
                    currentIndexRef.current[currentView] = index
                    return true
                }}>
                {value.name}
            </a>
        )
    }

    /**
     * function to set active category class for current category on category pages
     * @param {CategoryDataProps} value // category to be checked
     * @param {boolean} isLink // Check if achor tag
     * @returns {string} // returns string of active category class
     */
    const getActiveCategoryClass = (value: CategoryDataProps, isLink: boolean): string => {
        const isUrlMatched = value.url === window.location.pathname
        const isL3View = currentView.toUpperCase() === 'L3' || !value.categories
        if (isLink) {
            return isUrlMatched ? `${PREFIX}-mega-navigation__link--active` : ''
        } else {
            return isUrlMatched && isL3View ? `${PREFIX}-mega-navigation__link--active` : ''
        }
    }

    const getEncodedUri = (url: string | Url): string => {
        return typeof url === 'string' ? encodeURI(url) : encodeURI(url?.url)
    }

    const renderListOrShopList = (): JSX.Element => {
        return (
            <ul
                ref={listRef}
                className={`${PREFIX}-mega-navigation__categories-section`}
                data-testid={`mega-navigation-list-${currentView}`}>
                {categoryMapData.map((value: CategoryDataProps, index: number) => {
                    const { style: colorType, categories, url, name } = value
                    const highlightedClass =
                        !!colorType && colorType === ItemColorType.highlight ? `highlight-item` : 'standard-item'
                    return (
                        <li key={`${name}${index}`} className={highlightedClass}>
                            {!isArrayNotEmpty(categories) ? (
                                renderListOfUrl(index, value)
                            ) : (
                                <button
                                    className={`${PREFIX}-mega-navigation__no-button ${
                                        style === 'bold' ? 'text-bold' : ''
                                    } ${getActiveCategoryClass(value, false)}`}
                                    data-testid={`mega-navigation-list-item-${currentView}-${index}`}
                                    aria-current={getAriaPath(getEncodedUri(url)) ? ariaType : false}
                                    onClick={() => {
                                        megaNavClicked && megaNavClicked(name)
                                        if (currentView === 'L3' || !categories) {
                                            window.location.href = getEncodedUri(url)
                                        } else {
                                            onCategoryClicked(value, currentView)
                                        }
                                        scrollToTop()
                                        currentIndexRef.current[currentView] = index
                                        forwardActionRef.current = true
                                    }}>
                                    {name}
                                    {!!categories && isArrayNotEmpty(categories) && currentView !== 'L3' && (
                                        <Icon type={'ct-chevron-right'} size="lg" path={path} />
                                    )}
                                </button>
                            )}
                        </li>
                    )
                })}
            </ul>
        )
    }

    return renderListOrShopList()
}

MegaNavigationList.propTypes = {
    categoryMapData: PropTypes.any,
    path: PropTypes.string,
    onCategoryClicked: PropTypes.func,
    currentView: PropTypes.string,
    style: PropTypes.oneOf(['normal', 'bold']),
    megaNavClicked: PropTypes.func,
}

export default MegaNavigationList
