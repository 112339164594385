/**
 * Link to Tooltip component.
 * @return {JSX.Element} returns Backdrop to be shown behind Tooltip
 */

import React from 'react'
import { PREFIX } from '../config'
import { isPrerenderOrNot } from '../../utils/isPrerender'

const Backdrop: React.FC<{ tooltipModifiedStyles: string }> = ({
    tooltipModifiedStyles,
}: {
    tooltipModifiedStyles?: string
}) => {
    const containerClass = !isPrerenderOrNot() ? `${PREFIX}-backdrop-tooltip` : ''
    return (
        <div
            className={`${containerClass}
            ${tooltipModifiedStyles || ''}`}
            data-testid="backdrop-tooltip"></div>
    )
}

export default Backdrop
