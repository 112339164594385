import { getPageType } from '@nl/lib'
import { pageTypes } from '../config'
import { ProductData, PurchaseOrderData, CartData } from '../redux/models/monetateFomoEvents.interface'

const { productPage, orderConfirmation } = pageTypes

/**
 * Handles the product event by adding the necessary data to the monetateQ array and tracking the data.
 * @param {ProductData | PurchaseOrderData} productData - The product data or purchase order data.
 * @returns {void}
 */
export const handleProductEvent = (productData: ProductData[] | PurchaseOrderData[]): void => {
    window.monetateQ = window.monetateQ || []
    window.monetateQ.push(['setPageType', getPageType()])
    switch (getPageType()) {
        case productPage:
            window.monetateQ.push(['addProductDetails', productData])
            break
        case orderConfirmation:
            window.monetateQ.push(['addPurchaseRows', productData])
            break
        default:
            break
    }
    window.monetateQ.push(['trackData'])
}

/**
 * Handles the cart event by setting the page type, adding cart rows, and tracking data.
 * @param cartData - An array of cart data.
 * @returns {void}
 */
export const handleCartEvent = (cartData: CartData[]): void => {
    window.monetateQ = window.monetateQ || []
    window.monetateQ.push(['setPageType', getPageType()])
    window.monetateQ.push(['addCartRows', cartData])
    window.monetateQ.push(['trackData'])
}
