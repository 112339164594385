import React, { Validator, useMemo } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Icon from '../Icon'
import { PREFIX } from '../config'
import Button from '../Button'
import { stringKeyCodes } from '../../utils'
import { isTireOrWheelProduct } from '../AutomotiveProductCards/AutomotiveProductCards.helper'
import { ProductCardATCProps } from './VehicleProductCard.type'
import { ProductCardType } from '../ProductGridView/ProductGrid.types'
import { getPageType } from '../../utils/getPageType'
import { atcSingleSkuPDPAnalyticValue, openMiniPDPAnalyticValue } from './ProductCardATC.constant'

/**
 * Add to cart  component in plp/srp
 * @param {ProductCardATCProps} props - ATC props
 * @returns {JSX.Element} returns ATC button component
 */
const ProductCardATC: React.FC<ProductCardATCProps> = (props: ProductCardATCProps): JSX.Element => {
    const {
        showAtcBtnSpinner,
        product,
        addToCartBtnClick,
        idx,
        addButton,
        optionsButton,
        enableAddToCartOptions,
        enableMiniPdpFlyoutSupport,
        enableATCForSingleSkuMiniPdp,
        addToCartLabel,
        selectedSchemaId,
        selectedColorVariantData,
        quickLookLabel,
        componentName,
    } = props
    const { showATCButton, isMultiSku, hasAtcQuickLookFlow } = product || {}
    const hasAtcQuickLookBtn = useMemo(
        () => enableMiniPdpFlyoutSupport || hasAtcQuickLookFlow,
        [enableMiniPdpFlyoutSupport, hasAtcQuickLookFlow],
    )
    const hasAtcOptionsBtn = useMemo(() => enableAddToCartOptions, [enableAddToCartOptions])
    const atcQuickLookLabel = useMemo(
        () => (isMultiSku || enableATCForSingleSkuMiniPdp ? quickLookLabel : addToCartLabel),
        [isMultiSku, enableATCForSingleSkuMiniPdp, quickLookLabel, addToCartLabel],
    )
    const componentNameForAnalytic = useMemo(() => componentName ?? getPageType(), [componentName])
    /**
     * Function to call on ATC button keyboard event
     * @param {Event} event keyboard event
     * @returns {void}
     */
    const handleATCKeyDown = (event: React.KeyboardEvent<HTMLElement>): void => {
        if (event.key === stringKeyCodes.enter || event.key === stringKeyCodes.spacing) {
            addToCartBtnClick(event, product, idx, isTireOrWheelProduct(product), selectedSchemaId)
        }
    }

    /**
     * Function to render Add or Option text based on showATCButton flag
     * @returns {JSX.Element}
     */
    const renderATCLabel = (): JSX.Element => {
        return (
            <>
                {showATCButton ? (
                    <span className={`${PREFIX}-product-card__atc-button-label`}>
                        <Icon type="ct-cart" size="md" />
                        {addButton}
                    </span>
                ) : (
                    <span className={`${PREFIX}-product-card__atc-button-label`}>{optionsButton}</span>
                )}
            </>
        )
    }

    if (hasAtcQuickLookBtn) {
        const hasAtcIcon = !isMultiSku && !enableATCForSingleSkuMiniPdp
        const dapWacLinkValue = hasAtcIcon ? atcSingleSkuPDPAnalyticValue : openMiniPDPAnalyticValue

        return (
            <Button
                size="mini"
                ariaLabel={atcQuickLookLabel}
                showSpinner={showAtcBtnSpinner}
                modifierClass={classNames(
                    `${PREFIX}-product-card__atc-button`,
                    `${PREFIX}-product-card__atc-button--quick-look`,
                )}
                onKeyDown={handleATCKeyDown}
                onClick={(event: React.MouseEvent<Element>) =>
                    addToCartBtnClick(
                        event,
                        product,
                        idx,
                        isTireOrWheelProduct(product),
                        selectedSchemaId,
                        selectedColorVariantData?.id,
                    )
                }
                dapWacLink="true"
                location={componentNameForAnalytic}
                linkValue={dapWacLinkValue}>
                {!showAtcBtnSpinner && (
                    <span className={`${PREFIX}-product-card__atc-button-label`}>
                        {hasAtcIcon && <Icon type="ct-cart" size="md" />}
                        {atcQuickLookLabel}
                    </span>
                )}
            </Button>
        )
    }

    return hasAtcOptionsBtn ? (
        <Button
            size="mini"
            ariaLabel={showATCButton ? addToCartLabel : optionsButton}
            showSpinner={showAtcBtnSpinner}
            modifierClass={`${PREFIX}-product-card__atc-button`}
            onKeyDown={(event: React.KeyboardEvent<HTMLElement>) => (showATCButton ? handleATCKeyDown(event) : '')}
            onClick={(event: React.MouseEvent<Element>) =>
                showATCButton
                    ? addToCartBtnClick(
                          event,
                          product,
                          idx,
                          isTireOrWheelProduct(product),
                          selectedSchemaId,
                          selectedColorVariantData?.id,
                      )
                    : ''
            }
            location={getPageType()}>
            {!showAtcBtnSpinner && renderATCLabel()}
        </Button>
    ) : null
}

ProductCardATC.propTypes = {
    showAtcBtnSpinner: PropTypes.bool,
    product: PropTypes.object.isRequired as Validator<ProductCardType>,
    addToCartBtnClick: PropTypes.func,
    idx: PropTypes.number,
    addButton: PropTypes.string,
    optionsButton: PropTypes.string,
    enableAddToCartOptions: PropTypes.bool,
    enableMiniPdpFlyoutSupport: PropTypes.bool,
    addToCartLabel: PropTypes.string,
}

export default ProductCardATC
