// [SEAR-2283] removing this logic, I removed all usage, but I think that this code can be useful in the future
import { ProductCardType } from '../../ProductGridView/ProductGrid.types'
import { magicNumber } from '../../../utils'
import { searchConstants } from '../SearchBar.type'

/**
 * Helper function, creating SKU URL format: [Domain]/[Language Prefix]/[Page Type Indicator]/[Product Title]-[PCode].[SKU].html
 * @param {string} initialURL - initial URL
 * @param {[string, boolean]} initialCodeData - [initial code, should pass '#gs']
 * @return {string} - SKU URL
 */
export const createSKUURL = (initialURL: string, initialCodeData: [string, boolean]): string => {
    let url = initialURL
    const [initialCode, addGS] = initialCodeData
    const code = initialCode.replace(new RegExp('p', 'ig'), '')
    const htmlIndex = url.indexOf('.html')
    if (htmlIndex !== magicNumber.MINUS_ONE) {
        url = `${url.slice(0, htmlIndex)}.${code}${url.slice(htmlIndex)}${addGS ? '#gs' : ''}`
    }
    return url
}

/**
 * Helper function, find rear variant code of the given product that overlaps with the user input
 * @param {ProductCardType} product - product
 * @param {string|null} searchTerm - searchTerm
 * @return {[string, boolean]} - [rear variant code, should pass '#gs']
 */
export const getRearVariantCodeData = (product: ProductCardType, searchTerm: string | null): [string, boolean] => {
    const rearVariants = product?.rearVariant?.code?.split('|')

    if (!rearVariants || rearVariants.length === 0) return [product.code, false]
    if (!searchTerm || searchTerm.length === 0) return [rearVariants[0], true]

    const rearVariantOverlap = rearVariants.find(it => it === searchTerm.replace(new RegExp('p', 'ig'), '').trim())

    if (!rearVariantOverlap) return [rearVariants[0], true]
    else return [rearVariantOverlap, true]
}

/**
 * Helper function, encode the HTML
 * @param {string|null} str - str
 * @return {string} - str
 */
export const htmlEncode = (str: string): string | null => {
    return String(str).replace(/[^\w. ]/gi, function (c) {
        return c.replace(c, '')
    })
}

/**
 * Helper function, returns correct GA type string from given "raw" string
 * @param {string} rawType
 * @return {string} correct GA type
 */
export const getSuggestionGAType = (rawType: string): string => {
    switch (rawType) {
        case searchConstants.SUGGESTION_RAW_KEYWORD_TYPE:
            return searchConstants.SUGGESTION_TYPE_KEYWORD
        case searchConstants.SUGGESTION_RAW_CATEGORY_TYPE:
            return searchConstants.SUGGESTION_TYPE_CATEGORY
        case searchConstants.SUGGESTION_RAW_HISTORY_TYPE:
            return searchConstants.SUGGESTION_TYPE_HISTORY
        default:
            return searchConstants.SUGGESTION_TYPE_KEYWORD
    }
}

/**
 * given price message template that includes [0] and price,
 * returns tokenized message separated by price in order in the following format ==> { message: string, isPrice: boolean}
 * each token of the message can be iterated through and converted to an element. the isPrice indicator is required so that styles for price or label can be applied
 * note that the number of tokens will always be 2 if [0] is inserted at the beginning or end of the template or 3 if [0] is in the middle of the template
 * if the number of tokens is 0, it means [0] does not exist in the message and this function should have not been used
 * @param {string} messageTemplate message template that includes [0]
 * @param {string} price price string
 * @return {Record[]} array of tokenized messages with price indicator
 */
// eslint-disable-next-line default-param-last
export const tokenizePriceMessage = (messageTemplate = '', price: string): Record<string, unknown>[] => {
    const delimiter = '[0]'
    const tokens = messageTemplate.split(delimiter)
    const result: Record<string, unknown>[] = []
    // only deal with cases when 2 tokens are returned from split. all other cases are invalid since that means [0] is not found in the string
    if (tokens.length === magicNumber.TWO) {
        // [0] is in the middle of the string
        if (tokens[0] && tokens[magicNumber.ONE]) {
            result.push({ message: tokens[0], isPrice: false })
            result.push({ message: price, isPrice: true })
            result.push({ message: tokens[magicNumber.ONE], isPrice: false })
            return result
        }

        // [0] is the last element
        if (tokens[0]) {
            result.push({ message: tokens[0], isPrice: false })
            result.push({ message: price, isPrice: true })
            // eslint-disable-next-line sonarjs/elseif-without-else
        } else if (tokens[magicNumber.ONE]) {
            // [0] is the first element
            result.push({ message: price, isPrice: true })
            result.push({ message: tokens[magicNumber.ONE], isPrice: false })
        }
    }

    return result
}
