import { createSwiper } from '../../utils/createSwiper'
import appCacheService from '../../utils/appCacheService'
import { zonedTimeToUtc } from 'date-fns-tz'
import { headbandBannerCountDownTimer, getTimeData } from './headbandBannerCountDownTimer'
import { PREFIX } from '../../config'
import { SwiperOptions, SwiperMethods, SliderDataProps, ButtonProps } from './HeadbandBannerComponent.type'

/**
 * HeadbandBannerComponent - Initializes a swiper component based on the provided element and parameters.
 */
const HeadbandBannerComponent = (): void => {
    const element = document.querySelector(`.${PREFIX}-headband-banner`) as HTMLInputElement
    const headbandbannerSlideContent = `.${PREFIX}-headband-banner__slide-content`
    const headbandBannerTimerClass = `.${PREFIX}-headband-banner__slide-timer`
    const hideHeadbandBannerClass = 'hide'
    const showHeadbandBannerClass = `${PREFIX}-xs-flex`
    const timerElement = document.querySelector(headbandBannerTimerClass)
    let shouldDisplayHeadbandBannerComponent = true

    /**
     * Get the length of numberOfSlides.
     * @param {string} slideSelector - The CSS selector for the slides.
     * @returns {number} The updated number of slides.
     */
    const getNumberOfSlides = (slideSelector: string): number => {
        const slides = document.querySelectorAll(slideSelector)
        return slides.length
    }

    const checkTimeExpiredAndUpdateDisplay = () => {
        if (timerElement) {
            const { endDateTime } = getTimeData(headbandBannerTimerClass)
            const storeTimeZone = appCacheService.storeTimeZone.get()
            const endTime = storeTimeZone ? zonedTimeToUtc(endDateTime, storeTimeZone).getTime() : endDateTime.getTime()
            const currentTime = new Date()
            const currentTimeInUTC = storeTimeZone
                ? zonedTimeToUtc(currentTime, storeTimeZone).getTime()
                : currentTime.getTime()

            // Calculate the time difference in milliseconds
            const timeDiff = endTime - currentTimeInUTC

            // Check if the time has expired
            if (timeDiff <= 0) {
                const numberOfSlides = getNumberOfSlides(headbandbannerSlideContent)
                const parentComponent = timerElement?.closest(`.swiper-slide`)
                parentComponent?.remove()
                if (numberOfSlides === 1) {
                    element.classList.add(hideHeadbandBannerClass)
                    shouldDisplayHeadbandBannerComponent = false
                }
            }
        }
    }

    checkTimeExpiredAndUpdateDisplay()

    const numberOfSlides = getNumberOfSlides(headbandbannerSlideContent)

    if (numberOfSlides === 1) {
        const slideContents = document.querySelector(headbandbannerSlideContent)
        ;(slideContents as HTMLElement).style.width = '100%'
    }

    // Update display status based on shouldDisplayHeadbandBannerComponent flag
    element.classList.toggle(showHeadbandBannerClass, shouldDisplayHeadbandBannerComponent)

    // Safely parse the JSON data and type it as SliderDataProps | undefined
    const props = (element as HTMLElement)?.dataset.props
    const sliderDataProps: SliderDataProps | undefined = props ? (JSON.parse(props) as SliderDataProps) : undefined

    const swiperOptions: SwiperOptions = {
        slidesPerView: 1,
        spaceBetween: 10,
        loop: true,
        autoplay: {
            enabled: Boolean(numberOfSlides > 1), // Autoplay enabled/disabled
            // eslint-disable-next-line no-magic-numbers
            delay: sliderDataProps ? sliderDataProps.autoplaySpeed * 1000 : 0, // Autoplay delay in seconds
            disableOnInteraction: false, // Autoplay continues even when user interacts with slides
        },
        navigation: {
            nextEl: '.nl-headband-banner--nextArrow',
            prevEl: '.nl-headband-banner--prevArrow',
        },
    }

    const swiper: SwiperMethods = createSwiper('.nl-headband-banner-swiper-container', swiperOptions)

    const pauseButton = document.querySelector('.nl-headband-banner--pause')
    const playButton = document.querySelector('.nl-headband-banner--play')
    const nextArrow = document.querySelector(`.${PREFIX}-headband-banner--nextArrow`)
    const prevArrow = document.querySelector(`.${PREFIX}-headband-banner--prevArrow`)

    /**
     * Displays the play/pause and chevron icons if there is more than one slide.
     */
    const displayIcons = () => {
        if (numberOfSlides > 1) {
            ;(nextArrow as HTMLElement).style.display = 'block'
            ;(prevArrow as HTMLElement).style.display = 'block'
            if (sliderDataProps && sliderDataProps.isAutoplay) {
                swiper.autoplay.start()
                if (pauseButton) {
                    ;(pauseButton as HTMLElement).style.display = 'block'
                }
                if (playButton) {
                    ;(playButton as HTMLElement).style.display = 'none'
                }
            } else {
                swiper.autoplay.stop()
                if (playButton) {
                    ;(playButton as HTMLElement).style.display = 'block'
                }
                if (pauseButton) {
                    ;(pauseButton as HTMLElement).style.display = 'none'
                }
            }
        }
    }

    /**
     * Toggles the autoplay feature of the swiper instance.
     * If autoplay is running, it will be stopped.
     * If autoplay is stopped, it will be started.
     */
    const toggleAutoplay = () => {
        if (swiper.autoplay.running) {
            swiper.autoplay.stop() // Pause autoplay if running
            ;(playButton as HTMLElement).style.display = 'block'
            ;(pauseButton as HTMLElement).style.display = 'none'
        } else {
            swiper.autoplay.start() // Start autoplay if paused
            ;(pauseButton as HTMLElement).style.display = 'block'
            ;(playButton as HTMLElement).style.display = 'none'
        }
    }

    displayIcons()
    ;(pauseButton as HTMLElement).addEventListener('click', toggleAutoplay)
    ;(playButton as HTMLElement).addEventListener('click', toggleAutoplay)

    const modals = document.querySelector(`.${PREFIX}-headband-banner .${PREFIX}-react-modal`)
    const modalButtons = document.querySelectorAll(`.${PREFIX}-headband-banner__slide-content .${PREFIX}-button2`)
    const closeButtons = document.querySelector(`.${PREFIX}-headband-banner .${PREFIX}-button2-icon`)

    modalButtons.forEach(button => {
        const btn = button as HTMLElement
        btn.onclick = () => {
            if (modals) {
                const modalTitle = modals.querySelector(`.${PREFIX}-headband-banner .${PREFIX}-modal__header`)
                const modalDetail = modals.querySelector(`.${PREFIX}-headband-banner .${PREFIX}-modal__content`)
                const modalCta = modals.querySelector(`.${PREFIX}-modal__button-container .${PREFIX}-button2`)
                const modalCtaContainer = modals.querySelector(`.${PREFIX}-modal__button-container`)

                const updateModalState = (hasCtaText: boolean) => {
                    const modalElement = modals as HTMLElement
                    const displayStyle = hasCtaText ? 'flex' : 'none'
                    const detailClassToAdd = hasCtaText ? `${PREFIX}-modal--with-button` : `${PREFIX}-modal--no-button`
                    const detailClassToRemove = hasCtaText
                        ? `${PREFIX}-modal--no-button`
                        : `${PREFIX}-modal--with-button`
                    const ctaContainerClassToAdd = hasCtaText
                        ? `${PREFIX}-modal--add-box-shadow`
                        : `${PREFIX}-modal--remove-box-shadow`
                    const ctaContainerClassToRemove = hasCtaText
                        ? `${PREFIX}-modal--remove-box-shadow`
                        : `${PREFIX}-modal--add-box-shadow`

                    modalElement.style.display = displayStyle
                    modalDetail?.classList.add(detailClassToAdd)
                    modalDetail?.classList.remove(detailClassToRemove)
                    modalCtaContainer?.classList.add(ctaContainerClassToAdd)
                    modalCtaContainer?.classList.remove(ctaContainerClassToRemove)
                }

                const updateModalContent = (buttonProps: ButtonProps) => {
                    if (modalTitle) {
                        modalTitle.innerHTML = buttonProps.title ?? ''
                    }
                    if (modalDetail) {
                        modalDetail.innerHTML = buttonProps.detail ?? ''
                    }
                    if (closeButtons) {
                        closeButtons.ariaLabel = closeButtons.title = buttonProps.closeAriaLabel ?? ''
                    }
                    if (modalCta) {
                        modalCta.textContent = buttonProps.ctaText ?? ''
                        modalCta.ariaLabel = modalCta.title = buttonProps.ctaText = buttonProps.ctaText ?? ''
                        updateModalState(!!buttonProps.ctaText)

                        modalCta.addEventListener('click', (event: MouseEvent) => {
                            event.preventDefault()
                            window.location.href = buttonProps.ctalink ?? '#'
                        })
                    }
                }

                updateModalContent(
                    JSON.parse(
                        btn.getAttribute('data-props').replace(/[\u0000-\u001F\u007F-\u009F]/g, '') ?? '{}',
                    ) as ButtonProps,
                )
                ;(modals as HTMLElement).style.display = 'block'
            }
        }
    })

    const closeModal = () => {
        ;(modals as HTMLElement).style.display = 'none'
    }

    if (closeButtons) {
        closeButtons.onclick = closeModal
    }

    window.onclick = (event: MouseEvent) => {
        // eslint-disable-next-line eqeqeq
        if (event.target == modals) {
            closeModal()
        }
    }

    if (timerElement) {
        headbandBannerCountDownTimer(headbandBannerTimerClass, swiper)
    }
}

HeadbandBannerComponent.displayName = 'HeadbandBannerComponent'

export { HeadbandBannerComponent }
