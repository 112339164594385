import { Dispatch } from 'redux'
import { AxiosResponse } from 'axios'
import store from '../../store'
import { SET_INITIAL_LINK_MEGANAV, SET_SITEMAP_LINKS } from '../types/secondaryNavigation/secondaryNavigation.constants'
import { setMegaNavLinks, setSiteMapLinks } from '../actionCreators/secondaryNavigation.actionCreators'
import { Category, InitialCategoryPayloadType, SiteMapCategory } from '../models/secondaryNavigation.interface'
import { HeaderNavigationService } from '../../services/headerNavigationService'
import { HeaderNavigationHelper } from '../../helpers/headerNavigation.helper'
import sessionStorageService from '../../utils/sessionStorageService'
import { getEnvironment } from '../../environments'

export const fetchHeaderNavigationCategories =
    (shopAllLabel: string, isSortAlphabetically?: boolean, relatedCategoriesParam?: string) =>
    (
        dispatch: Dispatch<{ type: typeof SET_INITIAL_LINK_MEGANAV; payload: InitialCategoryPayloadType[] }>,
        getState: typeof store.getState,
    ): void => {
        const config = getEnvironment()
        const currentTime = new Date().getTime()
        const { categoryData, expiryTimestamp, maxAge } = HeaderNavigationHelper.getCategoryDataFromSessionStorage(
            config.SESSION_STORAGE_KEYS.categoryData,
        )
        const rootState = getState()
        const {
            commonContent: {
                commonContentAvailable: { breadcrumb },
            },
        } = rootState

        // eslint-disable-next-line sonar/expression-complexity
        if (!categoryData || (maxAge && currentTime > maxAge) || (!maxAge && currentTime > expiryTimestamp)) {
            // eslint-disable-next-line no-magic-numbers
            HeaderNavigationService.getCategories('', '', (5).toString(), relatedCategoriesParam)
                .then(data => {
                    const headers = data.headers
                    const categoryIdData = HeaderNavigationHelper.prepareCategorySessionStorage(
                        data.data,
                        headers['expires'] as string,
                        headers['date'] as string,
                        headers['cache-control'] as string,
                    )
                    sessionStorageService.setItem(
                        config.SESSION_STORAGE_KEYS.categoryData,
                        JSON.stringify(categoryIdData),
                    )
                    const updatedData = HeaderNavigationHelper.transformApiResponse(
                        rootState.secondaryNavigation,
                        data.data.categories,
                        breadcrumb,
                        shopAllLabel,
                        isSortAlphabetically,
                    )
                    updatedData && dispatch(setMegaNavLinks(updatedData))
                })
                .catch(err => console.error(err))
            // eslint-disable-next-line sonarjs/elseif-without-else
        } else if (categoryData) {
            const updatedData = HeaderNavigationHelper.transformApiResponse(
                rootState.secondaryNavigation,
                categoryData.categories,
                breadcrumb,
                shopAllLabel,
                isSortAlphabetically,
            )
            updatedData && dispatch(setMegaNavLinks(updatedData))
        }
    }

export const setMegaNavPayload =
    (requestData: InitialCategoryPayloadType[]) =>
    (dispatch: Dispatch<{ type: typeof SET_INITIAL_LINK_MEGANAV; payload: InitialCategoryPayloadType[] }>): void => {
        dispatch(setMegaNavLinks(requestData))
    }

export const fetchSiteMapCategories =
    (scope: string) =>
    (dispatch: Dispatch<{ type: typeof SET_SITEMAP_LINKS; payload: Category[] }>): void => {
        HeaderNavigationService.getCategories('', scope)
            .then((data: AxiosResponse<SiteMapCategory>) => {
                const responseData = data?.data?.categories
                dispatch(setSiteMapLinks(responseData))
            })
            .catch(err => console.error(err))
    }
