import { MagicNumber } from '../analytics/analytics.type'
import { daysCalculationConstants } from './getDaysConstant.constant'

export const getDaysDifference = (timeStamp: string): string => {
    const dateObj = new Date(timeStamp)
    const todayDate = new Date()
    const differenceInTime = dateObj.getTime() - todayDate.getTime()
    const { thousand, totalSeconds, totalHours } = daysCalculationConstants
    const differenceInDays = Math.floor(differenceInTime / (thousand * totalSeconds * totalHours)).toString()
    return timeStamp
        ? // eslint-disable-next-line sonar/no-nested-conditional
          Number(differenceInDays) < MagicNumber.ZERO
            ? MagicNumber.ZERO.toString()
            : differenceInDays
        : ''
}
