import React, { useRef } from 'react'
import { StackedCategoryPayloadType, StackedSubcategoriesListNewProps } from './StackedCategoryListNew.type'
import { PREFIX } from '../../config'
import { arrowActionIdentifier, CategoryCard, stringKeyCodes } from '@nl/lib'
import { linkTargetOptions } from '@nl/lib/src/globalConstants/global.constant'
import { AkamaiImagePolicies } from '../../akamaiPolicy/akamaiPolicy.service'
import { centerCard, megaNavIdentifier, stackedCategoryListNew } from './StackedCategoryListNew.constant'
import { getEnvironment } from '../../environments'
import {
    sortCategoryDataByName,
    handleArrowsForCategories,
    getActiveCategoryElement,
} from './StackedCategoryListNew.helper'
import { analyticsInteraction } from '../../redux/actions'

import { analyticsAttributes } from '../../globalConstants'
import { categoryShopAllLink } from './ShopAllLink.helper'
import { selectorSubcategories, gridClass, shopAllLinkStyle, viewAllLinkStyle } from './StackedCategoryListNew.constant'
import { MagicNumber } from '../../analytics/analytics.type'
import { useAppDispatch } from '../../hooks/react-redux.hook'

const StackedSubcategoriesListNew: React.FC<StackedSubcategoriesListNewProps> = ({ ...props }): JSX.Element => {
    const {
        subCategories,
        noOfCategoriesToShow,
        enableAlphabeticalSorting,
        style,
        shopAllLinkLabel,
        shopAllLinkPlaceholder,
        enableShopAllLink,
        singleCategory,
        isAuthorCategories,
        viewAllCategoriesLinkLabel,
    } = props
    const subCategoriesForRender = enableAlphabeticalSorting ? sortCategoryDataByName(subCategories) : subCategories
    const desktopCenterAlignThreshold = noOfCategoriesToShow < centerCard.desktop ? `${gridClass}__desktop--center` : ''
    const wideCenterAlignThreshold = noOfCategoriesToShow < centerCard.wide ? `${gridClass}__wide--center` : ''
    const {
        event: { filter },
        eventParameters: {
            action: { shopByCategory },
        },
    } = analyticsAttributes

    const dispatch = useAppDispatch()
    const subcategoriesListRef = useRef<HTMLUListElement>(null)
    const config = getEnvironment()
    const cardClicked = (label: string | undefined): void => {
        dispatch(analyticsInteraction(label as string, '', filter, shopByCategory))
    }

    const cardKeyDown = (event: React.KeyboardEvent<HTMLElement>, currentIndex: number): void => {
        if (event.key === stringKeyCodes.rightArrow) {
            handleArrowsForCategories(
                event,
                arrowActionIdentifier.NEXT,
                currentIndex,
                subcategoriesListRef,
                selectorSubcategories,
            )
        } else if (event.key === stringKeyCodes.leftArrow && currentIndex) {
            handleArrowsForCategories(
                event,
                arrowActionIdentifier.PREVIOUS,
                currentIndex,
                subcategoriesListRef,
                selectorSubcategories,
            )
            // eslint-disable-next-line sonarjs/elseif-without-else
        } else if (event.key === stringKeyCodes.leftArrow && currentIndex === 0) {
            event.preventDefault()
            event.stopPropagation()
            const activeCategory = getActiveCategoryElement() as unknown as HTMLElement
            activeCategory?.focus()
        }
    }

    const handleShopAllKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
        const subCategoriesListLength = subCategoriesForRender?.length as number
        if (event.key === stringKeyCodes.rightArrow) {
            handleArrowsForCategories(
                event,
                arrowActionIdentifier.NEXT,
                subCategoriesListLength,
                subcategoriesListRef,
                selectorSubcategories,
            )
            // eslint-disable-next-line sonarjs/elseif-without-else
        } else if (event.key === stringKeyCodes.leftArrow) {
            handleArrowsForCategories(
                event,
                arrowActionIdentifier.PREVIOUS,
                subCategoriesListLength,
                subcategoriesListRef,
                selectorSubcategories,
            )
        }
    }

    const handleViewAllKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
        if (event.key === stringKeyCodes.leftArrow) {
            const subCategoriesListLength = enableShopAllLink
                ? (subCategoriesForRender?.length as number) + MagicNumber.ONE
                : (subCategoriesForRender?.length as number)
            handleArrowsForCategories(
                event,
                arrowActionIdentifier.PREVIOUS,
                subCategoriesListLength,
                subcategoriesListRef,
                selectorSubcategories,
            )
        }
    }

    /**
     * Renders author shop all link.
     * @param {StackedCategoryPayloadType} authorCategory authorCategory
     * @returns {JSX.Element | null}
     */
    const renderAuthorShopAll = (authorCategory: StackedCategoryPayloadType): JSX.Element | null => {
        return enableShopAllLink ? (
            <a
                className={shopAllLinkStyle}
                aria-label={authorCategory.name}
                data-automationid={`${megaNavIdentifier}${authorCategory.id}`}
                onKeyDown={handleShopAllKeyDown}
                href={authorCategory.shopAllLink}
                dap-wac-link={'true'}
                dap-wac-loc={stackedCategoryListNew}
                dap-wac-value={`${authorCategory.name} ${shopAllLinkLabel}`}>
                {shopAllLinkLabel}
            </a>
        ) : null
    }

    return (
        <>
            <ul
                className={`${gridClass} ${style} ${desktopCenterAlignThreshold} ${wideCenterAlignThreshold}`}
                ref={subcategoriesListRef}>
                {subCategoriesForRender?.map((singleSubCategory: StackedCategoryPayloadType, index: number) => {
                    const url = singleSubCategory?.url
                    return (
                        <li
                            key={`${singleSubCategory.id}_${index}`}
                            className={`${PREFIX}-product-subcategory__content`}>
                            <CategoryCard
                                href={url}
                                linkTarget={linkTargetOptions.selfTarget}
                                imagePolicies={AkamaiImagePolicies.initIndPolicy}
                                cardClicked={cardClicked}
                                cardKeyDown={(event: React.KeyboardEvent<HTMLElement>) => cardKeyDown(event, index)}
                                linkClass={`${PREFIX}-product-category__link`}
                                imageClass={`${PREFIX}-product-category__image`}
                                labelClass={`${PREFIX}-product-category__label`}
                                image={singleSubCategory.image ?? config.defaultProductImage}
                                label={singleSubCategory.name}
                                sectionTitle={stackedCategoryListNew}
                            />
                        </li>
                    )
                })}
                <li>
                    {isAuthorCategories
                        ? renderAuthorShopAll(singleCategory)
                        : categoryShopAllLink(
                              singleCategory?.id,
                              singleCategory?.url,
                              shopAllLinkPlaceholder,
                              enableShopAllLink,
                              [''],
                              shopAllLinkLabel,
                              singleCategory?.name,
                              shopAllLinkStyle,
                              stackedCategoryListNew,
                              handleShopAllKeyDown,
                          )}
                </li>
                <li>
                    <a
                        className={viewAllLinkStyle}
                        aria-label={singleCategory?.name}
                        data-automationid={`${megaNavIdentifier}${singleCategory?.id}`}
                        onKeyDown={handleViewAllKeyDown}
                        href={singleCategory?.url}
                        dap-wac-link={'true'}
                        dap-wac-loc={stackedCategoryListNew}
                        dap-wac-value={`${singleCategory?.name} ${viewAllCategoriesLinkLabel}`}>
                        {viewAllCategoriesLinkLabel}
                    </a>
                </li>
            </ul>
        </>
    )
}

export default StackedSubcategoriesListNew
