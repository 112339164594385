import { useEffect, useRef, RefObject, useCallback } from 'react'

/**
 * This hook is used to handle your callback on click or focus outside of elements from the provided reference.
 * E.G. it could be closing some popup with click or focus outside of it.
 * @param {RefObject<HTMLElement> | RefObject<HTMLElement>[]} refs
 * @param {( => void} callback
 * @param {boolean} isEnabled
 */

export const useClickOrFocusOutsideForCallback = (
    refs: RefObject<HTMLElement> | RefObject<HTMLElement>[],
    callback: () => void,
    isEnabled: boolean,
): void => {
    const updateClosureOnRender = useRef(callback)

    /**
     * Memoised function to check if refs array contains new target:
     * The callback method will be called if the target is not one or inside of one of the list of refs passed.
     * @param {MouseEvent} ev
     * @return {void}
     */
    const handleIfTargetOutside = useCallback(
        (target: EventTarget | Element): void | undefined => {
            let matchingRef = false
            if (refs instanceof Array) {
                matchingRef = Boolean(
                    refs.find(refItem => refItem.current && refItem.current.contains(target as HTMLElement)),
                )
            } else {
                matchingRef = refs.current && refs.current.contains(target as HTMLElement)
            }

            if (!matchingRef) {
                updateClosureOnRender.current()
            }
        },
        [refs],
    )

    /**
     * Memoised function to check refs array and Mouse event target.
     * The callback method will be called if the clicked target is not one or inside of one of the list of refs passed.
     * @param {MouseEvent} ev
     * @return {void}
     */
    const handleClickEvent = useCallback(
        (ev: MouseEvent): void => handleIfTargetOutside(ev.target),
        [handleIfTargetOutside],
    )

    /**
     * Memoised function to check refs array and focused target. It closes the popover by calling
     * The callback method will be called if the focused target is not one or inside of one of the list of refs passed.
     * @return {void}
     */
    const handleFocusEvent = useCallback(
        (): void => handleIfTargetOutside(document.activeElement),
        [handleIfTargetOutside],
    )

    /**
     * lifecycle hook to assign callback ref
     */
    useEffect(() => {
        updateClosureOnRender.current = callback
    })

    /**
     * lifecycle hook to add the click event listener with closeEvent handler if isEnabled is true
     * and remove the event before component unmounts
     */
    useEffect(() => {
        if (isEnabled) {
            document.addEventListener('click', handleClickEvent, true)
            document.addEventListener('focus', handleFocusEvent, true)
            return () => {
                document.removeEventListener('click', handleClickEvent, true)
                document.removeEventListener('focus', handleFocusEvent, true)
            }
        } else {
            return undefined
        }
    }, [isEnabled, handleClickEvent, handleFocusEvent])
}
