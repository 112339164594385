import { useEffect } from 'react'

const DEFAULT_OPTIONS = {
    childList: false,
    subtree: false,
    attributes: true,
    characterData: false,
}

const FORCE_LOADING_ATTRIBUTE = 'forcelazyloading'
const DATA_IS_LAZY_LOADED_ATTRIBUTE = 'islazyloaded'

export const useDataComponentObserver = (targetEl: Node, lazyLoadCallback: () => void, options = DEFAULT_OPTIONS) => {
    useEffect(() => {
        if (!targetEl || !lazyLoadCallback || typeof lazyLoadCallback !== 'function') return

        const observer = new MutationObserver((mutations: MutationRecord[]) => {
            if (mutations[0].attributeName === FORCE_LOADING_ATTRIBUTE) {
                lazyLoadCallback()
            }
        })
        if (targetEl) {
            observer.observe(targetEl.parentNode as Node, options)
        }

        // eslint-disable-next-line consistent-return
        return () => {
            if (observer) {
                observer.disconnect()
            }
        }
    }, [options, targetEl, lazyLoadCallback])
}

export const addForceLazyLoadingAttribute = (dataComponentName: string, callback: () => void): void => {
    let isComponentAbove = true
    const dataComponentElements = document.querySelectorAll('[data-component]') as unknown as HTMLElement[]
    const dataComponentsArray = Array.from(dataComponentElements)
    const lazyLoadingComponents = [] as HTMLElement[]

    dataComponentsArray.forEach((component: HTMLElement) => {
        const reactComponent = component.children[0] as HTMLElement

        if (isComponentAbove && reactComponent?.dataset[DATA_IS_LAZY_LOADED_ATTRIBUTE] === 'false') {
            component.setAttribute(FORCE_LOADING_ATTRIBUTE, 'true')
            lazyLoadingComponents.push(reactComponent)
        }

        if (component.dataset.component === dataComponentName) {
            isComponentAbove = false
        }
    })

    lazyLoadingComponents.length ? checkComponentsReadiness(lazyLoadingComponents, callback) : callback && callback()
}

const checkComponentsReadiness = (components: HTMLElement[], callback: () => void) => {
    let isLoaded = false
    const allLazyComponentsReadyCheckList = [] as boolean[]
    components.forEach((component: HTMLElement) => {
        const loadingObserver = new MutationObserver((mutations: MutationRecord[]) => {
            const mutation = mutations[0]
            if (
                mutation.attributeName === `data-${DATA_IS_LAZY_LOADED_ATTRIBUTE}` &&
                (mutation.target as HTMLElement)?.dataset[DATA_IS_LAZY_LOADED_ATTRIBUTE] === 'true' &&
                !isLoaded
            ) {
                allLazyComponentsReadyCheckList.push(true)
                if (components.length === allLazyComponentsReadyCheckList.length) {
                    isLoaded = true
                    if (typeof callback === 'function') {
                        callback()
                    }
                    loadingObserver.disconnect()
                }
            }
        })
        loadingObserver.observe(component, DEFAULT_OPTIONS)
    })
}
