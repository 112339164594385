import { isArrayNotEmpty } from './isArrayNotEmpty'
import { checkNestedProps } from './checkNestedProps'
import { Offers, BannerImagePath, offerlabelType, OfferTypePath } from '../components/OfferCard/OfferCard.type'
import {
    getMultiplierLabel,
    getOfferTypeData,
    getOfferTypeStatus,
    getSpendAndGetLabel,
    sortOffers,
    stringToDescending,
} from './offersSorter'
import { checkDataLength } from './checkDataLength'
import { magicNumber } from './magicNumber'
import { BannerMapping } from '../globalConstants/global.constant'
import { Facet, FacetTypes, FacetValue } from '../components/FacetPanelModal/FacetPanelModal.type'
import { areAllParamsValid } from './validParams'

/**
 * function to get offer count available
 * @param {Offers[]} selectedOffersArray
 * @param {Record<string, string[]>} filters
 * @param {string} label
 * @param {Offers[]} offers
 * @param {offerlabelType} filterLabel
 * @return {Offers[]}
 */
const getOfferData = (
    selectedOffersArray: Offers[],
    filters: Record<string, string[]>,
    label?: string,
    offers?: Offers[],
    filterLabel?: offerlabelType,
): Offers[] => {
    let offersData: Offers[] = []
    if (!!checkNestedProps(filters, label)) {
        filters[label].forEach(el => {
            offersData = selectedOffersArray?.concat(
                ...offersData,
                offers.filter(offer => {
                    const getOfferBanner = Array.isArray(offer.banner) ? offer.banner.includes(el) : offer.banner === el
                    if (label === filterLabel.brand) {
                        return getOfferBanner
                    } else if (label === filterLabel.category) {
                        return offer.details.categoryLevel1 === el
                    } else {
                        return getOfferTypeData(offer, el)
                    }
                }),
            )
        })
    }
    return offersData
}

/**
 * function to get offer count available
 * @param {Offers[]} filterdData
 * @param {string} facetSelected
 * @param {Offers[]} initialOfferData
 * @param {string} offerLabel
 * @param {string} offerTypes
 * @param {Record<string, string[]>} facetFilter
 * @param {offerlabelType} filterLabel
 * @return {number}
 */
const getOffersCount = (
    filterdData: Offers[],
    facetSelected: string,
    initialOfferData: Offers[],
    offerLabel?: string,
    offerTypes?: string,
    facetFilter?: Record<string, string[]>,
    filterLabel?: offerlabelType,
) => {
    let combinedFilteredData: Offers[] = []
    const { brand, category, offerType } = filterLabel

    if (facetSelected === offerLabel) {
        let filteredList
        switch (offerLabel) {
            case category:
                filteredList = getCategoryFilter(filterLabel, facetFilter, initialOfferData)
                break
            case offerType:
                filteredList = getOfferFilter(filterLabel, facetFilter, initialOfferData)
                break
            case brand:
                filteredList = getBrandFilter(filterLabel, facetFilter, initialOfferData)
                break
            default:
                filteredList = []
        }
        const distinctFilter = getDistinctOffer(filteredList)
        combinedFilteredData = isArrayNotEmpty(distinctFilter) ? distinctFilter : initialOfferData
    } else {
        combinedFilteredData = filterdData ? filterdData : initialOfferData
    }
    const offerCount = combinedFilteredData?.filter(offerValue => {
        const getOfferBanner = Array.isArray(offerValue.banner)
            ? offerValue.banner.includes(offerTypes)
            : offerValue.banner === offerTypes
        if (offerLabel === filterLabel.brand) {
            return getOfferBanner
        } else if (offerLabel === filterLabel.category) {
            return offerValue.details.categoryLevel1 === offerTypes
        } else {
            return getOfferTypeData(offerValue, offerTypes)
        }
    })
    return offerCount?.length
}

/**
 * function to remove duplicate offers
 * @param {Offers[]} filteredList
 * @return {Offers[]}
 */
const getDistinctOffer = (filteredList?: Offers[]): Offers[] => {
    return (
        isArrayNotEmpty(filteredList) &&
        filteredList?.filter((items, index) => {
            return filteredList.findIndex(item => item === items) === index
        })
    )
}

/**
 * function to get filtered offer when brand selected
 * @param {offerlabelType} filterLabel
 * @param {Record<string, string[]>} facetFilter
 * @param {Offers[]} initialOfferData
 * @return {Offers[]}
 */
const getBrandFilter = (
    filterLabel?: offerlabelType,
    facetFilter?: Record<string, string[]>,
    initialOfferData?: Offers[],
): Offers[] => {
    const { category, offerType } = filterLabel
    const unprettifyOfferType = offerType?.replace(/\s/g, '')
    let filteredList: Offers[] = []
    if (isArrayNotEmpty(facetFilter[unprettifyOfferType]) && !isArrayNotEmpty(facetFilter[filterLabel.category])) {
        filteredList = getOfferData(filteredList, facetFilter, unprettifyOfferType, initialOfferData, filterLabel)
    }

    if (isArrayNotEmpty(facetFilter[category]) && !isArrayNotEmpty(facetFilter[unprettifyOfferType])) {
        filteredList = getOfferData(filteredList, facetFilter, category, initialOfferData, filterLabel)
    }

    if (isArrayNotEmpty(facetFilter[category]) && isArrayNotEmpty(facetFilter[unprettifyOfferType])) {
        let catergoriesData: Offers[] = []
        let offerTypeData: Offers[] = []
        catergoriesData = getOfferData(catergoriesData, facetFilter, category, initialOfferData, filterLabel)
        offerTypeData = getOfferData(offerTypeData, facetFilter, unprettifyOfferType, initialOfferData, filterLabel)

        catergoriesData.forEach(item => {
            const checkbox = offerTypeData.find(offerItem => offerItem === item)
            // eslint-disable-next-line eqeqeq
            if (checkbox != undefined) {
                filteredList.push(checkbox)
            }
        })
    }

    if (
        isArrayNotEmpty(facetFilter[filterLabel.brand]) &&
        !isArrayNotEmpty(facetFilter[unprettifyOfferType]) &&
        !isArrayNotEmpty(facetFilter[filterLabel.category])
    ) {
        filteredList = initialOfferData
    }

    return filteredList
}

/**
 * function to get filtered offer when offertype selected
 * @param {offerlabelType} filterLabel
 * @param {Record<string, string[]>} facetFilter
 * @param {Offers[]} initialOfferData
 * @return {Offers[]}
 */
const getOfferFilter = (
    filterLabel?: offerlabelType,
    facetFilter?: Record<string, string[]>,
    initialOfferData?: Offers[],
): Offers[] => {
    const { brand, category, offerType } = filterLabel
    const unprettifyOfferType = offerType?.replace(/\s/g, '')
    let filteredList: Offers[] = []
    if (isArrayNotEmpty(facetFilter[filterLabel.brand]) && !isArrayNotEmpty(facetFilter[filterLabel.category])) {
        filteredList = getOfferData(filteredList, facetFilter, brand, initialOfferData, filterLabel)
    }

    if (isArrayNotEmpty(facetFilter[filterLabel.category]) && !isArrayNotEmpty(facetFilter[filterLabel.brand])) {
        filteredList = getOfferData(filteredList, facetFilter, category, initialOfferData, filterLabel)
    }

    if (isArrayNotEmpty(facetFilter[filterLabel.category]) && isArrayNotEmpty(facetFilter[filterLabel.brand])) {
        let bannerList: Offers[] = []
        let categoryList: Offers[] = []
        bannerList = getOfferData(bannerList, facetFilter, brand, initialOfferData, filterLabel)

        categoryList = getOfferData(categoryList, facetFilter, category, initialOfferData, filterLabel)

        bannerList.forEach(item => {
            const checkbox = categoryList.find(array2Item => array2Item === item)
            // eslint-disable-next-line eqeqeq
            if (checkbox != undefined) {
                filteredList.push(checkbox)
            }
        })
    }

    if (
        isArrayNotEmpty(facetFilter[unprettifyOfferType]) &&
        !isArrayNotEmpty(facetFilter[filterLabel.brand]) &&
        !isArrayNotEmpty(facetFilter[filterLabel.category])
    ) {
        filteredList = initialOfferData
    }

    return filteredList
}

/**
 * function to get filtered offer when category selected
 * @param {offerlabelType} filterLabel
 * @param {Record<string, string[]>} facetFilter
 * @param {Offers[]} initialOfferData
 * @return {Offers[]}
 */
const getCategoryFilter = (
    filterLabel?: offerlabelType,
    facetFilter?: Record<string, string[]>,
    initialOfferData?: Offers[],
): Offers[] => {
    const { brand, offerType } = filterLabel
    const unprettifyOfferType = offerType?.replace(/\s/g, '')
    let filteredList: Offers[] = []
    if (isArrayNotEmpty(facetFilter[filterLabel.brand]) && !isArrayNotEmpty(facetFilter[unprettifyOfferType])) {
        filteredList = getOfferData(filteredList, facetFilter, brand, initialOfferData, filterLabel)
    }
    if (isArrayNotEmpty(facetFilter[unprettifyOfferType]) && !isArrayNotEmpty(facetFilter[filterLabel.brand])) {
        filteredList = getOfferData(filteredList, facetFilter, unprettifyOfferType, initialOfferData, filterLabel)
    }

    if (isArrayNotEmpty(facetFilter[unprettifyOfferType]) && isArrayNotEmpty(facetFilter[filterLabel.brand])) {
        let bannerList: Offers[] = []
        let offerTypeList: Offers[] = []
        bannerList = getOfferData(bannerList, facetFilter, brand, initialOfferData, filterLabel)

        offerTypeList = getOfferData(offerTypeList, facetFilter, unprettifyOfferType, initialOfferData, filterLabel)

        bannerList.forEach(item => {
            const checkbox = offerTypeList.find(array2Item => array2Item === item)
            if (checkbox !== undefined) {
                filteredList.push(checkbox)
            }
        })
    }

    if (
        isArrayNotEmpty(facetFilter[filterLabel.category]) &&
        !isArrayNotEmpty(facetFilter[filterLabel.brand]) &&
        !isArrayNotEmpty(facetFilter[unprettifyOfferType])
    ) {
        filteredList = initialOfferData
    }
    return filteredList
}
/**
 * function to disable list based on validation
 * @param {Offers[]} filteredOffers
 * @param {string} offerLabel
 * @param {string} offerType
 * @param {offerlabelType} filterLabel
 * @return {boolean}
 */
const isDisabledStatus = (
    filteredOffers: Offers[],
    offerLabel?: string,
    offerType?: string,
    filterLabel?: offerlabelType,
) => {
    let status: boolean
    filteredOffers?.forEach(offer => {
        if (offerLabel === filterLabel.brand) {
            status = Array.isArray(offer.banner) ? !offer.banner.includes(offerType) : offer.banner !== offerType
        } else if (offerLabel === filterLabel.category) {
            status = offer.details.categoryLevel1 !== offerType
        } else {
            status = getOfferTypeStatus(offer, offerType)
        }
        return status
    })
    return status
}

const getOfferFilteredData = (
    offerData: string[],
    facetSelected: string,
    initialOfferData: Offers[],
    offerLabel?: string,
    facetFilter?: Record<string, string[]>,
    prettifyLabelList?: BannerImagePath[] | null,
    prettifyOfferType?: OfferTypePath[],
    filterLabel?: offerlabelType,
    filteredOffers?: Offers[],
    currentBannerId?: string,
    // eslint-disable-next-line max-params
) => {
    return offerData?.map((offer: string) => {
        const unprettifyLabel = offerLabel?.replace(/\s/g, '')

        let beautifiedLabel
        let offerNameLabel: string
        let brandImage
        let filteredBannerId: string
        if (offerLabel === filterLabel.brand) {
            if (currentBannerId === BannerMapping.LEQ && offer.toLowerCase() === BannerMapping.MRK.toLowerCase()) {
                filteredBannerId = BannerMapping.LEQ
            } else {
                filteredBannerId = offer
            }
            beautifiedLabel = prettifyLabelList?.find(
                (banner: BannerImagePath) => banner.bannerId === filteredBannerId,
            ) as BannerImagePath[]
            offerNameLabel = beautifiedLabel ? (beautifiedLabel as BannerImagePath)?.bannerName : offer
            const { bannerImagePath, bannerImageAlt } = (beautifiedLabel as BannerImagePath) || {}
            brandImage = areAllParamsValid(beautifiedLabel, !!bannerImagePath)
                ? { bannerImagePath, bannerImageAlt }
                : null
        } else {
            beautifiedLabel = prettifyOfferType?.find(
                (banner: OfferTypePath) => banner.offerTypeId === offer,
            ) as unknown as OfferTypePath[]
            offerNameLabel = beautifiedLabel ? (beautifiedLabel as OfferTypePath)?.offerName : offer
        }
        return {
            name: offerNameLabel,
            label: offer,
            count: getOffersCount(
                checkDataLength(filteredOffers) && filteredOffers,
                facetSelected,
                initialOfferData,
                offerLabel,
                offer,
                facetFilter,
                filterLabel,
            ),
            disabled: isDisabledStatus(filteredOffers, offerLabel, offer, filterLabel),
            selected: checkNestedProps(facetFilter, unprettifyLabel)
                ? facetFilter[unprettifyLabel].some(e => e === offer)
                : false,
            brandImageData: brandImage as BannerImagePath,
        }
    })
}

const brandFilter = (
    brandOffers: Offers[],
    facetSelected?: string,
    facetFilter?: Record<string, string[]>,
    brandsLabel?: string,
    prettifyLabelList?: BannerImagePath[] | null,
    filterLabel?: offerlabelType,
    filteredBrandOffers?: Offers[],
    offersSortBy?: string[],
    currentBannerId?: string,
    // eslint-disable-next-line max-params
): FacetValue[] => {
    // eslint-disable-next-line sonar/function-return-type
    let brands = brandOffers?.map(offer => {
        return offer.banner?.toString().includes(',') ? offer.banner?.toString().split(',') : offer.banner?.toString()
    }) as string[]

    brands = brands?.flatMap(banner => banner)
    brands = brands?.filter((b, i) => {
        return brands.indexOf(b) === i
    })

    const brandFacet = getOfferFilteredData(
        brands,
        facetSelected,
        brandOffers,
        brandsLabel,
        facetFilter,
        prettifyLabelList,
        [],
        filterLabel,
        filteredBrandOffers,
        currentBannerId,
    )
    const sortItem = sortByBrandsOrCategory(brandFacet, offersSortBy, false, true)
    if (isArrayNotEmpty(sortItem)) {
        return sortItem as FacetValue[]
    } else {
        return []
    }
}

const categoryFilter = (
    categoryOffers: Offers[],
    facetSelected?: string,
    facetFilter?: Record<string, string[]>,
    categoriesLabel?: string,
    filterLabel?: offerlabelType,
    filteredCategoryOffers?: Offers[],
    offersSortBy?: string[],
): FacetValue[] => {
    let categories = categoryOffers?.map(offer => {
        return offer.details.categoryLevel1
    })
    categories = categories?.filter((value, index) => {
        return categories.indexOf(value) === index
    })
    const categoryFacet = getOfferFilteredData(
        categories,
        facetSelected,
        categoryOffers,
        categoriesLabel,
        facetFilter,
        [],
        [],
        filterLabel,
        filteredCategoryOffers,
    )
    const sortedBrand = sortByBrandsOrCategory(categoryFacet, offersSortBy, false, false)
    if (isArrayNotEmpty(sortedBrand)) {
        return sortedBrand as FacetValue[]
    } else {
        return []
    }
}

/**
 * function to get offertype list
 * @param {Offers[]} offerTypeOffers offer data from filtered offerlist
 * @param {string} facetSelected filter selected offer
 * @param {Record<string, string[]>} facetFilter filtered checked array
 * @param {string} offerTypesLabel filtered offertype Label
 * @param {OfferTypePath[]} prettifyLabelList filtered offertype label mapped list
 * @param {string} filterLabel filter pffer label
 * @param {offerlabelType} facetFilter filtered offer list
 * @param {Offers[]} filteredOfferTypeOffers filter list based on type
 * @returns {FacetValue[]}
 */
const offerTypeFilter = (
    offerTypeOffers: Offers[],
    facetSelected?: string,
    facetFilter?: Record<string, string[]>,
    offerTypesLabel?: string,
    prettifyLabelList?: OfferTypePath[],
    filterLabel?: offerlabelType,
    filteredOfferTypeOffers?: Offers[],
): FacetValue[] => {
    const multiplierValue = getMultiplierLabel(offerTypeOffers)
    const spendAndGetValue = getSpendAndGetLabel(offerTypeOffers)
    // eslint-disable-next-line sonar/no-misleading-array-reverse
    const sortFacet = multiplierValue?.sort(stringToDescending)
    let offerTypes = spendAndGetValue.concat(sortFacet).filter(arr => Boolean(arr))
    offerTypes = offerTypes?.filter((value, index) => {
        return offerTypes.indexOf(value) === index
    })

    return getOfferFilteredData(
        offerTypes,
        facetSelected,
        offerTypeOffers,
        offerTypesLabel,
        facetFilter,
        [],
        prettifyLabelList,
        filterLabel,
        filteredOfferTypeOffers,
    )
}

/**
 * function to sort brand and categories in filter modal
 * @param {FacetValue[] | Offers[]} itemList
 * @param {string[]} offersSortBy
 * @param {boolean} isDropDownList
 * @param {boolean} sortBrands
 * @return {FacetValue[]| Offers[]}
 */
export const sortByBrandsOrCategory = <
    T extends { banner?: string; label?: string; details?: { categoryLevel1: string } },
>(
    itemList?: T[],
    offersSortBy?: string[],
    isDropDownList?: boolean,
    sortBrands = true,
): T[] => {
    let updatedFacetedList: T[] = []
    updatedFacetedList = itemList?.slice()?.sort((a, b) => {
        const firstBanner = isDropDownList ? a.banner : a.label
        const secondBanner = isDropDownList ? b.banner : b.label

        // Below conditions are used to sort brands (based on offersSortBy prop) and Categories (Alphabetical order)
        if (sortBrands && isArrayNotEmpty(offersSortBy)) {
            const isMultiBannerA = offersSortBy?.filter(ob => firstBanner?.includes(ob))
            const isMultiBannerB = offersSortBy?.filter(ob => secondBanner?.includes(ob))
            const sortedA = offersSortBy?.indexOf(isMultiBannerA[0])
            const sortedB = offersSortBy?.indexOf(isMultiBannerB[0])
            return (sortedA >>> 0) - (sortedB >>> 0)
        } else {
            const categoryL1A = isDropDownList ? a.details.categoryLevel1 : a.label
            const categoryL1B = isDropDownList ? b.details.categoryLevel1 : b.label

            if (categoryL1A && categoryL1B) {
                // localeCompare - comparisons for strings w accents (ex: Automotivé and AUTOMOTIVE is 1)
                return categoryL1A.localeCompare(categoryL1B)
            } else if (categoryL1A) {
                return magicNumber.MINUS_ONE
            } else {
                return magicNumber.ONE
            }
        }
    })
    return updatedFacetedList
}

/*
 * Initialize / update Facet data
 */
export const updateFilterFacet = (
    allOffers: Offers[],
    facetSelected: string | null,
    facetValues: Facet[],
    facetLabels: offerlabelType,
    filteredOffers: Offers[],
    filters: Record<string, string[]>,
    prettifyLabelList?: { brand: BannerImagePath[] | null; offerType: OfferTypePath[] },
    offersSortBy?: string[],
    currentBannerId?: string,
    // eslint-disable-next-line max-params
): Facet[] => {
    const { brand, category, offerType } = facetLabels
    // also pass selected offers to this helper and on selection of one facet type update filter option in another type
    return brand
        ? [
              {
                  type: FacetTypes.MULTISELECT,
                  label: brand,
                  values:
                      brand &&
                      brandFilter(
                          allOffers,
                          facetSelected,
                          filters,
                          brand,
                          prettifyLabelList.brand,
                          facetLabels,
                          filteredOffers,
                          offersSortBy,
                          currentBannerId,
                      ),
                  selected: isArrayNotEmpty(facetValues)
                      ? facetValues.filter(val => val.label === brand)[0].selected
                      : false,
              },
              {
                  type: FacetTypes.MULTISELECT,
                  label: offerType,
                  values: offerTypeFilter(
                      allOffers,
                      facetSelected,
                      filters,
                      offerType,
                      prettifyLabelList.offerType,
                      facetLabels,
                      filteredOffers,
                  ),
                  selected: isArrayNotEmpty(facetValues)
                      ? facetValues.filter(val => val.label === offerType)[0].selected
                      : false,
              },
              {
                  type: FacetTypes.MULTISELECT,
                  label: category,
                  values:
                      category &&
                      categoryFilter(
                          allOffers,
                          facetSelected,
                          filters,
                          category,
                          facetLabels,
                          filteredOffers,
                          offersSortBy,
                      ),
                  selected: isArrayNotEmpty(facetValues)
                      ? facetValues.filter(val => val.label === category)[0].selected
                      : false,
              },
          ]
        : [
              {
                  type: FacetTypes.MULTISELECT,
                  label: offerType,
                  values: offerTypeFilter(
                      allOffers,
                      facetSelected,
                      filters,
                      offerType,
                      prettifyLabelList.offerType,
                      facetLabels,
                      filteredOffers,
                  ),
                  selected: isArrayNotEmpty(facetValues)
                      ? facetValues.filter(val => val.label === offerType)[0].selected
                      : false,
              },
              {
                  type: FacetTypes.MULTISELECT,
                  label: category,
                  values: categoryFilter(
                      allOffers,
                      facetSelected,
                      filters,
                      category,
                      facetLabels,
                      filteredOffers,
                      offersSortBy,
                  ),
                  selected: isArrayNotEmpty(facetValues)
                      ? facetValues.filter(val => val.label === category)[0].selected
                      : false,
              },
          ]
}

/*
 * filter offers as per selected filter options
 */
export const filterOffersData = (
    filters: Record<string, string[]>,
    offers: Offers[],
    facetLabels: offerlabelType,
): Offers[] => {
    let selectedbrandsArray: Offers[] = []
    let selectedCategoriesArray: Offers[] = []
    let selectedofferTypesArray: Offers[] = []

    const { brand, category, offerType } = facetLabels

    selectedbrandsArray = getOfferData(selectedbrandsArray, filters, brand, offers, facetLabels)
    selectedCategoriesArray = getOfferData(selectedCategoriesArray, filters, category, offers, facetLabels)
    selectedofferTypesArray = getOfferData(selectedofferTypesArray, filters, offerType, offers, facetLabels)

    const combinedFilteredOffers = [selectedbrandsArray, selectedofferTypesArray, selectedCategoriesArray]
    let combinedSortedOffers: Offers[][] = []
    combinedSortedOffers = combinedFilteredOffers.filter(filteritem => {
        return filteritem.length
    })

    const combinedFilteredResult =
        isArrayNotEmpty(combinedSortedOffers) &&
        combinedSortedOffers?.shift().filter((offerlist: Offers) => {
            return combinedSortedOffers.every(item => {
                return item.indexOf(offerlist) !== magicNumber.MINUS_ONE
            })
        })

    const distinctValues = getDistinctOffer(combinedFilteredResult)
    const sortedOfferList = isArrayNotEmpty(distinctValues) && sortOffers(distinctValues)
    return isArrayNotEmpty(sortedOfferList) ? sortedOfferList : offers
}
