/* eslint-disable no-magic-numbers */
export enum magicNumber {
    MINUS_FIVETHOUSAND = -5000,
    MINUS_ONEHUNDREDFIFTY = -150,
    MINUS_NINE = -9,
    MINUS_FIVE = -5,
    MINUS_FOUR = -4,
    MINUS_THREE = -3,
    MINUS_TWO = -2,
    MINUS_ONE = -1,
    ZERO = 0,
    ONE = 1,
    TWO = 2,
    THREE = 3,
    FOUR = 4,
    FIVE = 5,
    SIX = 6,
    SEVEN = 7,
    EIGHT = 8,
    NINE = 9,
    TEN = 10,
    ELEVEN = 11,
    TWELVE = 12,
    THIRTEEN = 13,
    FOURTEEN = 14,
    FIFTEEN = 15,
    SIXTEEN = 16,
    EIGHTEEN = 18,
    TWENTY = 20,
    TWENTYFOUR = 24,
    TWENTYFIVE = 25,
    THIRTY = 30,
    THIRTYONE = 31,
    THIRTYSIX = 36,
    FORTY = 40,
    FORTYFIVE = 45,
    FIFTY = 50,
    SIXTY = 60,
    SEVENTY = 70,
    EIGHTY = 80,
    HUNDRED = 100,
    ONEHUNDREDFIFTY = 150,
    TWOHUNDRED = 200,
    THREEHUNDRED = 300,
    FOURHUNDREDFOUR = 404,
    SIXHUNDRED = 600,
    SEVENHUNDREDSIXTYEIGHT = 768,
    NINEHUNDREDNINTYNINE = 999,
    THOUSAND = 1000,
    ONETHOUSAND = 1000,
    THOUSANDTWOHUNDRED = 1200,
    TWOTHOUSAND = 2000,
    FIVETHOUSAND = 5000,
    SIXTHOUSAND = 6000,
    TENTHOUSAND = 10000,
    SIXTYTHOUSAND = 60000,
    ONEMILLION = 1000000,
    MILLISINDAY = 86400000,
    MILLISINHOUR = 3600000,
    MILLISINMINUTE = 60000,
}
