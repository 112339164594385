import BaseService from '../base.service'
import { getEnvironment } from '../../environments'
import {
    NearbyStoreList,
    StoreWithAvailability,
    storeListRequestType,
    StoresFilters,
} from '../../redux/models/storeDetails.interface'
import { checkDataLength } from '../../components/Accounts/Addresses/checkDataLength'
import { apiMethods, REQUEST_CASHING_DURATION } from '../service.constants'
import memoizeAsync from '../../utils/HttpClientUtils/memoizeAsync.utils'

const environment = getEnvironment()

/**
 * Stores Service - Store Details, Near by stores
 */
class StoresService extends BaseService {
    static apiGetWithCaching = memoizeAsync(
        REQUEST_CASHING_DURATION,
        StoresService.apiMethod.bind(null, apiMethods.GET),
    )
    /**
     * function to fetch store filters
     * @return {Filter[]}
     */
    getStoreFilters(): Promise<{ data: StoresFilters }> {
        const url = this.createStoreFilterUrl()
        return StoresService.apiMethod(apiMethods.GET, url, {})
    }

    /**
     * function to fetch store details
     * @param {string} storeId
     * @param {boolean} withCaching
     * @return {StoreWithAvailability}
     */
    getStoreDetails(storeId: string, withCaching = false): Promise<{ data: StoreWithAvailability }> {
        const url = this.createStoreDetailsURL(storeId)
        return withCaching
            ? StoresService.apiGetWithCaching(url, {
                  lang: StoresService.language,
              })
            : StoresService.apiMethod('GET', url, {
                  lang: StoresService.language,
              })
    }

    /**
     * function to fetch store details
     * @param {number} storeId
     * @return {string}
     */
    createStoreDetailsURL(storeId: string): string {
        const {
            API_BASE_URL,
            API_ENDPOINTS: { getStoreDetails },
        } = environment
        return `${API_BASE_URL}${getStoreDetails}/${storeId}`
    }

    /**
     * function to fetch list of stores
     * @param {storeListRequestType} requestPayload
     * @return {NearbyStoreList}
     */
    getStoreList(requestPayload: storeListRequestType): Promise<{ data: NearbyStoreList }> {
        let newRequestPayload = {
            ...requestPayload,
        }

        // eslint-disable-next-line no-warning-comments
        // TODO: Remove default params once api's are ready.
        newRequestPayload = this.defaultParams(newRequestPayload)

        const toMeters = 1000

        let finalPayload = {
            ...newRequestPayload,
            language: StoresService.language,
            banner: environment.banner,
        }

        if (newRequestPayload.radius) {
            finalPayload = {
                ...finalPayload,
                radius: newRequestPayload.radius * toMeters, // AEM will be sending in KM but hybris accepts in meters.
            }
        }

        if (!finalPayload.latitude && !finalPayload.longitude) {
            delete finalPayload.latitude
            delete finalPayload.longitude
        }

        if (!finalPayload.type) {
            delete finalPayload.type
        }

        const apiEndPoint = `${environment.API_BASE_URL}${environment.API_ENDPOINTS.getStoresDetails}`

        return StoresService.apiMethod('GET', apiEndPoint, finalPayload).then(data => {
            const hasKeys = checkDataLength(data.data)

            if (hasKeys) {
                return data
            } else {
                const newData: NearbyStoreList = {
                    stores: [],
                }
                data.data = newData
                return data
            }
        })
    }

    /**
     * For setting default params using the url search params.
     * @param {storeListRequestType} requestPayload
     * @return {storeListRequestType}
     */
    defaultParams(requestPayload: storeListRequestType) {
        // eslint-disable-next-line no-warning-comments
        // TODO: Need to remove when api works.
        const searchParams = new URLSearchParams(window.location.search)
        const latitude = searchParams.get('lat')
        const longitude = searchParams.get('long')
        const maxCount = searchParams.get('maxCount')
        const storeType = searchParams.get('type') || requestPayload.type
        const radius = searchParams.get('radius')
        if (!!latitude && !!longitude) {
            requestPayload.latitude = Number(latitude)
            requestPayload.longitude = Number(longitude)
        }
        if (!!maxCount) {
            requestPayload.maxCount = Number(maxCount)
        }
        if (!!requestPayload.type) {
            requestPayload.type = storeType
        }
        if (!!radius) {
            requestPayload.radius = Number(radius)
        }
        return requestPayload
    }

    /**
     * Create store details url
     * @param {string} endpoint
     * @return {URL}
     */
    createStoreFilterUrl(): string {
        const {
            API_ENDPOINTS: { storeFilters },
        } = environment
        return `${environment.API_BASE_URL}${storeFilters}?language=${StoresService.language}`
    }
}

// Creating a singleton instance of service
const storesService = new StoresService()

export { storesService }
export default storesService
