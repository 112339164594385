import React from 'react'
import { PREFIX } from '../../../../config'
import { OOSAtAllStoreMessageProps } from './OOSAtAllStoreMessage.type'

/**
 * OOSAtAllStoreMessage Component
 * @param {OOSAtAllStoreMessageProps} props - props for OOSAtAllStoreMessage
 * @returns {JSX.Element | null} OOSAtAllStoreMessage
 */
const OOSAtAllStoreMessage: React.FC<OOSAtAllStoreMessageProps> = ({
    ...props
}: OOSAtAllStoreMessageProps): JSX.Element | null => {
    const {
        isOutOfStockInAllStore,
        isAllVariantsSelected,
        isProductLevel,
        isProductFullyDiscontinued,
        fullyDiscontinuedTitle = '',
        outOfStockTitle,
        outOfStockText,
        isVariantsHidden,
        hidingAllFirstVariantOOSMessage,
    } = props
    const componentClassName = `${PREFIX}-buy-online`

    /**
     * Function to get the OOS Title based on corporate status
     * @returns {string | undefined} - returns OOS Title
     */
    const getOOSText = (): string | undefined => {
        return isProductFullyDiscontinued ? `${fullyDiscontinuedTitle} ` : `${outOfStockTitle} `
    }

    /**
     * Function to get the OOS Title
     * @returns {string | undefined} - returns OOS Title
     */
    const getOOSTitle = (): string | undefined => {
        return !isVariantsHidden ? getOOSText() : `${hidingAllFirstVariantOOSMessage} `
    }

    /**
     * Function to render OOS message
     * @returns {JSX.Element | null} renderOOSAtAllStores
     */
    const renderOutOfStockMsg = (): JSX.Element | null => {
        return !isVariantsHidden ? (
            <span className={`${componentClassName}__fulfillment-text`}>{outOfStockText}</span>
        ) : null
    }

    return (
        <>
            {(isOutOfStockInAllStore || isProductLevel) && isAllVariantsSelected ? (
                <div className={`${componentClassName}__fulfillment`}>
                    <span
                        className={`${componentClassName}__fulfillment-title`}
                        dangerouslySetInnerHTML={{
                            __html: getOOSTitle() as string,
                        }}
                    />
                    {renderOutOfStockMsg()}
                </div>
            ) : null}
        </>
    )
}

export default OOSAtAllStoreMessage
