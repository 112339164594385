import React from 'react'
import PropTypes from 'prop-types'
import { EmptyScreenProps } from './EmptyScreen.type'
import { PREFIX } from '../config'
import Button from '../Button'
import SanitizeStringContentWrapper from '../../utils/sanitizeStringContent'
/**
 * EmptyOffers component
 * @param {EmptyOffers} props
 * @return {JSX.Element} returns EmptyOffers component
 */
const EmptyScreen: React.FC<EmptyScreenProps> = props => {
    const {
        image,
        imageAltText,
        title,
        subtitle,
        mainClass,
        CTALabel,
        CTALink,
        toggleView,
        isSideView,
        onClickHandler,
        imageHeight,
        imageWidth,
    } = props

    const renderStackedView = (): JSX.Element => {
        const toggleViewClass = !toggleView ? `${mainClass}-content` : `${mainClass}-details`
        return (
            <div className={`${mainClass} ${toggleViewClass}`}>
                <img
                    className={`${mainClass}--img`}
                    src={image}
                    alt={imageAltText}
                    height={imageHeight}
                    width={imageWidth}
                />
                <SanitizeStringContentWrapper stringContent={title}>
                    {memoizedStringContent => (
                        <h4
                            className={`${mainClass}--heading`}
                            dangerouslySetInnerHTML={{ __html: memoizedStringContent }}
                        />
                    )}
                </SanitizeStringContentWrapper>
                <p className={`${mainClass}--content`} dangerouslySetInnerHTML={{ __html: subtitle }} />
                {CTALabel && (
                    <a className={`${PREFIX}-button ${PREFIX}-button--primary ${PREFIX}-button--small`} href={CTALink}>
                        {CTALabel}
                    </a>
                )}
            </div>
        )
    }

    const renderSideView = (): JSX.Element => {
        return (
            <div className={`${mainClass} ${PREFIX}-row`}>
                <div className={`${mainClass}__container-image`}>
                    <img
                        className={`${mainClass}__image`}
                        src={image}
                        alt={imageAltText}
                        height={imageHeight}
                        width={imageWidth}
                    />
                </div>
                <div className={`${mainClass}__container-content`}>
                    <h3 className={`${mainClass}__heading`}>{title}</h3>
                    <p className={`${mainClass}__description`}>{subtitle}</p>
                    {CTALabel && (
                        <Button type="primary" size="small" onClick={onClickHandler}>
                            {CTALabel}
                        </Button>
                    )}
                </div>
            </div>
        )
    }

    return isSideView ? renderSideView() : renderStackedView()
}

EmptyScreen.propTypes = {
    image: PropTypes.string.isRequired,
    imageAltText: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    onClickHandler: PropTypes.func,
    imageWidth: PropTypes.number,
    imageHeight: PropTypes.number,
}
export default EmptyScreen
