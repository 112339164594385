import {
    SEOSchemaTypes,
    SEOSchema,
    DepartmentStoreSchema,
    ProductSchema,
    SEOProductAvailability,
    LocalBusinessSchema,
    SpecificPageTypes,
} from './Seo.helper.types'
import { MagicNumber } from '../../analytics/analytics.type'
import { isArrayNotEmpty, checkNeedUpdateWeekdayHours, isPrerenderOrNot } from '@nl/lib'
import { BreadcrumbParentPageLink } from '../../components/Breadcrumbs/BreadcrumbsComp.type'
import { NavigationCategory } from '../../components/SecondaryNavigation/SecondaryNavigationWrapper.type'
import { schemaOrgURL, itemCondition } from '../components/Hoc/Seo.constants'
import {
    StoreWithAvailability,
    WeekDayOpening,
    SpecialOpeningDayList,
    StoreGeoPoint,
    StoreAddress,
    OpeningHours,
} from '../../redux/models/storeDetails.interface'

import { generateGoogleMapLink } from '../../utils/generateGoogleMapLink'
import { checkDataLength } from '../../components/Accounts/Addresses/checkDataLength'
import { ProductResponseData, ProductSku, FeatureBulletsDTO } from '../../redux/models/product.interface'
import extractPCodeFromUrl from '../../utils/PDP/extractPCodeFromUrl'
import { currencyFormat } from '../../globalConstants'
import { SeoHelper } from './Seo.helper'
import { GlobalPropsHelper } from '../../analytics/helpers/globalProps'
import { IBreadcrumb } from '../../redux/models/commonContent.interface'
import { checkAndPopulateMpn } from './checkAndPopulateMpn'
import { ProductDataForABTest } from '../../components/BuyBox/BuyBox.type'

const globalProps = new GlobalPropsHelper()
const { storeSchemaType } = globalProps.readDataAlternateLangDetails()

/**
 * function to prepare schema Elements/Tag for BreadCrumbList
 * @param {string} breadcrumbList
 * @param {string} breadcrumbsCommonContent
 * @returns {string}
 */
export const getBreadCrumbSchema = (
    breadcrumbList: BreadcrumbParentPageLink[],
    breadcrumbsCommonContent: IBreadcrumb,
): string => {
    const schemaObj: SEOSchema = {
        '@context': schemaOrgURL,
        '@type': SEOSchemaTypes.BreadcrumbList,
        itemListElement: [],
    }
    const { backToSearchResults, backToResults } = breadcrumbsCommonContent
    const interActiveBreadCrumbLinks = breadcrumbList
        .slice(MagicNumber.ZERO, MagicNumber.MINUS_ONE) // exclude current page and back page link
        .filter((item: BreadcrumbParentPageLink) => item.name !== backToSearchResults && item.name !== backToResults)
    isArrayNotEmpty(interActiveBreadCrumbLinks) &&
        interActiveBreadCrumbLinks.map((breadCrumbLink: BreadcrumbParentPageLink, index: number) =>
            schemaObj.itemListElement?.push({
                '@type': SEOSchemaTypes.ListItem,
                name: breadCrumbLink.name as string,
                position: (index + MagicNumber.ONE).toString(), // Position starts from 1
                item: `${window.location.origin}${breadCrumbLink.link as string}`,
            }),
        )
    return (isArrayNotEmpty(schemaObj.itemListElement) as unknown as string) && JSON.stringify(schemaObj)
}

/**
 * function to prepare schema Elements/Tag for Secondary Navigation
 * @param {string} categoriesList
 * @returns {string}
 */
export const getSiteNavigationSchema = (categoriesList: NavigationCategory[]): string => {
    const schemaObj: SEOSchema = {
        '@context': schemaOrgURL,
        '@type': SEOSchemaTypes.ItemList,
        itemListElement: [],
    }

    isArrayNotEmpty(categoriesList) &&
        categoriesList.map((category: NavigationCategory, index: number) =>
            schemaObj.itemListElement?.push({
                '@type': SEOSchemaTypes.SiteNavigationElement,
                name: category.name,
                position: (index + MagicNumber.ONE).toString(), // Position starts from 1
                url: `${window.location.origin}${category.url as string}`,
            }),
        )

    return (isArrayNotEmpty(schemaObj.itemListElement) as unknown as string) && JSON.stringify(schemaObj)
}

/**
 * function to prepare schema Elements/Tag for Department Store
 * @param {StoreWithAvailability} storeDetails
 * @param {string} logoURL
 * @returns {string}
 */
export const getDepartmentStoreSchema = (storeDetails: StoreWithAvailability, logoURL: string): string | undefined => {
    const {
        geoPoint = {} as StoreGeoPoint,
        address = {} as StoreAddress,
        openingHours = {} as OpeningHours,
    } = storeDetails

    const { latitude, longitude } = geoPoint
    const { line1, line2, town, phone, region, country, postalCode } = address
    const { weekDayOpeningList, specialDayOpeningList } = openingHours

    if (!checkDataLength(storeDetails)) {
        return undefined
    }

    const redirectLink = generateGoogleMapLink(latitude, longitude)
    const schemaObj: DepartmentStoreSchema = {
        '@context': schemaOrgURL,
        '@type': storeSchemaType,
        name: storeDetails.displayName,
        url: storeDetails.url,
        hasMap: redirectLink,
        geo: {
            '@type': SEOSchemaTypes.GeoCoordinates,
            latitude: latitude,
            longitude: longitude,
        },
        openingHoursSpecification: [],
        address: {
            '@type': SEOSchemaTypes.PostalAddress,
            streetAddress: `${line1} ${line2}`,
            addressLocality: town,
            addressRegion: region?.name,
            postalCode: postalCode,
            addressCountry: country?.name,
        },
        telephone: phone,
        image: logoURL,
    }

    isArrayNotEmpty(weekDayOpeningList) &&
        weekDayOpeningList.map((weekDayObj: WeekDayOpening) => {
            const weekDayObjUpdate = checkNeedUpdateWeekdayHours(storeDetails, weekDayObj) as WeekDayOpening
            return schemaObj.openingHoursSpecification?.push({
                '@type': SEOSchemaTypes.OpeningHoursSpecification,
                dayOfWeek: weekDayObjUpdate?.weekDay,
                opens: !weekDayObjUpdate?.closed
                    ? `${weekDayObjUpdate?.openingTime?.hour}:${weekDayObjUpdate?.openingTime?.minute}`
                    : '00:00',
                closes: !weekDayObjUpdate?.closed
                    ? `${weekDayObjUpdate?.closingTime?.hour}:${weekDayObjUpdate?.closingTime?.minute}`
                    : '00:00',
            })
        })

    isArrayNotEmpty(specialDayOpeningList) &&
        (specialDayOpeningList as SpecialOpeningDayList[]).map((specialDayObj: SpecialOpeningDayList) =>
            schemaObj.openingHoursSpecification?.push({
                '@type': SEOSchemaTypes.OpeningHoursSpecification,
                validFrom: specialDayObj?.formattedDate,
                validThrough: specialDayObj?.formattedDate,
                opens: `${specialDayObj?.openingTime?.hour}:${specialDayObj?.openingTime?.minute}`,
                closes: `${specialDayObj?.closingTime?.hour}:${specialDayObj?.closingTime?.minute}`,
            }),
        )

    return JSON.stringify(schemaObj)
}

/**
 *function to prepare schema Element/Tag for LocalBusiness Store
 * @param {StoreWithAvailability} storeDetails
 * @param {string} logoURL
 * @returns {string}
 */
export const getLocalBusinessSchema = (storeDetails: StoreWithAvailability, logoURL: string): string | undefined => {
    const { address = {} as StoreAddress } = storeDetails

    const { line1, line2, town, phone, region, country, postalCode } = address

    if (!checkDataLength(storeDetails)) {
        return undefined
    }

    const schemaObj: LocalBusinessSchema = {
        '@context': schemaOrgURL,
        '@type': SEOSchemaTypes.LocalBusiness,
        name: SeoHelper.getSiteBannerTitle(),
        address: {
            '@type': SEOSchemaTypes.PostalAddress,
            postalCode: postalCode,
            streetAddress: `${line1} ${line2}`,
            addressCountry: country?.name,
            addressRegion: region?.name,
            addressLocality: town,
        },
        image: logoURL,
        telephone: phone,
    }
    return JSON.stringify(schemaObj)
}

/**
 * function to fetch sku code for product
 * @param {ProductSku[]} skus
 * @returns {string}
 */
export const getSkuCode = (skus: ProductSku[]): string => {
    if (isArrayNotEmpty(skus)) {
        return skus.length > MagicNumber.ONE ? extractPCodeFromUrl()?.skuCode : skus[0]?.code
    } else {
        return ''
    }
}

/**
 * function to prepare schema Elements/Tag for Product
 * @param {ProductResponseData} productDetails
 * @param {ProductSku} productSkuData
 * @param {string} storeId
 * @param {boolean} isOutOfStock
 * @param {string} defaultImage
 * @param {boolean} enableMPNForAll
 * @param {boolean} enableMPNForAutomotive
 * @param {boolean} enableABTestForProducts
 * @param {ProductDataForABTest} productListForABTestProps
 * @returns {string}
 */
export const getProductSchema = (
    productDetails: ProductResponseData,
    productSkuData: ProductSku | null,
    storeId: string,
    isOutOfStock: boolean,
    defaultImage: string,
    // eslint-disable-next-line default-param-last
    enableMPNForAll = false,
    // eslint-disable-next-line default-param-last
    enableMPNForAutomotive = false,
    enableABTestForProducts: boolean,
    productListForABTestProps?: ProductDataForABTest,
    // eslint-disable-next-line max-params
): string => {
    const { name, longDescription, brand, images, featureBullets, skus } = productDetails
    const skuCode = getSkuCode(skus)
    const primaryImageURL = isArrayNotEmpty(images) && images[0]?.url
    const concatinatedFeatures = []
    concatinatedFeatures.push(
        featureBullets?.map((featureDescription: FeatureBulletsDTO) => featureDescription.description),
    )
    const priceWithFeeValue = (productSkuData?.currentPrice?.value || 0) + (productSkuData?.feeValue || 0) // adding price with fee value for seo schema
    /**
     * function to prepare schema Elements/Tag for Product
     * @returns {Object}
     */
    const getProductPriceSchema = (): {
        '@type': string
        highPrice?: string
        lowPrice?: string
        price?: string
    } => {
        if (enableABTestForProducts && isPrerenderOrNot()) {
            const pCodeFromProductArray = productListForABTestProps?.data?.productList?.find(
                item => item.pCode === extractPCodeFromUrl().pCode,
            )
            if (pCodeFromProductArray) {
                return {
                    '@type': SEOSchemaTypes.aggregateOffer,
                    highPrice: pCodeFromProductArray.maxPrice,
                    lowPrice: pCodeFromProductArray.minPrice,
                }
            }
        }
        return { '@type': SEOSchemaTypes.Offer, price: priceWithFeeValue.toString() }
    }
    const schemaObj: ProductSchema = {
        '@context': schemaOrgURL,
        '@id': SeoHelper.canonicalUrlData(productDetails, false as unknown as SpecificPageTypes),
        '@type': SEOSchemaTypes.Product,
        name: name,
        description: longDescription || concatinatedFeatures.join(), // either description or feature bullets
        brand: {
            '@type': SEOSchemaTypes.Brand,
            name: brand?.label,
        },
        image: primaryImageURL || defaultImage,
    }

    checkAndPopulateMpn(schemaObj, productDetails, enableMPNForAll, enableMPNForAutomotive)

    if (Boolean(Number(skuCode)) && storeId) {
        // if sku page  is loaded with store id
        schemaObj.offers = {
            ...getProductPriceSchema(), // A/B Test for PDP pages by conditionally rendering offer and aggregate offer schema type
            priceValidUntil: productSkuData?.priceValidUntil,
            priceCurrency: currencyFormat,
            itemCondition: itemCondition,
            availability: isOutOfStock ? SEOProductAvailability.OutOfStock : SEOProductAvailability.Instock,
            availableAtOrFrom: {
                branchCode: storeId,
            },
        }
        schemaObj.sku = skuCode
    }

    try {
        return JSON.stringify(schemaObj)
    } catch (e) {
        return ''
    }
}
