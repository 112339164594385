/* eslint-disable max-lines */
import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { useGlobalResizeEvent } from '../../utils/useWindowEvent'
import { BREAKPOINTS, PREFIX } from '../config'
import Icon from '../Icon'
import Tooltip from '../Tooltip'
import { PriceMessage, PriceType, FeeType, Price as PriceInterface } from './Price.types'
import { checkInstanceOfPrice } from '../ProductReusableCard/instanceCheckingUtil'
import { addModalAttribute } from '../../utils/modalAccessibility'
import { checkWasCurrentPrice, getNowWasLabel } from '../../helpers/Price.helper'
import { isArrayNotEmpty } from '../../utils'
import { magicNumber } from '../../utils/magicNumber'
import { multiLanguageLabel } from '../../globalConstants/global.constant'
import { isEnterPressed } from '../../helpers/checkKeyboardKey.helper'
import { isAtleastOneParamValid } from '../../utils/validParams'
import { SET_OF_FOUR_MULTIPLAYER, wasPriceTestId } from './Price.constant'
import RangePriceOrFromPrice from './RangePriceOrFromPrice'
import TotalRangePriceOrFromPrice from './TotalRangePriceOrFromPrice'
import cx from 'classnames'

/**
 * Price component
 * @param {PriceType} props - priceStyle, promotionalPriceLabel, thresholdValue, unitPriceLabel, clearancePriceLabel, discountValue, a11yPriceRangeFrom, a11yPriceRangeTo, hideSaveLabel, a11yStrikeOutPrice, a11yStrikeOutPriceRange, setOf4Label, setOf4NowLabel isStaggered, productSkus
 * @returns {JSX.Element} returns Price component
 */
const Price: React.FC<PriceType> = ({
    priceStyle,
    unitPriceLabel,
    clearancePriceLabel,
    a11yPriceRangeFrom,
    a11yPriceRangeTo,
    a11yStrikeOutPrice,
    a11yStrikeOutPriceRange,
    a11yTooltipIcon,
    a11yCloseIconLabel,
    feeTitle,
    feeDisclaimerMessage,
    feeDisclaimerTitle,
    originalPrice,
    currentPrice,
    displayWasLabel,
    language,
    isMoreQuantityCartPage,
    eachLabel,
    priceValue,
    priceMessage,
    isAutomotiveEachLabel,
    feeDisclaimerType,
    scrollToFooter,
    a11yRecycleFeesTriangleIcon,
    wasFromLabel,
    wasToLabel,
    fromLabel,
    toLabel,
    nowFromLabel,
    nowToLabel,
    isVehicleInformationSaved,
    isWheelOrTirePDP,
    totalCurrentPrice,
    totalOriginalPrice,
    setOfFourLabel,
    setOfFourNowLabel,
    setOfTwoLabel,
    setOfTwoNowLabel,
    isWheelOrTire,
    productSkus,
    isStaggered,
    isStaggeredFront,
    showFromNow,
    showToNow,
    isRebate,
    isShoppingCartPage,
    showEachTextForSaveLabel,
    overridePriceHeight,
    hideSaveLabel,
    saveMessage,
    showDiscountedPrice,
    highlightDiscountedPrice,
    discountValue,
    totalPrice,
    isAppliedPromoExist,
    enablePdpPricingEnhancement,
    a11yClickToReadFootnote,
    accessibilityPriceId,
    isOnSaleOrClearance,
    fromAutomotiveSpecification,
    plusMinusSymbol,
    isProductLevelPDP,
    isModalPresent,
    staggeredfulfillmentData,
    isOnSale = false,
    isRangeView = false,
}): JSX.Element => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= BREAKPOINTS.tabletMaxWidth)
    const isFrenchLabel = language?.toUpperCase() === multiLanguageLabel.frenchLangLabel

    // Flag to check if gen merch PDP and feature toggle is enabled for PDP-508 [Reduce number of lines for pricing on gen merch PDP]
    const isGenmerchPDP = !isAutomotiveEachLabel && enablePdpPricingEnhancement

    /**
     * Return true if from label is allowed.
     * @param {PriceInterface[]} prices
     * @returns
     */
    const canShowFrom = (...prices: PriceInterface[]) =>
        !isRangeView || !prices?.filter(Boolean)?.find(item => item.maxPrice)

    /**
     * Checks for tabletMaxWidth width on window resize
     */

    const handleResize = (): void => {
        setIsMobile(window.innerWidth <= BREAKPOINTS.tabletMaxWidth)
    }

    useGlobalResizeEvent(handleResize)
    const checkRange = (str: string) => {
        return !!str && str.includes('-')
    }

    const screenReaderPrice = (actualPrice: string) => {
        const rangeToIndex = 1
        return checkRange(actualPrice)
            ? a11yPriceRangeFrom &&
                  a11yPriceRangeTo &&
                  `${a11yPriceRangeFrom} ${actualPrice.split('-')[0]} ${a11yPriceRangeTo} ${
                      actualPrice.split('-')[rangeToIndex]
                  }`
            : actualPrice
    }

    const cartPageOrMoreQuantity = isShoppingCartPage || isMoreQuantityCartPage

    /**
     * Is need to render Show Now Label
     *
     * @returns { boolean }
     */
    const isShowNowLabelVisible = (): boolean => {
        return (
            // eslint-disable-next-line sonar/expression-complexity
            isPromoGivesAdditionalDiscountOnItem() ||
            (cartPageOrMoreQuantity && !isStaggered) ||
            (!cartPageOrMoreQuantity && isWheelOrTire)
        )
    }

    /**
     * Func to render Show Now Label
     * @returns { JSX.Element } - returns descriptions
     */
    const renderShowNowLabel = (): JSX.Element => {
        return (
            isShowNowLabelVisible() && (
                // eslint-disable-next-line sonarjs/no-nested-template-literals
                <div className={`${showNowLabel() ? `${PREFIX}-price--now` : `${PREFIX}-xs-none`}`}>
                    {showNowLabel()}
                </div>
            )
        )
    }

    /**
     * Func to render Show Now To Label
     * @returns { JSX.Element } - returns descriptions
     */
    const renderShowNowToLabel = (): JSX.Element => {
        return (
            isShowNowLabelVisible() && (
                // eslint-disable-next-line sonarjs/no-nested-template-literals
                <div className={`${showNowToLabel() ? `${PREFIX}-price--now` : `${PREFIX}-xs-none`}`}>
                    {showNowToLabel()}
                </div>
            )
        )
    }

    /**
     * Function to render each Label
     * @returns { JSX.Element }
     */
    const renderEachLable = (): JSX.Element => {
        return <div className={`${PREFIX}-price__each-label`}>{eachLabel}</div>
    }

    const renderCurrentPrice = (): JSX.Element => {
        let currentPriceCls = priceStyle ? `${priceStyle}` : `${PREFIX}-price__value`
        currentPriceCls = `${currentPriceCls} ${overridePriceHeight ? 'current-price--min-height' : ''}`
        const label = renderShowNowLabel()
        const labelTo = renderShowNowToLabel()
        const selectedPrice = currentPrice && Object.keys(currentPrice).length ? currentPrice : originalPrice
        const justPrice = () => (
            <RangePriceOrFromPrice
                price={selectedPrice}
                language={language}
                isRangeView={isRangeView}
                fromLabel={label}
                toLabel={labelTo}
                showToNow={showToNow}
            />
        )
        const enlishPriceLabel = () => canShowFrom(selectedPrice) && !isFrenchLabel && label
        const frenchPriceLabel = () => canShowFrom(selectedPrice) && isFrenchLabel && label

        return (
            <div
                aria-label={screenReaderPrice(checkWasCurrentPrice(currentPrice, originalPrice, language))}
                className={`${currentPriceCls} ${isOnSaleOrClearance ? definePriceStyle() : ''}`}
                data-testid="price">
                {enlishPriceLabel()}
                {justPrice()}
                {frenchPriceLabel()}
                {isMoreQuantityCartPage && renderEachLable()}
            </div>
        )
    }

    const [visibility, setVisibility] = useState(false)
    const buttonRef = useRef()

    const [saveTooltipVisibility, setSaveTooltipVisibility] = useState(false)
    const saveTooltipButtonRef = useRef()
    /**
     * To display tooltip description
     *
     * @param { string } tooltipDesc - tooltip description message
     * @returns { JSX.Element } - returns descriptions
     */
    const toolTipText = (tooltipDesc: string): JSX.Element => {
        return <div data-testid="tooltip-text" dangerouslySetInnerHTML={{ __html: tooltipDesc }}></div>
    }

    /**
     * function to handle tool tip on click
     * @param {React.MouseEvent<HTMLButtonElement, MouseEvent> | React.KeyboardEvent<HTMLElement>} e
     * @param {React.FunctionComponent} visibilitySetter
     * @param {boolean} currentVisibility
     * @returns {void}
     */
    const toolTipButtonHandler = (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.KeyboardEvent<HTMLElement>,
        visibilitySetter: (value: React.SetStateAction<boolean>) => void,
        currentVisibility: boolean,
    ): void => {
        e.stopPropagation()
        e.preventDefault()
        addModalAttribute(e)
        visibilitySetter(!currentVisibility)
    }

    /**
     * function to handle tool tip button when enter is pressed from keyboard
     * @param {React.KeyboardEvent<HTMLElement>} e
     * @param {React.FunctionComponent} visibilitySetter
     * @param {boolean} currentVisibility
     * @returns {void}
     */
    const toolTipButtonKeyboardHandler = (
        e: React.KeyboardEvent<HTMLElement>,
        visibilitySetter: (value: React.SetStateAction<boolean>) => void,
        currentVisibility: boolean,
    ): void => {
        isEnterPressed(e.key) && toolTipButtonHandler(e, visibilitySetter, currentVisibility)
    }

    const feesComponent = (): JSX.Element => {
        return (
            feeTitle && (
                <div className={`${PREFIX}-price__eco-fee ${overridePriceHeight ? 'eco-fee--min-height' : ''}`}>
                    {feeDisclaimerType === FeeType.TIRE_RECYCLE_FEE
                        ? isAtleastOneParamValid(!isStaggered, !showFromNow, fromAutomotiveSpecification) && (
                              <div
                                  ref={buttonRef}
                                  onClick={scrollToFooter}
                                  onKeyPress={scrollToFooter}
                                  className={`${PREFIX}-price__tire-fee-tooltip-btn`}
                                  role="link"
                                  tabIndex={magicNumber.ZERO}
                                  data-testid="tire-recycle-fee"
                                  aria-label={feeTitle.concat('-', a11yClickToReadFootnote)}>
                                  {feeTitle.trim()}
                                  <sup aria-hidden="true">{a11yRecycleFeesTriangleIcon}</sup>
                              </div>
                          )
                        : feeDisclaimerMessage && (
                              <>
                                  {feeTitle}
                                  <button
                                      ref={buttonRef}
                                      className={`${PREFIX}-price__eco-fee-tooltip-btn`}
                                      onClick={event => toolTipButtonHandler(event, setVisibility, visibility)}
                                      onKeyDown={event =>
                                          toolTipButtonKeyboardHandler(event, setVisibility, visibility)
                                      }
                                      data-testid="eco-fee"
                                      aria-label={a11yTooltipIcon}>
                                      <span
                                          className={visibility ? `${PREFIX}-price__eco-fee-tooltip-btn--active` : ''}>
                                          <Icon type="ct-information-details" size="sm" />
                                      </span>
                                  </button>
                              </>
                          )}
                    {!!visibility && (
                        <Tooltip
                            visibility={visibility}
                            setVisibility={setVisibility}
                            iconID="ct-close"
                            headerText={feeDisclaimerTitle}
                            bodyText={toolTipText(feeDisclaimerMessage)}
                            coords={buttonRef.current}
                            a11yCloseIconLabel={a11yCloseIconLabel}
                            isModalPresent={isModalPresent}
                        />
                    )}
                </div>
            )
        )
    }
    let wasPriceClass = feeTitle ? `${PREFIX}-price--was-eco` : `${PREFIX}-price--was`
    wasPriceClass = overridePriceHeight ? `${wasPriceClass} ${PREFIX}-price--was-price--min-height` : wasPriceClass

    /**
     * Function to show each label
     * @returns {JSX.Element}
     */
    const showEachLabel = (): JSX.Element => {
        return isAutomotiveEachLabel && <span className={`${PREFIX}-price--each`}>{eachLabel}</span>
    }

    /**
     * function to check original price is present or not
     * @returns {boolean}
     */
    const checkIfOriginalPriceAvailable = (): boolean => {
        return !!(originalPrice?.value || originalPrice?.minPrice)
    }
    /**
     * function to get a11y price was label
     * @returns {string}
     */
    const getA11yPriceWasLabel = (): string => {
        return originalPrice?.minPrice && currentPrice?.minPrice ? a11yStrikeOutPriceRange : a11yStrikeOutPrice
    }

    /**
     * Render Now/Was label Element
     * @param {string} nowWasLabel
     * @param {number} index
     * @returns { JSX.Element } - returns Now/Was label Element
     */
    const renderNowWasLabel = (nowWasLabel: string, index: number): JSX.Element => {
        const nowWasPriceLabel = nowWasLabel?.split('[0]')[index]
        return (
            !!nowWasPriceLabel && (
                <span aria-hidden="true">
                    {`${nowWasPriceLabel}`}
                    {!!originalPrice?.value && <sup aria-hidden="true">{plusMinusSymbol}</sup>}
                </span>
            )
        )
    }

    /**
     * To display original price with each label
     * @returns { JSX.Element }
     */
    const priceAndEachLabel = (): JSX.Element => {
        return (
            <>
                <RangePriceOrFromPrice
                    price={originalPrice}
                    language={language}
                    isRangeView={isRangeView}
                    fromLabel={fromLabel}
                    toLabel={toLabel}
                    showToNow={showToNow}
                />
                {!isProductLevelPDP && showEachLabel()}
            </>
        )
    }

    /**
     * To display was price in cart
     *
     * @return { JSX.Element } - returns was price
     */

    const wasPriceComponent = (): JSX.Element => {
        const priceLabel = getA11yPriceWasLabel()
        const nowWasLabel = getNowWasLabel(
            displayWasLabel,
            originalPrice,
            unitPriceLabel,
            wasFromLabel,
            fromLabel,
            isRangeView,
        )
        const nowWasToLabel = getNowWasLabel(
            displayWasLabel,
            originalPrice,
            unitPriceLabel,
            wasToLabel,
            toLabel,
            isRangeView,
        )
        const fromNowWasLabelPart = (
            <>
                {renderNowWasLabel(nowWasLabel, 0)} {renderNowWasLabel(nowWasLabel, 1)}{' '}
            </>
        )
        const toNowWasLabelPart = (
            <>
                {renderNowWasLabel(nowWasToLabel, 0)} {renderNowWasLabel(nowWasToLabel, 1)}{' '}
            </>
        )
        const innerPrice = () => (
            <>
                {isVehicleInformationSaved ? (
                    <>
                        <RangePriceOrFromPrice
                            price={totalOriginalPrice}
                            language={language}
                            isRangeView={isRangeView}
                            fromLabel={fromNowWasLabelPart}
                            toLabel={toNowWasLabelPart}
                            showToNow={showToNow}
                        />
                        <RangePriceOrFromPrice
                            price={originalPrice}
                            language={language}
                            isRangeView={isRangeView}
                            fromLabel={fromNowWasLabelPart}
                            toLabel={toNowWasLabelPart}
                            showToNow={showToNow}
                        />
                        {eachLabel}
                    </>
                ) : (
                    priceAndEachLabel()
                )}
            </>
        )

        if (checkIfOriginalPriceAvailable() && !isStaggered) {
            if (language.toUpperCase() === multiLanguageLabel.frenchLangLabel && nowWasLabel?.includes('[0]')) {
                return (
                    <div className={wasPriceClass} data-tesId={wasPriceTestId}>
                        <s aria-hidden="true">{innerPrice()}</s>
                        {fromNowWasLabelPart}
                        <span className="sr-only">
                            {priceLabel
                                ? `${priceLabel} ${screenReaderPrice(checkInstanceOfPrice(originalPrice, language))}`
                                : ''}
                        </span>
                    </div>
                )
            } else {
                return (
                    <div className={wasPriceClass}>
                        {fromNowWasLabelPart}
                        <s aria-hidden="true">{innerPrice()}</s>
                        <span className="sr-only">
                            {priceLabel
                                ? `${priceLabel} ${screenReaderPrice(checkInstanceOfPrice(originalPrice, language))}`
                                : ''}
                        </span>
                    </div>
                )
            }
        } else {
            return null
        }
    }

    /**
     * To display now label string
     *
     * @returns {string } - now or now from or empty string
     */
    const showNowLabel = (): string => {
        return getNowWasLabel(displayWasLabel, currentPrice, clearancePriceLabel, nowFromLabel, fromLabel, isRangeView)
    }

    /**
     * To display to label string
     *
     * @returns {string } - now or now to or empty string
     */
    const showNowToLabel = (): string => {
        return getNowWasLabel(displayWasLabel, currentPrice, clearancePriceLabel, nowToLabel, toLabel, isRangeView)
    }

    /**
     * Render Cart Now/Was label Element
     * @param {string} cartPriceLabel
     * @param {number} index
     * @returns { JSX.Element } - returns Now/Was from label Element
     */
    const renderCartWasNowLabel = (cartPriceLabel: string): JSX.Element => {
        const containerClassName = cartPriceLabel ? `${PREFIX}-price--now` : `${PREFIX}-xs-none`
        return (
            !isMoreQuantityCartPage &&
            !isPromoGivesAdditionalDiscountOnItem() && (
                <div className={`${containerClassName}`}>{!isStaggered && cartPriceLabel}</div>
            )
        )
    }

    const hasDiscount = (): boolean => {
        return Boolean(discountValue?.value)
    }

    const definePriceStyle = (): string => {
        if (hasDiscount() && highlightDiscountedPrice) {
            return `${PREFIX}-price--total--bold`
        } else if (isOnSaleOrClearance) {
            return `${PREFIX}-price--total--red`
        } else if (isOnSale) {
            return `${PREFIX}-price--total--bold--red`
        } else {
            return `${PREFIX}-price--total`
        }
    }

    /**
     * To display total price for cart component
     *
     * @returns { JSX.Element } - returns total price
     */
    const totalPriceCartComponent = (): JSX.Element => {
        const cartPriceLabel = showNowLabel()
        const updatedFromLabel = renderCartWasNowLabel(cartPriceLabel)
        const updatedToLabel = showNowToLabel()

        return (
            <div className={`${PREFIX}-price--charge`}>
                <div className={definePriceStyle()} data-testid="priceTotal">
                    {!isFrenchLabel &&
                        canShowFrom(priceValue, currentPrice, originalPrice, totalPrice) &&
                        updatedFromLabel}
                    <TotalRangePriceOrFromPrice
                        {...{
                            isMoreQuantityCartPage,
                            priceValue,
                            currentPrice,
                            originalPrice,
                            language,
                            showDiscountedPrice,
                            totalPrice,
                            isAppliedPromoExist,
                            isRangeView,
                            fromLabel: updatedFromLabel,
                            toLabel: updatedToLabel,
                            showToNow,
                        }}
                    />
                    {isFrenchLabel &&
                        canShowFrom(priceValue, currentPrice, originalPrice, totalPrice) &&
                        updatedFromLabel}
                    {showEachLabel()}
                </div>
            </div>
        )
    }

    /**
     * Render Now/Was from label Element
     * @param {string} priceLabel
     * @param {number} index
     * @returns { JSX.Element } - returns Now/Was from label Element
     */
    const renderWasNowFromLabel = (priceLabel: string, index: number): JSX.Element => {
        const containerClassName = priceLabel ? `${PREFIX}-price--now` : `${PREFIX}-xs-none`
        return (
            !isMoreQuantityCartPage &&
            !!priceLabel &&
            !isPromoGivesAdditionalDiscountOnItem() && (
                <span className={`${containerClassName}`}>{!isStaggered && priceLabel.split('[0]')[index]}</span>
            )
        )
    }

    /**
     * To display total price in cart
     * @returns { JSX.Element } - returns total price
     */
    const totalPriceComponent = (): JSX.Element => {
        const priceLabel = showNowLabel()
        const priceToLabel = showNowToLabel()

        if (language.toUpperCase() === multiLanguageLabel.frenchLangLabel && priceLabel?.includes('[0]')) {
            return (
                <div className={`${PREFIX}-price--charge`}>
                    {renderWasNowFromLabel(priceLabel, 0)}
                    <span className={definePriceStyle()} data-testid="priceTotal">
                        <TotalRangePriceOrFromPrice
                            {...{
                                isMoreQuantityCartPage,
                                priceValue,
                                currentPrice,
                                originalPrice,
                                language,
                                showDiscountedPrice,
                                totalPrice,
                                isAppliedPromoExist,
                                isRangeView,
                                fromLabel: priceLabel,
                                toLabel: priceToLabel,
                                showToNow,
                            }}
                        />
                    </span>
                    &nbsp;
                    {renderWasNowFromLabel(priceLabel, magicNumber.ONE)}
                    {showEachLabel()}
                </div>
            )
        } else {
            return (
                <div className={`${PREFIX}-price--charge`}>
                    {renderWasNowFromLabel(priceLabel, 0)}
                    {renderWasNowFromLabel(priceLabel, magicNumber.ONE)}
                    <span className={definePriceStyle()} data-testid="priceTotal">
                        <TotalRangePriceOrFromPrice
                            {...{
                                isMoreQuantityCartPage,
                                priceValue,
                                currentPrice,
                                originalPrice,
                                language,
                                showDiscountedPrice,
                                totalPrice,
                                isAppliedPromoExist,
                                isRangeView,
                                fromLabel: priceLabel,
                                toLabel: priceToLabel,
                                showToNow,
                            }}
                        />
                        {showEachLabel()}
                    </span>
                </div>
            )
        }
    }
    /**
     * To display set of 4||2 label string
     *
     * @returns {string } - Set of 4 or Set of 4 Now ||  Set of 2 or Set of 2 Now
     */
    const displaySetOfLabel = (): string => {
        const frontSkuDisplayWasLabel = productSkus && productSkus[magicNumber.ZERO]?.displayWasLabel
        const rearSkuDisplayWasLabel = productSkus && productSkus[magicNumber.ONE]?.displayWasLabel
        if (isArrayNotEmpty(staggeredfulfillmentData)) {
            // eslint-disable-next-line no-magic-numbers
            if (staggeredfulfillmentData.length === 2) {
                return frontSkuDisplayWasLabel && rearSkuDisplayWasLabel ? setOfFourNowLabel : setOfFourLabel
            } else {
                return frontSkuDisplayWasLabel || rearSkuDisplayWasLabel ? setOfTwoNowLabel : setOfTwoLabel
            }
        } else return displayWasLabel ? setOfFourNowLabel : setOfFourLabel
    }

    /**
     * Function to display from label in buybox
     * @param {string} updatedFromLabel
     * @returns {JSX.Element}
     */
    const renderSetOfFromPrice = (updatedFromLabel: string, updatedToLabel?: string): JSX.Element => {
        const splitIndex = Number(isFrenchLabel)
        const splitedUpdatedFromLabel = updatedFromLabel?.split('[0]')[splitIndex]

        const totalPriceCalculated = currentPrice
            ? Object.entries(currentPrice).reduce((acc, [key, value]) => {
                  acc[key] = value ? value * SET_OF_FOUR_MULTIPLAYER : null
                  return acc
              }, {} as PriceInterface)
            : {}

        const setOfPrice = !isStaggered ? totalPriceCalculated : totalCurrentPrice

        return (
            <>
                {canShowFrom(setOfPrice) && !isFrenchLabel && splitedUpdatedFromLabel}
                <RangePriceOrFromPrice
                    price={setOfPrice}
                    language={language}
                    isRangeView={!isStaggered && isRangeView}
                    fromLabel={updatedFromLabel}
                    toLabel={updatedToLabel}
                    showToNow={showToNow}
                />
                {canShowFrom(setOfPrice) && isFrenchLabel && splitedUpdatedFromLabel}
            </>
        )
    }

    /**
     * Function to display from label in pcode PDP
     * @param {string} pcodeFromLabel
     * @returns {JSX.Element}
     */
    const renderFromLabelForPcodePDP = (pcodeFromLabel: string, pcodeToLabel?: string): JSX.Element => {
        const isFrench = language.toUpperCase() === multiLanguageLabel.frenchLangLabel
        const splitFromIndex = Number(pcodeFromLabel?.includes('[0]') && isFrench)
        const splitToIndex = Number(pcodeToLabel?.includes('[0]') && isFrench)
        const pcodeFromLabelElement = (
            <span className={`${PREFIX}-price__set-of-four__from-label-${isFrench ? 'french' : 'english'}`}>
                {pcodeFromLabel?.split('[0]')[splitFromIndex]}
            </span>
        )
        const pcodeToLabelElement = (
            <span className={`${PREFIX}-price__set-of-four__from-label-${isFrench ? 'french' : 'english'}`}>
                {pcodeToLabel?.split('[0]')[splitToIndex]}
            </span>
        )

        return (
            <span className={`${PREFIX}-price__price-range`}>
                {canShowFrom(currentPrice, originalPrice, totalPrice, priceValue) && !isFrench && pcodeFromLabelElement}
                <TotalRangePriceOrFromPrice
                    {...{
                        isMoreQuantityCartPage,
                        priceValue,
                        currentPrice,
                        originalPrice,
                        language,
                        showDiscountedPrice,
                        totalPrice,
                        isAppliedPromoExist,
                        fromLabel,
                        pcodefromLabel: pcodeFromLabelElement,
                        toLabel: pcodeToLabelElement,
                        showToNow,
                        isRangeView,
                    }}
                />
                {canShowFrom(currentPrice, originalPrice, totalPrice, priceValue) && isFrench && pcodeFromLabelElement}
            </span>
        )
    }

    /**
     * To display current price with each label
     *
     * @returns { JSX.Element }
     */
    const currentPriceWithEachLabel = () => {
        return (
            <div className={`${PREFIX}-price__set-of-four__total-price`}>
                <span
                    className={cx(`${PREFIX}-price__set-of-four__current-price`, {
                        [definePriceStyle()]: isOnSaleOrClearance,
                    })}>
                    {isProductLevelPDP ? (
                        renderFromLabelForPcodePDP(fromLabel, toLabel)
                    ) : (
                        <TotalRangePriceOrFromPrice
                            {...{
                                isMoreQuantityCartPage,
                                priceValue,
                                currentPrice,
                                originalPrice,
                                language,
                                showDiscountedPrice,
                                totalPrice,
                                isAppliedPromoExist,
                                fromLabel,
                                toLabel,
                                showToNow,
                                isRangeView,
                            }}
                        />
                    )}
                    {!isProductLevelPDP && (
                        <span className={` ${PREFIX}-price__set-of-four__each-label`}>
                            {isWheelOrTire && !isMoreQuantityCartPage && ` ${eachLabel}`}
                        </span>
                    )}
                </span>
            </div>
        )
    }

    /**
     * To display set of 4 price
     *
     * @returns { JSX.Element } - returns total price
     */
    const setOfFourTire = (): JSX.Element => {
        const [updatedFromLabel, updatedToLabel] =
            totalCurrentPrice?.minPrice && !totalCurrentPrice?.value ? [fromLabel, toLabel] : ['', '']

        return (
            // eslint-disable-next-line sonarjs/no-nested-template-literals
            <div className={cx(`${PREFIX}-price__set-of-four`, { [`${PREFIX}-price__staggered-text`]: isStaggered })}>
                {!isStaggered && currentPriceWithEachLabel()}
                <div
                    className={cx(
                        `${PREFIX}-price__set-of-four__text`,

                        {
                            [`${PREFIX}-price__set-of-four__updateLable`]: updatedFromLabel?.length > 0,
                            [`${PREFIX}-price__set-of-four__no-margin-text`]: isStaggered,
                        },
                    )}>
                    {isWheelOrTire && !showFromNow ? setOfFourLabel : displaySetOfLabel()}{' '}
                    {renderSetOfFromPrice(updatedFromLabel, updatedToLabel)}
                </div>
            </div>
        )
    }

    /**
     * To display renderPriceMessage
     *
     * @return { JSX.Element } - returns renderPriceMessage
     */

    const renderPriceMessage = (): JSX.Element => {
        if (!priceMessage?.[0]?.label) {
            return <div className={`${PREFIX}-price__without-message`} data-testid="priceTotal"></div>
        }

        return (
            <div className={`${PREFIX}-price__message`} data-testid="priceTotal">
                <Icon type="ct-warning" size={isMobile ? 'sm' : 'lg'}></Icon>
                <p className={`${PREFIX}-price__message-text`} role="alert" aria-atomic="true">
                    {priceMessage?.[0]?.label}
                </p>
            </div>
        )
    }

    /**
     * is promo gives additional discount for 1 item (qty = 1)
     *
     * @return { boolean }
     */

    const isPromoGivesAdditionalDiscountOnItem = (): boolean => {
        return (
            !isMoreQuantityCartPage &&
            isAppliedPromoExist &&
            currentPrice?.value !== totalPrice?.value &&
            currentPrice?.value !== originalPrice?.value
        )
    }

    /**
     * is currentPrice shown on the page
     *
     * @return { boolean }
     */

    const isCurrentPriceShown = (): boolean => {
        return isMoreQuantityCartPage || isPromoGivesAdditionalDiscountOnItem()
    }

    /**
     * function to render save story
     * @return {JSX.Element[]}
     */

    const renderSaveStory = (): JSX.Element | JSX.Element[] => {
        const addCls = overridePriceHeight ? `${PREFIX}-price__save-story--min-height` : ''
        const onSaleCls = isOnSale ? `${PREFIX}-price__save-story-label--bold` : ''
        return !hideSaveLabel && isArrayNotEmpty(priceMessage) && !isStaggered
            ? priceMessage?.map((item: PriceMessage, index: number) => {
                  return item.label ? (
                      <div key={`${item.label}-${index}`} className={`${PREFIX}-price__save-story ${addCls}`}>
                          {showEachTextForSaveLabel ? (
                              <div className={`${PREFIX}-price__save-story-label ${onSaleCls}`}>{item.label}</div>
                          ) : null}
                          {item.tooltip && (
                              <button
                                  ref={saveTooltipButtonRef}
                                  className={`${PREFIX}-price__eco-fee-tooltip-btn`}
                                  onClick={event =>
                                      toolTipButtonHandler(event, setSaveTooltipVisibility, saveTooltipVisibility)
                                  }
                                  onKeyDown={event =>
                                      toolTipButtonKeyboardHandler(
                                          event,
                                          setSaveTooltipVisibility,
                                          saveTooltipVisibility,
                                      )
                                  }
                                  data-testid="eco-fee"
                                  aria-label={a11yTooltipIcon}>
                                  <span
                                      className={
                                          saveTooltipVisibility ? `${PREFIX}-price__eco-fee-tooltip-btn--active` : ''
                                      }>
                                      <Icon type="ct-information" size="sm" />
                                  </span>
                              </button>
                          )}
                          {!!saveTooltipVisibility && (
                              <Tooltip
                                  visibility={saveTooltipVisibility}
                                  setVisibility={setSaveTooltipVisibility}
                                  iconID="ct-close"
                                  headerText={item.label}
                                  bodyText={item.tooltip}
                                  coords={saveTooltipButtonRef.current}
                                  a11yCloseIconLabel={a11yCloseIconLabel}
                                  isModalPresent={isModalPresent}
                              />
                          )}
                      </div>
                  ) : null
              })
            : null
    }

    /**
     * function to render save message
     * @return {JSX.Element[]}
     */

    const renderSaveMessage = (): JSX.Element | JSX.Element[] => {
        const addCls = overridePriceHeight ? `${PREFIX}-price__save-story--min-height` : ''

        return !hideSaveLabel && !!saveMessage ? (
            <div key={`${saveMessage}`} className={`${PREFIX}-price__save-story ${addCls}`}>
                <div className={`${PREFIX}-price__save-story-label`}>{saveMessage}</div>
            </div>
        ) : null
    }

    /**
     * Function to render product pricing
     * @returns {JSX.Element}
     */
    const renderProductPricing = (): JSX.Element => {
        return (
            // eslint-disable-next-line sonar/expression-complexity
            !isStaggeredFront &&
            (isWheelOrTirePDP || isWheelOrTire
                ? setOfFourTire()
                : // eslint-disable-next-line sonar/no-nested-conditional
                cartPageOrMoreQuantity
                ? totalPriceCartComponent()
                : totalPriceComponent())
        )
    }

    /**
     * To display renderPrice
     *
     * @returns { JSX.Element } - returns renderPrice
     */
    const renderPrice = (): JSX.Element => {
        const priceMargingClass = !isRebate ? `${PREFIX}-price__margin` : ''
        const priceContainerClass = isGenmerchPDP ? `${PREFIX}-price__container ${PREFIX}-row` : ''
        return (
            <div
                className={`${priceMargingClass} ${PREFIX}-price ${
                    overridePriceHeight ? `${PREFIX}-price--min-height` : ''
                } ${priceContainerClass}`}
                id={accessibilityPriceId}>
                {renderProductPricing()}
                {!isStaggered && feesComponent()}
                {isCurrentPriceShown() && renderCurrentPrice()}
                {wasPriceComponent()}
                {renderSaveMessage()}
                {renderSaveStory()}
                {isStaggered && feesComponent()}
            </div>
        )
    }

    return checkInstanceOfPrice(originalPrice, language) === '' && checkInstanceOfPrice(currentPrice, language) === ''
        ? renderPriceMessage()
        : renderPrice()
}

Price.defaultProps = {
    showEachTextForSaveLabel: true,
    hideSaveLabel: false,
}

Price.displayName = 'Price'

Price.propTypes = {
    priceStyle: PropTypes.string,
    promotionalPriceLabel: PropTypes.string,
    thresholdValue: PropTypes.number,
    unitPriceLabel: PropTypes.string,
    clearancePriceLabel: PropTypes.string,
    a11yPriceRangeFrom: PropTypes.string,
    a11yPriceRangeTo: PropTypes.string,
    hideSaveLabel: PropTypes.bool,
    a11yStrikeOutPrice: PropTypes.string,
    a11yStrikeOutPriceRange: PropTypes.string,
    a11yTooltipIcon: PropTypes.string,
    a11yCloseIconLabel: PropTypes.string,
    feeTitle: PropTypes.string,
    feeDisclaimerMessage: PropTypes.string,
    feeDisclaimerTitle: PropTypes.string,
    originalPrice: PropTypes.any,
    currentPrice: PropTypes.any,
    priceValue: PropTypes.any,
    displayWasLabel: PropTypes.bool,
    language: PropTypes.string,
    eachLabel: PropTypes.string,
    isMoreQuantityCartPage: PropTypes.bool,
    priceMessage: PropTypes.any,
    isAutomotiveEachLabel: PropTypes.bool,
    a11yRecycleFeesTriangleIcon: PropTypes.string,
    nowFromLabel: PropTypes.string,
    wasFromLabel: PropTypes.string,
    fromLabel: PropTypes.string,
    feeDisclaimerType: PropTypes.string,
    isWheelOrTirePDP: PropTypes.bool,
    totalCurrentPrice: PropTypes.any,
    totalOriginalPrice: PropTypes.any,
    setOfFourLabel: PropTypes.string,
    setOfFourNowLabel: PropTypes.string,
    isWheelOrTire: PropTypes.bool,
    isStaggered: PropTypes.bool,
    isStaggeredFront: PropTypes.bool,
    showFromNow: PropTypes.bool,
    isRebate: PropTypes.bool,
    isShoppingCartPage: PropTypes.bool,
    showEachTextForSaveLabel: PropTypes.bool,
    overridePriceHeight: PropTypes.bool,
    isAppliedPromoExist: PropTypes.bool,
    enablePdpPricingEnhancement: PropTypes.bool,
    accessibilityPriceId: PropTypes.string,
}

export default Price
