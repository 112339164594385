import React, { useState, useRef, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'

import { StoreSelectorModal, isArrayNotEmpty, MapPosition, libUtils, isAutoServiceAvailableInStore } from '@nl/lib'
import { RootState } from '../../redux/reducers'
import { ADDRESS_MAXLENGTH, StoreChangeSource } from '../../globalConstants/global.constant'
import storeLocatorAnalytics from '../../analytics/components/storeLocatorAnalytics'
import {
    analyticsInteraction,
    fetchNearbyStoreList,
    setSelectedPreferredStoreId,
    setSelectedStoreName,
    resetEstimateFees,
    fetchPreferredStoreDetails,
} from '../../redux/actions'
import { NearByOptions, storeListRequestType, StoreWithAvailability } from '../../redux/models/storeDetails.interface'
import appCacheService from '../../utils/appCacheService'
import { googleMapsProps, markerProps, minimumStore } from '../StoreDetails/StoreDetails.constant'
import { checkDataLength } from '../Accounts/Addresses/checkDataLength'
import { Position } from '../Header/Header.type'
import { navigatorService } from '../../utils/navigatorService'
import getPageType from '../../utils/getPageType'
import { fetchNextSetOfStores } from '../BuyBox/BuyBox.constant'
import { analyticsAttributes } from '../../globalConstants'
import { StoreSelectorModalProps } from './StoreSelectorModalComp.type'
import {
    setPreferredStoreDetailsAction,
    setStoreAPITriangulationFailAction,
    storeLatLongDetails,
} from '../../redux/actionCreators'
import AutoServiceButton from '../AutoServiceButton'
import { MagicNumber } from '../../analytics/analytics.type'
import { getCriticalFitmentProducts } from '../AutomotivePDP/AutomotiveBuyBox/AutomotiveBuyBox.helper'
import { fetchSingleProductSku, isCartPage } from '../ShoppingCart/CartItem.util'
import { pageTypes } from '../../config'
import { commonContentAvailableSelector } from '../../redux/selectors/commonContent.selectors'
import { userProfileVehicleSelector } from '../../redux/selectors/userProfile.selectors'
import { productSelector } from '../../redux/selectors/product.selectors'
// eslint-disable-next-line max-len
import { StoreSelectorModalProps as LibStoreSelectorModalProps } from '@nl/lib/src/components/StoreSelectorModal/StoreSelectorModal.type'

import {
    selectedPreferredStoreIdSelector,
    nearbyStoreListSelector,
    preferredStoreDetailsSelector,
    storeAPITriangulationFailSelector,
    nearByStoreListForSelectedSkuSelector,
    geoPointSelector,
} from '../../redux/selectors/storeDetails.selectors'
import { IAccessibility, IBreadcrumb, IProduct, IStoreLocator } from '../../redux/models/commonContent.interface'
import { triggerBrazeCustomEvent } from '../Header/HeaderWrapper/HeaderWrapper.helper'
import { miniPDPFlyoutDetailsSelector } from '../../redux/selectors/miniPDPFlyoutData.selectors'
import { ProductDataDetails } from '../../redux/models/miniPDPFlyout.interface'
import { enableDestructOnUndefinedData } from '../../utils/PDP/enableDestructOnUndefinedData.utils'
import { useAppDispatch, useAppSelector } from '../../hooks/react-redux.hook'

/**
 * Order Summary fulfillment section for cart/checkout/wishlist summary
 * @returns {JSX.Element} StoreSelectorModalComp
 */
const StoreSelectorModalComp: React.FC<StoreSelectorModalProps> = ({ ...props }): JSX.Element => {
    const {
        maxNumberOfStores,
        closeFlyout,
        isShowAvailability,
        isSingleItemCartPage,
        isMultipleItemsCartPage,
        isHeaderStoreLocator,
        viewMoreAnalytics,
        forcePreferredStoreMsg,
        isShowEmptyStoresToggle,
        selectedProductCodeWishList,
        isOnlineOnlyItem,
        inStockDCOnlyEnabled,
        isOpen,
        allItemsInStockMsg,
        isInMiniPDPFlyout,
    } = props
    const storeList = useAppSelector(nearbyStoreListSelector)
    const storeAPITriangulationFail = useAppSelector(storeAPITriangulationFailSelector)
    const selectedPreferredStoreId = useAppSelector(selectedPreferredStoreIdSelector)
    const nearByStoreListForSelectedSku = useAppSelector(nearByStoreListForSelectedSkuSelector)
    const storeErrorResponse = useAppSelector((state: RootState) => state.storeDetails.StoreErrorResponse)
    const { productDataList, currentCode } = useAppSelector(miniPDPFlyoutDetailsSelector)
    const { variantsInfo } = enableDestructOnUndefinedData<ProductDataDetails>(productDataList?.[currentCode])
    const generalProductInfo = useAppSelector(productSelector)
    const { productSkuData, selectedProductCode } = isInMiniPDPFlyout ? variantsInfo : generalProductInfo
    const vehicle = useAppSelector(userProfileVehicleSelector)
    const {
        accessibility = {} as IAccessibility,
        storeLocator = {} as IStoreLocator,
        breadcrumb = {} as IBreadcrumb,
        product: commonContentProduct = {} as IProduct,
    } = useAppSelector(commonContentAvailableSelector)
    const miniCartItemsData = useAppSelector((state: RootState) => state.cart.miniCartItemsData)
    const { status: isWindowGoogleLoaded } = useAppSelector((state: RootState) => state.docLoadStatus)
    const { criticalFitment } = vehicle || {}
    const dispatch = useAppDispatch()
    const [hasMoreStores, setHasMoreStores] = useState(false)
    const [currentPage, setCurrentPage] = useState(0)
    const {
        maxRadiusToSearchStore,
        googleMapsMarkersColor,
        maxNumberOfStores: maxStoreNos,
        maxNumberOfStoresHeader,
        seeMoreCTAURL,
        seeMoreCTALabel,
        showInStockFilterLabel,
        a11yMapViewAriaLabel,
        a11yListViewAriaLabel,
        enableInStockOnlineInFlyout,
        a11yStoresAvailableLabel,
        noResultForCartInStoreInventoryTitle,
        noResultForCartInStoreInventoryDescription,
        noResultForCartTitle,
        noResultForCartDescription,
    } = storeLocator

    const fitmentProduct = getCriticalFitmentProducts(criticalFitment)
    const preferredStoreDetails = useAppSelector(preferredStoreDetailsSelector)

    const {
        fullCorporateAssortmentAvailableForOnlinePurchaseLabel: inStockOnlineLabel,
        onlineAssortmentAvailableForOnlinePurchaseLabel: inStockOnlineOnlyLabel,
    } = commonContentProduct

    /**
     * function to check if product data has SKU for PDP or cart page has one order entry
     * then refer to nearByStoreListForSelectedSku redux state
     * @returns {boolean}
     */
    const checkSkuForNearbyStore = (): boolean => {
        return (
            // eslint-disable-next-line sonar/expression-complexity
            (isArrayNotEmpty(productSkuData?.skus) && !props.isProductLevel) ||
            !!isSingleItemCartPage ||
            !!isMultipleItemsCartPage ||
            !!selectedProductCodeWishList
        )
    }

    /**
     * Function to check if no preferred store in localstorage and stores api fails with error code "00114"
     * @returns {boolean}
     */
    const checkIfStoresApiFails = (): boolean => {
        return !preferredStoreDetails?.id && !!storeAPITriangulationFail
    }
    const isStoresApiFailed = checkIfStoresApiFails()

    const nearbyStoreList =
        checkSkuForNearbyStore() && !isHeaderStoreLocator ? nearByStoreListForSelectedSku : storeList
    const [selectedStore, setSelectedStore] = useState(
        nearbyStoreList?.length > 0 ? nearbyStoreList[0] : ({} as StoreWithAvailability),
    )

    const [preferredStoreId, setPreferredStoreId] = useState(
        Number(appCacheService.preferredStoreId.get()) || selectedStore.id,
    )
    const { markerTypes, activeMarkerLabelColor } = markerProps
    const { defaultMarker, preferredMarker, preferredSelectedMarker, numericMarker } = markerTypes
    const [markerClickHandler, setMarkerClickHandler] = useState({} as MapPosition)
    const { defaultMapId, mapZoomValue, defaultStoreLocations, preferredMarkerVal } = googleMapsProps
    const [mapZoom, setMapZoom] = useState(mapZoomValue)
    const language = libUtils.getLanguage()
    const [newMapProps, setNewMapProps] = useState({
        mapId: defaultMapId,
        locations: defaultStoreLocations as MapPosition[],
        setMapZoom,
        mapZoom,
        windowRef: window,
        selectedStoreName: markerClickHandler.name,
        isWindowGoogleLoaded,
    })
    const maxStoresToDisplay = maxNumberOfStores ? maxNumberOfStores : (maxStoreNos as unknown as number)

    const {
        event: { interaction },
        eventParameters: {
            action: { viewStoreList, searchStore, viewStoreMap },
            location: { buyBoxLocation, miniPDP: analyticMiniPDPLocation },
            labels: { userInput, geolocation },
            category: { storeView, store: storeName },
            selection: { userSelected },
        },
    } = analyticsAttributes

    const filteredStores = useRef([] as StoreWithAvailability[])
    const filteredAllStores = useRef([] as StoreWithAvailability[])
    const pageType = getPageType()

    const { setPreferredStoreAnalytics } = storeLocatorAnalytics

    /**
     * function to pass data to analytics function when user clicks search icon
     */
    const searchStoreAnalytics = (): void => {
        dispatch(
            analyticsInteraction(
                userInput,
                ' ',
                interaction,
                searchStore,
                storeName,
                getStoreSelectorAnalyticsLocation(),
            ),
        )
    }

    /**
     * Function to send analytics when store locator view changes
     * @param {boolean} isMapView - selected store view
     */
    const switchStoreViewAnalytics = (isMapView: boolean): void => {
        const onSwitch = isMapView ? viewStoreList : viewStoreMap
        const labelName = `${onSwitch}`
        dispatch(analyticsInteraction('', storeView, interaction, labelName))
    }

    /**
     * returns the filtered stores list
     * @param {StoreWithAvailability} preferredStore near by preferred store
     * @return {StoreWithAvailability[]} returns the filtered stores list
     */
    const getFilteredStoreList = useCallback(
        (preferredStore: StoreWithAvailability): StoreWithAvailability[] => {
            const stores = checkDataLength(preferredStore) ? [preferredStore, ...nearbyStoreList] : nearbyStoreList
            return stores.reduce((uniqueStore: StoreWithAvailability[], store: StoreWithAvailability) => {
                if (!uniqueStore.some((storeObj: StoreWithAvailability) => storeObj?.id === store.id)) {
                    uniqueStore.push(store)
                }
                return uniqueStore
            }, [])
        },
        [nearbyStoreList],
    )

    const [filteredStoreList, setFilteredStoreList] = useState(filteredStores.current)

    /**
     * Function to get preferred store
     * @return {StoreWithAvailability|undefined} returns the preferred store
     */
    const getPreferredStore = useCallback((): StoreWithAvailability | undefined => {
        return nearbyStoreList?.find((item: StoreWithAvailability) => item?.id === preferredStoreId)
    }, [nearbyStoreList, preferredStoreId])

    /**
     * useEffect to set filtered store list
     */
    useEffect(() => {
        if (isArrayNotEmpty(nearbyStoreList)) {
            const preferredStore = getPreferredStore()
            filteredAllStores.current = getFilteredStoreList(preferredStore as StoreWithAvailability)
            filteredStores.current = filteredAllStores.current.slice(0, maxStoresToDisplay)
            filteredStores.current.length > 0 && setFilteredStoreList(filteredStores.current)
        } else {
            filteredStores.current = []
            setFilteredStoreList(filteredStores.current)
        }
    }, [getFilteredStoreList, nearbyStoreList, preferredStoreId, maxStoresToDisplay, getPreferredStore])

    const renderAutoAppointmentButton = (storeId: number): JSX.Element | null => {
        const isPreferredStore = storeId === preferredStoreDetails.id
        const isAOAFlow = pageType === pageTypes.aoaPage
        if (isPreferredStore && isAutoServiceAvailableInStore(preferredStoreDetails) && !isAOAFlow) {
            return <AutoServiceButton />
        }
        return null
    }

    /**
     * useEffect to set prefered store id
     */
    useEffect(() => {
        selectedPreferredStoreId && setPreferredStoreId(Number(selectedPreferredStoreId))
    }, [selectedPreferredStoreId])

    /**
     * function to get store change source
     * @returns {string}
     */
    const getStoreSelectorAnalyticsLocation = (): string => {
        if (isHeaderStoreLocator) {
            return StoreChangeSource.header
        } else if (pageType === pageTypes.pdpPage) {
            return StoreChangeSource.pdp
            // eslint-disable-next-line sonarjs/elseif-without-else
        } else if (pageType === pageTypes.cart) {
            return StoreChangeSource.cart
        }
        return StoreChangeSource.other
    }

    const storeChangeLocation = getStoreSelectorAnalyticsLocation()

    /**
     * sets prefered store
     * @param {StoreWithAvailability} store near by store
     * @param {boolean} isClosed flag to check if store closed or not
     */

    const setPreferredStore = useCallback(
        (store: StoreWithAvailability, isClosed = false): void => {
            setSelectedStore(store)
            dispatch(resetEstimateFees())
            dispatch(setSelectedPreferredStoreId(String(store.id)))
            dispatch(setSelectedStoreName(store.displayName))
            dispatch(setPreferredStoreDetailsAction(store))
            dispatch(fetchPreferredStoreDetails(store.id.toString(), undefined, undefined, storeChangeLocation))
            dispatch(setStoreAPITriangulationFailAction(false))
            const filteredStoresData = getFilteredStoreList(store)
            setFilteredStoreList(filteredStoresData)
            // OCCP-21600
            // if (isLoggedIn) {
            //     const payload = {
            //         ecommerce: {
            //             CTR: {
            //                 preferredStore: String(store.id),
            //             },
            //         },
            //     }
            //     dispatch(updateProfile(payload))
            // }
            setPreferredStoreAnalytics(store, isClosed, buyBoxLocation, userSelected)
            // Trigger braze custom event on change of preferred store
            triggerBrazeCustomEvent(store)
            closeFlyout?.()
        },
        [
            dispatch,
            getFilteredStoreList,
            buyBoxLocation,
            setPreferredStoreAnalytics,
            userSelected,
            closeFlyout,
            storeChangeLocation,
        ],
    )

    /**
     * Callback function to set Marker for each store
     * @param {string} name - store name
     * @param {string} marker - preferred or blank
     * @param {number} storesLength
     * @param {boolean} isSelected - store selection
     * @return {string} returns store marker
     */

    const setMarker = useCallback(
        (id: string | number, marker: string, storesLength: number, isSelected: boolean): string => {
            let storeMarker = numericMarker(googleMapsMarkersColor) || ''

            if (marker === preferredMarkerVal) {
                storeMarker =
                    markerClickHandler.id === id && isSelected
                        ? preferredSelectedMarker(googleMapsMarkersColor)
                        : preferredMarker(googleMapsMarkersColor)
            } else if (markerClickHandler.id === id && isSelected) {
                storeMarker = numericMarker(googleMapsMarkersColor)
                // eslint-disable-next-line sonarjs/elseif-without-else
            } else if (storesLength > minimumStore) {
                storeMarker = defaultMarker(googleMapsMarkersColor)
            }

            return storeMarker
        },
        [
            preferredMarker,
            preferredSelectedMarker,
            defaultMarker,
            markerClickHandler,
            numericMarker,
            preferredMarkerVal,
            googleMapsMarkersColor,
        ],
    )

    /**
     * function to return marker color
     * @param {StoreWithAvailability} store
     * @return {string} returns store marker color
     */
    const setStoreMarkerColor = useCallback(
        (store: StoreWithAvailability): string => {
            return markerClickHandler.id === store.id && markerClickHandler.isSelected
                ? activeMarkerLabelColor
                : googleMapsMarkersColor
        },
        [markerClickHandler, activeMarkerLabelColor, googleMapsMarkersColor],
    )

    /**
     * useEffect to set map marker icon data
     */
    useEffect(() => {
        const maxStores = [] as MapPosition[]
        let storeMarkerIndex = MagicNumber.ONE
        nearbyStoreList.forEach((store: StoreWithAvailability, index: number) => {
            const { latitude, longitude } = store?.geoPoint || {}
            const { id, name } = store
            const marker = setMarker(
                id,
                preferredStoreId && preferredStoreId === id ? preferredMarkerVal : '',
                filteredStoreList.length,
                Boolean(markerClickHandler.isSelected),
            )

            maxStores.push({
                id: id as number,
                name,
                latitude,
                longitude,
                storeMarker: marker,
                storeMarkerLabel: id === preferredStoreId ? ' ' : storeMarkerIndex,
                markerColor: setStoreMarkerColor(store),
                selectedIndex: index,
            } as MapPosition)
            // eslint-disable-next-line sonar/no-nested-incdec
            preferredStoreId !== id && storeMarkerIndex++
        })
        const updatedMapProps = {
            mapId: defaultMapId,
            locations: maxStores,
            setMapZoom,
            mapZoom,
            windowRef: window,
            selectedStoreName: markerClickHandler.name,
            isWindowGoogleLoaded,
        }
        setNewMapProps(updatedMapProps)
    }, [
        nearbyStoreList,
        defaultMapId,
        mapZoomValue,
        preferredMarker,
        preferredStoreId,
        activeMarkerLabelColor,
        googleMapsMarkersColor,
        preferredMarkerVal,
        preferredSelectedMarker,
        setMarker,
        filteredStoreList.length,
        markerClickHandler.id,
        markerClickHandler.name,
        markerClickHandler.isSelected,
        mapZoom,
        setStoreMarkerColor,
        isWindowGoogleLoaded,
    ])

    const requestPayload = {
        maxCount: isHeaderStoreLocator ? maxNumberOfStoresHeader : maxStoresToDisplay,
    } as storeListRequestType

    /**
     * Callback for the google autocomplete.
     * Will trigger when someone clicks on the search icon or inputs enter.
     * @param {number} latitude
     * @param {number} longitude
     */
    const fetchNearbyStoreListOnSearch = (latitude: number, longitude: number): void => {
        const radius = Number(maxRadiusToSearchStore) || 0
        const skuData =
            isMultipleItemsCartPage ||
            (isArrayNotEmpty(productSkuData?.skus) && productSkuData?.skus?.[MagicNumber.ZERO].code) ||
            selectedProductCodeWishList

        !!skuData && !isHeaderStoreLocator
            ? dispatch(
                  fetchNearbyStoreList(
                      {
                          ...requestPayload,
                          radius,
                          latitude,
                          longitude,
                          sku: skuData,
                          showNotInStock: shouldRenderEmptyStoresToggle(),
                      } as storeListRequestType,
                      NearByOptions.SKU,
                  ),
              )
            : dispatch(
                  fetchNearbyStoreList({
                      ...requestPayload,
                      radius,
                      latitude,
                      longitude,
                  } as storeListRequestType),
              )
    }

    /**
     * function called on click of allow location
     * @param {Position} position
     */
    const getCurrentLocation = (position: Position): void => {
        const { latitude, longitude } = position.coords
        const skuData =
            isMultipleItemsCartPage || productSkuData?.skus?.[MagicNumber.ZERO].code || selectedProductCodeWishList
        const newRequestPayload = { ...requestPayload, latitude, longitude, sku: skuData }
        const searchRequestPayload = {
            maxCount: isHeaderStoreLocator ? maxNumberOfStoresHeader - MagicNumber.ONE : maxStoresToDisplay,
        } as storeListRequestType
        const headerRequestPayload = { ...searchRequestPayload, latitude, longitude }
        dispatch(
            storeLatLongDetails({
                latitude,
                longitude,
            }),
        )
        !isHeaderStoreLocator
            ? dispatch(fetchNearbyStoreList(newRequestPayload as storeListRequestType, NearByOptions.SKU))
            : dispatch(fetchNearbyStoreList(headerRequestPayload))
    }
    const geoPoint = useAppSelector(geoPointSelector)

    /**
     * function to fetch near by store when user click on check near by stores in the flyout
     */
    const fetchNearbyStoresWithAvailability = (): void => {
        const radius = Number(maxRadiusToSearchStore) || 0
        dispatch(
            fetchNearbyStoreList(
                {
                    sku: isMultipleItemsCartPage,
                    maxCount: maxNumberOfStores,
                    radius: radius,
                    latitude: geoPoint.latitude,
                    longitude: geoPoint.longitude,
                    showNotInStock: true,
                } as storeListRequestType,
                NearByOptions.SKU,
            ),
        )
    }

    /**
     * function called on click of block location or closing popup without any button click
     * @param {Record<string, unknown>} error
     */
    const disallowLocation = (error?: Record<string, unknown>): void => {
        // eslint-disable-next-line no-warning-comments
        // TODO: Create a toast message alerting customers about location settings in browser.
        console.error('Browser geolocation error !\n\nPosition unavailable', error?.message as string)
    }
    /**
     * function to set preferred store on location icon clicked
     */
    const setPreferredStoreOnMapIconClicked = (): void => {
        navigatorService.getCurrentPosition(getCurrentLocation, disallowLocation)
        dispatch(
            analyticsInteraction(
                geolocation,
                ' ',
                interaction,
                searchStore,
                storeName,
                getStoreSelectorAnalyticsLocation(),
            ),
        )
    }

    /**
     * function to get sku from order entry for cart page and selected product code for PDP
     */
    // eslint-disable-next-line sonar/function-return-type
    const getSkuForStoreLocator = useCallback(() => {
        return (
            !isHeaderStoreLocator &&
            (isCartPage()
                ? // eslint-disable-next-line sonar/no-nested-conditional
                  isSingleItemCartPage
                    ? isSingleItemCartPage
                    : fetchSingleProductSku(miniCartItemsData)
                : selectedProductCode)
        )
    }, [isSingleItemCartPage, miniCartItemsData, selectedProductCode, isHeaderStoreLocator])

    /**
     * useEffect is used to set next set of store result
     */
    useEffect(() => {
        if (isArrayNotEmpty(nearbyStoreList) && hasMoreStores) {
            const results = nearbyStoreList.slice(0, currentPage + fetchNextSetOfStores)
            const preferredStore = getPreferredStore()
            const filteredStoresList = getFilteredStoreList(preferredStore as StoreWithAvailability)
            results && setFilteredStoreList(filteredStoresList)
            setHasMoreStores(false)
        }
    }, [
        nearbyStoreList,
        hasMoreStores,
        currentPage,
        setFilteredStoreList,
        getFilteredStoreList,
        preferredStoreId,
        getPreferredStore,
    ])

    /**
     * function to set flag should be rendered empty stores toggle and work the logic of the toggle
     * @returns {boolean}
     */
    const shouldRenderEmptyStoresToggle = (): boolean => {
        return !!isShowEmptyStoresToggle && !isHeaderStoreLocator
    }

    const getOnlineOnlyLabel = () => {
        return isOnlineOnlyItem ? inStockOnlineOnlyLabel : inStockOnlineLabel
    }

    return (
        <StoreSelectorModal
            inStockOnlineLabel={getOnlineOnlyLabel()}
            inStockDCOnlyEnabled={inStockDCOnlyEnabled}
            closeFlyout={closeFlyout}
            storeList={filteredStoreList}
            closeIconLabel={accessibility.a11yCloseIconLabel}
            setHasMoreStores={setHasMoreStores}
            setCurrentPage={setCurrentPage}
            {...storeLocator}
            searchStoreAnalyticsHandler={searchStoreAnalytics}
            fetchNearbyStoreListOnSearch={fetchNearbyStoreListOnSearch}
            addressMaxLength={ADDRESS_MAXLENGTH}
            renderAutoAppointmentButton={
                renderAutoAppointmentButton as LibStoreSelectorModalProps['renderAutoAppointmentButton']
            }
            allStores={nearbyStoreList}
            preferredStoreId={preferredStoreId as number}
            setPreferredStore={setPreferredStore as LibStoreSelectorModalProps['setPreferredStore']}
            setPreferredStoreOnMapIconClicked={setPreferredStoreOnMapIconClicked}
            language={language}
            newMapProps={newMapProps}
            setMarkerClickHandler={setMarkerClickHandler as LibStoreSelectorModalProps['setMarkerClickHandler']}
            markerClickHandler={markerClickHandler}
            onStoreViewChangesAnalytics={switchStoreViewAnalytics}
            indentPreferredStart={false}
            showClose={!isStoresApiFailed && !isInMiniPDPFlyout}
            backBtnLabel={breadcrumb.backLabel}
            isInMiniPDPFlyout={isInMiniPDPFlyout}
            analyticMiniPDPLocation={analyticMiniPDPLocation}
            isCheckout={true}
            pageType={pageType}
            isShowAvailability={isShowAvailability}
            sku={getSkuForStoreLocator() as string}
            isStaggeredProduct={fitmentProduct?.isStaggered}
            staggeredSkus={productSkuData?.skus}
            isHeaderStoreLocator={isHeaderStoreLocator}
            seeMoreCTAURL={seeMoreCTAURL}
            seeMoreCTALabel={seeMoreCTALabel}
            viewMoreAnalytics={viewMoreAnalytics}
            storeErrorResponse={storeErrorResponse}
            getStoreSelectorAnalyticsLocation={getStoreSelectorAnalyticsLocation}
            isStoresApiFailed={isStoresApiFailed}
            forcePreferredStoreMsg={forcePreferredStoreMsg}
            isShowEmptyStoresToggle={shouldRenderEmptyStoresToggle()}
            showInStockFilterLabel={showInStockFilterLabel}
            a11yTelephoneAriaLabel={accessibility?.a11yTelephoneAriaLabel}
            a11ySuggestionResultAvailable={accessibility?.a11ySuggestionResultAvailable}
            a11yMapLocateMeButtonLabel={accessibility?.a11yMapLocateMeButtonLabel}
            isOpen={isOpen}
            a11yMapViewAriaLabel={a11yMapViewAriaLabel}
            a11yListViewAriaLabel={a11yListViewAriaLabel}
            enableInStockOnlineInFlyout={enableInStockOnlineInFlyout}
            a11yStoresAvailableLabel={a11yStoresAvailableLabel}
            fetchNearbyStoresWithAvailability={fetchNearbyStoresWithAvailability}
            noResultForCartInStoreInventoryTitle={noResultForCartInStoreInventoryTitle}
            noResultForCartInStoreInventoryDescription={noResultForCartInStoreInventoryDescription}
            noResultForCartTitle={noResultForCartTitle}
            noResultForCartDescription={noResultForCartDescription}
            allItemsInStockMsg={allItemsInStockMsg}
        />
    )
}

StoreSelectorModalComp.propTypes = {
    maxNumberOfStores: PropTypes.number.isRequired,
    closeFlyout: PropTypes.func,
    isShowAvailability: PropTypes.bool,
    storeList: PropTypes.any,
    isSingleItemCartPage: PropTypes.string,
    isMultipleItemsCartPage: PropTypes.array,
    isProductLevel: PropTypes.bool,
    isOpen: PropTypes.bool,
}

StoreSelectorModalComp.displayName = 'StoreSelectorModalComp'

export default StoreSelectorModalComp
