import { types } from '../providers'
import { AnalyticsRecordInterface, ProductRecordInterface } from '../providers/provider.type'

/**
 * Global analytics class.
 * Used for all analytics related records
 */
export class AnalyticsRecord implements types.AnalyticsRecordInterface {
    event: string
    promotion?: types.PromotionRecord | types.PromotionRecord[]
    eventParameters?: Record<string, string>
    installationAvailable?: types.YesNoNotApplicable
    installationHelp?: types.YesNoNotApplicable
    vinEntered?: types.YesNoNotApplicable
    cart?: types.CartRecord
    checkout?: types.CheckoutRecord
    products?: types.ProductRecordInterface[]

    /**
     * @param  {string} eventName
     */
    constructor(eventName: string) {
        this.event = eventName
    }

    /**
     * Convert class object to plan JS object
     * @return {AnalyticsRecordInterface}
     */
    toPlainObject(): AnalyticsRecordInterface {
        const plainObject: AnalyticsRecordInterface = {
            event: this.event,
            checkout: this.checkout,
            installationAvailable: this.installationAvailable,
            installationHelp: this.installationHelp,
            vinEntered: this.vinEntered,
            // eslint-disable-next-line no-warning-comments
            // TODO: cart should be separate class.
            cart: {
                id: this.cart?.id,
                fulfillmentCost: this.cart?.fulfillmentCost,
                financingEligible: this.cart?.financingEligible,
                financingShown: this.cart?.financingShown,
                financingSelected: this.cart?.financingSelected,
                shippingCost: this.cart?.shippingCost,
            },
        }
        // Create product plain object
        plainObject.products = this.products?.map<ProductRecordInterface>((product: ProductRecordInterface) => {
            return product?.toPlainObject()
        })
        return plainObject
    }
}
