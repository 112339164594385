import { Dispatch } from 'redux'

import criteoService from '../../services/criteoService'
import {
    fetchSponsoredAdsErrorAction,
    fetchSponsoredAdsRequestAction,
    fetchSponsoredAdsSuccessAction,
    setSponsoredAdsCarouselProducts,
} from '../actionCreators/sponsoredAds.actionCreators'
import { CriteoEventType, CriteoPageId } from '../../services/criteoService/criteoService.constants'
import {
    CriteoUniversalParams,
    CriteoViewItemParams,
    SponsoredProduct,
} from '../../services/criteoService/criteo.interface'
import productDetailsService from '../../services/productDetailsService'
import { attachProductDetails, renamePlacementKeys } from '../../services/criteoService/criteoService.helper'

/**
 * Fetches sponsored ads using search keyword or category ID and dispatches appropriate actions.
 * @param {string} eventType - Criteo event type for the the Criteo request.
 * @param {string} pageId - Criteo page id for the the Criteo request.
 * @param {string} criteoBaseUrl - The base URL for the Criteo request.
 * @param {string} partnerId - The partner ID for Criteo.
 * @param {string} noLog - Flag to determine if logging should be disabled.
 * @param {string} retailerVisitorId - The retailer visitor ID.
 * @param {string} storeId - The store ID or region ID.
 * @param {boolean} isMobile - Indicates if the environment is mobile.
 * @param {number} pageNumber - The current page number of the pagination.
 * @param {string} identifier - Keywords for the search query or category ID.
 * @param {string} [customerId] - The optional customer ID.
 * @returns {Promise<void>}
 */
export const fetchSponsoredAds =
    (
        eventType: CriteoEventType,
        pageId: CriteoPageId,
        criteoBaseUrl: string,
        partnerId: string,
        noLog: string,
        retailerVisitorId: string,
        storeId: string,
        isMobile: boolean,
        pageNumber: number,
        identifier: string,
        customerId?: string,
        // eslint-disable-next-line max-params
    ) =>
    (dispatch: Dispatch): Promise<void> => {
        dispatch(fetchSponsoredAdsRequestAction())

        const fetchCriteoData =
            eventType === CriteoEventType.VIEW_SEARCH_RESULT
                ? criteoService.fetchCriteoData({
                      eventType,
                      pageId,
                      keywords: identifier,
                      criteoBaseUrl,
                      partnerId,
                      noLog,
                      retailerVisitorId,
                      storeId,
                      isMobile,
                      pageNumber,
                      customerId,
                  })
                : criteoService.fetchCriteoData({
                      eventType,
                      pageId,
                      category: identifier,
                      criteoBaseUrl,
                      partnerId,
                      noLog,
                      retailerVisitorId,
                      storeId,
                      isMobile,
                      pageNumber,
                      customerId,
                  })

        return fetchCriteoData
            .then(response => {
                const simplifiedResponse = renamePlacementKeys(response.data)

                // Trigger root level beacon
                if (simplifiedResponse.onLoadBeacon) {
                    navigator.sendBeacon(simplifiedResponse.onLoadBeacon)
                }

                dispatch(fetchSponsoredAdsSuccessAction(simplifiedResponse))
            })
            .catch(error => {
                // eslint-disable-next-line no-console
                console.warn(error)
                dispatch(fetchSponsoredAdsErrorAction())
            })
    }

/**
 * Fetches sponsored ads data for the Product Detail Page (PDP).
 * @param {CriteoUniversalParams} baseParams - The universal parameters for the Criteo API.
 * @param {string} baseParams.eventType - Criteo event type for the the Criteo request.
 * @param {string} baseParams.pageId - Criteo page id for the the Criteo request.
 * @param {string} baseParams.criteoBaseUrl - The base URL for the Criteo API.
 * @param {string} baseParams.partnerId - The partner ID for the Criteo API.
 * @param {string} baseParams.noLog - A parameter related to logging in Criteo.
 * @param {string} baseParams.retailerVisitorId - The retailer visitor ID for tracking.
 * @param {string} baseParams.storeId - The store ID associated with the Criteo setup.
 * @param {boolean} baseParams.isMobile - Indicates whether the environment is mobile.
 * @param {string} [baseParams.customerId] - Optional customer ID for personalized ads.
 * @param {CriteoViewItemParams} viewItemParams - The specific parameters for the view item event.
 * @param {string} viewItemParams.parentItem - The parent item ID or reference.
 * @param {string} viewItemParams.price - The price of the viewed item.
 * @param {boolean} viewItemParams.isAvailable - Indicates if the item is available.
 * @param {string} [viewItemParams.category] - Optional category information of the viewed item.
 * @returns {Promise<void>}
 */
export const fetchPDPSponsoredAds =
    (baseParams: CriteoUniversalParams, viewItemParams: CriteoViewItemParams) =>
    (dispatch: Dispatch): Promise<void> => {
        dispatch(fetchSponsoredAdsRequestAction())

        const { eventType, pageId, criteoBaseUrl, partnerId, noLog, retailerVisitorId, storeId, isMobile, customerId } =
            baseParams
        const { isAvailable, parentItem, price, category } = viewItemParams

        return criteoService
            .fetchCriteoData({
                eventType,
                pageId,
                criteoBaseUrl,
                partnerId,
                noLog,
                retailerVisitorId,
                storeId,
                isMobile,
                customerId,
                isAvailable,
                parentItem,
                price,
                category,
            })
            .then(response => {
                const simplifiedResponse = renamePlacementKeys(response.data)

                // Trigger root level beacon
                if (simplifiedResponse.onLoadBeacon) {
                    navigator.sendBeacon(simplifiedResponse.onLoadBeacon)
                }

                dispatch(fetchSponsoredAdsSuccessAction(simplifiedResponse))
            })
            .catch(error => {
                // eslint-disable-next-line no-console
                console.warn(error)
                dispatch(fetchSponsoredAdsErrorAction())
            })
    }

/**
 * Fetch additional details like badges, ratings and price for Sponsored Product cards
 * @param {SponsoredProduct[]} carouselData - data from criteo carousel placement
 * @param {string} storeId - The preferred store id
 * @returns {Promise<void>}
 */
export const fetchSponsoredCarouselDetails =
    (carouselData: SponsoredProduct[], storeId: string, onComplete?: () => void) =>
    (dispatch: Dispatch): Promise<void> => {
        const pCodes = carouselData.map(product => product.code)

        return (
            productDetailsService
                .getProductInfoUsingPCodesBasic(pCodes, storeId, false)
                .then(response => {
                    const productsWithDetails = attachProductDetails(carouselData, response.data.products)
                    dispatch(setSponsoredAdsCarouselProducts(productsWithDetails))
                })
                // eslint-disable-next-line no-console
                .catch(err => console.warn(err))
                .finally(() => {
                    onComplete?.()
                })
        )
    }
