import {
    FilteredCartData,
    eEntryType,
    CartOrderEntries,
    DeliveryStatusSTH,
    CartOrderDeliveryModes,
    DeliveryStatusBopis,
    DeliveryModesData,
    Package,
    CartOrderEntriesWithGroupingVal,
    IMinMaxEtaEntry,
    CartItemsData,
    DeliveryAddressDTO,
    CartOrderEntriesWithFilterVal,
} from '../redux/models/cart.interface'
import { Consignment, OrderEntry } from '../redux/models/orderConfirmation.interface'
import { StoreWithAvailability } from '../redux/models/storeDetails.interface'
import {
    areAllParamsValid,
    collectAllPackages,
    getBopisPackages,
    getSthPackages,
    isAtLeastOnePackageExists,
    isAtleastOneParamValid,
} from './getFilteredCartItems'
import { getStatusGrouping, getMinMaxEta } from './getStatusGrouping'

/**
 * @method getFilteredDeliveryModes: get the order entries filtered by delivery mode and grouped
 * @param cartData actual orders cart data from API to be filtered
 * @return {FilteredCartData} filtered grouped Object
 **/

export const displaySequenceSTH = [
    DeliveryStatusSTH.ORDER_RECEIVED,
    DeliveryStatusSTH.IN_PROGRESS,
    DeliveryStatusSTH.SHIPPED,
    DeliveryStatusSTH.CANCELLATION_PENDING,
    DeliveryStatusSTH.RETURN_REQUESTED,
    DeliveryStatusSTH.CANCELLED,
    DeliveryStatusSTH.COMPLETED,
    DeliveryStatusSTH.UNCONSIGNED,
    DeliveryStatusSTH.RETURNED,
]

export const displaySequenceBOPIS = [
    DeliveryStatusBopis.READY_FOR_PICK_UP,
    DeliveryStatusBopis.IN_PROGRESS,
    DeliveryStatusBopis.ORDER_RECEIVED,
    DeliveryStatusBopis.COMPLETED,
    DeliveryStatusBopis.CANCELLATION_PENDING,
    DeliveryStatusBopis.CANCELLED,
    DeliveryStatusBopis.UNCONSIGNED,
    DeliveryStatusBopis.RETURNED,
]

/**
 * function to filter only BOPIS product items
 * @param { CartOrderEntries[]} orderEntries - array of order entries from get order response
 * @return {CartOrderEntries[]}
 */
const getBopisEntries = (orderEntries: CartOrderEntries[]): CartOrderEntries[] => {
    return orderEntries.filter((item: CartOrderEntries) =>
        // OCCP-23389: SFS orders from old car does not send entryType. as a hot fix, treat all entries as PRODUCT.
        //             to allow easier removal of this code after full migration to ODP, a separate condition is constructed here
        isAtleastOneParamValid(
            areAllParamsValid(
                isAtleastOneParamValid(
                    item.fulfillment?.deliveryMode === CartOrderDeliveryModes.BOPIS,
                    item.fulfillment?.deliveryMode === CartOrderDeliveryModes.CURBSIDE,
                ),
                // eslint-disable-next-line sonar/different-types-comparison
                item.entryType === null,
            ),
            areAllParamsValid(
                isAtleastOneParamValid(
                    item.fulfillment?.deliveryMode === CartOrderDeliveryModes.BOPIS,
                    item.fulfillment?.deliveryMode === CartOrderDeliveryModes.CURBSIDE,
                ),
                item.entryType === eEntryType.PRODUCT,
                !item.cartEntryReference,
            ),
        ),
    )
}

/**
 * function to filter only STH product items
 * @param { CartOrderEntries[]} orderEntries - array of order entries from get order response
 * @return {CartOrderEntries[]}
 */
const getSthEntries = (orderEntries: CartOrderEntries[]): CartOrderEntries[] => {
    return orderEntries.filter((item: CartOrderEntries) =>
        // OCCP-23389: SFS orders from old car does not send entryType. as a hot fix, treat all entries as PRODUCT.
        //             to allow easier removal of this code after full migration to ODP, a separate condition is constructed here
        isAtleastOneParamValid(
            // eslint-disable-next-line sonar/different-types-comparison
            areAllParamsValid(item.fulfillment?.deliveryMode === CartOrderDeliveryModes.STH, item.entryType === null),
            areAllParamsValid(
                item.fulfillment?.deliveryMode === CartOrderDeliveryModes.STH ||
                    item.fulfillment?.deliveryMode === CartOrderDeliveryModes.EXPRESS,
                item.entryType === eEntryType.PRODUCT,
                !item.cartEntryReference,
            ),
        ),
    )
}

/**
 * function to push orderenries to service addon list
 * @param {CartOrderEntries[]} orderEntries
 * @return {CartOrderEntries[]}
 */
const getServiceAddonsList = (orderEntries: CartOrderEntries[]): CartOrderEntries[] => {
    const serviceAddonList: CartOrderEntries[] = []
    orderEntries?.forEach((orderEntry: CartOrderEntries) => {
        serviceAddonList.push(orderEntry)
    })
    return serviceAddonList
}

const getPackagesData = <T extends DeliveryModesData>(cartData: T) => {
    const isCartHasPackages = isAtLeastOnePackageExists(cartData)
    let bopisPackages: Package[] = []
    let sthPackages: Package[] = []

    if (isCartHasPackages) {
        const allPackages = collectAllPackages(cartData)
        bopisPackages = getBopisPackages(allPackages)
        sthPackages = getSthPackages(allPackages)
    }

    return { bopisPackages, sthPackages }
}

const getFilteredDeliveryModes = <T extends DeliveryModesData>(
    cartData: T,
    enableMergingOrderDetailsConsignmentAndUnconsignmentEntries?: boolean,
): FilteredCartData => {
    const orderEntries = cartData.orderEntries || cartData.updatedCart?.orderEntries || cartData.entries || []
    const cart = cartData
    const bopis = getBopisEntries(orderEntries)
    const sth = getSthEntries(orderEntries)
    const storeData = isAtleastOneParamValid(
        cartData.entries ? cartData.deliveryPointOfService : cartData.store,
        cartData.updatedCart?.store,
        [],
    ) as StoreWithAvailability
    const deliveryAddress = isAtleastOneParamValid(
        cartData.deliveryAddress,
        cartData.updatedCart?.deliveryAddress,
    ) as DeliveryAddressDTO
    const pickupOrderEntries = [] as CartOrderEntriesWithGroupingVal[]
    const unconsignedEntriesStatus = enableMergingOrderDetailsConsignmentAndUnconsignmentEntries
        ? { status: DeliveryStatusSTH.UNCONSIGNED }
        : {}

    cartData?.unconsignedEntries?.forEach((entry: CartOrderEntries) => {
        pickupOrderEntries.push({
            minETA: getMinMaxEta(entry)?.minETA as string,
            maxETA: getMinMaxEta(entry)?.maxETA as string,
            ...entry,
            ...unconsignedEntriesStatus,
        } as unknown as CartOrderEntriesWithGroupingVal)
    })
    const consignmentEntries = [] as CartOrderEntriesWithGroupingVal[]
    cartData?.consignments?.forEach((orderEntry: Consignment) => {
        orderEntry?.entries?.forEach((entry: OrderEntry) => {
            consignmentEntries.push({
                trackingID: orderEntry.trackingID,
                trackingUrl: orderEntry.trackingUrl,
                minETA: getMinMaxEta(entry as IMinMaxEtaEntry)?.minETA as string,
                maxETA: getMinMaxEta(entry as IMinMaxEtaEntry)?.maxETA as string,
                ...entry,
                status: orderEntry.status,
                statusDate: orderEntry.statusDate,
            } as unknown as CartOrderEntriesWithGroupingVal)
        })
    })

    // api new change will only return consignments or unconsignment based on whether order has been processed and consigned
    // will have final details after processing hence it gets precedence
    const consignOrder = enableMergingOrderDetailsConsignmentAndUnconsignmentEntries
        ? consignmentEntries?.concat(pickupOrderEntries)
        : // eslint-disable-next-line sonar/no-nested-conditional
        cartData?.consignments
        ? consignmentEntries
        : pickupOrderEntries
    const returnRequestedOrderEntryNumbers: number[] = []

    cartData?.returnRequestedData?.forEach(returnRequest => {
        returnRequest.entries = returnRequest.returnEntries.map(returnEntry => {
            const orderEntry = orderEntries.find(entry => entry.entryNumber === returnEntry.entryNumber)
            returnRequestedOrderEntryNumbers.push(returnEntry.entryNumber as number)
            return {
                ...orderEntry,
                ...returnEntry,
                status: DeliveryStatusSTH.RETURN_REQUESTED,
                packageIds: returnRequest.packageIds,
                returnRequestDate: returnRequest.requestCreatedDate,
            }
        })
    })

    const consignOrderBopis = getBopisEntries(consignOrder)
    const consignOrderSth = getSthEntries(consignOrder)
    const bopisGrouped = getStatusGrouping(consignOrderBopis as CartOrderEntriesWithGroupingVal[])
    const sthGrouped = getStatusGrouping(consignOrderSth as CartOrderEntriesWithGroupingVal[])
    const selectedServiceList = getServiceAddonsList(consignOrder)
    const services = consignOrder.filter((item: CartOrderEntries) => item.entryType === eEntryType.SERVICE_STANDALONE)
    cartData?.returnRequestedData?.forEach(returnRequest => {
        sthGrouped[`${DeliveryStatusSTH.RETURN_REQUESTED}_${returnRequest.requestCreatedDate as string}`] =
            returnRequest.entries as unknown as CartOrderEntriesWithFilterVal[]
    })

    const sthGroupedCancellationKeys = Object.keys(sthGrouped).filter(key =>
        key.startsWith(DeliveryStatusSTH.CANCELLATION_PENDING),
    )

    sthGroupedCancellationKeys?.forEach(sthGroupedCancellationKey => {
        sthGrouped[sthGroupedCancellationKey] = sthGrouped[sthGroupedCancellationKey]?.filter(
            cancelEntry => !returnRequestedOrderEntryNumbers.includes(cancelEntry.entryNumber),
        )
    })

    /**
     * This function maintains order of bopis and sth items grouped
     * @param {Record<string, unknown>} groupedItems can be sth or bopis grouped items as object with status in key
     * @param {(DeliveryStatusBopis | DeliveryStatusSTH)[]} displaySequence sequence of statuses array
     * @return {Record<string, unknown>} returns ordered items as per sequence
     */
    const getReorderedItems = (
        groupedItems: Record<string, unknown>,
        displaySequence: (DeliveryStatusBopis | DeliveryStatusSTH)[],
    ): Record<string, unknown> => {
        const reorderedGrouped: Record<string, unknown> = {}
        displaySequence.forEach(status => {
            const keysByStatus = Object.keys(groupedItems).filter(key => key.startsWith(status))
            keysByStatus.forEach(key => {
                reorderedGrouped[key] = groupedItems[key]
            })
        })
        return reorderedGrouped
    }

    const servicesGrouped = getStatusGrouping(services)
    const { bopisPackages, sthPackages } = getPackagesData(cartData)

    return {
        cart: cart as unknown as CartItemsData,
        bopis,
        sth,
        services,
        storeData,
        deliveryAddress,
        selectedServiceList,
        bopisGrouped: cartData?.consignments ? getReorderedItems(bopisGrouped, displaySequenceBOPIS) : bopisGrouped,
        sthGrouped: cartData?.consignments ? getReorderedItems(sthGrouped, displaySequenceSTH) : sthGrouped,
        servicesGrouped,
        bopisPackages,
        sthPackages,
    } as unknown as FilteredCartData
}

export default getFilteredDeliveryModes
