import { magicNumber } from '@nl/lib'
import { PREFIX } from '../../config'
import { getComponentProps } from '../../utils/getComponentProps'
import { FeaturedListNavigationSectionAnchor } from './featuredListNavigationSection.type'

/**
 * @param featuredList with navigation section container
 * @param {Record<string, string>} props
 */
const embedFeaturedListNavigationSection = (featuredList: Element, props: Record<string, string>): void => {
    const { navigationSectionAreaLabel, navigationSectionLinksAreaLabel } = props

    const getIndexOfFocusedLink = (listOfLinks: Element): number => {
        return Array.prototype.indexOf.call(listOfLinks.children, document.activeElement?.parentElement)
    }

    const addFocusToTheLinkByIndex = (listOfLinks: Element, index: number): void => {
        listOfLinks.getElementsByTagName('a')[index].focus()
    }

    const moveFocusToThePreviousLink = (listOfLinks: Element): void => {
        const minIndex = magicNumber.ZERO
        const indexOfPreviousLink = getIndexOfFocusedLink(listOfLinks) - magicNumber.ONE
        if (indexOfPreviousLink >= minIndex) {
            addFocusToTheLinkByIndex(listOfLinks, indexOfPreviousLink)
        }
    }

    const handleLeftArrowKeyPressed = (listOfLinks: Element, event: Event): void => {
        event.preventDefault()
        moveFocusToThePreviousLink(listOfLinks)
    }

    const moveFocusToTheNextLink = (listOfLinks: Element): void => {
        const maxIndex = listOfLinks.children.length - magicNumber.ONE
        const indexOfNextLink = getIndexOfFocusedLink(listOfLinks) + magicNumber.ONE
        if (indexOfNextLink <= maxIndex) {
            addFocusToTheLinkByIndex(listOfLinks, indexOfNextLink)
        }
    }

    const handleRightArrowKeyPressed = (listOfLinks: Element, event: Event): void => {
        event.preventDefault()
        moveFocusToTheNextLink(listOfLinks)
    }

    const moveFocusToTheLinkTarget = (link: HTMLElement): void => {
        const href = link.getAttribute('href') || ''
        const containerId = href.substring(href.indexOf('#') + magicNumber.ONE)
        document.getElementById(containerId)?.getElementsByTagName('a')[magicNumber.ZERO]?.focus()
    }

    const handleEnterKeyPressed = (event: Event): void => {
        event.preventDefault()
        moveFocusToTheLinkTarget(event.target as HTMLElement)
    }

    const handleKeyPressed = (listOfLinks: Element, event: KeyboardEvent): void => {
        switch (event.key) {
            case 'ArrowLeft':
                handleLeftArrowKeyPressed(listOfLinks, event)
                break
            case 'ArrowRight':
                handleRightArrowKeyPressed(listOfLinks, event)
                break
            case 'Enter':
                handleEnterKeyPressed(event)
                break
            default:
                break
        }
    }

    const addKeysNavigationSupport = (listOfLinks: Element): void => {
        listOfLinks.addEventListener('keydown', event => handleKeyPressed(listOfLinks, event as KeyboardEvent))
    }

    /**
     * @param {string} anchorLabel will be concatenated with randon value
     * @param linkLabel
     * @returns generated id
     */
    const generateUniqueId = (linkLabel: string): string => {
        return `${linkLabel}_${Math.random().toString(magicNumber.SIXTEEN).slice(magicNumber.TWO)}`
    }

    const createAnchors = (): FeaturedListNavigationSectionAnchor[] => {
        const anchors = [] as FeaturedListNavigationSectionAnchor[]
        const featuredListComponents = document.querySelectorAll(`.${PREFIX}-featured-list`)
        featuredListComponents.forEach((featuredListComponent: Element) => {
            const { enableNavigationSection, navigationSectionLinkLabel } = getComponentProps(featuredListComponent)

            if (!Boolean(enableNavigationSection) && navigationSectionLinkLabel) {
                const id = generateUniqueId(navigationSectionLinkLabel)
                featuredListComponent.setAttribute('id', id)

                anchors.push({
                    anchorLink: '#' + id,
                    anchorLabel: navigationSectionLinkLabel,
                })
            }
        })
        return anchors
    }

    /**
     * @param {FeaturedListNavigationSectionAnchor} anchor with anchor link and label
     * @param {string} ariaLabel for the link
     * @returns created link
     */
    const createLink = (
        { anchorLink, anchorLabel }: FeaturedListNavigationSectionAnchor,
        ariaLabel: string,
    ): Element => {
        const link = document.createElement('a')
        link.setAttribute('class', `${PREFIX}-navigation-section__nav-list--item-link`)
        link.setAttribute('href', anchorLink)
        link.setAttribute('aria-label', ariaLabel ? ariaLabel.replace('[0]', anchorLabel) : anchorLabel)
        link.innerText = anchorLabel
        return link
    }

    /**
     * @param {FeaturedListNavigationSectionAnchor[]} anchors
     * @param {string} listAreaLabel
     * @param {string} linksAreaLabel
     * @returns created list of links
     */
    const createListOfLinks = (
        anchors: FeaturedListNavigationSectionAnchor[],
        listAreaLabel: string,
        linksAreaLabel: string,
    ): Element => {
        const ul = document.createElement('ul')
        ul.setAttribute('class', `${PREFIX}-navigation-section__nav-list`)
        ul.setAttribute('aria-label', listAreaLabel ? listAreaLabel : 'featured-list-navigation-section')

        anchors.forEach(anchor => {
            const li = document.createElement('li')
            li.setAttribute('class', `${PREFIX}-navigation-section__nav-list--item`)
            const link = createLink(anchor, linksAreaLabel)
            li.appendChild(link)
            ul.appendChild(li)
        })

        return ul
    }

    const navigationSectionContainer = featuredList.querySelector(`.${PREFIX}-navigation-section`)

    const anchors = createAnchors()

    if (anchors.length && navigationSectionContainer) {
        const listOfLinks = createListOfLinks(anchors, navigationSectionAreaLabel, navigationSectionLinksAreaLabel)
        navigationSectionContainer?.appendChild(listOfLinks)
        addKeysNavigationSupport(listOfLinks)
    }
}

export { embedFeaturedListNavigationSection }
