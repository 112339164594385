import { createAction } from 'typesafe-actions'
import {
    SUCCESS_UPDATE_PASSWORD,
    FAIL_UPDATE_PASSWORD,
    FAIL_UPDATE_TFA_PHONE_NUMBER,
    SUCCESS_UPDATE_TFA_PHONE_NUMBER,
} from '../types/security/security.actionTypes.constant'

export const successUpdatePassword = createAction(SUCCESS_UPDATE_PASSWORD)()
export const failUpdatePassword = createAction(FAIL_UPDATE_PASSWORD)<string>()
export const successUpdateTfaPhoneNumber = createAction(SUCCESS_UPDATE_TFA_PHONE_NUMBER)()
export const failUpdateTfaPhoneNumber = createAction(FAIL_UPDATE_TFA_PHONE_NUMBER)<string>()
