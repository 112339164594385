import React from 'react'
import PropTypes from 'prop-types'

import { HeroBannerType } from './HeroBannerSplit.type'
import { PREFIX } from '../config'

/**
 * HeroBannerSplit component
 *
 * @param {HeroBannerType} props
 * @return {JSX.Element}
 */
const HeroBannerSplit: React.FC<HeroBannerType> = ({ ...props }): JSX.Element => {
    const { img, bgImg, children, className, dynamicImgs, isPostPurchaseComp } = props

    const bannerClass = className ? className : `${PREFIX}-hero-banner`

    const imgClass = img ? `${bannerClass}__image` : ''
    const bgImageInline = bgImg ? { backgroundImage: `url(${bgImg})` } : {}
    return isPostPurchaseComp ? (
        <section className={`${PREFIX}-upselling-rewards ${PREFIX}-post-purchase ${PREFIX}-row`}>
            <div className={`${PREFIX}-upselling-rewards__bgimage`} style={bgImageInline}></div>
            <div className={`${PREFIX}-col-md-3 ${PREFIX}-col-sm-3 ${imgClass}`}>
                {img && <img data-component-name={img?.dataComp} src={img?.url} alt={img?.altText} />}
                {dynamicImgs}
            </div>
            <div className={`${bannerClass}__content ${PREFIX}-col-md-9 ${PREFIX}-col-sm-12`}>{children}</div>
        </section>
    ) : (
        <section className={`${bannerClass} ${PREFIX}-row`} style={bgImageInline}>
            <div className={`${bannerClass}__content ${PREFIX}-col-md-6 ${PREFIX}-col-sm-6`}>{children}</div>
            <div className={`${PREFIX}-col-md-6 ${PREFIX}-col-sm-6 ${imgClass}`}>
                {img && <img data-component-name={img?.dataComp} src={img?.url} alt={img?.altText} />}
                {dynamicImgs}
            </div>
        </section>
    )
}

HeroBannerSplit.propTypes = {
    img: PropTypes.shape({
        url: PropTypes.string,
        altText: PropTypes.string,
        title: PropTypes.string,
        dataComp: PropTypes.string,
    }),
    bgImg: PropTypes.string,
    children: PropTypes.node.isRequired,
    className: PropTypes.string.isRequired,
}

export default HeroBannerSplit
