import { magicNumber } from './magicNumber'

/**
 * Generates a function that adds ordered promises into a queue.
 * Resolved promises will have their callbacks called, and remove any previous promises from the queue, blocking any outdated data from being handled.
 * @param {boolean} handleRecentOnly - Handle only the most recently pushed promise. Defaults to false.
 * @return {function} function that pushes a promise to the queue
 */
export const promiseQueue = (handleRecentOnly = false) => {
    const queueArr = [] as Promise<unknown>[]

    /**
     * This function handle pos
     * @param {number} pos
     * @return {boolean}
     */
    function shouldBeHandled(pos: number) {
        if (handleRecentOnly) {
            return pos + magicNumber.ONE === queueArr.length
        }
        return pos >= 0
    }

    /**
     * @param {Promise<void>} promise - promise to be pushed to queue
     * @param {function} handleResponse - called on success
     * @param {function} handleError - called on failure
     * @return {void}
     */
    // eslint-disable-next-line sonar/no-built-in-override
    function pushToQueue<Data, Error>(
        promise: Promise<Data>,
        handleResponse: (d: Data) => void,
        handleError: (e: Error) => void,
    ) {
        queueArr.push(promise)
        promise
            .then(data => {
                const pos = queueArr.indexOf(promise)
                if (shouldBeHandled(pos)) {
                    handleResponse(data)
                    queueArr.splice(0, pos)
                }
            })
            .catch((err: Error) => {
                const pos = queueArr.indexOf(promise)
                if (shouldBeHandled(pos)) handleError(err)
            })
    }
    return pushToQueue
}
