export const queryParameters = {
    language: 'lang',
    searchQuery: 'q',
    searchRequestQuery: 'rq',
    divider: ';',
    count: 'count',
    page: 'page',
    priceUpperBound: 'priceUpperBound',
    priceLowerBound: 'priceLowerBound',
    store: 'store',
    plpCDSDivider: '&',
    mock: 'mock',
    light: 'light',
    automotive: 'automotive',
    tireSize: 'auto.size',
    useRanks: 'useRanks',
    utmParams: ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'mi_ecmp', 'mi_u', 'et_hue', 'gclid'],
    isIgnoreAttributesThreshold: 'isIgnoreAttributesThreshold',
    hashDivider: '#',
    aoaLinkType: 'aoaLinkType',
    orderId: 'orderId',
    orderIdForOrderDetailsPage: 'token',
    searchResultType: 'index',
} as const

export const anyDividerRegExp = new RegExp(
    `[${queryParameters.divider}${queryParameters.plpCDSDivider}${queryParameters.hashDivider}]`,
)
