import { getEnvironment } from '../../environments'
import { getHttpClient } from '../../httpClient'
import BaseService from '../base.service'
import { HttpReqHeaders } from '../../httpClient/client.type'
// eslint-disable-next-line max-len
import { OrderModificationType } from '../../components/ProductCardAndBillingWrapper/BillingAndShippingInfo/BillingAndShippingInfo.type'
import { apiMethods } from '../service.constants'
import { AxiosPromise } from 'axios'

const environment = getEnvironment()
const httpClient = getHttpClient()

const ORDER_ID_PLACEHOLDER = '{orderId}'

/**
 * Get an order
 * @param {string} guid
 * @param {userId} userId
 * @param {bannerid} bannerId
 */
class OrderDetailsService extends BaseService {
    /**
     * This function used to create uri for fetching return slip info
     * @param {string} orderId
     * @return {URL} url
     */
    createGetReturnSlipUrl(orderId: string): URL {
        const path =
            environment.API_ENDPOINTS.orderReturnSlip.replace(ORDER_ID_PLACEHOLDER, orderId) +
            `?lang=${OrderDetailsService.language}`
        return OrderDetailsService.createUri(path)
    }

    /**
     * This function used to fetch PDP document with order info
     * @param {string} orderId
     * @param {string[]} packageIds
     * @return {AxiosPromise} blob file
     */
    generateReturnSlip(orderId: string, packageIds: string[]) {
        const url: URL = this.createGetReturnSlipUrl(orderId)
        const headers: HttpReqHeaders = {
            'Content-Type': 'application/json',
            accept: 'application/pdf',
        }

        return httpClient.apiPostForFile(url.toString(), packageIds, headers, 'blob', true)
    }

    /**
     * This function used to create uri for update order customer info
     * @param {string} orderId
     * @return {URL} urlP
     */
    createUpdateOrderCustomerInfoUrl(orderId: string): URL {
        const path = `${environment.API_ENDPOINTS.modifyOrderCustomerInfo}?code=${orderId}`
        return OrderDetailsService.createUri(path)
    }

    /**
     * API call to update billing infromation on order details page
     * @param {string} orderId
     * @param {OrderModificationType} orderModificationData
     * @return {AxiosPromise}
     */
    updateBillingInformation(orderId: string, orderModificationData: OrderModificationType): AxiosPromise {
        const url: URL = this.createUpdateOrderCustomerInfoUrl(orderId)

        return OrderDetailsService.apiMethod(apiMethods.PUT, String(url), { ...orderModificationData })
    }
}

// Creating a singleton instance of service
const orderDetailsService = new OrderDetailsService()

export { orderDetailsService }
export default orderDetailsService
