import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { productSelector } from '../../../redux/selectors/product.selectors'
import { getFormattedPriceWithLocale } from '../../../utils/formatPrice'
import { Badges, badgesAndPrioritiesType, isArrayNotEmpty } from '@nl/lib'
import { PREFIX } from '../../../config'
import { analyticsAttributes } from '../../../globalConstants'
import { BadgesWrapperProps } from './BadgesWrapper.type'
import { loyaltyBadge } from '../BuyBox.type'

const badgesWrapperClass = `${PREFIX}-buy-box__badges`

/**
 * BadgesWrapper component
 * @param {BadgesWrapperProps} props - props for BadgesWrapper
 * @returns {JSX.Element} returns BadgesWrapper component
 */
const BadgesWrapper = ({
    badges,
    isStaggered,
    hideDisclaimer,
    badgesDisplayOptions,
    saleEndDaySoonMessage,
    productSaleEndDate,
    saleEndDisableShift,
    loyaltyValue,
}: BadgesWrapperProps): JSX.Element => {
    const { quantitySelected } = useSelector(productSelector)
    const { OfferRewardValue = '', RewardPercentage } = loyaltyValue?.Bonus || {}

    /**
     * calculated Loyalty value from CDS response of PandA and Product api
     * @returns {loyaltyBadge | undefined} return LoyaltyValue
     */
    const calculatedLoyaltyValue = useCallback((): loyaltyBadge | undefined => {
        return {
            ...loyaltyValue,
            Bonus: {
                OfferRewardValue: RewardPercentage
                    ? getFormattedPriceWithLocale(Number(OfferRewardValue) * quantitySelected)
                    : getFormattedPriceWithLocale(Number(OfferRewardValue)),
                OfferRule: loyaltyValue?.Bonus?.OfferRule || '',
                RewardPercentage: loyaltyValue?.Bonus?.RewardPercentage || '',
            },
        }
    }, [loyaltyValue, OfferRewardValue, RewardPercentage, quantitySelected])

    return (
        <>
            {isArrayNotEmpty(badges) && !isStaggered ? (
                <div className={badgesWrapperClass}>
                    <Badges
                        badges={badges}
                        badgesAndPriorities={badgesDisplayOptions as badgesAndPrioritiesType}
                        hideDisclaimer={hideDisclaimer}
                        clickable={true}
                        saleEndDaySoonMessage={saleEndDaySoonMessage}
                        salePriceEndDate={productSaleEndDate}
                        analyticsLabelValue={analyticsAttributes.eventParameters.labels.clickableBadge}
                        saleEndDisableShift={saleEndDisableShift}
                        loyaltyValue={calculatedLoyaltyValue()}
                    />
                </div>
            ) : null}
        </>
    )
}

export default BadgesWrapper
