import React, { useEffect, useState, Validator } from 'react'
import PropTypes from 'prop-types'

import { PREFIX } from '../config'
import ProductInfo from '../ProductInfo'
import VehicleProductInformation from './VehicleProductInformation'
import {
    vehicleRearLabelValue,
    rearTireCode,
    rearTireCurrentPrice,
    setOf4Price,
    frontTireSpecification,
    rearTireSpecification,
    rearTireDisplayWasLabel,
    rearTireOriginalPrice,
    getFeeValue,
    rearPriceMessage,
    rearBadgeList,
    getRebateDetails,
} from './VehicleProduct.helper'
import { BannerType, productWheelTypes } from '../VehicleBanner/VehicleBanner.type'
import { VehicleCardPropTypes } from './VehicleProductCard.type'
import { staggeredSkuLength, minimumSpecificationCount, maximumSpecificationCount } from './VehicleProduct.constant'
import { DisplayViewType } from '../GridListToggle/buttonConstant.type'
import AutoPartsProductInformation from './AutoPartsProductInformation'
import { CriticalFitment, FitmentNote } from '../ProductGridView/ProductGrid.types'
import RoadRatingBadge from '../RoadRatingBadge/RoadRatingBadge'
import { replaceStrWithDynamicVal, addDynamicToken } from '../../utils/replaceStrWithDynamicVal'
import { Specification } from '../ProductReusableCard/product.types'
import { ProductSku } from '../VariantsWrapper/VariantsWrapper.type'
import { isArrayNotEmpty, magicNumber, stringKeyCodes } from '../../utils'
import { productCardScrollToTop } from '../../utils/ProductCardScrollToTop/productCardScrollToTop'
import { ProductCardScrollEvent } from '../../utils/ProductCardScrollToTop/productCardScrollToTop.type'
import { getFormattedPriceValue } from '../ProductReusableCard/instanceCheckingUtil'
import { ProductCardType } from '../ProductGridView/ProductGrid.types'
import { getAccessibilityTitleId } from '../../utils/getAccessibilityId'
import { checkValidData } from '../../utils/checkDataLength'
import ClampLinesEllipsis from '../ClampLinesEllipsis'
import { isProductTire } from '../AutomotiveProductCards/AutomotiveProductCards.helper'
import VehicleAvailabilitySection from '../VehicleAvailabilitySection'
import classNames from 'classnames'
import ProductCardATC from './ProductCardATC'
import ProductCardWishlist from '../ProductCardWishlist/ProductCardWishlist'
import CompareCheckbox from '../CompareCheckbox/CompareCheckbox'

/**
 * ProductCard component
 * @param {VehicleCardPropTypes} props
 * @returns {JSX.Element} returns ProductCardVariants,ProductInfo,Price,Badges
 */
const VehicleProductCard: React.FC<VehicleCardPropTypes> = props => {
    const {
        addToCartBtnClick,
        product,
        idx,
        productProps,
        showAtcBtnSpinner,
        addToCartLabel,
        addProductItemToWishlist,
        isWishlistItemActive,
        isWishlistEligible,
        showWishlistBtnSpinner,
        sponsoredLabel,
        a11yWishlistIcon,
        isWishlistToShow,
        addProductItemToCompare,
        isCompareProductChecked,
        enableComparisonOfProducts,
        enableComparisonTool,
        compareProductsLabel,
        isBrowseOnlyMode,
        isPriceRangeViewEnabled = false,
    } = props
    const GridView = props.cardType === DisplayViewType.grid.toLowerCase()
    const ratingsAndReview = productProps.ratingsAndReview
    const { fitmentCriticalNotesLabel, vehicleSpecificFitLabel, enableAddToCartOptions } = productProps
    const [updatedFitmentNotesList, setFitmentNotes] = useState<null | FitmentNote[]>(null)
    const [selectedSKUData, setSelectedSkuData] = useState<null | ProductSku>()
    const isStaggeredState = props.skus?.length === staggeredSkuLength && props.vehicleInformationPresent
    const returnPolicy = props.returnPolicy
    const isLazyRequire = props.isLazyRequire
    /**
     *return image of product
     * @returns {string}
     */
    const productImageRendering = (): string => {
        return props.images && props.images?.[0]?.url
    }

    useEffect(() => {
        if (props.isAutoPartPlp) {
            const sku = props.skus?.[0]
            const fitment = sku?.fitment
            const fitmentData: FitmentNote[] = []
            if (fitment) {
                setSelectedSkuData(sku)
                fitment.fitmentNotes?.forEach(fitmentNote => {
                    fitmentData.push({ sku: fitment.sku, title: fitmentNote?.title, note: fitmentNote?.note })
                    return isArrayNotEmpty(fitmentData) ? fitmentData : null
                })
            }
            setFitmentNotes(fitmentData.length ? fitmentData : null)
        }
    }, [props.isAutoPartPlp, props.skus])

    /**
     * function to return rating of vehicle
     * @returns {JSX.Element}
     */
    const ratingsView = (): JSX.Element => {
        // eslint-disable-next-line no-warning-comments
        // TODO: if cds sends rating as an object i.e. averageRating and number of reviews need to pass the object keys as the props.
        return (
            ratingsAndReview && (
                <div
                    className={`${PREFIX}-reviews__list`}
                    data-bv-show="inline_rating"
                    data-bv-product-id={props.productDataId}
                    data-bv-seo="false"
                    aria-hidden={true}
                />
            )
        )
    }

    /**
     * @description function to return product rating info
     * @returns {JSX.Element} rating or skeleton
     */
    const showRating = (): JSX.Element => {
        return (
            props.showRatingSection && (
                <>
                    {ratingsView()}
                    {props.productWheelType?.toUpperCase() === productWheelTypes.Tire && (
                        <RoadRatingBadge
                            updateRoadRatingRef={props?.updateRoadRatingRef}
                            roadRatedLabel={props.roadRatedLabel}
                            aggregateValue={props.roadRating?.aggregateValue}
                            isReviewAvailable={props.ratingsCount > 0}
                            ariaHidden={true}
                        />
                    )}
                </>
            )
        )
    }

    /**
     * function return skucode code for Complete Vehicle State  and pcode for partial Vehicle State for non-staggered
     * @returns {string}
     */
    const codeValueForVehicle = (): string => {
        return props.skus?.[0]?.formattedCode || ''
    }

    /**
     * function return sponsored aria label for sponsored product
     * @returns {string}
     */
    const getSponsoredA11yId = (): string => {
        return product?.isSponsored ? `sponsored_${product?.code}` : ''
    }

    /**
     * function return skucode code for Complete Vehicle State  and pcode for partial Vehicle State in Autoparts
     * @returns {string}
     */
    const codeValueForAutoParts = (): string | JSX.Element => {
        const formattedCode = codeValueForVehicle()
        if (isArrayNotEmpty(props.skus) && props.isAutoPartPlp) {
            if (props.skus?.[0]?.partNumber) {
                if (GridView) {
                    return (
                        <>
                            <div
                                className={`${PREFIX}-product__grid-code`}>{`${props.partNumberLabel}: ${props.skus?.[0]?.partNumber}`}</div>
                            <div>{`#${formattedCode}`}</div>
                        </>
                    )
                } else return `#${formattedCode} | ${props.partNumberLabel} ${props.skus?.[0]?.partNumber}`
            } else return `#${formattedCode}`
        } else return ''
    }
    const code = props.isAutoPartPlp ? codeValueForAutoParts() : null

    const productCodeDisplay = (): JSX.Element => {
        return (
            code && (
                <p className={`${PREFIX}-product__code`} aria-hidden={true}>
                    {code}
                </p>
            )
        )
    }

    /**
     * function to display fitment details
     * @param { string | number } displayNote
     * @param { string } displayName
     * @returns { JSX.Element }
     */
    const displayFitmentDetails = (displayNote: string | number, displayName: string): JSX.Element => {
        return (
            <li key={displayNote}>
                <span className={`${PREFIX}-product-card__fitment-label`}>{displayName}: </span>
                <span className={`${PREFIX}-product-card__fitment-value`}>{displayNote}</span>
            </li>
        )
    }
    /**
     * function to display usage or position details
     * @param {FitmentNote} displayList
     * @param {string} displayName
     * @returns { JSX.Element[] }
     */
    const displayUsageOrPositionDetails = (displayList: FitmentNote[], displayName: string): JSX.Element[] => {
        return displayList?.map((usageValue: FitmentNote) => {
            return displayFitmentDetails(usageValue.note, displayName)
        })
    }

    /**
     * function to check if  fitment details present
     * @param {CriticalFitment} fitment
     * @returns { boolean }
     */

    /**
     *
     * @param fitment
     */
    const fitmentDetailsPresent = (fitment: CriticalFitment): boolean => {
        return (
            // eslint-disable-next-line sonar/expression-complexity
            fitment &&
            (isArrayNotEmpty(fitment?.fitmentNotes) ||
                isArrayNotEmpty(fitment?.position) ||
                isArrayNotEmpty(fitment?.tireWheelFitment) ||
                isArrayNotEmpty(fitment?.usage))
        )
    }

    /**
     * function to display critical fitment notes title
     * @param {CriticalFitment} fitment
     * @returns { boolean }
     */
    const shouldDisplayFitmentTitle = (fitment: CriticalFitment): boolean => {
        return fitment && (fitmentDetailsPresent(fitment) || fitment?.suggestedQty > 1)
    }

    /**
     * function  to show critical Fitment Notes
     * @returns {JSX.Element|null}
     */
    const specifications = (): JSX.Element | null => {
        const updatedFitmentNotes = updatedFitmentNotesList?.slice(minimumSpecificationCount, maximumSpecificationCount)
        const fitment = props.skus?.[0]?.fitment
        return props.isAutoPartPlp && checkValidData(fitment) && !GridView ? (
            <div className={`${PREFIX}-product-card__specific-information`} aria-hidden={true}>
                {shouldDisplayFitmentTitle(fitment) && (
                    <p className={`${PREFIX}-product-card__critical-fitment`}>{fitmentCriticalNotesLabel}</p>
                )}
                <ul className={`${PREFIX}-product-card__fitment-list`}>
                    {displayUsageOrPositionDetails(fitment?.usage, props.attributeUsageLabel)}
                    {displayUsageOrPositionDetails(fitment?.position, props.attributePositionLabel)}
                    {fitment?.suggestedQty &&
                        Number(fitment?.suggestedQty) > magicNumber.ONE &&
                        displayFitmentDetails(fitment?.suggestedQty, props.suggestedQuantityLabel)}
                    {updatedFitmentNotes?.map((value: FitmentNote) => {
                        return (
                            <li key={value.note}>
                                {value.title && (
                                    <span className={`${PREFIX}-product-card__fitment-label`}>{value.title}: </span>
                                )}
                                <span className={`${PREFIX}-product-card__fitment-value`}>{value.note}</span>
                            </li>
                        )
                    })}
                </ul>
            </div>
        ) : (
            <div className={`${PREFIX}-product-card__specific-information`} aria-hidden={true}>
                <ul className={`${PREFIX}-product-card__fitment-grid`}>
                    {displayUsageOrPositionDetails(fitment?.usage, props.attributeUsageLabel)}
                    {displayUsageOrPositionDetails(fitment?.position, props.attributePositionLabel)}
                    {updatedFitmentNotes?.slice(magicNumber.ZERO, magicNumber.TWO)?.map((value: FitmentNote) => {
                        return (
                            <li key={value.note}>
                                {value.title && (
                                    <span className={`${PREFIX}-product-card__fitment-label`}>{value.title}: </span>
                                )}
                                <ClampLinesEllipsis
                                    text={value.note}
                                    lines={magicNumber.TWO}
                                    className={`${PREFIX}-product-card__fitment-value--shiitake`}
                                />
                            </li>
                        )
                    })}
                </ul>
            </div>
        )
    }

    /**
     * function to display vehicle product list
     * @returns {JSX.Element}
     */
    const productVehicleCardData = (): JSX.Element => {
        const feeValue = getFeeValue(
            props.skus,
            props.feeValue,
            isStaggeredState,
            props.vehicleInformationPresent,
            props.tireInformationPresent,
        )
        return props.isAutoPartPlp ? (
            <AutoPartsProductInformation
                {...props}
                isVehicleFullState={props.vehicleInformationPresent}
                selectedSKUData={selectedSKUData}
                a11yStrikeOutPrice={props.a11yStrikeOutPrice}
                a11yStrikeOutPriceRange={props.a11yStrikeOutPriceRange}
                overridePriceHeight={props.overridePriceHeight}
                feeMessages={props.feeMessages}
                accessibilityId={props.accessibilityId}
                plusMinusSymbol={props.plusMinusSymbol}
                isOnSale={props.isOnSale}
                fitmentIcon={props.fitmentIcon}
                fitmentMessageToggle={props.fitmentMessageToggle}
                fitmentMessageToggleUniversal={props.fitmentMessageToggleUniversal}
                fitMessageOnCard={props.fitMessageOnCard}
                vehicleString={props.vehicleString}
                partialFitMessageOnCard={props.partialFitMessageOnCard}
                confirmFitMessageUniversalOnCard={props.confirmFitMessageUniversalOnCard}
                fitMessageUniversalOnCard={props.fitMessageUniversalOnCard}
                fitmentTypeCode={props.fitmentTypeCode}
            />
        ) : (
            <VehicleProductInformation
                vehicleRearLabel={vehicleRearLabelValue(isStaggeredState, props.perTireLabel, props.vehicleRearLabel)}
                rearTireSpecification={rearTireSpecification(
                    props.skus,
                    props.specification,
                    isStaggeredState,
                    props.productCardSpecifications,
                )}
                rearTireCode={rearTireCode(
                    props.skus,
                    codeValueForVehicle(),
                    isStaggeredState,
                    props.vehicleInformationPresent,
                    props.tireInformationPresent,
                )}
                rearTireCurrentPrice={rearTireCurrentPrice(
                    props.skus,
                    props.currentPrice,
                    isStaggeredState,
                    props.vehicleInformationPresent,
                    props.tireInformationPresent,
                )}
                rearTireOriginalPrice={rearTireOriginalPrice(
                    props.skus,
                    props.originalPrice,
                    isStaggeredState,
                    props.vehicleInformationPresent,
                    props.tireInformationPresent,
                )}
                frontTireSpecification={frontTireSpecification(
                    props.skus,
                    isStaggeredState,
                    props.productCardSpecifications,
                    props.specification,
                )}
                totalCurrentPrice={setOf4Price(
                    props.skus,
                    props.totalCurrentPrice,
                    isStaggeredState,
                    props.vehicleInformationPresent,
                    props.tireInformationPresent,
                )}
                frontTireCode={props.skus?.[0]?.formattedCode}
                setOfFourLabel={props.setOfFourLabel}
                skus={props.skus}
                vehicleFrontLabel={props.vehicleFrontLabel}
                language={props.language}
                feeTitle={
                    feeValue
                        ? replaceStrWithDynamicVal(
                              addDynamicToken(props.feeTitle, '$x'),
                              getFormattedPriceValue(props.language, feeValue),
                          )
                        : ''
                }
                vehicleFitType={props.vehicleFitType}
                rebate={getRebateDetails(
                    props.skus,
                    props.rebate,
                    isStaggeredState,
                    props.vehicleInformationPresent,
                    props.tireInformationPresent,
                )}
                rebateIcon={props.rebateIcon}
                eachLabel={props.eachLabel}
                isNonStaggeredGrid={isNonStaggeredGrid()}
                badges={rearBadgeList(
                    props.skus,
                    props.badges,
                    isStaggeredState,
                    props.vehicleInformationPresent,
                    props.tireInformationPresent,
                    props.exclude,
                    isProductTire(props.product),
                )}
                productLevelBadges={props.badges}
                badgePriorities={props.badgePriorities}
                hideDisclaimer={props.hideDisclaimer}
                originalPrice={props.originalPrice}
                feeDisclaimerMessage={props.feeDisclaimerMessage}
                nowFromLabel={props.nowFromLabel}
                saveFromLabel={props.saveFromLabel}
                wasFromLabel={props.wasFromLabel}
                fromLabel={props.fromLabel}
                discount={props.discount}
                promotionalPriceLabel={props.promotionalPriceLabel}
                unitPriceLabel={props.unitPriceLabel}
                displayWasLabel={props.displayWasLabel}
                rearTireDisplayWasLabel={rearTireDisplayWasLabel(
                    props.skus,
                    props.displayWasLabel,
                    isStaggeredState,
                    props.vehicleInformationPresent,
                    props.tireInformationPresent,
                )}
                clearancePriceLabel={props.clearancePriceLabel}
                feeDisclaimerType={props.feeDisclaimerType}
                isSRPPage={props.isSRPPage}
                priceMessage={rearPriceMessage(
                    props.skus,
                    props.priceMessage,
                    isStaggeredState,
                    props.vehicleInformationPresent,
                    props.tireInformationPresent,
                )}
                vehicleInformationPresent={props.vehicleInformationPresent}
                feeDisclaimerTitle={props.feeDisclaimerTitle}
                scrollToFooter={props.scrollToFooter}
                tireInformationPresent={props.tireInformationPresent}
                a11yStrikeOutPrice={props.a11yStrikeOutPrice}
                a11yStrikeOutPriceRange={props.a11yStrikeOutPriceRange}
                excludeBadges={props.exclude}
                frontAvailability={
                    hasMultipleSkus(props?.skus?.length) && props?.skus?.[0] ? (
                        <VehicleAvailabilitySection
                            skuData={props?.skus?.[0]}
                            accessibilityId={props.accessibilityId}
                            inStockAisleLabel={props.inStockAisleLabel}
                            inStockOnlineLabel={props.inStockOnlineLabel}
                            isUrgentLowStock={props.isUrgentLowStock}
                            isWheelOrTirePDP={props.isWheelOrTirePDP}
                            isAutoPartPlp={props.isAutoPartPlp}
                            isUrgentLowStockLabel={props.isUrgentLowStockLabel}
                            outOfStockLabel={props.outOfStockLabel}
                            checkAvailabilityLabel={props.checkAvailabilityLabel}
                            checkOtherStoresLabel={props.checkOtherStoresLabel}
                            {...props}
                        />
                    ) : null
                }
                rearAvailability={
                    hasMultipleSkus(props?.skus?.length) && props?.skus?.[magicNumber.ONE] ? (
                        <VehicleAvailabilitySection
                            skuData={props?.skus?.[magicNumber.ONE]}
                            accessibilityId={props.accessibilityId}
                            inStockAisleLabel={props.inStockAisleLabel}
                            inStockOnlineLabel={props.inStockOnlineLabel}
                            isUrgentLowStock={props.isUrgentLowStock}
                            isWheelOrTirePDP={props.isWheelOrTirePDP}
                            isAutoPartPlp={props.isAutoPartPlp}
                            isUrgentLowStockLabel={props.isUrgentLowStockLabel}
                            outOfStockLabel={props.outOfStockLabel}
                            checkAvailabilityLabel={props.checkAvailabilityLabel}
                            checkOtherStoresLabel={props.checkOtherStoresLabel}
                            {...props}
                        />
                    ) : null
                }
                perAvailability={
                    props?.skus?.[0] && (
                        <VehicleAvailabilitySection
                            skuData={props?.skus?.[0]}
                            accessibilityId={props.accessibilityId}
                            inStockAisleLabel={props.inStockAisleLabel}
                            inStockOnlineLabel={props.inStockOnlineLabel}
                            isUrgentLowStock={props.isUrgentLowStock}
                            isWheelOrTirePDP={props.isWheelOrTirePDP}
                            isAutoPartPlp={props.isAutoPartPlp}
                            isUrgentLowStockLabel={props.isUrgentLowStockLabel}
                            outOfStockLabel={props.outOfStockLabel}
                            checkAvailabilityLabel={props.checkAvailabilityLabel}
                            checkOtherStoresLabel={props.checkOtherStoresLabel}
                            {...props}
                        />
                    )
                }
                a11yClickToReadFootnote={props.a11yClickToReadFootnote}
                accessibilityId={props.accessibilityId}
                ariaHidden={true}
                a11yTooltipIcon={props.a11yTooltipIcon}
                a11yCloseIconLabel={props.a11yCloseIconLabel}
                plusMinusSymbol={props.plusMinusSymbol}
                isTireProduct={isProductTire(props.product)}
                isTireDataExist={props.isTireDataExist}
                fitmentMessage={props.fitmentMessageOnProductLevel}
                fitmentIcon={props.fitmentIcon}
                fitmentMessageToggle={props.fitmentMessageToggle}
                isOnSale={props.isOnSale}
                isPriceRangeViewEnabled={isPriceRangeViewEnabled}
            />
        )
    }

    /**
     * function to get vehicle fit label
     * @param {Specification[]} specificationsList
     * @returns {string}
     */
    const getVehicleFitLabel = (specificationsList: Specification[]): string => {
        const vehicleFitType = specificationsList?.find((specification: Specification) =>
            [vehicleSpecificFitLabel].includes(specification.code),
        )
        return vehicleFitType?.value
    }

    /**
     * function return product information brand, name, rating, badges, variant
     * @returns {JSX.Element}
     */
    const productInfoComponent = (): JSX.Element => {
        return (
            <div className={`${PREFIX}-product-card__product-information`}>
                <ProductInfo
                    brand={props.brand}
                    title={props.title}
                    hideDescription={props.hideDescription}
                    titleStyle={`${PREFIX}-product-card__title ${PREFIX}-product--trim`}
                    titleContainerStyle={`${PREFIX}-product-card__title-container`}
                    isFitmentRequired={props.isFitmentRequired}
                    tireCategory={props.tireCategory}
                    isAutoPartPlp={props.isAutoPartPlp}
                    ariaHidden={true}
                    vehicleFitLabel={
                        props.vehicleInformationPresent
                            ? getVehicleFitLabel(props?.skus[0]?.specifications)
                            : getVehicleFitLabel(props?.specification)
                    }
                    accessibilityTitleId={getAccessibilityTitleId(props.accessibilityId)}
                    boldBrand={props.boldBrand}
                />
                {showRating()}
                {!GridView && (
                    <div className={`${PREFIX}-product-card__wishlist-section`}>
                        <ProductCardATC
                            showAtcBtnSpinner={showAtcBtnSpinner}
                            product={product}
                            addToCartBtnClick={addToCartBtnClick}
                            idx={idx}
                            addButton={productProps.addButton}
                            optionsButton={productProps.optionsButton}
                            enableAddToCartOptions={enableAddToCartOptions}
                            enableMiniPdpFlyoutSupport={false}
                            enableATCForSingleSkuMiniPdp={false}
                            addToCartLabel={addToCartLabel}
                        />
                        <ProductCardWishlist
                            isWishlistEligible={isWishlistEligible}
                            a11yWishlistIcon={a11yWishlistIcon}
                            showWishlistBtnSpinner={showWishlistBtnSpinner}
                            addProductItemToWishlist={addProductItemToWishlist}
                            isWishlistItemActive={Boolean(isWishlistItemActive)}
                            product={product}
                            idx={idx}
                            isWishlistToShow={isWishlistToShow}
                        />
                    </div>
                )}
            </div>
        )
    }

    // returns true is plp is in grid view with non-staggered fit product
    const isNonStaggeredGrid = () => {
        return GridView && props.skus?.length !== staggeredSkuLength
    }

    const getAutoMotiveWrapperClass = (): string => {
        const autoPartPlpClass =
            props.vehicleFitType === BannerType.Fit
                ? `${PREFIX}-product-card__full-vehicle`
                : `${PREFIX}-product-card__partial-vehicle`
        return props.isAutoPartPlp ? autoPartPlpClass : `${PREFIX}-product-card__automotive-content`
    }

    const hasMultipleSkus = (skuQuantity: number): boolean => {
        const singleSkuNumber = 1
        return skuQuantity > singleSkuNumber
    }

    const fitmentClass = props.isAutoPartPlp ? `${PREFIX}-product-card__price-fitment_grid--auto-parts` : ''

    const modifierClass =
        !isArrayNotEmpty(updatedFitmentNotesList) && props.isAutoPartPlp
            ? `${PREFIX}-product-card__auto-info--no-critical-fitmnet`
            : ''

    type imgAttribute = {
        'data-src'?: string
        src?: string
        className?: string
        'data-component-name': string
    }
    /**
     * @param {string} path
     * @param {string} dataComponentName
     * @returns {imgAttribute}
     */
    const imageAttributes = (path: string, dataComponentName: string) => {
        const attributeObject: imgAttribute = {
            'data-component-name': dataComponentName,
        }
        if (isLazyRequire) {
            attributeObject['data-src'] = path
            attributeObject.className = 'lazyload'
        } else {
            attributeObject.src = path
        }
        return attributeObject
    }

    const createUrlWithPolicy = (source: string, policyType: string) => {
        return policyType
            ? `${source?.toString()?.split('?', magicNumber.ONE).toString()}${returnPolicy(policyType)}`
            : `${source}`
    }

    /**
     * function to check if specification should be rendered
     * @returns {JSX.Element}
     */
    const shouldRenderSpecification = (): JSX.Element => {
        const fitment = props.skus?.[0]?.fitment
        return fitmentDetailsPresent(fitment) && specifications()
    }

    /**
     * This function is used to render list view specifications on product cards
     * @returns {JSX.Element}
     */
    const renderListViewSpecifications = (): JSX.Element => {
        return !GridView && shouldRenderSpecification()
    }

    /**
     * This function is used to render grid view specifications on product cards
     * @returns {JSX.Element}
     */
    const renderGridViewSpecifications = (): JSX.Element => {
        return GridView && shouldRenderSpecification()
    }

    /**
     * @param {Event} event
     * @param {string} key
     * @param {boolean} scrollingRequired
     */
    const handleKeyUp = (event, key, scrollingRequired) => {
        if (key === stringKeyCodes.tab && scrollingRequired) {
            productCardScrollToTop(event as ProductCardScrollEvent)
        }
    }
    /**
     * @param {Event} event
     * @param {string} key
     */
    const handleKeyDown = (event, key) => {
        if (key === stringKeyCodes.enter) {
            props.productCardClick(event, props.product, props.idx)
        }
    }

    /**
     * This function to return wishlist component for grid view
     * @returns {JSX.Element} returns JSX.Element
     */
    const renderGridWishlistSection = (): JSX.Element => {
        return (
            GridView && (
                <ProductCardWishlist
                    isWishlistEligible={isWishlistEligible}
                    a11yWishlistIcon={a11yWishlistIcon}
                    showWishlistBtnSpinner={showWishlistBtnSpinner}
                    addProductItemToWishlist={addProductItemToWishlist}
                    isWishlistItemActive={Boolean(isWishlistItemActive)}
                    product={product}
                    idx={idx}
                    isWishlistToShow={isWishlistToShow}
                />
            )
        )
    }

    /**
     * This function is used to render sponsored label for sponsored products
     * @returns {JSX.Element}
     */
    const renderSponsoredLabel = (): JSX.Element => {
        return (
            GridView &&
            product?.isSponsored && (
                <small
                    id={getSponsoredA11yId()}
                    aria-label={sponsoredLabel}
                    aria-hidden="true"
                    className={`${PREFIX}-product-card__sponsored`}>
                    {sponsoredLabel}
                </small>
            )
        )
    }

    return (
        <a
            href={props.url}
            onKeyPress={(event: React.KeyboardEvent<HTMLElement>) => {
                handleKeyDown(event, event.key)
            }}
            aria-labelledby={`title__${props.accessibilityId} ${getSponsoredA11yId()} price__${
                props.accessibilityId
            } availability__${props.accessibilityId}`}
            onClick={event => props.productCardClick(event, props.product, props.idx)}
            tabIndex={0}
            onKeyUp={(event: React.KeyboardEvent<HTMLElement>) => {
                handleKeyUp(event, event.key, props.isScrollingRequired)
            }}
            data-testid={`product-card-container${props.idx}`}
            className={classNames(`${PREFIX}-product-card__content`, getAutoMotiveWrapperClass(), props.className)}>
            <div
                className={`${PREFIX}-product-card__automotive-wrap ${
                    !props.isWheelOrTirePDP ? `${PREFIX}-product-card__automotive-wrap--no-wrap` : ``
                }`}>
                {renderGridWishlistSection()}
                <div className={`${PREFIX}-product-card__auto-primary-details`}>
                    <div className={`${PREFIX}-product-card__auto-primary-details-right`}>
                        <div className={`${PREFIX}-product-card__image-wrap`} aria-hidden={true}>
                            <img
                                aria-hidden={true}
                                alt=""
                                {...imageAttributes(
                                    `${createUrlWithPolicy(productImageRendering(), props.imageDataComponentName)}`,
                                    props.imageDataComponentName,
                                )}
                            />
                        </div>
                        <div className={`${PREFIX}-product-card__cta`}>
                            {GridView && (
                                <ProductCardATC
                                    showAtcBtnSpinner={showAtcBtnSpinner}
                                    product={product}
                                    addToCartBtnClick={addToCartBtnClick}
                                    idx={idx}
                                    addButton={productProps.addButton}
                                    optionsButton={productProps.optionsButton}
                                    enableAddToCartOptions={enableAddToCartOptions}
                                    enableMiniPdpFlyoutSupport={false}
                                    enableATCForSingleSkuMiniPdp={false}
                                    addToCartLabel={addToCartLabel}
                                />
                            )}
                            {productInfoComponent()}
                        </div>
                    </div>

                    <div className={`${PREFIX}-product-card__price-fitment_grid ${fitmentClass}`}>
                        <div className={`${PREFIX}-product-card__auto-info ${modifierClass}`}>
                            {productVehicleCardData()}
                            {renderGridViewSpecifications()}
                            {productCodeDisplay()}
                            {props.isAutoPartPlp && props.showAvailabilityMessage && (
                                <VehicleAvailabilitySection
                                    skuData={props?.skus?.[0]}
                                    accessibilityId={props.accessibilityId}
                                    inStockAisleLabel={props.inStockAisleLabel}
                                    inStockOnlineLabel={props.inStockOnlineLabel}
                                    isUrgentLowStock={props.isUrgentLowStock}
                                    isWheelOrTirePDP={props.isWheelOrTirePDP}
                                    isAutoPartPlp={props.isAutoPartPlp}
                                    isUrgentLowStockLabel={props.isUrgentLowStockLabel}
                                    outOfStockLabel={props.outOfStockLabel}
                                    checkAvailabilityLabel={props.checkAvailabilityLabel}
                                    checkOtherStoresLabel={props.checkOtherStoresLabel}
                                    {...props}
                                />
                            )}
                        </div>
                        {renderListViewSpecifications()}
                        {
                            <CompareCheckbox
                                addProductItemToCompare={addProductItemToCompare}
                                product={product}
                                idx={idx}
                                isCompareProductChecked={isCompareProductChecked}
                                enableComparisonOfProducts={enableComparisonOfProducts}
                                enableComparisonTool={enableComparisonTool}
                                compareProductsLabel={compareProductsLabel}
                                isBrowseOnlyMode={isBrowseOnlyMode}
                            />
                        }
                    </div>
                </div>
            </div>
            {renderSponsoredLabel()}
        </a>
    )
}

VehicleProductCard.propTypes = {
    path: PropTypes.string,
    productProps: PropTypes.any,
    idx: PropTypes.number.isRequired,
    cardType: PropTypes.string.isRequired,
    type: PropTypes.string,
    title: PropTypes.string.isRequired,
    code: PropTypes.string,
    brand: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.exact({
            label: PropTypes.string,
            url: PropTypes.string,
        }),
    ]).isRequired,
    rating: PropTypes.number.isRequired,
    ratingsCount: PropTypes.number.isRequired,
    priceShortMessage: PropTypes.string,
    priceLongMessage: PropTypes.string,
    longDescription: PropTypes.string,
    productCardClick: PropTypes.func,
    redirectAfterProductCardClick: PropTypes.func,
    hideDescription: PropTypes.bool,
    images: PropTypes.array,
    showRatingSection: PropTypes.bool,
    ecoFeeTitleChange: PropTypes.func,
    skus: PropTypes.array,
    currentPrice: PropTypes.any,
    originalPrice: PropTypes.any,
    language: PropTypes.string,
    feeTitle: PropTypes.string,
    rebate: PropTypes.any,
    rebateIcon: PropTypes.string,
    tireCategory: PropTypes.string,
    roadRating: PropTypes.any,
    isFitmentRequired: PropTypes.bool,
    specification: PropTypes.any,
    setOfFourLabel: PropTypes.string,
    perTireLabel: PropTypes.string,
    vehicleFrontLabel: PropTypes.string,
    vehicleRearLabel: PropTypes.string,
    roadRatedLabel: PropTypes.string,
    vehicleFitType: PropTypes.any,
    eachLabel: PropTypes.string,
    badgePriorities: PropTypes.any,
    badges: PropTypes.array,
    hideDisclaimer: PropTypes.bool,
    isAutoPartPlp: PropTypes.bool,
    a11yStrikeOutPrice: PropTypes.string,
    a11yStrikeOutPriceRange: PropTypes.string,
    a11yCloseIconLabel: PropTypes.string,
    a11yTooltipIcon: PropTypes.string,
    fitmentTypeCode: PropTypes.any,
    vehicleList: PropTypes.array,
    options: PropTypes.array,
    partNumber: PropTypes.string,
    fitmentNotes: PropTypes.array,
    productWheelType: PropTypes.string,
    feeDisclaimerMessage: PropTypes.string,
    nowFromLabel: PropTypes.string,
    saveFromLabel: PropTypes.string,
    wasFromLabel: PropTypes.string,
    fromLabel: PropTypes.string,
    promotionalPriceLabel: PropTypes.string,
    unitPriceLabel: PropTypes.string,
    displayWasLabel: PropTypes.bool,
    clearancePriceLabel: PropTypes.string,
    discount: PropTypes.any,
    feeDisclaimerType: PropTypes.string,
    isSRPPage: PropTypes.bool,
    feeValue: PropTypes.number,
    priceMessage: PropTypes.array,
    vehicleInformationPresent: PropTypes.bool,
    feeDisclaimerTitle: PropTypes.string,
    tireInformationPresent: PropTypes.bool,
    scrollToFooter: PropTypes.func,
    isWheelOrTirePDP: PropTypes.bool,
    inStockLabel: PropTypes.string,
    inStockOnlineLabel: PropTypes.string,
    outOfStockLabel: PropTypes.string,
    inStorePurchaseLabel: PropTypes.string,
    inStockAisleLabel: PropTypes.string,
    checkAvailabilityLabel: PropTypes.string,
    checkOtherStoresLabel: PropTypes.string,
    isUseLegacyLogicOfVehicleCheckAvailability: PropTypes.bool,
    partNumberLabel: PropTypes.string,
    exclude: PropTypes.array,
    overridePriceHeight: PropTypes.bool,
    a11yReviewRating: PropTypes.string,
    url: PropTypes.string,
    feeMessages: PropTypes.array,
    productCardSpecifications: PropTypes.string,
    isUrgentLowStock: PropTypes.bool,
    isUrgentLowStockLabel: PropTypes.string,
    totalCurrentPrice: PropTypes.any,
    imageDataComponentName: PropTypes.string,
    attributePositionLabel: PropTypes.string,
    suggestedQuantityLabel: PropTypes.string,
    attributeUsageLabel: PropTypes.string,
    isScrollingRequired: PropTypes.bool,
    returnPolicy: PropTypes.func,
    isLazyRequire: PropTypes.bool,
    product: PropTypes.object.isRequired as Validator<ProductCardType>,
    productDataId: PropTypes.string,
    a11yClickToReadFootnote: PropTypes.string,
    showAvailabilityMessage: PropTypes.bool,
    sponsoredLabel: PropTypes.string,
}

VehicleProductCard.displayName = 'VehicleProductCard'

export default VehicleProductCard
