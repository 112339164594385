import { Dispatch } from 'redux'

import httpClient from '../utils/httpClient'
import { fetchTncContent, fetchFrTncContent } from '../actionCreators'

export const getTncContent =
    (payload: string) =>
    (dispatch: Dispatch): Promise<void> => {
        return httpClient
            .apiGet(payload, {}, 'AEM_EXP_FRAG')
            .then(data => {
                dispatch(fetchTncContent(data.data))
            })
            .catch(() => {
                // eslint-disable-next-line no-warning-comments
                // TODO: implement the error logic once there is a requirement
            })
    }

export const getFrTncContent =
    (payload: string) =>
    (dispatch: Dispatch): Promise<void> => {
        return httpClient
            .apiGet(payload, {}, 'AEM_EXP_FRAG')
            .then(data => {
                dispatch(fetchFrTncContent(data.data))
            })
            .catch(error => {
                // eslint-disable-next-line no-console
                console.log(error)
            })
    }
