import React from 'react'
import { Url } from './StackedCategoryListNew.type'
import { getLanguage } from '@nl/lib/src/utils'
import { stackedCategoryIdentifier } from './StackedCategoryListNew.constant'
import { FORWARD_SLASH } from '@nl/lib'

/**
 * function to create category shop all link
 * @param {string} categoryId
 * @param {string | Url} categoryUrl
 * @param {string} shopAllPlaceholderUrl
 * @param {boolean} enableShopAllLabel
 * @param {string[]} shopAllExclusionIds
 * @param {string} shopAllLinkLabel
 * @param {string} ariaLabelShopAll
 * @param {string} shopAllLinkStyle
 * @returns {JSX.Element} return the shop all link if enableShopAllLabel is true and not part of exclusion Ids from AEM
 */
const language = getLanguage()

export const categoryShopAllLink = (
    categoryId: string,
    categoryUrl: string | Url,
    shopAllPlaceholderUrl: string,
    enableShopAllLabel: boolean,
    shopAllExclusionIds: string[],
    shopAllLinkLabel: string,
    ariaLabelShopAll: string,
    shopAllLinkStyle: string,
    sectionTitle?: string,
    handleKeyDown?: (event: React.KeyboardEvent<HTMLElement>) => void,
    // eslint-disable-next-line max-params
): JSX.Element | null => {
    const shopAllUrl =
        shopAllPlaceholderUrl &&
        categoryUrl
            .toString()
            .replace(
                `${FORWARD_SLASH}${language}${FORWARD_SLASH}cat${FORWARD_SLASH}`,
                `${FORWARD_SLASH}${language}${FORWARD_SLASH}${shopAllPlaceholderUrl}${FORWARD_SLASH}cat${FORWARD_SLASH}`,
            )
    return enableShopAllLabel && !shopAllExclusionIds?.includes(categoryId) ? (
        <a
            className={shopAllLinkStyle}
            aria-label={ariaLabelShopAll}
            data-automationid={`${stackedCategoryIdentifier}${categoryId}`}
            onKeyDown={handleKeyDown}
            href={shopAllUrl}
            dap-wac-link={'true'}
            dap-wac-loc={sectionTitle}
            dap-wac-value={`${ariaLabelShopAll} ${shopAllLinkLabel}`}>
            {shopAllLinkLabel}
        </a>
    ) : null
}

export default categoryShopAllLink
