/**
 * Don't create multiple exports in this file.
 * Use below object and add your constants in it as a key.
 * If your constant is a common name, add it under labels key.
 */
// eslint-disable-next-line no-warning-comments
// TODO: need to organize this file
export const analyticsAttributes = {
    event: {
        interaction: 'interaction',
        addToCart: 'addToCart',
        mergeSharedCart: 'merge-cart-complete',
        checkout: 'checkout',
        error: 'error',
        removeFromCart: 'removeFromCart',
        promoView: 'promoView',
        promoClick: 'promoClick',
        facetFilterAction: 'searchInteraction',
        signout: 'signout',
        signIn: 'signin',
        removeQuantity: 'Quantity Removed',
        warning: 'warningModal',
        view: 'view',
        productDetailView: 'productDetailView',
        search: 'search',
        demoVideo: 'video',
        filter: 'filter',
        productImpression: 'productImpression',
        productClick: 'productClick',
        purchase: 'purchase',
        vehicleInteraction: 'vehicleInteraction',
        mergeWishlist: 'mergeWishlist',
        selectVariant: 'selectVariant',
        mergeCart: 'mergeCart',
        eflyer: 'eflyer',
        estimatedSF: 'estimated_shipping_fees',
        video: 'video',
        filterInteraction: 'filterInteraction',
        aoaBookingForm: 'auto_appointment_form',
        aoaBookingFormSubmit: 'auto_appointment_form_submit',
        shippingInfo: 'shippingInfo',
        otpPrompt: 'otpPrompt',
    },
    aoaBookingFormSteps: ['Location', 'Services', 'Date And Time', 'Information'],
    eventParameters: {
        action: {
            updateUserProfile: 'update user profile',
            viewSuccessMessage: 'view success message',
            viewErrorMessage: 'view error message',
            viewError: 'view error',
            postalCodeUpdate: 'postal code updated',
            filterSelected: 'filter selected',
            clearAllFilter: 'filter/sort',
            openFilterPanel: 'filter panel open',
            signout: 'sign out',
            tfa: 'two-factor authentication displayed',
            mergeCard: 'merge card',
            addressValidation: 'canada post address validation',
            resetPasswordAction: 'reset password',
            signInEmail: 'sign in with confirmation email',
            signInTriangle: 'sign in with triangle',
            createAccount: 'create an account',
            viewWarningMessage: 'modal displayed',
            loginModalLabel: 'login modal displayed',
            viewStoreList: 'view store list results',
            viewStoreMap: 'view store map results',
            setPreferredStore: 'set as preferred store',
            searchStore: 'search for a store',
            filterStores: 'filter stores',
            storeLocator: 'store locator',
            clickOn: 'click on',
            link: 'link',
            megaNav: 'mega nav click',
            offerFilter: 'offer filter/sort',
            accordionClick: 'accordion click',
            viewMedia: 'view media',
            viewMediaModal: 'view media modal',
            changeResultsLayout: 'change results layout',
            videoPlay: 'video play',
            suggestedSearchTerm: 'suggested search term',
            shopByCategory: 'shop by category',
            modalDisplayed: 'modal displayed',
            viewOrderDetails: 'view order details',
            saveWishlist: 'Save Wishlist',
            mySalesAlert: 'My Sales Alert',
            shopByVehicleAdded: 'shop by vehicle added',
            shopByTireSize: 'shop by tire size',
            shopWithNoVehicle: 'shop with no vehicle',
            vehicleUpdated: 'vehicle updated',
            canadaPostSuggestionClicked: 'canada post address suggestion clicked',
            shopByVehicleDisplayed: 'shop by vehicle displayed',
            selectVehicle: 'select vehicle',
            productNotFitForVehicle: 'product does not fit vehicle',
            shopByTireSizeDisplayed: 'shop by tire size displayed',
            partialVehicleDisplayed: 'partial vehicle displayed',
            thirdPartyPaymentInitiated: '3rd party payment initiated',
            mergeWishlist: 'merge wishlist',
            thirdPartyPaymentCompleted: '3rd party payment completed',
            thirdPartyPayment: '3rd party payment',
            rewardsRedemption: 'rewards redemption',
            trackOrder: 'track order',
            selectedVariant: 'select variant',
            packageLabel: 'package',
            packageModalDisplay: 'package modal displayed',
            packageStarted: 'package started',
            packageCompleted: 'package completed',
            packageChanged: 'package product changed',
            mergeCart: 'merge cart',
            packageNoResults: 'package - no results displayed',
            packageNoResultCtaClick: 'package - no results - cta click',
            packageRestarted: 'package restarted',
            estimatedSFLinkClick: 'estimated shipping fees link click',
            estimatedSFModalClick: 'estimated shipping fees modal click',
            addedToWishlistBySalesAlertsCta: 'add',
            removedFromWishlistBySalesAlertsCta: 'remove',
        },
        category: {
            account: 'account',
            cart: 'cart',
            wishlist: 'ecommerce',
            checkout: 'checkout',
            store: 'store',
            header: 'header',
            storeDetails: 'store detail',
            siteSearch: 'site search',
            orderDetail: 'order details',
            vehicleType: 'vehicle',
            payment3rdParty: '3rd party payment',
            eCommerce: 'ecommerce',
            shipping: 'shipping',
            storeView: undefined,
        },
        labels: {
            update: 'update',
            userInitiated: 'user initiated - started',
            userCompleted: 'user initiated - completed',
            cartWishlist: 'wishlist',
            serviceCartList: 'add to cart flyout',
            clearAllFilter: 'clear all',
            mergeSuccess: 'merge card successful',
            addressValidationOnOpen: 'validation displayed',
            originalAddressSelected: 'original address selected',
            otherAddressSelected: 'canada post address selected',
            forcePwdDisplayLabel: 'forced reset - displayed',
            forcePwdCompletedLabel: 'forced reset - completed',
            dontRemember: 'remember me: false',
            rememberMe: 'remember me: ',
            withTriangle: 'with triangle',
            pdpAddToCart: 'pdp add to cart',
            miniPdpAddToCart: 'mini pdp add to cart',
            shopTheLookAddToCart: 'shop the look add to cart',
            checkoutSignIn: 'checkout sign-in',
            userInput: 'user inputted',
            geolocation: 'geolocation',
            selected: 'selected',
            deselected: 'deselected',
            enabled: 'enabled',
            cleared: 'cleared',
            available: 'available',
            photo: 'photo',
            errorMessage: 'verification email expired',
            orderExpired: 'Order Expired',
            viewStoreDetails: 'View Store Details',
            shippingAddressLabel: 'shipping address',
            removeFromWishlist: 'remove from wishlist',
            masterpass: 'masterpass',
            paypal: 'paypal',
            savePaymentMethod: 'save payment method',
            redemptionSelected: 'redemption selected',
            redemptionDeselected: 'redemption deselected',
            redemptionAmountIncreased: 'redemption amount increased',
            redemptionAmountDecreased: 'redemption amount decreased',
            invalidAmount: 'invalid amount',
            saveShippingAddress: 'save shipping address',
            saveBillingAddress: 'save billing address',
            saveCreditCard: 'save credit card',
            billingAddressLabel: 'billing address',
            frequentlyBoughtTogether: 'FrequentlyBoughtTogether',
            addToWishlist: 'add to wishlist',
            ctMoneyLabel: 'CT money',
            noTiresOrWheelsThatFit: 'unfortunately no tire or wheels that fit',
            noTiresThatFit: 'unfortunately no tires that fit',
            noWheelsThatFit: 'unfortunately no wheels that fit',
            estimatedSFlink: 'link',
            estimatedSFContinue: 'continue',
            estimatedSFCancel: 'cancel',
            clickableBadge: {
                CLEARANCE: 'clearance',
                PROMO: 'Sale',
                HOTDEAL: 'Hot Deal',
            },
            fieldRequired: 'This field is required',
            plpAddToCart: 'plp add to cart',
            recommendationAddToCart: 'recommendation add to cart',
            productAddToWishlist: 'add_to_wishlist',
        },
        location: {
            buyBox: 'Buy Box',
            cartItem: 'Cart Item',
            servicesLocation: 'add to cart flyout',
            filterPanel: 'filter panel',
            header: 'header',
            cartLocation: 'add to cart',
            shoppingInformation: 'shipping information',
            shoppableAddToCart: 'shoppable component',
            page: 'page',
            modal: 'modal',
            nearbyAvailabilityModal: 'nearby availability modal',
            storeLocatorPage: 'store locator page',
            buyBoxLocation: 'buyBox',
            mergeCart: 'merge cart',
            secondaryNavigation: 'Secondary Navigation',
            myAccount: 'My account',
            nearByStores: 'near By stores',
            defaultStore: 'default',
            orderHistory: 'orderHistory',
            orderDetailLocation: 'StoreTooltip',
            orderSummaryPage: 'OrderSummary',
            vehicleSelector: 'vehicle selector',
            softRecallModal: 'soft recall modal',
            banner: 'banner',
            wishlist: 'wishlist page',
            siteMap: 'siteMap',
            packageModal: 'package modal',
            createPackage: 'create package',
            editProduct: 'edit product',
            addPackage: 'buyBox',
            mergeModal: 'merge modal',
            login: 'login',
            unfinishedPackageCard: 'unfinished package card',
            shoppingCartItems: 'shopping cart items',
            product: 'product',
            ssoLogin: 'ssoLogin',
            finishLoginFromCLP: 'finishLoginFromCLP',
            salesAlertsLocation: 'sales alerts',
            miniPDP: 'mini PDP',
            plpSrp: 'PLP/SRP',
            certona: 'certona',
        },
        stepAction: {
            initiated: 'initiated',
            completed: 'completed',
        },
        selection: {
            defaultSelection: 'default',
            userSelected: 'user selected',
        },
    },
    promotion: {
        creative: 'Loyalty offers',
        componentType: 'Offers grid',
        activeLabel: 'activated',
        activateLabel: 'not activated',
        offerActivationNotRequired: 'N',
    },
    instantMCPromotion: {
        creative: 'Triangle MC Banner',
        componentType: 'Promotion banner',
        cta: 'Apply now',
    },
    linkTypes: {
        external: 'external',
        internal: 'internal',
    },
    list: {
        productList: 'product listing page',
        digitalFlyer: 'e flyer',
    },
    level: {
        auto: 'auto',
        automotive: 'automotive',
        genMerch: 'gen merch',
        generalMerch: 'general merch',
        criticalFitment: 'critical fitment',
    },
    products: {
        rebate: {
            mailInRebate: 'mail-in rebate',
            instantRebate: 'instant rebate',
        },
        feeCharges: {
            coreCharge: 'core charge',
            ecoFee: 'eco fee',
            recycleFee: 'recycle fee',
        },
        wishlist: 'wishlist',
        notApplicableText: 'Not applicable',
    },
    estimatedShipping: {
        success: 'success',
        outRange: 'out of range',
        invalid: 'invalid',
        list: 'estimated shipping fees link',
    },
}
/**
 * Dom selectors to be used for analytics purpose like for CTAs selection, promo selection etc.
 */
export enum AnalyticsDomSelector {
    promoContainer = "div[data-analytics-type='promoContainer']",
    promo = "div[data-analytics-type='promo'], li[data-analytics-type='promo']",
    promoCta = "a[data-analytics-type='promoCta'], button[data-analytics-type='promoCta']",
}
/**
 * Partial authentication modal triggered by below actions
 */
export const actionPerformed = {
    applyInstantCredit: 'Apply for Instant Credit',
    saveCreditCard: 'Save Credit Card',
    redeemCTMoney: 'Redeem CT Money',
    viewOrderHistory: 'View order History',
    saveCommunicationPreference: 'Save Communication preference',
    viewShoppingPreference: 'View shopping Preference',
    saveShoppingPreferences: 'Save Shopping Preferences',
    EditPaymentInformation: 'Edit Payment information',
    saveAddress: 'Save billing and shipping Address',
    saveShippingAddress: 'Save Shipping Address',
    saveBillingAddress: 'Save Billing Address ',
    saveProfile: 'Save Profile',
    saveChangedPassword: 'Save Changed Password',
    linkCardFromRewards: 'Link Card from rewards',
    mergeRewards: 'Merge Rewards',
}
/**
 * It will use for logged in statuses
 */
export enum UserLoggedInStatus {
    anonymousUser = 'anonymous',
    partialLoggedInUser = 'partial',
    loggedInUser = 'logged in',
}
