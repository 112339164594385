import { areAllParamsValid, isArrayNotEmpty } from '@nl/lib'
import { MagicNumber } from '../analytics/analytics.type'
import { PencilBannerGlobalProps } from '../components/AccountsPencilBanner/PencilBanner/PencilBanner.type'
import {
    NavigationCategory,
    SecondaryNavigationWrapperProps,
} from '../components/SecondaryNavigation/SecondaryNavigationWrapper.type'
import { CategoryResponseById, CategorySessionStorage } from '../redux/models/category.interface'
import { IAccountDashboard, IBreadcrumb, IGlobalLinks, IGlobalProps } from '../redux/models/commonContent.interface'
import { InitialCategoryPayloadType, Category, SaleCategoriesType } from '../redux/models/secondaryNavigation.interface'
import sessionStorageService from '../utils/sessionStorageService'
import { salesCategoryTypes } from './headerNavigationConstants'

/**
 * Function to sort NavigationData.
 * @param {InitialCategoryPayloadType[]} data - data to be sorted.
 * @returns {InitialCategoryPayloadType[] | null} - sorted data.
 */
const sortNavigationData = (data: InitialCategoryPayloadType[]): InitialCategoryPayloadType[] => {
    if (Array.isArray(data)) {
        data.sort((a, b) => a.name.localeCompare(b.name))
        data.forEach(item => {
            if (Array.isArray(item.subcategories)) {
                sortNavigationData(item.subcategories)
            }
        })
    }
    return data
}

/**
 * Header Navigation helper
 */
class HeaderNavigationHelper {
    /**
     * Function to transform l1 categories into updated format.
     * @param {InitialCategoryPayloadType[]} previousReduxState - previous redux state
     * @param {Category[]} apiResponse - api response
     * @param {IGlobalProps} commonContentState - common content state
     * @param {string} shopAllLabel - shop all label
     * @param {boolean} isSortAlphabetically - sort alphabetically
     * @returns {InitialCategoryPayloadType[] | null} - updated mega nav data
     */
    static transformApiResponse(
        previousReduxState: InitialCategoryPayloadType[],
        apiResponse: Category[],
        commonContentState: IBreadcrumb,
        shopAllLabel: string,
        isSortAlphabetically?: boolean,
    ): InitialCategoryPayloadType[] | null {
        if (!isArrayNotEmpty(previousReduxState) || !isArrayNotEmpty(apiResponse)) {
            return null
        }
        const backToLabel = commonContentState?.backToLabel
        const backToHome = commonContentState?.backToHomeLabel
        apiResponse = isSortAlphabetically ? sortNavigationData(apiResponse) : apiResponse
        const updatedMegaNavData = JSON.parse(JSON.stringify(previousReduxState)) as InitialCategoryPayloadType[]
        ;(apiResponse as InitialCategoryPayloadType[]).forEach(category => {
            const apiCategoryL1 = updatedMegaNavData.find(apiCat => {
                if (apiCat.id === category.id) {
                    category.saleCategories = apiCat?.saleCategories
                    return true
                } else if (apiCat.id === apiCat.name && category?.id) {
                    return (apiCat.url as string)?.includes(category.id)
                } else {
                    return false
                }
            })
            if (apiCategoryL1) {
                apiCategoryL1.name = apiCategoryL1.name ?? category.name
                apiCategoryL1.megaNavTitle = `${shopAllLabel} ${category.name}`
                apiCategoryL1.backLinkLabel = `${backToHome}`
                apiCategoryL1.saleCategories = category?.saleCategories
                apiCategoryL1.categories = category.subcategories
                apiCategoryL1.url = category.url
                apiCategoryL1.image = category.image

                apiCategoryL1.categories?.forEach((apiCatL2: InitialCategoryPayloadType) => {
                    apiCatL2.megaNavTitle = `${shopAllLabel} ${apiCatL2.name}`
                    apiCatL2.backLinkLabel = `${backToLabel} ${apiCategoryL1.name}`
                })
            }
        })
        // insert hot sales and flyers
        // eslint-disable-next-line no-warning-comments
        // TODO: logic need to search hot sales and flyers

        return updatedMegaNavData
    }

    /**
     * Transform additional mega nav props which contains categories.
     * @param {SecondaryNavigationWrapperProps} aemProps - aem props
     * @param {string} shopAllLabel - shop all label
     * @param {string} backToLabel - back to label
     * @param {string} backToMain - back to main label
     * @param {string} autoServiceUrl - auto service url
     * @param {boolean} isMobileOrTablet - is mobile or tablet
     * @returns {InitialCategoryPayloadType[]} - transformed category data
     */
    static transformAemResponse(
        aemProps: SecondaryNavigationWrapperProps,
        shopAllLabel: string,
        backToLabel: string,
        backToMain: string,
        autoServiceUrl: string,
        isMobileOrTablet: boolean,
    ): InitialCategoryPayloadType[] {
        const reduxState: InitialCategoryPayloadType[] = []
        // Prepare CDS based categories
        aemProps.categoriesL1.forEach(category => {
            const categoryState: InitialCategoryPayloadType = HeaderNavigationHelper.transformCategory(
                category,
                shopAllLabel,
                backToLabel,
            )
            categoryState.saleCategories = []
            category?.saleUrl &&
                categoryState.saleCategories.push(
                    HeaderNavigationHelper.prepareSalesCategory(
                        aemProps.saleLabel,
                        aemProps.saleLabel,
                        category?.saleUrl,
                        `${aemProps.megaNavCtaLabel} ${category.name}`,
                        salesCategoryTypes.sale,
                    ),
                )
            category?.clearanceUrl &&
                categoryState.saleCategories.push(
                    HeaderNavigationHelper.prepareSalesCategory(
                        aemProps.clearanceLabel,
                        aemProps.clearanceLabel,
                        category?.clearanceUrl,
                        `${aemProps.megaNavCtaLabel} ${category.name}`,
                        salesCategoryTypes.clearance,
                    ),
                )
            category?.newArrivalsUrl &&
                categoryState.saleCategories.push(
                    HeaderNavigationHelper.prepareSalesCategory(
                        aemProps.newArrivalsLabel,
                        aemProps.newArrivalsLabel,
                        category?.newArrivalsUrl,
                        `${aemProps.megaNavCtaLabel} ${category.name}`,
                        salesCategoryTypes.arrival,
                    ),
                )
            category?.needServiceButton &&
                categoryState.saleCategories.push(
                    HeaderNavigationHelper.prepareSalesCategory(
                        aemProps.autoServiceLabel,
                        aemProps.autoServiceLabel,
                        autoServiceUrl,
                        aemProps.bookAppointmentLabel,
                        salesCategoryTypes.autoService,
                    ),
                )
            reduxState.push(categoryState)
        })

        // Prepare AEM based categories
        aemProps.additionalLinksMegaNav?.forEach(category => {
            const categoryState: InitialCategoryPayloadType = HeaderNavigationHelper.transformNestedCategories(
                category,
                shopAllLabel,
                backToLabel,
                backToMain,
            )
            reduxState.push(categoryState)
        })

        /**
         * Updates the state of header props.
         * @param {NavigationCategory[] | undefined} secondaryNavProp - data to be updated in the secondary nav, or undefined if there are no data.
         * @returns {void | undefined}.
         */
        const updateHeaderPropState = (secondaryNavProp: NavigationCategory[] | undefined): void | undefined => {
            secondaryNavProp?.forEach(category => {
                const categoryState: InitialCategoryPayloadType = HeaderNavigationHelper.transformNestedCategories(
                    category,
                    shopAllLabel,
                    backToLabel,
                )
                reduxState.push(categoryState)
            })
        }

        const shouldUpdatePromoLinks = !areAllParamsValid(
            Boolean(aemProps.enablePromoAndFlyerInHamburgerMenu),
            isMobileOrTablet,
        )

        shouldUpdatePromoLinks && updateHeaderPropState(aemProps.dealsLinksCommonMegaNav)
        updateHeaderPropState(aemProps.dealSectionAdditionalLinks)
        shouldUpdatePromoLinks && updateHeaderPropState(aemProps.featuredLinksMegaNavSection)
        updateHeaderPropState(aemProps.additionalLinksNoMegaNav)

        return reduxState
    }

    /**
     * Transform nested props.
     * @param {NavigationCategory} category - category
     * @param {string} shopAllLabel - shop all label
     * @param {string} backToLabel - back to label
     * @param {string} backToMain - back to main label
     * @returns {InitialCategoryPayloadType} - transformed category
     */
    static transformNestedCategories(
        category: NavigationCategory,
        shopAllLabel: string,
        backToLabel: string,
        backToMain?: string,
    ): InitialCategoryPayloadType {
        const categoryState: InitialCategoryPayloadType = HeaderNavigationHelper.transformCategory(
            category,
            shopAllLabel,
            backToLabel,
            backToMain,
        )
        if (category.categories) {
            categoryState.categories = []
            category.categories.forEach(cat1 => {
                const categoryState1: InitialCategoryPayloadType = HeaderNavigationHelper.transformCategory(
                    cat1,
                    shopAllLabel,
                    `${backToLabel} ${category?.name}`,
                )
                if (cat1.categories) {
                    categoryState1.categories = []
                    cat1.categories.forEach(cat2 => {
                        categoryState1.categories?.push(
                            HeaderNavigationHelper.transformCategory(
                                cat2,
                                shopAllLabel,
                                `${backToLabel} ${cat1?.name}`,
                            ),
                        )
                    })
                }
                categoryState.categories?.push(categoryState1)
            })
        }
        return categoryState
    }

    /**
     * Modify the category object
     * @param {NavigationCategory} category - category object
     * @param {string} shopAllLabel - shop all label
     * @param {string} backToLabel - back to label
     * @param {string} backToMain - back to main label
     * @returns {InitialCategoryPayloadType} - modified category object
     */
    static transformCategory(
        category: NavigationCategory,
        shopAllLabel: string,
        backToLabel: string,
        backToMain?: string,
    ): InitialCategoryPayloadType {
        return {
            name: category.name,
            id: category.categoryId || category.name,
            url: (category.url || category.saleUrl) as string,
            style: category.style || 'standard',
            megaNavTitle: `${shopAllLabel} ${category.name}`,
            backLinkLabel: backToMain ? backToMain : `${backToLabel}`,
            needServiceButton: category.needServiceButton ? category.needServiceButton : false,
        } as InitialCategoryPayloadType
    }

    /**
     * Accepts category data and returns transformed data.
     * @param {InitialCategoryPayloadType} category - Category object.
     * @returns {InitialCategoryPayloadType} - Transformed category object.
     */
    static transformToMegaNavDesktopProps(category: InitialCategoryPayloadType): InitialCategoryPayloadType {
        const props: InitialCategoryPayloadType = JSON.parse(JSON.stringify(category)) as InitialCategoryPayloadType
        const categories: Partial<InitialCategoryPayloadType>[][] = []
        if (Array.isArray(props.categories) && props.categories.length > 0) {
            props.categories = props.categories.slice(MagicNumber.ZERO, MagicNumber.TEN)
            for (let i = MagicNumber.ZERO; i < props.categories.length; i++) {
                const column = i % MagicNumber.FIVE
                if (!categories[column]) {
                    categories[column] = []
                }
                const currentCategory = props.categories[i]
                if (currentCategory) {
                    if (Array.isArray(currentCategory.subcategories) && currentCategory.subcategories.length > 0) {
                        currentCategory.categories = HeaderNavigationHelper.limitL3Categories(
                            currentCategory.subcategories,
                        )
                    } else {
                        currentCategory.categories = currentCategory.categories || []
                    }
                    categories[column].push(currentCategory)
                }
            }
            props.categories = categories as unknown as InitialCategoryPayloadType[]
        } else {
            props.categories = []
        }
        return props
    }

    /**
     * This function control max number of categories
     * @param {InitialCategoryPayloadType[]} l3Categories - l3 categories
     * @returns {InitialCategoryPayloadType[]} l3Categories
     */
    static limitL3Categories(l3Categories: InitialCategoryPayloadType[]): InitialCategoryPayloadType[] {
        if (l3Categories.length < MagicNumber.FIVE) {
            return l3Categories
        } else {
            return l3Categories.slice(MagicNumber.ZERO, MagicNumber.FIVE)
        }
    }

    /**
     * Prepare sale category object.
     * @param {string} id - id
     * @param {string} name - name
     * @param {string} url - url
     * @param {string} buttonCtaText - button cta text
     * @param {string} type - type
     * @returns {SaleCategoriesType} - sale category object
     */
    static prepareSalesCategory(
        id: string,
        name: string,
        url: string,
        buttonCtaText: string,
        type: string,
    ): SaleCategoriesType {
        return {
            id,
            name,
            url,
            buttonCtaText,
            type,
        }
    }

    /**
     * Extract pencil banner props from global props.
     * @param {IGlobalProps} commonContentAvailable - global props
     * @returns {PencilBannerGlobalProps} - pencil banner props
     */
    static getPencilBannerGlobalProps = (commonContentAvailable: IGlobalProps): PencilBannerGlobalProps => {
        const { accountDashboard = {} as IAccountDashboard, globalLinks = {} as IGlobalLinks } = commonContentAvailable

        const {
            registerLabel = '',
            registerNowLabel = '',
            authenticatedUserGreetingText = '',
            signOutLabel = '',
            orText = '',
            signInLabel = '',
            cardNumberLabel = '',
            registerNowLink = '',
            cardNumberLabelIcon = '',
            rewardsUpsellingMessage = '',
            rewardsBalanceLabelIcon = '',
            triangleRewardsIcon = '',
            triangleRewardsLabel = '',
            rewardsRegisterRichText = '',
            rewardsBalanceLabel = '',
            backToMainLabel = '',
        } = accountDashboard

        const { loginPageLink, registrationPageLink } = globalLinks

        return {
            signInLink: loginPageLink,
            registerLink: registrationPageLink,
            authenticatedUserGreetingText,
            signOutLabel,
            orText,
            signInLabel,
            registerLabel,
            registerNowLabel,
            triangleRewardsIcon,
            triangleRewardsLabel,
            rewardsRegisterRichText,
            rewardsBalanceLabel,
            backToMainLabel,
            cardNumberLabel,
            registerNowLink,
            cardNumberLabelIcon,
            rewardsBalanceLabelIcon,
            rewardsUpsellingMessage,
        }
    }

    /**
     * function to crete session storage data for categories
     * @param {CategoryResponseById} data - category data
     * @param {string} expires - expires
     * @param {string} date - date
     * @param {string} cacheControl - cache control
     * @returns {CategorySessionStorage} - category session storage
     */
    static prepareCategorySessionStorage = (
        data: CategoryResponseById,
        expires: string,
        date: string,
        cacheControl: string,
    ): CategorySessionStorage => {
        const expiringTime = new Date(expires).getTime()
        const currentDate = new Date(date).getTime()
        // sample response cache-control: public,max-age=86400 OR cache-control: max-age=0, no-cache, no-store
        const maxAgeInSeconds = cacheControl?.split('=')[MagicNumber.ONE]?.split(',')[0]
        const maxAgeInMiliSecond = Number(maxAgeInSeconds) * MagicNumber.ONETHOUSAND
        return {
            categoryData: data,
            expiryTimestamp: expiringTime,
            maxAge: maxAgeInMiliSecond + currentDate,
        }
    }

    /**
     * function to get category data from session storage
     * @param {string} storageKey - storage key
     * @returns {CategorySessionStorage} - category session storage
     */
    static getCategoryDataFromSessionStorage = (storageKey: string): CategorySessionStorage => {
        let expiryTimestamp
        let maxAge
        let categoryDataParsed = {} as CategorySessionStorage
        const categoryData = sessionStorageService.getItem(storageKey)
        if (categoryData) {
            categoryDataParsed = JSON.parse(categoryData) as CategorySessionStorage
            expiryTimestamp = categoryDataParsed.expiryTimestamp
            maxAge = categoryDataParsed.maxAge
        }
        return {
            categoryData: categoryDataParsed.categoryData,
            expiryTimestamp: expiryTimestamp as number,
            maxAge: maxAge as number,
        }
    }
}

export { HeaderNavigationHelper }

export default HeaderNavigationHelper
