import React, { useEffect } from 'react'

import { fetchStoreDetails } from '../../redux/actions/storeDetails.action'
import { checkDataLength } from '../Accounts/Addresses/checkDataLength'
import { updateUrlHistory } from '@nl/lib'
import { MagicNumber } from '../../analytics/analytics.type'
import { getStoreId } from '../StoreDetails/StoreDetail.helper'
import { preferredStoreDetailsSelector, storeDetailsDataSelector } from '../../redux/selectors/storeDetails.selectors'
import appCacheService from '../../utils/appCacheService'
import { fetchStoreDetailsSuccessAction } from '../../redux/actionCreators'
import { SeoHelper } from '../../Seo/helpers/Seo.helper'
import { httpStatusCodes } from '../../globalConstants'
import { secondaryNavPropsSelector } from '../../redux/selectors/headerProps.selectors'
import { useAppDispatch, useAppSelector } from '../../hooks/react-redux.hook'

/**
 * StoreDetailPageInit to invoke store details api
 * @return {null}
 */

const StoreDetailPageInit: React.FC = () => {
    const { storeDetails, StoreErrorResponse } = useAppSelector(storeDetailsDataSelector)
    const preferredStoreDetails = useAppSelector(preferredStoreDetailsSelector)
    const secondaryNavProps = useAppSelector(secondaryNavPropsSelector)
    const { enablePrerenderMetaTagStore } = secondaryNavProps || {}
    const preferredStoreId = appCacheService.preferredStoreId.get()
    const queryParams = window.location.search

    const storeId = getStoreId(queryParams)

    const dispatch = useAppDispatch()

    useEffect(() => {
        if (storeId && preferredStoreId !== storeId) dispatch(fetchStoreDetails(storeId))
        else dispatch(fetchStoreDetailsSuccessAction(preferredStoreDetails))
    }, [dispatch, storeId, preferredStoreId, preferredStoreDetails])

    useEffect(() => {
        if (checkDataLength(storeDetails)) {
            const { url } = storeDetails
            const currentPath = window.location.pathname
            if (!!url && url !== currentPath) {
                updateUrlHistory(url)
                const prerenderHeaderURL = window.location.origin + url
                if (enablePrerenderMetaTagStore) {
                    SeoHelper.createMetaTag('name', 'prerender-status-code', String(httpStatusCodes.redirectCode))
                    SeoHelper.createMetaTag('name', 'prerender-header', `Location: ${prerenderHeaderURL}`)
                }
            }
        }
    }, [storeDetails, enablePrerenderMetaTagStore])

    useEffect(() => {
        if (checkDataLength(StoreErrorResponse) && StoreErrorResponse?.status === MagicNumber.FOURHUNDREDFOUR) {
            window.location.href = encodeURI(window.ODP?.globalLinks.pageNotFoundPath)
        }
    }, [StoreErrorResponse])

    return null
}

export default StoreDetailPageInit
