// eslint-disable-next-line no-warning-comments
// TODO: props need to be added
import React from 'react'
import Skeleton from 'react-loading-skeleton'

import { PREFIX } from '../config'
import { SkeletonProps } from './Skeleton.type'
import PropTypes from 'prop-types'
/**
 * Skeleton component to integrate as a placeholder in the app/lib components until the response data is loaded
 * For animation, duration can be set with seconds
 * SkeletonTheme can be used from the library and color/highlight color props needs to be passed as a wrapper
 * @param {Object} props - props for the component
 * @return {JSX.Element}
 */

const SkeletonComponent: React.FC<SkeletonProps> = ({ ...props }): JSX.Element => {
    const { count, isCircle, height, width, duration, skeletonClass, dataTestId } = props
    return (
        <Skeleton
            className={`${PREFIX}-skeleton ${String(skeletonClass)}`}
            count={count}
            circle={isCircle}
            height={height}
            width={width}
            duration={duration}
            inline={true}
            containerTestId={dataTestId}
        />
    )
}

SkeletonComponent.propTypes = {
    isCircle: PropTypes.bool,
    skeletonClass: PropTypes.string,
    count: PropTypes.number,
    duration: PropTypes.number,
    width: PropTypes.string || PropTypes.number,
    height: PropTypes.string || PropTypes.number,
}
SkeletonComponent.displayName = 'SkeletonComponent'

export default SkeletonComponent
