import React from 'react'
import {
    buildEstematedDeliveryDateSplitQty,
    getEstimatedDeliveryDateLabelAvailability,
    isPackageMainProduct,
    isProductFromPackage,
} from './ProductCartItem.helper'
import { CartOrderDeliveryModes, ProductSourceTypes } from './ProductItemInfo.type'
import { checkNotNullAndUndefined } from '../../utils/checkNotNullAndUndefined'
import {
    CartItemFulfillmentSource,
    getSourceTypeData,
    magicNumber,
    replaceMultipleStrWithDynamicVal,
} from '../../utils'
import {
    checkIsEstimatedDeliveryDateAvailableNotSplitQty,
    checkIsEstimatedDeliveryDateAvailableSplitQty,
    checkIsOrderFromInStoreAndDC,
    isShowEstimatedDeliveryDateLabel,
} from './ProductCartItem'
import { STHDeliveryLabelsProps } from './STHDeliveryLabels.type'
import { EMPTY_STRING } from '../../globalConstants/global.constant'

/**
 * STHDeliveryLabels component renders Ship To Home delivery labels like 'Ready for...'
 * @param {STHDeliveryLabelsProps} props of component
 * @returns {JSX.Element | null} Returns STHDeliveryLabels component
 */
const STHDeliveryLabels = (props: STHDeliveryLabelsProps): JSX.Element | null => {
    const {
        cart,
        productData,
        isBulk,
        isShowedEstimatedDeliveryDateBetween,
        selectedDeliveryMode,
        singleShippingMethodOption,
        renderAllAvailabilityInfo,
        isShoppingCartPage,
        isOrderConfirmationPage,
        isLimitedStockAndAvailableInDc,
        receiveBetweenLabel,
        betweenLabelSTH,
        getETAForRange,
        getBetweenSTHLabel,
        etaTimeOfDelivery,
        estimatedDeliveryDateBetween,
    } = props
    const { fulfillment } = productData
    const estimatedDeliveryDate = isShoppingCartPage
        ? productData.estimatedDeliveryDate
        : {
              startDate: productData.eddMin,
              endDate: productData.eddMax,
          }
    const isExpressDeliveryModeForOrder = productData?.fulfillment?.deliveryMode === CartOrderDeliveryModes.EXPRESS

    /**
     * Checks if product belongs to some package but not the main in it
     * @returns { boolean } returns flag
     */
    const isPackageNotMainItem = (): boolean => {
        const isPackageItem = isProductFromPackage(cart?.sthPackages, productData)
        const isMainProductInPackage = isPackageMainProduct(cart?.sthPackages, productData)

        return isPackageItem && !isMainProductInPackage
    }

    /**
     * function to set between label for etas for order confirmation and order detail page
     * @param {CartItemFulfillmentSource} storePickup
     * @returns {string}
     */
    const getBetweenLabel = (storePickup: CartItemFulfillmentSource): string => {
        const modifiedBetweenLabel = betweenLabelSTH?.split('-')[magicNumber.ZERO]
        return storePickup.etaEarliest && storePickup.etaLatest ? betweenLabelSTH : modifiedBetweenLabel
    }

    const createLi = (cartItemFulfillmentSource: CartItemFulfillmentSource) => {
        return (
            <li>
                {replaceMultipleStrWithDynamicVal(
                    props.isShoppingCartPage ? receiveBetweenLabel : getBetweenLabel(cartItemFulfillmentSource),
                    [
                        cartItemFulfillmentSource.quantity?.toString(),
                        getETAForRange(cartItemFulfillmentSource.etaEarliest),
                        getETAForRange(cartItemFulfillmentSource.etaLatest),
                    ],
                )}
            </li>
        )
    }

    /**
     * Renders estimated delivery date label
     *
     * @param {CartItemFulfillmentSource} cartItemFulfillmentSource
     * @return {JSX.Element} returns estimated delivery date label
     */
    const rendersEstimatedDeliveryDate = (
        cartItemFulfillmentSource?: CartItemFulfillmentSource,
    ): JSX.Element | null => {
        const quantity = cartItemFulfillmentSource?.quantity?.toString()
        return (
            <li
                dangerouslySetInnerHTML={{
                    __html: quantity
                        ? buildEstematedDeliveryDateSplitQty(quantity, estimatedDeliveryDateBetween)
                        : estimatedDeliveryDateBetween || EMPTY_STRING,
                }}
            />
        )
    }

    /**
     * Renders range ETA label
     * @param {CartItemFulfillmentSource} storePickupDC - fulfillment data
     * @param {CartItemFulfillmentSource} storePickupInStore - fulfillment data
     * @param {boolean} isEstimatedDeliveryDateAvailable - estimated delivery date availability flag
     * @param {boolean} isOrderFromInStoreAndDC - additional flag
     * @returns Range ETA element
     */
    const renderETAForRange = (
        storePickupDC: CartItemFulfillmentSource,
        storePickupInStore: CartItemFulfillmentSource,
        isEstimatedDeliveryDateAvailable: boolean,
        isOrderFromInStoreAndDC: boolean,
    ): JSX.Element => {
        return (
            <>
                {checkIsEstimatedDeliveryDateAvailableSplitQty(
                    isEstimatedDeliveryDateAvailable,
                    isOrderFromInStoreAndDC,
                )
                    ? rendersEstimatedDeliveryDate(storePickupInStore)
                    : createLi(storePickupInStore)}
                {createLi(storePickupDC)}
            </>
        )
    }

    const renderETAInfo = (etaTime: string): JSX.Element | null => {
        return etaTime ? (
            <li>
                <b>{`${getBetweenSTHLabel()} ${getETAForRange(etaTime)}`}</b>
            </li>
        ) : null
    }

    const renderSTHBetweenRangeLabel = (): JSX.Element => {
        const { sourceTypes = [] } = fulfillment || {}
        const storePickupDC = getSourceTypeData(sourceTypes, ProductSourceTypes.DC)

        return (
            <li>{`${getBetweenSTHLabel()} ${getETAForRange(storePickupDC.etaEarliest)} - ${getETAForRange(
                storePickupDC.etaLatest,
            )}`}</li>
        )
    }

    const checkOnlyDCStock = (
        dcQuantity: number,
        inStoreQuantity: number,
        sourceDC: CartItemFulfillmentSource,
        sourceStore: CartItemFulfillmentSource,
    ): boolean => {
        return props.isShoppingCartPage
            ? Boolean(dcQuantity && !inStoreQuantity)
            : Boolean(!Object.keys(sourceStore).length && Object.keys(sourceDC).length)
    }

    const { dcQuantity, storeQuantity: currentSelectedStoreQuantity } = fulfillment?.stockItemAvailability || {}
    const isEstimatedDeliveryDateAvailable = getEstimatedDeliveryDateLabelAvailability(
        props.productData.badges,
        ProductSourceTypes.INSTORE,
        isBulk,
        fulfillment,
        isShowedEstimatedDeliveryDateBetween,
        !checkNotNullAndUndefined(estimatedDeliveryDate),
    )
    const { sourceTypes = [] } = fulfillment || {}
    const storePickupDC = getSourceTypeData(sourceTypes, ProductSourceTypes.DC)
    const storePickupInStore = getSourceTypeData(sourceTypes, ProductSourceTypes.INSTORE)
    const isOrderFromInStoreAndDC = checkIsOrderFromInStoreAndDC(storePickupDC, storePickupInStore)
    const isSTH = selectedDeliveryMode?.isSTH || !selectedDeliveryMode?.isExpress

    const renderSTHLabels = (): JSX.Element => {
        if (singleShippingMethodOption) {
            return renderAllAvailabilityInfo()
        } else if (
            checkIsEstimatedDeliveryDateAvailableNotSplitQty(
                isEstimatedDeliveryDateAvailable,
                isOrderFromInStoreAndDC,
            ) &&
            isShowEstimatedDeliveryDateLabel(isShoppingCartPage, isOrderConfirmationPage, isSTH) &&
            !isExpressDeliveryModeForOrder
        ) {
            return rendersEstimatedDeliveryDate()
        } else if (checkOnlyDCStock(dcQuantity, currentSelectedStoreQuantity, storePickupDC, storePickupInStore)) {
            return renderSTHBetweenRangeLabel()
            // eslint-disable-next-line sonarjs/elseif-without-else
        } else if (isLimitedStockAndAvailableInDc) {
            return renderETAForRange(
                storePickupDC,
                storePickupInStore,
                isEstimatedDeliveryDateAvailable,
                isOrderFromInStoreAndDC,
            )
        }
        return renderETAInfo(etaTimeOfDelivery)
    }

    return isPackageNotMainItem() ? null : renderSTHLabels()
}

export default STHDeliveryLabels
