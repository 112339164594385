import React, { useState, useMemo, useEffect, useCallback, useRef, SetStateAction } from 'react'

import { QuantitySelector, isArrayNotEmpty, libUtils, replaceStrWithDynamicVal } from '@nl/lib'

import { PREFIX } from '../../../../config'
import HandleToast from '../../../BuyBox/HandleToast/HandleToast'
import {
    addProductToCartGuest,
    setStickyBuyboxCTAClicked,
    setIfAllVariantsSelected,
    setStickyBuyBarATCSpinner,
} from '../../../../redux/actions'
import localStorageService from '../../../../utils/localStorageService'
import { analyticsAttributes } from '../../../../globalConstants'
import { AddProductToCartGuestRequestDTO } from '../../../../redux/models/cart.interface'
import { checkDataLength } from '../../../Accounts/Addresses/checkDataLength'
import { createOrderEntriesPayload, getFulfillmentMethod, getIsBulk, isBopis } from '../../../BuyBox/BuyBox.helper'

import { MagicNumber } from '../../../../analytics/analytics.type'
import { storeDetailsDataSelector } from '../../../../redux/selectors/storeDetails.selectors'
import { addRemovePayLoad, WishlistProduct } from '../../../../redux/models/wishlist.interface'
import { wishlistItemsSelector } from '../../../../redux/selectors/wishlist.selectors'
import { commonContentSelector } from '../../../../redux/selectors/commonContent.selectors'
import { IAccessibility, IFeatureFlag } from '../../../../redux/models/commonContent.interface'

import ATCWishlistBtnsComponent from '../../../BuyBox/ATCWishlistBtnsComponent/ATCWishlistBtnsComponent'
import { cartStateSelector } from '../../../../redux/selectors/cart.selectors'
import { addModalAttribute } from '@nl/lib/src/utils'

import { miniPDPFlyoutDetailsSelector } from '../../../../redux/selectors/miniPDPFlyoutData.selectors'
import { ATCQuantityWishlistProps } from './ATCQuantityWishlist.type'
import { closeMiniPDPFlyoutAction, isMiniPDPAtcButtonClicked } from '../../../../redux/actionCreators'
import { isObjectNotEmpty } from '../../../../utils/isObjectNotEmpty'
import { addToCartAnalytics } from '../../../../analytics/components/addToCartAnalytics'
import getPageType from '../../../../utils/getPageType'
import { useAppDispatch, useAppSelector } from '../../../../hooks/react-redux.hook'
import { CriticalFitmentProduct } from '../../../AutomotivePDP/AutomotiveBuyBox/AutomotiveBuyBox.type'
import { ATCWishlistBtnsComponentProps } from '../../../BuyBox/ATCWishlistBtnsComponent/ATCWishlistBtnsComponent.type'

/**
 * ATCQuantityWishlist component
 * @param {ATCQuantityWishlistProps} props - component props
 * @returns {JSX.Element} - ATCQuantityWishlist component
 */
const ATCQuantityWishlist: React.FC<ATCQuantityWishlistProps> = (props: ATCQuantityWishlistProps): JSX.Element => {
    const {
        minQuantity,
        freeShippingEnabled,
        freePickUpInStoreLabel,
        goToPdpButtonLabel,
        variantNotSelectedMessage,
        isAddToCartClicked,
        setIsAddToCartClicked,
        isAddToWishlistClicked,
        setIsAddToWishlistClicked,
        setIsVariantSelected,
        isShipToHome,
        unavailableFulfillmentSection,
        isOOSAtStoreForPickUp,
        selectedFulfillment,
        bopisUnavailable,
        isExpressDelivery,
        isExpressDeliveryEligible,
        isExpressDeliveryEnabledForProduct,
        isSelectAStoreButtonAvailable,
        inStockAtNearbyStores,
        onlineOrdering,
        isBopisOnlySkuWithStoreBopisUnavailable,
        isBopisOnlyUnavailableForPickupInStock,
        isProductDiscontinued,
        isOutOfStockInAllStore,
        isStoreSelected,
        maxQuantityAvailable,
        inStockAtDC,
        quantityExceeded,
        availableForATC,
        qtySelectorErrorMsg,
        setQtySelectorErrorMsg,
        maxPurchasableLimitLabel,
        limitMsgValue,
        showErrorMsgForVariantSelection,
        setShowErrorMsgForVariantSelection,
        isNoSkuFindStoreClicked,
        setIsNoSkuFindStoreClicked,
        qtySelectorInput,
        setQtySelectorInput,
        enableFulfilmentSelection,
    } = props

    const dispatch = useAppDispatch()
    const { commonContentAvailable } = useAppSelector(commonContentSelector)
    const { productDataList, currentCode, isQuickLookFromCertona, isAtcClicked } =
        useAppSelector(miniPDPFlyoutDetailsSelector)
    const { variantsInfo, priceInfo, fulfillmentInfo } = productDataList[currentCode] || {}
    const {
        productData,
        selectedProductCode,
        selectedFirstVariantId,
        selectedSecondVariantId,
        selectedThirdVariantId,
        productSkuData,
        isAllSkusOOS,
        isAllVariantsSelected,
    } = variantsInfo || {}
    const { code, pdpUrl, options } = productData || {}
    const { productDataBySku, isCurrentPriceAvailable } = priceInfo
    const { currentPrice } = productDataBySku || {}
    const { isLimitedPDP, isRestrictedPDP, selectedStoreQty, inStockInSelectedStore, isProductSellable } =
        fulfillmentInfo

    const [showUnavailableFulfillmentToast, setShowUnavailableFulfillmentToast] = useState(false)
    const [shouldShowSelectStoreToast, setShouldShowSelectStoreToast] = useState(false)
    const [toastErrorMessage, setToastErrorMessage] = useState('')

    // getting entire wishlist Data
    const wishlistItems = useAppSelector(wishlistItemsSelector)

    const { addToCartErrorData, cartModifications, finalCartModifications, cartLimitItemsFailure } =
        useAppSelector(cartStateSelector)

    const { selectedStoreName } = useAppSelector(storeDetailsDataSelector)

    const { accessibility = {} as IAccessibility, featureFlag = {} as IFeatureFlag } = commonContentAvailable

    const divRef = useRef<HTMLDivElement>(null)

    const [addToCartErrorMessage, setAddToCartErrorMessage] = useState('')
    const [showSpinner, setShowSpinner] = useState(false)

    /** Check if GUID is available (Cart already created) then call add product to cart api otherwise call create cart api  */
    const addProductToCartAPI = useCallback(() => {
        const cartGUID = localStorageService.getItem('cart-guid') as string
        const deliveryMethod = !enableFulfilmentSelection || isShipToHome

        // eslint-disable-next-line no-warning-comments
        // TODO: payload creation should move to service
        const cartData: AddProductToCartGuestRequestDTO = {
            guid: cartGUID,
            entries: {
                orderEntries: createOrderEntriesPayload(
                    libUtils.getNumberOrDefault(qtySelectorInput),
                    selectedProductCode,
                    productData,
                    undefined as unknown as string[],
                    libUtils.getStringOrDefault(''),
                    deliveryMethod,
                    isExpressDelivery,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    productSkuData,
                    undefined,
                    false,
                ),
            },
        }

        const isBulk = getIsBulk(productData, selectedProductCode)
        dispatch(
            addProductToCartGuest(
                cartData,
                false,
                false,
                true,
                undefined,
                undefined,
                isBulk,
                undefined,
                undefined,
                false,
                false,
                false,
                isQuickLookFromCertona,
                featureFlag?.enableFOMO,
            ),
        )
    }, [
        selectedProductCode,
        enableFulfilmentSelection,
        isShipToHome,
        qtySelectorInput,
        dispatch,
        productData,
        productSkuData,
        isExpressDelivery,
        isQuickLookFromCertona,
    ])

    const addCartClicked = useCallback(
        // eslint-disable-next-line complexity, sonar/cyclomatic-complexity
        (addCartMouseEvent?: React.MouseEvent<Element, MouseEvent> | MouseEvent) => {
            addCartMouseEvent && addModalAttribute(addCartMouseEvent as MouseEvent)
            setIsAddToCartClicked(true)
            setIsAddToWishlistClicked(false)
            setIsNoSkuFindStoreClicked(false)
            let isStoreSelectedValue = true

            if (!isAllVariantsSelected) {
                setShowErrorMsgForVariantSelection(true)
            }

            if (enableFulfilmentSelection) {
                if (unavailableFulfillmentSection() || !availableForATC) {
                    setShowUnavailableFulfillmentToast(true)
                }

                // Check if store is selected from fulfillment section
                if (!inStockInSelectedStore && inStockAtNearbyStores()) {
                    isStoreSelectedValue = !!selectedStoreName
                }

                if (
                    // eslint-disable-next-line sonar/expression-complexity
                    (isBopis(selectedFulfillment, freePickUpInStoreLabel) &&
                        !bopisUnavailable &&
                        isOOSAtStoreForPickUp() &&
                        availableForATC &&
                        isAllVariantsSelected) ||
                    (!freeShippingEnabled &&
                        isExpressDelivery &&
                        isExpressDeliveryEligible() &&
                        isExpressDeliveryEnabledForProduct &&
                        selectedStoreQty === 0)
                ) {
                    setShouldShowSelectStoreToast(true)
                }
            }

            // eslint-disable-next-line no-warning-comments
            // TODO: validation logic can be improved to be more readable. recommend performing all validation checks before call to addProductToCartAPI
            // OCCP-14639: override quantity
            if (
                // eslint-disable-next-line sonar/expression-complexity
                isAllVariantsSelected &&
                isCurrentPriceAvailable &&
                !unavailableFulfillmentSection() &&
                !quantityExceeded &&
                availableForATC &&
                (!!selectedStoreName || inStockInSelectedStore || (inStockAtDC && isStoreSelectedValue))
            ) {
                setShowSpinner(true)
                addProductToCartAPI()
                setIsAddToCartClicked(false)
                dispatch(isMiniPDPAtcButtonClicked(true))
            }

            // Below Dispatch is used to reset the redux variables.
            dispatch(
                setStickyBuyboxCTAClicked({
                    event: null,
                    isClicked: false,
                    clickedCTAName: '',
                }),
            )
        },
        [
            dispatch,
            quantityExceeded,
            selectedStoreName,
            inStockInSelectedStore,
            inStockAtDC,
            addProductToCartAPI,
            freePickUpInStoreLabel,
            selectedStoreQty,
            isCurrentPriceAvailable,
            freeShippingEnabled,
            availableForATC,
            isAllVariantsSelected,
            bopisUnavailable,
            isExpressDelivery,
            enableFulfilmentSelection,
            isExpressDeliveryEligible,
            isExpressDeliveryEnabledForProduct,
            isOOSAtStoreForPickUp,
            isStoreSelected,
            selectedFulfillment,
            setIsAddToCartClicked,
            setIsAddToWishlistClicked,
            unavailableFulfillmentSection,
            setIsNoSkuFindStoreClicked,
            setShowErrorMsgForVariantSelection,
        ],
    )

    /* Check If All Variant Selected For Frequently Brought Together Component */
    useEffect(() => {
        dispatch(setIfAllVariantsSelected(isAllVariantsSelected))
        setIsVariantSelected(isAllVariantsSelected)
    }, [dispatch, setIsVariantSelected, isAllVariantsSelected])

    useEffect(() => {
        const isSameProduct = cartModifications?.entry?.baseProduct === productData.code
        if (isAtcClicked && isSameProduct && isObjectNotEmpty(cartModifications)) {
            const cartGUID = localStorageService.getItem('cart-guid')
            const fulfillmentType = getFulfillmentMethod(isShipToHome, isExpressDelivery)
            addToCartAnalytics(
                finalCartModifications,
                cartGUID,
                analyticsAttributes.eventParameters.location.miniPDP,
                analyticsAttributes.eventParameters.labels.miniPdpAddToCart,
                false,
                false,
                false,
                false,
                undefined,
                undefined,
                fulfillmentType ?? '',
                isQuickLookFromCertona ? analyticsAttributes.eventParameters.location.certona : getPageType(),
            )
            dispatch(closeMiniPDPFlyoutAction())
        }
        setShowSpinner(false)
    }, [addToCartErrorData, cartModifications, cartLimitItemsFailure])

    /**
     * useEffect to set sticky buybar ATC spinner state
     */
    useEffect(() => {
        dispatch(setStickyBuyBarATCSpinner(showSpinner))
    }, [dispatch, showSpinner])

    const pdpLink = useMemo(() => {
        let link = pdpUrl

        if (selectedProductCode !== code) {
            link = link.replace('html', `${selectedProductCode}.html`)
        }

        return selectedFirstVariantId ? `${link}?colorCode=${selectedFirstVariantId}` : link
    }, [pdpUrl, selectedProductCode, code, selectedFirstVariantId])

    // Run the code when the data changes,
    const isAddedToWishlist = useMemo(() => {
        if (checkDataLength(wishlistItems)) {
            // For the first time it is undefined when the page load.
            const wishListProductList = wishlistItems.products
            let exists = false

            // Condition to avoid some function error
            if (isArrayNotEmpty(wishListProductList)) {
                exists = wishListProductList.some((wishListProduct: WishlistProduct) => {
                    return wishListProduct.code === (selectedProductCode || code)
                })
            }
            return exists
        }

        return false
    }, [wishlistItems, selectedProductCode, code])

    const getIsShipToHome = useMemo(() => {
        return enableFulfilmentSelection ? isShipToHome : true
    }, [enableFulfilmentSelection, isShipToHome])

    // Object to be used for analytics
    const { location, labels } = analyticsAttributes.eventParameters

    const productObject: addRemovePayLoad = {
        pcode: code,
        action: isAddedToWishlist ? labels.removeFromWishlist : labels.addToWishlist,
        sku: selectedProductCode,
        value: `${String(currentPrice?.value)}`,
        location: location.miniPDP,
    }
    /**
     * useEffect to set isVariantSelected if all variant 3 options are selected
     */
    useEffect(() => {
        if (options?.length === MagicNumber.TWO && selectedFirstVariantId && selectedSecondVariantId) {
            setIsVariantSelected(true)
        }
        if (selectedFirstVariantId && selectedSecondVariantId && selectedThirdVariantId) {
            setIsVariantSelected(true)
        }
    }, [selectedFirstVariantId, setIsVariantSelected, selectedSecondVariantId, selectedThirdVariantId, options])

    /**
     * useEffect to set isVariantSelected if all variant 3 options are selected
     * @returns {string} - returns primary button type
     */
    const getAtcPrimaryButtonType = (): string => (!isAllVariantsSelected ? 'primary' : 'call_to_action')

    const checkIfRenderATCWishlistButton = useCallback((): boolean => {
        const showATCButton = isAllVariantsSelected ? !isOutOfStockInAllStore : true
        const hasAdditionalDeliveryOptions =
            !enableFulfilmentSelection ||
            (onlineOrdering && !isBopisOnlySkuWithStoreBopisUnavailable && !isBopisOnlyUnavailableForPickupInStock)

        // eslint-disable-next-line sonar/expression-complexity
        return !isAllSkusOOS && hasAdditionalDeliveryOptions && !isLimitedPDP && !isRestrictedPDP && showATCButton
    }, [
        isAllSkusOOS,
        enableFulfilmentSelection,
        isLimitedPDP,
        isRestrictedPDP,
        isAllVariantsSelected,
        isOutOfStockInAllStore,
        isBopisOnlySkuWithStoreBopisUnavailable,
        onlineOrdering,
        isBopisOnlyUnavailableForPickupInStock,
    ])

    const checkIfAllVariantsSelected = useCallback(() => isAllVariantsSelected, [isAllVariantsSelected])
    const checkIsSelectAllOptionToShow = useCallback(() => !isAllVariantsSelected, [isAllVariantsSelected])
    const checkInStockInSelectedStore = useCallback(() => inStockInSelectedStore, [inStockInSelectedStore])
    const checkIsProductDiscontinued = useCallback(() => isProductDiscontinued, [isProductDiscontinued])
    const checkMaxQuantityAvailable = useCallback(() => maxQuantityAvailable, [maxQuantityAvailable])
    const checkInStockAtDC = useCallback(() => inStockAtDC, [inStockAtDC])
    const checkIsRestrictedPDP = useCallback(() => !!isRestrictedPDP, [isRestrictedPDP])
    const checkIsSelectAStoreButtonAvailable = useCallback(
        () => isSelectAStoreButtonAvailable,
        [isSelectAStoreButtonAvailable],
    )

    /**
     * Render Buy Box Component
     * @returns {JSX.Element} - returns buy box component
     */
    const renderBuyBoxComponent = (): JSX.Element => {
        return (
            <div id={`${PREFIX}-buy-box`} className={`${PREFIX}-buy-box`} ref={divRef}>
                <div id={`${PREFIX}-buy-box`} className={`${PREFIX}-buy-box__qty--container`}>
                    {checkIfRenderATCWishlistButton() && (
                        <div className={`${PREFIX}-buy-box__qty-selector`}>
                            <QuantitySelector
                                defaultQty={minQuantity}
                                maximumQuantityError={qtySelectorErrorMsg}
                                maxQty={maxQuantityAvailable}
                                quantityExceeded={quantityExceeded}
                                qtySelectorInput={libUtils.getNumberOrDefault(qtySelectorInput)}
                                setQtySelectorInput={setQtySelectorInput as (number: number | string) => void}
                                addToCartErrorData={addToCartErrorMessage}
                                a11yQuantityLabel={accessibility.a11yQuantityLabel}
                                a11yQuantityDecrementLabel={accessibility.a11yQuantityDecrementLabel}
                                a11yQuantityIncrementLabel={accessibility.a11yQuantityIncrementLabel}
                                a11yMinQuantityReached={accessibility.a11yMinQuantityReached}
                                a11yQuantityInputAriaLabel={accessibility?.a11yQuantityInputAriaLabel}
                                disableErrorIcon={false}
                                limitLabel={
                                    limitMsgValue.current
                                        ? replaceStrWithDynamicVal(maxPurchasableLimitLabel, limitMsgValue.current)
                                        : undefined
                                }
                                limitLabelShow={true}
                                a11yUpdatedQuantityText={accessibility.a11yUpdatedQuantityText}
                            />
                        </div>
                    )}
                    <ATCWishlistBtnsComponent
                        {...({
                            a11yUpdatingWishList: props.a11yUpdatingWishList,
                            addToWishlistInactiveProductErrorMsg: props.addToWishlistInactiveProductErrorMsg,
                            a11yRemoveFromWishList: props.a11yRemoveFromWishList,
                            a11yAddToWishList: props.a11yAddToWishList,
                            addToWishListLabel: props.addToWishListLabel,
                            addedToWishListLabel: props.addedToWishListLabel,
                            wishListNonSellableEnabled: props.wishListNonSellableEnabled,
                            addToPackageCTA: props.addToPackageCTA,
                            buyboxButtonLabel: props.buyboxButtonLabel,
                            selectAllOptionsLabel: props.selectAllOptionsLabel,
                            addCartClicked: addCartClicked,
                            isAddedToWishlist: isAddedToWishlist,
                            criticalFitmentGrouped: { isStaggered: false } as CriticalFitmentProduct,
                            setIsAddToWishlistClicked: setIsAddToWishlistClicked as React.Dispatch<
                                SetStateAction<boolean>
                            >,
                            checkIfAllVariantsSelected: checkIfAllVariantsSelected,
                            setShowErrorMsgForVariantSelection: setShowErrorMsgForVariantSelection as React.Dispatch<
                                SetStateAction<boolean>
                            >,
                            isAutomotive: false,
                            isTireSizePresent: false,
                            homeService: false,
                            setIsVariantSelected: setIsVariantSelected as React.Dispatch<SetStateAction<boolean>>,
                            setIsNoSkuFindStoreClicked: setIsNoSkuFindStoreClicked as React.Dispatch<
                                SetStateAction<boolean>
                            >,
                            scrollOnToastDisplayed: () => false,
                            productObject: productObject,
                            inStockInSelectedStore: checkInStockInSelectedStore,
                            isProductSellable: isProductSellable,
                            isRestrictedPDP: checkIsRestrictedPDP,
                            isDisabledATC: false,
                            isSelectAStoreButtonAvailable: checkIsSelectAStoreButtonAvailable,
                            showSpinner: showSpinner,
                            isOutOfStockInAllStore: !!isOutOfStockInAllStore,
                            isBopisOnlyUnavailableForPickupInStock: isBopisOnlyUnavailableForPickupInStock,
                            isBopisOnlySkuWithStoreBopisUnavailable: isBopisOnlySkuWithStoreBopisUnavailable,
                            getAtcPrimaryButtonType: getAtcPrimaryButtonType,
                            isSelectAllOptionToShow: checkIsSelectAllOptionToShow,
                            needRenderATCWishlistButton: checkIfRenderATCWishlistButton(),
                            flyoutVariantsInfoData: variantsInfo,
                        } as unknown as ATCWishlistBtnsComponentProps)}
                    />
                </div>
                <div className={`${PREFIX}-buy-box-link`}>
                    <a
                        href={pdpLink}
                        className={''}
                        aria-label={goToPdpButtonLabel}
                        dap-wac-link="true"
                        dap-wac-loc={location.miniPDP}
                        dap-wac-value={goToPdpButtonLabel}>
                        {goToPdpButtonLabel}
                    </a>
                </div>
            </div>
        )
    }

    return (
        <>
            <HandleToast
                isAddedToWishlist={isAddedToWishlist}
                isAddToWishlistClicked={isAddToWishlistClicked}
                isShipToHome={getIsShipToHome}
                isAddToCartClicked={isAddToCartClicked}
                setIsAddToCartClicked={setIsAddToCartClicked as React.Dispatch<SetStateAction<boolean>>}
                setIsAddToWishlistClicked={setIsAddToWishlistClicked as React.Dispatch<SetStateAction<boolean>>}
                isNoSkuFindStoreClicked={isNoSkuFindStoreClicked}
                setIsNoSkuFindStoreClicked={setIsNoSkuFindStoreClicked as React.Dispatch<SetStateAction<boolean>>}
                setToastErrorMessage={setToastErrorMessage}
                toastErrorMessage={toastErrorMessage}
                showErrorMsgForVariantSelection={showErrorMsgForVariantSelection}
                setShowErrorMsgForVariantSelection={
                    setShowErrorMsgForVariantSelection as React.Dispatch<SetStateAction<boolean>>
                }
                showUnavailableFulfillmentToast={showUnavailableFulfillmentToast}
                setShowUnavailableFulfillmentToast={setShowUnavailableFulfillmentToast}
                checkIfAllVariantsSelected={checkIfAllVariantsSelected}
                setAddToCartErrorMessage={setAddToCartErrorMessage}
                inStockInSelectedStore={checkInStockInSelectedStore}
                shouldShowSelectStoreToast={shouldShowSelectStoreToast}
                setShouldShowSelectStoreToast={setShouldShowSelectStoreToast}
                isProductDiscontinued={checkIsProductDiscontinued}
                variantNotSelectedMessage={variantNotSelectedMessage}
                addToWishlistMessage={props.addToWishlistMessage}
                addToWishlistMessageGuest={props.addToWishlistMessageGuest}
                itemRemovedFromWishlistMsg={props.itemRemovedFromWishlistMsg}
                failureCloseLabel={props.failureCloseLabel}
                quantityExceeded={quantityExceeded}
                getMaxQuantityAvailable={checkMaxQuantityAvailable}
                inStockAtDC={checkInStockAtDC}
                isSeparatedErrorHandling={props.isSeparatedErrorHandling}
                qtySelectorErrorMsg={qtySelectorErrorMsg}
                setQtySelectorErrorMsg={setQtySelectorErrorMsg}
                exceedMaximumSellQuantityMessage={props.exceedMaximumSellQuantityMessage}
                exceedMaximumPurchableMessage={props.exceedMaximumPurchableMessage}
                wishlistMaxLimitMessage={props.wishlistMaxLimitMessage}
                isATCErrorHandling={props.isATCErrorHandling}
                prodOOSErrMsg={props.prodOOSErrMsg}
                allSelectedSkus={[]}
                isAutomotive={undefined as unknown as boolean}
                isStaggered={undefined as unknown as boolean}
                isTireSizePresent={undefined as unknown as boolean}
                signInForSalesAlerts={undefined as unknown as boolean}
                postalCodeErrorMessage={undefined as unknown as string}
                productObject={undefined as unknown as addRemovePayLoad}
                setPostalCodeErrorMessage={undefined as unknown as React.Dispatch<React.SetStateAction<string>>}
            />
            {renderBuyBoxComponent()}
        </>
    )
}

export default ATCQuantityWishlist
