import { MagicNumber } from '../analytics/analytics.type'
import { pageTypes } from '../config'
import { libUtils, isArrayNotEmpty, isAtleastOneParamValid } from '@nl/lib'
import getPageType from '../utils/getPageType'
import getEnvironment from '../utils/getEnvironment'
import { getEnvironment as getSiteConfig } from '../environments'
import {
    CertonaObjectType,
    CertonaResponseType,
    CertonaSchemaType,
    CertonaProductType,
    CertonaAdditionalParams,
    CertonaProductWithImagesType,
} from './certona.type'
import appCacheService from '../utils/appCacheService'
import { CertonaConstants, deviceTypes } from '@nl/lib/src/globalConstants/global.constant'
import { gclid, google, utmMedium } from '../globalConstants'
import { HybrisMedia } from '../redux/models/product.interface'

/**
 * Function to create object, assign response callback, filtering the pcodes.
 * @returns init, extractPCodes methods to use.
 */
export const CertonaInitialization = (window => {
    // eslint-disable-next-line complexity, sonar/cyclomatic-complexity
    const _constructCertonaObjectByPageType = (additionalParams: CertonaAdditionalParams) => {
        const {
            productPage,
            searchPage,
            cart,
            wishlist,
            store,
            categoryPages,
            orderConfirmation,
            orderDetails,
            account,
            homePage,
            emailOfferActivation,
            weeklyOffer,
            accountDasboard,
        } = pageTypes
        const [cat1, cat2, catN] = categoryPages
        switch (getPageType() || '') {
            case productPage:
                return {
                    ..._defaultCertonaObjectParams(),
                    itemid: additionalParams?.ItemId,
                    customerid: additionalParams?.customerId,
                    ..._updateFilterObject(_defaultCertonaObjectParams(), 'filter', {
                        outofstock: additionalParams?.outOfStock,
                        pla: isAtleastOneParamValid(
                            window.location.search?.includes(gclid),
                            window.location.search?.includes(utmMedium),
                        ),
                    }),
                }
            case searchPage:
                return {
                    ..._defaultCertonaObjectParams(),
                    exitemid: additionalParams?.exItemId,
                    pagetype: additionalParams?.pageType,
                }
            case cart:
            case wishlist:
                return {
                    ..._defaultCertonaObjectParams(),
                    itemid: additionalParams.ItemId,
                    customerid: additionalParams.customerId,
                }
            case cat1:
            case cat2:
            case catN:
                const categoriesLength = (additionalParams.categories || []).length
                return {
                    ..._defaultCertonaObjectParams(),
                    ..._updateFilterObject(_defaultCertonaObjectParams(), 'filter', {
                        [`categorylevel${categoriesLength}`]: additionalParams.categoryId,
                        pla: (getPageType() === cat1 || getPageType() === cat2) && document.referrer?.includes(google),
                    }),
                    categoryid: additionalParams.categoryId,
                    pagetype: `categorylevel${categoriesLength}`,
                }
            case orderConfirmation:
            case orderDetails:
                return {
                    ..._defaultCertonaObjectParams(),
                    pagetype: orderConfirmation,
                    itemid: additionalParams.ItemId,
                    qty: additionalParams.qty,
                    price: additionalParams.price,
                    total: additionalParams.total,
                    transactionid: additionalParams.transactionId,
                    customerid: additionalParams.customerId,
                }
            case account:
            case weeklyOffer:
            case accountDasboard:
            case emailOfferActivation:
            case homePage:
                return {
                    ..._defaultCertonaObjectParams(),
                    customerid: additionalParams.customerId,
                }
            case store:
            default:
                return {
                    ..._defaultCertonaObjectParams(),
                }
        }
    }

    /**
     * Get Device type i.e. mobile, tablet, desktop
     * @return {string}
     */
    const _getDeviceType = (): string => {
        const userAgent = navigator.userAgent
        return /(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(userAgent)
            ? deviceTypes.TABLET
            : // eslint-disable-next-line max-len, sonar/no-nested-conditional
            /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
                  userAgent,
              )
            ? deviceTypes.MOBILE
            : deviceTypes.DESKTOP
    }

    /**
     * Default certona params.
     * @return {CertonaObjectType}
     */
    const _defaultCertonaObjectParams = (): CertonaObjectType => {
        // eslint-disable-next-line no-warning-comments
        // TODO Four digit store id is required to match storeid in Certona feed but this will be removed once it is changed on IA side
        const fourDigitStoreId = appCacheService.preferredStoreId.get().padStart(MagicNumber.FOUR, '0')
        return {
            pagetype: getPageType() || '',
            devicetype: _getDeviceType(),
            filter: {
                storeid: fourDigitStoreId || '',
                currencycode: CertonaConstants.CURRENCY,
                language: libUtils.getLanguage(),
            },
            // eslint-disable-next-line no-warning-comments
            // TODO: Env needs to be taken from AEM once https://jira.corp.ad.ctc/browse/OCCP-16126 is implemented
            environment: getEnvironment() || '',
            recommendations: true,
        }
    }

    /**
     * Function will update the default object if we have passed any key and additional params while init.
     * @param {CertonaObjectType} defaultObject - default certona object.
     * @param {string} objectKey - key to be used to update the default object.
     * @param {Record<string, string>} additionalParams - use when u want to pass additional information.
     *
     * @return {Record<string, string>} returns the updated object for the specified key in the default prop
     */
    const _updateFilterObject = (
        defaultObject: CertonaObjectType,
        objectKey: keyof CertonaObjectType,
        additionalParams: Record<string, string | boolean | number | undefined>,
    ): Record<string, unknown> => {
        if (!objectKey) return {}
        const keyProps = Object.assign({}, defaultObject[objectKey])
        return {
            [objectKey]: {
                ...(keyProps as Record<string, string>),
                ...additionalParams,
            },
        }
    }

    /**
     * Function to filter out the wanted scheme from list of schemes.
     * @param {CertonaSchemaType[]} schemes
     * @param {string} usedSchemeId
     * @return {CertonaSchemaType}
     */
    const _filterScheme = (schemes: CertonaSchemaType[], usedSchemeId: string): CertonaSchemaType | undefined => {
        return schemes.find((singleSchema: CertonaSchemaType) => singleSchema.scheme === usedSchemeId)
    }

    /**
     * Extract pcodes based on the provided scheme id.
     * @param {CertonaSchemaType[]} schemes - all schemes returned by the certona.
     * @param {string} usedSchemeId - requested scheme id for which we need to extract the codes.
     *
     * @return {object} - returns pcodes and title..
     */
    const _filterSchemeDetails = (
        schemes: CertonaSchemaType[],
        usedSchemeId: string,
    ): {
        getPCodes: string[]
        getTitle: string
        getItems: CertonaProductType[]
    } => {
        const singleSchemeDetails = _filterScheme(schemes, usedSchemeId)
        return {
            getPCodes:
                singleSchemeDetails?.items.map((singleItem: CertonaProductType) => singleItem.prod_id || '') || [],
            getTitle: singleSchemeDetails?.explanation || '',
            getItems: singleSchemeDetails?.items as CertonaProductType[],
        }
    }

    /**
     * Use the function only when you want to call certona i.e. On Page Event Trigger
     * @param {Record<string, unknown>} certonaObject - object used by callCertona Method.
     */
    const _triggerCertona = (certonaObject?: Record<string, unknown>): void => {
        if (window.callCertona) {
            !!certonaObject ? window.callCertona(certonaObject) : window.callCertona()
        }
    }

    /**
     * Function used to call the certona api to get the response.
     * @param {Function} certonaCallback - certona recommendation will store the data in this variable.
     * @param {string[]} additionalParams
     */
    const _init = (
        certonaCallback: (certonaData: CertonaResponseType) => void,
        additionalParams: CertonaAdditionalParams,
    ): void => {
        window.certona = _constructCertonaObjectByPageType(additionalParams) as CertonaObjectType // Create certona object. this will be used by certona.
        window.certonaRecommendations = certonaCallback // Response callback which holds jsonp object.
    }

    return {
        init: _init,
        extractSchemeDetails: _filterSchemeDetails,
        triggerCertona: _triggerCertona,
    }
})(window)

/**
 * Function to get product images from certona
 * @param {CertonaProductType[]} certonaItems
 * @param {ProductCardType} product
 * @param {boolean} isFBT
 * @return {string[] | null}
 */
export const getImagesFromCertona = <Product extends CertonaProductWithImagesType>(
    certonaItems: CertonaProductType[] | null,
    product: Product,
    isFBT?: boolean,
): HybrisMedia[] | null => {
    const config = getSiteConfig()
    const pCode = (isFBT ? product?.pcode : product?.code) as string
    const certonaItem = certonaItems?.find(rec => rec.prod_id?.toUpperCase() === pCode?.toUpperCase())
    if (certonaItem) {
        const imageObject = { altText: '', url: certonaItem.image || '' }
        return (
            certonaItem?.image
                ? [imageObject]
                : // eslint-disable-next-line sonar/no-nested-conditional
                isArrayNotEmpty(product.images) && product?.images?.[0]
                ? [product.images[0]]
                : [{ altText: '', url: config.defaultProductImage }]
        ) as HybrisMedia[]
    }
    return null
}
