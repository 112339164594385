import React from 'react'
import PropTypes from 'prop-types'

import { RecommendationsListProps, ProductCardType } from './RecommendationsList.type'
import { ProductCardType as ProductCardGrid } from '../ProductGridView/ProductGrid.types'
import { PREFIX } from '../config'
import { addDynamicToken, replaceStrWithDynamicVal } from '../../utils/replaceStrWithDynamicVal'
import { getFormattedPriceValue } from '../ProductReusableCard/instanceCheckingUtil'
import { appendQueryParamsToUrl, magicNumber } from '../../utils'
import { getAccessibilityId } from '../../utils/getAccessibilityId'
import { checkDataLength } from '../../utils/checkDataLength'
import { getPageType } from '../../utils/getPageType'
import { areAllParamsValid } from '../../utils/validParams'
import ProductListCard from '../ProductListCard'
import { ProductDataTypeObj } from '../../types'

/**
 * RecommendationsList component
 * @param {RecommendationsListProps} props -  title, productList, path, priceProps, productCardClick, maximumNumberOfRecommendations, a11yCarouselPreviousLabel, a11yCarouselNextLabel, a11yStrikeOutPrice
 * @return {JSX.Element} returns Recommendations component
 */
const RecommendationsList: React.FC<RecommendationsListProps> = props => {
    const {
        title,
        productList,
        path,
        priceProps,
        productCardClick,
        maximumNumberOfRecommendations,
        a11yStrikeOutPrice,
        a11yStrikeOutPriceRange,
        badgePriorities,
        language,
        imageDataComponentName,
        enableBadges,
        enableSaveStory,
        returnPolicy,
        a11yClickToReadFootnote,
        selectedSchemaId,
        addToCartClickHandler,
        addToCartLabel,
        addedToCartLabel,
        isYouNeedThis,
        miniCartData,
        plusMinusSymbol,
        enableATCFlyOut,
        optionsCTA,
    } = props

    const {
        unitPriceLabel,
        clearancePriceLabel,
        promotionalPriceLabel,
        threshold,
        nowFromLabel,
        saveFromLabel,
        wasFromLabel,
        fromLabel,
    } = priceProps

    const productProps = {
        ratingsAndReview: true,
        featureHeaderLabel: '',
        variantAvailableMsg: '',
        moreLabel: '',
        unitPriceLabel,
        clearancePriceLabel,
        promotionalPriceLabel,
        thresholdValue: threshold,
        nowFromLabel,
        saveFromLabel,
        wasFromLabel,
        fromLabel,
    }

    /**
     * gets the ProductCarousel List
     *
     * @return {JSX.Element} returns array of JSX.Element
     */
    const getProductCarouselList = (): JSX.Element[] => {
        return productList.slice(0, maximumNumberOfRecommendations).map((product: ProductCardType, index: number) => {
            let isProductAdded = false
            const {
                type,
                title: productTitle,
                brand,
                images,
                rating,
                ratingsCount,
                price,
                featureBullets,
                discount,
                badges,
                priceMessage,
                currentPrice,
                originalPrice,
                displayWasLabel,
                feeMessages,
                url,
                code,
                skus,
                isMultiSku,
                fitmentTypeCode,
                productWheelType,
            } = product
            const selectedProductCode = skus?.length === 1 ? skus?.[0]?.code : code.slice(0, -1)

            if (
                checkDataLength(miniCartData) &&
                miniCartData?.orderEntries?.some(cartData => cartData?.code === selectedProductCode)
            ) {
                isProductAdded = true
            }

            const productBadges = enableBadges ? badges : []
            const pageType = getPageType()
            const urlParams = {
                rrecName: title,
                rrecReferrer: pageType,
                rrecProductId: code,
                rrecProductSlot: index + magicNumber.ONE,
                rrecSchemeId: selectedSchemaId,
                rrec: true,
            }

            const isProductNotFitmentAndWheelType = areAllParamsValid(!fitmentTypeCode, !productWheelType, !isMultiSku)
            const isMultiSkuProduct = areAllParamsValid(!isProductNotFitmentAndWheelType, enableATCFlyOut)

            return (
                <ProductListCard
                    product={product as unknown as ProductCardGrid}
                    url={appendQueryParamsToUrl(url, urlParams)}
                    key={index}
                    productProps={productProps}
                    path={path}
                    idx={index}
                    cardType="grid"
                    title={productTitle}
                    type={type}
                    code={product?.skus?.length === magicNumber.ONE ? product?.skus?.[0]?.formattedCode : ''}
                    brand={brand}
                    images={images}
                    rating={rating}
                    ratingsCount={ratingsCount || 0}
                    price={price}
                    discount={discount}
                    featureBullets={featureBullets}
                    productCardClick={(event, productdata, idx) =>
                        productCardClick(event, productdata as unknown as ProductDataTypeObj, idx)
                    }
                    a11yStrikeOutPrice={a11yStrikeOutPrice}
                    a11yStrikeOutPriceRange={a11yStrikeOutPriceRange}
                    showRatingSection={true}
                    badges={productBadges}
                    badgePriorities={badgePriorities}
                    language={language}
                    feeTitle={
                        feeMessages?.[0]?.value
                            ? replaceStrWithDynamicVal(
                                  addDynamicToken(feeMessages?.[0]?.feeTitle, '$x'),
                                  getFormattedPriceValue(language, feeMessages?.[0]?.value),
                              )
                            : ''
                    }
                    feeDisclaimerType={feeMessages?.[0]?.type}
                    feeDisclaimerMessage={feeMessages?.[0]?.feeDisclaimerMessage}
                    feeDisclaimerTitle={feeMessages?.[0]?.feeDisclaimerTitle}
                    currentPrice={currentPrice}
                    originalPrice={originalPrice}
                    priceMessage={priceMessage}
                    nowFromLabel={nowFromLabel}
                    saveFromLabel={saveFromLabel}
                    wasFromLabel={wasFromLabel}
                    fromLabel={fromLabel}
                    displayWasLabel={displayWasLabel}
                    imageDataComponentName={imageDataComponentName}
                    returnPolicy={returnPolicy}
                    unitPriceLabel={unitPriceLabel}
                    promotionalPriceLabel={promotionalPriceLabel}
                    clearancePriceLabel={clearancePriceLabel}
                    thresholdValue={threshold}
                    enableSaveStory={enableSaveStory}
                    productDataId={code}
                    a11yClickToReadFootnote={a11yClickToReadFootnote}
                    addToCartClickHandler={addToCartClickHandler}
                    accessibilityId={getAccessibilityId(
                        selectedSchemaId,
                        product?.skus?.[0]?.code ? product?.skus?.[0]?.code : code,
                    )}
                    addToCartLabel={addToCartLabel}
                    addedToCartLabel={addedToCartLabel}
                    isYouNeedThis={isYouNeedThis}
                    isProductAdded={isProductAdded}
                    plusMinusSymbol={plusMinusSymbol}
                    isMultiSkuProduct={isMultiSkuProduct}
                    optionsCTA={optionsCTA}
                />
            )
        })
    }

    return (
        <div className={`${PREFIX}-recommendations`}>
            <div className={`${PREFIX}-recommendations__title`} data-testid="recommendations-title">
                <h2>{title}</h2>
            </div>
            {productList && productList.length > 0 && (
                <div className={`${PREFIX}-product__list-view ${PREFIX}-product__list-items`}>
                    {getProductCarouselList()}
                </div>
            )}
        </div>
    )
}

RecommendationsList.propTypes = {
    title: PropTypes.string,
    productList: PropTypes.array,
    path: PropTypes.string,
    priceProps: PropTypes.object,
    productCardClick: PropTypes.func,
    maximumNumberOfRecommendations: PropTypes.number,
    a11yStrikeOutPrice: PropTypes.string,
    a11yStrikeOutPriceRange: PropTypes.string,
    language: PropTypes.string.isRequired,
    imageDataComponentName: PropTypes.string,
    enableBadges: PropTypes.bool,
    enableSaveStory: PropTypes.bool,
    returnPolicy: PropTypes.func,
    a11yClickToReadFootnote: PropTypes.string,
    selectedSchemaId: PropTypes.string,
}

export default RecommendationsList
