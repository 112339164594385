/* eslint-disable max-lines */
import {
    CorporateStatus,
    FulfillmentDTOP,
    ProductOption,
    ProductOptionValue,
    ProductResponseData,
    ProductSku,
    ProductSkusData,
} from '../../redux/models/product.interface'
import { checkDataLength } from '../Accounts/Addresses/checkDataLength'
import {
    isArrayNotEmpty,
    scrollToElement,
    replaceStrWithDynamicVal,
    isValidPrice,
    FitmentNote,
    RebateData,
    saleOrClearanceBadge,
    areAllParamsValid,
    libUtils,
} from '@nl/lib'
import getQueryString from '../../utils/getQueryString'
import {
    maxCount,
    fulfillmentConstants,
    corporateConstants,
    mediaConstants,
    setOfFourCount,
    homeServiceConstants,
    guranteedFitments,
    firstVariantKey,
    secondVariantKey,
} from './BuyBox.constant'
import { productDetailAnalytics } from '../../analytics/components/productDetailAnalytics'
import {
    FbtProductWithAvailability,
    FilteredVariantType,
    PriceVariant,
    STHCodes,
    VariantDetails,
    VariantType,
    VehiclePayload,
} from './BuyBox.type'
import { getBreadcrumbList } from '../Breadcrumbs/Breadcrumbs.helper'
import { BreadcrumbLinkData } from '../../redux/models/productData.interface'
import { IHeliumInflationStoreServiceType, OrderEntryDTO, VehicleInformation } from '../../redux/models/cart.interface'
import { bazaarVoiceConst } from '../BazaarVoice/bazaarVoice.constant'
import { BREAKPOINTS, PREFIX } from '../../config'
import {
    CTAClickedName,
    productWheelTypes,
    ProductTypeList,
    VehicleFitType,
    inflationData,
} from '../../globalConstants/global.constant'
import appCacheService from '../../utils/appCacheService'
import { FulfillmentMethods } from '../ShoppingCart/ShoppingCart.type'
import { StoreWithAvailability } from '../../redux/models/storeDetails.interface'
import { addToCartAnalyticsError } from '../../analytics/components/addToCartAnalytics'
import { analyticsAttributes } from '../../globalConstants/analyticsParams.constant'
import { MagicNumber } from '../../analytics/analytics.type'
import { getDaysDifference } from '../../utils/getDaysDifference.utils'
import { isTirePDP } from '../Vehicles/Vehicle.helper'
import { pdpStaggeredTotalPrice } from '../AutomotivePDP/AutomotiveBuyBox/AutomotiveBuyBox.helper'
import { TireType } from '../../redux/models/tireVehicle.interface'
import { Vehicle } from '../../../../lib/src/components/ProductGridView/ProductGrid.types'
import { CriticalFitmentProduct } from '../AutomotivePDP/AutomotiveBuyBox/AutomotiveBuyBox.type'
import { SkuListType } from '../FrequentlyBoughtTogether/FrequentlyBoughtTogether.type'
import { AutoPackages } from '../AutomotivePackage/PackageLanding/PackageLanding.type'
import { expandAccordion } from '../../../../lib/src/components/StickyTOC/StickyTOC'
import { pageTypes } from '../../config'
import getPageType from '../../utils/getPageType'
import { AddToWishListPayload, WishListEntry } from '../../redux/models/wishlist.interface'
// eslint-disable-next-line no-warning-comments
// Todo: Change import to '@nl/lib` when correct types export will be implemented
import { BreadcrumbParentPageLink } from '../Breadcrumbs/BreadcrumbsComp.type'
import { VehicleState } from '../../redux/reducers/user.profile.reducer'
import { ErrorResponse, SuccessResponse } from '../../redux/models/deliveryOptions.interface'
import { EstimateDeliveryDate } from '../../redux/models/estimateDeliveryDate.interface'
import { StandardDeliveryDateType } from './SwitchFulfillmentOptions/SwitchFulfillmentOptions.type'
import { checkNumberNotNullAndUndefined, checkNotNullAndUndefined } from '../../utils/checkNotNullAndUndefined'
import { isStaggeredFront } from '../../utils/checkStaggered.utils'
import { IFedErrors } from '../../redux/models/commonContent.interface'

/**
 * function called whenever any variant is selected and productData changes
 * @param {ProductResponseData} productData
 * @param {string} variantSKU
 * @param {ProductSku} productSkuData
 * @returns {FulfillmentDTOP | undefined} returns quantity available in store passed as param
 */
export const getSelectedVariantSKU = (
    productData: ProductResponseData,
    variantSKU: string,
    productSkuData: ProductSku,
): FulfillmentDTOP | undefined => {
    if ((variantSKU && variantSKU.length) || productData?.code) {
        const { skus } = productData
        const maxPurchasableQty = productData?.maxPurchasableQty || 0
        const skuQtyData = skus?.find(value => value.code === variantSKU)
        const updatedSkuQty = checkDataLength(skuQtyData as unknown as Record<string, unknown>)
            ? { ...productSkuData?.fulfillment, maxPurchasableQty: skuQtyData?.maxPurchasableQty }
            : null
        return (updatedSkuQty as FulfillmentDTOP) || ({ maxPurchasableQty } as unknown as FulfillmentDTOP)
    }
    return undefined
}

/**
 * Takes number Array and returns min Check Quantity check
 * @param {number[]} values
 * @returns {number | undefined} returns quantity available in store passed as param
 */
export const checkMaxQuantity = (...values: number[]): number => {
    let maxValuesArr: number[] = []
    values.forEach((value: number) => {
        if (typeof value === 'number' && value !== 0) {
            maxValuesArr.push(value)
        }
    })
    maxValuesArr = maxValuesArr.sort((firstNum, secondNum) => firstNum - secondNum)

    return maxValuesArr[0]
}

/**
 * Funtion returns the available option ids for SKU
 * @param {ProductResponseData} productData
 * @param {string} skuCode
 * @returns {ProductSku} returns quantity available in store passed as param
 */
export const getSelectedDetailsFromSKU = (productData: ProductResponseData, skuCode: string): ProductSku => {
    if (skuCode) {
        const { skus } = productData
        return skus?.find((value: ProductSku) => value.code === skuCode) || ({} as ProductSku)
    }
    return {} as ProductSku
}

/**
 * This function compares user selected variant options ids and options ids Array pertaining to each sku
 * @param {number[]} optionIdsFromSku options id for each sku from API
 * @param {number[]} selectedOptionIds options Ids array based on user selection
 * @returns {boolean} returns true or false if both array are compatible
 */
export const compareOptionIds = (
    optionIdsFromSku?: Array<number | string>,
    selectedOptionIds?: Array<number | string>,
): boolean => {
    if (!optionIdsFromSku || !selectedOptionIds) {
        return false
    }
    if (optionIdsFromSku.length !== selectedOptionIds.length) {
        return false
    }
    return selectedOptionIds.every(selectedOptionId => optionIdsFromSku.includes(selectedOptionId))
}

/**
 * function to get sku code if all variants are selected
 * @param {number} selectedColorId - selected color Id
 * @param {number} selectedSizeId - selected size Id
 * @param {number} selectedDynamicId - selected dynamic Id
 * @param {ProductResponseData} productData - product data
 * @returns {string}
 */
export const getProductSKUCode = (
    selectedColorId: string,
    selectedSizeId: string,
    selectedDynamicId: string,
    productData: ProductResponseData,
): string => {
    const variantArr: Array<number | string> = []
    ;[selectedColorId, selectedSizeId, selectedDynamicId].forEach(
        (variant: string) => variant && variant !== '0' && variantArr.push(variant),
    )

    variantArr.length === 0 && variantArr.push(0)
    let productSkuCode
    if (isArrayNotEmpty(productData.skus)) {
        productSkuCode = isSingleSku(productData)
            ? productData.skus[MagicNumber.ZERO]
            : productData.skus.find((skucode: ProductSku) => compareOptionIds(skucode?.optionIds, variantArr))
    }

    return productSkuCode?.code || ''
}

/**
 * This function returns variant data based on option id
 * @param {VariantType[]} variantData
 * @param {option} number
 * @return VariantType | undefined
 */

const getVariantName = (variantData: VariantType[], option: string): VariantType | undefined => {
    return variantData.find((item: VariantType) => item.id === option)
}

/**
 * This function returns variant data based on variant selection
 * @param {FilteredVariantType} variantSelected
 * @param {string} selectedVariant
 * @returns {VariantType | undefined}
 */
export const getVariantSelected = (
    variantSelected: FilteredVariantType,
    selectedVariant: string,
): VariantType | undefined => {
    return variantSelected?.values.find((value: { id: unknown }) => value.id === selectedVariant)
}

/**
 * This is callback function type
 * @callback requestCallback
 * @param {string} selectedVariant
 */

/**
 * This function returns variant data based on option id
 * @param {FilteredVariantType} item
 * @param {requestCallback} setVariantOption
 * @param {number} option option id
 * @param {requestCallback} setVariantOptionId
 */
export const setVariantOptionFromSKU = (
    item: FilteredVariantType,
    setVariantOption: (selectedVariant: string, selectedVarintDisplayName?: string) => void,
    option: string,
    setVariantOptionId: (selectedVariantId: string) => void,
): void => {
    const variantData = getVariantName(item.values, option)
    if (variantData) {
        setVariantOption(variantData.name, variantData.displayNameForVariants)
        setVariantOptionId(variantData.id)
    }
}

/**
 * This function used to set selected variant name
 * @param {VariantDetails} variantDetails
 * @param {number[]} optionIds
 * @param {requestCallback} setSelectedFirstVariant
 * @param {requestCallback} setSelectedSecondVariant
 * @param {requestCallback} setSelectedThirdVariant
 * @param {requestCallback} setSelectedFirstVariantId
 * @param {requestCallback} setSelectedSecondVariantId
 * @param {requestCallback} setSelectedThirdVariantId
 */
export const setSelectedVariantName = (
    variantDetails: VariantDetails,
    optionIds: string[],
    setSelectedFirstVariant: (selectedFirstVariant: string, selectedFirstVariantDisplayName?: string) => void,
    setSelectedSecondVariant: (selectedSecondVariant: string) => void,
    setSelectedThirdVariant: (selectedThirdVariant: string) => void,
    setSelectedFirstVariantId: (selectedFirstVariantId: string) => void,
    setSelectedSecondVariantId: (selectedSecondVariantId: string) => void,
    setSelectedThirdVariantId: (selectedThirdVariantId: string) => void,
    // eslint-disable-next-line max-params
): void => {
    optionIds?.forEach((option: string) => {
        let variantKey = ''
        Object.keys(variantDetails).forEach((variant: string) => {
            const valueObj = variantDetails[variant]?.values?.find((value: VariantType) => value.id === option)
            if (checkDataLength(valueObj)) {
                variantKey = variant
            }
        })

        switch (variantKey) {
            case firstVariantKey:
                setVariantOptionFromSKU(
                    variantDetails[variantKey],
                    setSelectedFirstVariant,
                    option,
                    setSelectedFirstVariantId,
                )
                break
            case secondVariantKey:
                setVariantOptionFromSKU(
                    variantDetails[variantKey],
                    setSelectedSecondVariant,
                    option,
                    setSelectedSecondVariantId,
                )
                break
            default:
                setVariantOptionFromSKU(
                    variantDetails[variantKey],
                    setSelectedThirdVariant,
                    option,
                    setSelectedThirdVariantId,
                )
                break
        }
    })
}

/**
 * This function used to set selected variant name
 * @param {VariantDetails} variantDetails
 * @param {number[]} optionIds
 * @param {requestCallback} setSelectedFirstVariant
 * @param {requestCallback} setSelectedSecondVariant
 * @param {requestCallback} setSelectedThirdVariant
 * @param {requestCallback} setSelectedFirstVariantId
 * @param {requestCallback} setSelectedSecondVariantId
 * @param {requestCallback} setSelectedThirdVariantId
 */
export const resetSelectedVariantName = (
    variantDetails: VariantDetails,
    optionIds: string[],
    setSelectedFirstVariant: (selectedFirstVariant: string, selectedFirstVariantDisplayName?: string) => void,
    setSelectedSecondVariant: (selectedSecondVariant: string) => void,
    setSelectedThirdVariant: (selectedThirdVariant: string) => void,
    setSelectedFirstVariantId: (selectedFirstVariantId: string) => void,
    setSelectedSecondVariantId: (selectedSecondVariantId: string) => void,
    setSelectedThirdVariantId: (selectedThirdVariantId: string) => void,
    // eslint-disable-next-line max-params
): void => {
    optionIds?.forEach((option: string) => {
        let variantKey = ''
        Object.keys(variantDetails).forEach((variantDetail: string) => {
            const variantObject = variantDetails[variantDetail]?.values?.find(
                (variantData: VariantType) => variantData.id === option,
            )
            if (checkDataLength(variantObject)) {
                variantKey = variantDetail
            }
        })

        switch (variantKey) {
            case firstVariantKey:
                setSelectedFirstVariant('', '')
                setSelectedFirstVariantId('')
                break
            case secondVariantKey:
                setSelectedSecondVariant('')
                setSelectedSecondVariantId('')
                break
            default:
                setSelectedThirdVariant('')
                setSelectedThirdVariantId('')
                break
        }
    })
}

/**
 * This function set variant name from query param
 * @param {string} queryParams
 * @param {ProductOption[]} options
 * @param {requestCallback} setSelectedColor
 * @param {requestCallback} setSelectedSize
 * @param {requestCallback} setSelectedName
 */
export const getVariantNameFromQueryParam = (
    queryParams: string,
    options: ProductOption[],
    setSelectedColor: (selectedColor: string) => void,
    setSelectedSize: (selectedSize: string) => void,
    setSelectedName: (selectedName: string) => void,
): void => {
    const urlParams = new URLSearchParams(queryParams)
    const urlParamsKeys = Array.from(urlParams.keys())
    const [firstVariant, secondVariant, thirdVariant] = options || []

    const setVariantName = (selectedValue: string, key: string) => {
        switch (key.toLowerCase()) {
            case firstVariant?.display.toLowerCase():
                setSelectedColor(selectedValue)
                break
            case secondVariant?.display.toLowerCase():
                setSelectedSize(selectedValue)
                break
            case thirdVariant?.display.toLowerCase():
                setSelectedName(selectedValue)
                break
            default:
                break
        }
    }
    urlParamsKeys.forEach(key => {
        const selectedValue = getQueryString(queryParams, key)
        selectedValue && setVariantName(selectedValue, key)
    })
}

/**
 * This function returns if product is limited PDP or not
 * @param {boolean} active
 * @param {boolean} sellableFlag
 * @returns { boolean } returns true or false
 */
export const checkIsLimitedPdp = (active?: boolean, sellableFlag?: boolean): boolean => {
    return active === undefined || sellableFlag === undefined ? false : Boolean(!sellableFlag && !active)
}

/**
 * This function returns if product has sale or clearance badge or not
 * @param {string[]} badges
 * @returns { boolean } returns true or false
 */
export const hasSaleClearanceBadge = (badges: string[]): boolean => {
    return badges?.some(badge => Number(saleOrClearanceBadge.indexOf(badge)) >= 0)
}

/**
 * This function returns if product is Restricted PDP or not
 * @param {string} messageCode
 * @param {boolean} sellableFlag
 * @returns { boolean } returns true or false
 */
export const checkIsRestrictedPDP = (messageCode?: string, sellableFlag?: boolean): boolean => {
    return (sellableFlag === undefined ? false : !sellableFlag) || !!messageCode
}

/**
 * scrollToRoadRatingHandler scrolling from road rating badge to road rating container
 */
const scrollToRoadRatingHandler = (): void => {
    const element = document.getElementById('road-rating')

    scrollToElement(element)
}

/** this function checks the sku data of auto pdp
 * @param {Vehicle} vehicleData
 * @param {TireType} tireSizeData
 * @param {string} vehicleFitTypes
 * @param {FulfillmentDTOP} fulfillmentData
 * @param {ProductSku} skuData
 * @returns {boolean}
 */
const checkAutoSkuData = (
    vehicleData?: Vehicle,
    tireSizeData?: TireType,
    vehicleFitTypes?: string,
    fulfillmentData?: FulfillmentDTOP,
    skuData?: ProductSku,
): boolean => {
    if (vehicleData || tireSizeData) {
        return vehicleFitTypes === VehicleFitType.Fit
            ? checkDataLength(fulfillmentData) && checkDataLength(skuData)
            : vehicleFitTypes !== VehicleFitType.Unknown
    }
    return true
}

/** this function checks the analytics call should happen for gen merch
 * @param {boolean} isSingleVariantGenMerch
 * @param {ProductSku} skuData
 * @param {string} selectedVariant
 * @returns {boolean}
 */
const checkForGenMerchAnalytics = (
    isSingleVariantGenMerch?: boolean,
    skuData?: ProductSku,
    selectedVariant?: string,
): boolean | undefined => {
    if (selectedVariant) {
        return isSingleVariantGenMerch
    } else return !!skuData
}

/**
 * This function checks the conditions for pdp page load analytics
 * @param {ProductResponseData} productDetail
 * @param {string} selectedVariant
 * @param {boolean} isAuto
 * @param {PriceVariant} getSkuProductData
 * @param {boolean} isSingleVariantGenMerch
 * @param {string} vehicleFitType
 * @param {Vehicle} vehicleData
 * @param {FulfillmentDTOP} fulfillmentData
 * @param {ProductSku} skuData
 * @returns {boolean}
 */
export const checkForPDPAnalytics = (
    productDetail?: ProductResponseData,
    selectedVariant?: string,
    isAuto?: boolean,
    getSkuProductData?: PriceVariant,
    isSingleVariantGenMerch?: boolean,
    vehicleFitType?: string,
    vehicleData?: Vehicle,
    fulfillmentData?: FulfillmentDTOP,
    skuData?: ProductSku,
    // eslint-disable-next-line max-params
): string | boolean | undefined => {
    const tireSizeData = appCacheService.tiresSize.get()
    return (
        checkDataLength(productDetail) &&
        getSkuProductData &&
        (isAuto
            ? checkAutoSkuData(
                  vehicleData,
                  tireSizeData as unknown as TireType,
                  vehicleFitType,
                  fulfillmentData,
                  skuData,
              )
            : checkForGenMerchAnalytics(isSingleVariantGenMerch, skuData, selectedVariant))
    )
}

/**
 * This function send the data to analytics
 * @param {ProductResponseData} productData
 * @param {VariantDetails } variantDetails
 * @param {string } selectedColor
 * @param {string } selectedSize
 * @param {string } selectedDynamicName
 * @param {Function} isProductDiscontinued
 * @param {Function} inStockAtDC
 * @param {Function} inStockInSelectedStore
 * @param {boolean} financingShown
 * @param {boolean} financingEligible
 * @param {boolean} isFitmentRequired
 * @param {Vehicle} vehicle
 * @param {boolean} shopWithVehicle
 * @param {boolean} staggeredFitment
 * @param {boolean} fitted
 * @param {string} productWheelType
 * @param {boolean} installationEligible
 * @param {boolean} isOutOfStockInAllStore
 * @return {void}
 */
let count = 0
export const sendProductAnalyticsData = (
    productData: ProductResponseData,
    productSkuData: ProductSkusData,
    variantDetails: VariantDetails,
    selectedColor: string,
    selectedSize: string,
    selectedDynamicName: string,
    inStockInSelectedStore: () => boolean,
    inStockAtDC: () => boolean,
    isProductDiscontinued: () => boolean,
    language: string,
    financingEligible: boolean,
    financingShown: boolean,
    skuCode: string,
    getProductDataBySku: PriceVariant,
    fulfillment: FulfillmentDTOP,
    checkIfAllVariantsSelected: () => boolean,
    skuOnLoad: string,
    isAutomotive?: boolean,
    vehicle?: Vehicle,
    staggeredFitment?: boolean,
    fitted?: boolean,
    productWheelType?: string,
    installationEligible?: boolean,
    isShipToHome?: boolean,
    selectedProductCode?: string,
    vehicleFitType?: string,
    isOutOfStockInAllStore?: boolean,
    isStoreEligibleForExpressDelivery?: boolean,
    // eslint-disable-next-line max-params
): void => {
    let isSingleVariant = true
    if (productData?.options?.length === MagicNumber.ONE) {
        isSingleVariant = checkDataLength(fulfillment) && checkDataLength(productSkuData?.skus?.[0])
        // eslint-disable-next-line sonarjs/elseif-without-else
    } else if (!!skuOnLoad) {
        isSingleVariant = checkIfAllVariantsSelected() && checkDataLength(fulfillment)
    }

    if (
        count === 0 &&
        checkForPDPAnalytics(
            productData,
            selectedColor,
            isAutomotive,
            getProductDataBySku,
            isSingleVariant,
            vehicleFitType,
            vehicle,
            fulfillment,
            productSkuData?.skus?.[0],
        )
    ) {
        count = maxCount
        productDetailAnalytics(
            productData,
            productSkuData,
            variantDetails,
            selectedColor,
            selectedSize,
            selectedDynamicName,
            inStockInSelectedStore,
            inStockAtDC,
            isProductDiscontinued,
            language,
            financingEligible,
            financingShown,
            skuCode,
            getProductDataBySku,
            fulfillment,
            isAutomotive,
            vehicle,
            staggeredFitment,
            fitted,
            productWheelType,
            installationEligible,
            isShipToHome,
            selectedProductCode,
            vehicleFitType,
            isOutOfStockInAllStore,
            isStoreEligibleForExpressDelivery,
        )
    }
}

const { sellable, restricted, bopisAndSth, bopisOnly, sthOnly, inStoreOnly, unavailable } = fulfillmentConstants
/**
 * This function checks the product type
 * @param {string} messageCode
 * @param { boolean } sellableProductType
 * @returns { string } returns productType
 */
export const checkProductType = (messageCode?: string, sellableProductType?: boolean): string => {
    let productType = ''
    if (checkIsRestrictedPDP(messageCode, sellableProductType)) {
        productType = restricted
    } else {
        productType = sellable
    }
    return productType
}

/**
 * This function checks the fulfillment availability type
 * @param {FulfillmentDTOP} fulfillment
 * @param {string} messageCode
 * @param {boolean} sellableProductType
 * @param {boolean} homeService
 * @param {boolean} isOutOfStockInAllStore
 * @returns { string } returns fulfillmentType
 */
export const getFulfillmentOptionAvailable = (
    fulfillment: FulfillmentDTOP,
    messageCode?: string,
    sellableProductType?: boolean,
    homeService?: boolean,
    isOutOfStockInAllStore?: boolean,
): string => {
    if (isOutOfStockInAllStore || homeService) return unavailable
    else {
        return getFulfillmentOption(fulfillment, messageCode, sellableProductType)
    }
}

/**
 * This function gives common condition applicable for fulfillment & availability
 * @param {FulfillmentDTOP} fulfillment
 * @param {string} messageCode
 * @param {boolean} sellableProductType
 * @returns { string } returns fulfillmentType
 */
const getFulfillmentOption = (
    fulfillment: FulfillmentDTOP,
    messageCode?: string,
    sellableProductType?: boolean,
): string => {
    let commonFulfillmentType = bopisAndSth
    if (fulfillment && Object.keys(fulfillment).length) {
        const { shipToHome, storePickUp } = fulfillment
        if (checkIsRestrictedPDP(messageCode, sellableProductType)) {
            commonFulfillmentType = inStoreOnly
        } else if (storePickUp?.enabled && shipToHome?.enabled) {
            commonFulfillmentType = bopisAndSth
        } else if (storePickUp?.enabled) {
            commonFulfillmentType = bopisOnly
            // eslint-disable-next-line sonarjs/elseif-without-else
        } else if (shipToHome?.enabled) {
            commonFulfillmentType = sthOnly
        }
    }
    return commonFulfillmentType
}

/**
 * This function checks the media type
 * @param {ProductResponseData} productData
 * @return { string } returns mediaType
 */
const { image, video, imageAndVideo } = mediaConstants
export const checkMediaType = (productData: ProductResponseData): string => {
    const { images, videoList } = productData
    if (isArrayNotEmpty(images) && isArrayNotEmpty(videoList)) {
        return imageAndVideo
    } else if (isArrayNotEmpty(images)) {
        return image
    } else if (isArrayNotEmpty(videoList)) {
        return video
    } else {
        return ''
    }
}

/**
 * This function checks the corporateStatus
 * @param {ProductResponseData} productData
 * @param {Function} isProductDiscontinued
 * @param {Function} inStockInSelectedStore
 * @return { string } returns corporateStatus
 */

export const getCorporateStatus = (
    corporateStatus: string | undefined,
    inStockInSelectedStore: () => boolean,
    isProductDiscontinued: () => boolean,
): string => {
    let corpStatus = ''
    const { FULLY_DISCONTINUED, SEASONALLY_DISCONTINUED, TEMPORARY_DISCONTINUED } = CorporateStatus
    const { fullyDiscontinued, seasonallyDiscontinued, temporaryDiscontinued, outOfStock, discontinued, activeStatus } =
        corporateConstants
    if (corporateStatus === FULLY_DISCONTINUED) {
        corpStatus = fullyDiscontinued
    } else if (corporateStatus === SEASONALLY_DISCONTINUED) {
        corpStatus = seasonallyDiscontinued
    } else if (corporateStatus === TEMPORARY_DISCONTINUED) {
        corpStatus = temporaryDiscontinued
    } else if (!inStockInSelectedStore()) {
        corpStatus = outOfStock
    } else if (isProductDiscontinued()) {
        corpStatus = discontinued
    } else {
        corpStatus = activeStatus
    }
    return corpStatus
}

/**
 * This function gets the category
 * @param {BreadcrumbLinkData} breadcrumbList
 * @returns { string } returns breadcrumbList joined with slash
 */
export const getCategory = (breadcrumbList: BreadcrumbLinkData[]): string => {
    return getBreadcrumbLinks(breadcrumbList).join('/')
}

/**
 * This function gets the BreadcrumbLinks
 * @param {BreadcrumbLinkData} breadcrumbList
 * @returns { string } returns breadcrumbList
 */
export const getBreadcrumbLinks = (breadcrumbList: BreadcrumbLinkData[]): string[] => {
    const breadcrumbsLinksList = isArrayNotEmpty(breadcrumbList) ? getBreadcrumbList(breadcrumbList) : []
    return breadcrumbsLinksList.map((breadcrumb: BreadcrumbParentPageLink) => breadcrumb.name) as string[]
}

/**
 * function to return product title
 * @returns {string} if home service is true return 'Home Services' else return product brand
 * @param {boolean} homeService
 * @param {string} homeServicesTitle
 * @param {ProductResponseData} productData
 */
export const getProductTitle = (
    homeService: boolean,
    homeServicesTitle: string,
    productData: ProductResponseData,
): string => {
    return homeService ? homeServicesTitle : productData.brand?.label
}

/**
 * function to get viewProductDetailsLabel
 * @returns {string}
 */
export const getViewProductDetailsLabel = (): string =>
    document
        ?.querySelector('.titlewrapper span[data-component="TitleWrapper"]')
        ?.getAttribute('data-productdetailslabel') || ''

/**
 * function to return product url
 * @param {boolean} homeService
 * @param {string} serviceLandingPage
 * @param {ProductResponseData} productData
 * @returns {string} if home service is true return serviceLandingPage from AEm global props else return brandUrl from api
 */
export const getProductUrl = (
    homeService: boolean,
    serviceLandingPage: string,
    productData: ProductResponseData,
): string => {
    return homeService ? serviceLandingPage : productData.brand?.url
}

/**
 * Scroll to review section in mobile and tablet view
 */
export const scrollToReviewSection = (): void => {
    const reviewsEle = document.querySelector(
        `#${bazaarVoiceConst.reviews.container}, #${bazaarVoiceConst.reviews.container}-mobile`,
    )
    const isDesktop = window.innerWidth > BREAKPOINTS.tabletMaxWidth
    const accordionEle = reviewsEle?.querySelector(`.${PREFIX}-accordion--minimize`)
    if (!isDesktop && accordionEle) {
        expandAccordion(reviewsEle as HTMLElement)
        scrollToElement(reviewsEle as HTMLElement)
    }
}

/**
 * calculate height of header and/or sticky TOC and apply correct top scroll on click of road rating badge
 */
export const scrollToRoadRating = (): void => {
    scrollToRoadRatingHandler()
}

/**
 * update firstVariant variant option
 * @param {FilteredVariantType} firstVariant firstVariant Variant
 * @param {VariantType} variant
 * @returns {VariantType[]}
 */
const updatedFirstVariant = (firstVariant: FilteredVariantType, variant: VariantType): VariantType[] => {
    variant.firstVariant =
        firstVariant &&
        firstVariant.values.map((firstOption: VariantType) => {
            return {
                ...firstOption,
                isUnavailable: !firstOption.skuCodes.some(sku => variant.skuCodes.includes(sku)),
            }
        })
    return variant.firstVariant
}

/**
 * update secondVariant variant option
 * @param {FilteredVariantType} secondVariant secondVariant Variant
 * @param {VariantType} variant
 * @returns {VariantType[]}
 */
const updatedSecondVariant = (secondVariant: FilteredVariantType, variant: VariantType): VariantType[] => {
    variant.secondVariant =
        secondVariant &&
        secondVariant.values.map((secondOption: VariantType) => {
            return {
                ...secondOption,
                isUnavailable: !secondOption.skuCodes.some(sku => variant.skuCodes.includes(sku)),
            }
        })
    return variant.secondVariant
}

/**
 * update third variant option
 * @param {FilteredVariantType} thirdVariant Third Variant
 * @param {VariantType} variant
 * @returns {VariantType[]}
 */
const updatedThirdVariant = (thirdVariant: FilteredVariantType, variant: VariantType): VariantType[] => {
    variant.thirdVariant =
        thirdVariant &&
        thirdVariant.values.map((thirdOption: VariantType) => {
            return {
                ...thirdOption,
                isUnavailable: !thirdOption.skuCodes.some(sku => variant.skuCodes.includes(sku)),
            }
        })
    return variant.thirdVariant
}

/**
 * Filter colour Variants component for its available sizes and dynamic variants
 * @param {FilteredVariantType} firstVariant First Variant
 * @param {FilteredVariantType} secondVariant Second Variant
 * @param {FilteredVariantType} thirdVariant Third Variant
 * @returns {FilteredVariantType} returns arrays with Variant Details like colorVariant,sizes,dynamicName
 */
export const getAvailableVariants = (
    firstVariant: FilteredVariantType,
    secondVariant: FilteredVariantType,
    thirdVariant: FilteredVariantType,
): FilteredVariantType => {
    const { display, descriptor, sortStatus } = firstVariant || {}
    let filteredData: VariantType[] = []

    if (checkDataLength(firstVariant)) {
        filteredData = firstVariant?.values.map((variant: VariantType) => {
            updatedSecondVariant(secondVariant, variant)
            updatedThirdVariant(thirdVariant, variant)
            return variant
        })
    }

    return {
        values: filteredData,
        display,
        descriptor,
        sortStatus,
    }
}

/**
 * Filter size Variants component for its available colour and dynamic variants
 * @param {FilteredVariantType} firstVariant First Variant
 * @param {FilteredVariantType} secondVariant Second Variant
 * @param {FilteredVariantType} thirdVariant Third Variant
 * @returns {FilteredVariantType} returns arrays with Variant Details like colorVariant,sizes,dynamicName
 */
export const getAvailableSecondVariants = (
    firstVariant: FilteredVariantType,
    secondVariant: FilteredVariantType,
    thirdVariant: FilteredVariantType,
): FilteredVariantType => {
    const { display, descriptor, sortStatus } = secondVariant || {}
    let filteredData: VariantType[] = []

    if (checkDataLength(secondVariant)) {
        filteredData = secondVariant?.values.map((variant: VariantType) => {
            updatedFirstVariant(firstVariant, variant)
            updatedThirdVariant(thirdVariant, variant)
            return variant
        })
    }

    return {
        values: filteredData,
        display,
        descriptor,
        sortStatus,
    }
}

/**
 * Filter dynamic Variants component for its available sizes and colour variants
 * @param {FilteredVariantType} firstVariant First Variant
 * @param {FilteredVariantType} secondVariant Second Variant
 * @param {FilteredVariantType} thirdVariant Third Variant
 * @returns {FilteredVariantType} returns arrays with Variant Details like colorVariant,sizes,dynamicName
 */
export const getAvailableThirdVariants = (
    firstVariant: FilteredVariantType,
    secondVariant: FilteredVariantType,
    thirdVariant: FilteredVariantType,
): FilteredVariantType => {
    const { display, descriptor, sortStatus } = thirdVariant || {}
    let filteredData: VariantType[] = []

    if (checkDataLength(thirdVariant)) {
        filteredData = thirdVariant?.values.map((variant: VariantType) => {
            updatedSecondVariant(secondVariant, variant)
            updatedFirstVariant(firstVariant, variant)
            return variant
        })
    }
    return {
        values: filteredData,
        display,
        descriptor,
        sortStatus,
    }
}

/**
 * This function finds out if product is fully discontinued or discontinue when out
 * EOL - End Of Life - If product is fully discontinued or discontinue when out
 * @param {string | undefined} corporateStatus
 * @returns {boolean} true or false
 */
export const checkIfProductDiscontinuedEOL = (corporateStatus: string | undefined): boolean =>
    corporateStatus === CorporateStatus.FULLY_DISCONTINUED || corporateStatus === CorporateStatus.DISCONTINUED_WHEN_OUT

/**
 * This function finds out if product is Seasonally or Temporarily discontinue
 * @param {string | undefined} corporateStatus
 * @returns {boolean} true or false
 */
export const checkIfProductDiscontinuedTemp = (corporateStatus: string | undefined): boolean =>
    corporateStatus === CorporateStatus.SEASONALLY_DISCONTINUED ||
    corporateStatus === CorporateStatus.TEMPORARY_DISCONTINUED

/**
 * This function finds out if product is fully discontinued or not
 * @param {string | undefined} corporateStatus
 * @returns {boolean} true or false
 */
export const checkIfProductFullyDiscontinued = (corporateStatus: string | undefined): boolean =>
    corporateStatus === CorporateStatus.FULLY_DISCONTINUED

/**
 * This function return true or false to show maximum purchasable error message
 * @param {number} qtySelectorInput
 * @param {FulfillmentDTOP | undefined} selectedVariantFulfillment
 * @param {boolean} showMaxPurchableError
 *
 * @returns {boolean} true or false
 */
const showExceedMaximumPurchableMessage = (
    qtySelectorInput: number,
    selectedVariantFulfillment: FulfillmentDTOP | undefined,
    showMaxPurchableError: boolean,
): boolean => {
    return (
        qtySelectorInput > libUtils.getNumberOrDefault(selectedVariantFulfillment?.maxPurchasableQty) ||
        showMaxPurchableError
    )
}

/**
 * This function used to set quantity selector error message
 * @param {number} qtySelectorInput
 * @param {Function} setQtySelectorErrorMsg
 * @param {number} quantityAtStoreDC
 * @param {number} quantityAtStore
 * @param {ProductResponseData} productData
 * @param {string} selectedProductCode
 * @param {string} exceedMaximumSellQuantityMessage
 * @param {string} exceedMaximumPurchableMessage
 * @param {string} maximumQuantityError
 * @param {ProductSku} productSkuData
 * @param {string} qtySelectorErrorMsg
 * @param {boolean} isSeparatedErrorHandling
 * @param {boolean} isShipToHome
 * @param {boolean} isDenyToUseStoreInventory
 */
export const setQuanitySelectorErrorMsg = (
    qtySelectorInput: number,
    setQtySelectorErrorMsg: (errorMsg: string) => void,
    quantityAtStoreDC: number,
    quantityAtStore: number,
    productData: ProductResponseData,
    selectedProductCode: string,
    exceedMaximumSellQuantityMessage: string,
    exceedMaximumPurchableMessage: string,
    maximumQuantityError: string,
    productSkuData: ProductSku,
    qtySelectorErrorMsg: string,
    isSeparatedErrorHandling: boolean,
    isShipToHome: boolean,
    isDenyToUseStoreInventory: boolean,
    // eslint-disable-next-line max-params
): void => {
    const selectedVariantFulfillment = getSelectedVariantSKU(productData, selectedProductCode, productSkuData)

    // If both purchseable quantity and sellable quantity are same, then maximum purchase-able quantity error should be triggered
    const isSellPurchaseQuantitySame: boolean =
        selectedVariantFulfillment?.maxPurchasableQty === quantityAtStore + quantityAtStoreDC
    const showMaxPurchableError =
        qtySelectorInput > libUtils.getNumberOrDefault(selectedVariantFulfillment?.maxPurchasableQty) &&
        isSellPurchaseQuantitySame
    const totalQuantity = getTotalQuantityOfProduct(
        isSeparatedErrorHandling,
        isShipToHome,
        quantityAtStore,
        quantityAtStoreDC,
        isDenyToUseStoreInventory,
    )

    if (!qtySelectorErrorMsg) {
        if (qtySelectorInput > totalQuantity && !isSellPurchaseQuantitySame) {
            exceedMaximumSellQuantityMessage && setQtySelectorErrorMsg(exceedMaximumSellQuantityMessage)
        } else if (
            showExceedMaximumPurchableMessage(qtySelectorInput, selectedVariantFulfillment, showMaxPurchableError)
        ) {
            exceedMaximumPurchableMessage && setQtySelectorErrorMsg(exceedMaximumPurchableMessage)
        } else {
            maximumQuantityError && setQtySelectorErrorMsg(maximumQuantityError)
        }
    }
}

/**
 * This function used to get quantity of product
 * @param {boolean} isSeparatedErrorHandling
 * @param {boolean} isShipToHome
 * @param {number} quantityAtStore
 * @param {number} quantityAtStoreDC
 * @param {boolean} isDenyToUseStoreInventory
 *
 * @returns {number} total quantity of product
 */
const getTotalQuantityOfProduct = (
    isSeparatedErrorHandling: boolean,
    isShipToHome: boolean,
    quantityAtStore: number,
    quantityAtStoreDC: number,
    isDenyToUseStoreInventory: boolean,
): number => {
    const inventoryQuantity = isDenyToUseStoreInventory ? quantityAtStoreDC : quantityAtStoreDC + quantityAtStore

    return isSeparatedErrorHandling && !isShipToHome ? quantityAtStore : inventoryQuantity
}

const createProductInfoRequestPayload = (
    vehicleInfo: VehiclePayload | null,
    productCode: string,
    quantity: number,
    deliveryModeCode: string,
    tiresData?: TireType | null,
    isTireProductPage?: boolean,
    mrRegistryId?: number,
    bundleTemplateId?: string | null,
    isHeliumInflationSelected?: boolean,
    // eslint-disable-next-line max-params
) => {
    const store = appCacheService.preferredStoreId.get()
    const heliumInflationStoreServiceType: IHeliumInflationStoreServiceType[] = [
        {
            type: inflationData.inflationType,
            value: inflationData.heliumAddOn,
        },
    ]

    let productInfoPayload = {
        product: {
            code: productCode,
        },
        quantity: quantity,
        deliveryPointOfService: {
            name: store,
        },
        // eslint-disable-next-line no-warning-comments
        deliveryMode: { code: deliveryModeCode }, // TODO: Need to update delivery mode when information is integrated
    }

    if (vehicleInfo && !(isTireProductPage && checkDataLength(tiresData))) {
        productInfoPayload = { ...productInfoPayload, ...{ vehicleInformation: vehicleInfo } }
    }

    if (isHeliumInflationSelected) {
        productInfoPayload = { ...productInfoPayload, ...{ selectedStoreServices: heliumInflationStoreServiceType } }
    }

    if (mrRegistryId) {
        productInfoPayload = { ...productInfoPayload, ...{ mrRegistryId: mrRegistryId } }
    }

    if (bundleTemplateId && isHeliumInflationSelected) {
        productInfoPayload = { ...productInfoPayload, ...{ bundleTemplateId: bundleTemplateId } }
    }

    return productInfoPayload
}

/**
 * Function used to return bundle ID if present in productSkuData
 * @param { ProductSku[] } skusData
 * @return { string | null }
 */
const getBundleTemplateOfProduct = (skusData: ProductSku[]): string | null => {
    let bundleTemplateId = null
    skusData?.forEach(sku => {
        sku?.bundleTemplates?.find(bundle => {
            if (!checkNotNullAndUndefined(bundle.id)) bundleTemplateId = bundle.id
        })
    })
    return bundleTemplateId
}

/**
 * function to create orderentry on ATC click from buybox
 * @param { number } qtySelectorInput
 * @param { string } selectedProductCode
 * @param { ProductResponseData } productData
 * @param { VehicleState | null } vehicleInformation
 * @param { string } deliveryModeCode
 * @param { CriticalFitmentProduct } staggeredSkuData
 * @param { number } qtySelectorInputFront
 * @param { number } qtySelectorInputRear
 * @return { OrderEntryDTO[] }
 */

export const createOrderEntriesPayloadForATCBuybox = (
    qtySelectorInput: number,
    selectedProductCode: string,
    productData: ProductResponseData,
    vehicleInformation: VehiclePayload | null,
    deliveryModeCode: string,
    tiresData: TireType | null,
    isTireProductPage: boolean,
    staggeredSkuData?: CriticalFitmentProduct,
    qtySelectorInputFront?: number,
    qtySelectorInputRear?: number,
    productSkuData?: ProductSkusData,
    mrRegistryId?: number,
    isHeliumInflationSelected?: boolean,
    // eslint-disable-next-line max-params
): OrderEntryDTO[] => {
    if (!staggeredSkuData?.isStaggered) {
        const productCode = selectedProductCode || productData.code
        const requestPayload = createProductInfoRequestPayload(
            vehicleInformation,
            productCode,
            qtySelectorInput,
            deliveryModeCode,
            tiresData,
            isTireProductPage,
            mrRegistryId,
            getBundleTemplateOfProduct(productData.skus),
            isHeliumInflationSelected,
        )
        return [requestPayload]
    } else {
        const orderEntriesArrForStaggeredData: OrderEntryDTO[] = []
        staggeredSkuData?.staggeredFitment?.forEach((staggeredSku: FitmentNote) => {
            const filteredSku = productSkuData?.skus?.find((skuObj: ProductSku) => skuObj.code === staggeredSku.sku)
            const requestPayload = createProductInfoRequestPayload(
                vehicleInformation,
                libUtils.getStringOrDefault(staggeredSku.sku),
                isStaggeredFront(staggeredSku.note)
                    ? libUtils.getNumberOrDefault(qtySelectorInputFront)
                    : libUtils.getNumberOrDefault(qtySelectorInputRear),
                deliveryModeCode,
                tiresData,
                isTireProductPage,
            )
            if (
                filteredSku?.fulfillment?.availability?.quantity ||
                filteredSku?.fulfillment?.availability?.Corporate?.Quantity
            ) {
                orderEntriesArrForStaggeredData.push(requestPayload)
            }
        })
        return orderEntriesArrForStaggeredData
    }
}

export const createOrderEntriesPayload = (
    qtySelectorInput: number,
    selectedProductCode: string,
    productData: ProductResponseData,
    fbtProductCode: string[],
    clickedCTAName: string,
    isShipToHome: boolean,
    isExpressDelivery: boolean,
    tiresData?: TireType | null,
    packageId?: string,
    isPackageFlow?: boolean,
    vehicle?: VehicleState,
    staggeredSkuData?: CriticalFitmentProduct,
    qtySelectorInputFront?: number,
    qtySelectorInputRear?: number,
    productSkuData?: ProductSkusData,
    mrRegistryId?: number,
    isHeliumInflationSelected?: boolean,
    // eslint-disable-next-line max-params
): OrderEntryDTO[] => {
    const deliveryModeCode = isShipToHome ? FulfillmentMethods.STH : FulfillmentMethods.BOPIS
    const fulfillmentMethod = getFulfillmentMethod(isShipToHome, isExpressDelivery)
    const vehicleInformation = vehicle ? createVehiclePayload(vehicle) : null
    const isTireProductPage = isTirePDP(productData?.productWheelType)
    if (clickedCTAName === CTAClickedName.fbtCTAClickedName && fbtProductCode && checkDataLength(fbtProductCode)) {
        const orderEntriesArr: OrderEntryDTO[] = []
        fbtProductCode.forEach((prdCode: string) => {
            const quantity = 1
            const requestPayload = createProductInfoRequestPayload(
                vehicleInformation,
                prdCode,
                quantity,
                deliveryModeCode,
            )
            orderEntriesArr.push(requestPayload)
        })
        return orderEntriesArr
    } else if (isPackageFlow) {
        const productCode = selectedProductCode || productData.code
        let requestPayload = createProductInfoRequestPayload(
            vehicleInformation,
            productCode,
            qtySelectorInput,
            deliveryModeCode,
        )
        requestPayload = {
            ...requestPayload,
            ...{ isPackageItem: isPackageFlow, packageId: packageId },
        }
        return [requestPayload]
    } else {
        return createOrderEntriesPayloadForATCBuybox(
            qtySelectorInput,
            selectedProductCode,
            productData,
            vehicleInformation,
            fulfillmentMethod,
            tiresData as TireType,
            isTireProductPage,
            staggeredSkuData,
            qtySelectorInputFront,
            qtySelectorInputRear,
            productSkuData,
            mrRegistryId,
            isHeliumInflationSelected,
        )
    }
}

export const createVehiclePayload = (vehicle: VehicleState) => {
    const defaultVehicle = vehicle?.defaultVehicle
    const autoAttributes = defaultVehicle?.autoAttributes
    const engineConfigId = autoAttributes?.engineConfigId?.toString()

    let vehiclePayload: VehiclePayload = {
        baseVehicleId: defaultVehicle?.baseVehicleId,
        type: defaultVehicle?.vehicleType,
        make: autoAttributes?.make,
        model: autoAttributes?.model,
        body: autoAttributes?.body,
        options: autoAttributes?.option,
        year: autoAttributes?.year,
        engineConfigId: undefined,
    }

    if (engineConfigId) {
        vehiclePayload = { ...vehiclePayload, engineConfigId }
    }

    return vehiclePayload
}

/**
 * This function checks the display of winterPolicyModal
 * @param {number} qtySelectorInput
 * @param {string} tireCategory
 * @param {Function} setIsWinterPolicyModalOpen
 * @param {string} winterTirePolicyModalTitle
 * @param {boolean} isWinterPolicyModalOpen
 */

const {
    event: { warning },
    eventParameters: {
        action: { modalDisplayed },
    },
    products: {
        rebate: { mailInRebate, instantRebate },
    },
} = analyticsAttributes
export const showWinterTirePolicyModal = (
    isWinterTirePolicyModal: boolean,
    tireCategory: boolean,
    setIsWinterPolicyModalOpen: (val: boolean) => void,
    winterTirePolicyModalTitle: string | undefined,
    isWinterPolicyModalOpen: boolean,
): void => {
    if (isWinterTirePolicyModal && tireCategory) {
        setIsWinterPolicyModalOpen(true)
        isWinterPolicyModalOpen &&
            addToCartAnalyticsError(libUtils.getStringOrDefault(winterTirePolicyModalTitle), warning, modalDisplayed)
    } else {
        setIsWinterPolicyModalOpen(false)
    }
}

export const extractFulfillmentBasedOnSku = (productData: ProductResponseData, skuCode: string): FulfillmentDTOP => {
    let fulfillmentData = {} as FulfillmentDTOP
    if (Object.keys(productData).length && skuCode) {
        // eslint-disable-next-line consistent-return
        productData.skus?.find(value => {
            if (value.code === skuCode) {
                fulfillmentData = value.fulfillment
                return true
            }
        })
    }
    return fulfillmentData
}

/**
 * This function iterates over nearby stores and finds out if quantity for given SKU is available at any nearby stores for DC
 * @param {StoreWithAvailability | null} storeDetails
 * @param {Function} getInventoryQtyFromSKUForDC
 * @param {Function} hasOnlyBOPISEnabled
 * @param {isSeparatedErrorHandling} isSeparatedErrorHandling
 * @returns {boolean} true or false if avaialble in nearby store for DC
 */
export const isInStockAtDC = (
    storeDetails: StoreWithAvailability | null,
    getInventoryQtyFromSKUForDC: () => number | undefined,
    hasOnlyBOPISEnabled: () => boolean,
    isSeparatedErrorHandling: boolean | undefined,
): boolean => {
    const inventoryQuantityAtDC = storeDetails ? getInventoryQtyFromSKUForDC() || 0 : 0

    return !(isSeparatedErrorHandling && hasOnlyBOPISEnabled()) && inventoryQuantityAtDC > 0
}

/**
 * Returns Selected Preferred Store
 * @param {StoreWithAvailability} nearbyStoreList
 * @param {string} selectedPreferredStoreId
 * @returns {StoreWithAvailability}
 */
export const getSelectedPreferredStore = (
    nearbyStoreList: StoreWithAvailability[],
    selectedPreferredStoreId: string,
): StoreWithAvailability | undefined => {
    return nearbyStoreList?.find(nearbyStore => nearbyStore.id === parseInt(selectedPreferredStoreId, 10))
}

/**
 * Returns Financing available for product
 * @param {number} currentPrice
 * @param {number} thresholdValue
 * @param {boolean} isQubecRegion
 * @return {boolean}
 */

export const isFinancingAvailable = (currentPrice: number, thresholdValue: number, isQubecRegion: boolean): boolean => {
    return currentPrice > thresholdValue && !isQubecRegion
}

/**
 * Function to get selected Product
 * @param {ProductResponseData} productData
 * @param {string} selectedProductCode
 * @param {ProductSkusData} productSkuData
 * @returns {ProductSku | undefined}
 */
export const getSelectedProduct = (
    productData: ProductResponseData,
    selectedProductCode: string,
    productSkuData: ProductSkusData = { skus: [] },
): ProductResponseData | ProductSku | undefined => {
    // get skus from data
    const skus = productData?.skus || []
    // get sku data deom PnA call for merge
    const pnASku = productSkuData.skus?.find(product => product.code === selectedProductCode)
    return !!skus.length && selectedProductCode && selectedProductCode !== productData?.code
        ? ({
              // merge product level and PnA data to return full product data
              ...skus.find(product => product.code === selectedProductCode),
              ...pnASku,
          } as ProductSku)
        : productData
}

/**
 * Function returns boolean value for checking shopping is with or without vehicle.
 * @param {Vehicle} defaultVehicle
 * @param {string} productWheelType
 * @returns {boolean}
 */
export const isShopWithVehicle = (defaultVehicle: Vehicle, productWheelType: string): boolean => {
    const isTire = productWheelType?.toUpperCase() === productWheelTypes.Tire // the page is tire PDP is checked
    const tireSizeData = appCacheService.tiresSize.get() // tire size data is fetched from local storage
    const wheelAutoShopWithVehicle = checkDataLength(defaultVehicle)
    // eslint-disable-next-line sonar/no-nested-conditional
    return isTire ? (tireSizeData ? tireSizeData.length <= 0 : wheelAutoShopWithVehicle) : wheelAutoShopWithVehicle
}

/**
 * This function returns if product is wheels PDP or not
 * @param {ProductResponseData} productData
 * @returns { boolean } returns true or false
 */
export const isWheelsPDP = (productData: ProductResponseData): boolean | undefined => {
    return productData?.productWheelType?.toUpperCase() === productWheelTypes.Wheel
}

/**
 * This function calculates the fulfillmentDaysAway
 * @param {FulfillmentDTOP} fulfillment
 * @param {boolean} isShipToHome
 * @returns {string}
 */
export const getFulfillmentDays = (fulfillment: FulfillmentDTOP, isShipToHome: boolean): string => {
    return getDaysDifference(
        isShipToHome ? fulfillment?.shipToHome?.etaEarliest : fulfillment?.storePickUp?.etaEarliest,
    )
}

/**
 * function to return setOfFour or each fee value
 * @param {ProductResponseData} productData
 * @param {number} value
 * @param {boolean} hideVehicleSelectorBannerState
 * @param {boolean} isStaggered
 * @param {ProductSku[]} staggeredSkuList
 * @returns {number}
 */
export const getFeeValue = (
    productData: ProductResponseData,
    value: number,
    hideVehicleSelectorBannerState: boolean,
    isStaggered: boolean,
    staggeredSkuList?: ProductSku[],
): number => {
    if ((isWheelsPDP(productData) || isTirePDP(productData?.productWheelType)) && !hideVehicleSelectorBannerState) {
        if (!isStaggered) {
            return value * setOfFourCount
        } else if (isStaggered && staggeredSkuList) {
            return pdpStaggeredTotalPrice(staggeredSkuList, true) as number
        } else return libUtils.getNumberOrDefault(productData?.feeMessages?.[0]?.value) * setOfFourCount
    } else return value
}

export const getRebateDetails = (rebateDetails: RebateData): string | undefined => {
    if (checkDataLength(rebateDetails)) {
        if (rebateDetails?.instant) {
            return instantRebate.concat(': ', String(libUtils.getNumberOrDefault(rebateDetails?.value)))
        } else {
            return mailInRebate.concat(': ', String(libUtils.getNumberOrDefault(rebateDetails?.value)))
        }
    } else return undefined
}

export const getPdpType = (isAutomotive: boolean, service: boolean): string | undefined => {
    if (service) return homeServiceConstants.service
    else if (isAutomotive) {
        return analyticsAttributes.level.automotive
    } else return analyticsAttributes.level.generalMerch
}

/**
 * check if productType is Home Service or not
 * @param { string } productType
 * @returns { boolean }
 */
export const isHomeServiceItem = (productType?: string): boolean => {
    return productType === ProductTypeList.SERVICE
}

/**
 * return maxSellable qty for staggered fit product
 * @param { ProductSku } skuData
 * @param {ProductResponseData} productData
 * @param {number} bannerMaxPurchasableQuantity
 * @returns { number }
 */
export const staggeredMaxSellableQty = (
    skuData: ProductSku,
    productData: ProductResponseData,
    bannerMaxPurchasableQuantity: number,
): number => {
    const staggeredInStoreQuantity = skuData?.fulfillment?.availability?.quantity || 0
    const staggeredDCQauntity = skuData?.fulfillment?.availability?.Corporate?.Quantity || 0
    const maxQty = staggeredDCQauntity + staggeredInStoreQuantity
    const skuObject = getSelectedVariantSKU(productData, skuData?.code, skuData)
    const skuPcodePurchasableQty = checkDataLength(skuObject as unknown as FulfillmentDTOP)
        ? skuObject?.maxPurchasableQty || productData.maxPurchasableQty
        : 0
    return checkMaxQuantity(
        libUtils.getNumberOrDefault(skuPcodePurchasableQty),
        maxQty,
        !!bannerMaxPurchasableQuantity ? Number(bannerMaxPurchasableQuantity) : 0,
    )
}

/**
 * This function determines the guaranteed fitment
 * @param { string } vehicleFit
 * @returns { string }
 */
export const guranteedFitment = (vehicleFit: string | undefined): string => {
    let guranteedFitmentType = ''
    if (vehicleFit === VehicleFitType.Fit) {
        guranteedFitmentType = guranteedFitments.true
    } else if (vehicleFit === VehicleFitType.NotFit) {
        guranteedFitmentType = guranteedFitments.undefined
    } else if (vehicleFit === VehicleFitType.PartialFit) {
        guranteedFitmentType = guranteedFitments.false
    } else {
        guranteedFitmentType = guranteedFitments.notApplicable
    }
    return guranteedFitmentType
}

/**
 * function to check if FBT product is out of stock ot not
 * @param {FbtProductWithAvailability} product
 * @param {number} index
 * @returns {boolean | undefined}
 */
export const checkIfFBTProductAvailable = <Product extends FbtProductWithAvailability>(
    product: Product,
): boolean | undefined => {
    return (
        checkNumberNotNullAndUndefined(product?.fulfillment?.availability?.quantity) === MagicNumber.ZERO &&
        checkNumberNotNullAndUndefined(product?.fulfillment?.availability?.Corporate?.Quantity) === MagicNumber.ZERO
    )
}

// Check if Front or Rear sku is OOS for staggered skus
export const isFrontOrRearSkuOOS = (staggeredSkus: ProductSku[]) =>
    Boolean(
        staggeredSkus?.find(
            (strgSku: ProductSku) =>
                !strgSku?.fulfillment?.availability?.quantity &&
                !strgSku?.fulfillment?.availability?.Corporate?.Quantity,
        ),
    )
/**
 * function to remove duplicate values from an array of objects
 * @param {SkuListType[]} skusList array to check
 * @returns {SkuListType[]}
 */
export const removeDuplicateSkus = (skusList: SkuListType[]): SkuListType[] => {
    return skusList.filter(
        (skuItem: SkuListType, index: number) =>
            index === skusList.findIndex((skuData: SkuListType) => skuData.code === skuItem.code),
    )
}

/**
 * function to get staggered skus list
 * @param {ProductSku[]} skus
 * @returns {string[]}
 */
export const getSaggeredSkus = (skus: ProductSku[]): string[] => {
    return skus?.map((strgSku: ProductSku) => {
        return strgSku.code
    })
}

/**
 * Returns OOS store error message for restricted pdp
 * @param {boolean} isRestrictedPDP
 * @param {VariantDetails} variantDetails
 * @param {string} outOfStockVariantGenericMsg
 * @param {string} outOfStockVariantMsg
 * @param {string} firstVariantLabel
 * @param {string} outOfStockText
 * @returns { string } Returns OOS store error message for restricted pdp
 */
export const getOOSErrorMsgForRestrictedPDP = (
    isRestrictedPDP: boolean,
    variantDetails: VariantDetails,
    outOfStockVariantGenericMsg: string,
    outOfStockVariantMsg: string,
    firstVariantLabel: string,
    outOfStockText: string,
): string => {
    let outOfStockMsg = outOfStockText
    const firstVariantArr = variantDetails?.firstVariant
    const anotherVariantArr = variantDetails?.secondVariant
    if (isRestrictedPDP && isArrayNotEmpty(firstVariantArr.values)) {
        if (isArrayNotEmpty(anotherVariantArr?.values)) {
            outOfStockMsg = outOfStockVariantGenericMsg
        } else {
            outOfStockMsg = replaceStrWithDynamicVal(outOfStockVariantMsg, firstVariantLabel?.toLowerCase())
        }
    }
    return outOfStockMsg
}

/**
 * Checks for PDP if correct package date is available for package analytics to trigger
 * @param {boolean} isPackageFlow
 * @param {AutoPackages[]} autoPackages
 * @returns { boolean } Returns true if package data exists
 */
export const checkPackageDataExist = (isPackageFlow: boolean, autoPackages: AutoPackages[]): boolean => {
    if (isPackageFlow) {
        if (autoPackages[0]?.changeSelectedLink) {
            return !!(autoPackages[0]?.vehicleInfo?.tireData || autoPackages[0]?.vehicleInfo?.wheelData)
        }
        return Boolean(
            !!autoPackages[0].packageId &&
                (autoPackages[0]?.vehicleInfo?.tireData || autoPackages[0]?.vehicleInfo?.wheelData),
        )
    } else return true
}

/**
 * function to get isBulk parameter
 * @param {ProductResponseData} productData
 * @param {string} sku
 * @return {boolean | undefined}
 */
// the return type is boolean | undefined here in case sku data does not have isBulk populated or sku data is not found. in this case the service call to add to cart will drop the isBulk field
export const getIsBulk = (productData: ProductResponseData, sku?: string): boolean | undefined => {
    const skuFiltered = productData.skus.find(productSku => productSku.code === sku)
    return skuFiltered?.isBulk
}

/**
 * function extract corporateStatus from product api at skulevel
 * @param {ProductResponseData} productData
 * @param {string} selectedProductCode
 * @returns {string}
 */
export const extractCorporateStatusBasedOnSku = (
    productData: ProductResponseData,
    selectedProductCode?: string,
): CorporateStatus | undefined => {
    const skuFiltered = productData.skus?.find(productSku => productSku.code === selectedProductCode)
    return skuFiltered?.corporateStatus
}

/**
 * function to get formatted sku code
 * @param {string} selectedSkuCode
 * @param {ProductResponseData} productData
 * @return {string}
 */

export const getFormattedSkuCode = <ProductData extends ProductSkusData>(
    selectedSkuCode: string,
    productData: ProductData,
): string => {
    let formattedCode = ''
    if (!!selectedSkuCode) {
        const selectedProduct = productData?.skus?.find((skuObj: ProductSku) => skuObj.code === selectedSkuCode)
        formattedCode = (checkDataLength(selectedProduct) ? selectedProduct?.formattedCode : '') as string
    }
    return !!formattedCode ? formattedCode : ''
}

/**
 * function extract staggred skus
 * @returns { Record<string, string> }
 */
export const extractStaggeredSkuFromUrl = (): Record<string, string> => {
    const { pdpPage, productPage } = pageTypes
    const pageType = getPageType()
    const path = window.location.pathname.toLowerCase().split('.')
    const skuCodes =
        // eslint-disable-next-line sonar/expression-complexity
        pageType === pdpPage || (pageType === productPage && path.length === MagicNumber.FOUR)
            ? // eslint-disable-next-line sonar/no-nested-conditional
              Number(path[MagicNumber.ONE]) && Number(path[MagicNumber.TWO])
                ? path[MagicNumber.ONE] + ',' + path[MagicNumber.TWO]
                : ''
            : ''
    return { skuCodes }
}

/**
 * Returns Wishlist Payload without vehicle information
 * @param {string[]} productCodeArray
 * @returns {AddToWishListPayload}
 */
export const wishlistEntriesWithoutVehicleInfo = (productCodeArray: string[]): AddToWishListPayload => {
    return {
        wishListEntries: productCodeArray.map(code => {
            return {
                product: {
                    code,
                },
            }
        }) as WishListEntry[],
    }
}

/**
 * Returns AddToWishListPayload
 * @param {string[]} productCodeArray
 * @param {boolean} isAutomotive
 * @param {Vehicle} defaultVehicle
 * @param {boolean} isTireSizePresent
 * @param {boolean} enableVehicleInformationOnWishlist
 * @returns {AddToWishListPayload}
 */
export const returnAddToWishListPayload = (
    productCodeArray: string[],
    isAutomotive: boolean,
    defaultVehicle: Vehicle,
    isTireSizePresent: boolean,
    enableVehicleInformationOnWishlist: boolean,
): AddToWishListPayload => {
    if (enableVehicleInformationOnWishlist) {
        if (isAutomotive && defaultVehicle && !Array.isArray(defaultVehicle) && !isTireSizePresent) {
            const autoAttributes = defaultVehicle.autoAttributes
            const vehicleInformation: VehicleInformation = {
                baseVehicleId: defaultVehicle.baseVehicleId,
                body: autoAttributes?.body,
                model: libUtils.getStringOrDefault(autoAttributes?.model),
                options: autoAttributes?.option,
                type: libUtils.getStringOrDefault(defaultVehicle.vehicleType),
                year: libUtils.getStringOrDefault(autoAttributes?.year),
                make: libUtils.getStringOrDefault(autoAttributes?.make),
            }
            if (autoAttributes?.engineConfigId) {
                vehicleInformation.engineConfigId = autoAttributes.engineConfigId.toString()
            }
            if (autoAttributes?.boltPattern) {
                vehicleInformation.boltPattern = autoAttributes.boltPattern
            }
            return {
                wishListEntries: productCodeArray.map(code => {
                    return {
                        product: {
                            code,
                        },
                        vehicleInformation,
                    }
                }) as WishListEntry[],
            }
        } else {
            return wishlistEntriesWithoutVehicleInfo(productCodeArray)
        }
    } else {
        return wishlistEntriesWithoutVehicleInfo(productCodeArray)
    }
}

/**
 * Function to calculate intersection of multiple arrays
 * @returns {string[]} intersection
 */
export const findIntersection = (...arrays: string[][]): string[] => {
    const result = [...arrays]?.reduce((a, b): string[] => a.filter((c: string): boolean => b.includes(c)))
    return Array.from(new Set(result))
}

/**
 * This function checks express delivery eligibility
 * @return {boolean}
 */

export const checkExpressDeliveryEligibility = (
    isExpressDeliveryEligible: boolean,
    inStockInSelectedStore: boolean,
    onlineOrdering: boolean,
    isBulk: boolean,
): boolean => {
    return isExpressDeliveryEligible && inStockInSelectedStore && onlineOrdering && !isBulk
}

/**
 * Check that an object has a valid currentPrice
 * @param {any} objectWithPrices
 * @returns {boolean}
 */
export const hasValidCurrentPrice = (objectWithPrices: ProductOptionValue | undefined): boolean => {
    return Boolean(objectWithPrices && isValidPrice(objectWithPrices.currentPrice))
}

/**
 * This function checks if delivery options are present
 * @return {boolean}
 */

export const checkDeliveryOptions = (deliveryOptions: SuccessResponse): boolean => {
    return deliveryOptions && Object.keys(deliveryOptions).length !== MagicNumber.ZERO
}

/**
 * This function checks if we should to hide update postal code input field and set the updated postal code
 * @return {boolean}
 */

export const isUpdatingOfPostalCodeWasSuccessful = (
    postalCodeSuccess: boolean,
    isChangeClicked: boolean,
    showEstimateFeesModal: boolean,
): boolean => {
    return postalCodeSuccess && !isChangeClicked && !showEstimateFeesModal
}

/**
 * This function checks if the product is available for STH or for Express Delivery
 * @return {boolean}
 */

export const checkIfProductAvailableForOrderFromSTH = (
    isStoreEligibleForExpressDelivery: boolean,
    isProductEligibleForExpressDelivery: boolean,
    isShipToHomeEnabled: boolean,
    allVariantsSelected: boolean,
): boolean => {
    if (!allVariantsSelected && isStoreEligibleForExpressDelivery) {
        return true
    }

    return isStoreEligibleForExpressDelivery
        ? isProductEligibleForExpressDelivery || isShipToHomeEnabled
        : isShipToHomeEnabled
}

/**
 * This function checks if the product is available for at nearby stores and available for online ordering
 * but is not in stock in selected store
 * @return {boolean}
 */

export const checkIfProductIsAvailableOnlyAtNearbyStores = (
    inStockInSelectedStore: boolean,
    onlineOrdering: boolean,
    inStockAtNearbyStores: boolean,
): boolean => {
    return !inStockInSelectedStore && onlineOrdering && inStockAtNearbyStores
}

/**
 * function for checking availability of EDD Split Qty
 * @return {boolean}
 */

export const checkRenderEstimatedDeliveryDateSplitQty = (
    isOrderFulfillFromDC: boolean,
    postalCodeVal: string,
    isEstimatedDeliveryDateAvailable: boolean,
) => {
    return isOrderFulfillFromDC && postalCodeVal && isEstimatedDeliveryDateAvailable
}

/**
 * function for checking necessity of rendering store and dc availability
 * @return {boolean}
 */

export const checkNecessityOfRenderStoreAndDCAvailability = (
    inStockAtDC: boolean,
    onlineOrdering: boolean,
    instoreQty: number,
    isOrderFulfillFromDC: boolean,
    orderable: boolean,
) => {
    // eslint-disable-next-line sonar/expression-complexity
    return inStockAtDC && onlineOrdering && !!instoreQty && isOrderFulfillFromDC && orderable
}

/**
 * function for checking of availability of EDD
 * @return {boolean}
 */

export const checkEstimateDeliveryDateAvailability = (
    estimateDeliveryDateSuccess: boolean,
    estimateDeliveryDateData: EstimateDeliveryDate | null,
) => {
    return (
        estimateDeliveryDateSuccess &&
        estimateDeliveryDateData &&
        estimateDeliveryDateData?.startDate &&
        estimateDeliveryDateData?.endDate
    )
}

/**
 * Renders standard delivery wrapper labels
 * @param {object} standardDeliveryLabels
 * @returns {JSX.Element} returns standard delivery wrapper labels
 */
export const getStandardDeliveryLabel = (standardDeliveryLabels: StandardDeliveryDateType): JSX.Element | null => {
    const {
        isOrderFulfillFromDC,
        isStoreEligibleForExpressDelivery,
        isShipToHomeEnabled,
        renderEstimateDeliveryLabel,
        rendersStandardDeliveryWrapper,
    } = standardDeliveryLabels
    return isStoreEligibleForExpressDelivery && isShipToHomeEnabled && !isOrderFulfillFromDC
        ? rendersStandardDeliveryWrapper
        : renderEstimateDeliveryLabel
}

/**
 * function for if render available be label and tooltip
 * @return {boolean}
 */

export const checkIfRenderIsAvailableByLabelAndTooltip = (
    isProductLevel: boolean,
    inStockInSelectedStore: boolean,
    inStockAtNearbyStores: boolean,
    assemblyRequired: boolean,
) => {
    return (isProductLevel || inStockInSelectedStore || inStockAtNearbyStores) && !assemblyRequired
}

/**
 * function to render available by label and tooltip based on product availability
 * @return {boolean}
 */

export const checkRenderIsAvailableByLabelAndTooltip = (
    enableShowOOSOnFulfillmentBlock: boolean,
    showOOSWarning: boolean,
    isRenderAvailableByLabelAndTooltip: boolean,
) => {
    return enableShowOOSOnFulfillmentBlock
        ? !showOOSWarning && isRenderAvailableByLabelAndTooltip
        : isRenderAvailableByLabelAndTooltip
}

/**
 * function for checking if user is clicking ATC button without updating postal code
 * @return {boolean}
 */

export const checkIfNeedToShowErrMsgWithoutUpdPC = (
    isAddToCartClicked: boolean,
    postalCodeSuccess: boolean,
    postalCodeFailure: boolean,
    isPostalCode: boolean,
) => {
    return isAddToCartClicked && ((!postalCodeSuccess && !postalCodeFailure) || isPostalCode)
}

export const checkIfResetEstimatedPCAllowed = (
    deliveryOptionsErrorResponse: ErrorResponse | undefined | null,
    showEstimateFeesModal: boolean | undefined,
): boolean => Boolean(deliveryOptionsErrorResponse) && Boolean(showEstimateFeesModal)

export const checkIfExpressDeliveryForNotAllowed = (
    deliveryOptions: SuccessResponse,
    isExpressDeliveryEligibleForPostalCode: boolean | undefined,
): boolean => checkDeliveryOptions(deliveryOptions) && !isExpressDeliveryEligibleForPostalCode

/**
 * This function searches for the selected first variant value from the given productData based on the variantDetails.
 * @param {VariantDetails} variantDetails
 * @param {string} selectedFirstVariantId
 * @param {ProductResponseData} productData
 * @returns {ProductOptionValue}
 */
export const getSelectedFirstVariantValues = (
    variantDetails: VariantDetails,
    selectedFirstVariantId: string,
    productData: ProductResponseData,
): ProductOptionValue | undefined => {
    const isFirstOption = (item: ProductOption): boolean =>
        item?.descriptor === variantDetails?.firstVariant?.descriptor

    const isSelectedValue = (item: ProductOptionValue): boolean => item.id === selectedFirstVariantId

    return productData?.options?.find(isFirstOption)?.values?.find(isSelectedValue)
}

/**
 * This function for checking express delivery eligibility for selected skus
 * @param {ProductResponseData} productData
 * @param {string} sku
 * @returns {boolean}
 */
export const checkExpressDeliveryEligibilityForSelectedSku = (
    productData: ProductResponseData,
    sku: string,
): boolean => {
    return productData.skus?.find(item => item.code === sku)?.isEligibleForExpressDelivery as boolean
}

/**
 * Function to get fulfillment availability label
 *  @param {boolean} isShipToHome
 *  @param {string} shipsByLabel
 *  @param {string} availableByLabel
 *  @return {string}
 */

export const getFulfillmentLabel = (
    isShipToHome: boolean | undefined,
    shipsByLabel: string | undefined,
    availableByLabel: string | undefined,
): string => {
    const availabilityLabel = isShipToHome ? shipsByLabel : availableByLabel
    return availabilityLabel || ''
}

/**
 * Return true if product have one sku (for all product)
 * @param {ProductResponseData}productData
 * @returns {boolean}
 */
export const isSingleSku = (productData?: ProductResponseData): boolean =>
    checkDataLength(productData) && isArrayNotEmpty(productData?.skus) && productData?.skus?.length === MagicNumber.ONE

/**
 * Function to get shipping Threshold
 *  @param {boolean} isTriangleSelectUser
 *  @param {number} triangleShippingThreshold
 *  @param {number} regularShippingThreshold
 *  @returns {number}
 */
export const getShippingThreshold = (
    isTriangleSelectUser: boolean,
    triangleShippingThreshold: number,
    regularShippingThreshold: number,
): number => {
    return isTriangleSelectUser ? triangleShippingThreshold : regularShippingThreshold
}

/**
 * Function to get title for Standard delivery
 *  @param {number} productPrice
 *  @param {number} shippingThreshold
 *  @param {string} freeStandardDeliveryLabel
 *  @param {string} freeStandardDeliveryWithPriceLimit
 *  @returns {string}
 */
export const getTitleOfStandardDelivery = (
    productPrice: number,
    shippingThreshold: number,
    freeStandardDeliveryLabel: string,
    freeStandardDeliveryWithPriceLimit: string,
): string => {
    return productPrice > shippingThreshold
        ? freeStandardDeliveryLabel
        : replaceStrWithDynamicVal(freeStandardDeliveryWithPriceLimit, shippingThreshold)
}

/**
 * Function to get class for cost label
 *  @param {boolean} isStoreEligibleForExpressDelivery
 *  @returns {string}
 */
export const getSthCostCalculationLabelClass = (isStoreEligibleForExpressDelivery: boolean): string => {
    return isStoreEligibleForExpressDelivery ? `${PREFIX}-switch-fulfillment__sth__shipping-cost-label` : ''
}

/**
 * Function to get class for OOS warning
 *  @param {boolean} isAccordionCollapsedText
 *  @returns {string}
 */
export const getOOSWarningClass = (isAccordionCollapsedText: boolean): string => {
    return isAccordionCollapsedText ? `${PREFIX}-switch-fulfillment__show-oos-warning` : ``
}

/**
 * Function to get class for STH only products
 *  @param {boolean} isOnlySTHEnabled
 *  @param {boolean} disableBopisBlock
 *  @returns {string}
 */
export const getOnlySTHClass = (isOnlySTHEnabled: boolean, disableBopisBlock: boolean): string => {
    return isOnlySTHEnabled || disableBopisBlock ? `${PREFIX}-switch-fulfillment__only-sth` : ``
}

/**
 * Function to get class for Bopis only products
 *  @param {boolean} isOnlyBOPISEnabled
 *  @returns {string}
 */
export const getOnlyBopisClass = (isOnlyBOPISEnabled: boolean): string => {
    return isOnlyBOPISEnabled ? `${PREFIX}-switch-fulfillment__only-bopis` : ``
}

/**
 * Function to get class without margin top
 *  @param {boolean} isProductEligibleForWestExpress
 *  @param {string} sthDeliveryClassPrefix
 *  @returns {string}
 */
export const getMarginTopOffClass = (
    isProductEligibleForWestExpress: boolean,
    sthDeliveryClassPrefix: string,
): string => {
    return isProductEligibleForWestExpress ? '' : `${PREFIX}-${sthDeliveryClassPrefix}--heading-no-top-margin`
}

/**
 * Function to check if product price exceeds threshold
 *  @param {number} expressDeliveryPriceThreshold
 *  @param {number} currentProductPrice
 *  @returns {string}
 */
export const checkProductPriceExceedsThreshold = (
    expressDeliveryPriceThreshold: number,
    currentProductPrice: number,
): boolean => {
    return areAllParamsValid(Boolean(expressDeliveryPriceThreshold), Boolean(currentProductPrice))
        ? currentProductPrice > expressDeliveryPriceThreshold
        : false
}

/**
 * @param { ProductResponseData } productData
 * @returns { boolean } return true/false if product options are available
 */
export const isProductOptionValues = (productData: ProductResponseData): boolean => {
    return Boolean(productData?.options && isArrayNotEmpty(productData?.options) && productData?.options[0]?.values)
}

/**
 * @param { boolean } isChangeClicked
 * @param { React.MutableRefObject<HTMLInputElement | null> } textInputRef
 * @param { string } errorMsg
 */
export const checkChangeClickOrError = (
    isChangeClicked: boolean,
    textInputRef: React.MutableRefObject<HTMLInputElement | null>,
    errorMsg: string,
): void => {
    if ((isChangeClicked && textInputRef) || errorMsg) {
        textInputRef.current?.focus()
    }
}

/**
 * Function to return true when STH is unavailable
 * @param { boolean } isAllVariantSelected true when all variants are selected
 * @param { FulfillmentDTOP } fulfillment fulfillment data for selected  sku
 * @param { ProductResponseData } productData product  data
 * @param { boolean } freeShippingEnabled true for western banners
 * @returns { boolean } true/false
 */
export const productNotForSth = (
    isAllVariantSelected: boolean,
    fulfillment: FulfillmentDTOP | undefined,
    productData: ProductResponseData,
    freeShippingEnabled: boolean,
): boolean => {
    if (!isAllVariantSelected) {
        return false
    }
    const { storePickUp, shipToHome, availability } = fulfillment || {}
    const { Corporate } = availability || {}
    const { Quantity } = Corporate || { Quantity: 0 }
    const { isBopisOnly } = productData || {}
    // ATS is available to sell. WEST uses ATS which is received in Corporate object of priceAvailability which includes store quantity already.
    const isATSQtyZero = freeShippingEnabled && Quantity === 0

    // eslint-disable-next-line no-warning-comments
    // TODO: For east ATS value will DC inventory + store inventory . This needs to be done as part of East STH.
    return (!shipToHome?.enabled && storePickUp?.enabled) || isBopisOnly || isATSQtyZero
}

/**
 * get no variant selected error message
 * @param {boolean} isAddToWishlistClicked
 * @param {string | undefined} variantNotSelectedMessage
 * @param {IFedErrors} fedErrors
 * @returns {string}
 */

export const getNoVariantErrorMesg = (
    isAddToWishlistClicked: boolean,
    variantNotSelectedMessage: string | undefined,
    fedErrors: IFedErrors,
): string =>
    isAddToWishlistClicked
        ? libUtils.getStringOrDefault(variantNotSelectedMessage)
        : libUtils.getStringOrDefault(fedErrors?.errorPDP001)
/**
 * Checks if a product is online-only based isEcommOnly, isEndlessAisle flags on variant selection and quantity in distribution center.
 * @param {boolean} isAllVariantSelected - checks if all variants are selected.
 * @param {ProductSku | undefined} selectedVariantSkuData - Data of the selected product variant.
 * @param {number | undefined} dcQuantity - Quantity available in the distribution center.
 * @returns {boolean | undefined} Returns true if the product is online-only.
 */
export const isProductOnlineOnly = (
    isAllVariantSelected: boolean,
    selectedVariantSkuData: ProductSku | undefined,
    dcQuantity: number | undefined,
): boolean | undefined => {
    if (!isAllVariantSelected) {
        return false
    }
    const { isEcommOnly, isEndlessAisle } = selectedVariantSkuData || {}
    return (isEcommOnly || isEndlessAisle) && (dcQuantity ?? 0) > 0
}

/**
 * get order for variant details
 * @params {ProductOption[]} productOption productOption
 * @params {VariantDetails} variantDetailsData variantDetailsData
 * @returns {string[]} returns order for variant details
 */
export const getVariantDetailsOrder = (
    productOption: ProductOption[],
    variantDetailsData: VariantDetails,
): string[] => {
    const variantsDataOrder = productOption?.map(item => ({
        ...item,
        variantOrder:
            item?.variantOrder || item?.variantOrder === 0
                ? libUtils.getNumberOrDefault(item?.variantOrder)
                : MagicNumber.EIGHTTHOUSAND,
    }))
    // eslint-disable-next-line sonar/no-misleading-array-reverse
    const sortedVariantsData = variantsDataOrder?.sort((a, b) => a.variantOrder - b.variantOrder)
    const sortByDescriptor = sortedVariantsData?.map(itemVariant => itemVariant.descriptor)
    // eslint-disable-next-line consistent-return
    return sortByDescriptor?.map(item => {
        for (const keyVariant in variantDetailsData) {
            if (variantDetailsData[keyVariant] && variantDetailsData[keyVariant].descriptor === item) {
                return keyVariant
            }
        }
    }) as string[]
}

/**
 * Function to return true if endless aisle
 * @param {FulfillmentDTOP | undefined} fulfillment fulfillment data for selected  sku
 * @returns {boolean} true/false
 */
const isSTHEndlessAisle = (fulfillment: FulfillmentDTOP | undefined): boolean => {
    return fulfillment?.shipToHome?.messageCode === STHCodes.ENDLESSAISLE
}

/**
 * Function to return true if product is eligible for ED
 * @param {FulfillmentDTOP | undefined} fulfillment fulfillment data for selected  sku
 * @returns {boolean} true/false
 */
export const isProductEligibleForExpressDelivery = (
    fulfillment: FulfillmentDTOP | undefined,
    freeShippingEnabled: boolean,
): boolean => {
    const { expressDelivery, storePickUp } = fulfillment || {}
    return areAllParamsValid(
        Boolean(expressDelivery?.enabled),
        Boolean(storePickUp?.enabled),
        freeShippingEnabled ? !isSTHEndlessAisle(fulfillment) : true,
    )
}

/**
 * Function to return true if store is eligible for ED
 * @param {preferredStoreDetails} preferredStoreDetails preferred Store Details
 * @returns {boolean} true/false
 */
export const isStoreEligibleForED = (
    preferredStoreDetails: StoreWithAvailability,
    freeShippingEnabled: boolean,
): boolean => {
    return areAllParamsValid(
        preferredStoreDetails?.isExpressDeliveryEligible,
        Boolean(freeShippingEnabled ? preferredStoreDetails?.bopisAvailable : true),
    )
}

/**
 * Function to return true if ExpressDelivery is available
 * @param {boolean} isAllVariantSelected checks if all variants are selected
 * @param {FulfillmentDTOP | undefined} fulfillment fulfillment data for selected  sku
 * @param {boolean} isStoreEligibleForExpressDelivery checks if the store is eligible for express delivery.
 * @param {boolean} freeShippingEnabled - checks if free shipping is enabled.
 * @param {boolean} isExpressDeliveryEligibleForPostalCode - checks if express delivery is eligible for the postal code.
 * @returns {boolean} returns true/false based on express delivery availability.
 */
export const isExpressDeliveryAvailable = (
    isAllVariantSelected: boolean,
    fulfillment: FulfillmentDTOP | undefined,
    isStoreEligibleForExpressDelivery: boolean,
    freeShippingEnabled: boolean,
    isExpressDeliveryEligibleForPostalCode: boolean,
): boolean => {
    if (!isAllVariantSelected) {
        return false
    }
    const isQuantityEligible = freeShippingEnabled
        ? libUtils.getNumberOrDefault(fulfillment?.availability?.quantity) > 0
        : libUtils.getNumberOrDefault(fulfillment?.availability?.quantity) >= 0
    return (
        isProductEligibleForExpressDelivery(fulfillment, freeShippingEnabled) &&
        isStoreEligibleForExpressDelivery &&
        isQuantityEligible &&
        isExpressDeliveryEligibleForPostalCode
    )
}

/**
 * Function to return the fulfillment method
 * @param {boolean} isShipToHome isShipToHome
 * @param {boolean} isExpressDelivery isExpressDelivery
 * @returns {string} Fulfillment Method
 */
export const getFulfillmentMethod = (isShipToHome: boolean, isExpressDelivery: boolean): string => {
    return isShipToHome
        ? FulfillmentMethods.STH
        : // eslint-disable-next-line sonar/no-nested-conditional
        isExpressDelivery
        ? FulfillmentMethods.EXPRESS
        : FulfillmentMethods.BOPIS
}

/**
 * Function to return bopis selected or not
 * @param {string} selectedFulfillment selectedFulfillment
 * @param {string} freePickUpInStoreLabel freePickUpInStoreLabel
 * @returns {boolean} bopis selected
 */
export const isBopis = (selectedFulfillment: string, freePickUpInStoreLabel: string): boolean => {
    return selectedFulfillment?.toLocaleLowerCase() === freePickUpInStoreLabel?.toLocaleLowerCase()
}

/**
 * checks if a repair order is required for the product.
 * @param {boolean} repairOrderRequired - Indicates if a repair order is required for the product.
 * @param {ProductResponseData} productData - The product information.
 * @returns {boolean | undefined} - Returns true if a repair order is required, if not false
 */
export const isRepairOrderRequiredCompared = (
    repairOrderRequired: boolean,
    productData: ProductResponseData,
): boolean | undefined => {
    return repairOrderRequired ? repairOrderRequired : productData?.repairOrderRequired
}
