import { iconProptypes } from '../Icon/Icon.types'
import { SpinnerProps } from '../Spinner/Spinner.types'
import { Variation, IA11y, Size, IValueType, State } from '../../types'
import { MouseEventHandler, KeyboardEventHandler } from 'react'

/**
 * Enum for ButtonV2 type
 */
export enum ButtonType {
    DEFAULT = 'button',
    SUBMIT = 'submit',
    RESET = 'reset',
}

/**
 * Enum for ButtonV2 colors
 * All listed on the branding for CTC
 * Default - Default button __COLORED__ for each banner (Tweb - Red, CT - Green, etc). __primary__ variations ONLY
 * Default 2 - Same as default but a second type default __COLORED__ button (only for Tweb). __primary__ variations ONLY
 * Active - Shared for all banners. For offers and __primary__ variations ONLY
 * Shop - Shared for all banners. For offers and __primary__ variations ONLY
 * Select - Shared for all banners. For triangle select buttons, __primary__ and __secondary__ variations ONLY
 * Light - Buttons with white backgrounds / outlines
 * Dark - Buttons with black backgrounds / outlines
 * @enum {string}
 */
export enum ButtonColors {
    DEFAULT = 'default',
    DEFAULT_2 = 'default-2', // only available for Triangle (primary)
    ACTIVATE = 'activate',
    SHOP = 'shop',
    SELECT = 'select',
    // below are missing in triangle (secondary)
    LIGHT = 'light',
    DARK = 'dark',
}

/**
 * Interface for Button Core
 * @typedef {object} IButtonCore
 */
interface IButtonCore {
    disabled?: boolean
    id?: string
    a11y?: IA11y
    qm?: IValueType
    ref?: React.ForwardedRef<HTMLButtonElement>
    customClass?: string
    onClick?: (e?) => void | MouseEventHandler
}

/**
 * Interface for Button Base
 * Follows attributes assigned to https://www.w3schools.com/tags/tag_button.asp
 * @typedef {object} IButtonBase
 */
export interface IButtonBase extends IButtonCore {
    variant?: Variation
    color?: ButtonColors
    size?: Size.MINI | Size.MEDIUM | Size.LARGE
    type?: ButtonType
    onKeyDown?: (e: React.KeyboardEvent<HTMLButtonElement>) => void
}

/**
 * Interface for Button icons
 * @typedef {object} IconButtonProps
 */
export interface IconButtonProps extends IButtonBase {
    icon?: iconProptypes
}

/**
 * Interface for Button
 * @typedef {object} ButtonProps
 */
export interface ButtonProps extends IconButtonProps {
    children?: React.ReactNode
    reverse?: boolean
    ariaLabel?: string
}

/**
 * Interface for Button spinner
 * @typedef {object} SpinnerButtonProps
 */
export interface SpinnerButtonProps extends ButtonProps {
    spinner?: SpinnerProps
    showSpinner?: boolean
}

/**
 * Interface for Button tab
 * @typedef {object} TabButtonProps
 */
export interface TabButtonProps extends IButtonCore {
    state?: State
    tabIndex?: number
    children?: React.ReactNode
    onKeyDown?: KeyboardEventHandler
}
