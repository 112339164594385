/**
 * check the DOM for the availability of elements matching the specified selector
 * and executes a callback when the element is found.
 *
 * @param {string} selector - The CSS selector for the target element(s).
 * @param {(nodeList: NodeListOf<HTMLElement>) } callback - The callback function to execute when the element is found.
 *   It receives a NodeList of HTMLElements matching the provided selector.
 * @return {void}
 */

export const onElementAvailable = (selector: string, callback: (nodeList: NodeListOf<HTMLElement>) => void): void => {
    const observer = new MutationObserver(() => {
        const nodeList: NodeListOf<HTMLElement> = document.querySelectorAll(selector)
        if (nodeList.length > 0) {
            callback(nodeList)
            observer.disconnect() // Stop observing once the element is found
        }
    })

    observer.observe(document.body, { childList: true, subtree: true })
}
