import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { ReferenceComponent } from '@nl/lib'
import { RootState } from '../../redux/reducers'
import { ReferenceComponentPropType } from './ReferenceComponent.type'
import { checkDataLength } from '../Accounts/Addresses/checkDataLength'
import { getDynamicComponentData } from '../../redux/actions'
import getPageType from '../../utils/getPageType'
import { HttpRequestState } from '../../httpClient/client.type'
import { pageTypes } from '../../config'
import { getPageHelper } from '../../helpers/pageHelpers'
import { Component } from '../../global.type'
import { renderDynamicReactComponent, renderAEMComponents } from '../../helpers/cms.helper'
import { productDataSelector } from '../../redux/selectors/product.selectors'
import { ComponentType } from '../../helpers/cms.helper.type'
import { useAppDispatch, useAppSelector } from '../../hooks/react-redux.hook'

export const ReferenceComponentWrapper: React.FC<ReferenceComponentPropType> = ({
    id,
}: ReferenceComponentPropType): JSX.Element => {
    const dispatch = useAppDispatch()

    const [componentContent, setComponentContent] = useState('')
    const pageType = getPageType()

    const { contentData, xhrInfo } = useAppSelector((state: RootState) => state.dynamicComponent)
    const productData = useAppSelector(productDataSelector)
    const pageHelper = getPageHelper(getPageType(), productData)

    const checkObjectLength = (obj: unknown) => {
        return checkDataLength(obj as Record<string, unknown>)
    }

    const injectDynamicContent = useCallback((): void => {
        if (checkObjectLength(contentData)) {
            const htmlStr = contentData[id]?.htmlStr
            setComponentContent(htmlStr)
        } else {
            xhrInfo === HttpRequestState.notStarted &&
                dispatch(
                    getDynamicComponentData(pageHelper?.pageType || '', pageHelper?.categories, pageHelper?.primaryId),
                )
        }
    }, [contentData, dispatch, id, xhrInfo, pageHelper])

    const checkProductDataAndInjectDynamicContent = useCallback(() => {
        if (checkObjectLength(productData)) {
            injectDynamicContent()
        }
    }, [injectDynamicContent, productData])

    useEffect(() => {
        if (pageType === pageTypes.pdpPage) {
            checkProductDataAndInjectDynamicContent()
        } else {
            injectDynamicContent()
        }
    }, [dispatch, contentData, id, checkProductDataAndInjectDynamicContent, injectDynamicContent, pageType])

    // Used to get the current Ref.
    const ref = React.createRef()

    /**
     * Helper Function to re render the injected components.
     * @param {HTMLElement[]} htmlElements
     */
    const renderReferenceComponents = (htmlElements: HTMLElement[]): void => {
        htmlElements.forEach((singleElement: HTMLElement) => {
            const { props, component } = singleElement.dataset
            const componentObject: Component = {
                name: component as string,
                element: singleElement,
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                props: JSON.parse(props as string),
                dynamic: true,
            }
            renderDynamicReactComponent(componentObject as unknown as ComponentType)
        })
    }

    /**
     * Render React component Dynamically.
     * @param {string} content
     */
    const renderReactComponent = (content: string): void => {
        // Extract list of data-components from the api and parse them from string to html
        const spanElementsFromAPI = new DOMParser()
            .parseFromString(content, 'text/html')
            ?.body?.querySelectorAll('[data-component]')

        // For Rendering AEM Components.
        renderAEMComponents(ref.current as HTMLElement)

        // spanElementsFromAPI is a node list, not using isArrayNotEmpty.
        if (spanElementsFromAPI.length > 0) {
            // Extract list of data-component from the parsed HTML.
            const listOfDataComponentAttribute = Array.from(spanElementsFromAPI).map((el: Element) =>
                el.getAttribute('data-component'),
            )

            /**
             * Extract the original elements from dom using the current reference component.
             * Why are we doing? - we are doing this since the parsed html from api content cant be used while re-rendering.
             * To re-render we are using ReactDOM.render. For this we need to pass the container on which we want to render as second param.
             * If we pass the parsed one, it wont exist in the dom since there wont be any reference. so before passing to ReactDOM.render,
             * we need to extract the actual elements from dom which are rendered(This logic runs after the injection is done which enables us to get the exact ref)
             *
             */
            const dataComponents = (ref?.current as Element)?.querySelectorAll(`[data-component]`)
            const mappedDOMElements = listOfDataComponentAttribute.map((_, index: number) => dataComponents[index])
            renderReferenceComponents(mappedDOMElements as unknown as HTMLElement[])
        }
    }

    return (
        <ReferenceComponent
            ref={ref as React.Ref<null> | undefined}
            htmlStr={componentContent}
            renderReactComponent={renderReactComponent}
        />
    )
}

ReferenceComponentWrapper.propTypes = {
    id: PropTypes.string.isRequired,
}

export default ReferenceComponentWrapper
