import { MagicNumber } from '../../analytics/analytics.type'
import { CertonaResponseType } from '../../certona/certona.type'
import { balloonEntryType, configurableEntryType, emptySpace, inflationData } from '../../globalConstants'
import { CartModificationsDTO, IHeliumInflationStoreServiceType } from '../../redux/models/cart.interface'
import { EntryGroup, EntryGroups } from '../../redux/models/orderConfirmation.interface'
import { AddToCartRecommendationDataType } from '../../redux/models/recommendations.interface'
import { checkNumberNotNullAndUndefined } from '../../utils/checkNotNullAndUndefined'
import { isAutoPartPDP } from '../Vehicles/Vehicle.helper'
import { isBalloonOrBalloonBouquetProduct } from '../ShoppingCart/CartItem.util'
import { isArrayEmpty, libUtils } from '@nl/lib'

/**
 *
 * function to check existing message display
 * @param { boolean } enableAOAFlowMessaging
 * @param { string } fitmentTypeCode
 * @param { string } productWheelType
 * @return { boolean }
 */
export const displayExistingMessage = (
    enableAOAFlowMessaging: boolean,
    fitmentTypeCode?: string,
    productWheelType?: string,
): boolean => {
    return enableAOAFlowMessaging && !isAutoPartPDP(fitmentTypeCode, productWheelType)
}

/**
 * check if installation message need to display or not
 * @param { boolean } isAutoInstallationReq
 * @param { string } fitmentTypeCode
 * @param { string } productWheelType
 * @return { boolean }
 */
export const checkInstallationRequired = (
    isAutoInstallationReq: boolean,
    fitmentTypeCode?: string,
    productWheelType?: string,
): boolean => {
    return isAutoInstallationReq || isAutoPartPDP(fitmentTypeCode, productWheelType)
}

/**
 * check if product is out of stock or not
 * @param { number } storeQunatity
 * @param { number } dcQuantity
 * @return { boolean }
 */
export const checkProductNotOutOfStock = (storeQunatity: number, dcQuantity: number): boolean => {
    return (
        checkNumberNotNullAndUndefined(storeQunatity) > MagicNumber.ZERO ||
        checkNumberNotNullAndUndefined(dcQuantity) > MagicNumber.ZERO
    )
}

/**
 * check if product is out of stock or not with additional check of orderable flag
 * @param { number } storeQunatity storeQunatity
 * @param { number } dcQuantity dcQuantity
 * @param { boolean } orderable orderable
 * @returns { boolean } true or false
 */
export const checkProductNotOutOfStockWithOrderableFlag = (
    storeQunatity: number,
    dcQuantity: number,
    orderable?: boolean,
): boolean => {
    return (
        checkNumberNotNullAndUndefined(storeQunatity) > MagicNumber.ZERO ||
        (Boolean(orderable) && checkNumberNotNullAndUndefined(dcQuantity) > MagicNumber.ZERO)
    )
}

/**
 * check if product in stock
 * @param { number } storeQunatity
 * @param { number } dcQuantity
 * @param { boolean } sellable
 * @param { boolean } orderable
 * @returns { boolean }
 */
export const isProductInStock = (
    storeQunatity: number,
    dcQuantity: number,
    sellable?: boolean,
    orderable = false,
): boolean => {
    return sellable
        ? checkNumberNotNullAndUndefined(storeQunatity) > 0 ||
              (checkNumberNotNullAndUndefined(dcQuantity) > 0 && orderable)
        : false
}

/**
 * function to get title, max/min recommendation count for selected SchemaId along with filtered Recommendation array
 * @param { string } selectedSchemeId
 * @param { CertonaResponseType } productRecommendations
 * @param { string } recomendationCarouselTitle
 * @param { string } recommendationCarouselTitleSecondSchemaId
 * @param { AddToCartRecommendationDataType[] } recommendationData
 * @param { boolean } isYouNeedThisRecommendation
 * @param { number } minimumNumberOfRecommendations
 * @param { number } maximumNumberOfRecommendations
 * @param { number } minimumNumberOfRecForSecondSchemaId
 * @param { number } maximumNumberOfRecForSecondSchemaId
 * @return { hasMinimunNumberOfRecommendations: boolean, filteredRecommendationData: AddToCartRecommendationDataType[] }
 */

export const filterRecommendations = (
    selectedSchemeId: string,
    productRecommendations: CertonaResponseType,
    recomendationCarouselTitle: string,
    recommendationCarouselTitleSecondSchemaId: string,
    recommendationData: AddToCartRecommendationDataType[],
    isYouNeedThisRecommendation: boolean,
    minimumNumberOfRecommendations: number,
    maximumNumberOfRecommendations: number,
    minimumNumberOfRecForSecondSchemaId: number,
    maximumNumberOfRecForSecondSchemaId: number,
): {
    title: string
    recommendationTitle: string
    hasMinimumNumberOfRecommendations: boolean
    filteredRecommendationData: AddToCartRecommendationDataType[]
    // eslint-disable-next-line max-params
} => {
    const recommendationTitle = productRecommendations?.resonance?.schemes?.find(
        (item: { scheme: string }) => item.scheme === selectedSchemeId,
    )?.explanation

    const title = isYouNeedThisRecommendation ? recomendationCarouselTitle : recommendationCarouselTitleSecondSchemaId

    const hasMinimumNumberOfRecommendations = isYouNeedThisRecommendation
        ? recommendationData?.length >= minimumNumberOfRecommendations
        : recommendationData?.length >= minimumNumberOfRecForSecondSchemaId

    const filteredRecommendationData = isYouNeedThisRecommendation
        ? recommendationData.slice(0, maximumNumberOfRecommendations)
        : recommendationData.slice(0, maximumNumberOfRecForSecondSchemaId)

    return {
        title,
        recommendationTitle: libUtils.getStringOrDefault(recommendationTitle),
        hasMinimumNumberOfRecommendations,
        filteredRecommendationData,
    }
}

/**
 * Checks if product is eligible to show addon options
 * @param {EntryGroups[]} entryGroups entryGroups data
 * @param {CartModificationsDTO} cartModifications cartModifications data
 * @returns {boolean}
 */
export const isBalloonAddonOptionsAvailable = (
    entryGroups: EntryGroups[],
    cartModifications: CartModificationsDTO,
): boolean => {
    const isEntryGroupsAvailable = entryGroups && !isArrayEmpty(entryGroups)
    const isBallonTypeAvailable =
        cartModifications?.entry?.balloonEntryType === balloonEntryType.weightSku ||
        cartModifications?.entry?.balloonEntryType === balloonEntryType.bagSku

    return isEntryGroupsAvailable && isBallonTypeAvailable
}

/**
 * Checks if balloon bouquet is helium filled or not
 * @param {CartModificationsDTO} cartModifications cartModifications
 * @returns {boolean} true if balloon bouquet is helium filled
 */
export const isHeliumFilledBalloonBouquet = (cartModifications: CartModificationsDTO): boolean => {
    if (cartModifications?.entry) {
        const isBouquet = isBalloonOrBalloonBouquetProduct(cartModifications.entry)
        const isHeliumFilled = isHeliumAddonServiceIncluded(cartModifications.entry.selectedStoreServices)

        return Boolean(isBouquet) && Boolean(isHeliumFilled)
    }
    return false
}

/**
 * Checks if supportedStoreServices/selectedStoreServices include helium addon
 * @param {IHeliumInflationStoreServiceType[]} storeServices supportedStoreServices/selectedStoreServices
 * @returns {boolean} true is store sercices include helium addon
 */
export const isHeliumAddonServiceIncluded = (
    storeServices: IHeliumInflationStoreServiceType[] | undefined,
): boolean | undefined => {
    return storeServices?.some(service => service.value === inflationData.heliumAddOn)
}

/**
 * Extracts entry group number from selected package
 * @param {EntryGroup[]} selectedGroup selected Group
 * @param {string} productType productType
 * @returns {number} entry group number
 */
export const getGroupNumber = (selectedGroup?: EntryGroup[], productType?: string): number => {
    let groupType = emptySpace
    if (productType === balloonEntryType.weightSku) {
        groupType = configurableEntryType.CONFIGURABLEBUNDLE_BALLOONWEIGHT
        // eslint-disable-next-line sonarjs/elseif-without-else
    } else if (productType === balloonEntryType.bagSku) {
        groupType = configurableEntryType.CONFIGURABLEBUNDLE_BALLOONBAG
    }

    const entryGroup = selectedGroup?.find(group => group.groupType === groupType)

    return entryGroup?.groupNumber ?? MagicNumber.ZERO
}
