import { ReactComponentAnalytics } from './reactComponentAnalytics.abstract'
import { AnalyticsRecord } from '../models'
import {
    AnalyticsRecordInterface,
    BaseProvider,
    ProductRecordInterface,
    YesNoNotApplicable,
} from '../providers/provider.type'
import { checkout } from '../components/analytics.constant'

import {
    CartOrderDeliveryModes,
    CartOrderEntries,
    CartOrderEntriesRecord,
    FilteredCartData,
} from '../../redux/models/cart.interface'
import { ProductRecord } from '../models/productRecord'
import { analyticsAttributes } from '../../globalConstants/analyticsParams.constant'
import { CheckoutPaymentInfo } from '../../redux/models/checkout.interface'
import { FulfillmentMethods } from '../../components/ShoppingCart/ShoppingCart.type'
import { MagicNumber } from '../analytics.type'
import { ProductHelper } from '../helpers/product.helper'
import { isEasternBanner } from '../../utils/getCurrentBannerId'

/**
 * Analytics implementation for checkout page
 */
export class CartAnalytics extends ReactComponentAnalytics {
    /**
     * TODO: analyticsRecord should be passed by the component
     * this class methods only change respective provided arguments
     * That way way we will have separation of concern https://softwareengineering.stackexchange.com/questions/32581/how-do-you-explain-separation-of-concerns-to-others
     */
    analyticsRecord: AnalyticsRecord
    sthProducts?: CartOrderEntries[]
    bopisProducts?: CartOrderEntries[]
    contactInfoStep: number = checkout.contactInfoStep
    shippingInfoStep: number = checkout.shippingInfoStep
    defaultCheckoutStep: number = checkout.defaultCheckoutStep

    /**
     * @param  {BaseProvider} publicanalyticsProvider
     * @param  {string} eventName
     */
    constructor(public analyticsProvider: BaseProvider, eventName: string) {
        super(analyticsProvider)
        this.analyticsRecord = new AnalyticsRecord(eventName)
        // eslint-disable-next-line no-warning-comments
        // TODO: fill data for AnalyticsRecord by reading directly from redux store
    }

    /**
     * Generic function to call steps
     * @param  {number} step
     * @param  {string|undefined} option
     */
    fireCheckoutStep(step: number, option: string | undefined): void {
        switch (step) {
            case checkout.contactInfoStep:
                this.fireCheckoutContactInfo(step, option)
                break
            case checkout.shippingInfoStep:
                this.fireCheckoutShippingInfo(step, option)
                break
            default:
                throw Error('Invalid Analytics Step')
        }
    }

    /**
     * Fire cart page load analytics event
     */
    fireCartPageLoad(): void {
        this.analyticsRecord.checkout = {
            step: 1,
            option: undefined,
        }
        this.push(this.analyticsRecord.toPlainObject())
    }

    /**
     * Method to fire shippingInfo event with product or cart level fulfillment type and shipping method
     * @param {FilteredCartData} reduxState updated redux state
     * @param {Record<string, string | boolean>} otions event options - deliveryModeType
     * @returns {void}
     */
    fireShippingInfo(reduxState: FilteredCartData, otions?: { deliveryModeType?: string }): void {
        const isSingleDeliveryModeForCart = !isEasternBanner()
        const deliveryModeType =
            isSingleDeliveryModeForCart && !!otions && !!otions?.deliveryModeType
                ? otions.deliveryModeType
                : reduxState.cart.deliveryMode
        this.analyticsRecord.event = analyticsAttributes.event.shippingInfo
        this.analyticsRecord.products = []
        const productRecordsFromCartEntry = ProductRecord.processCardOrderEntries([
            ...reduxState.sth,
            ...reduxState.bopis,
        ])
        productRecordsFromCartEntry.forEach((productRecord: ProductRecord) => {
            if (!isSingleDeliveryModeForCart && !!productRecord.fulfillment) {
                ProductHelper.processShippingInfo(productRecord.fulfillment, productRecord)
            }
            this.analyticsRecord.products?.push(productRecord as ProductRecordInterface)
        })
        const shippingInfoEventData = this.analyticsRecord.toPlainObject()
        delete shippingInfoEventData.cart
        delete shippingInfoEventData.checkout
        delete shippingInfoEventData.installationAvailable
        delete shippingInfoEventData.installationHelp
        delete shippingInfoEventData.vinEntered
        isSingleDeliveryModeForCart &&
            !!deliveryModeType &&
            (shippingInfoEventData.shoppingExperience = ProductHelper.processShippingInfo(deliveryModeType))
        this.push(shippingInfoEventData)
    }

    /**
     * Fire cart page load analytics event
     */
    fireWishListPageLoad(): void {
        // eslint-disable-next-line no-warning-comments
        // TODO:this hack only for now need to fix properly
        const data = this.analyticsRecord.toPlainObject()
        this.deleteWishlistData(data)
        data.event = 'wishlist'
        this.push(data)
    }

    /**
     * this function deletes these keys from the data layer
     * @param {AnalyticsRecordInterface} data
     */
    deleteWishlistData(data: AnalyticsRecordInterface): void {
        delete data.cart
        delete data.checkout
        delete data.checkout
        delete data.installationAvailable
        delete data.installationAvailable
        delete data.installationHelp
        delete data.vinEntered
        delete (data?.products as ProductRecordInterface[])?.[0]?.fitted
        delete (data?.products as ProductRecordInterface[])?.[0]?.fulfillment
        delete (data?.products as ProductRecordInterface[])?.[0]?.fulfillmentDaysAway
        delete (data?.products as ProductRecordInterface[])?.[0]?.guaranteedFitment
        delete (data?.products as ProductRecordInterface[])?.[0]?.package
        delete (data?.products as ProductRecordInterface[])?.[0]?.pickupEmailProvided
        delete (data?.products as ProductRecordInterface[])?.[0]?.quantity
        delete (data?.products as ProductRecordInterface[])?.[0]?.ror
        delete (data?.products as ProductRecordInterface[])?.[0]?.shipFrom
        delete (data?.products as ProductRecordInterface[])?.[0]?.someoneElsePickingUp
        delete (data?.products as ProductRecordInterface[])?.[0]?.vehicle
        delete (data?.products as ProductRecordInterface[])?.[0]?.bulk
    }
    /**
     * Fire add quantity analytics
     */
    fireAddQuantity(): void {
        this.analyticsRecord.event = analyticsAttributes.event.addToCart
        this.push(this.analyticsRecord.toPlainObject())
    }
    /**
     * Fire remove quantity analytics
     */
    fireRemoveQuantity(): void {
        this.analyticsRecord.event = analyticsAttributes.event.removeQuantity
        this.push(this.analyticsRecord.toPlainObject())
    }

    /**
     * Fire fulfillment change analytics
     * @param {string}type
     * @param {number} entrynumber
     */
    fireFulfillmentChange(type?: string, entrynumber?: number): void {
        this.analyticsRecord.event = analyticsAttributes.event.checkout
        const thisProduct = type === FulfillmentMethods.STH ? this.bopisProducts : this.sthProducts
        const modifiedProduct = JSON.parse(
            JSON.stringify(thisProduct?.find(product => product.entryNumber === entrynumber) || {}),
        ) as CartOrderEntries
        if (modifiedProduct.fulfillment) {
            modifiedProduct.fulfillment.deliveryMode = type as CartOrderDeliveryModes
        }
        const productIndex = this.analyticsRecord.products?.findIndex(
            product => product.entryNumber === modifiedProduct?.entryNumber,
        )
        this.analyticsRecord.products?.splice(productIndex as number, MagicNumber.ONE)
        const productRecord = ProductRecord.processCardOrderEntries([modifiedProduct] as CartOrderEntries[])
        this.analyticsRecord.products?.push(productRecord[0] as ProductRecordInterface)
        this.push(this.analyticsRecord.toPlainObject())
    }
    /**
     * Fire bulk delivery option change analytics
     */
    fireBulkDeliveryOptionChange(): void {
        this.analyticsRecord.event = analyticsAttributes.event.checkout
        this.push(this.analyticsRecord.toPlainObject())
    }

    /**
     *
     * @param {boolean | string}installDetails
     * @return {YesNoNotApplicable | boolean}
     */
    installationHelper(installDetails: boolean | string): YesNoNotApplicable | boolean {
        return this.analyticsRecord.installationAvailable === YesNoNotApplicable.notApplicable
            ? this.analyticsRecord.installationAvailable
            : !!installDetails
    }

    /**
     * push analytics event for contact info section
     * @param  {number} step
     * @param  {string|undefined} option
     */
    fireCheckoutContactInfo(step: number, option: string | undefined): void {
        this.analyticsRecord.checkout = {
            step,
            option: option,
        }
        this.push(this.analyticsRecord.toPlainObject())
    }
    /**
     * push analytics event for shipping info section
     * @param  {number} step
     * @param  {string|undefined} option
     */
    fireCheckoutShippingInfo(step: number, option: string | undefined): void {
        const isSingleDeliveryModeForCart = !isEasternBanner()
        this.analyticsRecord.checkout = {
            step,
            option: option,
        }
        // Store all product reference
        const allProducts = this.analyticsRecord.products

        this.analyticsRecord.products = []

        const sthProductRecords = ProductRecord.processCardOrderEntries(this.sthProducts as CartOrderEntries[])
        sthProductRecords.forEach((product: ProductRecord) => {
            !isSingleDeliveryModeForCart &&
                !!product.fulfillment &&
                ProductHelper.processShippingInfo(product.fulfillment, product)
            this.analyticsRecord.products?.push(product as ProductRecordInterface)
        })
        const checkoutEventData = this.analyticsRecord.toPlainObject()
        isSingleDeliveryModeForCart &&
            !!sthProductRecords[0].fulfillment &&
            (checkoutEventData.shoppingExperience = ProductHelper.processShippingInfo(sthProductRecords[0].fulfillment))
        this.push(checkoutEventData)
        // Set all product again
        this.analyticsRecord.products = allProducts
    }

    /**
     * push analytics event for pick up info section
     * @param  {number} step
     * @param  {string} fulfillment
     * @param {boolean} someoneElsePickingUp
     * @param {boolean} pickupEmailProvided
     * @param  {string|undefined} option
     * @param {Record<string, string | boolean | unknown>}installationDetails
     */
    firePickUpInfoAnalytics(
        step: number,
        fulfillment: string,
        someoneElsePickingUp: boolean,
        // eslint-disable-next-line default-param-last
        pickupEmailProvided = false,
        option: string | undefined,
        installationDetails: Record<string, string | boolean> = {},
    ): void {
        const isSingleDeliveryModeForCart = !isEasternBanner()
        this.analyticsRecord.checkout = {
            step,
            option: option,
        }
        this.analyticsRecord.installationHelp = this.installationHelper(installationDetails.requestCallback) as
            | YesNoNotApplicable
            | undefined
        this.analyticsRecord.vinEntered = this.installationHelper(installationDetails.vin) as
            | YesNoNotApplicable
            | undefined

        const allProducts = this.analyticsRecord.products
        this.analyticsRecord.products = []
        const bopisProductRecords = ProductRecord.processCardOrderEntries(this.bopisProducts as CartOrderEntries[])
        bopisProductRecords.forEach((product: ProductRecord) => {
            !isSingleDeliveryModeForCart &&
                !!product.fulfillment &&
                ProductHelper.processShippingInfo(product.fulfillment, product)
            ProductRecord.processBopisOrderEntry(
                product as unknown as CartOrderEntriesRecord,
                fulfillment,
                someoneElsePickingUp,
                pickupEmailProvided,
            )
            this.analyticsRecord.products?.push(product as ProductRecordInterface)
        })

        const checkoutEventData = this.analyticsRecord.toPlainObject()
        isSingleDeliveryModeForCart &&
            !!bopisProductRecords[0].fulfillment &&
            (checkoutEventData.shoppingExperience = ProductHelper.processShippingInfo(
                bopisProductRecords[0].fulfillment,
            ))
        this.push(checkoutEventData)
        // Set all product again
        this.analyticsRecord.products = allProducts
    }

    /**
     *
     * @param {number}step
     * @param {string | undefined}option
     * @param {checkoutPaymentInfo}paymentInfo
     */
    firePaymentInfoAnalytics(step: number, option: string | undefined, paymentInfo: CheckoutPaymentInfo): void {
        this.analyticsRecord.checkout = {
            step,
            option: option,
        }

        this.analyticsRecord.cart = {
            ...this.analyticsRecord.cart,
            financingEligible: paymentInfo.eligibleForFinancing,
            financingSelected:
                (paymentInfo.financingPlanId as boolean | undefined) ||
                (YesNoNotApplicable.notSelected as unknown as boolean | undefined),
            financingShown: paymentInfo.eligibleForFinancing,
        }

        this.push(this.analyticsRecord.toPlainObject())
    }

    /**
     * Function to fire add to cart analytics when a product is added or merged to cart
     * @param {CartOrderEntries} orderEntries
     * @param {string} cartId
     * @param {string} eventName
     * @param {string} eventLocation
     */
    fireAddToCart(orderEntries: CartOrderEntries[], cartId: string, eventName: string, eventLocation: string): void {
        this.analyticsRecord.event = eventName
        this.analyticsRecord.cart = {
            id: cartId,
            shippingCost: undefined,
        }
        this.analyticsRecord.products = []
        orderEntries.forEach((product: CartOrderEntries) => {
            const orderEntry = new ProductRecord()
            orderEntry.processCardOrderEntry(product)
            this.analyticsRecord.products?.push(orderEntry as ProductRecordInterface)
        })

        const data = this.analyticsRecord.toPlainObject()
        data.eventParameters = {
            location: eventLocation,
        }
        // TO-DO: adapting fix as per wishlist
        delete data.checkout
        delete data.checkout
        delete data.installationAvailable
        delete data.installationHelp
        delete data.cart?.financingEligible
        delete data.cart?.financingSelected
        delete data.cart?.financingShown
        delete data.cart?.fulfillmentCost
        delete data.vinEntered
        this.push(data)
    }

    /**
     * Fires merge cart analytics
     * @param {string} cartId
     * @param {string} mergedSKUs
     * @param {boolean} locationIsModal
     */
    fireMergeCartAnalytics(cartId: string, mergedSKUs: string, locationIsModal: boolean): void {
        const {
            event: { mergeCart },
            eventParameters: {
                action: { mergeCart: mergeCartAction },
                category: { eCommerce },
                location: { mergeModal, login },
            },
        } = analyticsAttributes

        const analyticsData = {
            event: mergeCart,
            cartId,
            eventParameters: {
                action: mergeCartAction,
                category: eCommerce,
                label: mergedSKUs,
                location: locationIsModal ? mergeModal : login,
            },
        }

        this.push(analyticsData)
    }

    /**
     * This function is responsible for google analytics for merge cards
     * @param {string} cartId
     * @param {string} mergedSKUs
     * @return {void}
     */
    fireMergeSharedCartAnalytics(cartId: string, mergedSKUs: string): void {
        const {
            event: { mergeSharedCart },
            eventParameters: {
                action: { mergeCart: mergeCartAction },
                category: { eCommerce },
                location: { mergeModal },
            },
        } = analyticsAttributes

        const analyticsData = {
            event: mergeSharedCart,
            cartId,
            eventParameters: {
                action: mergeCartAction,
                category: eCommerce,
                label: mergedSKUs,
                location: mergeModal,
            },
        }

        this.push(analyticsData)
    }

    /**
     * Prepare basic analytics state for Checkout page(react components only)
     * @param  {CartItemsData} reduxState
     * @param {Record<string, string>} props
     */
    prepareBaseAnalyticsState(reduxState: FilteredCartData, props?: Record<string, string>): void {
        this.analyticsRecord.installationAvailable = reduxState.installationAvailable
        this.analyticsRecord.installationHelp = this.installationHelper(reduxState.cart?.requestCallback as boolean) as
            | YesNoNotApplicable
            | undefined
        this.analyticsRecord.vinEntered = this.installationHelper(reduxState.cart?.vin as string) as
            | YesNoNotApplicable
            | undefined

        this.analyticsRecord.cart = {
            id: reduxState?.cart?.cartId,
            financingEligible: undefined,
            financingSelected: undefined,
            financingShown: undefined,
            fulfillmentCost: reduxState.cart?.cartSummary?.totalAmt?.value?.toString(),
            shippingCost: reduxState.cart?.cartSummary?.shippingFees?.value as number,
        }

        this.analyticsRecord.products = []
        // storing sth products for future use
        this.sthProducts = reduxState.sth
        this.bopisProducts = reduxState.bopis

        this.analyticsRecord.products = this.analyticsRecord.products.concat(
            ProductRecord.processCardOrderEntries(reduxState.sth, props) as ProductRecordInterface[],
            ProductRecord.processCardOrderEntries(reduxState.bopis, props) as ProductRecordInterface[],
            ProductRecord.processCardOrderEntries(reduxState.services, props) as ProductRecordInterface[],
        )
    }
}

export default CartAnalytics
