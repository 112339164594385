import { ActionType, getType } from 'typesafe-actions'

import { setApiXhrInfo, setDynamicContentDataOnSuccess } from '../actionCreators'
import * as actions from '../actionCreators'
import { DynamicComponentState } from '../models/dynamicComponent.interface'
import { HttpRequestState } from '../../httpClient/client.type'

type Action = ActionType<typeof actions>

const initialState: DynamicComponentState = {
    xhrInfo: HttpRequestState.notStarted,
    contentData: {},
}

export const dynamicComponentData = (
    state: DynamicComponentState = initialState,
    action: Action,
): DynamicComponentState => {
    if (action.type === getType(setDynamicContentDataOnSuccess)) {
        return { ...state, contentData: action.payload }
    } else if (action.type === getType(setApiXhrInfo)) {
        return { ...state, xhrInfo: action.payload }
    } else {
        return state
    }
}
