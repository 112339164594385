import { isArrayNotEmpty } from '../../utils'
import { BREAKPOINTS, PREFIX } from '../config'
import { mediaGalleryConstants } from './MediaGalleryViewer.constant'
import { DynamicButton, ProductImages } from './MediaGalleryViewer.type'
import { sanitizeStringContent } from '../../utils/sanitizeStringContent'
import {
    thumbnailImageCountDesktop,
    thumbnailImageCountTablet,
    thumbnailImageCountTabletLandscape,
    thumbnailImageCountMobile,
} from '../VideoGalleryViewer/VideoGalleryViewer.constant'

const { prevArrowIcon, nextArrowIcon, minimumImageLength, minimumThumbnailCount } = mediaGalleryConstants

let thumbnailScrollWidth = 0

/**
 * function to get previous button element
 * @param {string} thumbnailPrevArrowClass
 * @param {string} a11yCarouselPreviousLabel
 * @return {DynamicButton}
 */
export const getPrevArrowButton = (
    thumbnailPrevArrowClass: string,
    a11yCarouselPreviousLabel: string,
): DynamicButton => {
    return {
        className: thumbnailPrevArrowClass,
        ariaLabel: a11yCarouselPreviousLabel,
        type: prevArrowIcon,
        size: 'md',
    }
}

/**
 * function to get next button element
 * @param {string} thumbnailNextArrowClass
 * @param {string} a11yCarouselNextLabel
 * @return {DynamicButton}
 */
export const getNextArrowButton = (thumbnailNextArrowClass: string, a11yCarouselNextLabel: string): DynamicButton => {
    return {
        className: thumbnailNextArrowClass,
        ariaLabel: a11yCarouselNextLabel,
        type: nextArrowIcon,
        size: 'md',
    }
}

/**
 * function to get image path for svg images
 * @param {string} icon
 * @param {string} path
 * @return {string}
 */
export const getImagePath = (icon: string, path: string): string => {
    return !path ? `#${icon}` : `${path}#${icon}`
}

/**
 * function used to scroll left or right side direction based on thumbnail previous or next arrow
 * @param {boolean} isPrevArrowClicked
 * @param {number} thumbnailImageCount
 * @param {string} mediaClassName
 * @param {Function} mediaGalleryClick
 */
export const thumbnailArrowClick = (
    isPrevArrowClicked: boolean,
    thumbnailImageCount: number,
    mediaClassName: string,
    mediaGalleryClick?: () => void,
): void => {
    const snapperNavEle = document.querySelector(`.${mediaClassName} .focused .snapper_nav_inner`)
    const navInner = snapperNavEle || document.querySelector(`.${mediaClassName} .snapper_nav_inner`)
    const navInnerWidth = navInner.clientWidth
    const thumbnailInnerDiv = document.querySelector(`.${mediaClassName} .snapper_nav_inner a`)
    const thumbnailImageMargin = window.getComputedStyle(thumbnailInnerDiv).getPropertyValue('margin-right')
    const thumbnailImageMarginValue =
        parseFloat(thumbnailImageMargin) -
        (thumbnailImageCount === minimumImageLength ? -minimumImageLength : minimumThumbnailCount)
    const thumbnailNavWidth = navInner.scrollWidth - thumbnailImageMarginValue
    // infinite scrolling for thumbnail arrows
    if (isPrevArrowClicked) {
        // scroll towards left side direction
        const scrollToThumbnail =
            thumbnailScrollWidth < navInnerWidth ? 0 : thumbnailScrollWidth - navInnerWidth - thumbnailImageMarginValue // current scroll width - thumbnail image width - thumbnail image margin
        thumbnailScrollWidth =
            thumbnailScrollWidth <= 0
                ? thumbnailNavWidth - navInnerWidth + thumbnailImageMarginValue
                : scrollToThumbnail
    } else {
        // scroll towards right side direction
        const nextThumbScrollValue = thumbnailScrollWidth + navInnerWidth + thumbnailImageMarginValue // current scroll width + thumbnail image width + thumbnail image margin
        thumbnailScrollWidth = nextThumbScrollValue >= thumbnailNavWidth ? 0 : nextThumbScrollValue
    }

    navInner.scrollTo({
        left: Math.round(thumbnailScrollWidth),
        behavior: 'smooth',
    })
    mediaGalleryClick && mediaGalleryClick()
}
/**
 * function to insert dynamic buttons
 * @param {DynamicButton} dynamicButton
 */
export const insertDynamicButtons = (dynamicButton: DynamicButton): void => {
    const { elementRef, position, className, ariaLabel, type, iconHrefLink, buttonId, size } = dynamicButton
    elementRef.insertAdjacentHTML(
        position,
        `<button class=${className} aria-label=${ariaLabel} id=${buttonId}> 
            <svg class='${PREFIX}-icon ${PREFIX}-icon-${type} ${PREFIX}-icon--${size}'>
                <use href=${sanitizeStringContent(iconHrefLink)} />
            </svg>
        </button>`,
    )
}

/**
 * function to add left and right navigation arrow buttons
 * @param {Element} elementRef
 * @param {string} leftArrowId
 * @param {string} rightArrowId
 * @param {string} prevArrowHrefLink
 * @param {string} nextArrowHrefLink
 * @param {string} a11yCarouselPreviousLabel
 * @param {string} a11yCarouselNextLabel
 * @param {string} thumbnailPrevArrowClass
 * @param {string} thumbnailNextArrowClass
 */
export const addNavigationArrowButtons = (
    elementRef: Element,
    leftArrowId: string,
    rightArrowId: string,
    prevArrowHrefLink: string,
    nextArrowHrefLink: string,
    a11yCarouselPreviousLabel: string,
    a11yCarouselNextLabel: string,
    thumbnailPrevArrowClass: string,
    thumbnailNextArrowClass: string,
    // eslint-disable-next-line max-params
): void => {
    const prevArrowButton = getPrevArrowButton(thumbnailPrevArrowClass, a11yCarouselPreviousLabel)
    const nextArrowButton = getNextArrowButton(thumbnailNextArrowClass, a11yCarouselNextLabel)

    const thumbnailArrowButtons: DynamicButton[] = [
        Object.assign(prevArrowButton, {
            elementRef,
            position: 'afterbegin' as InsertPosition,
            iconHrefLink: prevArrowHrefLink,
            buttonId: leftArrowId,
        }),
        Object.assign(nextArrowButton, {
            elementRef,
            position: 'beforeend' as InsertPosition,
            iconHrefLink: nextArrowHrefLink,
            buttonId: rightArrowId,
        }),
    ]
    // Add left and right thumbnail arrow
    thumbnailArrowButtons.forEach(arrowButtons => insertDynamicButtons({ ...arrowButtons }))
}

/**
 * Function to return media set array if tag exist
 * @param {ProductImages[]} productImages
 * @param {string} tagValue
 * @return {ProductImages[]} returns media set array if tag exist
 */
export const getMediaIfTagExist = (productImages: ProductImages[], tagValue: string): ProductImages[] => {
    return productImages?.filter((image: ProductImages) => image.tags?.includes(tagValue))
}

/**
 * Function to return media set array if media url exist
 * @param {ProductImages[]} productImages
 * @return {ProductImages[]} returns media set array if media url exist
 */
export const getMediaIfUrlExist = (productImages: ProductImages[]): ProductImages[] => {
    return productImages?.filter((media: ProductImages) => !!media.url)
}

/**
 * Function to check if media set exist
 * @param {string} tagValue
 * @param {string} selectedProductCode
 * @param {boolean} isAutomotive
 * @param {ProductImages[]} productImages
 * @param {string} productDataCode
 * @return {boolean}
 */
export const checkIfMediaSetExist = (
    tagValue: string,
    selectedProductCode: string,
    isAutomotive: boolean,
    productImages: ProductImages[],
    productDataCode: string,
): boolean => {
    const selectedTag = isAutomotive ? selectedProductCode : tagValue
    const filteredImagesArr = getMediaIfTagExist(productImages, selectedTag)
    const filteredProductLevelImagesArr = getMediaIfTagExist(productImages, productDataCode)
    const mediaSetExist = getMediaIfUrlExist(filteredImagesArr)
    const productLevelMediaSetExist = getMediaIfUrlExist(filteredProductLevelImagesArr)
    return !isArrayNotEmpty(mediaSetExist) && !isArrayNotEmpty(productLevelMediaSetExist)
}

/**
 * Function to return number of thumbnail items
 * @return {number} returns the number of thumbnail items
 */
export const getThumbnailCount = (): number => {
    const windowInnerWidth = window.innerWidth
    if (windowInnerWidth > BREAKPOINTS.tabletMaxWidth) {
        return thumbnailImageCountDesktop
    }
    if (windowInnerWidth >= BREAKPOINTS.tabletLandscape) {
        return thumbnailImageCountTabletLandscape
    }
    if (windowInnerWidth >= BREAKPOINTS.tabletPortrait) {
        return thumbnailImageCountTablet
    }
    return thumbnailImageCountMobile
}
