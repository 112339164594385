import React, { useEffect, useState, useRef, useCallback } from 'react'
import { libUtils, updateUrlHistory } from '@nl/lib'

import { RootState } from '../../redux/reducers'

import { MagicNumber } from '../../analytics/analytics.type'
import { commonContentSelector } from '../../redux/selectors/commonContent.selectors'
import { checkDataLength } from '../Accounts/Addresses/checkDataLength'
import { HrefLangLinkData, ProductResponseErrorDTO } from '../../redux/models/product.interface'
import { CartResponseErrorDTO } from '../../redux/models/cart.interface'
import { skuNotFoundAction } from '../../redux/actionCreators'
import useProduct from './useProduct'
import { selectedPreferredStoreIdSelector } from '../../redux/selectors/storeDetails.selectors'
import { fetchVariantsProductDataBySku } from '../../redux/actions'
import { isAutomotivePDP } from '../Vehicles/Vehicle.helper'
import { SeoHelper } from '../../Seo/helpers/Seo.helper'
import { httpStatusCodes } from '../../globalConstants'
import { secondaryNavPropsSelector } from '../../redux/selectors/headerProps.selectors'
import appCacheService from '../../utils/appCacheService'
import { SkuListType } from '../../components/FrequentlyBoughtTogether/FrequentlyBoughtTogether.type'
import usePLACarouselTracker from '../../hooks/usePLACarouselTracker.hook'
import { handleProductEvent } from '../../helpers/monetateFomoEvents.helper'
import extractPCodeFromUrl from '../../utils/PDP/extractPCodeFromUrl'
import { useAppDispatch, useAppSelector } from '../../hooks/react-redux.hook'
import usePrevious from '../../hooks/usePrevious.hook'

/**
 * ProductPageInit to invoke product details api
 * @return {null}
 */

const ProductPageInit: React.FC = () => {
    const dispatch = useAppDispatch()
    const { productData, productErrorResponse, skuCode, staggeredSkus } = useProduct()
    const { skuNotFound } = useAppSelector((state: RootState) => state.product)
    const { addToCartErrorData } = useAppSelector((state: RootState) => state.cart)
    const selectedPreferredStoreId =
        useAppSelector(selectedPreferredStoreIdSelector) || appCacheService.preferredStoreId.get()
    const [runOnce, setRunOnce] = useState(true)
    const { commonContentAvailable } = useAppSelector(commonContentSelector)
    const { featureFlag, product } = commonContentAvailable

    const { notFoundOnAbsentSKuEnabled } = product || {}
    const { enableFOMO } = featureFlag || {}
    const { skuCode: selectedSkuCode, staggeredSkus: selectedStaggeredSKus } = extractPCodeFromUrl()
    const isMultiSku = productData?.skus?.length > 1
    const currentSkuCode = isMultiSku
        ? selectedSkuCode || selectedStaggeredSKus
        : productData?.code?.toUpperCase().replace('P', '').replace('F', '')

    const productSkus = useRef<SkuListType[]>([])
    const previousPreferredStoreId = useRef('')
    const isAutomotive = isAutomotivePDP(productData?.fitmentTypeCode, productData?.productWheelType)
    const secondaryNavProps = useAppSelector(secondaryNavPropsSelector)
    const { enablePrerenderMetaTag } = secondaryNavProps || {}
    const { skus, lowStockThreshold } = productData
    const label = productData?.brand?.label || ''
    const previousSkuCode = usePrevious(currentSkuCode)
    const runOneTime = useRef(0)

    /**
     * function to update the url
     * @param {HrefLangLinkData | undefined}
     * @param {string} search
     * @param {string} hash
     * @param {string} extension
     * @param {string} staggeredSkus1
     * @param {string} staggeredSkus2
     * @return {string}
     */
    const renderUpdatedUrl = useCallback(
        (
            linkData: HrefLangLinkData | undefined,
            search: string,
            hash: string,
            extension: string,
            staggeredSkus1: string,
            staggeredSkus2: string,
        ): string => {
            return !!skuCode
                ? `${String(linkData?.href).split('.html')[0]}.${skuCode}.${extension}${search}${hash}`
                : // eslint-disable-next-line sonar/no-nested-conditional
                !!staggeredSkus
                ? `${
                      String(linkData?.href).split('.html')[0]
                  }.${staggeredSkus1}.${staggeredSkus2}.${extension}${search}${hash}`
                : `${String(linkData?.href)}${search}${hash}`
        },
        [skuCode, staggeredSkus],
    )

    usePLACarouselTracker()

    useEffect(() => {
        if (
            notFoundOnAbsentSKuEnabled &&
            skuCode &&
            checkDataLength(productData) &&
            !productData?.skus?.some(sku => sku.code === skuCode && sku.variantStatus === 'ACTIVE')
        ) {
            dispatch(skuNotFoundAction())
        }
    }, [dispatch, notFoundOnAbsentSKuEnabled, skuCode, productData])

    /**
     * To reset the runOneTime.current to zero, when sku is changed.
     */
    useEffect(() => {
        if (previousSkuCode !== currentSkuCode && runOneTime.current === 1) {
            runOneTime.current = 0
        }
    }, [currentSkuCode])

    /**
     * useEffect to dispatch the product event with the current product code and SKU code when sku is changed.
     */
    useEffect(() => {
        if (enableFOMO && checkDataLength(productData) && runOneTime.current === 0) {
            handleProductEvent([{ productId: productData?.code, ...(currentSkuCode && { sku: currentSkuCode }) }])
            runOneTime.current = 1
        }
    }, [productData, currentSkuCode, enableFOMO])

    useEffect(() => {
        if (runOnce && checkDataLength(productData)) {
            const { hreflangLinkData, breadcrumbList } = productData
            if (!!hreflangLinkData) {
                const language = libUtils.getLanguage()
                const currentPath = window.location.pathname
                const linkData = hreflangLinkData.find((hrefFlagLink: HrefLangLinkData) => {
                    return hrefFlagLink.hreflang === language
                })
                if (checkDataLength(linkData)) {
                    const search = window.location.search
                    const hash = window.location.hash
                    const path = window.location.pathname.toLowerCase().split('.')
                    const extension = path[path.length - MagicNumber.ONE]
                    const [staggeredSkus1, staggeredSkus2] = staggeredSkus.split(',')
                    const urlToUpdate = renderUpdatedUrl(
                        linkData,
                        search,
                        hash,
                        extension,
                        staggeredSkus1,
                        staggeredSkus2,
                    )
                    if (
                        enablePrerenderMetaTag &&
                        linkData?.href.split('.')[MagicNumber.ZERO] !== currentPath.split('.')[MagicNumber.ZERO]
                    ) {
                        const prerenderHeaderURL = window.location.origin + urlToUpdate
                        SeoHelper.createMetaTag('name', 'prerender-status-code', String(httpStatusCodes.redirectCode))
                        SeoHelper.createMetaTag('name', 'prerender-header', `Location: ${prerenderHeaderURL}`)
                    }
                    if (linkData?.href !== currentPath) {
                        updateUrlHistory(urlToUpdate)
                    }
                }
            }
            window.gamDatalayer = window.gamDatalayer || {}
            // eslint-disable-next-line no-magic-numbers
            window.gamDatalayer.categoryId = breadcrumbList?.[breadcrumbList.length - 2]?.categoryId || ''
            setRunOnce(false)
        }
    }, [productData, runOnce, skuCode, enablePrerenderMetaTag, staggeredSkus, renderUpdatedUrl])

    /**
     * useEffect to make panda call on page load for all possible skus
     */
    useEffect(() => {
        if (!isAutomotive && skus) {
            const skusForAPI = skus.map(item => {
                return {
                    code: item.code,
                    lowStockThreshold,
                    brand: label,
                }
            })
            if (
                JSON.stringify(skusForAPI) !== JSON.stringify(productSkus.current) ||
                previousPreferredStoreId.current !== selectedPreferredStoreId
            ) {
                dispatch(fetchVariantsProductDataBySku(skusForAPI, selectedPreferredStoreId))
                productSkus.current = skusForAPI
                previousPreferredStoreId.current = selectedPreferredStoreId
            }
        }
    }, [
        skus,
        lowStockThreshold,
        label,
        selectedPreferredStoreId,
        previousPreferredStoreId,
        productSkus,
        dispatch,
        isAutomotive,
    ])

    const is404Response = (response: ProductResponseErrorDTO | CartResponseErrorDTO) =>
        checkDataLength(response) && response.status === MagicNumber.FOURHUNDREDFOUR

    useEffect(() => {
        const isNotMock = !window.location.search.includes('mock')
        const notFoundProduct = is404Response(productErrorResponse as ProductResponseErrorDTO)
        const noSkuOnAddToCart = notFoundOnAbsentSKuEnabled && is404Response(addToCartErrorData)
        const redirectDueToAbsentSku = notFoundOnAbsentSKuEnabled && skuNotFound
        if (isNotMock && (redirectDueToAbsentSku || notFoundProduct || noSkuOnAddToCart)) {
            window.location.href = encodeURI(window.ODP?.globalLinks.pageNotFoundPath)
        }
    }, [productErrorResponse, addToCartErrorData, skuNotFound, notFoundOnAbsentSKuEnabled])

    return null
}

export default ProductPageInit
