import { magicNumber } from './magicNumber'
import { ProductOption } from '../components/ProductCartItem/ProductItemInfo.type'
/**
 * Returns sorted variants base on variant order property
 * @params {ProductOptionsType[]} - options options
 * @returns {ProductOptionsType[]}
 */

export const getSortVariants = (options: ProductOption[]): ProductOption[] => {
    const variantsDataOrder = options?.map(item => ({
        ...item,
        variantOrder: item?.variantOrder || item?.variantOrder === 0 ? item?.variantOrder : magicNumber.SIXTHOUSAND,
    }))
    // eslint-disable-next-line sonar/no-misleading-array-reverse
    return variantsDataOrder?.sort((a, b) => a.variantOrder - b.variantOrder)
}
