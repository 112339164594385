import React from 'react'
import { WrapByLabelProps } from './Price.types'
import { multiLanguageLabel } from '../../globalConstants/global.constant'

const WrapPriceByLabel: React.FC<WrapByLabelProps> = ({ inputPrice, label, language }) => {
    const isFrenchLabel = language?.toUpperCase() === multiLanguageLabel.frenchLangLabel

    if (!inputPrice) {
        return null
    }

    if (typeof label === 'string' && label.includes('[0]')) {
        return <>{label.replace('[0]', inputPrice)}</>
    } else if (isFrenchLabel) {
        return (
            <>
                {inputPrice}
                {label}
            </>
        )
    } else {
        return (
            <>
                {label}
                {inputPrice}
            </>
        )
    }
}

WrapPriceByLabel.displayName = 'WrapPriceByLabel'

export default WrapPriceByLabel
