// eslint-disable-next-line no-warning-comments
// TODO: props need to be added
import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import { PREFIX, previousElementName } from '../config'
import ReactModal from '../ReactModal'
import Button from '../Button'
import Icon from '../Icon'
import { RichTextModalProps } from './RichTextModal.types'
import {
    HEADER_ELEMENT_ID,
    FOOTER_ELEMENT_ID,
    HEADER_SHADOW_CLASS,
    FOOTER_SHADOW_CLASS,
} from './RichTextModal.constant'
import { modalAccessibilityHandler } from '../../utils/modalAccessibility'

/**
 * Rich Text modal component
 * @returns {JSX.Element} returns Overlay component
 */
const RichTextModal: React.FC<RichTextModalProps> = ({ ...props }): JSX.Element => {
    const {
        closeRichTextModal,
        isRichTextModalOpen,
        overlayHeaderLabel,
        overlaySubHeaderLabel,
        overlayContent,
        overlayContinueLabel,
        overlayCancelLabel,
        path,
        overlayChildren,
        onContinueClicked,
        heightNotFixed,
        hideFooterCTA,
        closeModalOnOutsideClick,
    } = props

    const componentClassName = `${PREFIX}-safety-modal`
    const contentEle = useRef(null)

    useEffect(() => {
        if (isRichTextModalOpen && contentEle && contentEle.current) {
            const { scrollHeight, clientHeight } = contentEle.current as Record<string, unknown>
            // eslint-disable-next-line sonar/values-not-convertible-to-numbers
            if (scrollHeight > clientHeight) {
                const footerEle = document.getElementById(FOOTER_ELEMENT_ID)
                footerEle.classList.add(FOOTER_SHADOW_CLASS)
            }
        }
    }, [isRichTextModalOpen])

    const contentRef = useRef(null)

    // Method used to add/remove shadow onScroll
    const onContentScroll = (e: React.UIEvent<HTMLElement>) => {
        const headerEle = document.getElementById(HEADER_ELEMENT_ID)
        const footerEle = document.getElementById(FOOTER_ELEMENT_ID)
        const {
            currentTarget: { scrollTop, scrollHeight, clientHeight },
        } = e
        const delta = 1
        // Added delta of 1 to take care of variation of 0.5 +/-
        const hasReachedBottom = scrollHeight - scrollTop <= clientHeight + delta
        if (scrollTop > 0) {
            headerEle.classList.add(HEADER_SHADOW_CLASS)
        } else {
            headerEle.classList.remove(HEADER_SHADOW_CLASS)
        }
        if (hasReachedBottom) {
            footerEle.classList.remove(FOOTER_SHADOW_CLASS)
        } else {
            footerEle.classList.add(FOOTER_SHADOW_CLASS)
        }
    }

    // Below code is for handling the accessibility when clicked on close button
    const modalClassName = `${PREFIX}-react-modal`

    const previousElement: HTMLElement = document.querySelector(`button[${previousElementName}]`)

    /**
     * To handle accessibility on keyboard tab. Enables the modal accessibility when clicked on the trigger element.
     *
     * @param {boolean} isContinueClicked - true or false based on whether clicked on continue or close/cancel button.
     * @returns {void}
     */
    const onCloseSafetyModal = (isContinueClicked: boolean): void => {
        modalAccessibilityHandler({ modalOpen: false, modalClassName })
        closeRichTextModal(isContinueClicked)
        if (!isContinueClicked && previousElement) {
            previousElement.focus() // Highlight the initiated button
            previousElement.removeAttribute(previousElementName) // Removing it when user close it.
        }
    }

    /**
     * Function to handle functionality on warning modal continue clicked
     */
    const onWarningModalContinueClicked = (): void => {
        onContinueClicked && onContinueClicked()
        onCloseSafetyModal(true)
    }

    const isHeightFixed = !!heightNotFixed ? false : true

    const packageMarginClass = props.isPackageModal ? `${componentClassName}__subheader-package` : ''
    const contentSlimModifier = props.hideFooterCTA ? `${componentClassName}__content--slim` : ''
    const footerSlimModifier = props.hideFooterCTA ? `${componentClassName}__footer--slim` : ''

    const handlePrevent: React.MouseEventHandler = e => {
        e.stopPropagation()
    }

    return (
        <ReactModal
            closeHandler={() => onCloseSafetyModal(false)}
            isOpen={isRichTextModalOpen}
            isHeightFix={isHeightFixed}
            closeModalOnOutsideClick={closeModalOnOutsideClick}
            isModalPadding={props.isPackageModal}>
            <div
                onClick={closeModalOnOutsideClick ? handlePrevent : null}
                role="presentation"
                className={componentClassName}
                ref={contentRef}>
                <div
                    className={`${componentClassName}__header-container`}
                    id={HEADER_ELEMENT_ID}
                    data-testid={HEADER_ELEMENT_ID}>
                    <div className={`${componentClassName}__close-container`}>
                        <button
                            data-testid="rich-close-modal"
                            className={`${componentClassName}__close-btn`}
                            onClick={() => onCloseSafetyModal(false)}
                            aria-label="close">
                            <Icon type="ct-close" size="lg" path={path} />
                        </button>
                    </div>
                    <h3 className={`${componentClassName}__header-text`}>{overlayHeaderLabel}</h3>
                </div>
                {/* this condition is for checkout merge cart modal */}
                {overlayChildren ? (
                    <div
                        className={`${componentClassName}__content ${contentSlimModifier}`}
                        onScroll={onContentScroll}
                        ref={contentEle}
                        data-testid={'content'}>
                        {/* TO-DO: will update styling class after getting details from design team */}
                        <div className={`${componentClassName}__subheader-text ${packageMarginClass}`}>
                            {overlaySubHeaderLabel}
                        </div>
                        <div
                            className={`${componentClassName}__subheader-description ${packageMarginClass}`}
                            dangerouslySetInnerHTML={{ __html: overlayContent }}
                        />
                        {overlayChildren}
                    </div>
                ) : (
                    <div
                        className={`${componentClassName}__content ${contentSlimModifier}`}
                        dangerouslySetInnerHTML={{ __html: overlayContent }}
                        onScroll={onContentScroll}
                        ref={contentEle}
                        data-testid={'content'}
                    />
                )}
                <div
                    className={`${componentClassName}__footer ${footerSlimModifier}`}
                    id={FOOTER_ELEMENT_ID}
                    data-testid={FOOTER_ELEMENT_ID}>
                    {!hideFooterCTA && (
                        <>
                            <Button id="rich-modal-continue" onClick={onWarningModalContinueClicked} size="large">
                                {overlayContinueLabel}
                            </Button>
                            <Button
                                id="btn-Warning"
                                type="tertiary"
                                onClick={() => onCloseSafetyModal(false)}
                                ariaLabel="cancel">
                                {overlayCancelLabel}
                            </Button>
                        </>
                    )}
                </div>
            </div>
        </ReactModal>
    )
}

RichTextModal.propTypes = {
    closeRichTextModal: PropTypes.func.isRequired,
    isRichTextModalOpen: PropTypes.bool.isRequired,
    overlayHeaderLabel: PropTypes.string,
    overlayContent: PropTypes.string,
    overlayContinueLabel: PropTypes.string,
    overlayCancelLabel: PropTypes.string,
    overlaySubHeaderLabel: PropTypes.string,
    path: PropTypes.string,
    overlayChildren: PropTypes.element,
    onContinueClicked: PropTypes.func,
    heightNotFixed: PropTypes.bool,
    isPackageModal: PropTypes.bool,
}

export default RichTextModal
