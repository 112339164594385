import React, { useCallback, useEffect, useRef } from 'react'

import { CertonaInitialization } from '../../certona/certona.service'
import { CertonaResponseType } from '../../certona/certona.type'
import { pageTypes } from '../../config'
import { storeCertonaRecommendationData } from '../../redux/actions'
import appCacheService from '../../utils/appCacheService'
import getPageType from '../../utils/getPageType'
import extractPCodeFromUrl from '../../utils/PDP/extractPCodeFromUrl'
import { checkDataLength } from '../Accounts/Addresses/checkDataLength'
import { getCategoriesFromURL } from '../../utils/PLP/getCategoriesFromURL'
import { getOrderDetailsForCertona } from '../../utils/Certona/getOrderDetailsForCertona'
import { retrieveProductCode } from '../../utils/Certona/retrieveProductCode'
import { getCategoriesList } from '../Vehicles/Vehicle.helper'
import { MagicNumber } from '../../analytics/analytics.type'
import { FilteredCartData } from '../../redux/models/cart.interface'
import { selectedPreferredStoreIdSelector } from '../../redux/selectors/storeDetails.selectors'
import { orderDetailsDataSelector } from '../../redux/selectors/orderDetails.selectors'
import { productSelector } from '../../redux/selectors/product.selectors'
import { cartItemsDataSelector } from '../../redux/selectors/cart.selectors'
import { wishlistItemsSelector } from '../../redux/selectors/wishlist.selectors'
import { categoryApiCompletedSelector, categoryIdDataSelector } from '../../redux/selectors/categoryIdData.selectors'
import { productCardDataSelector } from '../../redux/selectors/productData.selectors'
import { docLoadStatusSelector } from '../../redux/selectors/docLoadStatus.selectors'
import { orderSelector } from '../../redux/selectors/orderItemsDetails.selectors'
import { useAppDispatch, useAppSelector } from '../../hooks/react-redux.hook'

export const CertonaInit: React.FC = () => {
    const dispatch = useAppDispatch()
    const { productData, isOOSCurrentStore, isProductDataAvailable, productSkuData } = useAppSelector(productSelector)
    const cartItemsData = useAppSelector(cartItemsDataSelector)
    const wishlistItems = useAppSelector(wishlistItemsSelector)
    const order = useAppSelector(orderSelector)
    const productCardData = useAppSelector(productCardDataSelector)
    const categoryData = useAppSelector(categoryIdDataSelector)
    const categoryApiCompleted = useAppSelector(categoryApiCompletedSelector)
    const currentPageType = getPageType()
    const docLoadStatus = useAppSelector(docLoadStatusSelector)
    const orderDetailsData = useAppSelector(orderDetailsDataSelector)
    const selectedPreferredStoreId = useAppSelector(selectedPreferredStoreIdSelector)

    const certonaCallRef = useRef(0)
    const storeIdFromStorageRef = useRef(appCacheService.preferredStoreId.get())

    const customerId = appCacheService.lmsId.get()
    const isCertonaScriptLoaded = Boolean(window.callCertona)

    /**
     * Callback for the certona response.
     */
    const storeCertonaData = useCallback(
        (data: CertonaResponseType) => {
            dispatch(storeCertonaRecommendationData(data))
        },
        [dispatch],
    )

    const categoryPageConditions = useCallback(
        (categoryPages: string[]): boolean => {
            return categoryPages.includes(currentPageType) && checkDataLength(categoryData)
        },
        [categoryData, currentPageType],
    )

    // useEffect to call certoana on store change
    useEffect(() => {
        if (selectedPreferredStoreId && storeIdFromStorageRef.current !== selectedPreferredStoreId) {
            certonaCallRef.current = MagicNumber.ZERO
            storeIdFromStorageRef.current = selectedPreferredStoreId
        }
    }, [selectedPreferredStoreId])

    // call certona only once when  certona object is ready
    const triggerRestrictedCertona = useCallback(() => {
        certonaCallRef.current = MagicNumber.ONE
        CertonaInitialization.triggerCertona()
    }, [])

    /*
     * trigger the recommendation if the user logs in with customer id or logs out
     * customerid attribute is only applicable to specific page types pdp/account/home/wishlist/cart
     */
    useEffect(() => {
        if (window.certona && window.certona.customerid !== customerId) {
            window.certona = { ...window.certona, customerid: customerId }
            triggerRestrictedCertona()
        }
    }, [customerId, triggerRestrictedCertona])

    /*
     * This is needed for certona to work correctly for empty scenarios
     * window.onload is only been used to initialize the certona object and does not impact component rendering
     * It is a workaround since there's no initial state for guest user in wishlist
     */
    useEffect(() => {
        if (docLoadStatus) {
            const { wishlist, cart } = pageTypes
            switch (currentPageType) {
                case wishlist:
                    if (checkDataLength(wishlistItems.products)) {
                        CertonaInitialization.init(storeCertonaData, {
                            ItemId: retrieveProductCode(wishlistItems.products, true),
                            customerId: customerId,
                        })
                        triggerRestrictedCertona()
                    }
                    break
                case cart:
                    if (checkDataLength(cartItemsData.cart)) {
                        CertonaInitialization.init(storeCertonaData, {
                            ItemId: retrieveProductCode(cartItemsData.cart?.orderEntries, true),
                            customerId: customerId,
                        })
                        triggerRestrictedCertona()
                    }
                    break
                default:
                    break
            }
        }
    }, [
        wishlistItems,
        cartItemsData,
        currentPageType,
        triggerRestrictedCertona,
        customerId,
        storeCertonaData,
        docLoadStatus,
    ])

    /**
     * @method getOrderData  : Gets order data based on order page
     * @returns {FilteredCartData} returns object
     */
    const getOrderData = useCallback(() => {
        if (currentPageType === pageTypes.orderDetails && checkDataLength(orderDetailsData)) {
            return orderDetailsData
        } else if (currentPageType === pageTypes.orderConfirmation && checkDataLength(order)) {
            return order
        } else {
            return undefined
        }
    }, [order, orderDetailsData, currentPageType])

    // Initialize the certona i.e create object and add callback to the response and parse the response.
    // eslint-disable-next-line complexity, sonar/cyclomatic-complexity
    useEffect(() => {
        const {
            productPage,
            searchPage,
            store,
            eventLanding,
            eventListing,
            categoryPages,
            orderConfirmation,
            orderDetails,
            digitalFlyer,
            homePage,
            promoListing,
            brandLandingPage,
            brandListingPage,
            noSearchPage,
            allBrandsPage,
            errorPage,
            emailOfferActivation,
            account,
            weeklyOffer,
            accountDasboard,
            holiday,
        } = pageTypes

        if (certonaCallRef.current === 0 && window.callCertona && docLoadStatus) {
            if (productPage === currentPageType && checkDataLength(productData)) {
                const { pCode } = extractPCodeFromUrl()
                CertonaInitialization.init(storeCertonaData, {
                    categories: getCategoriesList(productData?.breadcrumbList, true),
                    ItemId: pCode,
                    customerId: customerId,
                    outOfStock: !!isOOSCurrentStore,
                })
                triggerRestrictedCertona()
            } else if (searchPage === currentPageType && checkDataLength(productCardData)) {
                CertonaInitialization.init(storeCertonaData, {
                    exItemId: retrieveProductCode(productCardData?.products),
                    pageType: productCardData?.products.length > 0 ? searchPage : noSearchPage,
                })
                triggerRestrictedCertona()
            } else if (store === currentPageType) {
                const storeIdFromURL = new URLSearchParams(window.location.search).get('storeId') as string
                const currentSelectedStore = appCacheService.preferredStoreId.get()
                CertonaInitialization.init(storeCertonaData, {
                    storeId: storeIdFromURL ?? currentSelectedStore,
                })
                triggerRestrictedCertona()
            } else if (
                (orderConfirmation === currentPageType || orderDetails === currentPageType) &&
                checkDataLength(getOrderData())
            ) {
                CertonaInitialization.init(storeCertonaData, {
                    ...getOrderDetailsForCertona(getOrderData() as FilteredCartData),
                    customerId,
                })
                triggerRestrictedCertona()
            } else if (eventLanding === currentPageType || eventListing === currentPageType) {
                CertonaInitialization.init(storeCertonaData, {
                    // eslint-disable-next-line no-warning-comments
                    // TODO: Send Page Name
                    event: '',
                })
                triggerRestrictedCertona()
            } else if (categoryPageConditions(categoryPages as unknown as string[]) && categoryApiCompleted) {
                CertonaInitialization.init(storeCertonaData, {
                    categories: getCategoriesFromURL(categoryData.breadcrumbList).getCategoryIds(),
                    categoryId: categoryData.id,
                })
                triggerRestrictedCertona()
            } else if (
                // eslint-disable-next-line sonar/expression-complexity
                account === currentPageType ||
                weeklyOffer === currentPageType ||
                accountDasboard === currentPageType ||
                homePage === currentPageType ||
                emailOfferActivation === currentPageType
            ) {
                CertonaInitialization.init(storeCertonaData, {
                    customerId: customerId,
                })
                triggerRestrictedCertona()
            } else if (brandListingPage === currentPageType || brandLandingPage === currentPageType) {
                const urlArr = window?.location?.href?.split('/')
                const brand = urlArr?.[urlArr.length - MagicNumber.ONE]?.split('.')?.[0]
                if (brand) {
                    CertonaInitialization.init(storeCertonaData, {
                        brand,
                    })
                    triggerRestrictedCertona()
                }
                // eslint-disable-next-line sonarjs/elseif-without-else
            } else if (
                // eslint-disable-next-line sonar/expression-complexity
                currentPageType === '' ||
                currentPageType === digitalFlyer ||
                currentPageType === promoListing ||
                currentPageType === allBrandsPage ||
                currentPageType === errorPage ||
                currentPageType === holiday
            ) {
                CertonaInitialization.init(storeCertonaData, {})
                triggerRestrictedCertona()
                const emptyCertona = { resonance: { schemes: [] } }
                // pages like promolist, customer-service and other static pages are not configured with certona, so we are dispatching separately
                dispatch(storeCertonaRecommendationData(emptyCertona))
            }
        }
    }, [
        storeCertonaData,
        productData,
        categoryPageConditions,
        currentPageType,
        categoryData,
        order,
        productCardData,
        triggerRestrictedCertona,
        customerId,
        isOOSCurrentStore,
        isProductDataAvailable,
        productSkuData,
        dispatch,
        orderDetailsData,
        getOrderData,
        categoryApiCompleted,
        docLoadStatus,
        isCertonaScriptLoaded,
    ])

    return null
}
