import { ProductResponseData, ProductSku, ProductSpecifications } from './../../../redux/models/product.interface'
import { BreadcrumbLinkData } from './../../../redux/models/productData.interface'
import { WeatherTechVehicleSelectorProps, CriticalFitmentProduct, WithVehicleFitType } from './AutomotiveBuyBox.type'
import {
    FitmentTypeEnum,
    TireWheelFitmentNote,
    TireWheelFitmentTitle,
    VehicleFitType,
    productWheelTypes,
    specifictionsCode,
} from '../../../globalConstants/global.constant'
import {
    isArrayNotEmpty,
    getSetOfFourPriceForStaggered,
    Vehicle,
    FitmentNote,
    CriticalFitment,
    checkDataLength,
    stringToBooleanMap,
} from '@nl/lib'
import { MagicNumber } from '../../../analytics/analytics.type'
import { TireType } from '../../../redux/models/tireVehicle.interface'
import { getFullTireSize, isAutoPartPDP, isTirePDP } from '../../Vehicles/Vehicle.helper'
import { Price } from '../../../redux/models/cart.interface'
import { areAllParamsValid } from '../../../utils/getFilteredCartItems'
import { pdpURLStringCheck } from '../../Vehicles/Vehicles.constant'
import { isStaggeredFront, isStaggeredRear } from '../../../utils/checkStaggered.utils'
import { ShopByTireSizeInitialState } from '../../AutomotivePLP/ShopByTireSize/ShopByTireSize.types'
import { isWeatherTechApplicableBrandAndCategory } from '../useIsFitmentSelectionsType.hook'

/**
 * Function to return true if auto-part/tire/wheel does not fit
 * @param {boolean} isFitmentRequired - is fitment required
 * @param {AutomotiveVehicle} vehicle - vehicle data
 * @returns {boolean} - true if auto-part/tire/wheel does not fit
 */
export const isAutomotiveProductNotFit = <T extends WithVehicleFitType>(
    isFitmentRequired: boolean,
    vehicle: T,
): boolean => {
    return isFitmentRequired && vehicle.vehicleFitType === VehicleFitType.NotFit
}

/**
 * Function to return vehicle name
 * @param {Vehicle | null} vehicleList - vehicle data
 * @returns {string} - vehicle name
 */
export const getVehicleName = (vehicleList?: Vehicle | null): string => {
    return `${String(vehicleList?.autoAttributes?.year)} ${String(vehicleList?.autoAttributes?.make)} ${String(
        vehicleList?.autoAttributes?.model,
    )}`
}

/**
 * Function to return product url
 * @param {BreadcrumbLinkData[]} breadcrumbList - breadcrumb list
 * @param {string} homeUrl - home url
 * @returns {string} - product url
 */
export const getProductListUrl = (breadcrumbList: BreadcrumbLinkData[], homeUrl: string): string => {
    return breadcrumbList[breadcrumbList.length - MagicNumber.TWO]?.url || homeUrl
}

/**
 * Function to return string for button in not fit condition
 * @param {ProductResponseData} productData - product data
 * @param {string} seeWheelsFitVehicleCTA - see wheels fit vehicle CTA
 * @param {string} seeProductFitVehicleCTA - see product fit vehicle CTA
 * @param {string} seeTiresFitVehicleCTA - see tires fit vehicle CTA
 * @param {string} seeProductFitTireSizeCTA - see product fit tire size CTA
 * @param {boolean} isTireSizePresent - is tire size present
 * @returns {string} - button string
 */
export const seeProductFitVehicleCTAButton = (
    productData: ProductResponseData,
    seeWheelsFitVehicleCTA: string,
    seeProductFitVehicleCTA: string,
    seeTiresFitVehicleCTA: string,
    seeProductFitTireSizeCTA: string,
    isTireSizePresent: boolean,
): string => {
    return productData?.productWheelType?.toUpperCase() === productWheelTypes.Wheel
        ? seeWheelsFitVehicleCTA
        : // eslint-disable-next-line sonar/no-nested-conditional
        isTirePDP(productData?.productWheelType)
        ? // eslint-disable-next-line sonar/no-nested-conditional
          isTireSizePresent
            ? seeProductFitTireSizeCTA
            : seeTiresFitVehicleCTA
        : seeProductFitVehicleCTA
}

/**
 * Function to return vehicle name or tire name
 * @param {Vehicle | null} vehicleList - vehicle data
 * @param {TireType | null} tiresData - tire data
 * @returns {string} - vehicle name or tire name
 */
export const getVehicleORTireName = (vehicleList: Vehicle | null, tiresData: TireType | null): string => {
    return !tiresData
        ? `${String(vehicleList?.autoAttributes?.year)} ${String(vehicleList?.autoAttributes?.make)} ${String(
              vehicleList?.autoAttributes?.model,
          )}`
        : `${tiresData?.selectedDiameterValue} ${getFullTireSize(tiresData)}`
}

/**
 * Function  to return regular and staggered fitment array
 * @param {CriticalFitment} criticalFitment - critical fitment data
 * @param { string[]} allSelectedSkus - all selected skus
 * @returns {CriticalFitmentProduct} - regular and staggered fitment array
 *
 */
export const getCriticalFitmentProducts = (
    criticalFitment?: CriticalFitment[] | null,
    allSelectedSkus?: string[],
): CriticalFitmentProduct => {
    const regularFitment: FitmentNote[] = []
    const rearFitment: FitmentNote[] = []
    const frontFitment: FitmentNote[] = []
    criticalFitment?.forEach((fitments: CriticalFitment) => {
        if (checkDataLength(fitments.tireWheelFitment)) {
            const fitmentNotes = Array.isArray(fitments.tireWheelFitment)
                ? fitments.tireWheelFitment
                : ([fitments.tireWheelFitment] as unknown[] as FitmentNote[])

            const regularFitmentNote =
                fitmentNotes.find(fitmentNote => fitmentNote.title === TireWheelFitmentTitle.regular) ||
                ([] as unknown as FitmentNote)

            const frontFitmentNote = fitmentNotes.find(fitmentNote => isStaggeredFront(fitmentNote.note)) as FitmentNote
            const rearFitmentNote = fitmentNotes.find(fitmentNote => isStaggeredRear(fitmentNote.note)) as FitmentNote

            if (checkDataLength(regularFitmentNote)) {
                // In this case vehicle can be regular or staggered
                // For staggered vehicle it means that product fits to the vehicle front and rear ( both )
                regularFitmentNote['sku'] = fitments.sku
                regularFitment.push(regularFitmentNote)
            } else if (checkDataLength(frontFitmentNote) && checkDataLength(rearFitmentNote)) {
                // In this case vehicle can be only staggered and it means that product fits to the vehicle front and rear ( both )
                // Possible only for OE
                regularFitment.push({
                    title: TireWheelFitmentTitle.regular,
                    note: TireWheelFitmentNote.OE,
                    sku: fitments.sku,
                })
            } else if (checkDataLength(frontFitmentNote)) {
                // In this case vehicle can be only staggered and it means that product fits to the vehicle front
                frontFitmentNote['sku'] = fitments.sku
                frontFitment.push(frontFitmentNote)
                // eslint-disable-next-line sonarjs/elseif-without-else
            } else if (checkDataLength(rearFitmentNote)) {
                // In this case vehicle can be only staggered and it means that product fits to the vehicle rear
                rearFitmentNote['sku'] = fitments.sku
                rearFitment.push(rearFitmentNote)
            }
        }
    })

    const staggeredFitment: FitmentNote[] = [
        ...frontFitment
            .filter(item => !allSelectedSkus?.length || allSelectedSkus?.includes(item.sku || ''))
            .slice(0, 1),
        ...rearFitment
            .filter(item => !allSelectedSkus?.length || allSelectedSkus?.includes(item.sku || ''))
            .slice(0, 1),
    ]

    const isStaggered = Boolean(staggeredFitment.length)
    return { regularFitment, staggeredFitment, isStaggered }
}

// function return RegExpMatchArray when url contains #df
export const checkForExternalLink = (): RegExpMatchArray | null => {
    return RegExp(pdpURLStringCheck.df).exec(window.location.href)
}

/**
 * Function to calculate total value for tire and wheel
 * @param { ProductSku[] } productskus - product skus
 * @param { boolean } isFeeValue - is fee value
 * @param { number } frontQuantity - front quantity
 * @param { number } rearQuantity - rear quantity
 * @returns { Price | undefined } - price
 */
export const pdpStaggeredTotalPrice = (
    productskus: ProductSku[],
    isFeeValue?: boolean,
    frontQuantity?: number,
    rearQuantity?: number,
): Price | undefined | number => {
    const staggeredSkusLength = 2
    if (isArrayNotEmpty(productskus)) {
        const isFullStaggered = productskus.length === staggeredSkusLength
        if (areAllParamsValid(!isFeeValue, !frontQuantity, !rearQuantity)) {
            return {
                minPrice: getSetOfFourPriceForStaggered(productskus, true),
                maxPrice: getSetOfFourPriceForStaggered(productskus, true),
                value: getSetOfFourPriceForStaggered(productskus, true),
            }
        } else if (areAllParamsValid(!isFeeValue, frontQuantity, rearQuantity)) {
            return {
                minPrice: getSetOfFourPriceForStaggered(productskus, true, frontQuantity, rearQuantity),
                maxPrice: getSetOfFourPriceForStaggered(productskus, true, frontQuantity, rearQuantity),
                value: getSetOfFourPriceForStaggered(productskus, true, frontQuantity, rearQuantity),
            }
        } else if (isFullStaggered) {
            return productskus[0].feeValue * staggeredSkusLength + productskus[1].feeValue * staggeredSkusLength
        } else {
            return productskus[0].feeValue * staggeredSkusLength
        }
    }
    return undefined
}

/**
 * Function to check if crticalfitment is returning multiplematching sku or single,
 * @param {CriticalFitment[]} criticalFitment - critical fitment data
 * @param {string} fitmentTypeCode - fitment type code
 * @param {string} productWheelType - product wheel type
 * @param {any} list - list
 * @returns {boolean} - true if multiple sku fits
 */
export const checkIfMultipleSKUFitsFromCriticalfitment = (
    criticalFitment?: CriticalFitment[],
    fitmentTypeCode?: string,
    productWheelType?: string,
    list?: Vehicle[],
): boolean => {
    return (
        // eslint-disable-next-line sonar/expression-complexity
        isAutoPartPDP(fitmentTypeCode, productWheelType) &&
        ((Array.isArray(criticalFitment) && criticalFitment.length > MagicNumber.ONE) ||
            (isArrayNotEmpty(list) && Array.isArray(list?.[0])))
    )
}

/**
 * Checks if single sku product
 * @param {ProductResponseData} productData - product data
 * @returns {boolean} - true if single sku product
 */
export const isSingleSkuProduct = (productData: ProductResponseData): boolean => {
    return isArrayNotEmpty(productData?.skus) && productData?.skus.length === MagicNumber.ONE
}

/**
 * Get PLP url and redirect it
 * @param {BreadcrumbLinkData[]} breadcrumbList - breadcrumb list
 * @param {string} homeUrl - home url
 * @returns {void} - void
 */
export const redirectToPLPURL = (breadcrumbList: BreadcrumbLinkData[], homeUrl: string): void => {
    const productListLink = getProductListUrl(breadcrumbList, homeUrl)
    window.open(productListLink, '_self', 'noopener')?.focus()
}

/**
 * Function to check selected tire details and previous tire details are not same
 * @param {ShopByTireSizeInitialState} selectedTireSize - selected tire size
 * @param {TireType | null} previosulySelectedData - previously selected data
 * @returns {boolean} - true if tire value changed
 */
export const isTireValueChanged = (
    selectedTireSize: ShopByTireSizeInitialState,
    previosulySelectedData: TireType | null,
): boolean => {
    return (
        selectedTireSize?.selectedAspectRatio !== previosulySelectedData?.selectedAspectRatio ||
        selectedTireSize?.selectedDiameterValue !== previosulySelectedData?.selectedDiameterValue ||
        selectedTireSize?.selectedSectionWidth !== previosulySelectedData?.selectedSectionWidth
    )
}

export const wheelSpecsRequireHighlight = ['ps_offset', 'ps_wheelrim_diameter', 'ps_rim_width']

/**
 * Function to return fitment note
 * @param {criticalFitmentData[] | null} criticalFitmentData - critical fitment data
 * @param {string} skuCode - sku code
 * @returns {string} - fitment note
 */
export const getFitmentNote = (criticalFitmentData: CriticalFitment[] | null, skuCode?: string): string | undefined => {
    const macthedSKU = criticalFitmentData?.find((skuData: CriticalFitment) => skuData?.sku === skuCode)
    if (checkDataLength(macthedSKU)) {
        return Array.isArray(macthedSKU?.tireWheelFitment)
            ? macthedSKU?.tireWheelFitment?.[0]?.note
            : macthedSKU?.tireWheelFitment?.note
    }
    return undefined
}

export const extractWeatherTechSpecifications = (specifications?: ProductSpecifications[]) => {
    const vendorGroupId = specifications?.find(spec => spec.code === specifictionsCode.vendorGroupId)
    const vendorGroupName = specifications?.find(spec => spec.code === specifictionsCode.vendorGroupName)
    return { vendorGroupId, vendorGroupName }
}

export const isShowWeatherTechVehicleSelector = <PropsType extends WeatherTechVehicleSelectorProps>(
    props: PropsType,
    productData?: ProductResponseData,
): boolean => {
    return Boolean(
        areAllParamsValid(
            stringToBooleanMap[props.weathertechProps?.showPDPWeatherTechVehicleSelector || 'false'],
            isWeatherTechApplicableBrandAndCategory(props, productData),
            productData?.fitmentTypeCode === FitmentTypeEnum.DIRECT_FIT_02,
            areAllParamsValid(...Object.values(extractWeatherTechSpecifications(productData?.specifications))),
        ),
    )
}
