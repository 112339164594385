import { magicNumber } from './magicNumber'

/**
 * Function to truncate and add ellipsis for string.
 * @param text
 * @param maxLength
 * @returns {string}
 */
export const truncateWithEllipsis = (text = '', maxLength = magicNumber.FORTYFIVE): string => {
    if (text.length > maxLength) {
        return text.slice(0, maxLength) + '...'
    }
    return text
}
