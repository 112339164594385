import { simpleHeaderCheckoutConst } from './SimpleHeaderCheckout.constant'
import analyticsLayer from '../../utils/analyticsService'
import { BREAKPOINTS } from '../../config'

const SimpleHeaderCheckout = (): void => {
    const simpleHeaderComp = document.getElementsByClassName(simpleHeaderCheckoutConst.name)[0]
    const elHeight = simpleHeaderComp?.clientHeight || 0
    const elTop = simpleHeaderComp?.getBoundingClientRect().top > 0 ? simpleHeaderComp?.getBoundingClientRect().top : 0
    const contentHeight = elHeight + elTop

    /**
     * Adding and Removing sticky on scroll
     */
    const handleScroll = (): void => {
        if (window.scrollY > contentHeight && window.innerWidth <= BREAKPOINTS.tabletMaxWidth) {
            simpleHeaderComp?.classList.add(simpleHeaderCheckoutConst.sticky)
        } else {
            simpleHeaderComp?.classList.remove(simpleHeaderCheckoutConst.sticky)
        }
    }

    window.addEventListener('scroll', handleScroll)

    const logoComp = document.getElementById(simpleHeaderCheckoutConst.logo)
    logoComp?.addEventListener('click', function () {
        analyticsLayer.pushData({
            event: 'interaction',
            eventParameters: {
                action: 'click on internal link',
                category: 'interaction',
                label: logoComp.getAttribute('alt') || simpleHeaderCheckoutConst.logoText,
                value: null,
                location: 'simple header',
            },
        })
    })
}

export { SimpleHeaderCheckout }
