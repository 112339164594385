import {
    currencyShortForm as currentCurrencyShortForm,
    defaultLocale,
    frenchLocale,
} from '../globalConstants/global.constant'
import { getLanguage } from '../utils'
import { magicNumber } from '../utils/magicNumber'

/**
 * Localization helper function.
 * @function
 * @method formatCurrency - Format price based on language and the currency code.
 */
export const localization = (() => {
    /**
     * Function to construct the locale based on the language on the document and currency code.
     * @param {number} language - ex: en or fr
     * @param {string} currencyShortForm - currency code ex: CA
     * @return {string} locale. ex: en-CA
     */
    const _constructLocale = (language: string, currencyShortForm: string): string => {
        return `${language}-${currencyShortForm}`
    }

    /**
     * Format the currency based on language and country.
     * @method
     * @param {string} language - ex: en or fr
     * @param {number} amount - price ex: 40.00
     * @param {string} currencyShortForm - ex: CA
     * @param {string} currencyFormat - ex: CAD
     * @param {number} maximumFractionDigits - ex: 2
     * @param {number} minimumFractionDigits - ex: 2
     * @return {string} - $40.00 or 40,00 $
     */
    const formattedPrice = (
        language: string,
        amount: number,
        currencyShortForm: string,
        currencyFormat: string,
        maximumFractionDigits: number | undefined = magicNumber.TWO,
        minimumFractionDigits: number | undefined = magicNumber.TWO,
    ): string => {
        const numberFormatOptions = {
            style: 'currency' as const,
            currency: currencyFormat,
            currencyDisplay: 'narrowSymbol' as const,
            maximumFractionDigits,
            minimumFractionDigits,
        }

        try {
            return new Intl.NumberFormat(_constructLocale(language, currencyShortForm), numberFormatOptions)?.format(
                amount,
            )
        } catch (error) {
            return new Intl.NumberFormat(_constructLocale(language, currencyShortForm), {
                ...numberFormatOptions,
                currencyDisplay: 'symbol' as const,
            })?.format(amount)
        }
    }

    /**
     * Format the date based on language and country.
     * @method
     * @param {Record<string, unknown>} options options for displaying the date
     * @param {string} date date-time stamp to be formatted
     * @param {string} language - ex: en or fr
     * @return {string} returns formatted date
     */
    const formattedDate = (options: Record<string, unknown>, date: string, language: string): string => {
        try {
            return new Intl.DateTimeFormat(_constructLocale(language, currentCurrencyShortForm), options).format(
                new Date(date),
            )
        } catch (error) {
            return new Date(date).toLocaleString(_constructLocale(language, currentCurrencyShortForm), options)
        }
    }

    const isEnglish = Boolean(getLanguage() === defaultLocale)
    const isFrench = Boolean(getLanguage() === frenchLocale)

    return {
        formattedPrice,
        formattedDate,
        isEnglish,
        isFrench,
    }
})()
