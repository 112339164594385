import { statusLabel } from '../../globalConstants/global.constant'
import { ButtonStatusClassType } from '../../utils/ButtonStatus/getButtonStatus.type'
import { ButtonColors } from '../ButtonV2/Button.types'

/**
 * return the state color class
 * @param {ButtonStatusClassType} className
 * @returns {  ButtonColors }
 */
export const getColorClass = (className: ButtonStatusClassType): ButtonColors => {
    switch (className) {
        case statusLabel.activateStatus?.toLocaleLowerCase():
            return ButtonColors.ACTIVATE
        case statusLabel.activatedStatus?.toLocaleLowerCase():
            return ButtonColors.LIGHT
        case statusLabel.swapOfferStatus?.toLocaleLowerCase():
            return ButtonColors.DARK
        case statusLabel.redeemedStatus?.toLocaleLowerCase():
            return ButtonColors.LIGHT
        default:
            return ButtonColors.DEFAULT
    }
}
