import React, { useEffect, useRef, useState } from 'react'

import {
    SkeletonComponent,
    isArrayNotEmpty,
    Icon,
    stringKeyCodes,
    useGlobalResizeEvent,
    arrowActionIdentifier,
} from '@nl/lib'
import { BREAKPOINTS, PREFIX } from '../../config'
import { StackedCategoryListNewProps, StackedCategoryPayloadType } from './StackedCategoryListNew.type'
import { RootState } from '../../redux/reducers'
import { MagicNumber } from '../../analytics/analytics.type'
import { commonContentSelector } from '../../redux/selectors/commonContent.selectors'
import { getEnvironment } from '../../environments'
import {
    choiceSourceData,
    sortCategoryDataByName,
    getActiveSubcategoryElement,
    handleArrowsForCategories,
} from './StackedCategoryListNew.helper'
import { blurElement } from '../../utils/blurElement.utils'
import StackedSubcategoriesListNew from './StackedSubcategoriesListNew'
import { AUTHOR, CATEGORYIMAGE, selectorCategories, stackedCategoryListNew } from './StackedCategoryListNew.constant'
import { useAppSelector } from '../../hooks/react-redux.hook'

/**
 *
 * @param root0
 * @param root0.numberOfItems
 * @param root0.categoryListSize
 * @param root0.viewLessCategoriesLabel
 * @param root0.viewMoreCategoriesLabel
 * @param root0.enableShopAllCategory
 * @param root0.image
 * @param root0.imageAltText
 * @param root0.shopAllLink
 * @param root0.shopAllLinkLabel
 * @param root0.enableAlphabeticalSorting
 * @param root0.nextCategoriesButtonLabel
 * @param root0.previousCategoriesButtonLabel
 * @param root0.enableShopAllLink
 * @param root0.shopAllLinkPlaceholder
 */
const StackedCategoryListNew: React.FC<StackedCategoryListNewProps> = ({
    categoryListSize,
    enableShopAllLink,
    image,
    imageAltText,
    shopAllLinkLabel,
    shopAllLinkPlaceholder,
    enableAlphabeticalSorting,
    nextCategoriesButtonLabel,
    previousCategoriesButtonLabel,
    dataSource,
    categories,
    viewAllCategoriesLinkLabel,
}: StackedCategoryListNewProps): JSX.Element => {
    const categoryIdFromRootState = useAppSelector((state: RootState) => state.categoryIdData.id)
    const secondaryNavigationData = useAppSelector((state: RootState) => state.secondaryNavigation)
    const { commonContentAvailable } = useAppSelector(commonContentSelector)
    const isAuthorCategories = dataSource === AUTHOR
    const urlData = new URL(window.location.href)
    const categoriesLevel = choiceSourceData(
        isAuthorCategories,
        categories as unknown as StackedCategoryListNewProps,
        categoryIdFromRootState,
        secondaryNavigationData,
        urlData?.pathname,
    )
    const categoriesAll = enableAlphabeticalSorting
        ? sortCategoryDataByName(categoriesLevel as StackedCategoryPayloadType[])
        : [...categoriesLevel]
    const categoriesAllLength = categoriesAll?.length || MagicNumber.ZERO
    const config = getEnvironment()
    const gridClass = `${PREFIX}-product-category__list-categories`
    // eslint-disable-next-line no-magic-numbers
    const classListType = Number(categoryListSize) === 10 ? 'list-10' : 'list-7'

    const [noOfCategoriesToShow, setNoOfCategoriesToShow] = useState(0)
    const [selectedCategoryItem, setSelectedCategoryItem] = useState<
        StackedCategoryPayloadType | Record<string, unknown>
    >({})
    const [firstSubcategoryItem, setFirstSubcategoryItem] = useState<HTMLElement | null>(null)
    const [showViewMore, setShowViewMore] = useState(true)
    const [showSkeleton, setShowSkeleton] = useState(false)
    const [isMobile, setIsMobile] = useState(window.innerWidth <= BREAKPOINTS.mobileMaxWidth)
    const categoriesListRef = useRef<HTMLUListElement>(null)

    const handleResize = () => {
        setIsMobile(window.innerWidth <= BREAKPOINTS.mobileMaxWidth)
    }

    useGlobalResizeEvent(handleResize)

    const { a11yExpandText, a11yCollapseText } =
        commonContentAvailable?.accessibility || ({} as typeof commonContentAvailable.accessibility)

    useEffect(() => {
        if (categoriesAllLength) {
            const categorySize = Number(categoryListSize) - 1
            const categoriesLength = categoriesAllLength > categorySize ? categorySize : categoriesAllLength
            setNoOfCategoriesToShow(categoriesLength)
            setShowSkeleton(false)
        } else {
            setShowSkeleton(true)
        }
    }, [categoriesAllLength, categoryListSize])

    useEffect(() => {
        if (firstSubcategoryItem) {
            firstSubcategoryItem.focus()
        }
    }, [firstSubcategoryItem])

    const buttonAriaLabel = showViewMore ? a11yExpandText : a11yCollapseText

    /**
     * Renders skeleton when there is no recommendation data.
     * @returns {JSX.Element[]}
     */
    const renderSkeletonCategory = (): JSX.Element[] => {
        const countCategories = Number(categoryListSize)
        const skeletonElements = []
        for (let index = 0; index < countCategories - 1; index++) {
            skeletonElements.push(
                <div className={`${PREFIX}-product-category__skeleton`}>
                    <SkeletonComponent skeletonClass={`${PREFIX}-product-category__skeleton-item`} width="100%" />
                </div>,
            )
        }
        return skeletonElements
    }
    /**
     * Renders skeleton when there is no recommendation data.
     * @returns {JSX.Element}
     */
    const renderSkeletonComponent = (): JSX.Element => {
        return <div className={`${PREFIX}-product-category__skeleton-wrapper`}>{renderSkeletonCategory()}</div>
    }

    /**
     * Button on click handler for toggling between next and previous list of categories to show.
     * @param {React.MouseEvent<HTMLButtonElement, MouseEvent>} event event
     */
    const onButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        blurElement(event)
        setShowViewMore(prevState => !prevState)
        setSelectedCategoryItem({})
    }
    /**
     * @param {React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement>} event event
     * @param {string | undefined} url url
     * @param {string} categoryClass categoryClass
     * @param {StackedCategoryPayloadType} category category
     * @returns {void}  React.MouseEvent<HTMLElement, MouseEvent>)
     */
    const onCategoryClick = (
        event: React.MouseEvent | React.KeyboardEvent<HTMLElement>,
        url: string | undefined,
        categoryClass: string,
        category: StackedCategoryPayloadType,
    ): void => {
        if (categoryClass && url) {
            event.preventDefault()
            event.stopPropagation()
            window.open(url, '_self', 'noopener')
        } else if (selectedCategoryItem.id !== category.id) {
            setSelectedCategoryItem(category)
            blurElement(event as React.MouseEvent<HTMLElement, MouseEvent>)
            const firstSubcategory = getActiveSubcategoryElement(event.currentTarget.nextElementSibling?.children[0])
            event.type !== 'click' && setFirstSubcategoryItem(firstSubcategory)
        } else {
            setSelectedCategoryItem({})
            setFirstSubcategoryItem(null)
            event.type === 'click' && blurElement(event as React.MouseEvent<HTMLElement, MouseEvent>)
        }
    }

    /**
     * @param {React.KeyboardEvent<HTMLElement>} event event
     * @param {string | undefined} url url
     * @param url
     * @param {string} categoryClass categoryClass
     * @param {StackedCategoryPayloadType} category category
     * @param {number} currentIndex currentIndex
     * @returns {void}
     */
    const handleKeyDown = (
        event: React.KeyboardEvent<HTMLElement>,
        url: string | undefined,
        categoryClass: string,
        category: StackedCategoryPayloadType,
        currentIndex: number,
    ) => {
        if (event.key === stringKeyCodes.enter) {
            onCategoryClick(event, url, categoryClass, category)
        } else if (event.key === stringKeyCodes.downArrow) {
            handleArrowsForCategories(
                event,
                arrowActionIdentifier.NEXT,
                currentIndex,
                categoriesListRef,
                selectorCategories,
            )
            // eslint-disable-next-line sonarjs/elseif-without-else
        } else if (event.key === stringKeyCodes.upArrow) {
            handleArrowsForCategories(
                event,
                arrowActionIdentifier.PREVIOUS,
                currentIndex,
                categoriesListRef,
                selectorCategories,
            )
        }
    }

    const listRenderCategories = () => {
        if (isMobile) {
            return categoriesAll
        }
        if (showViewMore) {
            return categoriesAll?.slice(0, noOfCategoriesToShow)
        } else {
            return categoriesAll?.slice(noOfCategoriesToShow, categoriesAll.length)
        }
    }

    /**
     * Renders chevron depend on state of category.
     * @param {string} singleCategoryId singleCategoryId
     * @returns {JSX.Element}
     */
    const renderChevronCategory = (singleCategoryId: string): JSX.Element => {
        if (selectedCategoryItem.id === singleCategoryId) {
            return <Icon type={isMobile ? 'ct-chevron-up' : 'ct-chevron-left'} size="lg" />
        } else {
            return <Icon type={isMobile ? 'ct-chevron-down' : 'ct-chevron-right'} size="lg" />
        }
    }

    /**
     * Function to render categories.
     * @returns {JSX.Element}
     */
    const renderCategories = (): JSX.Element => {
        const classListCategories = showViewMore ? '' : 'second-list-categories'
        const textButton = showViewMore ? nextCategoriesButtonLabel : previousCategoriesButtonLabel
        const classButton = showViewMore ? '-next' : '-previous'
        const chevronType = showViewMore ? 'ct-chevron-down' : 'ct-chevron-up'
        return (
            <>
                <ul className={`${gridClass} ${classListCategories}`} ref={categoriesListRef}>
                    {listRenderCategories()?.map((singleCategory, index: number) => {
                        const url = singleCategory.url
                        const isHasSubcategories = isArrayNotEmpty(singleCategory.subcategories)
                        const categoriesClass = isHasSubcategories ? '' : 'without-subcategories'
                        const categoriesActiveClass =
                            selectedCategoryItem.id === singleCategory.id ? 'active-category' : ''
                        const hideClass =
                            index + MagicNumber.ONE > Number(categoryListSize)
                                ? // eslint-disable-next-line sonar/no-nested-conditional
                                  showViewMore
                                    ? `${PREFIX}-product-category__content--hide-items`
                                    : ''
                                : ''
                        return (
                            <li
                                key={`${singleCategory.id}_${index}`}
                                className={`${PREFIX}-product-category__content-wrapper ${hideClass}`}>
                                <div
                                    className={`${PREFIX}-product-category__content ${categoriesClass} ${categoriesActiveClass}`}
                                    role={'button'}
                                    aria-label={isHasSubcategories ? '' : 'link'}
                                    aria-expanded={selectedCategoryItem.id === singleCategory.id}
                                    onClick={(event: React.MouseEvent) =>
                                        onCategoryClick(
                                            event,
                                            url,
                                            categoriesClass,
                                            singleCategory as StackedCategoryPayloadType,
                                        )
                                    }
                                    onKeyDown={(event: React.KeyboardEvent<HTMLElement>) =>
                                        handleKeyDown(
                                            event,
                                            url,
                                            categoriesClass,
                                            singleCategory as StackedCategoryPayloadType,
                                            index,
                                        )
                                    }
                                    dap-wac-link={'true'}
                                    dap-wac-loc={stackedCategoryListNew}
                                    dap-wac-value={singleCategory.name}
                                    tabIndex={0}>
                                    <div className={`${PREFIX}-product-category__content-image`}>
                                        <img
                                            src={singleCategory.image ?? config.defaultProductImage}
                                            alt={CATEGORYIMAGE}
                                        />
                                    </div>
                                    <span className={`${PREFIX}-product-category__content-name ${categoriesClass}`}>
                                        {singleCategory.name}
                                    </span>
                                    {isHasSubcategories && renderChevronCategory(singleCategory.id)}
                                </div>
                                <StackedSubcategoriesListNew
                                    subCategories={singleCategory?.subcategories as StackedCategoryPayloadType[]}
                                    noOfCategoriesToShow={noOfCategoriesToShow}
                                    enableAlphabeticalSorting={enableAlphabeticalSorting}
                                    shopAllLinkLabel={
                                        isAuthorCategories
                                            ? (singleCategory.shopAllLinkLabel as string)
                                            : shopAllLinkLabel
                                    }
                                    shopAllLinkPlaceholder={shopAllLinkPlaceholder}
                                    style={selectedCategoryItem.id === singleCategory.id ? 'show-subcategories' : ''}
                                    enableShopAllLink={enableShopAllLink}
                                    singleCategory={singleCategory as StackedCategoryPayloadType}
                                    isAuthorCategories={isAuthorCategories}
                                    viewAllCategoriesLinkLabel={viewAllCategoriesLinkLabel}
                                />
                            </li>
                        )
                    })}
                </ul>
                {noOfCategoriesToShow < categoriesAllLength && (
                    <button
                        id="view-next-previous-button"
                        className={`${PREFIX}-button--tertiary ${PREFIX}-product-category__button ${PREFIX}-product-category__button${classButton}`}
                        onClick={event => onButtonClick(event)}
                        aria-label={buttonAriaLabel}>
                        <span className={`${PREFIX}-product-category__button-title`}>{textButton}</span>
                        <Icon type={`${chevronType}`} size="lg" />
                    </button>
                )}
            </>
        )
    }

    return (
        <div className={`${PREFIX}-stacked-category-list-component  ${classListType}`}>
            {noOfCategoriesToShow > 0 ? renderCategories() : showSkeleton && renderSkeletonComponent()}
            <div className={`${PREFIX}-product-category__image`}>
                {noOfCategoriesToShow > 0 ? (
                    <img src={image} alt={imageAltText} />
                ) : (
                    showSkeleton && (
                        <SkeletonComponent skeletonClass={`${PREFIX}-product-category__image-skeleton-wrapper`} />
                    )
                )}
            </div>
        </div>
    )
}

export default StackedCategoryListNew
