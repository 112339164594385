import React, { useRef } from 'react'
import PropTypes from 'prop-types'

import { PREFIX, previousElementName } from '../config'
import ReactModal from '../ReactModal'
import { modalAccessibilityHandler } from '../../utils/modalAccessibility'
import { useClickOutsideClose } from '../Tooltip/useClickOutside'
import { InformationModalProps } from './InformationModal.types'
import Icon from '../Icon'
import { useState } from 'react'
import Table from '../Table'
import Button from '../Button'

/**
 * Rich Text modal component
 * @return {JSX.Element} returns Overlay component
 */
const InformationModal: React.FC<InformationModalProps> = ({ ...props }): JSX.Element => {
    const {
        closeInformationModal,
        isInformationModalOpen,
        enableOutSideClick,
        path,
        tabs,
        title,
        subtitle,
        description,
        indexSelected,
        a11yCloseIconLabel,
    } = props

    const unity = 1

    const [selectedIndex, setSelectedIndex] = useState(indexSelected || unity)

    const componentClassName = `${PREFIX}-information-modal`

    const contentRef = useRef(null)

    // Below code is for handling the accessibility when clicked on close button
    const modalClassName = `${PREFIX}-react-modal`

    const previousElement: HTMLElement = document.querySelector(`button[${previousElementName}]`)

    /**
     * To handle accessibility on keyboard tab. Enables the modal accessibility when clicked on the trigger element.
     *
     * @return {void}
     */
    const onInformationModal = (): void => {
        modalAccessibilityHandler({ modalOpen: false, modalClassName })
        closeInformationModal()
        if (previousElement) {
            previousElement.focus() // Highlight the initiated button
            previousElement.removeAttribute(previousElementName) // Removing it when user close it.
        }
    }

    useClickOutsideClose(contentRef, () => onInformationModal(), isInformationModalOpen, enableOutSideClick)

    return (
        <ReactModal closeHandler={() => onInformationModal()} isOpen={isInformationModalOpen}>
            <div className={componentClassName} ref={contentRef}>
                <div className={`${componentClassName}__close-container`}>
                    <Button
                        id="info-button"
                        type="icon_button"
                        ariaLabel={a11yCloseIconLabel}
                        onClick={() => onInformationModal()}>
                        <Icon type="ct-close" size="lg" path={path} />
                    </Button>
                </div>
                <div>
                    <h1 className={`${componentClassName}__header-text`}>{title}</h1>
                    {tabs ? (
                        <>
                            <div className={`${componentClassName}__tabs`}>
                                {tabs.map((item, index) => {
                                    const activeClass =
                                        selectedIndex === index ? `${componentClassName}__tab-button--active` : ''
                                    return (
                                        <div
                                            className={`${componentClassName}__tab-button ${activeClass}`}
                                            key={index}
                                            role="button"
                                            tabIndex={0}
                                            onKeyDown={() => {
                                                setSelectedIndex(index)
                                            }}
                                            onClick={() => {
                                                setSelectedIndex(index)
                                            }}>
                                            {item.tabTitle}
                                        </div>
                                    )
                                })}
                            </div>
                            <div>
                                {tabs[selectedIndex].tableData ? (
                                    <Table
                                        tableType="stripped"
                                        headerType="column"
                                        tableData={tabs[selectedIndex].tableData}
                                    />
                                ) : (
                                    <div className={`${componentClassName}__text-container`}>
                                        <div className={`${componentClassName}__subtitle`}>
                                            {tabs[selectedIndex].subtitle}
                                        </div>
                                        <div
                                            className={`${componentClassName}__content`}
                                            dangerouslySetInnerHTML={{ __html: tabs[selectedIndex].description }}
                                        />
                                    </div>
                                )}
                            </div>
                        </>
                    ) : (
                        <div className={`${componentClassName}__text-container`}>
                            <div className={`${componentClassName}__subtitle`}>{subtitle}</div>
                            <div
                                className={`${componentClassName}__content`}
                                dangerouslySetInnerHTML={{ __html: description }}
                            />
                        </div>
                    )}
                </div>
            </div>
        </ReactModal>
    )
}

InformationModal.displayName = 'InformationModal'

InformationModal.defaultProps = {
    enableOutSideClick: false,
}

InformationModal.propTypes = {
    closeInformationModal: PropTypes.func,
    isInformationModalOpen: PropTypes.bool,
    enableOutSideClick: PropTypes.bool,
    indexSelected: PropTypes.number,
    path: PropTypes.string,
    tabs: PropTypes.array,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    description: PropTypes.string,
}

export default InformationModal
