import React from 'react'
import ReactDOM from 'react-dom/client'
import { string, func } from 'prop-types'
import { Icon, Button, libUtils } from '@nl/lib'
import { PasswordVisibilityToggleType } from './gigyaScreen.type'
import { IGlobalProps } from '../../redux/models/commonContent.interface'
import sessionStorageService from '../../utils/sessionStorageService'
import { PREFIX } from '../../config'

const returnLabel = (returnType: string): string => {
    const language: string = libUtils.getLanguage()
    const commonContentAvailable: IGlobalProps = JSON.parse(
        sessionStorageService.getItem(`global_props_${language}`) ?? '{}',
    ) as IGlobalProps
    const { showLabel = '', hideLabel = '' } = commonContentAvailable.general || {}

    if (returnType === 'show') {
        return showLabel
    } else {
        return hideLabel
    }
}

export const addPasswordVisibilityToggle = (el: HTMLInputElement, container: HTMLElement | null): void => {
    const iconContainer = document.createElement('div')
    iconContainer.classList.add('pwd-visibility-toggle', 'passwordTypeInput')
    iconContainer.setAttribute('aria-live', 'polite')
    /**
     * showHideBtnRendered checks is show/hide button has already been rendered
     */
    const showHideBtnRendered = el.parentElement?.querySelector('.pwd-visibility-toggle')
    showHideBtnRendered && showHideBtnRendered.remove()

    const root = ReactDOM.createRoot(iconContainer)
    root.render(
        <PasswordVisibilityToggle
            ariaLabel={libUtils.getStringOrDefault(container?.dataset.passVisibilityToggle)}
            onClick={() => onPasswordVisibilityToggle(el)}
        />,
    )
    el.parentElement?.append(iconContainer)
}
/**
 * onPasswordVisibilityToggle
 * @param {HTMLInputElement} el - Password input
 */
const onPasswordVisibilityToggle = (el: HTMLInputElement): void => {
    const isPasswordInput = el.getAttribute('type') === 'password'
    const container = el.parentElement?.lastChild as HTMLDivElement
    container?.classList.toggle('textTypeInput')
    container?.classList.toggle('passwordTypeInput')

    const toggleDivs: NodeListOf<HTMLDivElement> | undefined =
        container?.parentElement?.querySelectorAll(`.pwd-visibility-toggle`)

    const textTypeInput = 'pwd-visibility-toggle textTypeInput'
    const passwordTypeInput = 'pwd-visibility-toggle passwordTypeInput'
    toggleDivs?.forEach(toggleDiv => {
        el.setAttribute('type', isPasswordInput ? 'text' : 'password')
        // eslint-disable-next-line sonar/no-nested-assignment
        isPasswordInput ? (toggleDiv.className = textTypeInput) : (toggleDiv.className = passwordTypeInput)
    })
}

/**
 * PasswordVisibilityToggle
 * @return {JSX.Element} Any - returns PasswordVisibilityToggle Component
 */
const PasswordVisibilityToggle: React.FC<PasswordVisibilityToggleType> = ({ ...props }) => {
    const { ariaLabel, onClick } = props

    return (
        <Button id="passwordVisibilityToggle" type="icon_button" onClick={onClick} ariaLabel={ariaLabel}>
            <Icon size="lg" type="ct-show" />
            <span className={`${PREFIX}-pwd-show`}>{returnLabel('show')}</span>
            <Icon size="lg" type="ct-hide" />
            <span className={`${PREFIX}-pwd-hide`}>{returnLabel('hide')}</span>
        </Button>
    )
}

PasswordVisibilityToggle.propTypes = {
    ariaLabel: string.isRequired,
    onClick: func.isRequired,
}

export default PasswordVisibilityToggle
