import React, { useCallback, useEffect, useRef, useState } from 'react'
import { FeaturedProductList, libUtils, isArrayNotEmpty, SkeletonComponent } from '@nl/lib'
import { RootState } from '../../redux/reducers'
import { FeaturedProductListType } from './FeaturedProductList.type'
import { path, PREFIX } from '../../config'
import { fetchRecommendationsData } from '../../redux/actions'
import { ProductCardType, RecommendationResponseDataDTO } from '../../redux/models/recommendations.interface'
import { AkamaiImagePolicies } from '../../akamaiPolicy/akamaiPolicy.service'
import { setOgImageTag } from '../../redux/actionCreators/ogImageTag.actionCreators'
import { commonContentAvailableSelector, commonContentSelector } from '../../redux/selectors/commonContent.selectors'
import { useAppDispatch, useAppSelector } from '../../hooks/react-redux.hook'

/**
 * FeaturedProductListComponent component
 * @param {FeaturedProductListType} props
 * @return {JSX.Element} returns FeaturedProductList component
 */
export const FeaturedProductListComponent: React.FC<FeaturedProductListType> = (
    props: FeaturedProductListType,
): JSX.Element => {
    const dispatch = useAppDispatch()
    const { commonContentAvailable } = useAppSelector(commonContentSelector)

    const { recommendationsData } = useAppSelector((state: RootState) => state.recommendationsData)

    const [productListData, setProductListData] = useState([] as ProductCardType[])

    const [showSkeleton, setShowSkeleton] = useState(true)
    const [userReachedVP, setuserReachedVP] = useState(false)
    const [productContent, setProductContent] = useState(
        commonContentAvailable?.product || ({} as typeof commonContentAvailable.product),
    )
    const [accessibilityContent, setAccessibilityContent] = useState(
        commonContentAvailable?.accessibility || ({} as typeof commonContentAvailable.accessibility),
    )

    useEffect(() => {
        commonContentAvailable?.product && setProductContent(commonContentAvailable.product)
        commonContentAvailable?.accessibility && setAccessibilityContent(commonContentAvailable.accessibility)
    }, [commonContentAvailable])

    const {
        nowFromLabel,
        saveFromLabel,
        wasFromLabel,
        fromLabel,
        unitPriceLabel,
        promotionalPriceLabel,
        clearancePriceLabel,
        thresholdValue: threshold,
    } = productContent

    const { a11yStrikeOutPrice, a11yStrikeOutPriceRange, a11yCarouselNextLabel, a11yCarouselPreviousLabel } =
        accessibilityContent

    const priceProps = {
        unitPriceLabel,
        clearancePriceLabel,
        promotionalPriceLabel,
        threshold,
        nowFromLabel,
        saveFromLabel,
        wasFromLabel,
        fromLabel,
    }

    const recommendationWrapper = useRef(null)
    const runApiOnce = useRef(0)
    /**
     * Make api call when there are pcodes.
     */
    const dispatchAction = useCallback(() => {
        if (isArrayNotEmpty(props.products) && runApiOnce.current === 0) {
            const pCodes = props.products.map(product => product.skuId)
            if (!isArrayNotEmpty(pCodes)) setShowSkeleton(false)
            dispatch(fetchRecommendationsData(pCodes, recommendationsData, props.title))
            runApiOnce.current = 1
        }
    }, [dispatch, props.products, recommendationsData, props.title])

    // use effect to dispatch cds api call when pcodes are ready.
    useEffect(() => {
        const recommendationObserver = new IntersectionObserver(
            ([entry], _observer) => {
                if (entry.isIntersecting) {
                    dispatchAction()
                    setuserReachedVP(true)
                    _observer.disconnect()
                }
            },
            {
                root: null,
                rootMargin: '0px',
                threshold: 0.1,
            },
        )
        if (recommendationWrapper.current) {
            recommendationObserver.observe(recommendationWrapper.current)
        }
        return () => {
            recommendationObserver.disconnect()
        }
    }, [dispatchAction, recommendationWrapper])

    useEffect(() => {
        const productImage = props.products[0].productImage
        if (productImage) {
            dispatch(setOgImageTag({ component: 'FeaturedProductListComponent', imageLink: productImage }))
        }
    }, [props.products, dispatch])

    useEffect(() => {
        const productList =
            recommendationsData.find(
                (singleProductData: RecommendationResponseDataDTO) => singleProductData.scheme === props.title,
            )?.productData || []
        setProductListData(productList)
        if (isArrayNotEmpty(productList)) setShowSkeleton(false)
    }, [recommendationsData, props.title])

    const language = libUtils.getLanguage()

    const { featureFlag } = useAppSelector(commonContentAvailableSelector)
    const enableBoldedBrandName = featureFlag?.enableBoldedBrandName

    const renderFeaturedProductListComponent = (): JSX.Element | null => {
        // eslint-disable-next-line sonar/expression-complexity
        return recommendationsData && recommendationsData.length > 0 && !showSkeleton ? (
            <FeaturedProductList
                aemProps={{ ...props, a11yCarouselPreviousLabel, a11yCarouselNextLabel }}
                badgePriorities={commonContentAvailable.badges}
                productList={productListData}
                path={path}
                a11yStrikeOutPrice={a11yStrikeOutPrice}
                a11yStrikeOutPriceRange={a11yStrikeOutPriceRange}
                language={language}
                priceProps={priceProps}
                imageDataComponentName={'product-card'}
                returnPolicy={AkamaiImagePolicies.returnPolicy}
                a11yClickToReadFootnote={accessibilityContent.a11yClickToReadFootnote}
                enableBoldedBrandName={enableBoldedBrandName}
            />
        ) : // eslint-disable-next-line sonar/no-nested-conditional
        showSkeleton ? (
            <SkeletonComponent skeletonClass={`${PREFIX}-recommendations__skeleton`} />
        ) : null
    }

    return (
        <>
            <div ref={recommendationWrapper}>{renderFeaturedProductListComponent()}</div>
            {!userReachedVP && <SkeletonComponent skeletonClass={`${PREFIX}-recommendations__skeleton`} />}
        </>
    )
}
