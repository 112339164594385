import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { PREFIX } from '../config'
import Icon from '../Icon'
import { servicesPropTypes } from './CartServicesComponent.type'
import { ServiceDTO } from '../CartFlyout/CartFlyout.type'
import Accordion from '../Accordion'
import Radio from '../Radio'
import Button from '../Button'
import { getLanguage, replaceStrWithDynamicVal } from '../../utils'
import localization from '../../helpers'
import { currencyFormat, currencyShortForm } from '../../globalConstants/global.constant'
import { ProductDataDTO } from '../ProductCartItem/ProductItemInfo.type'

/**
 * CartServices component
 *
 * @param {servicesPropTypes} props - path, serviceAddOnLabel, serviceAddOnNextSteps, serviceAddOnIcon,serviceAddOnAvailableLabel, addServiceToCartLabel,
        cartServices,
        addServiceToCart= false;,
        serviceAddToCartHandler,
        serviceDescription,
 * @return {JSX.Element} returns CartServices component
 */
const CartServicesComponent: React.FC<servicesPropTypes> = ({ ...props }): JSX.Element => {
    const {
        path,
        serviceAddOnLabel,
        serviceAddOnNextSteps,
        serviceAddOnIcon,
        serviceAddOnAvailableLabel,
        addServiceToCartLabel,
        cartServices,
        addedServiceToCart,
        serviceAddToCartHandler,
        serviceDescription,
        onFlyout,
        parentProduct,
    } = props
    const parentClass = `${PREFIX}-cart-services`
    const serviceLength = 1
    const singleService = cartServices.length === serviceLength

    const initialServiceSelectValue = singleService ? cartServices[0].name : ''
    const [serviceSelect, setServiceSelect] = useState(initialServiceSelectValue)
    const [code, setCode] = useState({})

    /**
     * Below function renders the title in the accordion component.
     * @return {JSX.Element}
     */
    const titleComponent = (): JSX.Element => {
        const flyoutLabel = addedServiceToCart?.success ? serviceAddOnLabel : serviceAddOnAvailableLabel
        const description = !addedServiceToCart?.success ? serviceDescription : `${serviceSelect}`
        return (
            <>
                <span className={`${PREFIX}-installation__icon`}>
                    <Icon type={serviceAddOnIcon} size="lg" path={path} />
                </span>
                <div className={`${PREFIX}-service__title`}>
                    <div className={`${PREFIX}-service__title-name`}>{onFlyout ? flyoutLabel : serviceAddOnLabel}</div>
                    <div className={`${PREFIX}-service__title-description`}>
                        {onFlyout ? description : replaceStrWithDynamicVal(serviceDescription, cartServices.length)}
                    </div>
                </div>
            </>
        )
    }

    // Add selected service to the cart
    const addServiceToCartHandler = (): void => {
        const selectedServiceCode = singleService ? cartServices : [code]
        serviceAddToCartHandler(selectedServiceCode as ServiceDTO[], parentProduct as unknown as ProductDataDTO)
    }

    // set the state variable based on the selection
    const onSelectService = (sku: string, serviceItem: ServiceDTO): void => {
        setServiceSelect(sku)
        setCode(serviceItem)
    }

    // Toggle style when there is only for service.
    const panelClass = singleService ? `${parentClass}__panel--single` : `${parentClass}__panel`

    /**
     * Render the below component when the service list has only one item.
     * @param {string} singleServiceItem - single service item
     *
     * @return {JSX.Element}
     */
    const singleServiceComponent = (singleServiceItem: string): JSX.Element => {
        return (
            !onFlyout &&
            singleService && <div className={`${parentClass}__single-service-title`}>{singleServiceItem}</div>
        )
    }

    const formatPrice = localization.formattedPrice
    const language = getLanguage()
    /**
     * Display the service options
     * @return {JSX.Element}
     */
    const renderServices = (): JSX.Element => {
        return !addedServiceToCart?.success ? (
            <>
                {cartServices.map((serviceItem, i) => (
                    <div className={panelClass} key={i}>
                        <div className={`${panelClass}__not-on-flyout`}>
                            {!singleService && (
                                <Radio
                                    id={`${serviceItem.sku}_${parentProduct.entryNumber}`}
                                    label={serviceItem.name}
                                    name="service-installation"
                                    checked={serviceSelect === serviceItem.sku}
                                    onChange={() => onSelectService(serviceItem.sku, serviceItem)}
                                />
                            )}
                            {singleServiceComponent(serviceItem.name)}
                            <div className={`${parentClass}__panel-content`}>
                                <p className={`${parentClass}__description`}>{serviceItem?.shortDescription}</p>
                                {onFlyout && (
                                    <span className={`${parentClass}__price`}>
                                        {formatPrice(language, serviceItem?.price, currencyShortForm, currencyFormat)}
                                    </span>
                                )}
                            </div>
                        </div>
                        {!onFlyout && (
                            <span className={`${parentClass}__price`}>
                                {formatPrice(language, serviceItem?.price, currencyShortForm, currencyFormat)}
                            </span>
                        )}
                    </div>
                ))}
            </>
        ) : (
            <div className={`${parentClass}__service-next-steps`}>
                <Icon type="ct-information-details  " size="lg" path={path} />
                <span className={`${parentClass}__service-next-steps-label`}>{serviceAddOnNextSteps}</span>
            </div>
        )
    }

    /**
     * Hide controls based on the number of services i.e. hide control's when there is only one service.
     * Also show the controls for single service when the service installation is not part of the fly out.
     */
    const accordionSettings = (singleService || addedServiceToCart?.success) && onFlyout
    const accordionSettingsClassName = `${parentClass} ${parentClass}--single`

    return (
        <div className={`${accordionSettings ? accordionSettingsClassName : parentClass}`}>
            <Accordion
                title={titleComponent()}
                hideControls={accordionSettings}
                isHeaderOpen={accordionSettings}
                disableOnClick={accordionSettings}>
                <>
                    {renderServices()}
                    {
                        // eslint-disable-next-line no-warning-comments
                        /* TODO:  Awaiting design for the add to service cart button on cart item page  */
                    }
                    {(serviceSelect || singleService) && !addedServiceToCart?.success && (
                        <Button type="secondary" buttonType="button" size="small" onClick={addServiceToCartHandler}>
                            {addServiceToCartLabel}
                        </Button>
                    )}
                </>
            </Accordion>
        </div>
    )
}

CartServicesComponent.defaultProps = {
    onFlyout: true,
}

CartServicesComponent.propTypes = {
    path: PropTypes.string,
    cartServices: PropTypes.array,
    addServiceToCartLabel: PropTypes.string,
    serviceAddOnAvailableLabel: PropTypes.string,
    serviceAddOnIcon: PropTypes.string,
    serviceAddOnLabel: PropTypes.string,
    serviceAddOnNextSteps: PropTypes.string,
    addedServiceToCart: PropTypes.any,
    serviceAddToCartHandler: PropTypes.func,
    serviceDescription: PropTypes.string,
    onFlyout: PropTypes.bool,
    parentProduct: PropTypes.object,
}

export default CartServicesComponent
