export const AOA_SELECTED_SERVICES = 'AOASelectedServices'
export const AOA_PREVIOUSLY_SELECTED_SERVICES = 'AOAPreviouslySelectedServices'
export const AOA_SERVICE_CODE = 'serviceCode'
export const AOA_ADDITIONAL_COMMENTS = 'AOAAdditionalComments'
export const AOA_CTPROMO_SERVICE_CODE = 'CTP'
export const AOA_TIRE_CATEGORY_CODE = 'TWS01'
export const AOA_ONLINE_TIRE_PURCHASE_SERVICE_CODE = 'OPI'

// This regular expression will match a space followed by anything within brackets.
export const matchWordWithinParenthesisAtTheEndOfString = /\s*\([^)]*\)$/

// eslint-disable-next-line no-warning-comments
// TODO: This is Temporary block. this constant will be removed when taking care of failure scenarios later.
export const API_FAILURE_ERROR_MESSAGE = 'Something went wrong'
