import { MediaImages } from '@nl/lib'

/**
 *
 * @param images
 */
export const updateImagesWithSizes = (images: MediaImages[]): MediaImages[] => {
    return images.map(img => ({ ...img, url: `${img.url}?imheight=300` }))
}

/**
 *
 * @param images
 */
export const getImageThumbnailsList = (images: MediaImages[]): MediaImages[] => {
    return images.map(img => ({ ...img, url: `${img.url}?imheight=65&imwidth=65&impolicy=thumbnail` }))
}
