import { FORWARD_SLASH } from '@nl/lib'

import { CategoryResponseById, CategoryUrlType } from '../../redux/models/category.interface'
import { multiLanguageLabel, multiLanguageAltValue } from '../../globalConstants/global.constant'
import { HrefLangLinkData, ProductResponseData } from '../../redux/models/product.interface'
import { pageTypes } from '../../config'
import getPageType from '../../utils/getPageType'
import { StoreWithAvailability } from '../../redux/models/storeDetails.interface'
import { CategoryPagesType } from '../../global.type'

const pcodeIdentifiers = ['P', 'F']

const languagesToggleService = () => {
    const { englishLangLabel, frenchLangLabel } = multiLanguageLabel
    const { englishLang, frenchLang } = multiLanguageAltValue
    const pageType = getPageType()
    const { productPage, pdpPage, categoryPages, store } = pageTypes

    /**
     * Generate function to return either frenchLocale or defaultLocale on the basis of Languages label
     * @param {string} languageLabel
     * @returns {string} returns languageLocale
     */
    const _getHrefLang = (languageLabel: string): string[] => {
        return languageLabel === englishLangLabel
            ? [englishLangLabel.toLowerCase(), englishLang]
            : [frenchLangLabel.toLowerCase(), frenchLang]
    }

    /**
     * Generate function to return hrefPath on the basis of Languages label
     * @param {string} languageLabel
     * @param {HrefLangLinkData[]|CategoryUrlType[]} hrefLangUrls
     * @param {string} selectedCode
     * @param {string} shopAllLabel
     * @param {string} shopAllLabelAlternateLang
     * @param {boolean} isSKUPresentInUrl
     * @returns {string} returns hrefPath
     */
    const _getProductDetailsAltLang = (
        languageLabel: string,
        hrefLangUrls: HrefLangLinkData[] | CategoryUrlType[],
        selectedCode?: string,
        shopAllLabel?: string,
        shopAllLabelAlternateLang?: string,
        isSKUPresentInUrl = false,
    ): string => {
        const currentHost = window.location.origin
        const pathName = window.location.pathname
        let hrefPath = ''
        hrefLangUrls?.forEach((hrefLangUrl: HrefLangLinkData | CategoryUrlType) => {
            const href = (hrefLangUrl as HrefLangLinkData)?.href || (hrefLangUrl as CategoryUrlType)?.url
            const { hreflang } = hrefLangUrl
            if (_getHrefLang(languageLabel).includes(hreflang)) {
                let updatedhref = href
                const isProductCode =
                    selectedCode && pcodeIdentifiers.some(identifier => new RegExp(identifier, 'i').test(selectedCode))

                if (isSKUPresentInUrl && selectedCode && !isProductCode) {
                    const hrefArr = href.split('.')
                    hrefArr.splice(1, 0, selectedCode)
                    updatedhref = hrefArr.join('.')
                }
                hrefPath = currentHost + updatedhref + window.location.search
            }
        })
        // eslint-disable-next-line no-warning-comments
        // TODO: This is a temporary solution, technical debt is created to address this issue.
        const regPattern = new RegExp(/\/(en|fr)\//)
        const extractHreflang = regPattern.exec(hrefPath)
        hrefPath =
            pathName.includes(shopAllLabel as string) &&
            categoryPages.includes(pageType as CategoryPagesType) &&
            extractHreflang !== null
                ? hrefPath.replace(
                      /\/(en|fr)\//,
                      `${FORWARD_SLASH}${extractHreflang[1]}${FORWARD_SLASH}${
                          shopAllLabelAlternateLang ?? ''
                      }${FORWARD_SLASH}`,
                  )
                : hrefPath

        return hrefPath
    }

    /**
     * Generate function to return pagePath on the basis of pageType and Language Toggle Service
     * @param {string} languageLabel
     * @param {ProductResponseData} productData
     * @param {CategoryResponseById} categoryData
     * @param {StoreWithAvailability} storeDetails
     * @param {string} selectedCode
     * @param {string} shopAllLabel
     * @param {string} shopAllLabelAlternateLang
     * @param {boolean} isSKUPresentInUrl
     * @returns {string} returns hrefPath
     */
    const _getPageTypeLangUrl = (
        languageLabel: string,
        productData: ProductResponseData,
        categoryData: CategoryResponseById,
        storeDetails: StoreWithAvailability,
        selectedCode?: string,
        shopAllLabel?: string,
        shopAllLabelAlternateLang?: string,
        isSKUPresentInUrl = false,
        // eslint-disable-next-line max-params
    ): string => {
        let hrefData: HrefLangLinkData[] | CategoryUrlType[] | undefined
        switch (true) {
            case pageType === productPage:
            case pageType === pdpPage: {
                hrefData = productData?.hreflangLinkData
                break
            }
            case categoryPages.includes(pageType as CategoryPagesType): {
                hrefData = categoryData?.url
                break
            }
            case pageType === store: {
                hrefData = storeDetails?.hreflangLinkData
                break
            }
            default: {
                break
            }
        }
        return hrefData
            ? _getProductDetailsAltLang(
                  languageLabel,
                  hrefData,
                  selectedCode,
                  shopAllLabel,
                  shopAllLabelAlternateLang,
                  isSKUPresentInUrl,
              )
            : ''
    }

    return {
        getPageTypeLangUrl: _getPageTypeLangUrl,
    }
}

export { languagesToggleService }
export default languagesToggleService
