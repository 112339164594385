import React from 'react'
import { InformationCardProps } from './InformationCard.type'
import { PREFIX } from '../config'
import PropTypes from 'prop-types'
import CardItemRow from './CardItemRow'
import CommonCardWrapper from '../CommonCardWrapper'
import CommonCard from '../CommonCard'
import Button from '../Button'

/**
 * Reusable Information Card Component Renders a generic Card view With Header ,a Edit/Change button base on props received , and renders a data-list
 * @param {InformationCardProps} props -  header, data, actionButtonLabel, onActionButtonClick, enteredField, informationField
 * header - shows header title of card
 * data - contains the data-list of user filled information
 * actionButtonLabel - represnts the name for the change button
 * onActionButtonClick - changeHandler for actionButtonLabel
 * enteredField - value for aria-label
 * informationField - value for aria-label
 * @return {JSX.Element} returns Information Card
 */

export const InformationCard: React.FC<InformationCardProps> = ({ ...props }): JSX.Element => {
    const {
        header,
        data,
        actionButtonLabel,
        onActionButtonClick,
        enteredField,
        informationField,
        inApp,
        vehicleHeader,
        locationHeader,
    } = props

    const filteredData = data.filter(item => !!item.value)

    const isVehicleOrLocationHeader = header === vehicleHeader || header === locationHeader

    return (
        <>
            {filteredData.length > 0 && (
                <CommonCardWrapper noPadding={true}>
                    <CommonCard>
                        <div className={`${PREFIX}-information-card`}>
                            <div className={`${PREFIX}-row`}>
                                <div
                                    className={`${PREFIX}-information-card__section-header ${PREFIX}-col-md-4 ${PREFIX}-col-xs-4`}>
                                    {header}
                                </div>
                                {!(inApp && isVehicleOrLocationHeader) && actionButtonLabel ? (
                                    <div
                                        className={`${PREFIX}-information-card__text-end ${PREFIX}-col-md-8 ${PREFIX}-col-xs-2 `}>
                                        <Button
                                            data-testid={'changeButton'}
                                            ariaLabel={actionButtonLabel + ' ' + enteredField + ' ' + header}
                                            type="tertiary"
                                            size="mini"
                                            onClick={onActionButtonClick}>
                                            {actionButtonLabel}
                                        </Button>
                                    </div>
                                ) : (
                                    <span data-testid={'blankField'}></span>
                                )}
                            </div>
                            {filteredData.map(({ label, value }, index) => (
                                <CardItemRow
                                    key={index}
                                    label={label}
                                    value={value}
                                    enteredField={enteredField}
                                    informationField={informationField}
                                />
                            ))}
                        </div>
                    </CommonCard>
                </CommonCardWrapper>
            )}
        </>
    )
}

export default InformationCard

InformationCard.propTypes = {
    header: PropTypes.string.isRequired,
    data: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.string,
        }).isRequired,
    ).isRequired,
    actionButtonLabel: PropTypes.string.isRequired,
    onActionButtonClick: PropTypes.func.isRequired,
    enteredField: PropTypes.string,
    informationField: PropTypes.string,
    inApp: PropTypes.bool,
    vehicleHeader: PropTypes.string,
    locationHeader: PropTypes.string,
}
