import React, { useEffect, useRef, useCallback, useMemo } from 'react'

import {
    areAllParamsValid,
    CriticalFitment,
    isArrayNotEmpty,
    isAtleastOneParamValid,
    VariantsWrapper,
    isPrerenderOrNot,
} from '@nl/lib'
import { PREFIX } from '../../../config'
import { ProductOption, ProductSku } from '../../../redux/models/product.interface'
import { VariantsProps } from './Variants.type'
import { hideVehicleSelectorBannerStateSelector } from '../../../redux/selectors/hideVehicleSelectorBanner.selectors'
import { productSelector } from '../../../redux/selectors/product.selectors'
import { storeDetailsDataSelector } from '../../../redux/selectors/storeDetails.selectors'
import { checkDataLength } from '../../Accounts/Addresses/checkDataLength'
import {
    checkForExternalLink,
    checkIfMultipleSKUFitsFromCriticalfitment,
} from '../../AutomotivePDP/AutomotiveBuyBox/AutomotiveBuyBox.helper'
import { isAutomotivePDP, isAutoPartPDP, isWheelOrTirePDP, pdpURLDirectAccess } from '../../Vehicles/Vehicle.helper'
import {
    compareOptionIds,
    getSelectedDetailsFromSKU,
    getVariantNameFromQueryParam,
    resetSelectedVariantName,
    setSelectedVariantName,
    getVariantSelected,
} from '../BuyBox.helper'
import { MagicNumber } from '../../../analytics/analytics.type'
import { VariantType } from '@nl/lib/src/components/BuyBox/Buybox.type'
import { variantsConstant } from '@nl/lib/src/components/Variants/Variants.constant'
import { isObjectNotEmpty } from '../../../utils/isObjectNotEmpty'
import { miniPDPFlyoutDetailsSelector } from '../../../redux/selectors/miniPDPFlyoutData.selectors'
import { useAppSelector } from '../../../hooks/react-redux.hook'

const buyBoxVariants = `${PREFIX}-buy-box__variants`
const buyBoxAutoVariants = `${PREFIX}-buy-box__autovariants`
const { firstVariantName, secondVariantName, thirdVariantName } = variantsConstant

const Variants = ({
    checkIfAllVariantsSelected,
    isAddToCartOrWishlistClicked,
    isAutomotive,
    isSKUPresentInUrl,
    productData,
    commonContentAvailable,
    selectedFirstVariant,
    selectedFirstVariantDisplayName,
    selectedSecondVariant,
    selectedThirdVariant,
    selectedFirstVariantId,
    selectedSecondVariantId,
    selectedThirdVariantId,
    variantWarningLabel,
    viewMoreLabel,
    viewLessLabel,
    variantDetails,
    isVariantSelected,
    setIsVariantSelected,
    setSelectedCode,
    skuCode,
    criticalFitment,
    list,
    a11yVariantSelected,
    a11yVariantUnSelected,
    getVariantsForMultipleSKUfromFitment,
    selectedProductCode,
    isFirstVariantClicked,
    selectedColorCode,
    setIsFirstVariantClicked,
    setShowSizeChart,
    showSizeChart,
    setSelectedFirstVariantIdValue,
    setSelectedSecondVariantIdValue,
    setSelectedThirdVariantIdValue,
    setSelectedFirstVariantValue,
    setSelectedSecondVariantValue,
    setSelectedThirdVariantValue,
    isNoSkuFindStoreClicked,
    isAllSkusOOS,
    enableHidingFirstVariantWhereAllSkusOOS,
    a11yVariantsSelectedCount,
    inStockLabel,
    outOfStockLabel,
    enableOnlyAvailableOnline,
    availableOnlinePurchaseLabel,
    onlyAvailableOnlineAtLabel,
    isLimitedStock,
    enableCustomOrder,
    variantDetailsOrder,
    noUrlUpdates,
    setSelectedVariantByUserClick,
    flyoutVariantsInfoData,
}: VariantsProps): JSX.Element | null => {
    const generalProductData = useAppSelector(productSelector)
    const { hideVehicleSelectorBannerState } = useAppSelector(hideVehicleSelectorBannerStateSelector)
    const { preferredStoreDetails, isStoreNameChangedSuccess } = useAppSelector(storeDetailsDataSelector)
    const { isFlyoutVisible } = useAppSelector(miniPDPFlyoutDetailsSelector)
    const { variantsProductSkuData, selectedPartNumber, sizeChartData } = useMemo(
        () => flyoutVariantsInfoData || generalProductData,
        [flyoutVariantsInfoData, generalProductData],
    )
    const variantSelectionFromUrlRef = useRef(0)

    const queryParams = window.location.search

    const checkIfSkuPresentInTheFittedArray = useCallback(
        (selectedCode: string): boolean => {
            const { optionIds } = getSelectedDetailsFromSKU(productData, selectedCode)
            let fittedSkus: ProductSku[] = []
            let isSkuPresentInArray = {}
            if (optionIds) {
                if (isArrayNotEmpty(list) && !Array.isArray(list[0])) {
                    criticalFitment?.forEach((criticalFitmentData: CriticalFitment) => {
                        const fittedSkuObject = productData?.skus?.find(
                            (skuData: ProductSku) => skuData.code === criticalFitmentData.sku,
                        )
                        if (fittedSkuObject) {
                            fittedSkus.push(fittedSkuObject)
                        }
                    })
                } else {
                    fittedSkus = productData?.skus
                }
                isSkuPresentInArray =
                    fittedSkus.find((skucode: ProductSku) =>
                        compareOptionIds(skucode?.optionIds as string[], optionIds as string[]),
                    ) || {}
            }
            return checkDataLength(isSkuPresentInArray)
        },
        [productData, criticalFitment, list],
    )

    /**
     * Function to check if variants to be reset
     * @param {number[] | undefined}optionIds - optionIds
     * @returns {boolean} returns boolean
     */
    const checkIfVariantsToBeReset = useCallback(
        (optionIds?: string[]): boolean => {
            return !!(optionIds && !isSKUPresentInUrl && isWheelOrTirePDP(productData?.productWheelType))
        },
        [productData, isSKUPresentInUrl],
    )

    /**
     * Function to check if variants present or not
     * @returns {boolean} returns boolean
     */
    const checkIfVariantsPresent = (): boolean => {
        return !hideVehicleSelectorBannerState && isArrayNotEmpty(productData?.options)
    }

    /**
     * Function to check If Sku Present In Url And Not Tire Or Wheel
     * @returns {boolean} returns boolean
     */
    const checkIfSkuPresentInUrlAndNotTireOrWheel = useCallback((): boolean => {
        return (
            !isWheelOrTirePDP(productData?.productWheelType) &&
            (!isAutomotive || checkIfSkuPresentInTheFittedArray(skuCode))
        )
    }, [productData, isAutomotive, skuCode, checkIfSkuPresentInTheFittedArray])

    /**
     * useEffect to set the selected variant from query param
     */
    useEffect(() => {
        if (queryParams && Object.keys(productData).length) {
            getVariantNameFromQueryParam(
                queryParams,
                productData.options,
                setSelectedFirstVariantValue,
                setSelectedSecondVariantValue,
                setSelectedThirdVariantValue,
            )
        }
    }, [
        productData,
        queryParams,
        setSelectedFirstVariantValue,
        setSelectedSecondVariantValue,
        setSelectedThirdVariantValue,
    ])

    const firstVariantSelected = getVariantSelected(variantDetails?.firstVariant, selectedFirstVariantId)
    const secondVariantSelected = getVariantSelected(variantDetails?.secondVariant, selectedSecondVariantId)
    const thirdVariantSelected = getVariantSelected(variantDetails?.thirdVariant, selectedThirdVariantId)

    /**
     * Function to get variant assigned class selected/unselected
     * @param {VariantType} item - item
     * @param {string} variantKey - variantKey
     * @returns {boolean | undefined} returns boolean or undefined
     */
    const checkOutOfStock = (item: VariantType, variantKey: string): boolean => {
        if (isAllSkusOOS) {
            return true
        }
        const isSkuOutOfStock = (sku: string): boolean => {
            const requiredSku = variantsProductSkuData?.skus?.find(variant => variant.code === sku)
            const skuProductInfo = productData?.skus?.find(({ code }) => code === sku)
            const dcQuantity = !skuProductInfo?.isBopisOnly && requiredSku?.fulfillment.availability.Corporate?.Quantity
            const dcOrderable = requiredSku?.orderable
            return (
                // eslint-disable-next-line sonar/expression-complexity
                !!requiredSku &&
                requiredSku.fulfillment.availability.quantity === 0 &&
                (dcQuantity === 0 || !dcOrderable || !dcQuantity)
            )
        }
        let commonSkus = firstVariantSelected?.skuCodes
        switch (variantKey) {
            case firstVariantName:
                return item.skuCodes.every(isSkuOutOfStock)
            case secondVariantName:
                if (thirdVariantSelected) {
                    commonSkus = commonSkus?.filter(code => thirdVariantSelected?.skuCodes.includes(code))
                    return item.skuCodes.filter(code => commonSkus?.includes(code)).every(isSkuOutOfStock)
                } else if (!variantDetails.thirdVariant) {
                    return item.skuCodes.filter(code => commonSkus?.includes(code)).every(isSkuOutOfStock)
                } else return item.skuCodes.every(isSkuOutOfStock)
            case thirdVariantName:
                if (secondVariantSelected) {
                    commonSkus = commonSkus?.filter(code => secondVariantSelected?.skuCodes.includes(code))
                    return item.skuCodes.filter(code => commonSkus?.includes(code)).every(isSkuOutOfStock)
                } else {
                    return item.skuCodes.every(isSkuOutOfStock)
                }
            default:
                return false
        }
    }

    /**
     * This useEffect used to set pre selected variant options based on sku code
     */
    useEffect(() => {
        if (skuCode) {
            const { optionIds } = getSelectedDetailsFromSKU(productData, skuCode)
            if (
                // eslint-disable-next-line sonar/expression-complexity
                optionIds &&
                variantSelectionFromUrlRef.current === 0 &&
                (!isAutomotive ||
                    !pdpURLDirectAccess() ||
                    isAutomotivePDP(productData?.fitmentTypeCode, productData?.productWheelType))
            ) {
                if (checkIfSkuPresentInUrlAndNotTireOrWheel()) {
                    setSelectedVariantName(
                        variantDetails,
                        optionIds as string[],
                        setSelectedFirstVariantValue,
                        setSelectedSecondVariantValue,
                        setSelectedThirdVariantValue,
                        setSelectedFirstVariantIdValue,
                        setSelectedSecondVariantIdValue,
                        setSelectedThirdVariantIdValue,
                    )

                    setIsVariantSelected(true)
                    variantSelectionFromUrlRef.current = 1
                }
                // eslint-disable-next-line sonarjs/elseif-without-else
            } else if (checkIfVariantsToBeReset(optionIds as string[])) {
                resetSelectedVariantName(
                    variantDetails,
                    optionIds as string[],
                    setSelectedFirstVariantValue,
                    setSelectedSecondVariantValue,
                    setSelectedThirdVariantValue,
                    setSelectedFirstVariantIdValue,
                    setSelectedSecondVariantIdValue,
                    setSelectedThirdVariantIdValue,
                )
                setIsVariantSelected(false)
            }
        }
    }, [
        skuCode,
        productData,
        setSelectedFirstVariantValue,
        setSelectedThirdVariantValue,
        setSelectedSecondVariantValue,
        setSelectedFirstVariantIdValue,
        setSelectedSecondVariantIdValue,
        setSelectedThirdVariantIdValue,
        variantDetails,
        isAutomotive,
        checkIfVariantsToBeReset,
        checkIfSkuPresentInUrlAndNotTireOrWheel,
        setIsVariantSelected,
    ])

    /**
     * Check if variantDropdown available for autoparts
     * @returns {boolean} returns boolean
     */
    const showVariantDropdownForAutoPart = (): boolean => {
        return Boolean(
            // eslint-disable-next-line sonar/expression-complexity
            (isArrayNotEmpty(list) && Array.isArray(list[0])) ||
                checkForExternalLink() ||
                pdpURLDirectAccess() ||
                checkIfMultipleSKUFitsFromCriticalfitment(
                    criticalFitment,
                    productData?.fitmentTypeCode,
                    productData?.productWheelType,
                ),
        )
    }

    /**
     * useEffect to reset the dropdown on vehicle change
     */
    useEffect(() => {
        if (
            isAutomotivePDP(productData?.fitmentTypeCode, productData?.productWheelType) &&
            !pdpURLDirectAccess() &&
            isArrayNotEmpty(list) &&
            !Array.isArray(list[0])
        ) {
            const { optionIds } = getSelectedDetailsFromSKU(productData, selectedProductCode)
            if (!checkIfSkuPresentInTheFittedArray(selectedProductCode) && optionIds) {
                resetSelectedVariantName(
                    variantDetails,
                    optionIds as string[],
                    setSelectedFirstVariantValue,
                    setSelectedSecondVariantValue,
                    setSelectedThirdVariantValue,
                    setSelectedFirstVariantIdValue,
                    setSelectedSecondVariantIdValue,
                    setSelectedThirdVariantIdValue,
                )
                setIsVariantSelected(false)
            }
        }
    }, [
        setSelectedFirstVariantValue,
        setSelectedThirdVariantValue,
        setSelectedSecondVariantValue,
        setSelectedFirstVariantIdValue,
        setSelectedSecondVariantIdValue,
        setSelectedThirdVariantIdValue,
        productData,
        variantDetails,
        selectedProductCode,
        list,
        checkIfSkuPresentInTheFittedArray,
        setIsVariantSelected,
    ])

    /**
     * Checks if all skus out of stock and enable hiding first variant when all skus out of stock
     * @returns { boolean } returns boolean
     */
    const isAllSkusOOSAndEnabledHidingFirstVariant = (): boolean => {
        return isAllSkusOOS && enableHidingFirstVariantWhereAllSkusOOS
    }

    /**
     * Function to check for searched sku is present in criticalfitment response
     * @returns { boolean } returns boolean
     */
    const checkSearchedSkuPresentInCriticalFitment = (): boolean => {
        return Boolean(
            isSKUPresentInUrl
                ? criticalFitment &&
                      isArrayNotEmpty(criticalFitment) &&
                      criticalFitment.findIndex(item => item.sku === selectedProductCode) > -1
                : true,
        )
    }

    /**
     * check if variants present, if variantDropdown available for autoparts and if PDP is Auto part
     */
    const shouldRenderVariantsForAutomotive = areAllParamsValid(
        checkIfVariantsPresent(),
        showVariantDropdownForAutoPart(),
        Boolean(criticalFitment && isArrayNotEmpty(criticalFitment) && criticalFitment?.length > MagicNumber.ONE),
        isAutoPartPDP(productData?.fitmentTypeCode, productData?.productWheelType),
        !selectedPartNumber,
        checkSearchedSkuPresentInCriticalFitment(),
    )
    const shouldRenderVariants =
        isAtleastOneParamValid(!isAutomotive, shouldRenderVariantsForAutomotive) &&
        !isAllSkusOOSAndEnabledHidingFirstVariant()

    const isAvailableOldSizeChart = useMemo(() => {
        return !isFlyoutVisible && productData?.isSizeChartAvailable
    }, [isFlyoutVisible, productData])

    const isSizeChartAvailable = commonContentAvailable.featureFlag?.enableNewSizeChart
        ? isObjectNotEmpty(sizeChartData as unknown as Record<string, unknown>)
        : isAvailableOldSizeChart

    // prepares props for auto variant
    const autoVariantProps = {
        dynamicVariantLabel: variantWarningLabel,
        selectSizeVariantLabel: variantWarningLabel as string,
        variantOptions: (checkIfMultipleSKUFitsFromCriticalfitment(
            criticalFitment,
            productData?.fitmentTypeCode,
            productData?.productWheelType,
        )
            ? getVariantsForMultipleSKUfromFitment()
            : productData.options) as ProductOption[],
    }

    // prepares props for genMerch variant
    const genMerchVariantProps = {
        isStoreNameChangedSuccess: isStoreNameChangedSuccess,
        variantsProductSkuData: variantsProductSkuData,
        isColourSwatchesActive: isPrerenderOrNot() ? false : commonContentAvailable.featureFlag?.isColourSwatchesActive,
        isFirstVariantClicked: isFirstVariantClicked,
        isOnlyOneVariant: productData.options.length === MagicNumber.ONE,
        isSizeChartAvailable,
        selectedColorCode: selectedColorCode,
        setShowSizeChart: setShowSizeChart,
        showSizeChart: showSizeChart,
        sizeChartButtonTitle: commonContentAvailable.product?.sizeChartButtonTitle,
        variantOptions: productData.options,
    }
    const variantProps = !isAutomotive ? genMerchVariantProps : autoVariantProps

    return shouldRenderVariants ? (
        <div id="variants" className={`${buyBoxVariants} ${isAutomotive ? buyBoxAutoVariants : ''}`}>
            <VariantsWrapper
                isFirstVariantClicked={isFirstVariantClicked}
                selectedColorCode={selectedColorCode}
                outOfStockLabel={outOfStockLabel}
                inStockLabel={inStockLabel}
                a11yVariantsSelectedCount={a11yVariantsSelectedCount}
                isAddToCartClicked={isAddToCartOrWishlistClicked}
                isNoSkuFindStoreClicked={isNoSkuFindStoreClicked}
                checkVariantSelected={isVariantSelected}
                setSelectedFirstVariantValue={setSelectedFirstVariantValue}
                setSelectedSecondVariantValue={setSelectedSecondVariantValue}
                setSelectedThirdVariantValue={setSelectedThirdVariantValue}
                selectedSecondVariant={selectedSecondVariant}
                selectedThirdVariant={selectedThirdVariant}
                colorVariantLabel={commonContentAvailable.product?.productColorLabel}
                sizeVariantLabel={commonContentAvailable.product?.productSizeLabel}
                setSelectedCode={setSelectedCode}
                selectedProductCode={selectedProductCode}
                pCode={productData.code}
                setSelectedFirstVariantIdValue={setSelectedFirstVariantIdValue}
                setSelectedSecondVariantIdValue={setSelectedSecondVariantIdValue}
                setSelectedThirdVariantIdValue={setSelectedThirdVariantIdValue}
                selectedFirstVariantId={selectedFirstVariantId}
                selectedSecondVariantId={selectedSecondVariantId}
                selectedThirdVariantId={selectedThirdVariantId}
                setIsVariantSelected={setIsVariantSelected}
                selectedFirstVariant={selectedFirstVariant}
                selectedFirstVariantDisplayName={selectedFirstVariantDisplayName}
                isVariantSelected={isVariantSelected}
                variantDetails={variantDetails}
                variantWarningLabel={variantWarningLabel}
                a11yVariantSelected={a11yVariantSelected}
                a11yVariantUnSelected={a11yVariantUnSelected}
                viewMoreLabel={viewMoreLabel}
                viewLessLabel={viewLessLabel}
                setIsFirstVariantClicked={setIsFirstVariantClicked}
                isAutomotive={isAutomotive}
                checkOutOfStock={checkOutOfStock}
                enableHidingFirstVariantWhereAllSkusOOS={enableHidingFirstVariantWhereAllSkusOOS}
                checkIfAllVariantsSelected={checkIfAllVariantsSelected}
                enableOnlyAvailableOnline={enableOnlyAvailableOnline}
                availableOnlinePurchaseLabel={availableOnlinePurchaseLabel}
                onlyAvailableOnlineAtLabel={onlyAvailableOnlineAtLabel}
                preferredStoreDetails={preferredStoreDetails.displayName}
                limitedStockMessage={commonContentAvailable.product?.limitedStockMessage}
                isLimitedStock={isLimitedStock}
                enableCustomOrder={enableCustomOrder}
                variantDetailsOrder={variantDetailsOrder}
                noUrlUpdates={noUrlUpdates}
                setSelectedVariantByUserClick={setSelectedVariantByUserClick}
                {...variantProps}
            />
        </div>
    ) : null
}

Variants.displayName = 'Variants'

export default Variants
