import { BREAKPOINTS } from '../../config'
import { onElementAvailable } from '@nl/lib/src/utils/elementAvailable'

/**
 * slimpromocarousel - customize the component based on the screen size
 */
const SlimPromoCarousel = (): void => {
    const tiles = '.slimpromocarousel:has(.swiper-slide)'
    const slimPromoCarousel = '.slimpromocarousel'
    const element = document.querySelector(slimPromoCarousel)

    const displayRecommendation = () => {
        const screensize = document.documentElement.clientWidth
        const numberOfTiles = element?.querySelectorAll('.swiper-slide').length
        const recommendation = document.querySelector(`${slimPromoCarousel} > .recommendation`)
        const swiperWrapper = document.querySelector(`${slimPromoCarousel} > .swiper-wrapper`)
        const dots = document.querySelector(`${slimPromoCarousel} > .swiper-pagination`)
        const dotsStyle = dots && window.getComputedStyle(dots).getPropertyValue('display')

        // eslint-disable-next-line no-magic-numbers
        if (screensize < BREAKPOINTS.wideDesktopWidth && numberOfTiles && numberOfTiles < 5) {
            ;(recommendation as HTMLElement).style.display = 'none'
        } else {
            ;(recommendation as HTMLElement).style.display = 'block'
        }

        if (dotsStyle === 'none' && screensize >= BREAKPOINTS.desktopMinWidth) {
            ;(swiperWrapper as HTMLElement).style.marginBottom = '0px'
        } else {
            ;(swiperWrapper as HTMLElement).style.marginBottom = 'revert-layer'
        }
    }

    onElementAvailable(tiles, () => {
        displayRecommendation()
        window.addEventListener('resize', displayRecommendation)
    })
}

SlimPromoCarousel.displayName = 'SlimPromoCarousel'

export { SlimPromoCarousel }
