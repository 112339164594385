/**
 * AOABookingForm helper file
 */

import differenceBy from 'lodash/differenceBy'
import { DateTimeViewProps } from './AOABookingForm.type'
import { ProgressBarProps, StepItemType } from './AOAProgressBarContainer/AOAProgressBarContainer.type'
import {
    aoaInApp,
    aoaInAppMake,
    aoaInAppModel,
    aoaInAppYear,
    AOA_CONTACT_INFO_DATA,
    StepsQueue,
    REGULAR_FLOW_KEYS_IN_ORDER,
    ETIRE_FLOW_KEYS_IN_ORDER,
} from './AOABookingForm.constants'
import {
    AOA_ADDITIONAL_COMMENTS,
    AOA_PREVIOUSLY_SELECTED_SERVICES,
    AOA_SELECTED_SERVICES,
    AOA_SERVICE_CODE,
} from './AOAServiceView/AOAServiceView.constants'
import { AOA_DATE_TIME_DATA } from './AOADateTimeStep/AOADateTimeStep.constants'
import { AOADateTimeInfoState } from '../../redux/models/aoaBookingForm.interface'
import { AOASelectedService } from './AOAServiceView/AOAServiceView.type'
import store from '../../store'
import { clearAOADateTimeData, clearPreviouslySelectedServicesAction } from '../../redux/actionCreators'
import { clearAOADateSlotsAction } from '../../redux/actionCreators/aoaDateTimeStep.actionCreators'
import getAOAWorkflowType, { onlineOrderAppointmentsWorkflow } from '../../helpers/aoaWorkflow.helper'
import aoaLocalStorageService from '../../helpers/aoaLocalStorageService.helper'
import getQueryString from '../../utils/getQueryString'
import { VehicleState } from '../../redux/reducers/user.profile.reducer'

/**
 * Returns progress bar index mapped to given step
 * @param index
 * @returns
 */
export const getProgressBarIndex = (index: number): number => {
    const aoaWorkflowType = getAOAWorkflowType()

    return onlineOrderAppointmentsWorkflow.includes(aoaWorkflowType) ? index - StepsQueue.ETireServices : index
}
/**
 * Returns progress bar step mapped to given index
 * @param index
 * @returns
 */
export const getStepIndex = (index: number): number => {
    const aoaWorkflowType = getAOAWorkflowType()

    return onlineOrderAppointmentsWorkflow.includes(aoaWorkflowType) ? index + StepsQueue.ETireServices : index
}
/**
 * Generates step data desired for AOAProgressBarContainer component
 * @param progressBarProps - Props object received by booking form. It will be used to extract information and generate step data array
 * @param keys - stepItems array is received based on aoaWorkflowType
 * to extract information and generate step data array
 * @returns array of StepItemType
 */
export const formStepData = (
    progressBarProps: ProgressBarProps,
    keys: typeof REGULAR_FLOW_KEYS_IN_ORDER | typeof ETIRE_FLOW_KEYS_IN_ORDER,
): Array<StepItemType> => {
    const stepData: StepItemType[] = []
    for (const key of keys) {
        const stepConfig = progressBarProps[key]

        stepData.push({
            title: stepConfig?.stepTitle || '',
            icon: stepConfig?.stepIcon || '',
            completedStepLabel: stepConfig.completedStepLabel,
            currentStepLabel: stepConfig.currentStepLabel,
        })
    }

    return stepData
}

/**
 * Clears booking form data (contact info, selected services, selected date, time and drop off option info) from local storage.
 * @returns
 */
export const clearBookingFormDataFromLocalStorage = (): void => {
    // Clear contact info data
    aoaLocalStorageService.removeItem(AOA_CONTACT_INFO_DATA)

    // Clear selected services data
    aoaLocalStorageService.removeItem(AOA_SELECTED_SERVICES)

    // Clear selected date, time, drop off option data
    aoaLocalStorageService.removeItem(AOA_DATE_TIME_DATA)

    // TODA - Clear current step data

    // Clear Additional Comment from Services
    aoaLocalStorageService.removeItem(AOA_ADDITIONAL_COMMENTS)
}

/**
 * This method validates the selected date, time, dropOff option on DateTime
 * step view and returns appropriate error message.
 * @param dateTimeInfo - Object containing selected date, time and dropOffOption values
 * @param props - DateTime Step view props containing error messages info
 * @returns appropriate error message if dateTimeInfo is invalid
 */
export const validateDateTimeView = (dateTimeInfo: AOADateTimeInfoState | null, props: DateTimeViewProps): string => {
    if (!dateTimeInfo?.selectedTime && !dateTimeInfo?.selectedDropOffOption) {
        return props.defaultDateTimeErrorMessage
    } else if (!dateTimeInfo?.selectedTime) {
        return props.availableTimeErrorMessage
    } else {
        return ''
    }
}

/**
 * verify is there any change in service selection compared to previous selection
 * @param selectedServices
 * @param previouslySelectedServices
 * @returns
 */
export const isChangeInServiceSelection = (
    selectedServices: Array<AOASelectedService>,
    previouslySelectedServices: Array<AOASelectedService>,
): boolean => {
    // if previous and current selected services length is equal then verify the change in selection
    if (previouslySelectedServices.length === selectedServices.length) {
        return differenceBy(previouslySelectedServices, selectedServices, AOA_SERVICE_CODE).length > 0
    }

    // previous and current selected services length is not equal there must be change in selection
    return true
}

/**
 * Checks if the vehicle data is available
 * @param vehicle VehicleState
 * @returns check if vehicle data available
 */
export const isVehicleDataAvailable = (vehicle: VehicleState): boolean => {
    return !!vehicle?.defaultVehicle?.autoAttributes
}

/**
 * verify previouslySelectedServices available and clear dateTimeInfo if any change in service selection
 * @param previouslySelectedServices
 * @param selectedServices
 */
export const clearDateStepDataIfSelectionChanged = (
    previouslySelectedServices: Array<AOASelectedService> | undefined,
    selectedServices: Array<AOASelectedService>,
): void => {
    // previouslySelectedServices available compare with selectedServices and clear it from redux and LS
    if (previouslySelectedServices) {
        // If any change in services selection clear dateSlots & dateTimeInfo from redux and LS
        if (isChangeInServiceSelection(selectedServices, previouslySelectedServices)) {
            store.dispatch(clearAOADateTimeData())
            store.dispatch(clearAOADateSlotsAction())
            aoaLocalStorageService.removeItem(AOA_DATE_TIME_DATA)
        }
        store.dispatch(clearPreviouslySelectedServicesAction())
        aoaLocalStorageService.removeItem(AOA_PREVIOUSLY_SELECTED_SERVICES)
    }
}

/**
 * This function is used to return the search object
 * @returns
 */
const returnSearchString = (): string => window.location.search

/**
 * Returns inApp query param
 * @returns
 */
export const returnInAppQueryParam = (): boolean => {
    const searchString = returnSearchString()
    const inAppIdentifier = aoaInApp
    const inAppQueryParam = getQueryString(searchString, inAppIdentifier)
    return inAppQueryParam === 'true'
}

interface inAppVehicleInfoInterface {
    year: string
    make: string
    model: string
}

/**
 * Returns inApp vehicle info object
 * @returns
 */
export const returnInAppVehicleInfoObject = (): inAppVehicleInfoInterface => {
    const searchString = returnSearchString()
    const yearIdentifier = aoaInAppYear
    const makeIdentifier = aoaInAppMake
    const modelIdentifier = aoaInAppModel
    const year = getQueryString(searchString, yearIdentifier)
    const make = getQueryString(searchString, makeIdentifier)
    const model = getQueryString(searchString, modelIdentifier)
    return {
        year,
        make,
        model,
    }
}
