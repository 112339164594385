import axios, { AxiosPromise, AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse, ResponseType } from 'axios'
import { httpErrorInterceptor } from '../../src/redux/utils/interceptors/errorInterceptors/httpErrorInterceptor'
import GlobalPropsHelper from '../analytics/helpers/globalProps/globalProps.helper'
import { checkDataLength } from '../components/Accounts/Addresses/checkDataLength'
import { getEnvironment } from '../environments'
import { fetchInterceptorPartialResponse } from '../redux/actionCreators/fetchInterceptorError.actionCreators'
import { AddToWishListPayload } from '../redux/models/wishlist.interface'
import { httpResponseInterceptor } from '../redux/utils/interceptors/responseInterceptors/httpResponseInterceptor'
import appCacheService from '../utils/appCacheService'
import { isUrl } from '../utils/isUrl'
import {
    excludeEndpointFromRemovingGuid,
    excludeExternalApiSystem,
    excludeWithCredentials,
    exludeCartSFL,
} from './axios.constant'
import { HttpReqHeaders, InterceptorConfig, RequestBodyType } from './client.type'
import { setRequestHeadersToAllowedDomains } from '@nl/lib/src/utils/HttpClientUtils/axiosHttpClientUtils'
import { modifyHeadersConfigForBrowseOnlyMode } from '../utils/HttpClientUtils/axiosHttpClientUtils'

// eslint-disable-next-line no-warning-comments
// TODO: environment access should be avoid in
// eslint-disable-next-line no-warning-comments
// TODO: Http Client need improvement
const environment = getEnvironment()
const globalProps = new GlobalPropsHelper()

type CustomAxiosStatic = { defaults: { headers: { post: { 'Content-Type': string } } } }
;(axios as unknown as CustomAxiosStatic).defaults.headers.post['Content-Type'] = 'application/json'

export const axiosInstance = axios.create({})
/**
 * Function to intercept the api request and modify the config if its an authenticated call.
 * @param {AxiosRequestConfig} config
 * @return {AxiosPromise}
 */
const modifyAuthRequest = async (config: AxiosRequestConfig) => {
    try {
        // eslint-disable-next-line sonar/no-invalid-await
        const store = await import('../../src/store') // Doing dynamic import since if we import using import {} from "", the store wont be ready yet
        const { isAuthFlowExecuted, userProfileData } = store.default.getState().userProfile
        const url = new URL(config?.url as string)
        const searchParams = url?.searchParams // get search params as an object.
        const isAuth = isAuthFlowExecuted && checkDataLength(userProfileData) // check if user logged in or not.
        // exclude credentials for external system like canada post and so on
        if (
            isAuth &&
            !excludeExternalApiSystem.includes(url.origin) &&
            !excludeWithCredentials.includes(url.pathname)
        ) {
            config.withCredentials = true // if logged in, pass cred as true to pass the cookie.
            // If no search params skip the logic and directly return
            if (!!url.search) {
                // Except few apis which require guid, remove guid in all the other api's
                if (!excludeEndpointFromRemovingGuid.includes(url?.pathname) && !url?.search.includes(exludeCartSFL)) {
                    searchParams.delete('guid')
                    searchParams.delete('cartId')
                }
                const modifiedSearchParams = searchParams.toString()
                // eslint-disable-next-line sonarjs/no-nested-template-literals
                config.url = `${url?.origin}${url?.pathname}${!!modifiedSearchParams ? `?${modifiedSearchParams}` : ''}`
                return config
            }
        }
    } catch (error) {
        console.error(error)
    }
    return config
}

axiosInstance.interceptors.request.use(async config => {
    // eslint-disable-next-line sonar/no-invalid-await
    config = await modifyAuthRequest(config)
    const globalDataConfigs = globalProps.readDataConfig()
    const headers = config.headers as Record<string, unknown>
    const jwt = localStorage.getItem('gigya.JWT') as string
    const domainsConfig = globalProps.readAllowedDomainsWithHeadersConfig()
    if (!config.headers) config.headers = {}
    modifyHeadersConfigForBrowseOnlyMode(config, domainsConfig)
    setRequestHeadersToAllowedDomains(environment, globalDataConfigs, domainsConfig, config.url as string, jwt, headers)
    return config
})

export const paramSerializer = (baseEndPoint: string, paramObj?: RequestBodyType): string => {
    if (!paramObj) {
        return baseEndPoint
    }
    // eslint-disable-next-line no-warning-comments
    // TODO: need to use string literals
    const reducer = (accumulator: string, currVal: string, index: number): string =>
        `${accumulator}${index === 0 ? '?' : '&'}${currVal}=${paramObj[currVal] as string}`
    return Object.keys(paramObj).reduce(reducer, baseEndPoint)
}

export const apiGet = <T, ParamsType = RequestBodyType>(
    url: string,
    params?: ParamsType,
    headers?: HttpReqHeaders,
    withCredentials = false,
): AxiosPromise<T> => {
    const finalURL = paramSerializer(url, params as RequestBodyType)
    return axiosInstance.get<T>(finalURL, {
        headers: headers as unknown as AxiosRequestHeaders,
        withCredentials,
    }) as unknown as AxiosPromise<T>
}

export const apiPost = <T, RequestBody = RequestBodyType | string>(
    url: URL | string,
    requestBody?: RequestBody,
    headers?: HttpReqHeaders,
    withCredentials = false,
): AxiosPromise<T> => {
    return axiosInstance.post<T>(url.toString(), requestBody, {
        headers: headers as unknown as AxiosRequestHeaders,
        withCredentials: withCredentials,
    }) as unknown as AxiosPromise<T>
}

export const apiPostForFile = <T = RequestBodyType | string>(
    url: URL | string,
    requestBody: T,
    headers: HttpReqHeaders,
    responseType: ResponseType,
    withCredentials = false,
): AxiosPromise => {
    return axiosInstance.post(url.toString(), requestBody, {
        headers: headers as unknown as AxiosRequestHeaders,
        responseType,
        withCredentials: withCredentials,
    })
}

export const apiPut = <T = RequestBodyType | AddToWishListPayload>(
    url: string,
    requestBody?: T,
    headers?: HttpReqHeaders,
    withCredentials = false,
): AxiosPromise => {
    return axiosInstance.put(url, requestBody, {
        headers: headers as unknown as AxiosRequestHeaders,
        withCredentials: withCredentials,
    })
}

export const apiDelete = <T = RequestBodyType | string[]>(
    url: string,
    requestBody?: T,
    headers?: HttpReqHeaders,
    withCredentials = false,
): AxiosPromise => {
    return axiosInstance.delete(url, {
        data: requestBody,
        headers: headers as unknown as AxiosRequestHeaders,
        withCredentials,
    })
}

axiosInstance.interceptors.response.use(
    response => {
        return new Promise(resolve => {
            httpResponseInterceptor(response, resolve)
        })
    },
    (error: { config: { headers: Record<string, string> } }) => {
        return new Promise((resolve, reject) => {
            if (error?.config?.headers?.['X-skip-default-error-handling'] !== 'true') {
                httpErrorInterceptor(error, resolve, reject)
            } else {
                reject(error)
            }
        })
    },
)

export const apiPatch = <T = RequestBodyType>(
    url: string,
    requestBody?: T,
    headers?: HttpReqHeaders,
    withCredentials = false,
): AxiosPromise => {
    return axiosInstance.patch(url, requestBody, {
        headers: headers as unknown as AxiosRequestHeaders,
        withCredentials,
    })
}

/**
 * Method to update partial response in state
 * @param {string} url :  url of the request
 * @param {AxiosResponse} response : response of the failed request
 *
 */
const updatePartialResponse = (url: string, response: AxiosResponse) => {
    const data = { url: url, response: response }
    // eslint-disable-next-line no-warning-comments
    // TODO 20220527 only a temporary fix to get the merge with develop working
    /* eslint-disable @typescript-eslint/no-floating-promises */
    import('../store').then(storeObj => storeObj.default.dispatch(fetchInterceptorPartialResponse(data)))
}

export const customInterceptorCall = (interceptorConfig: InterceptorConfig): void => {
    const updatedToken = appCacheService.gigyaJWTToken.get()
    const { config, resolveFunc, rejectFunc } = interceptorConfig
    const oldHeaders = config.headers as Record<string, unknown>
    const data = config.data as Record<string, unknown>
    const { url, baseURL, method } = config
    const updatedHeaders = {
        ...oldHeaders,
        authorization: `Bearer ${updatedToken}`,
    }
    const validUrl = isUrl(url || '') ? `${url || ''}` : `${baseURL}${url || ''}`

    axios({
        method: method,
        url: validUrl,
        data: data,
        headers: updatedHeaders,
        withCredentials: true,
    })
        .then(response => {
            updatePartialResponse(validUrl, response)
            resolveFunc(response)
        })
        .catch(err => rejectFunc(err))
}

export default {
    apiGet,
    apiPost,
    apiPostForFile,
    apiPut,
    apiDelete,
    apiPatch,
    customInterceptorCall,
}
