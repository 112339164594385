import React from 'react'
import { PREFIX } from '../config'
import { rangeDash } from './Price.constant'
import { TotalRangePriceOrFromPriceProps } from './Price.types'
import { checkTotalPriceComponent, getTotalPriceMax, normalizePrice } from '../../helpers/Price.helper'
import WrapPriceByLabel from './WrapPriceByLabel'

const TotalRangePriceOrFromPrice: React.FC<TotalRangePriceOrFromPriceProps> = ({
    isMoreQuantityCartPage,
    priceValue,
    currentPrice,
    originalPrice,
    language,
    showDiscountedPrice,
    totalPrice,
    isAppliedPromoExist,
    isRangeView = false,
    fromLabel,
    toLabel,
    showToNow,
}) => {
    // Normalize all price inputs
    const normalizedPriceValue = normalizePrice(priceValue)
    const normalizedCurrentPrice = normalizePrice(currentPrice)
    const normalizedOriginalPrice = normalizePrice(originalPrice)
    const normalizedTotalPrice = normalizePrice(totalPrice)
    const showRange =
        isRangeView &&
        [normalizedPriceValue, normalizedCurrentPrice, normalizedOriginalPrice]
            .filter(item => item?.minPrice)
            .find(item => item.maxPrice)

    const totalPriceInstance = checkTotalPriceComponent(
        isMoreQuantityCartPage,
        normalizedPriceValue,
        normalizedCurrentPrice,
        normalizedOriginalPrice,
        language,
        showDiscountedPrice,
        normalizedTotalPrice,
        isAppliedPromoExist,
    )
    const maxTotalPriceInstance = getTotalPriceMax(
        isMoreQuantityCartPage,
        normalizedPriceValue,
        normalizedCurrentPrice,
        normalizedOriginalPrice,
        language,
        showDiscountedPrice,
        normalizedTotalPrice,
    )

    return (
        <>
            {
                // eslint-disable-next-line sonar/expression-complexity
                showRange && totalPriceInstance && maxTotalPriceInstance ? (
                    // eslint-disable-next-line sonar/no-nested-conditional
                    showToNow && fromLabel && toLabel ? (
                        <span className={`${PREFIX}-price__price-range`}>
                            <WrapPriceByLabel inputPrice={totalPriceInstance} label={fromLabel} language={language} />
                            <WrapPriceByLabel inputPrice={maxTotalPriceInstance} label={toLabel} language={language} />
                        </span>
                    ) : (
                        <span className={`${PREFIX}-price__price-range`}>
                            {totalPriceInstance}
                            <span className="range-dash">{rangeDash}</span>
                            {maxTotalPriceInstance}
                        </span>
                    )
                ) : (
                    totalPriceInstance
                )
            }
        </>
    )
}

TotalRangePriceOrFromPrice.displayName = 'TotalRangePriceOrFromPrice'

export default TotalRangePriceOrFromPrice
