import { ActionType, getType } from 'typesafe-actions'
import * as actions from '../actionCreators/security.actionCreators'
import { SecurityDataState } from '../models/security.interface'
import { Status } from '../../globalConstants'
import {
    successUpdatePassword,
    failUpdatePassword,
    successUpdateTfaPhoneNumber,
    failUpdateTfaPhoneNumber,
} from '../actionCreators/security.actionCreators'

type Action = ActionType<typeof actions>

export const initialState: SecurityDataState = {
    passwordUpdateStatus: '',
    passwordErrCode: '',
    tfaPhoneNumberUpdateStatus: '',
    tfaPhoneNumberErrCode: '',
}

export const securityReducer = (state: SecurityDataState = initialState, action: Action): SecurityDataState => {
    switch (action.type) {
        case getType(successUpdatePassword): {
            return {
                ...state,
                passwordUpdateStatus: Status.success,
            }
        }
        case getType(failUpdatePassword): {
            return {
                ...state,
                passwordUpdateStatus: Status.failed,
                passwordErrCode: action.payload,
            }
        }
        case getType(successUpdateTfaPhoneNumber): {
            return {
                ...state,
                tfaPhoneNumberUpdateStatus: Status.success,
            }
        }
        case getType(failUpdateTfaPhoneNumber): {
            return {
                ...state,
                tfaPhoneNumberUpdateStatus: Status.failed,
                tfaPhoneNumberErrCode: action.payload,
            }
        }
        default:
            return state
    }
}
