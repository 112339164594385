import { ActionType, getType } from 'typesafe-actions'

import * as actions from '../actionCreators/user.profile.actionCreators'
import { SignOutResponseError, UserProfileData, SoftRecallCTARequestPayload } from '../models/user.profile.interface'
import { CriticalFitment } from '@nl/lib'
import { Vehicle } from '@nl/lib/src/components/ProductGridView/ProductGrid.types'
import {
    userProfileSuccess,
    userProfileFailure,
    signOutSuccessAction,
    signOutErrorAction,
    resetUserProfileAction,
    profileUpdateSuccessAction,
    profileUpdateErrorAction,
    resetProfileUpdateAction,
    setSoftRecallCTAClickedAction,
    liteUserProfileCallSuccess,
    gigyaUpdateAction,
} from '../actionCreators/user.profile.actionCreators'
import { HttpRequestState } from '../../httpClient/client.type'
import { VehicleFitType } from '../../globalConstants'
import { EmptyActionType } from '../models/globalData.interface'

type Action = ActionType<typeof actions> | EmptyActionType
export interface UserProfileDataState {
    isUserDataFetched: boolean
    userProfileData: UserProfileData | null
    liteUserProfileData: UserProfileData | null
    isAuthFlowExecuted: boolean
    profileUpdate: boolean
    profilePending: boolean | null
    updateErrorData: SignOutResponseError | null
    signOutSuccess: boolean
    signOutErrorData: SignOutResponseError
    vehicle: VehicleState
    xhrInfo: {
        userProfile: string
    }
    location: string
    isSoftRecallCTAClicked: SoftRecallCTARequestPayload
    rehydrateUserProfile: boolean
    isGigyaActionsUpdated: boolean
    isLiteProfileCallCompleted: boolean
}

export interface VehicleState {
    vehicleId: Record<string, string>
    defaultVehicle?: Vehicle
    urlDefaultVehicle?: Vehicle
    vehicleFitType: VehicleFitType
    list: Vehicle[]
    isVehicleSyncedWithLSOnPageLoad: boolean
    criticalFitment?: CriticalFitment[]
    vehicleFitResponse?: []
    vehicleFormProps?: Record<string, unknown>
    isCriticalFitmentApiDone?: boolean
    isDefaultVehicleSet?: boolean
    isTireOrDefaultVehicleSet?: boolean
    lang?: string
    selectedVehicle: string
    selectedVehicleIndex: number
    withoutBodyAndOption: boolean | null
}

export const initialState: UserProfileDataState = {
    userProfileData: {} as unknown as UserProfileData,
    liteUserProfileData: {} as unknown as UserProfileData,
    isAuthFlowExecuted: false,
    isLiteProfileCallCompleted: false,
    profileUpdate: false,
    profilePending: null,
    updateErrorData: {} as unknown as SignOutResponseError,
    signOutSuccess: false,
    signOutErrorData: {} as unknown as SignOutResponseError,
    xhrInfo: {
        userProfile: HttpRequestState.notStarted,
    },
    location: '',
    vehicle: {
        vehicleId: {
            baseVehicleId: '',
            vehicleId: '',
        },
        vehicleFitType: VehicleFitType.Unknown,
        list: [],
        isVehicleSyncedWithLSOnPageLoad: false,
        isTireOrDefaultVehicleSet: false,
        selectedVehicle: '',
        selectedVehicleIndex: 0,
        withoutBodyAndOption: null,
    },
    isSoftRecallCTAClicked: {
        isClicked: false,
        clickedCardName: '',
    },
    rehydrateUserProfile: false,
    isUserDataFetched: false,
    isGigyaActionsUpdated: false,
}

// eslint-disable-next-line complexity
export const userProfileReducer = (
    state: UserProfileDataState = initialState,
    action: Action,
    // eslint-disable-next-line sonar/cyclomatic-complexity
): UserProfileDataState => {
    // eslint-disable-next-line sonarjs/max-switch-cases
    switch (action.type) {
        case getType(userProfileSuccess): {
            return {
                ...state,
                signOutSuccess: false,
                isUserDataFetched: true,
                userProfileData: action.payload,
                isAuthFlowExecuted: true,
                profilePending: false,
                isLiteProfileCallCompleted: false,
                xhrInfo: { userProfile: HttpRequestState.done },
            }
        }
        case getType(liteUserProfileCallSuccess): {
            return {
                ...state,
                liteUserProfileData: action.payload,
                isLiteProfileCallCompleted: true,
            }
        }
        case getType(userProfileFailure): {
            return {
                ...state,
                userProfileData: null,
                isAuthFlowExecuted: true,
                profilePending: false,
                xhrInfo: { userProfile: HttpRequestState.done },
            }
        }
        case getType(profileUpdateSuccessAction): {
            return {
                ...state,
                profileUpdate: action.payload.profileUpdated,
                profilePending: false,
                isUserDataFetched: false,
                userProfileData: { ...state.userProfileData, ...action.payload.profileData },
                updateErrorData: null,
            }
        }
        case getType(profileUpdateErrorAction): {
            return { ...state, profileUpdate: false, updateErrorData: action.payload, profilePending: false }
        }
        case getType(resetProfileUpdateAction): {
            return { ...state, profileUpdate: false, profilePending: false }
        }
        case getType(signOutSuccessAction): {
            return {
                ...state,
                signOutSuccess: true,
                location: action.payload,
                isAuthFlowExecuted: false,
                isLiteProfileCallCompleted: false,
            }
        }
        case getType(signOutErrorAction): {
            return {
                ...state,
                signOutSuccess: true,
                isLiteProfileCallCompleted: false,
                signOutErrorData: action.payload,
            }
        }
        case getType(resetUserProfileAction): {
            return { ...state, userProfileData: null, profilePending: false, isLiteProfileCallCompleted: false }
        }
        case getType(actions.addVehicleListAction): {
            return { ...state, vehicle: { ...state.vehicle, list: action.payload } }
        }
        case getType(actions.addVehicleAction): {
            return { ...state, vehicle: { ...state.vehicle, list: [action.payload, ...state.vehicle.list] } }
        }
        case getType(actions.setIsVehicleSyncedWithLSOnPageLoadAction): {
            return { ...state, vehicle: { ...state.vehicle, isVehicleSyncedWithLSOnPageLoad: action.payload } }
        }
        case getType(actions.showNoVehicleAction): {
            return { ...state, vehicle: { ...state.vehicle, list: [...action.payload] } }
        }
        case getType(actions.setDefaultVehicleAction): {
            return {
                ...state,
                vehicle: { ...state.vehicle, defaultVehicle: action.payload as Vehicle, isDefaultVehicleSet: true },
            }
        }
        case getType(actions.setIsTireOrDefaultVehiclePresentAction): {
            return {
                ...state,
                vehicle: { ...state.vehicle, isTireOrDefaultVehicleSet: action.payload },
            }
        }
        case getType(actions.removeVehicleListFromGarage): {
            return {
                ...state,
                vehicle: { ...state.vehicle, list: [], defaultVehicle: undefined, isDefaultVehicleSet: false },
            }
        }
        case getType(actions.setURLDefaultVehicleAction): {
            return {
                ...state,
                vehicle: {
                    ...state.vehicle,
                    urlDefaultVehicle: action.payload as Vehicle,
                },
            }
        }
        case getType(actions.clearURLDefaultVehicleAction): {
            return {
                ...state,
                vehicle: {
                    ...state.vehicle,
                    urlDefaultVehicle: undefined,
                },
            }
        }
        case getType(actions.setVehicleFitTypeAction): {
            return { ...state, vehicle: { ...state.vehicle, vehicleFitType: action.payload } }
        }
        case getType(actions.removeVehicleAction): {
            const updateList = state.vehicle.list.filter(vehicleObj => vehicleObj.id !== action.payload.id)
            return { ...state, vehicle: { ...state.vehicle, list: updateList } }
        }
        case getType(actions.setVehicleIdAction): {
            return { ...state, vehicle: { ...state.vehicle, vehicleId: action.payload } }
        }
        case getType(actions.setCriticalFitmentDetails): {
            return { ...state, vehicle: { ...state.vehicle, criticalFitment: action.payload } }
        }
        case getType(setSoftRecallCTAClickedAction): {
            return { ...state, isSoftRecallCTAClicked: action.payload }
        }
        case getType(actions.updateVehicleAction): {
            return { ...state, vehicle: { ...state.vehicle, list: action.payload } }
        }
        case getType(actions.resetCriticalFitmentDetails): {
            return { ...state, vehicle: { ...state.vehicle, criticalFitment: null as unknown as CriticalFitment[] } }
        }
        case getType(actions.triggeredCriticalFitmentAction): {
            return { ...state, vehicle: { ...state.vehicle, isCriticalFitmentApiDone: false } }
        }
        case getType(actions.criticalFitmentDoneAction): {
            return { ...state, vehicle: { ...state.vehicle, isCriticalFitmentApiDone: true } }
        }
        case getType(actions.newVehicleFormProps): {
            return { ...state, vehicle: { ...state.vehicle, vehicleFormProps: action.payload } }
        }
        case getType(actions.pendingProfileAction): {
            const profilePending = state.profilePending !== false
            return { ...state, profilePending }
        }
        case getType(actions.rehydrateUserProfileAction): {
            return { ...state, rehydrateUserProfile: action.payload }
        }
        case getType(actions.setSelectedVehicleAction): {
            return { ...state, vehicle: { ...state.vehicle, selectedVehicle: action.payload } }
        }
        case getType(actions.setSelectedVehicleIndexAction): {
            return { ...state, vehicle: { ...state.vehicle, selectedVehicleIndex: action.payload } }
        }
        case getType(actions.setVehicleWithoutBodyAndOption): {
            return { ...state, vehicle: { ...state.vehicle, withoutBodyAndOption: action.payload } }
        }
        case getType(gigyaUpdateAction): {
            return { ...state, isGigyaActionsUpdated: action.payload }
        }
        default:
            return state
    }
}
