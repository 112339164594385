import { OSType } from '../globalConstants/global.constant'

/**
 * function to return device OS
 * @return {string}
 */
export const getDeviceOS = (): string => {
    const userAgent = navigator.userAgent
    if (/android/i.test(userAgent)) {
        return OSType.android
    } else if (/iPad|iPhone|iPod/.test(userAgent)) {
        return OSType.ios
    } else if (/Mac/.test(userAgent)) {
        return OSType.macOS
    } else {
        return undefined
    }
}
