import { createStore, applyMiddleware, Middleware, Dispatch } from 'redux'
import thunk, { ThunkMiddleware } from 'redux-thunk'
import rootReducer, { RootState } from '../redux/reducers'
import { composeWithDevTools } from 'redux-devtools-extension'
// eslint-disable-next-line sonar/no-wildcard-import
import * as actionCreators from '../redux/actions'
import { prerenderMiddleware } from '../redux/customMiddlewares/prerenderMiddleware'
import { mergeProductsAndSponsoredAdsMiddleware } from '../redux/customMiddlewares/mergeSearchAdResultMiddleware'
import { mergeSponsoredFromSearchMiddleware } from '../redux/customMiddlewares/mergeSponsoredFromSearchMiddleware'

// Below modification is used to get the trace option in the redux dev tool.
const composeEnhancers = composeWithDevTools({
    actionCreators,
    trace: true,
    traceLimit: 25,
})

export const configureStore = (initialState?: any) =>
    createStore(
        rootReducer,
        initialState,
        composeEnhancers(
            applyMiddleware(
                thunk as ThunkMiddleware<RootState>,
                prerenderMiddleware as Middleware<unknown, unknown, Dispatch>,
                mergeProductsAndSponsoredAdsMiddleware as Middleware<unknown, unknown, Dispatch>,
                mergeSponsoredFromSearchMiddleware as Middleware<unknown, unknown, Dispatch>,
            ),
        ),
    )

export class dispatch {}

export default configureStore()
