import { currencyShortForm, currencyFormat } from '../globalConstants/global.constant'

/**
 * function to get selected price range for the pills
 * @param {number} minPrice
 * @param {number} maxPrice
 * @param {string} currentLocale
 * @return {string} selected price range pill
 */
export const getPriceRangePill = (minPrice: number, maxPrice: number, currentLocale: string): string => {
    let pricePills = ''
    if (minPrice > 0 && maxPrice > 0) {
        pricePills = `${getFormattedPrice(minPrice, currentLocale)}-${getFormattedPrice(maxPrice, currentLocale)}`
    } else if (maxPrice > 0) {
        pricePills = `${getFormattedPrice(0, currentLocale)}-${getFormattedPrice(maxPrice, currentLocale)}`
    } else if (minPrice > 0) {
        pricePills = `${getFormattedPrice(minPrice, currentLocale)}+`
        // eslint-disable-next-line sonar/different-types-comparison, sonarjs/elseif-without-else
    } else if (minPrice === null && maxPrice === null) {
        pricePills = 'Price Suppression'
    }
    return pricePills
}

/**
 * function to get formatted price based on locale
 * @param {number}price
 * @param {string} currentLocale
 * @return {string} formatted price
 */
export const getFormattedPrice = (price: number, currentLocale: string): string => {
    const language = `${currentLocale}-${currencyShortForm}`

    try {
        return new Intl.NumberFormat(language, {
            style: 'currency',
            currency: currencyFormat,
            useGrouping: false,
            minimumFractionDigits: 0,
            currencyDisplay: 'narrowSymbol',
        }).format(price)
    } catch (error) {
        return new Intl.NumberFormat(language, {
            style: 'currency',
            currency: currencyFormat,
            useGrouping: false,
            minimumFractionDigits: 0,
            currencyDisplay: 'symbol',
        }).format(price)
    }
}
