import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import Button from '../Button'
import { CompareProductsProps } from './CompareProductsFlyout.type'
import { PREFIX } from '../config'
import ProductCard from '../ProductReusableCard'
import { ProductCardType } from '../ProductGridView/ProductGrid.types'
import { getAccessibilityId } from '../../utils/getAccessibilityId'
import Icon from '../Icon'
import { replaceStrWithDynamicVal } from '../../utils'
import Heading from '../Heading'

/**
 * CompareProductsFlyout component
 * @param {CompareProductsProps} props
 * @returns {JSX.Element} returns CommonWarningToast component
 */
const CompareProductsFlyout: React.FC<CompareProductsProps> = ({ ...props }): JSX.Element => {
    const {
        compareProductList,
        returnPolicy,
        productCardClick,
        removeCompareProductCard,
        compareProductCardClearAllBtn,
        compareBtn,
        isHalfModalOpen,
        setIsHalfModalOpen,
        isFullModalOpen,
        setIsFullModalOpen,
        compareProductsL,
        clearAllLabel,
        compareL,
        selectItemsToCompareL,
        a11yStrikeOutPrice,
        a11yStrikeOutPriceRange,
        closeLabel,
        language,
    } = props

    const compareProductsFlyoutClass = `${PREFIX}-compare-products-flyout`
    const showFullModalClass = isFullModalOpen ? `${PREFIX}-compare-products-flyout__full-modal` : ''
    const showHalfOrFullModalClass = isHalfModalOpen
        ? `${PREFIX}-compare-products-flyout__half-modal`
        : showFullModalClass
    const showGridViewListView = isFullModalOpen ? `${PREFIX}-product__grid-view` : `${PREFIX}-product__list-view`

    useEffect(() => {
        const productDataCardList = document.body.getElementsByClassName(`${PREFIX}-product__list-view`)
        isHalfModalOpen &&
            productDataCardList.length &&
            productDataCardList[productDataCardList.length - 1]?.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center',
            })
    }, [compareProductList])

    /**
     * Return updated Comparison product list with empty card.
     * Adding empty object in array to represent empty card.
     * @returns {Record<string, unknown>[]} returns array of JSX.Element
     */
    const updateComparisonProductList = (): Record<string, unknown>[] => {
        const updatedProductList = [...compareProductList] as unknown as Record<string, unknown>[]
        // eslint-disable-next-line no-magic-numbers
        const numberOfEmptyCard = 4 - updatedProductList.length
        // eslint-disable-next-line no-magic-numbers
        if (numberOfEmptyCard < 4) {
            ;[...Array<number>(numberOfEmptyCard)].forEach(() => {
                updatedProductList.push({})
            })
        } else return compareProductList as unknown as Record<string, unknown>[]
        return updatedProductList
    }

    /**
     * render Compare and clear all button
     * @returns {JSX.Element} returns  JSX.Element
     */
    const renderCTA = (): JSX.Element => {
        return (
            <div className={`${compareProductsFlyoutClass}__btn-row`}>
                <Button
                    size="mini"
                    type="primary"
                    ariaLabel={compareL}
                    onClick={compareBtn}
                    modifierClass={`${compareProductsFlyoutClass}__btn-row-compare`}>
                    {compareL}
                </Button>
                <Button type="tertiary" onClick={compareProductCardClearAllBtn}>
                    {clearAllLabel}
                </Button>
            </div>
        )
    }

    /**
     * gets the ProductCarousel List
     * @param {ProductCardType} product
     * @param {number} index
     * @returns {JSX.Element} returns array of JSX.Element
     */
    const getProductCardsList = (product: ProductCardType, index: number): JSX.Element => {
        const {
            title: productTitle,
            brand,
            images,
            rating,
            ratingsCount,
            currentPrice,
            url,
            code,
            skus,
            isOnSale,
        } = product

        const productProps = {
            ratingsAndReview: true,
        }

        return (
            <div className={showGridViewListView}>
                <ProductCard
                    product={product}
                    url={url}
                    key={index}
                    productProps={productProps}
                    idx={index}
                    cardType="grid"
                    title={productTitle}
                    code={null}
                    brand={brand}
                    images={images}
                    rating={rating}
                    ratingsCount={ratingsCount || 0}
                    productCardClick={(event, productdata, idx) =>
                        productCardClick(event, productdata as unknown as ProductCardType, idx)
                    }
                    a11yStrikeOutPrice={a11yStrikeOutPrice}
                    a11yStrikeOutPriceRange={a11yStrikeOutPriceRange}
                    showRatingSection={true}
                    language={language}
                    currentPrice={currentPrice ? currentPrice : skus?.[0]?.currentPrice}
                    returnPolicy={returnPolicy}
                    enableSaveStory={false}
                    productDataId={code}
                    accessibilityId={getAccessibilityId(code, skus?.[0]?.code ? skus?.[0]?.code : code)}
                    boldBrand={true}
                    showSaleClearanceBadge={true}
                    swiperLazyLoading={true}
                    showCloseBtn={true}
                    removeCompareProductCard={removeCompareProductCard}
                    isOnSale={isOnSale}
                    closeLabel={closeLabel}
                />
            </div>
        )
    }

    /**
     * render empty card element
     * @param {number} index
     * @returns {JSX.Element} returns array of JSX.Element
     */
    const emptyCard = (index: number): JSX.Element => {
        return (
            <div className={`${compareProductsFlyoutClass}__empty-product-card`}>
                <div className={`${compareProductsFlyoutClass}__empty-product-card-text`}>
                    {replaceStrWithDynamicVal(selectItemsToCompareL, index + 1)}
                </div>
            </div>
        )
    }

    /**
     * render comparison half modal element
     * @returns {JSX.Element[]} returns array of JSX.Element
     */
    const renderHalfModal = (): JSX.Element[] => {
        // eslint-disable-next-line no-magic-numbers
        return [...Array<number>(4)].map((_el, index) => {
            if (Object.keys(updateComparisonProductList()[index]).length === 0) {
                return emptyCard(index)
            } else return getProductCardsList(compareProductList[index], index)
        })
    }

    return (
        <div
            className={`${compareProductsFlyoutClass} ${PREFIX}-column ${PREFIX}-sticky-comparison-bar 
            ${PREFIX}-sticky-comparison-bar--sticky ${showHalfOrFullModalClass}`}>
            <button
                className={`${compareProductsFlyoutClass}__comparison-btn`}
                onClick={() => {
                    setIsHalfModalOpen(!isHalfModalOpen)
                    setIsFullModalOpen(false)
                }}>
                <Heading variant={'h3'} componentClass={`${compareProductsFlyoutClass}__comparison-heading`}>
                    {`${compareProductsL} (${compareProductList?.length})`}
                </Heading>
                <div className={`${compareProductsFlyoutClass}__comparison-heading-icon`}>
                    {isHalfModalOpen || isFullModalOpen ? (
                        <Icon type="ct-chevron-down" size="lg" />
                    ) : (
                        <Icon type="ct-chevron-up" size="lg" />
                    )}
                </div>
            </button>
            <div className={`${compareProductsFlyoutClass}__product-card-row ${PREFIX}-full-width-container`}>
                <div className={`${compareProductsFlyoutClass}__product-card-row-data`}>{renderHalfModal()}</div>
                {!isFullModalOpen && renderCTA()}
            </div>
        </div>
    )
}

CompareProductsFlyout.propTypes = {
    compareProductList: PropTypes.array,
    returnPolicy: PropTypes.func,
    productCardClick: PropTypes.func,
    removeCompareProductCard: PropTypes.func,
    compareProductCardClearAllBtn: PropTypes.func,
    compareBtn: PropTypes.func,
    isHalfModalOpen: PropTypes.bool,
    setIsHalfModalOpen: PropTypes.func,
    isFullModalOpen: PropTypes.bool,
    setIsFullModalOpen: PropTypes.func,
    compareProductsL: PropTypes.string,
    clearAllLabel: PropTypes.string,
    compareL: PropTypes.string,
    selectItemsToCompareL: PropTypes.string,
    a11yStrikeOutPrice: PropTypes.string,
    a11yStrikeOutPriceRange: PropTypes.string,
    closeLabel: PropTypes.string,
    language: PropTypes.string,
}

export default CompareProductsFlyout
