import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'

import { PREFIX, BREAKPOINTS } from '../config'
import { SectionHeader, StickyTOCProps } from './StickyTOC.type'
import Dropdown from '../Dropdown/Dropdown'
import { useGlobalResizeEvent, useGlobalScrollEvent } from '../../utils/useWindowEvent'
import { isArrayNotEmpty, magicNumber } from '../../utils'
import usePrevious from '../../hooks/usePrevious.hook'

/**
 * StickyTOC component
 * @param {StickyTOCProps} props
 * @return {JSX.Element} returns Dropdown
 */
declare const window: Window & typeof globalThis
if (typeof window !== 'undefined') {
    window.analyticsLayer = window.analyticsLayer || []
}

/**
 * expandAccordion function to expand elements
 * @param {HTMLElement} element  Accordion Item for expand
 */
export const expandAccordion = (element: HTMLElement): void => {
    const buttonExpanded = element.getElementsByClassName(
        `${PREFIX}-accordion__button`,
    ) as HTMLCollectionOf<HTMLElement>
    if (buttonExpanded.length) {
        const className = `${buttonExpanded[0].className}`
        if (!className.includes('expanded')) {
            buttonExpanded[0].click()
        }
    }
}

const StickyTOC: React.FC<StickyTOCProps> = ({ ...props }) => {
    const {
        isStickyTOCEnabled,
        sectionHeaders,
        moreSectionsLabel,
        moreSectionsIcon,
        setStickyTOCInView,
        isTires,
        roadRatings,
        stickyBuyBarHeight,
        scrollTOCHandler,
        stickyTOCRef,
    } = props

    const [menuItems, setMenuItems] = useState([])
    const [selectItems, setSelectItems] = useState([])
    const [showSelect, setshowSelect] = useState(false)
    const [isScrollingDown, setIsScrollingDown] = useState(true) // true - down, false - up
    const [prevScrollPos, setPrevScrollPos] = useState(window.scrollY)
    const [activeTab, setActiveTab] = useState<string>(null)
    const [offsetElementIndex, setOffsetElementIndex] = useState<number>(null)
    const [currentWindowWidth, setCurrentWindowWidth] = useState(window.innerWidth)
    const [blockScrollEvent, setBlockScrollEvent] = useState(false)

    const stickyNav = document.getElementById(`${PREFIX}-nav`)
    const activeTabClass = `${PREFIX}-Scrollspy-nav-ul-link-label__active`
    const previousSectionHeaders = usePrevious(sectionHeaders)
    const previousActiveTab = usePrevious(activeTab)
    const previousOffsetElementIndex = usePrevious(offsetElementIndex)
    const previousMenuItems = usePrevious(menuItems)

    /**
     * @method getSelectItem : Method used for getting the selected item
     *  @param { sectionHeaders} item  Item in the navighation bar selected
     */

    const getSelectItem = useCallback(
        (item: SectionHeader): void => {
            setBlockScrollEvent(true)
            const itemId = item.id
            const el = document.getElementById(itemId)

            setActiveTab(itemId)

            if (el) {
                scrollTOCHandler(el)
                setBlockScrollEvent(false)
            }
        },
        [scrollTOCHandler],
    )

    /**
     * @method itemClicked  : Method called when an item is clicked
     *  @param { sectionHeaders} item : refers to clicked link Value
     *
     */

    const itemClicked = useCallback(
        (item: SectionHeader) => {
            window.analyticsLayer.push({
                event: 'interaction',
                eventParameters: {
                    action: 'click on internal link',
                    category: 'interaction',
                    label: item.label,
                    value: '',
                    location: 'sticky toc',
                },
            })
            getSelectItem(item)
        },
        [getSelectItem],
    )

    /**
     * @method createMenuItems  : Method used for adding items to the stickyTOC
     *  @param { string} id : refers to the id mapping with props
     */

    const createMenuItems = useCallback(
        (tabs: SectionHeader[], id: string) => {
            return tabs.map(link => {
                link.isActive = link.id === id
                return (
                    <li
                        key={link.id}
                        data-id={link.id}
                        className={`${PREFIX}-Scrollspy-nav-ul-link`}
                        data-testid={link.id}>
                        <button
                            className={`${PREFIX}-Scrollspy-nav-ul-link-label ${link.isActive ? activeTabClass : ''}`}
                            tabIndex={magicNumber.MINUS_ONE}
                            onClick={() => itemClicked(link)}>
                            {link.label}
                        </button>
                    </li>
                )
            })
        },
        [activeTabClass, itemClicked],
    )

    const [isSticky, setSticky] = useState(false)
    let buyBoxCompEL: HTMLElement | null

    /* Function to handle scroll event */
    const handleScroll = () => {
        buyBoxCompEL = document.getElementById(`${PREFIX}-buy-box`) || null
        const buyBoxCompELHeight = buyBoxCompEL?.clientHeight || 0
        const buyBoxCompELTop = buyBoxCompEL?.offsetTop || 0
        const buyBoxContentHeight = buyBoxCompELHeight + buyBoxCompELTop
        setStickyComponent(buyBoxContentHeight)

        onScrollEvent()
    }

    /* Function to set Sticky State */
    const setStickyComponent = (buyBoxContentHeight: number): void => {
        window.pageYOffset > buyBoxContentHeight ? setSticky(true) : setSticky(false)
    }

    /* useEffect to setStickyInView State */
    useEffect(() => {
        setStickyTOCInView(isSticky)
    }, [isSticky, setStickyTOCInView])

    useEffect(() => {
        if (
            // eslint-disable-next-line sonar/expression-complexity
            (sectionHeaders !== previousSectionHeaders ||
                activeTab !== previousActiveTab ||
                offsetElementIndex !== previousOffsetElementIndex) &&
            sectionHeaders &&
            sectionHeaders?.length
        ) {
            const filteredHeaders = sectionHeaders.filter(header => {
                return (
                    header.id !== 'road-rating' ||
                    (header.id === 'road-rating' && isTires && isArrayNotEmpty(roadRatings))
                )
            })
            const menuItemsEl = createMenuItems(filteredHeaders, activeTab || sectionHeaders[0].id)
            setMenuItems(menuItemsEl)
            if (offsetElementIndex) {
                setshowSelect(true)
                const dropDownItems = filteredHeaders.slice(offsetElementIndex).map(header => {
                    return {
                        id: header.id,
                        label: header.label,
                    }
                })
                const menuTabs = createMenuItems(
                    filteredHeaders.slice(0, offsetElementIndex),
                    activeTab || sectionHeaders[0].id,
                )
                setSelectItems(dropDownItems)
                setMenuItems(menuTabs)
            }
        }
    }, [
        sectionHeaders,
        activeTab,
        offsetElementIndex,
        previousSectionHeaders,
        previousActiveTab,
        previousOffsetElementIndex,
        createMenuItems,
        isTires,
        roadRatings,
    ])

    /**
     * @method resize  : used to watch the Viewport changes
     */
    const resize = useCallback(() => {
        if (currentWindowWidth < window.innerWidth && offsetElementIndex) {
            setshowSelect(false)
            setOffsetElementIndex(null)
        }

        if (window.innerWidth <= BREAKPOINTS.tabletMaxWidth) {
            setOffsetElementIndex(null)
            setshowSelect(false)
        }
        setCurrentWindowWidth(window.innerWidth)
    }, [currentWindowWidth, offsetElementIndex])

    useEffect(() => {
        menuItems !== previousMenuItems && resize()
    }, [menuItems, previousMenuItems, resize])

    const onScrollEvent = () => {
        const currPossY = window.scrollY
        prevScrollPos < currPossY ? setIsScrollingDown(true) : setIsScrollingDown(false)

        const findAllTabs = () => {
            const tabsId = sectionHeaders.map(el => el.id)
            return tabsId
                .map(id => document.getElementById(id))
                .filter(el => el)
                .map(el => {
                    return { id: el.id, position: el.offsetTop, distance: Math.abs(el.offsetTop - currPossY) }
                })
        }
        const tabs = findAllTabs()
        const tabsPositions = tabs.map(tab => tab.distance)
        const closerDistance = Math.min(...tabsPositions)
        const closerTab = tabs.filter(tab => tab.distance === closerDistance)

        setPrevScrollPos(currPossY)
        !blockScrollEvent && setActiveTab(closerTab[0].id)
    }

    useGlobalScrollEvent(handleScroll)

    useGlobalResizeEvent(resize)

    // Method used for enabling sticky behaviour
    const enableStickyTOC = useCallback((): void => {
        if (stickyNav) {
            stickyNav?.setAttribute('style', `top: ${stickyBuyBarHeight}px`)
        }
    }, [stickyBuyBarHeight, stickyNav])
    // Method used for disabling sticky behaviour
    const disableStickyTOC = useCallback((): void => {
        if (stickyNav) {
            stickyNav.setAttribute('style', 'top: -100%')
        }
    }, [stickyNav])

    useEffect(() => {
        if (isStickyTOCEnabled) {
            enableStickyTOC()
            setStickyTOCInView(isStickyTOCEnabled)
        } else {
            disableStickyTOC()
        }
    }, [isStickyTOCEnabled, isScrollingDown, stickyBuyBarHeight, enableStickyTOC, setStickyTOCInView, disableStickyTOC])

    return (
        <nav
            id={`${PREFIX}-nav`}
            ref={stickyTOCRef}
            data-testid="sticky-scroll"
            className={`${PREFIX}-Scrollspy-nav ${PREFIX}-full-width-container ${
                isSticky ? `${PREFIX}-Scrollspy-sticky` : `${PREFIX}-xs-none`
            }`}>
            <div className={`${PREFIX}-container`}>
                <ul id="teleporter" className={`${PREFIX}-Scrollspy-nav-ul`} aria-hidden="true">
                    {menuItems}
                </ul>
                {showSelect && (
                    <Dropdown
                        selectedItem={item => getSelectItem(item)}
                        dropdownTitle={moreSectionsLabel}
                        dropdownList={selectItems}
                        dropdownIcon={moreSectionsIcon}
                        resetDropdown={isStickyTOCEnabled}
                        size="mini"
                        dropdownId="receiver"
                    />
                )}
            </div>
        </nav>
    )
}

StickyTOC.propTypes = {
    isStickyTOCEnabled: PropTypes.bool,
    sectionHeaders: PropTypes.any,
    moreSectionsLabel: PropTypes.string,
    moreSectionsIcon: PropTypes.string,
    setStickyTOCInView: PropTypes.func.isRequired,
    isTires: PropTypes.bool,
    ratingsContainer: PropTypes.string,
    reviewsContainer: PropTypes.string,
    roadRatings: PropTypes.string,
}

export default StickyTOC
