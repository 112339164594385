import { dateDropdownListType } from './AgeOfMajority.types'
import {
    monthNumberAddValue,
    defaultLoweLimitRangeNumber,
    yearCount,
    ageIdCalculation,
} from './AgeOfMajority.constants'
import getLanguage from '../getLanguage'

let dropdownYearList: Array<dateDropdownListType> = []
let months: Array<dateDropdownListType> = []
const presentYear = new Date().getFullYear() as unknown as number
export const currentYear = presentYear - yearCount

export const yearDropdownCalculation = (): dateDropdownListType[] => {
    dropdownYearList = []
    for (let i = presentYear - defaultLoweLimitRangeNumber; i >= currentYear; i--) {
        dropdownYearList.push({ label: i.toString(), id: i.toString(), selected: false })
    }
    return dropdownYearList
}

export const internationalizedMonthList = (): dateDropdownListType[] => {
    months = []
    Array.from({ length: 12 }, (_, i) => {
        const internationalizedMonthName = new Date(presentYear, i).toLocaleDateString(getLanguage() || 'en', {
            month: 'long',
        })
        return months.push({
            label: internationalizedMonthName,
            id: (i + ageIdCalculation).toString(),
            selected: false,
            monthNumber: i + monthNumberAddValue,
        })
    })

    return months
}

export const daysInMonth = (selectedYear: string, selectedMonth: string): dateDropdownListType[] => {
    const daysList: dateDropdownListType[] = []
    let monthYear: Array<dateDropdownListType> = []
    monthYear = months.filter(value => value.label === selectedMonth)
    const numberofDays = new Date(
        Number(selectedYear ? selectedYear : currentYear),
        monthYear.length && monthYear[0].monthNumber ? monthYear[0].monthNumber : 0,
        0,
    ).getDate()

    for (let i = 1; i <= numberofDays; i++) {
        daysList.push({ label: i.toString(), id: i.toString(), selected: false })
    }

    return daysList
}

export const checkAgeValidation = (
    selectedYearOfBirth: string,
    selectedMonthOfBirth: string,
    selectedDayOfBirth: string,
    minAgeForProvince: number,
): boolean => {
    const userBirthMonth = months.filter(value => value.label === selectedMonthOfBirth)
    const userDob = new Date(
        `${Number(selectedYearOfBirth)}/${Number(userBirthMonth[0].monthNumber)}/${Number(selectedDayOfBirth)}`,
    )
    const ageOfMajorityDate = new Date(
        `${new Date().getFullYear() - minAgeForProvince}/${new Date().getMonth() + monthNumberAddValue}/${Number(
            new Date().getDate(),
        )}`,
    )
    return userDob.getTime() - ageOfMajorityDate.getTime() <= 0
}
