import { deviceTypes } from '../globalConstants/global.constant'

/**
 * function to return device type
 * @return {string}
 */

export const getDeviceType = (): string => {
    const userAgent = navigator.userAgent
    return /(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(userAgent)
        ? deviceTypes.TABLET
        : // eslint-disable-next-line sonar/no-nested-conditional
        /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
              userAgent,
          )
        ? deviceTypes.MOBILE
        : deviceTypes.DESKTOP
}
