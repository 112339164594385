import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import Button from '../Button'
import { BREAKPOINTS, PREFIX, previousElementName } from '../config'
import Icon from '../Icon'
import { useClickOutsideClose } from '../Tooltip/useClickOutside'
import { SkinnyBannerModalType } from './SkinnyBannerModal.type'
import ReactModal from '../ReactModal'
import { magicNumber, useGlobalResizeEvent } from '../../utils'

const SkinnyBannerModal: React.FC<SkinnyBannerModalType> = ({ ...props }): JSX.Element => {
    const {
        modalHeading,
        isModalOpen,
        modalDescription,
        modalSecondaryHeader,
        optionalCTALink,
        optionalCTALabel,
        modalLogo,
        usModalImgAltText,
        setModalOpen,
        usModalDesktopBgImg,
        usModalMobileBgImg,
        closeCtaAccessibilityLabel,
        ctaAccessibilityLabel,
        optionalCTAHandler,
        disclaimerMessage,
    } = props
    const previousElement: HTMLElement = document.querySelector(`button[${previousElementName}]`)
    const skinnyBannerRef = useRef<HTMLDivElement>(null)
    const componentName = `${PREFIX}-ultra-slim-banner__modal`
    const [isMobile, setIsMobile] = useState(window.innerWidth <= BREAKPOINTS.mobileMaxWidth)

    /**
     * function to check disclaimer text element and set height of the element based on resize of the screen
     * @return { void }
     */
    const setDisclaimerElementHeight = (): void => {
        const disclaimerTextElement: HTMLElement = document.getElementsByClassName(
            `${PREFIX}-ultra-slim-banner__modal-text-disclaimer`,
        )[0] as HTMLElement
        disclaimerTextElement?.setAttribute(
            'style',
            // eslint-disable-next-line no-unsafe-optional-chaining
            `height: ${window.innerHeight - disclaimerTextElement?.offsetTop - magicNumber.EIGHTY}px`,
        )
    }

    useEffect(() => {
        setDisclaimerElementHeight()
    }, [isModalOpen])

    const handleResize = () => {
        setIsMobile(window.innerWidth <= BREAKPOINTS.mobileMaxWidth)
        setDisclaimerElementHeight()
    }
    useGlobalResizeEvent(handleResize)

    /**
     * To handle close out side click of modal popup
     * @return {void }
     */
    const closeHandler = (): void => {
        setModalOpen(false) // Closing Modal UI
        if (previousElement) {
            previousElement.focus() // Highlight the initiated button
            previousElement.removeAttribute(previousElementName) // Removing it when user close it.
        }
    }

    useClickOutsideClose(skinnyBannerRef, closeHandler, isModalOpen, true)

    return (
        <ReactModal isOpen={isModalOpen} closeHandler={() => closeHandler()}>
            <div ref={skinnyBannerRef} className={`${componentName}`}>
                <section
                    style={{
                        backgroundImage: `url(${isMobile ? usModalMobileBgImg : usModalDesktopBgImg})`,
                    }}
                    className={`${componentName}-bg`}>
                    <Button
                        id="banner-modal-close-btn"
                        type="icon_button"
                        ariaLabel={closeCtaAccessibilityLabel}
                        onClick={() => closeHandler()}>
                        <Icon type="ct-close" size="lg" />
                    </Button>
                    <div className={`${componentName}-bg-text`}>
                        <img src={modalLogo} alt={usModalImgAltText} />
                        <div
                            className={`${componentName}-bg-text-heading`}
                            dangerouslySetInnerHTML={{ __html: modalHeading }}></div>
                    </div>
                </section>
                <section className={`${componentName}-text`}>
                    <div
                        className={`${componentName}-text-heading`}
                        dangerouslySetInnerHTML={{ __html: modalSecondaryHeader }}></div>
                    <div
                        className={`${componentName}-text-description`}
                        dangerouslySetInnerHTML={{ __html: modalDescription }}></div>
                    <div>
                        <div
                            className={`${componentName}-text-disclaimer`}
                            dangerouslySetInnerHTML={{ __html: disclaimerMessage }}
                        />
                    </div>
                    {optionalCTALabel && optionalCTALink && (
                        <Button
                            type="primary"
                            size="medium"
                            ariaLabel={ctaAccessibilityLabel}
                            onClick={optionalCTAHandler}>
                            <span className={`${componentName}-text-button`}>{optionalCTALabel}</span>
                        </Button>
                    )}
                </section>
            </div>
        </ReactModal>
    )
}

SkinnyBannerModal.propTypes = {
    modalHeading: PropTypes.string.isRequired,
    isModalOpen: PropTypes.bool.isRequired,
    modalDescription: PropTypes.string.isRequired,
    modalSecondaryHeader: PropTypes.string.isRequired,
    optionalCTALink: PropTypes.string,
    optionalCTALabel: PropTypes.string,
    modalLogo: PropTypes.string.isRequired,
    usModalImgAltText: PropTypes.string.isRequired,
    setModalOpen: PropTypes.func.isRequired,
    usModalMobileBgImg: PropTypes.string.isRequired,
    usModalDesktopBgImg: PropTypes.string.isRequired,
    closeCtaAccessibilityLabel: PropTypes.string.isRequired,
    ctaAccessibilityLabel: PropTypes.string,
    optionalCTAHandler: PropTypes.func,
}

export default SkinnyBannerModal
