import { useEffect, useMemo } from 'react'
import { fetchProductDataSuccessAction } from '../../redux/actionCreators'
import { fetchProductData } from '../../redux/actions'
import { productSelector } from '../../redux/selectors/product.selectors'
import { selectedPreferredStoreIdSelector } from '../../redux/selectors/storeDetails.selectors'
import appCacheService from '../../utils/appCacheService'
import extractPCodeFromUrl from '../../utils/PDP/extractPCodeFromUrl'
import { replaceEmptyImagesWithDefault } from '../../utils/replaceEmptyImagesWithDefault'
import { commonContentSelector } from '../../redux/selectors/commonContent.selectors'
import { checkDataLength } from '@nl/lib'
import { useAppDispatch, useAppSelector } from '../../hooks/react-redux.hook'

const useProduct = () => {
    const dispatch = useAppDispatch()
    const { commonContentAvailable } = useAppSelector(commonContentSelector)
    const selectedPreferredStoreId = useAppSelector(selectedPreferredStoreIdSelector)
    const { productData, productErrorResponse } = useAppSelector(productSelector)
    const { pCode, skuCode, staggeredSkus } = useMemo(() => extractPCodeFromUrl(), [])

    const preferredStoreIdValue = useMemo(() => {
        return selectedPreferredStoreId || appCacheService.preferredStoreId.get()
    }, [selectedPreferredStoreId])

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (checkDataLength(commonContentAvailable)) {
            const enableLightPdpLoading = commonContentAvailable?.featureFlag?.enableLightPdpLoading || false

            if (preferredStoreIdValue) {
                const { prefetchedProductPromise } = window

                if (!prefetchedProductPromise) {
                    dispatch(fetchProductData(pCode, preferredStoreIdValue, enableLightPdpLoading))
                } else {
                    prefetchedProductPromise
                        .then(response => {
                            if (response.ok) return response.json()
                            throw new Error('Prefetch request fall')
                        })
                        .then(prefetchedProduct => {
                            replaceEmptyImagesWithDefault([prefetchedProduct], 'images')
                            dispatch(fetchProductDataSuccessAction(prefetchedProduct))
                        })
                        .catch(() => {
                            dispatch(fetchProductData(pCode, preferredStoreIdValue, enableLightPdpLoading))
                        })
                }
            }
            return () => {
                window.prefetchedProductPromise = undefined
            }
        }
    }, [commonContentAvailable, dispatch, pCode, preferredStoreIdValue, skuCode, staggeredSkus])

    return { productData, productErrorResponse, skuCode, staggeredSkus }
}

export default useProduct
