import React from 'react'
import { PREFIX } from '../config'
import { rangeDash } from './Price.constant'
import { RangePriceOrFromPriceProps } from './Price.types'
import { checkInstanceOfPrice, checkInstanceOfMaxPrice } from '../ProductReusableCard/instanceCheckingUtil'
import WrapPriceByLabel from './WrapPriceByLabel'
import { normalizePrice } from '../../helpers/Price.helper'

const RangePriceOrFromPrice: React.FC<RangePriceOrFromPriceProps> = ({
    price,
    language,
    isRangeView = false,
    fromLabel,
    toLabel,
    showToNow,
}) => {
    const normalizedPrice = normalizePrice(price)
    const toShowRange = isRangeView && normalizedPrice?.maxPrice
    const priceInstance = checkInstanceOfPrice(normalizedPrice, language)
    const maxPriceInstance = checkInstanceOfMaxPrice(normalizedPrice, language)

    return (
        <>
            {
                // eslint-disable-next-line sonar/expression-complexity
                toShowRange ? (
                    // eslint-disable-next-line sonar/no-nested-conditional
                    showToNow && fromLabel && toLabel ? (
                        <span className={`${PREFIX}-price__price-range`}>
                            <WrapPriceByLabel inputPrice={priceInstance} label={fromLabel} language={language} />
                            <WrapPriceByLabel inputPrice={maxPriceInstance} label={toLabel} language={language} />
                        </span>
                    ) : (
                        <span className={`${PREFIX}-price__price-range`}>
                            {priceInstance}
                            <span className="range-dash" data-testId="price-range-dash">
                                {rangeDash}
                            </span>
                            {maxPriceInstance}
                        </span>
                    )
                ) : (
                    priceInstance
                )
            }
        </>
    )
}

RangePriceOrFromPrice.displayName = 'RangePriceOrFromPrice'

export default RangePriceOrFromPrice
