import { AxiosPromise } from 'axios'

import { MagicNumber } from '../../analytics/analytics.type'
import { checkDataLength } from '../../components/Accounts/Addresses/checkDataLength'
import { getEnvironment } from '../../environments'
import { AutoAttributes, Vehicle } from '@nl/lib'
import BaseService from '../base.service'
import { categoryLevelPrefix } from '../../components/Vehicles/Vehicles.constant'
import getPageType from '../../utils/getPageType'
import { pageTypes } from '../../config'
import extractPCodeFromUrl from '../../utils/PDP/extractPCodeFromUrl'
import { AddVehicleDataResponse } from '../../components/BuyBox/BuyBox.type'
import { lastCharacterRegExp } from '../../globalConstants'
import appCacheService from '../../utils/appCacheService'
import { TireType } from '../../redux/models/tireVehicle.interface'
import { getFullTireSize } from '../../components/Vehicles/Vehicle.helper'
import { searchQueryPair } from '../../components/Vehicles/hooks/useVehicleSelectionController.type'

const environment = getEnvironment()

/**
 * @class {AutomotiveVehicleService}
 * @extends BaseService
 */
export class AutomotiveVehicleService extends BaseService {
    locale = AutomotiveVehicleService.language

    /**
     * This function is used to check does it fit vehicle
     * @param {string} year
     * @param {string} make
     * @param {string} model
     * @param {string} body
     * @param {string} option
     * @param {string} catL1
     * @param {string} catL2
     * @param {string} catL3
     * @param {string} catL4
     * @param {string} sku
     * @return {AxiosPromise} axiosPromise
     */
    // eslint-disable-next-line max-params
    doesItFit(
        year: string,
        make: string,
        model: string,
        body: string,
        option: string,
        catL1: string,
        catL2: string,
        catL3: string,
        catL4: string,
        sku: string,
    ) {
        const url = this.prepareDoesItFirUrl(year, make, model, body, option, catL1, catL2, catL3, catL4, sku)
        return AutomotiveVehicleService.get(url)
    }

    /**
     * This function is used to prepare does it fit vehicle URL
     * @param {string} year
     * @param {string} make
     * @param {string} model
     * @param {string} body
     * @param {string} option
     * @param {string} catL1
     * @param {string} catL2
     * @param {string} catL3
     * @param {string} catL4
     * @param {string} sku
     * @return {URL} url
     */
    // eslint-disable-next-line max-params
    prepareDoesItFirUrl(
        year: string,
        make: string,
        model: string,
        body: string,
        option: string,
        catL1: string,
        catL2: string,
        catL3: string,
        catL4: string,
        sku: string,
    ): URL {
        return new URL(
            `${environment.AUTO_MOTIVE_URL}${environment.API_ENDPOINTS.doesItFit}?q=*;x1=auto.year;q1=${year};x2=auto.make;q2=${make};x3=auto.model;q3=${model};x4=auto.body;q4=${body};x5=auto.option;q5=${option};x6=c.cat-level-1;q6=${catL1};x7=c.cat-level-2;q7=${catL2};x8=c.cat-level-3;q8=${catL3};x9=c.cat-level-4;q9=${catL4};sku=${sku}`,
        )
    }

    /**
     *
     * @param {Vehicle} vehicle
     * @param {BreadcrumbLinkData[]} breadcrumbList
     * @param {string} pCode
     * @param {TireType} tireSize
     * @return {AxiosPromise}
     */
    criticalFitData(vehicle: Vehicle, breadcrumbList: string[], pCode: string, tireSize?: TireType): AxiosPromise {
        const fullTireSize = tireSize ? getFullTireSize(tireSize) : ''

        const vehicleQueryString: string[] = fullTireSize ? [`q=${fullTireSize}`] : ['q=*']
        let vehicleQueryStringIndex = MagicNumber.ONE

        // if tire size selected vehicle does not pass to the query
        if (!fullTireSize) {
            const knownVehicle = Object.keys(vehicle?.autoAttributes as AutoAttributes)
            knownVehicle?.forEach((attributeName: string) => {
                vehicleQueryString.push(
                    `x${vehicleQueryStringIndex}=auto.${attributeName};q${vehicleQueryStringIndex}=${encodeURIComponent(
                        (vehicle.autoAttributes as AutoAttributes)[attributeName as keyof AutoAttributes] as string,
                    )}`,
                )
                vehicleQueryStringIndex++
            })
        }
        breadcrumbList.slice(MagicNumber.ZERO, MagicNumber.THREE).forEach((category: string, index: number) => {
            vehicleQueryString.push(
                `x${vehicleQueryStringIndex}=c.${categoryLevelPrefix}-${
                    index + MagicNumber.ONE
                };q${vehicleQueryStringIndex}=${encodeURIComponent(category)}`,
            )
            vehicleQueryStringIndex++
        })
        const url = this.prepareCriticalDataFetchUrl(vehicleQueryString.join(';'), pCode)
        return AutomotiveVehicleService.get(url)
    }

    /**
     *
     * @param {string}vehicleQueryString
     * @param {string}pCode
     * @return {URL}
     */
    prepareCriticalDataFetchUrl(vehicleQueryString: string, pCode: string): URL {
        const storeId = appCacheService.preferredStoreId.get()
        const {
            API_BASE_URL,
            API_ENDPOINTS: { criticalFitment },
        } = environment

        return new URL(
            `${API_BASE_URL}${criticalFitment}?${vehicleQueryString};sku=${pCode};lang=${this.locale}&storeId=${storeId}`,
        )
    }

    /**
     * This function used to prepare tire sizes url
     * @return {URL} url
     */
    prepareTireSizesUrl() {
        return new URL(`${environment.API_BASE_URL}${environment.API_ENDPOINTS.tireSizes}`)
    }

    /**
     * This function used to prepare tire sizes width url
     * if pCode exists in the url adds it as a search parameter
     * @return {URL} url
     */
    prepareTireSizesWidthUrl() {
        const url = this.prepareTireSizesUrl()
        const pCode = extractPCodeFromUrl()?.pCode || ''
        const capitalizedLastChar = pCode?.charAt(pCode.length - 1)?.toUpperCase() || ''
        const pCodeParameter = pCode ? `x2=pCode;q2=${pCode.replace(lastCharacterRegExp, capitalizedLastChar)}` : ''

        url.search = `x1=auto.sizeRequest;q1=tire-width;${pCodeParameter}&lang=${this.locale}`
        return url
    }

    /**
     * This function used to prepare tire sizes aspect ratio url
     * if pCode exists in the url adds it as a search parameter
     * @param {string} tireWidth
     * @return {URL} url
     */
    prepareTireSizesAspectRatioUrl(tireWidth: string) {
        const url = this.prepareTireSizesUrl()
        const pCode = extractPCodeFromUrl()?.pCode || ''
        const capitalizedLastChar = pCode?.charAt(pCode.length - 1)?.toUpperCase() || ''
        const pCodeParameter = pCode ? `x3=pCode;q3=${pCode.replace(lastCharacterRegExp, capitalizedLastChar)}` : ''

        url.search = `x1=auto.tireWidth;q1=${tireWidth};x2=auto.sizeRequest;q2=tire-aspect;${pCodeParameter}&lang=${this.locale}`
        return url
    }

    /**
     * This function used to prepare tire sizes diameter url
     * if pCode exists in the url adds it as a search parameter
     * @param {string} tireWidth
     * @param {string} tireAspect
     * @param { boolean } fullSizeRequired
     * @return {URL} url
     */
    prepareTireSizesDiameterUrl(tireWidth: string, tireAspect: string, fullSizeRequired?: boolean) {
        const url = this.prepareTireSizesUrl()
        const pCode = extractPCodeFromUrl()?.pCode || ''
        const capitalizedLastChar = pCode?.charAt(pCode.length - 1)?.toUpperCase() || ''
        const pCodeParameter = pCode ? `x4=pCode;q4=${pCode.replace(lastCharacterRegExp, capitalizedLastChar)}` : ''

        url.search = `x1=auto.tireWidth;q1=${tireWidth};x2=auto.tireAspect;q2=${tireAspect};x3=auto.sizeRequest;q3=tire-diameter${
            (fullSizeRequired && ',tire-query-size') || ''
        };${pCodeParameter}&lang=${this.locale}`
        return url
    }

    /**
     * This function used to fetch tire sizes width
     * @return {AxiosPromise} axiosPromise
     */
    tireSizesWidth() {
        const url = this.prepareTireSizesWidthUrl()
        return AutomotiveVehicleService.get(url)
    }

    /**
     * This function used to fetch tire sizes aspect ratio
     * @param {string} tireWidth
     * @return {AxiosPromise} axiosPromise
     */
    tireSizesAspectRatio(tireWidth: string) {
        const url = this.prepareTireSizesAspectRatioUrl(tireWidth)
        return AutomotiveVehicleService.get(url)
    }

    /**
     * This function used to fetch tire sizes diameter
     * @param {string} tireWidth
     * @param {string} tireAspect
     * @param { boolean } fullSizeRequired
     * @return {AxiosPromise} axiosPromise
     */
    tireSizesDiameter(tireWidth: string, tireAspect: string, fullSizeRequired?: boolean) {
        const url = this.prepareTireSizesDiameterUrl(tireWidth, tireAspect, fullSizeRequired)
        return AutomotiveVehicleService.get(url)
    }

    /**
     * This function used to prepare add new vehicle url
     * @param {Object[]} known
     * @param {string[]} suggestionRequired
     * @param {string} sku
     * @param {Object[]} categories
     * @param {Object} astIdLevel
     * @param {Object[]} optionalParameters
     * @return {URL} url
     */
    prepareAddNewVehicleUrl(
        known: { x: string; q: string | boolean | number | undefined }[] | [],
        suggestionRequired: string[],
        sku: string,
        categories?: { x: string; q: string }[],
        astIdLevel?: { x: string; q: string },
        optionalParameters?: { x: string; q: string }[],
    ): URL {
        const url = new URL(`${environment.API_BASE_URL}${environment.API_ENDPOINTS.addVehicle}`)
        const queryString: string[] = ['q=*']
        let index = MagicNumber.ONE

        // knows attributes
        known.forEach(item => {
            queryString.push(`x${index}=auto.${item.x};q${index}=${encodeURIComponent(item.q ?? '')}`)
            index++
        })

        if (astIdLevel) {
            // ast-id-level attribute
            queryString.push(`x${index}=${astIdLevel.x};q${index}=${encodeURIComponent(astIdLevel.q)}`)
            index++
        } else {
            // categories attributes
            categories?.forEach((category: { x: string; q: string }) => {
                queryString.push(`x${index}=c.${category.x};q${index}=${encodeURIComponent(category.q)}`)
                index++
            })
        }

        // requested attributes
        suggestionRequired.forEach(item => {
            if (item) {
                queryString.push(`x${index}=auto.attrRequest;q${index}=${encodeURIComponent(item)}`)
                index++
            }
        })

        // append optional parameters
        optionalParameters?.forEach((item: { x: string; q: string }) => {
            queryString.push(`x${index}=${item.x};q${index}=${encodeURIComponent(item.q)}`)
            index++
        })

        // append sku
        if (getPageType() === pageTypes.productPage) {
            const pcode = sku?.split('.')[0]?.toUpperCase()
            queryString.push(`sku=${pcode}`)
        }
        url.search = queryString.join(';')
        return new URL(`${String(url)}&lang=${this.locale}`)
    }

    /**
     * This function used to prepare get vehicle image url
     * @param {Object[]} known
     * @return {URL} url
     */
    prepareVehicleImageUrl(known: { x: string; q: string }[] | []) {
        const url = new URL(`${environment.API_BASE_URL}${environment.API_ENDPOINTS.addVehicleImage}`)
        const queryString: string[] = []

        known.forEach(item => {
            queryString.push(`auto.${item.x}=${encodeURIComponent(item.q)}`)
        })
        url.search = queryString.join(';')
        return `${String(url)}&lang=${this.locale}`
    }

    /**
     * This function used to fetch add new vehicle
     * @param {Object[]} known
     * @param {string[]} suggestionRequired
     * @param {string} sku
     * @param {Object[]} categories
     * @param {Object[]} astIdLevel
     * @param {Object[]} optionalParameters
     * @return {AxiosPromise} axiosPromise
     */
    addNewVehicle(
        known: [{ x: string; q: string }] | [] | searchQueryPair[],
        suggestionRequired: string[],
        sku: string,
        categories?: { x: string; q: string }[],
        astIdLevel?: { x: string; q: string },
        optionalParameters?: { x: string; q: string }[],
    ) {
        const url = this.prepareAddNewVehicleUrl(
            known,
            suggestionRequired,
            sku,
            categories,
            astIdLevel,
            optionalParameters,
        )
        return AutomotiveVehicleService.get(url)
    }

    /**
     * This function used to fetch vehicle attributes
     * @param {string} url
     * @return {AxiosPromise} axiosPromise
     */
    getVehicleAttributes(url: URL | string): Promise<AddVehicleDataResponse> {
        return new Promise<AddVehicleDataResponse>((resolve, reject) => {
            AutomotiveVehicleService.get(url)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }

    /**
     * This function used to fetch vehicle image
     * @param {Vehicle} vehicle
     * @return {AxiosPromise} axiosPromise
     */
    fetchVehicleImage(vehicle: Vehicle) {
        const known = [{ x: 'baseVehicleId', q: vehicle.baseVehicleId as string }]
        if (
            checkDataLength(Object.keys(vehicle.autoAttributes as AutoAttributes)) &&
            Object.keys(vehicle.autoAttributes as AutoAttributes).length > MagicNumber.THREE
        ) {
            const additionalAttributes = { ...vehicle.autoAttributes }
            delete additionalAttributes.year
            delete additionalAttributes.make
            delete additionalAttributes.model
            Object.entries(additionalAttributes).forEach(attribute => {
                known.push({ x: attribute[0], q: attribute[MagicNumber.ONE] })
            })
        }

        const url = this.prepareVehicleImageUrl(known)
        return AutomotiveVehicleService.get(url)
    }

    /**
     * function to prepare dropdown url
     * @param {string} id
     * @param {string[]} value
     * @return {URL}
     */
    prepareVehicleDropdownUrl(id: string, value: string[]): URL {
        return new URL(
            `${environment.API_BASE_URL}${environment.API_ENDPOINTS.addVehicle}/${id}?id=${value.toString()}`,
        )
    }

    /**
     * funtction to fetch dropdown list values
     * @param {string} id
     * @param {string[]} values
     * @return {AxiosPromise}
     */
    fetchVehicleDropdownList(id: string, values: string[]): AxiosPromise {
        const url = this.prepareVehicleDropdownUrl(id, values)
        return AutomotiveVehicleService.get(url)
    }
}
const automotiveVehicleService = new AutomotiveVehicleService()

export { automotiveVehicleService }
export default AutomotiveVehicleService
