import { ActionType, getType } from 'typesafe-actions'

import * as actions from '../actionCreators'
import {
    CategoryResponseById,
    OptionalCategoryResponseById,
    CategoryResponseErrorDTO,
} from '../models/category.interface'

type Action = ActionType<typeof actions>

export const initialState: CategoryResponseById = {
    name: '',
    id: '',
    description: '',
    url: [],
    categories: [],
    subcategories: [],
    label: '',
    image: '',
    fitmentCategoryType: '',
    productWheelType: '',
    isFitmentRequired: undefined,
    breadcrumbList: [],
    defaultView: 'GRID',
    fitmentTypeCode: '',
    seoCopy: '',
    categoryErrorResponse: {} as CategoryResponseErrorDTO,
    categoryApiCompleted: false,
    seoHeaderTitle: '',
    categoryLoading: false,
    seoTitle: '',
    canonicalUrl: '',
    noFollow: false,
    noIndex: false,
}

export const getCategoryIdData = (state = initialState, action: Action): CategoryResponseById => {
    switch (action.type) {
        case getType(actions.fetchCategoryIdAction):
            return {
                ...state,
                name: action.payload.name,
                id: action.payload.id,
                url: action.payload.url,
                categories: action.payload.categories,
                subcategories: action.payload.subcategories,
                isFitmentRequired: action.payload.isFitmentRequired,
                breadcrumbList: action.payload.breadcrumbList,
                defaultView: action.payload.defaultView,
                seoCopy: action.payload.seoCopy,
                seoTitle: action.payload.seoTitle,
                canonicalUrl: action.payload.canonicalUrl,
                seoHeaderTitle: action.payload.seoHeaderTitle,
                ...getOptionalFields(action),
                categoryApiCompleted: true,
                categoryLoading: false,
            }
        case getType(actions.fetchCategoryIdRequestAction):
            return {
                ...state,
                categoryLoading: true,
            }
        case getType(actions.fetchCategoryIdErrorAction):
            return {
                ...state,
                categoryErrorResponse: action.payload,
                categoryApiCompleted: false,
                categoryLoading: false,
            }
        case getType(actions.updateCategoryIdData):
            return {
                ...state,
                ...action.payload,
            }
        default:
            return state
    }
}

/**
 * This function used to get optional fields of category
 * @param {Action} action
 * @return {OptionalCategoryResponseById | undefined} optionalCategoryResponseById
 */
// eslint-disable-next-line consistent-return
function getOptionalFields(action: Action): OptionalCategoryResponseById | undefined {
    if (action.type === getType(actions.fetchCategoryIdAction)) {
        return {
            ...(action.payload.description ? { description: action.payload.description } : {}),
            ...(action.payload.label ? { label: action.payload.label } : {}),
            ...(action.payload.image ? { image: action.payload.image } : {}),
            ...(action.payload.fitmentCategoryType ? { fitmentCategoryType: action.payload.fitmentCategoryType } : {}),
            ...(action.payload.productWheelType ? { productWheelType: action.payload.productWheelType } : {}),
            ...(action.payload.isFitmentRequired ? { isFitmentRequired: action.payload.isFitmentRequired } : {}),
            ...(action.payload.fitmentTypeCode ? { fitmentTypeCode: action.payload.fitmentTypeCode } : {}),
            ...(action.payload.seoDescription ? { seoDescription: action.payload.seoDescription } : {}),
        }
    }
}
