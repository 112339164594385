import { createSwiper } from '../../utils/createSwiper'
import { PREFIX } from '../../config'
import { BREAKPOINTS, libUtils } from '@nl/lib'
import { getComponentProps } from '../../utils/getComponentProps'
import { FeaturedListTallTilesProps, FeaturedListTallTilesSwiper } from './FeaturedListTallTiles.type'
import { SwiperOptions } from '../HeadbandBannerComponent/HeadbandBannerComponent.type'

/**
 * Initializes a featured list categories with the given element and parameters.
 * @param {HTMLElement} [element] - The HTML element to initialize the featured list categories on.
 */
const FeaturedListTallTiles = (element?: HTMLElement): void => {
    if (!element) return

    const { mobileMinWidth, tabletPortrait, tabletMaxWidth } = BREAKPOINTS

    const { nextSlidesButtonAreaLabel, prevSlidesButtonAreaLabel } = getComponentProps(
        element,
    ) as FeaturedListTallTilesProps

    const getNavigationButton = function (carouselSwiper: HTMLElement | null, showDirection: string) {
        return carouselSwiper?.getElementsByClassName(`${PREFIX}-carousel-swiper--${showDirection}`)[0]
    }

    const setPositionOfNavigationButton = function (button: Element | undefined, position: number) {
        button && button.setAttribute('style', `top: ${position}px;`)
    }

    /**
     * Will set 'style' attribute for navigation buttons with calculated value to place navigation buttons at the middle of image container edge.
     * If all images have the same height then custom position for navigation buttons will be added. Otherwise will be default value from css.
     */
    const changePositionOfNavigationButtons = function (swiper: FeaturedListTallTilesSwiper) {
        const images = Array.from(swiper?.el?.getElementsByTagName('img'))
        const firstImageHeight = images[0]?.clientHeight
        const imagesHaveTheSameHeight = images.every(image => image?.clientHeight === firstImageHeight)

        if (imagesHaveTheSameHeight) {
            const carouselSwiper = swiper?.el?.parentElement
            const nextButton = getNavigationButton(carouselSwiper, 'next')
            const prevButton = getNavigationButton(carouselSwiper, 'prev')
            // eslint-disable-next-line no-magic-numbers
            const halfImageHeight = firstImageHeight / 2

            setPositionOfNavigationButton(nextButton, halfImageHeight)
            setPositionOfNavigationButton(prevButton, halfImageHeight)
        }
    }

    const getLink = function (event: Event) {
        return (event.currentTarget as Element).getElementsByTagName('a')[0]
    }

    const followTheLink = function (event: Event) {
        event.preventDefault()
        const link = getLink(event)
        link && window.open(link.href, link.target)
    }

    /**
     * Handler to make the hole slide behave as a link by 'Enter' key press and follow the link inside slide
     */
    const handleEnterKeyPressed = function (event: KeyboardEvent) {
        event.key === 'Enter' && followTheLink(event)
    }

    /**
     * Handler to make the hole slide clickable and follow the link inside slide
     */
    const handleMouseClick = function (event: Event) {
        followTheLink(event)
    }

    const addEventHandlersForSlide = function (slide: Element) {
        slide.addEventListener('keydown', function (event: Event) {
            handleEnterKeyPressed(event as KeyboardEvent)
        })
        slide.addEventListener('click', function (event: Event) {
            handleMouseClick(event)
        })
    }

    const uniqueId = `swiper-${libUtils?.generateUniqueId() || ''}`

    const carouselSwiper = element?.getElementsByClassName(`${PREFIX}-carousel-swiper`)[0]

    if (carouselSwiper) {
        carouselSwiper.setAttribute('id', uniqueId)
    }

    const swiperElementClassName = `#${uniqueId} .swiper`
    const swiperCarouselNextClassName = `#${uniqueId} .${PREFIX}-carousel-swiper--next`
    const swiperCarouselPrevClassName = `#${uniqueId} .${PREFIX}-carousel-swiper--prev`
    const swiperCarouselPaginationClassName = `#${uniqueId} .swiper-pagination`
    const swiperOptions = {
        spaceBetween: 24,
        slidesPerView: 1.5,
        slidesPerGroup: 1,
        loop: false,
        keyboard: {
            enabled: true,
        },
        pagination: {
            el: swiperCarouselPaginationClassName,
            clickable: true,
        },
        navigation: {
            nextEl: swiperCarouselNextClassName,
            prevEl: swiperCarouselPrevClassName,
        },
        breakpoints: {
            [mobileMinWidth]: {
                slidesPerView: 2.5,
                slidesPerGroup: 2,
            },
            [tabletPortrait]: {
                slidesPerView: 3.5,
                slidesPerGroup: 3,
            },
            [(tabletMaxWidth as number) + 1]: {
                slidesPerView: 6,
                slidesPerGroup: 6,
            },
        },
        a11y: {
            slideRole: 'listitem',
            slideLabelMessage: '',
            nextSlideMessage: nextSlidesButtonAreaLabel || 'Next slide',
            prevSlideMessage: prevSlidesButtonAreaLabel || 'Previous slide',
        },
        on: {
            init: function (swiper: FeaturedListTallTilesSwiper) {
                swiper.slides.forEach(slide => addEventHandlersForSlide(slide))
            },
            afterInit: function (swiper: FeaturedListTallTilesSwiper) {
                changePositionOfNavigationButtons(swiper)
            },
            resize: function (swiper: FeaturedListTallTilesSwiper) {
                changePositionOfNavigationButtons(swiper)
            },
        },
    } as unknown as SwiperOptions

    createSwiper(swiperElementClassName, swiperOptions)
}

export { FeaturedListTallTiles }
