import React, { useRef } from 'react'
import { PREFIX } from '../../config'
import { BREAKPOINTS, Icon, magicNumber } from '@nl/lib'
import { useGlobalScrollEvent } from '@nl/lib/src/utils/useWindowEvent'
import { STICKY_TO_BOTTOM } from '../../globalConstants'
import { commonContentAvailableSelector } from '../../redux/selectors/commonContent.selectors'
import { useAppSelector } from '../../hooks/react-redux.hook'

export const calculateComponentPosition = (windowInnerWidth: number): number => {
    // Initialize bottom values
    const pageBottom = window.innerHeight
    const allTopPositions = [pageBottom]
    const keepShoppingWidgetExtraTop = 84
    const isMobile = windowInnerWidth <= BREAKPOINTS.mobileMaxWidth
    const isTablet = windowInnerWidth <= BREAKPOINTS.tabletMaxWidth

    if (isMobile) {
        // Get possible styky elements to the bottom of the page
        // Sticky Buybox for ex and also loyalty banner when NOT on top of the footer
        const stickyToBottomElems = document.querySelectorAll(`
            .${STICKY_TO_BOTTOM}, 
            .${PREFIX}-loyalty-offers_container.on-bottom .${PREFIX}-loyalty-offers_toggle-btn-wrapper,
            .${PREFIX}-keep-shopping-widget
        `)
        // Add stiky elements to position array to calculate the highest
        allTopPositions.push(...Array.from(stickyToBottomElems).map(e => e.getBoundingClientRect().top))
        // eslint-disable-next-line sonarjs/elseif-without-else
    } else if (isTablet) {
        const keepShoppingWidgetElems = document.querySelectorAll(`
            .${PREFIX}-keep-shopping-widget
        `)
        // Get the top positions of KeepShoppingWidgetElems and add an offset of 76 to have space of 32px between keep shopping widget and back to top
        allTopPositions.push(
            ...Array.from(keepShoppingWidgetElems).map(e => e.getBoundingClientRect().top + keepShoppingWidgetExtraTop),
        )
    }
    // get the highest element visible in page bottom
    const highestStickyElementTopPosition = Math.min(...allTopPositions)
    // calculate the distance from the page bottom and return it
    return Math.ceil(pageBottom - highestStickyElementTopPosition)
}

/**
 * BackToTop component
 * @param {ProductDataTypeObj} props
 * @returns {JSX.Element} returns BackToTop component
 */
const BackToTop = (): JSX.Element => {
    const { general, accessibility } = useAppSelector(commonContentAvailableSelector)

    let showBackToTopButton = false
    let oldScrollValue = 0
    let newScrollValue = 0

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
    }

    const handleScroll = (): void => {
        newScrollValue = window.pageYOffset
        if (oldScrollValue < newScrollValue || userIsAtTop()) {
            showBackToTopButton = false
            // eslint-disable-next-line sonarjs/elseif-without-else
        } else if (oldScrollValue > newScrollValue) {
            showBackToTopButton = true
        }
        oldScrollValue = newScrollValue
        setBackToTopButtonDisplay()
    }

    const userIsAtTop = (): boolean => {
        return window.scrollY <= 0
    }

    const setBackToTopButtonDisplay = (): void => {
        const bottom = calculateComponentPosition(window.innerWidth)
        if (btnRef && btnRef.current) {
            btnRef.current.style.bottom = `${bottom}px`
            btnRef.current.style.display = showBackToTopButton ? 'unset' : 'none'
        }
    }

    const btnRef = useRef<null | HTMLDivElement>(null)
    const compClass = `${PREFIX}-backToTop`

    useGlobalScrollEvent(handleScroll)

    return (
        <div className={compClass} ref={btnRef}>
            <div
                className={`${compClass}__button`}
                onClick={scrollToTop}
                onKeyPress={scrollToTop}
                aria-label={accessibility?.a11yBackToTopLabel}
                role="link"
                tabIndex={magicNumber.ZERO}>
                <div>
                    <Icon type="ct-chevron-up" size="md" />
                    <span className={`${compClass}__label`}>{general?.backToTopLabel}</span>
                </div>
            </div>
        </div>
    )
}

export default BackToTop
