/* eslint-disable max-len */
const title = "Helly Hansen Women's Seven J Shell Jacket"
const brand = { label: 'HELLY HANSEN', url: '' }
const productUrl1 =
    '/categories/women/womens-jackets-coats-vests/rain-jackets/product/helly-hansen-womens-seven-j-shell-jacket-331118955'
const productUrl2 =
    '/categories/women/womens-jackets-coats-vests/rain-jackets/short-rain-jackets/product/helly-hansen-womens-seven-j-shell-jacket-331118955'
const discount = '40% Off* - Discount Applied'
const priceLongMsg =
    'Select Spring Jackets & Pants 40% Off* Our in-store ticket price. Select styles. Excluding items already marked down. Selection and availability may vary by location. Order must be completed by July 30th, 2020 1:59 AM ET to qualify.'
const longDescription =
    '<p>You’ll keep warm and dry in this outdoor rainwear jacket with plenty of features to keep the wet weather at bay. Adjustable cuffs and a bottom hem cinch cord provide plenty of comfort. A full coverage hood and front storm flap will keep water away whether you’re out hiking on the trail or camping.</p>'
const productUrl3 =
    '/categories/women/womens-jackets-coats-vests/rain-jackets/shell-rain-jackets/short/product/helly-hansen-womens-seven-j-shell-jacket-331118955'
const featureBullets = [
    {
        description: 'Unlined, weatherproof rain jacket for year-round use</li><li>Relaxed Fit',
    },
    {
        description: 'Waterproof, breathable, fully seam-sealed DryVent™ 2.5L shell',
    },
    {
        description: '100% windproof fabric</li><li>Adjustable hood',
    },
    {
        description: 'Stormflap with Velcro® closure covers front zipper',
    },
    {
        description: 'Covered, secure-zip hand pockets',
    },
    {
        description: 'Pit-zip venting for added breathability',
    },
    {
        description: 'Hem cinch-cord',
    },
    {
        description: 'Stowable in hand pocket',
    },
]

const data = {
    type: 'product',
    title: title,
    code: '331118955',
    brand: brand,
    onTime: 1577854800000,
    offTime: 3155778000000,
    images: [
        {
            url: 'https://canadiantire.scene7.com/is/image/CanadianTire/1872500_1?&layer=comp&fit=constrain,1&wid=45&hei=45&fmt=jpg',
            isListingThumbnailImage: 'N',
            mediaType: 'LIFESTYLE_IMAGE',
            altText: "Altra Connor Men's Wheat CSA Boot, 8-in",
        },
    ],
    productPageUrls: [productUrl1, productUrl2, productUrl3],
    rating: 4.475,
    ratingsCount: 61,
    formattedTimeTestedPrice: null,
    wasPrice: null,
    formattedWasPrice: null,
    priceData: '$71.99',
    price: {
        formattedValue: '$189.99',
        value: 189.99,
    },
    salePrice: {
        minPrice: { formattedValue: '$129.99', value: 129.99 },
        maxPrice: { formattedValue: '$139.99', value: 139.99 },
    },
    discount: {
        minPrice: 29.99,
        maxPrice: 39.99,
    },
    timeTestedPrice: {
        formattedValue: '$189.99',
        value: 189.99,
    },
    priceShortMessage: discount,
    priceLongMessage: priceLongMsg,
    hiddenPrice: null,
    longDescription: longDescription,
    featureBullets: featureBullets,
    specifications: null,
    productVariants: null,
    clearancePrice: false,
    permanentMarkdown: false,
    fulfillment: {
        availability: {
            Corporate: {
                NextOrderCutOffDate: '2021-01-19',
                MaxETA: '2021-01-24',
                MinETA: '2021-01-19',
                MinOrderQty: 1,
                Quantity: 15,
            },
            quantity: 15,
            storeShelfLocation: 'Aisle 24',
        },
    },
    sellable: false,
    assemblyRequired: false,
    inStock: false,
    onlineOnly: false,
    packagePrice: false,
    onFloor: true,
    keepOnSiteWithZeroInventory: false,
    bopisOnly: false,
    promoMessages: [],
    restrictedPromoMessages: [],
    availability: ['ONLINE'],
    productSizeChart: null,
    availabilityDate: 1593662400000,
    mdmSpecifications: null,
    productPageShortUrls: [],
    pagePath: productUrl1,
    feeTitle: '$10 fee title',
    currentPrice: {
        formattedValue: '$60',
        value: 70,
    },
    originalPrice: {
        formattedValue: '$60',
        value: 70,
    },
    url: '/en/pdp/yardworks-kids-electronic-lawn-mower-toy-332236144.html',
}
const data1 = {
    ...data,
    badges: ['EXCLUSIVE', 'BESTSELLER', 'PROMO', 'CLEARANCE', 'SALE'],
}

export const ProductCardData = [data, data, data, data, data, data1]
export const plaCarouselSchemaId = ['cat1pla_rr', 'cat2pla_rr', 'pla_rr']
