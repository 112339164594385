import React, { useState, useEffect, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'

import { EstimateFeesModalProps } from './EstimateFeesModal.type'
import TextInput from '../TextInput'
import { PREFIX, previousElementName } from '../config'
import Button from '../Button'
import ReactModal from '../ReactModal'
import Icon from '../Icon'
import { useClickOutsideClose } from '../Tooltip/useClickOutside'
import { useGlobalResizeEvent } from '../../utils/useWindowEvent'
import { modalAccessibilityHandler } from '../../utils/modalAccessibility'
import usePrevious from '../../hooks/usePrevious.hook'

/**
 * EstimateFeesModal component
 * @param {EstimateFeesModalProps} props
 * @return {JSX.Element} returns ReactModal and TextInput
 */
const EstimateFeesModal: React.FC<EstimateFeesModalProps> = ({ ...props }): JSX.Element => {
    const {
        isOpen,
        modalTitle,
        modalDescription,
        modalDisclaimer,
        modalInputPlaceholder,
        modalEstimatedFees,
        modalEstimatedFeesPrice,
        modalContinueLabel,
        modalCancelLabel,
        a11ypcmCloseLabel,
        emptyPostalCodeMessage,
        onCloseEFAction,
        onContinueEFAction,
        onPostalCodeUpdateAction,
        pcmErrorMsg,
        selectedPostalCode,
        estimateFeesSuccessMsg,
        postalCodeRegex,
        validatePostalCode,
        showEstimatedFees,
        showEFSpinner,
        isExpressDeliveryEnabledForProduct,
        enableExpressDeliveryOption,
        estimateFeesDisclaimerExpressDelivery,
        modalEstimatedFeesPriceExpressDelivery,
        standardShippingFeesText,
        expressShippingFeesText,
        notAvailableShippingFeesText,
        isExpressDeliveryEligibleForPostalCode,
        setIsEstimateFeeBtnPressed,
    } = props
    const [postalCode, setPostalCode] = useState(selectedPostalCode)
    const [isUpdateBtnClicked, setIsUpdateBtnClicked] = useState(false)
    const [errorMsg, setErrorMsg] = useState(pcmErrorMsg)
    const [isSmallWindow, setIsSmallWindow] = useState(false)
    const componentClassName = `${PREFIX}-postal-code-modal`
    const smallWindowClass = `${componentClassName}__small-window`
    const minHeight = 650
    const isExpressDeliveryAvailable = enableExpressDeliveryOption && isExpressDeliveryEnabledForProduct
    const [previousPostalCode, setPreviousPostalCode] = useState(postalCode)

    // Get previously entered postal code value using custom hook
    const previousPostalCodeValue = usePrevious(previousPostalCode)

    /**
     * function to be called when postal code is entered and update btn is clicked
     * @returns {boolean|void} Returns a boolean value or undefined (void).
     */
    // eslint-disable-next-line consistent-return
    const onPCChangeClick = useCallback((): boolean | void => {
        setPreviousPostalCode(postalCode)

        // checks if the previous postal code equal to current postal code
        if (previousPostalCodeValue === postalCode) {
            return false
        }
        if (postalCode) {
            const validate = postalCodeRegex.test(postalCode)
            setErrorMsg(!validate ? emptyPostalCodeMessage : '')
            setIsUpdateBtnClicked(true)
            if (onContinueEFAction && validate) {
                setIsEstimateFeeBtnPressed(true)
                onContinueEFAction(postalCode)
            }
        } else {
            setIsEstimateFeeBtnPressed(false)
            setErrorMsg(emptyPostalCodeMessage)
        }
    }, [
        previousPostalCodeValue,
        postalCode,
        postalCodeRegex,
        emptyPostalCodeMessage,
        onContinueEFAction,
        setIsEstimateFeeBtnPressed,
    ])

    useEffect(() => {
        setPostalCode(postalCode)
        setIsUpdateBtnClicked(false)
    }, [postalCode])

    useEffect(() => {
        setPostalCode(selectedPostalCode)
        !selectedPostalCode && setErrorMsg('')
    }, [isOpen, selectedPostalCode])

    useEffect(() => {
        const isOpenAndNoErrors = isOpen && !pcmErrorMsg
        if (isOpenAndNoErrors && selectedPostalCode && postalCode && !showEstimatedFees) {
            onPCChangeClick()
        }
    }, [isOpen, selectedPostalCode, postalCode, pcmErrorMsg, showEstimatedFees, onPCChangeClick])

    useEffect(() => {
        setErrorMsg(pcmErrorMsg)
        // eslint-disable-next-line no-warning-comments
        // TODO: This show spinner false may be removed in the future based on error condition
    }, [pcmErrorMsg])

    const closeHandler = () => {
        modalAccessibilityHandler({ modalOpen: false, modalClassName: `${PREFIX}-react-modal` })
        onCloseEFAction()
        setIsEstimateFeeBtnPressed(false)
        setPreviousPostalCode('')
        onPostalCodeUpdateAction()
        const previousElement: HTMLElement = document.querySelector(`button[${previousElementName}]`)
        if (previousElement) {
            previousElement.focus() // Highlight the initiated button
            previousElement.removeAttribute(previousElementName) // Removing it when user close it.
        }
    }

    /**
     * function to check whether the device is tablet/mobile/desktop
     */
    const handleResize = (): void => {
        setIsSmallWindow(window.innerHeight <= minHeight)
    }

    useGlobalResizeEvent(handleResize)

    const modalRef = useRef(null)
    useClickOutsideClose(modalRef, closeHandler, isOpen, true)

    /**
     * function to return estimate shipping fees price and success text
     * @returns {JSX.Element} returns estimate shipping fees price and success text
     */
    const renderEstimateFees = (): JSX.Element => {
        if (postalCode !== '' && isUpdateBtnClicked) {
            return (
                <>
                    {estimateFeesSuccessMsg && (
                        <div className={`${componentClassName}__success`} role="alert">
                            <Icon type="ct-checkmark" size="sm" />
                            <span className={`${componentClassName}__success-text`}>{estimateFeesSuccessMsg}</span>
                        </div>
                    )}
                    {modalEstimatedFees && modalEstimatedFeesPrice && (
                        <div className={`${componentClassName}__price`}>
                            <span>{modalEstimatedFees}:</span>
                            <span>{modalEstimatedFeesPrice}</span>
                        </div>
                    )}
                </>
            )
        }
        return null
    }

    /**
     * function to return estimate shipping fees price and success text for express delivery
     * @return {JSX.Element} returns estimate shipping fees price and success text
     */
    const renderEstimateFeesExpressDelivery = (): JSX.Element => {
        if (postalCode !== '' && isUpdateBtnClicked) {
            return (
                <>
                    {estimateFeesSuccessMsg && (
                        <div className={`${componentClassName}__success`} role="alert">
                            <Icon type="ct-checkmark" size="sm" />
                            <span className={`${componentClassName}__success-text`}>{estimateFeesSuccessMsg}</span>
                        </div>
                    )}
                    <div className={`${componentClassName}__price-info`}>
                        {standardShippingFeesText && modalEstimatedFeesPrice && (
                            <div className={`${componentClassName}__price--standard ${PREFIX}-xs-flex`}>
                                <span>{standardShippingFeesText}:</span>
                                <span>{modalEstimatedFeesPrice}</span>
                            </div>
                        )}
                        {expressShippingFeesText && (
                            <div className={`${componentClassName}__price--express ${PREFIX}-xs-flex`}>
                                <span>{expressShippingFeesText}:</span>
                                <span>
                                    {isExpressDeliveryEligibleForPostalCode
                                        ? modalEstimatedFeesPriceExpressDelivery
                                        : notAvailableShippingFeesText}
                                </span>
                            </div>
                        )}
                    </div>
                </>
            )
        }
        return null
    }
    const isMobile = navigator.userAgent.toLowerCase().includes('mobi')

    return (
        <ReactModal closeHandler={closeHandler} isOpen={isOpen} isHeightFix={true}>
            <div role="dialog" aria-label={modalTitle} className={`${componentClassName}`} ref={modalRef}>
                <div className={`${componentClassName}__close-container`}>
                    <button
                        className={`${componentClassName}__close-btn`}
                        onClick={closeHandler}
                        aria-label={a11ypcmCloseLabel}>
                        <Icon type="ct-close" size="lg"></Icon>
                    </button>
                </div>
                <div className={`${componentClassName}__content`}>
                    <h2 className={`${componentClassName}__title`}>{modalTitle}</h2>
                    <div className={`${componentClassName}__description`}>
                        <p>{modalDescription}</p>
                    </div>
                    <TextInput
                        id="postal-code-modal"
                        label={modalInputPlaceholder}
                        value={postalCode}
                        error={errorMsg}
                        success={showEstimatedFees}
                        maxLength={7}
                        onChange={(val: string) => setPostalCode(validatePostalCode(val))}
                        size="default"
                    />
                    {isExpressDeliveryAvailable
                        ? showEstimatedFees && renderEstimateFeesExpressDelivery()
                        : showEstimatedFees && renderEstimateFees()}
                    <div className={`${componentClassName}__footer-buttons ${isSmallWindow ? smallWindowClass : ''}`}>
                        <div
                            className={`${componentClassName}__disclaimer`}
                            data-testid="tooltip-text"
                            dangerouslySetInnerHTML={{
                                __html: isExpressDeliveryAvailable
                                    ? estimateFeesDisclaimerExpressDelivery
                                    : modalDisclaimer,
                            }}></div>
                        <div
                            className={`${componentClassName}__buttons ${
                                isMobile ? `${componentClassName}__footer-buttons-mobile-fixed` : ``
                            }`}>
                            <Button
                                type="secondary"
                                size="small"
                                onClick={closeHandler}
                                label={modalCancelLabel}></Button>
                            <Button
                                modifierClass={`${componentClassName}__update-btn`}
                                type="primary"
                                size="small"
                                onClick={onPCChangeClick}
                                label={modalContinueLabel}
                                showSpinner={showEFSpinner}></Button>
                        </div>
                    </div>
                </div>
            </div>
        </ReactModal>
    )
}

EstimateFeesModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    modalTitle: PropTypes.string,
    modalDescription: PropTypes.string,
    modalDisclaimer: PropTypes.string,
    modalInputPlaceholder: PropTypes.string,
    modalEstimatedFees: PropTypes.string,
    modalEstimatedFeesPrice: PropTypes.string,
    modalContinueLabel: PropTypes.string,
    modalCancelLabel: PropTypes.string,
    a11ypcmCloseLabel: PropTypes.string,
    emptyPostalCodeMessage: PropTypes.string,
    pcmErrorMsg: PropTypes.string,
    selectedPostalCode: PropTypes.string,
    estimateFeesSuccessMsg: PropTypes.string,
    onCloseEFAction: PropTypes.func,
    onContinueEFAction: PropTypes.func,
    onPostalCodeUpdateAction: PropTypes.func,
    postalCodeRegex: PropTypes.instanceOf(RegExp).isRequired,
    validatePostalCode: PropTypes.func,
    showEstimatedFees: PropTypes.bool,
    showEFSpinner: PropTypes.bool,
}

export default EstimateFeesModal
