import React, { useCallback, useEffect } from 'react'

import { FORWARD_SLASH, checkNotNullAndUndefined, isArrayNotEmpty, libUtils, updateUrlHistory } from '@nl/lib'
import { fetchDataUsingCategoryId } from '../../redux/actions'
import { MagicNumber } from '../../analytics/analytics.type'
import { seoHoc } from '../../Seo/components/Hoc/Seo.hoc'
import getPageType from '../../utils/getPageType'
import { checkDataLength } from '../Accounts/Addresses/checkDataLength'
import { CategoryUrlType } from '../../redux/models/category.interface'
import { categoryIdDataSelector } from '../../redux/selectors/categoryIdData.selectors'
import { categoryURLIdentifier } from '../../config'
import { categoryUrlParserForPreselectedVehicle } from '../../utils/PLP/categoryUrlParserForPreselectedVehicle'
import { SeoHelper } from '../../Seo/helpers/Seo.helper'
import { httpStatusCodes } from '../../globalConstants'
import { secondaryNavPropsSelector } from '../../redux/selectors/headerProps.selectors'
import { IGeneral } from '../../redux/models/commonContent.interface'
import { commonContentAvailableSelector, commonContentSelector } from '../../redux/selectors/commonContent.selectors'
import usePLACarouselTracker from '../../hooks/usePLACarouselTracker.hook'
import { useAppDispatch, useAppSelector } from '../../hooks/react-redux.hook'

const CategoryInit: React.FC = () => {
    const categoryIdData = useAppSelector(categoryIdDataSelector)
    const secondaryNavProps = useAppSelector(secondaryNavPropsSelector)
    const { enablePrerenderMetaTagClp } = secondaryNavProps || {}
    const dispatch = useAppDispatch()
    const commonContent = useAppSelector(commonContentSelector)
    const commonContentAvailable = useAppSelector(commonContentAvailableSelector)

    const { general = {} as IGeneral } = commonContentAvailable
    const { isCommonContentAvailableInit } = commonContent

    const { shopAllLabel } = general

    /**
     * Function to check for current path and not update the url history if the url is shop-all
     * @param {string | undefined} url
     * @param {string} currentPath
     */
    const checkShopAllAndUpdateUrlHistory = useCallback(
        (url: string | undefined, currentPath: string) => {
            if (isCommonContentAvailableInit) {
                !currentPath.includes(`${FORWARD_SLASH}${shopAllLabel ?? ''}${FORWARD_SLASH}`) && updateUrlHistory(url)
            }
        },
        [shopAllLabel, isCommonContentAvailableInit],
    )

    usePLACarouselTracker()

    /**
     * use Effect used to process init operation once when Redux provides dispatch as a tool
     * During initial operation:
     * - url parsed to extract category id and vehicle preselection attribute if they exist
     * - propagate the data from url by dispatching to REDUX
     */
    useEffect(() => {
        const pathName = window.location.pathname
        const isCategoryURL = pathName.includes(`/${categoryURLIdentifier}/`)
        const { categoryId } = categoryUrlParserForPreselectedVehicle(pathName)

        if (isCategoryURL) {
            dispatch(fetchDataUsingCategoryId(categoryId))
        }

        return () => {
            window.prefetchedCategoryPromise = null
        }
    }, [dispatch])

    /**
     * As soon as category data will be ready process redirect if the dynamic url is not the same as API provides
     * Until API has been updated, this POC will prevent re-direction if URL contains automotive attributes and process them accordingly
     */
    useEffect(() => {
        if (checkDataLength(categoryIdData)) {
            if (categoryIdData?.categoryErrorResponse?.status === MagicNumber.FOURHUNDREDFOUR) {
                window.location.href = encodeURI(window.ODP?.globalLinks.pageNotFoundPath)
            }
            const { url } = categoryIdData
            if (isArrayNotEmpty(url) && !checkNotNullAndUndefined(enablePrerenderMetaTagClp)) {
                const language = libUtils.getLanguage()
                const currentPath = window.location.pathname
                const linkData = url.find((link: CategoryUrlType) => {
                    return link.hreflang === language
                })

                const { vehicleParameters } = categoryUrlParserForPreselectedVehicle(currentPath)

                if (!checkDataLength(vehicleParameters) && checkDataLength(linkData) && linkData?.url !== currentPath) {
                    checkShopAllAndUpdateUrlHistory(linkData?.url, currentPath)
                    const prerenderHeaderURL = window.location.origin + String(linkData?.url)
                    if (enablePrerenderMetaTagClp) {
                        SeoHelper.createMetaTag('name', 'prerender-status-code', String(httpStatusCodes.redirectCode))
                        SeoHelper.createMetaTag('name', 'prerender-header', `Location: ${prerenderHeaderURL}`)
                    }
                }
            }
        }
    }, [categoryIdData, enablePrerenderMetaTagClp, checkShopAllAndUpdateUrlHistory])

    return null
}

export default seoHoc(CategoryInit, getPageType())
