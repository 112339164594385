import { AnyAction, Dispatch } from 'redux'

import {
    fetchProductDataSuccessAction,
    fetchProductDataBySkuAction,
    fetchProductDataErrorAction,
    setSelectedSizeProductCodeAction,
    setStickyBuyboxCTAClickedAction,
    toggleAddToCartCTAAction,
    setIfAllVariantsSelectedAction,
    setFBTProductCodeAction,
    setBuyBoxPropsAction,
    setIsOutOfStockAction,
    setQuantitySelectedAction,
    setSelectedImageUrlAction,
    resetProductSKUDataAction,
    setRearQuantitySelectedAction,
    setFrontQuantitySelectedAction,
    fetchFBTProductDataBySkuAction,
    fetchVariantsProductDataBySkuAction,
    fetchProductDataBySkuErrorAction,
    fetchFBTProductDataBySkuErrorAction,
    setIsTireOrWheelShopWithNoVehicleAction,
    setIsSKUPresentInUrlAction,
    setIsOOSCurrentStoreAction,
    setIsProductDataAvailableAction,
    setIsPriceAvailabilityApiDoneAction,
    fetchVariantsProductDataBySkuErrorAction,
    setIsVehicleChangedAction,
    fetchSizeChartDataAction,
    fetchSizeChartDataErrorAction,
    setSelectedVariantIdAction,
    setSelectedVariantAction,
    setSelectedCurrentPriceAction,
    setProductListABTestSuccessAction,
    fetchAppointmentAvailabilityDateTimeSlotsSuccessAction,
    fetchAppointmentAvailabilityDateTimeSlotsErrorAction,
    setAppointmentAvailabilityDateTimeSlotsLoading,
    resetAppointmentAvailabilityDateTimeSlots,
    setShowSpinner,
    setCheckoutAppointment,
    setSelectedVariantByUserAction,
} from '../actionCreators'
import {
    ProductResponseData,
    StickyBuyBoxCTARequestPayload,
    SizeChartDataType,
    ProductResponseErrorDTO,
    SelectedVariantID,
    SelectedVariant,
    SelectedVariantIdType,
    ProductSkusData,
} from '../models/product.interface'
import { BreadcrumbLinkData } from '../../redux/models/productData.interface'
import { productDetailsService } from '../../services/productDetailsService/productDetails.service'
import { SkuListType } from '../../components/FrequentlyBoughtTogether/FrequentlyBoughtTogether.type'
import { BuyboxProps, ThresholdValuesType } from '../../components/BuyBox/BuyBox.type'
import { AxiosError, AxiosResponse } from 'axios'
import { replaceEmptyImagesWithDefault } from '../../utils/replaceEmptyImagesWithDefault'
import httpClient from '../utils/httpClient'
import { firstVariantKey, secondVariantKey, thirdVariantKey } from '../../components/BuyBox/BuyBox.constant'
import { AxiosCartResponseErrorDTO, Price } from '../models/cart.interface'
import GlobalPropsHelper from '../../analytics/helpers/globalProps/globalProps.helper'
import { logNewRelicError } from '../../components/NewRelic/newRelic.helper'
// eslint-disable-next-line max-len
import appointmentAvailabilityService from '../../services/appointmentAvailabilityService/appointmentAvailabilityService'
import { CheckoutAppointmentInfo, CheckoutPickupInfo } from '../models/checkout.interface'
import { successStatus } from '../../components/Checkout/Checkout.constant'
import { checkValidData } from '../../components/Accounts/Addresses/checkDataLength'

const globalProps = new GlobalPropsHelper()

/**
 * @param {string} productCode productID from query param
 * @param {string} selectedPreferredStoreId selected preferred store id
 * @param {boolean} lightLoadingEnabled
 * @returns {Promise<void>} returns api call promise
 */
export const fetchProductData =
    (productCode: string, selectedPreferredStoreId: string, lightLoadingEnabled?: boolean) =>
    (dispatch: Dispatch): Promise<void> => {
        return productDetailsService
            .getProductDetails(productCode, selectedPreferredStoreId, lightLoadingEnabled)
            .then(data => {
                replaceEmptyImagesWithDefault([data?.data] as Record<string, any>[], 'images')
                dispatch(fetchProductDataSuccessAction(data.data as ProductResponseData))
            })
            .catch((error: AxiosError<string>) => {
                const { data, status } = error.response as AxiosResponse<string>
                dispatch(fetchProductDataErrorAction({ data, status }))
            })
    }

/**
 * Function to fetch product data by sku
 * @param {string[]|SkuListType[]} skuCode
 * @param {string} selectedPreferredStoreId
 * @param {ThresholdValuesType} thresholdValues
 * @param {string} brandName
 * @returns {Promise<void>}
 */
export const fetchProductDataBySku =
    (
        skuCode: string[] | SkuListType[],
        selectedPreferredStoreId: string,
        thresholdValues?: ThresholdValuesType,
        brandName?: string | boolean,
    ) =>
    (dispatch: Dispatch): Promise<void> => {
        dispatch(setIsVehicleChangedAction(false))
        return productDetailsService
            .getProductDetailsOfSku(
                skuCode,
                selectedPreferredStoreId,
                thresholdValues,
                false,
                brandName as string | undefined,
            )
            .then((data: AxiosResponse<unknown>) => {
                replaceEmptyImagesWithDefault([data?.data as ProductResponseData], 'images')
                dispatch(fetchProductDataBySkuAction(data.data as ProductResponseData))
                dispatch(setIsPriceAvailabilityApiDone(true) as unknown as AnyAction)
            })
            .catch((error: AxiosError<string>) => {
                const { data, status } = error.response as AxiosResponse<string>
                dispatch(fetchProductDataBySkuErrorAction({ data, status }))
                dispatch(setIsPriceAvailabilityApiDone(true) as unknown as AnyAction)
                // temporary logging
                logNewRelicError({
                    error,
                    errorInfo: {
                        allowedDomainHeaders: globalProps.readAllowedDomainsWithHeadersConfig(),
                    },
                    resource: 'priceAvailability',
                })
            })
    }

export const setIsOutOfStock =
    (outOfStock: boolean) =>
    (dispatch: Dispatch): void => {
        dispatch(setIsOutOfStockAction(outOfStock))
    }

export const setIsOOSCurrentStore =
    (outOfStock: boolean) =>
    (dispatch: Dispatch): void => {
        dispatch(setIsOOSCurrentStoreAction(outOfStock))
    }

export const setSelectedFirstVariant =
    (selectedFirstVariant: string, selectedFirstVariantDisplayName?: string) =>
    (dispatch: Dispatch): void => {
        const selectedVariant: SelectedVariant = {
            variantLevel: firstVariantKey,
            selectedVariant: selectedFirstVariant,
            selectedFirstVariantDisplayName: selectedFirstVariantDisplayName,
        }
        dispatch(setSelectedVariantAction(selectedVariant))
    }

export const setSelectedSecondVariant =
    (selectedSecondVariant: string | number) =>
    (dispatch: Dispatch): void => {
        const selectedVariant: SelectedVariant = {
            variantLevel: secondVariantKey,
            selectedVariant: selectedSecondVariant as string,
        }
        dispatch(setSelectedVariantAction(selectedVariant))
    }

export const setSelectedProductCode =
    (selectedCode: string) =>
    (dispatch: Dispatch): void => {
        dispatch(setSelectedSizeProductCodeAction(selectedCode))
    }

export const setSelectedCurrentPrice =
    (currentPrice: Price) =>
    (dispatch: Dispatch): void => {
        dispatch(setSelectedCurrentPriceAction(currentPrice))
    }

export const setSelectedThirdVariant =
    (selectedThirdVariant: string) =>
    (dispatch: Dispatch): void => {
        const selectedVariant: SelectedVariant = {
            variantLevel: thirdVariantKey,
            selectedVariant: selectedThirdVariant,
        }
        dispatch(setSelectedVariantAction(selectedVariant))
    }
export const setQuantitySelected =
    (quantitySelected: number) =>
    (dispatch: Dispatch): void => {
        dispatch(setQuantitySelectedAction(quantitySelected))
    }
export const setSelectedFirstVariantId =
    (selectedFirstVariantId: SelectedVariantIdType) =>
    (dispatch: Dispatch): void => {
        const selectedVariantID: SelectedVariantID = {
            variantLevel: firstVariantKey,
            selectedVariantID: selectedFirstVariantId,
        }
        dispatch(setSelectedVariantIdAction(selectedVariantID))
    }

export const setSelectedSecondVariantId =
    (selectedSecondVariantId: SelectedVariantIdType) =>
    (dispatch: Dispatch): void => {
        const selectedVariantID: SelectedVariantID = {
            variantLevel: secondVariantKey,
            selectedVariantID: selectedSecondVariantId,
        }
        dispatch(setSelectedVariantIdAction(selectedVariantID))
    }

export const setSelectedThirdVariantId =
    (selectedThirdVariantId: SelectedVariantIdType) =>
    (dispatch: Dispatch): void => {
        const selectedVariantID: SelectedVariantID = {
            variantLevel: thirdVariantKey,
            selectedVariantID: selectedThirdVariantId,
        }
        dispatch(setSelectedVariantIdAction(selectedVariantID))
    }

export const setStickyBuyboxCTAClicked =
    (requestPayload: StickyBuyBoxCTARequestPayload) =>
    (dispatch: Dispatch): void => {
        dispatch(setStickyBuyboxCTAClickedAction(requestPayload))
    }

export const toggleAddToCartCTA =
    (showCTA: boolean) =>
    (dispatch: Dispatch): void => {
        dispatch(toggleAddToCartCTAAction(showCTA))
    }

export const setIfAllVariantsSelected =
    (isSelected: boolean) =>
    (dispatch: Dispatch): void => {
        dispatch(setIfAllVariantsSelectedAction(isSelected))
    }

export const setFBTProductCode =
    (productCode: string[]) =>
    (dispatch: Dispatch): void => {
        dispatch(setFBTProductCodeAction(productCode))
    }

export const setBuyBoxProps =
    (buyBoxProps: BuyboxProps) =>
    (dispatch: Dispatch): void => {
        dispatch(setBuyBoxPropsAction(buyBoxProps))
    }

export const setSelectedImageUrl =
    (url: string) =>
    (dispatch: Dispatch): void => {
        dispatch(setSelectedImageUrlAction(url))
    }

export const resetProductSKUData =
    () =>
    (dispatch: Dispatch): void => {
        dispatch(resetProductSKUDataAction())
    }
export const setFrontQuantitySelected =
    (frontQuantitySelected: number) =>
    (dispatch: Dispatch): void => {
        dispatch(setFrontQuantitySelectedAction(frontQuantitySelected))
    }
export const setRearQuantitySelected =
    (rearQuantitySelected: number) =>
    (dispatch: Dispatch): void => {
        dispatch(setRearQuantitySelectedAction(rearQuantitySelected))
    }

/**
 * Function to fetch FBT product data by sku
 * @param {string[]|SkuListType[]} skuCode
 * @param {string} selectedPreferredStoreId
 * @param {ThresholdValuesType} thresholdValues
 * @param {boolean} isFBT
 * @returns {Promise<void>}
 */

export const fetchFBTProductDataBySku =
    (skuCode: SkuListType[], selectedPreferredStoreId: string, thresholdValues: ThresholdValuesType, isFBT: boolean) =>
    (dispatch: Dispatch): Promise<void> => {
        return productDetailsService
            .getProductDetailsOfSku(skuCode, selectedPreferredStoreId, thresholdValues, isFBT)
            .then(data => {
                dispatch(fetchFBTProductDataBySkuAction(data.data as ProductSkusData))
                dispatch(setIsPriceAvailabilityApiDoneAction(true))
            })
            .catch((error: AxiosError<string>) => {
                const { data, status } = error.response as AxiosResponse<string>
                dispatch(fetchFBTProductDataBySkuErrorAction({ data, status }))
                // temporary logging
                logNewRelicError({
                    error,
                    errorInfo: {
                        allowedDomainHeaders: globalProps.readAllowedDomainsWithHeadersConfig(),
                    },
                    resource: 'priceAvailability',
                })
            })
    }

/**
 * Function to fetch variants product data by sku
 * @param {string[]|SkuListType[]} skuCode
 * @param {string} selectedPreferredStoreId
 * @return {Promise<void>}
 */

export const fetchVariantsProductDataBySku =
    (skuCode: SkuListType[], selectedPreferredStoreId: string) =>
    (dispatch: Dispatch): Promise<void> => {
        return productDetailsService
            .getProductDetailsOfSku(skuCode, selectedPreferredStoreId, undefined, true)
            .then(data => {
                dispatch(fetchVariantsProductDataBySkuAction(data.data as ProductSkusData))
            })
            .catch((error: AxiosError<string>) => {
                const response = error.response
                if (response && response.data) {
                    const { data, status } = error.response as AxiosResponse<string>
                    dispatch(fetchVariantsProductDataBySkuErrorAction({ data, status }))
                }
                // temporary logging
                logNewRelicError({
                    error,
                    errorInfo: {
                        allowedDomainHeaders: globalProps.readAllowedDomainsWithHeadersConfig(),
                    },
                    resource: 'priceAvailability',
                })
            })
    }

/**
 * function to set redux state if shopwith no vehicle selected or not
 * @param {boolean} isTireOrWheelShopWithNoVehicle
 * @returns {void}
 */
export const setIsTireOrWheelShopWithNoVehicle =
    (isTireOrWheelShopWithNoVehicle: boolean) =>
    (dispatch: Dispatch): void => {
        dispatch(setIsTireOrWheelShopWithNoVehicleAction(isTireOrWheelShopWithNoVehicle))
    }

/**
 * function to check sku present in the url
 * @param {boolean} isSKUPresent
 * @returns {void}
 */
export const setIsSKUPresentInUrl =
    (isSKUPresent: boolean) =>
    (dispatch: Dispatch): void => {
        dispatch(setIsSKUPresentInUrlAction(isSKUPresent))
    }

/**
 * function to check sku present in the url
 * @param {boolean} isApisDone
 * @returns {void}
 */
export const setIsProductDataAvailable =
    (isApisDone: boolean) =>
    (dispatch: Dispatch): void => {
        dispatch(setIsProductDataAvailableAction(isApisDone))
    }

/**
 * function to reset IsNearByStoreListApiDone
 * @param {boolean} isApiCalled
 * @returns {Dispatch}
 */
export const setIsPriceAvailabilityApiDone =
    (isApiCalled: boolean) =>
    (dispatch: Dispatch): void => {
        dispatch(setIsPriceAvailabilityApiDoneAction(isApiCalled))
    }

/**
 * function to set vehicleChanged
 * @param {boolean} isApiCalled
 * @returns {Dispatch}
 */
export const setIsVehicleChanged =
    (isApiCalled: boolean) =>
    (dispatch: Dispatch): void => {
        dispatch(setIsVehicleChangedAction(isApiCalled))
    }

/**
 * function to fetch size chart for current product
 * @param {BreadcrumbLinkData[]} breadcrumbList
 * @param {string} brand
 * @param {string} language
 * @returns {Dispatch}
 */
export const fetchSizeChartData =
    (breadcrumbList: BreadcrumbLinkData[], brand: string, language: string, enableNewSizeChart?: boolean) =>
    (dispatch: Dispatch): Promise<void> => {
        return productDetailsService
            .getSizeChartData(breadcrumbList, brand, language, enableNewSizeChart)
            .then(({ data: sizeChartData }: AxiosResponse<SizeChartDataType>) =>
                dispatch(fetchSizeChartDataAction(sizeChartData)),
            )
            .catch((error: { response: { data: any; status: any } }) => {
                const { data, status } = error.response as ProductResponseErrorDTO
                dispatch(fetchSizeChartDataErrorAction({ data, status }))
            }) as Promise<void>
    }

/**
 * function to get product list for A/B Test on PDP's
 * @returns {string}
 */
const getProductListABTestEndpoint = (): string =>
    document?.querySelector('.buybox span[data-component="Buybox"]')?.getAttribute('data-product-list') || ''

/**
 * function call to get product list data props for A/B Test on PDP's
 * @returns {Promise}
 */
export const setProductListABTest =
    () =>
    // eslint-disable-next-line consistent-return
    (dispatch: Dispatch): Promise<void> | void => {
        const payloadEndpoint = getProductListABTestEndpoint()
        if (payloadEndpoint) {
            return httpClient
                .apiGet(payloadEndpoint, {}, 'AEM_EXP_FRAG')
                .then(data => {
                    data && dispatch(setProductListABTestSuccessAction(data))
                })
                .catch(error => {
                    if (error instanceof Error) {
                        logNewRelicError({
                            error,
                            resource: 'setProductListABTest',
                        })
                    }
                })
        }
    }

/**
 * Action to load balloons appointment availability data
 * @param {number} storeId - Store ID
 * @param {string} startDate - start day date
 * @param {string} endDate - end day date
 * @returns {Promise<void>} returns api call promise
 */
export const fetchBalloonsAppointmentAvailability =
    (storeId: number, startDate: Date, endDate: Date, cartId?: string) =>
    (dispatch: Dispatch): Promise<void> => {
        dispatch(setAppointmentAvailabilityDateTimeSlotsLoading(true))
        dispatch(resetAppointmentAvailabilityDateTimeSlots())

        return appointmentAvailabilityService
            .getBalloonsAppointmentAvailability(storeId, startDate, endDate, cartId)
            .then(data => {
                dispatch(fetchAppointmentAvailabilityDateTimeSlotsSuccessAction(data.data))
            })
            .catch((error: AxiosError<string>) => {
                const { data, status } = error.response as AxiosResponse<string>
                dispatch(fetchAppointmentAvailabilityDateTimeSlotsErrorAction({ data, status }))
            })
            .finally(() => {
                dispatch(setAppointmentAvailabilityDateTimeSlotsLoading(false))
            })
    }

/**
 * Action to load balloons appointment availability data
 * @param { string } cartId - id of the current cart
 * @param { CheckoutAppointmentInfo } appointmentInfo - information about appointment
 * @param { boolean } showSpinner - show spinner
 * @param {CheckoutPickupInfo} checkoutPickupInfo - checkoutPickupInfo
 * @returns {Promise<void>} returns api call promise
 */
export const saveBalloonsAppointmentAvailability =
    (
        cartId: string,
        appointmentInfo: CheckoutAppointmentInfo,
        // eslint-disable-next-line default-param-last
        showSpinner = false,
        checkoutPickupInfo?: CheckoutPickupInfo,
    ) =>
    (dispatch: Dispatch) => {
        showSpinner && dispatch(setShowSpinner(true))
        return appointmentAvailabilityService
            .saveBalloonsAppointmentAvailability(cartId, appointmentInfo)
            .then((response: AxiosResponse) => {
                if (response.status === successStatus) {
                    if (
                        appointmentInfo?.isPickupAllItemsTogether &&
                        checkValidData(checkoutPickupInfo) &&
                        checkoutPickupInfo?.orderPickupInPerson
                    ) {
                        dispatch(
                            setCheckoutAppointment({
                                ...appointmentInfo,
                                appointmentContact: checkoutPickupInfo?.orderPickupInPerson,
                            }),
                        )
                    } else {
                        dispatch(setCheckoutAppointment(appointmentInfo))
                    }
                }
            })
            .catch((err: AxiosCartResponseErrorDTO) => {
                // eslint-disable-next-line no-console
                console.warn(err)
            })
            .finally(() => {
                dispatch(setShowSpinner(false))
            })
    }

/**
 * function to set boolean true if variant is selected by user
 * @param {boolean} isSelected
 * @returns {Dispatch}
 */
export const setSelectedVariantByUser =
    (isSelected: boolean) =>
    (dispatch: Dispatch): void => {
        dispatch(setSelectedVariantByUserAction(isSelected))
    }
