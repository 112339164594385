import { StatusType, LabelType, ButtonStatusType, ButtonStatusClassType } from './getButtonStatus.type'
import { statusLabel } from '../../globalConstants/global.constant'

/**
 * @param {string} status - callback function
 * @param {StatusType} type - time interval
 * @param {LabelType} labels - getter function
 * @return {ButtonStatusType}
 */
export const getButtonStatus = (
    status: string | ButtonStatusClassType,
    type: StatusType,
    labels: LabelType,
): ButtonStatusType => {
    const { activated, activate, redeemed, allActivated, swap } = type
    const { activatedLabel, activateLabel, redeemedLabel, allActivatedLabel, swapOfferCTALabel } = labels
    const lowerCase = (item: string) => item?.toLocaleLowerCase()
    const { activateStatus, activatedStatus, redeemedStatus, swapOfferStatus } = statusLabel
    const confirmIcon = 'ct-confirm'

    switch (lowerCase(status)) {
        case lowerCase(activatedStatus):
            return {
                class: lowerCase(activated) as ButtonStatusClassType,
                label: activatedLabel,
                icon: confirmIcon,
            }

        case lowerCase(activateStatus):
            return {
                class: lowerCase(activate) as ButtonStatusClassType,
                label: activateLabel,
                icon: 'ct-plus',
            }

        case lowerCase(redeemedStatus):
            return {
                class: lowerCase(redeemed) as ButtonStatusClassType,
                label: redeemedLabel,
                icon: 'ct-gift',
            }

        case lowerCase(allActivatedLabel):
            return {
                class: lowerCase(allActivated) as ButtonStatusClassType,
                label: allActivatedLabel,
                icon: confirmIcon,
            }

        case lowerCase(swapOfferStatus):
            return {
                class: lowerCase(swap) as ButtonStatusClassType,
                label: swapOfferCTALabel,
                icon: 'ct-swap',
            }
        default:
            return undefined
    }
}
