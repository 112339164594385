import { ProductDataDTO } from '../components/ProductCartItem/ProductItemInfo.type'
import { WishlistProduct } from '../types'

/**
 * Function to render vehicle information if vehicle context is present
 * @param {WishlistProduct | ProductDataDTO} productData
 * @return {string | null} vehicleInfo
 */
export const renderVehicleInfo = (productData: WishlistProduct | ProductDataDTO): string | null => {
    if (
        productData?.vehicleInformation &&
        productData?.vehicleInformation?.year &&
        Object.keys(productData?.vehicleInformation).length
    ) {
        // eslint-disable-next-line no-unsafe-optional-chaining
        const { body = '', make = '', model = '', options = '', year = '' } = productData?.vehicleInformation
        return `${year} ${make} ${model} ${body || ''} ${options || ''}`
    } else return null
}
