import { ProductSku } from '../components/VariantsWrapper/VariantsWrapper.type'

/**
 * This function concat sku and pCode level badges
 * @param {string[]} skuBadges badges at SKU level from panda api response
 * @param {string[]} productBadges badges at pCode level
 * @param {string[]} excludeBadgeList badges to exclude
 * @param {string} originallyInstalled  originallyInstalled badge
 * @param {boolean} isCustomBadge  flag to determine whether any of the skus under productFamily response has customBadge
 * @param {string[]} skuLevelBadges  badges at SKU level from product family api response
 * @returns {string[]} concat badges from sku and pCode level and returns unique badges
 */
export const combineBadges = (
    skuBadges?: string[],
    productBadges?: string[],
    excludeBadgeList?: string[],
    originallyInstalled?: string,
    isCustomBadge?: boolean,
    skuLevelBadges?: string[],
): string[] => {
    let combinedBadges: string[] = []

    const filteredProductBadges =
        productBadges?.filter((badge: string) => !excludeBadgeList?.includes(badge?.split('|')[0])) || []

    if (originallyInstalled) {
        filteredProductBadges.push(originallyInstalled)
    }

    // If isCustomBadge is true, combine skuBadges, skuLevelBadges, and product level badges
    if (isCustomBadge) {
        combinedBadges = [...(skuBadges || []), ...(skuLevelBadges || []), ...filteredProductBadges]
    } else {
        // Otherwise, combine only skuBadges and product level badges
        combinedBadges = [...(skuBadges || []), ...filteredProductBadges]
    }

    // Return unique badges using Set
    return Array.from(new Set(combinedBadges))
}

/**
 * function to return badges at sku level coming from product family api response
 * @param {ProductSku[]} productSKUs skus under product family response
 * @param {string} selectedProductCode  selected sku
 * @returns {string[]} returns array of string
 */
export const selectedSKUBadge = (productSKUs: ProductSku[], selectedProductCode: string): string[] => {
    const selectedSKU = productSKUs?.find(sku => sku.code === selectedProductCode)
    if (selectedSKU) {
        return selectedSKU.badges
    } else {
        return []
    }
}
