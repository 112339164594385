import { BannerMapping } from '../globalConstants/global.constant'
import { TransactionProps } from '../components/TransactionBackgroundImage/TransactionBackgroundImage.type'

/**
 * Function to get first element with inner content from the list of elements
 * @param {Element[]} elements
 * @return {Element}
 */
export const getElementWithContent = (elements: ArrayLike<Element>): Element | undefined => {
    const elementArray = Array.from(elements)
    return elementArray.find(element => element.innerHTML.length > 0)
}

/**
 * Function to return string value based on condition
 * @param {boolean} value1
 * @param {string} value2
 * @param {string} value3
 * @return {Element}
 */
export const getStringBasedOnCondition = (value1: boolean, value2: string, value3: string): string => {
    return value1 ? value2 : value3
}

/**
 * check if petro points conversion transaction is true
 * @param {string} banner
 * @param {boolean} isPointsConversion
 * @returns {boolean}
 */
export const isPetroConversionTransaction = (transaction: TransactionProps): boolean =>
    transaction?.banner === BannerMapping.CIRPP && transaction?.isCirPointsConversion

/**
 * check if annual topup conversion transaction is true
 * @param {string} banner
 * @param {boolean} isAnnualTopUp
 * @returns {boolean}
 */
export const isAnnualTopupTransaction = (transaction: TransactionProps): boolean =>
    transaction?.banner === BannerMapping.ANTU && transaction?.isAnnualTopUp

/**
 * check if petro or annual topup transaction is true
 * @param {TransactionProps} transaction
 * @returns {boolean}
 */
export const isPetroOrAnnualTopUpTransactions = (transaction: TransactionProps): boolean => {
    return isPetroConversionTransaction(transaction) || isAnnualTopupTransaction(transaction)
}

/**
 * display storeName/petroPoints/annualTopup label based on the conditions
 * @param {TransactionProps} transaction
 * @param {string} petroPointsConversionLabel
 * @param {string} annualTopUpLabel
 * @returns {string}
 */
export const getTransactionName = (
    transaction: TransactionProps,
    petroPointsConversionLabel: string,
    annualTopUpLabel: string,
): string => {
    const label = isPetroConversionTransaction(transaction) ? petroPointsConversionLabel : annualTopUpLabel
    return !isPetroOrAnnualTopUpTransactions(transaction) ? transaction.storeName : label
}
