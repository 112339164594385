import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { VehiclePropTypes } from './VehicleProductCard.type'
import Price from '../Price'
import { PREFIX } from '../config'
import Rebate from '../Rebate'
import Badges from '../Badges'
import { BannerType } from '../VehicleBanner/VehicleBanner.type'
import { staggeredSkuLength } from './VehicleProduct.constant'
import { isArrayNotEmpty } from '../../utils/isArrayNotEmpty'
import { combineBadges } from '../../utils/badgeUtil'
import { getAccessibilityPriceId } from '../../utils/getAccessibilityId'
import { getFitmentNote } from './VehicleProduct.helper'
import Icon from '../Icon'

export const VehicleProductInformationBadgesTestId = 'plp-badges-id'

/**
 * Product Vehicle information component displays per tire, front, rare and setOfFourTire details
 * @param {VehiclePropTypes} props
 * @returns {JSX.Element} returns Product vehicle information component
 */
const VehicleProductInformation: React.FC<VehiclePropTypes> = props => {
    const staggeredState = props.skus?.length === staggeredSkuLength && props.vehicleInformationPresent
    const { message, greyCopyMsg, pdfLink } = props.rebate || {}
    const ProductCardVehicle = `${PREFIX}-product-card-vehicle`

    /**
     * return badges detail for product
     * @param {string[]} badgesList
     * @returns {JSX.Element} badges if there are some
     */
    const productBadges = (badgesList: string[]): JSX.Element => {
        return (
            isArrayNotEmpty(badgesList) && (
                <div className={`${PREFIX}-plp-badges`} data-testId={VehicleProductInformationBadgesTestId}>
                    <Badges
                        badges={badgesList}
                        badgesAndPriorities={props.badgePriorities}
                        hideDisclaimer={props.hideDisclaimer}
                    />
                </div>
            )
        )
    }

    /**
     * Function to render Fitment Message for products
     * @returns {JSX.Element}
     */
    const renderProductFitmentMessage = (): JSX.Element | null => {
        const fitmentMessageClass = `${ProductCardVehicle}--fitment-container`
        const fitmentMessageAdditionalClass = staggeredState ? `${fitmentMessageClass}__staggered` : ''
        return !props.isTireDataExist && props.fitmentMessageToggle ? (
            <div className={`${fitmentMessageClass} ${fitmentMessageAdditionalClass}`}>
                <Icon type={props.fitmentIcon} size="sm" />
                <div
                    id={`fitment__${props.accessibilityId}`}
                    className={`${fitmentMessageClass}__fitment-message`}
                    dangerouslySetInnerHTML={{ __html: props.fitmentMessage }}></div>
            </div>
        ) : null
    }

    /**
     * renders front tire code
     * @returns {JSX.Element}
     */
    const renderFrontTireCode = (): JSX.Element => {
        return (
            <div
                aria-hidden={true}
                className={`${ProductCardVehicle}--per-tire__code ${
                    !props.badges && !props.frontTireSpecification
                        ? `${ProductCardVehicle}--per-tire__code--top-margin`
                        : ''
                } ${props.isNonStaggeredGrid ? `${ProductCardVehicle}--per-tire__grid-code` : ''}
            `}>
                {props.frontTireCode && `#${props.frontTireCode}`}
            </div>
        )
    }

    /**
     * front tire detail
     * @returns {JSX.Element}
     */
    const frontTire = (): JSX.Element => {
        return (
            <div className={`${ProductCardVehicle}--per-tire`}>
                <div className={`${ProductCardVehicle}--per-tire__text`}>{props.vehicleFrontLabel} </div>
                <div className={`${ProductCardVehicle}--per-tire__price-code`}>
                    <Price
                        isRangeView={props.isPriceRangeViewEnabled}
                        currentPrice={props.skus[0].currentPrice}
                        originalPrice={props.skus[0].originalPrice}
                        language={props.language}
                        isAutomotiveEachLabel={true}
                        eachLabel={props.eachLabel}
                        nowFromLabel={props.nowFromLabel}
                        saveFromLabel={props.saveFromLabel}
                        wasFromLabel={props.wasFromLabel}
                        fromLabel={props.fromLabel}
                        priceMessage={props.skus[0].priceMessage}
                        displayWasLabel={props.skus[0].displayWasLabel}
                        promotionalPriceLabel={props.promotionalPriceLabel}
                        unitPriceLabel={props.unitPriceLabel}
                        clearancePriceLabel={props.clearancePriceLabel}
                        a11yStrikeOutPrice={props.a11yStrikeOutPrice}
                        a11yStrikeOutPriceRange={props.a11yStrikeOutPriceRange}
                        ariaHidden={props.ariaHidden}
                        a11yTooltipIcon={props.a11yTooltipIcon}
                        a11yCloseIconLabel={props.a11yCloseIconLabel}
                        plusMinusSymbol={props.plusMinusSymbol}
                        isOnSaleOrClearance={props.skus[0]?.isOnSale}
                    />
                    {productBadges(
                        combineBadges(
                            props.skus?.[0]?.badges || [],
                            props.productLevelBadges,
                            props.excludeBadges,
                            getFitmentNote(props.skus[0], props.isTireProduct),
                        ),
                    )}
                    {(props.vehicleFitType === BannerType.Fit ||
                        props.vehicleInformationPresent ||
                        props.tireInformationPresent) && (
                        <div
                            aria-hidden={true}
                            className={`${ProductCardVehicle}--per-tire__specification ${
                                !isArrayNotEmpty(props.skus?.[0]?.badges) && props.frontTireSpecification
                                    ? `${ProductCardVehicle}--per-tire__specification--extra-space`
                                    : `${ProductCardVehicle}--per-tire__specification--space`
                            }`}
                            dangerouslySetInnerHTML={{ __html: props.frontTireSpecification }}></div>
                    )}
                    {renderFrontTireCode()}
                    {props.frontAvailability}
                </div>
            </div>
        )
    }

    /**
     * renders tire code
     * @returns {JSX.Element}
     */
    const renderRearTireCode = (): JSX.Element => {
        return (
            <div
                aria-hidden={true}
                className={`${ProductCardVehicle}--per-tire__code ${
                    !isArrayNotEmpty(props.badges) && !props.rearTireSpecification
                        ? `${ProductCardVehicle}--per-tire__code--top-margin`
                        : ''
                } ${props.isNonStaggeredGrid ? `${ProductCardVehicle}--per-tire__grid-code` : ''}
            `}>
                {props.rearTireCode && `#${props.rearTireCode}`}
            </div>
        )
    }

    /**
     * to hide per title lable checking for staggered type or not
     * @returns {string}
     */
    const getStaggeredModifirClass = (): string => {
        return props.isNonStaggeredGrid ? `${ProductCardVehicle}--text-visibility` : ''
    }

    /**
     * function to render Fitment Message for Non Staggered product
     * @returns {JSX.Element}
     */
    const renderFitmentMessageForNonStaggered = (): JSX.Element | null => {
        return !staggeredState ? renderProductFitmentMessage() : null
    }

    /**
     * Function to return isOnSale flag for
     * - product level
     * - regular sku level
     * - staggered rear sku level
     * @param {ProductCardType} value
     * @returns {boolean}
     */

    const onSaleValue = useMemo((): boolean => {
        if (props.skus.length === 1) {
            // for regular sku product and staggered single sku product
            return props.skus[0].isOnSale
            // eslint-disable-next-line no-magic-numbers
        } else if (props.skus.length === 2) {
            // for staggered rear sku product
            return props.skus[1].isOnSale
        } else {
            // for product level
            return props.isOnSale
        }
    }, [props.isOnSale, props.skus])

    /**
     * This function is responsible to display price for
     * - product Level price(when no vehicle or partial vehicle)
     * - Regular sku price
     * - staggered rear sku price
     * @returns {JSX.Element}
     */
    const perTire = (): JSX.Element => {
        const showPerTireSpecification =
            (props.vehicleFitType === BannerType.Fit ||
                props.vehicleInformationPresent ||
                props.tireInformationPresent) &&
            props.rearTireSpecification
        return (
            <div className={`${ProductCardVehicle}--per-tire ${getStaggeredModifirClass()}`}>
                {!props.isSRPPage && (
                    <div
                        className={`${ProductCardVehicle}--per-tire__text ${
                            props.isNonStaggeredGrid ? `${ProductCardVehicle}--per-tire__grid-text` : ''
                        }`}>
                        {props.vehicleRearLabel}{' '}
                    </div>
                )}
                <div className={`${ProductCardVehicle}--per-tire__price-code`}>
                    <Price
                        isRangeView={props.isPriceRangeViewEnabled}
                        currentPrice={props.rearTireCurrentPrice}
                        language={props.language}
                        isAutomotiveEachLabel={true}
                        eachLabel={props.eachLabel}
                        nowFromLabel={props.nowFromLabel}
                        saveFromLabel={props.saveFromLabel}
                        wasFromLabel={props.wasFromLabel}
                        fromLabel={props.fromLabel}
                        originalPrice={props.rearTireOriginalPrice}
                        discountValue={props.discount}
                        promotionalPriceLabel={props.promotionalPriceLabel}
                        unitPriceLabel={props.unitPriceLabel}
                        displayWasLabel={props.rearTireDisplayWasLabel}
                        clearancePriceLabel={props.clearancePriceLabel}
                        priceMessage={props.priceMessage}
                        a11yStrikeOutPrice={props.a11yStrikeOutPrice}
                        a11yStrikeOutPriceRange={props.a11yStrikeOutPriceRange}
                        ariaHidden={props.ariaHidden}
                        a11yCloseIconLabel={props.a11yCloseIconLabel}
                        a11yTooltipIcon={props.a11yTooltipIcon}
                        isOnSaleOrClearance={onSaleValue}
                    />
                    {productBadges(props.badges)}
                    {renderFitmentMessageForNonStaggered()}
                    {showPerTireSpecification && (
                        <div
                            aria-hidden={true}
                            className={`${ProductCardVehicle}--per-tire__specification ${
                                !isArrayNotEmpty(props.badges) && props.rearTireSpecification
                                    ? `${ProductCardVehicle}--per-tire__specification--extra-space`
                                    : `${ProductCardVehicle}--per-tire__specification--space`
                            }`}
                            dangerouslySetInnerHTML={{ __html: props.rearTireSpecification }}></div>
                    )}
                    {renderRearTireCode()}
                    {staggeredState ? props.rearAvailability : props.perAvailability}
                </div>
            </div>
        )
    }

    /**
     * setOfFour tire detail
     * @returns {JSX.Element}
     */
    const setOfFourTire = (): JSX.Element => {
        const displayWasLabel =
            staggeredState && props.skus?.[0]?.displayWasLabel
                ? props.skus?.[0]?.displayWasLabel
                : props.rearTireDisplayWasLabel
        return (
            <div className={`${ProductCardVehicle}--set-of-four`}>
                <div className={`${ProductCardVehicle}--set-of-four__text`}>{props.setOfFourLabel}</div>
                <div className={`${ProductCardVehicle}--set-of-four__product-information`}>
                    <Price
                        isRangeView={props.isPriceRangeViewEnabled}
                        currentPrice={props.totalCurrentPrice}
                        language={props.language}
                        feeTitle={props.feeTitle}
                        nowFromLabel={props.nowFromLabel}
                        saveFromLabel={props.saveFromLabel}
                        wasFromLabel={props.wasFromLabel}
                        fromLabel={props.fromLabel}
                        feeDisclaimerType={props.feeDisclaimerType}
                        feeDisclaimerMessage={props.feeDisclaimerMessage}
                        feeDisclaimerTitle={props.feeDisclaimerTitle}
                        displayWasLabel={displayWasLabel}
                        promotionalPriceLabel={props.promotionalPriceLabel}
                        unitPriceLabel={props.unitPriceLabel}
                        clearancePriceLabel={props.clearancePriceLabel}
                        scrollToFooter={props.scrollToFooter}
                        a11yStrikeOutPrice={props.a11yStrikeOutPrice}
                        a11yStrikeOutPriceRange={props.a11yStrikeOutPriceRange}
                        a11yClickToReadFootnote={props.a11yClickToReadFootnote}
                        ariaHidden={props.ariaHidden}
                        a11yTooltipIcon={props.a11yTooltipIcon}
                        a11yCloseIconLabel={props.a11yCloseIconLabel}
                    />
                    {props.rebate && Object.keys(props.rebate).length && (
                        <Rebate
                            title={message}
                            rebateIcon={props.rebateIcon}
                            greyCopyMsg={greyCopyMsg}
                            pdfLink={pdfLink}
                            ariaHidden={true}
                        />
                    )}
                </div>
            </div>
        )
    }

    return (
        <div className={`${ProductCardVehicle}`} id={getAccessibilityPriceId(props.accessibilityId)}>
            {!props.isSRPPage && staggeredState && renderProductFitmentMessage()}
            {!props.isSRPPage && staggeredState && frontTire()}
            {perTire()}
            {!props.isSRPPage && setOfFourTire()}
        </div>
    )
}

VehicleProductInformation.propTypes = {
    skus: PropTypes.array,
    vehicleRearLabel: PropTypes.string,
    rearTireCode: PropTypes.string,
    rearTireCurrentPrice: PropTypes.any,
    frontTireSpecification: PropTypes.string,
    totalCurrentPrice: PropTypes.any,
    frontTireCode: PropTypes.string,
    setOfFourLabel: PropTypes.string,
    vehicleFrontLabel: PropTypes.string,
    language: PropTypes.string,
    feeTitle: PropTypes.string,
    vehicleFitType: PropTypes.any,
    rebate: PropTypes.any,
    rebateIcon: PropTypes.string,
    eachLabel: PropTypes.string,
    isNonStaggeredGrid: PropTypes.bool,
    rearTireSpecification: PropTypes.string,
    badgePriorities: PropTypes.any,
    badges: PropTypes.array,
    productLevelBadges: PropTypes.array,
    hideDisclaimer: PropTypes.bool,
    nowFromLabel: PropTypes.string,
    saveFromLabel: PropTypes.string,
    wasFromLabel: PropTypes.string,
    fromLabel: PropTypes.string,
    promotionalPriceLabel: PropTypes.string,
    unitPriceLabel: PropTypes.string,
    displayWasLabel: PropTypes.bool,
    clearancePriceLabel: PropTypes.string,
    originalPrice: PropTypes.any,
    discount: PropTypes.any,
    feeDisclaimerType: PropTypes.string,
    feeDisclaimerMessage: PropTypes.string,
    isSRPPage: PropTypes.bool,
    priceMessage: PropTypes.array,
    vehicleInformationPresent: PropTypes.bool,
    feeDisclaimerTitle: PropTypes.string,
    rearTireOriginalPrice: PropTypes.object,
    rearTireDisplayWasLabel: PropTypes.bool,
    tireInformationPresent: PropTypes.bool,
    scrollToFooter: PropTypes.func,
    a11yStrikeOutPrice: PropTypes.string,
    a11yStrikeOutPriceRange: PropTypes.string,
    excludeBadges: PropTypes.array,
    frontAvailability: PropTypes.any,
    rearAvailability: PropTypes.any,
    perAvailability: PropTypes.any,
    a11yClickToReadFootnote: PropTypes.string,
    accessibilityId: PropTypes.string,
    ariaHidden: PropTypes.bool,
    a11yTooltipIcon: PropTypes.string,
    a11yCloseIconLabel: PropTypes.string,
}

export default VehicleProductInformation
