/**
 * scrolling to a particular section
 * @param {string} sectionId
 * @param {boolean} isDesktop
 */
export const scrollToTarget = (sectionId?: string, isDesktop?: boolean): void => {
    const sectionElem = document.getElementById(sectionId)
    const navHeaderHeight = document.getElementById('primary-navigation')?.clientHeight || 0

    // in case of mobile or tablet view,the primary nav is non scrollable
    // so need to reduce that height from section offset to view the toast section
    // eslint-disable-next-line no-unsafe-optional-chaining
    const offsetTopValue = isDesktop ? sectionElem?.offsetTop : sectionElem?.offsetTop - navHeaderHeight
    window.scroll({
        top: sectionId ? offsetTopValue : 0,
        behavior: 'smooth',
    })
}

export const scrollToElementIfExists = (headerSelector: string, selectors: string[]): void => {
    const header = document.querySelector(headerSelector)?.clientHeight || 0
    let element: HTMLElement | null = null

    selectors.some(selector => {
        element = document.querySelector(selector)
        return Boolean(element)
    })

    if (element) {
        window.scroll({
            top: element.offsetTop - header,
            behavior: 'smooth',
        })
    }
}

export const scrollToTop = scrollToTarget
