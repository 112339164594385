import { ActionType, getType } from 'typesafe-actions'

import * as actions from '../actionCreators/aoaBookingForm.actionCreators'

import { AOABookingFormState, AOAEtireInfoState } from '../models/aoaBookingForm.interface'

type Action = ActionType<typeof actions>

export const initialState: AOABookingFormState = {
    contactInfo: null,
    dateTimeInfo: null,
    locationInfo: null,
    bookingResponse: null,
    serviceInfo: null,
    currentBookingFormStep: 0,
    isOpenedStoreLocatorModal: false,
    isContactInfoEditMode: true,
    eTireInfo: null,
    hasStartedAOAFlow: false,
    showSkeleton: false,
    error: null,
    isLoading: false,
}

// eslint-disable-next-line complexity
export const aoaBookingFormReducer = (
    state: AOABookingFormState = initialState,
    action: Action,
    // eslint-disable-next-line sonar/cyclomatic-complexity
): AOABookingFormState => {
    switch (action.type) {
        case getType(actions.saveAOAContactInfoAction):
            return { ...state, contactInfo: action.payload }
        case getType(actions.saveAOADateInfoAction):
            return {
                ...state,
                dateTimeInfo: {
                    selectedDate: action.payload,
                    selectedTime: undefined,
                    selectedDropOffOption: undefined,
                },
            }
        case getType(actions.saveAOATimeInfoAction):
            return {
                ...state,
                dateTimeInfo: { ...state.dateTimeInfo, selectedTime: action.payload, selectedDropOffOption: undefined },
            }
        case getType(actions.saveAOADropOffOptionInfoAction):
            return {
                ...state,
                dateTimeInfo: { ...state.dateTimeInfo, selectedDropOffOption: action.payload },
            }
        case getType(actions.saveAOABookingResponseAction):
            return { ...state, bookingResponse: action.payload }
        case getType(actions.saveAOALocationInfoAction):
            return { ...state, locationInfo: action.payload }
        case getType(actions.clearAOABookingData):
            return {
                contactInfo: null,
                locationInfo: null,
                dateTimeInfo: null,
                bookingResponse: null,
                currentBookingFormStep: 0,
                serviceInfo: null,
                isOpenedStoreLocatorModal: false,
                isContactInfoEditMode: true,
                eTireInfo: null,
                showSkeleton: state.showSkeleton,
                hasStartedAOAFlow: false,
                error: null,
                isLoading: false,
            }
        case getType(actions.clearAOADateTimeData):
            return { ...state, dateTimeInfo: null }
        case getType(actions.clearAOAServicesData):
            return { ...state, serviceInfo: null }
        case getType(actions.saveCurrentBookingFormStep):
            return { ...state, currentBookingFormStep: action.payload }
        case getType(actions.saveAutomotiveServiceResponseAction):
            return {
                ...state,
                serviceInfo: {
                    ...state.serviceInfo,
                    serviceData: action.payload,
                },
            }
        case getType(actions.updateAutomotiveServicesAction):
            return {
                ...state,
                serviceInfo: {
                    ...state.serviceInfo,
                    selectedServices: [...action.payload],
                },
            }
        case getType(actions.saveOpenedStoreLocatorModal):
            return { ...state, isOpenedStoreLocatorModal: action.payload }
        case getType(actions.saveContactInfoEditMode):
            return { ...state, isContactInfoEditMode: action.payload }

        case getType(actions.savePreviouslySelectedServicesAction):
            return {
                ...state,
                serviceInfo: {
                    ...state.serviceInfo,
                    previouslySelectedServices: action.payload,
                },
            }
        case getType(actions.clearPreviouslySelectedServicesAction):
            return {
                ...state,
                serviceInfo: {
                    ...state.serviceInfo,
                    previouslySelectedServices: undefined,
                },
            }
        case getType(actions.saveHasStartedAOAFlow):
            return {
                ...state,
                hasStartedAOAFlow: action.payload,
            }
        case getType(actions.saveAOAeTireOrderInfo):
            return {
                ...state,
                eTireInfo: action?.payload,
            }
        case getType(actions.saveVehicleOrderInfo):
            return {
                ...state,
                eTireInfo: { ...state.eTireInfo, vehicleInfo: { ...action.payload } } as AOAEtireInfoState,
            }
        case getType(actions.setShowSkeleton):
            return {
                ...state,
                showSkeleton: action.payload,
            }
        case getType(actions.setAOAError):
            return {
                ...state,
                error: action.payload,
            }
        case getType(actions.setAOAIsLoading):
            return {
                ...state,
                isLoading: action.payload,
            }
        case getType(actions.saveAdditionalComments):
            return {
                ...state,
                serviceInfo: {
                    ...state.serviceInfo,
                    additionalComments: action.payload,
                },
            }
        case getType(actions.saveAdditionalCommentsFromServices):
            return {
                ...state,
                serviceInfo: {
                    ...state.serviceInfo,
                    additionalCommentsFromServices: action.payload,
                },
            }
        case getType(actions.resetAOADateTimeInfoAction):
            return {
                ...state,
                dateTimeInfo: null,
            }
        default:
            return state
    }
}
