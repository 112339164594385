import { ActionType, getType } from 'typesafe-actions'
import * as actions from '../actionCreators/weatherTech.actionCreators'
import { WeatherTech, WeatherTechStartTab, WeatherTechVehicle } from '../models/weatherTech.interface'
import { checkDataLength } from '@nl/lib'

export const initialState: WeatherTech = {
    weatherTechVehicle: null,
    weatherTechErrorVehicle: null,
    isWeatherTechFlow: false,
    isWeatherTechProductsPresent: false,
    initialWeatherTechProductFetchNeeded: false,
    showErrorToast: false,
    weatherTechVehicleSelectorModeOptions: {
        show: false,
        returnToStartStage: false,
        keepStagesInMemory: false,
        startTab: WeatherTechStartTab.VEHICLE_ATTRIBUTE,
        hasCancelButton: false,
    },
}
type Action = ActionType<typeof actions>

/**
 * update weather tech values
 * @param { Object } state set to initial state by default
 * @param { Action } action called
 * @returns {Object}
 *
 */
export const weatherTechReducer = (state = initialState, action: Action): WeatherTech => {
    switch (action.type) {
        case getType(actions.setWeatherTechVehicle):
            return {
                ...state,
                weatherTechVehicle: action.payload,
                isWeatherTechFlow: isWeatherTechVehicleSelected(action.payload),
            }
        case getType(actions.setWeatherTechErrorVehicle):
            return {
                ...state,
                weatherTechErrorVehicle: action.payload,
            }
        case getType(actions.setWeatherTechVehicleVendorResults):
            const { weatherTechVehicle } = state
            return {
                ...state,
                weatherTechVehicle: { ...weatherTechVehicle, vendorResults: action.payload } as WeatherTechVehicle,
            }
        case getType(actions.setIsWeatherTechFlow):
            return { ...state, isWeatherTechFlow: action.payload }
        case getType(actions.setIsWeatherTechProductsPresent):
            return { ...state, isWeatherTechProductsPresent: action.payload }
        case getType(actions.setInitialWeatherTechProductFetchNeeded):
            return { ...state, initialWeatherTechProductFetchNeeded: action.payload }
        case getType(actions.setShowErrorToast):
            return { ...state, showErrorToast: action.payload }
        case getType(actions.setWeatherTechVehicleSelectorModeOptions):
            return {
                ...state,
                weatherTechVehicleSelectorModeOptions: {
                    ...state.weatherTechVehicleSelectorModeOptions,
                    ...action.payload,
                },
            }

        default:
            return state
    }
}

/**
 * Function to check that weather tech vehicle selected
 * @param {WeatherTechVehicle} weatherTechVehicle
 * @returns {boolean}
 */
const isWeatherTechVehicleSelected = (weatherTechVehicle: WeatherTechVehicle): boolean => {
    return (
        // eslint-disable-next-line sonar/expression-complexity
        weatherTechVehicle &&
        checkDataLength(weatherTechVehicle.year) &&
        checkDataLength(weatherTechVehicle.vehicleId) &&
        checkDataLength(weatherTechVehicle.groupId) &&
        checkDataLength(weatherTechVehicle.choiceIds)
    )
}
