/**
 * Interface for QuantitySelectorProps
 * @interface
 */
export interface QuantitySelectorProps {
    setQtySelectorInput: (number: number, fromInputUpdate?: boolean, inputRef?: React.MutableRefObject<any>) => void
    qtySelectorInput: number
    defaultQty: number
    maxQty?: number
    maxInputValue?: number
    maximumQuantityError?: string
    path?: string
    quantityExceeded?: boolean
    disableErrors?: boolean
    disableErrorIcon?: boolean
    limitLabel?: string
    limitLabelShow?: boolean
    checkBannerMaxError?: (value: boolean) => void
    addToCartErrorData?: string
    disabled?: boolean
    id?: string
    a11yQuantityDecrementLabel: string
    a11yQuantityIncrementLabel: string
    a11yQuantityLabel: string
    a11yQuantityInputAriaLabel?: string
    a11yMinQuantityReached?: string
    limitMsg?: string
    disableMinQuantityReachedMessage?: boolean
    minInputValue?: number
    incrementDecrementValue?: number
    infoMessage?: string
    formatQuantity?: boolean
    a11yUpdatedQuantityText?: string
    setInteractiveElementEvent?: (event: string) => void
}

export enum InteractiveElementEvents {
    CHANGE = 'change',
    BLUR = 'blur',
    MINUS_BUTTON = 'minus_button',
    PLUS_BUTTON = 'plus_button',
}
