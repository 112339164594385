import { Vehicle, queryParameters } from '@nl/lib'

import { getEnvironment } from '../../environments'
import BaseService from '../base.service'
import { TireType } from '../../redux/models/tireVehicle.interface'
import { getFullTireSize } from '../../components/Vehicles/Vehicle.helper'
import { categoryLevelPrefix } from '../../components/Vehicles/Vehicles.constant'
import { HallOfFameAPIResponse } from '../../components/AutomotivePLP/FeaturedProducts/HallOfFame.types'
import { AutoAttributes } from '@nl/lib/src'

const environment = getEnvironment()

/**
 * @class {HallOfFameService}
 * @extends BaseService
 */
export class HallOfFameService extends BaseService {
    /**
     * @description the function creates the url according to payload
     * and the language env variable
     * @method
     * @param {string} ranks
     * @param {string} storeId
     * @param {string[]} breadcrumbList
     * @param {Vehicle} vehicle
     * @param {TireType} tireSize
     * @return {URL} url
     */
    private static createHallOfFameUrl(
        ranks: string,
        storeId: string,
        breadcrumbList: string[],
        vehicle: Vehicle | undefined,
        tireSize: TireType | null,
    ): URL {
        const { API_BASE_URL, API_ENDPOINTS, language } = environment
        const { apimEndPointFeaturedProducts } = API_ENDPOINTS

        const fullTireSize = tireSize ? getFullTireSize(tireSize) : ''

        const vehicleQueryString: string[] = fullTireSize ? [`q=${fullTireSize}`] : ['q=*']

        let queryIndex = 0

        // eslint-disable-next-line sonar/no-nested-incdec
        vehicleQueryString.push(`x${++queryIndex}=auto.application;q${queryIndex}=both`)

        // if tire size not selected and vehicle does, pass vehicle to the query
        if (!fullTireSize && vehicle) {
            const knownVehicle = Object.keys(vehicle?.autoAttributes ?? {}) as (keyof AutoAttributes)[]
            knownVehicle?.forEach(attributeName => {
                vehicleQueryString.push(
                    // eslint-disable-next-line sonar/no-nested-incdec
                    `x${++queryIndex}=auto.${attributeName};q${queryIndex}=${encodeURIComponent(
                        vehicle?.autoAttributes?.[attributeName] || '',
                    )}`,
                )
            })
        }

        breadcrumbList.forEach((category: string, index: number) => {
            vehicleQueryString.push(
                // eslint-disable-next-line sonar/no-nested-incdec
                `x${++queryIndex}=c.${categoryLevelPrefix}-${index + 1}${
                    queryParameters.divider
                }q${queryIndex}=${encodeURIComponent(category)}`,
            )
        })

        const vehicleOrTireSelectingPart = `${vehicleQueryString.join(queryParameters.divider)}`

        const mandatoryParamsSuffix = `${queryParameters.language}=${language}${queryParameters.plpCDSDivider}${queryParameters.store}=${storeId}${queryParameters.plpCDSDivider}${queryParameters.useRanks}=${ranks}`
        return new URL(
            `${API_BASE_URL}${apimEndPointFeaturedProducts}?${vehicleOrTireSelectingPart}${queryParameters.plpCDSDivider}${mandatoryParamsSuffix}`,
        )
    }

    /**
     * @description This function used to fetch featured products
     * @method
     * @param {URL} url
     * @return {AxiosPromise} HOFData
     */
    private static getHallOfFameProducts<T>(url: URL): Promise<T> {
        return new Promise<T>((resolve, reject) => {
            HallOfFameService.get(url)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }

    /**
     * @description This function used to encapsulate the url creation and fetching featured products
     * @method
     * @param {string} ranks
     * @param {string} storeId
     * @param {string[]} breadcrumbList
     * @param {Vehicle} vehicle
     * @param {TireType} tireSize
     * @return {AxiosPromise<HallOfFameAPIResponse>} HallOfFame products
     */
    public static fetchFeaturedProducts(
        ranks: string,
        storeId: string,
        breadcrumbList: string[],
        vehicle: Vehicle | undefined,
        tireSize: TireType | null,
    ): Promise<HallOfFameAPIResponse> {
        const url = this.createHallOfFameUrl(ranks, storeId, breadcrumbList, vehicle, tireSize)
        return this.getHallOfFameProducts<HallOfFameAPIResponse>(url)
    }
}

export default HallOfFameService
