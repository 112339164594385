import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { RangeSliderProps, inputValues } from './RangeSlider.type'
import { PREFIX } from '../config'
import Icon from '../Icon'
import { getValueWithUOM } from '../RangeFilter/RangeFilter.helper'
import { knobOffset, rangeValueOffset, rangeValuePixel } from './RangeSlider.constant'
import { getMaxRoundOff, getMinRoundOff } from './RangeSlider.helper'

/**
 * Range input component
 * @param {RangeSliderProps} props
 * @returns {JSX.Element} returns Range input element
 */
const RangeSlider: React.FC<RangeSliderProps> = ({ ...props }): JSX.Element => {
    const { range, values, onChange, uom, disabled, updateUOMPosition, error, isErrorChecked } = props
    const { min, max } = range
    const [showMinSpan, setShowMinSpan] = useState(false)
    const [showMaxSpan, setShowMaxSpan] = useState(false)
    const [zIndex, setZindex] = useState(0)
    const minSliderRef = useRef(null) as React.RefObject<HTMLInputElement>
    const maxSliderRef = useRef(null) as React.RefObject<HTMLInputElement>
    const rangeTrackRef = useRef(null) as React.RefObject<HTMLInputElement>
    const leftRangeValueRef = useRef(null) as React.RefObject<HTMLInputElement>
    const rightRangeValueRef = useRef(null) as React.RefObject<HTMLInputElement>
    const leftKnobRef = useRef(null) as React.RefObject<HTMLInputElement>
    const rightKnobRef = useRef(null) as React.RefObject<HTMLInputElement>
    const valuesRef = useRef(values)

    /**
     * Below function returns start and end values based on error
     * @returns { inputValues }
     */
    const getStartAndEndValues = (): inputValues => {
        if (isErrorChecked) {
            if (error) {
                return valuesRef.current
            } else {
                valuesRef.current = values
                return valuesRef.current
            }
        } else {
            return valuesRef.current
        }
    }

    const { startValue, endValue } = getStartAndEndValues()

    /**
     * function to update z-index according to current thumb
     * @param {React.RefObject<HTMLInputElement>} input
     * @returns {void}
     */
    const setInputZindex = (input: React.RefObject<HTMLInputElement>): void => {
        setZindex(zIndex + 1)
        input.current.style.zIndex = String(zIndex + 1)
    }

    /**
     * function to set the position of value span and range thumb
     * @param {React.RefObject<HTMLInputElement>} input
     * @param {React.RefObject<HTMLInputElement>} container
     * @param {React.RefObject<HTMLInputElement>} knob
     */
    const setRangePosition = useCallback(
        (
            input: React.RefObject<HTMLInputElement>,
            container: React.RefObject<HTMLInputElement>,
            knob?: React.RefObject<HTMLInputElement>,
        ) => {
            const inputElement = input.current
            const containerElement = container.current
            const knobElement = knob?.current
            const value = Number(inputElement.value)
            const inputMin = Number(inputElement.min)
            const inputMax = Number(inputElement.max)
            // eslint-disable-next-line no-magic-numbers
            const newValue = ((value - inputMin) * 100) / (inputMax - inputMin)
            const newPosition = rangeValuePixel - newValue * rangeValueOffset
            containerElement.style.left = `calc(${newValue}% + (${newPosition}px))` // To set the position for range value span

            // To set the position for left and right range thumbs
            if (knobElement) {
                if (minSliderRef.current.value === maxSliderRef.current.value) {
                    leftKnobRef.current.style.left = `calc(${newValue + knobOffset}% + (${newPosition}px))`
                } else knobElement.style.left = `calc(${newValue}% + (${newPosition}px))`
                knobElement.style.left = `calc(${newValue}% + (${newPosition}px))`
            }
        },
        [minSliderRef, maxSliderRef, leftKnobRef],
    )

    /**
     * useEffect to set the position and width of UOM in text input field
     */
    useEffect(() => {
        if (updateUOMPosition) updateUOMPosition(minSliderRef, maxSliderRef)
    }, [updateUOMPosition, minSliderRef, maxSliderRef])

    /**
     * useEffect to set the position and width of progress bar
     */
    useEffect(() => {
        if (
            // eslint-disable-next-line sonar/expression-complexity
            !rangeTrackRef.current ||
            !minSliderRef.current ||
            !maxSliderRef.current ||
            !leftKnobRef.current ||
            !rightKnobRef.current
        ) {
            return
        }
        // calculating the position of left and width to set the progress bar between two knobs
        // eslint-disable-next-line no-magic-numbers
        const startPercent = ((Number(minSliderRef.current.value) - min) / (max - min)) * 100
        const trackWidthPercent =
            // eslint-disable-next-line no-magic-numbers
            ((Number(maxSliderRef.current.value) - Number(minSliderRef.current.value)) / (max - min)) * 100

        rangeTrackRef.current.style.left = `${startPercent}%`
        rangeTrackRef.current.style.width = `${trackWidthPercent}%`

        setRangePosition(minSliderRef, leftRangeValueRef, leftKnobRef)
        setRangePosition(maxSliderRef, rightRangeValueRef, rightKnobRef)
    }, [
        startValue,
        endValue,
        max,
        min,
        minSliderRef,
        maxSliderRef,
        rangeTrackRef,
        leftRangeValueRef,
        rightRangeValueRef,
        leftKnobRef,
        rightKnobRef,
        setRangePosition,
    ])

    /**
     * function to update the value for range inputs
     * @param {string} value
     * @param {string} id
     * @returns {void}
     */
    const handleChange = (value: string, id: string): void => {
        const sliderRef = id === minSliderRef.current.id ? minSliderRef : maxSliderRef
        setInputZindex(sliderRef)
        onChange(value, id)
    }

    return (
        <div className={`${PREFIX}-range-container`} aria-hidden={true}>
            <div className={`${PREFIX}-range-container__values`}>
                <span>{getValueWithUOM(getMinRoundOff(min), uom)}</span>
                <span>{getValueWithUOM(getMaxRoundOff(max), uom)}</span>
            </div>
            <div className={`${PREFIX}-range-container__progress-bar`}>
                <div className={`${PREFIX}-range-container__progress-bar--slider`} ref={rangeTrackRef}></div>
                <div className={`${PREFIX}-range-knob`} ref={leftKnobRef} id="left-knob">
                    <Icon type="ct-range-knob-left" size="md" />
                </div>
                <div className={`${PREFIX}-range-knob`} ref={rightKnobRef} id="right-knob">
                    <Icon type="ct-range-knob-right" size="md" />
                </div>
            </div>
            <div className={`${PREFIX}-range-container__slider`}>
                <div className={`${PREFIX}-range-container__slider--range-value`} id="rangeMin" ref={leftRangeValueRef}>
                    {showMinSpan && (
                        <span data-testid={`range-value-min`}>
                            {getValueWithUOM(getMinRoundOff(Number(startValue)), uom)}
                        </span>
                    )}
                </div>
                <input
                    type="range"
                    id="min-range"
                    className={`${PREFIX}-range-slider-input`}
                    min={min}
                    max={max}
                    value={Number(startValue)}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.value, e.target.id)}
                    onPointerDown={() => setShowMinSpan(true)}
                    onPointerUp={() => setShowMinSpan(false)}
                    onTouchStart={() => setShowMinSpan(true)}
                    onTouchEnd={() => setShowMinSpan(false)}
                    ref={minSliderRef}
                    disabled={disabled}
                    tabIndex={-1}
                />
                <div
                    className={`${PREFIX}-range-container__slider--range-value`}
                    id="rangeMax"
                    ref={rightRangeValueRef}>
                    {showMaxSpan && (
                        <span data-testid={`range-value-max`}>
                            {getValueWithUOM(getMaxRoundOff(Number(endValue)), uom)}
                        </span>
                    )}
                </div>
                <input
                    type="range"
                    id="max-range"
                    className={`${PREFIX}-range-slider-input`}
                    min={min}
                    max={max}
                    value={Number(endValue)}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.value, e.target.id)}
                    onPointerDown={() => setShowMaxSpan(true)}
                    onPointerUp={() => setShowMaxSpan(false)}
                    onTouchStart={() => setShowMaxSpan(true)}
                    onTouchEnd={() => setShowMaxSpan(false)}
                    ref={maxSliderRef}
                    disabled={disabled}
                    tabIndex={-1}
                />
            </div>
        </div>
    )
}

RangeSlider.defaultProps = {
    disabled: false,
}

RangeSlider.propTypes = {
    onChange: PropTypes.func,
    uom: PropTypes.object,
    disabled: PropTypes.bool,
}

export default RangeSlider
