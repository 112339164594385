import React from 'react'
import PropTypes from 'prop-types'

import { BREAKPOINTS, PREFIX } from '../config'
import { linkBackPropTypes } from './LinkRewardsCard.types'
import Separator from '../Separator'
import { checkDataLength } from '../../utils/checkDataLength'
/**
 * LinkRewardsBackground component
 * @param {linkBackPropTypes} props
 * @return {JSX.Element} returns LinkRewardsBackground component
 */
const LinkRewardsBackground: React.FC<linkBackPropTypes> = ({ ...props }): JSX.Element => {
    const { children, dynamicBackground } = props
    const { dynamicBGContent, dynamicImg, separator } = dynamicBackground || {}

    const mobileRow = window.innerWidth <= BREAKPOINTS.tabletMaxWidth ? '' : `${PREFIX}-row ${PREFIX}-row--no-gutters`

    const renderTop = (): JSX.Element => {
        return separator ? (
            <Separator colors={separator.colors} style={separator.style} size={separator.size} />
        ) : (
            <div className={`${PREFIX}-link-background__logo`} />
        )
    }

    const renderDynamicBgChild = (): JSX.Element => {
        return dynamicBGContent ? dynamicBGContent : <></>
    }
    const renderDynamicBg = (): JSX.Element => {
        const isDynamicBg = dynamicImg ? { backgroundImage: `url(${dynamicImg})` } : {}
        return (
            <div className={`${PREFIX}-link-background__dynamic-bg`} style={isDynamicBg}>
                {renderDynamicBgChild()}
            </div>
        )
    }
    const separatorClass = separator ? `${mobileRow}` : ''

    return (
        <div
            className={`${PREFIX}-link-background ${separatorClass} ${PREFIX}-full-width-container`}
            data-testid="link-background">
            <div
                className={`${
                    separator ? `${PREFIX}-col-sm-12 ${PREFIX}-col-md-9` : `${PREFIX}-link-background__wrapper`
                }`}>
                {renderTop()}
                <div className={`${PREFIX}-link-background__content`}>{children}</div>
                {renderTop()}
            </div>
            {checkDataLength(dynamicBackground) && (
                <section className={`${PREFIX}-col-sm-12 ${PREFIX}-col-md-3`}>{renderDynamicBg()}</section>
            )}
        </div>
    )
}

LinkRewardsBackground.propTypes = {
    children: PropTypes.element,
    path: PropTypes.string,
    dynamicBackground: PropTypes.object,
}

export default LinkRewardsBackground
