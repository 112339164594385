import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { PREFIX, BREAKPOINTS } from '../config'
import { specificationProps } from './Specifications.type'
import Button from '../Button'
import Table from '../Table'
import Icon from '../Icon'
import Accordion from '../Accordion'
import { useGlobalResizeEvent } from '../../utils/useWindowEvent'
import { magicNumber } from '../../utils'

/**
 * Specifications component
 * @return {JSX.Element} returns Specifications component
 */
const Specifications: React.FC<specificationProps> = ({ ...props }): JSX.Element => {
    const {
        tableData,
        specificationContent,
        path,
        defaultState = true,
        mobileAccordionMode = true,
        specificationTitle,
        isPrerender,
    } = props

    // default specifications
    const defaultSpecifications = useMemo(
        () => tableData.slice(0, specificationContent.rowsOnLoad),
        [tableData, specificationContent.rowsOnLoad],
    )

    /**
     * This functions checks if defaultSpecifications are even
     * @return { boolean }
     */
    const isDefaultSpecificationsEven = (): boolean => {
        return defaultSpecifications.length % magicNumber.TWO === 0
    }

    // expanded specifications
    const expandedSpecifications = useMemo(
        () => tableData.slice(specificationContent.rowsOnLoad),
        [tableData, specificationContent.rowsOnLoad],
    )

    // state of isExpanded is true for gooblebot to show expanded specifications and false for normal user
    const [isExpanded, setIsExpanded] = useState(isPrerender)

    /**
     * This function toggles expanded status of specifications table
     */
    const toggleExpandedStatus = () => {
        setIsExpanded(!isExpanded)
    }

    /**
     * This function returns specifications expand button text
     * @return { string }
     */
    const getSpecificationsExpandButtonText = (): string => {
        return isExpanded ? specificationContent.showLessLabel : specificationContent.showMoreLabel
    }

    /**
     * This function returns specifications expand icon
     * @return { JSX.Element }
     */
    const getSpecificationsExpandIcon = (): JSX.Element => {
        return <Icon size="sm" type={isExpanded ? 'ct-chevron-up' : 'ct-chevron-down'} path={path} />
    }

    /**
     * This function returns specifications table element
     * @param { Record<string, unknown>[] } tableDataLocal
     * @param { string } ariaLabel
     * @param { string } tableModifierClass
     * @param { string } tableId
     * @return { JSX.Element }
     */
    const getSpecificationsTableElement = (
        tableDataLocal: Record<string, unknown>[],
        ariaLabel: string,
        tableModifierClass: string,
        tableId?: string,
    ): JSX.Element => {
        return (
            <Table
                tableType="stripped"
                headerType="column"
                tableData={tableDataLocal}
                ariaLabel={ariaLabel}
                tableModifierClass={tableModifierClass}
                tableId={tableId}
            />
        )
    }

    /**
     * This function returns specifications
     * @return { JSX.Element }
     */
    const getSpecifications = (): JSX.Element => {
        const tableModifierClass = isDefaultSpecificationsEven() ? '' : `${PREFIX}-table--expanded-specifications`
        return (
            <div className={`${PREFIX}-specifications-table`}>
                {getSpecificationsTableElement(
                    defaultSpecifications,
                    specificationTitle,
                    isExpanded ? `${PREFIX}-table--default-specifications` : '',
                )}
                {tableData.length > specificationContent.rowsOnLoad && (
                    <Button
                        type="tertiary"
                        onClick={toggleExpandedStatus}
                        ariaExpanded={isExpanded}
                        ariaControls="expanded-specifications"
                        modifierClass={`${PREFIX}-button--expand-specifications`}>
                        {getSpecificationsExpandButtonText()}
                        {getSpecificationsExpandIcon()}
                    </Button>
                )}
                {isExpanded
                    ? getSpecificationsTableElement(
                          expandedSpecifications,
                          specificationContent.expandedSpecificationsTitle,
                          tableModifierClass,
                          'expanded-specifications',
                      )
                    : null}
            </div>
        )
    }

    const [isMobile, setIsMobile] = useState(window.innerWidth <= BREAKPOINTS.tabletMaxWidth)

    const handleResize = () => {
        setIsMobile(window.innerWidth <= BREAKPOINTS.tabletMaxWidth)
    }

    useGlobalResizeEvent(handleResize)

    const renderSpecifications = (): JSX.Element => {
        const prerenderClass = isPrerender ? `${PREFIX}-md-none` : ''
        return isMobile && mobileAccordionMode ? (
            <div id="specifications" className={`${PREFIX}-section ${PREFIX}-specification`}>
                <Accordion title={specificationTitle} isHeaderOpen={defaultState}>
                    {getSpecifications()}
                </Accordion>
            </div>
        ) : (
            <div id="specifications" className={`${PREFIX}-section ${PREFIX}-specification ${prerenderClass}`}>
                {getSpecifications()}
            </div>
        )
    }

    return <>{renderSpecifications()}</>
}
Specifications.propTypes = {
    tableData: PropTypes.any,
    specificationContent: PropTypes.any,
    path: PropTypes.string,
    defaultState: PropTypes.bool,
    isPrerender: PropTypes.bool,
}

export default Specifications
