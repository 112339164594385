import React, { useLayoutEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import { CircularProgressProps, ProgressValuePosition, ProgressSizes, ProgressType } from './CircularProgress.types'
import { PREFIX } from '../config'

/**
 * Works both as spinner and circular progress bar
 * @param { CircularProgressProps } props
 * @return { JSX.Element } returns Circular progress component
 */
const CircularProgress: React.FC<CircularProgressProps> = ({ ...props }) => {
    const [strokeDashOffset, setStrokeDashOffset] = useState(0)
    const className = `${PREFIX}-circular-progress`
    const circularProgresElement = useRef<SVGCircleElement>(null)
    const progressContainer = useRef<HTMLElement>(null)
    /**
     * useLayoutEffect te get the radius from the progress circle
     */
    useLayoutEffect(() => {
        const progressCircle: SVGCircleElement = circularProgresElement.current
        const strokeWidth = parseInt(window.getComputedStyle(progressCircle).strokeWidth, 10)
        // eslint-disable-next-line no-magic-numbers
        const radius = progressCircle.getBoundingClientRect().width / 2
        // circumference of the circle
        // eslint-disable-next-line no-magic-numbers
        const cirumference = 2 * radius * Math.PI
        // cap width for stroke-linecap
        // eslint-disable-next-line no-magic-numbers
        const capWidth = props.value === 0 || props.value === 100 ? 0 : strokeWidth
        // offset calculation
        // eslint-disable-next-line no-magic-numbers
        const strokeOffset = (cirumference - (props.value * cirumference) / 100 + capWidth) * -1
        setStrokeDashOffset(strokeOffset)
        if (props.strokeColor) {
            progressContainer.current.style.stroke = props.strokeColor
            progressContainer.current.style.color = props.strokeColor
            progressContainer.current.style.fill = props.strokeColor
        }
    }, [props.size, props.value, className, props.strokeColor])

    return (
        <span
            className={`${className} ${className}--${props.type} ${className}--${props.size} ${className}--${props.position}`}
            ref={progressContainer}>
            <svg className={`${className}__svg`}>
                <circle className={`${className}__track`} />
                <circle
                    className={`${className}__progress ${className}__progress--${props.type}`}
                    strokeDashoffset={props.type && props.type !== ProgressType.SPINNER ? strokeDashOffset : 0}
                    ref={circularProgresElement}
                />
            </svg>
            {props.displayValue && (
                <span className={`${className}__value ${className}__value--${props.type}`}>{props.value}</span>
            )}
            {props.children}
        </span>
    )
}

CircularProgress.defaultProps = {
    value: 0,
    type: ProgressType.SPINNER,
    displayValue: false,
    position: ProgressValuePosition.INSIDE,
}

CircularProgress.propTypes = {
    value: PropTypes.number,
    size: PropTypes.oneOf([ProgressSizes.LARGE, ProgressSizes.SMALL]),
    type: PropTypes.oneOf([ProgressType.PROGRESS, ProgressType.SPINNER]),
    displayValue: PropTypes.bool,
    position: PropTypes.oneOf([ProgressValuePosition.BELOW, ProgressValuePosition.INSIDE]),
    strokeColor: PropTypes.string,
}

CircularProgress.displayName = 'CircularProgressInLib'

export default CircularProgress
