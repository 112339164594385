import getPageType from './getPageType'
import { pageTypes } from '../config'
import { isVehicleSelected, isWheelOrTirePDP } from '../components/Vehicles/Vehicle.helper'
import store from '../store'

const {
    checkout,
    cart,
    packageLanding,
    signIn,
    plp,
    searchPage,
    productPage,
    balloonsAppointment,
    orderDetails,
    orderConfirmation,
    promoListing,
    brandListingPage,
} = pageTypes

/**
 * Function to check the pages where minicart api to be called
 * @return { boolean }
 */
const isPageForMiniCart = (): boolean => {
    const rootState = store.getState()
    const productWheelType = rootState.categoryIdData.productWheelType

    return (
        // eslint-disable-next-line sonar/expression-complexity
        !isSigninPage() &&
        !isCheckoutPage() &&
        !isShoppingCartPage() &&
        !(isVehicleSelected() && isWheelOrTirePDP(productWheelType)) &&
        !isPackageLanding()
    )
}

/**
 * Function to check the page where the getCart api should be called
 * @returns { boolean }
 */
const isPageForCartEndPoint = (): boolean => {
    const rootState = store.getState()
    const isFitmentRequired = rootState.categoryIdData.isFitmentRequired ?? false
    // eslint-disable-next-line sonar/expression-complexity
    return isShoppingCartPage() || isPackageLanding() || (isPlpPage() && isVehicleSelected() && isFitmentRequired)
}

/**
 * Function to check if page is sigin
 * @return { boolean }
 */
const isSigninPage = (): boolean => {
    return getPageType().toLowerCase() === signIn.toLowerCase()
}

/**
 * Function to check if page is PDP
 * @return { boolean }
 */
const isProductPage = (): boolean => {
    return getPageType() === productPage
}

/**
 * Function to check if page is Balloons Appointment
 * @return { boolean }
 */
const isBalloonsAppointmentPage = (): boolean => {
    return getPageType() === balloonsAppointment
}

/**
 * Function to check if page is checkout
 * @return { boolean }
 */
const isCheckoutPage = (): boolean => {
    return getPageType() === checkout
}

/**
 * Function to check if page is cart
 * @return { boolean }
 */
const isShoppingCartPage = (): boolean => {
    return getPageType() === cart
}

/**
 * Function to check if page is packageLanding
 * @return { boolean }
 */
const isPackageLanding = (): boolean => {
    return getPageType() === packageLanding
}

/**
 * Function to check if the page is plp
 * @returns { boolean }
 */
const isPlpPage = (): boolean => {
    return getPageType() === plp
}

/**
 * Function to check if the page is search
 * @returns { boolean }
 */
const isSearchPage = (): boolean => {
    return getPageType() === searchPage
}

/**
 * Function to check if the page is categoryPage L1, L2, L3
 * @returns { boolean }
 */
const isProductListingPanelPages = (): boolean => {
    const pageType = getPageType()
    return (
        pageTypes.categoryPages.includes(pageType as 'categorylevelN' | 'categorylevel1' | 'categorylevel2') ||
        pageType === promoListing ||
        pageType === brandListingPage
    )
}

/**
 * Function to check if page is order detail or order confirmation page
 * @returns { boolean }
 */
const isOrderDetailsOrConfirmationPage = (): boolean => {
    return getPageType() === orderDetails || getPageType() === orderConfirmation
}

export {
    isPlpPage,
    isSearchPage,
    isCheckoutPage,
    isProductPage,
    isBalloonsAppointmentPage,
    isShoppingCartPage,
    isPageForMiniCart,
    isOrderDetailsOrConfirmationPage,
    isPageForCartEndPoint,
    isProductListingPanelPages,
}
