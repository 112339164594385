export const AOA_DATE_TIME_DATA = 'AOADateTimeData'
export const NUM_OF_DAYS_PER_BATCH = 35
export const FRENCH_LANGUAGE_COSTAR = 'F'
export const STORE_ID_LENGTH = 4
// To Be Removed - Error Message to show for error handling on api failure
export const API_FAILURE_ERROR_MESSAGE = 'Something went wrong'
// eslint-disable-next-line no-warning-comments
// TODO - will be removed after this error message is received as props from aem
export const NO_TIMESLOTS_AVAILABLE_ERROR_MESSAGE = 'No time slots available for the selected services'
// Calendar constants
export const calendarConstants = {
    calendarContainerClassName: 'react-calendar',
    showNeighboringMonth: false,
    calendarType: 'US',
    higherNextLabel: null,
    higherPrevLabel: null,
    nextLabelIcon: {
        type: 'ct-chevron-right',
        size: 'elements',
    },
    previousLabelIcon: {
        type: 'ct-chevron-left',
        size: 'elements',
    },
    actions: {
        next: 'next',
        previous: 'prev',
    },
    monthView: 'month',
    maxDetail: 'month',
    minDetail: 'month',
    weekDayNarrow: 'narrow',
    weekDayShort: 'short',
    enCaFormat: 'en-CA',
    calendarMonthLong: 'long',
} as const

// Time constants
export const timeConstants = {
    timeDelimiter: ':',
    hourFormat: 'numeric',
    minuteFormat: 'numeric',
}
export const dateTimeStamp = 'T'
