/* eslint no-invalid-this: 0 */
// eslint-disable-next-line no-warning-comments
// TODO: need to investigate above eslint issue further
import { TriggerEventType } from './component.type'
import { AnalyticsComponent } from './analyticsComponent.abstract'
import { AnalyticsDomSelector, analyticsAttributes } from '../../globalConstants/analyticsParams.constant'
import { PromoCta } from './promoCta'
import { AnalyticsRecordInterface } from '../providers/provider.type'
import { promoCtaClass } from './analytics.constant'

/**
 * Promo component analytics implementation
 */
export class Promo extends AnalyticsComponent {
    promoCtas: PromoCta[] = []
    /**
     * Register event listeners
     */
    registerListeners = (): void => {
        this.triggerEvents.forEach((event: TriggerEventType) => {
            switch (event) {
                case TriggerEventType.PREVIEW:
                    this.registerPreviewListener()
                    break
                case TriggerEventType.CLICK:
                    break
                default:
                    // eslint-disable-next-line no-console
                    console.warn(`invalid ${String(event)} type`)
            }
        })
    }
    /**
     * Find and register listeners for CTAs
     */
    checkAndRegisterCtas = (): void => {
        const ctaList = this.domElement.querySelectorAll(AnalyticsDomSelector.promoCta)
        ctaList.forEach((node: Node) => {
            // check node is type of Element
            if (node.nodeType === Node.ELEMENT_NODE) {
                const cta = new PromoCta(
                    node as HTMLElement,
                    [TriggerEventType.CLICK],
                    this.analyticsData,
                    this.analyticsProvider,
                )
                cta.registerListeners()
                this.promoCtas.push(cta)
            }
        })
    }
    /**
     * Register Preview listener for promos component
     * Listener trigger only when promo display on the screen completely and push data to analytics layer
     */
    private registerPreviewListener = (): void => {
        const REQUIRED_THRESHOLD = 1
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 1.0,
        }
        // intersection observer listener to track a promo is displaying on screen or not.
        const observer = new IntersectionObserver(
            (entries: IntersectionObserverEntry[], _observer: IntersectionObserver): void => {
                entries.forEach(entry => {
                    // check is completely visible on view port
                    if (entry.intersectionRatio >= REQUIRED_THRESHOLD) {
                        this.push(this.createData())
                        // stop listing
                        _observer.disconnect()
                    }
                })
            },
            options,
        )
        observer.observe(this.domElement)
    }

    /**
     * Create analytics data
     * @return {any} any
     */
    createData = (): AnalyticsRecordInterface => {
        const MAX_CTA_LENGTH = 1
        // Filtering out the elements which have height of 0 i.e. display none.
        this.promoCtas = this.promoCtas.filter(
            item =>
                item.domElement.getBoundingClientRect().height > 0 &&
                (item.domElement.classList.contains(promoCtaClass) ||
                    item.domElement.querySelectorAll(`.${promoCtaClass}`).length > 0),
        )

        // passing CTA value as undefined when promo CTA is empty
        if (this.promoCtas.length > MAX_CTA_LENGTH) {
            this.analyticsData.cta = 'multi-select'
        } else if (this.promoCtas.length === MAX_CTA_LENGTH && !!this.promoCtas[0].getCtaText()?.trim()) {
            this.analyticsData.cta = this.promoCtas[0].getCtaText()?.trim()
        } else {
            this.analyticsData.cta = undefined
        }

        return {
            event: analyticsAttributes.event.promoView,
            promotion: [this.analyticsData],
        }
    }
}
