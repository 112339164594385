import React from 'react'
import { PREFIX } from '../config'
import UrgencyMessage from '../UrgencyMessage'
import { replaceStrWithDynamicElement } from '../../utils'
import { loyaltyBadge } from './Badges.type'

export const LOYALTY_BADGE_WEB = 'LOYALTYWEB' // This const is for Loyalty badge for web

/**
 * function to render Urgency Message
 * @param {string} saleEndDaySoonMessage
 * @param {string} salePriceEndDate
 * @param {boolean} saleEndDisableShift
 * @returns {JSX.Element} returns Urgency Message component
 */
const renderUrgencyMessage = (
    saleEndDaySoonMessage: string,
    salePriceEndDate: string,
    saleEndDisableShift: boolean,
): JSX.Element => {
    return (
        <UrgencyMessage
            saleEndDaySoonMessage={saleEndDaySoonMessage}
            salePriceEndDate={salePriceEndDate}
            saleEndDisableShift={saleEndDisableShift}
        />
    )
}

/**
 * function to return product sale ends message component text
 * @param {string} salePriceEndDate
 * @param {boolean} isOnSale
 * @param {boolean} isUrgentLowStock
 * @param {boolean} isShoppingCart
 * @param {boolean} hideSaleEndDaySoonMessage
 * @param {string} saleEndDaySoonMessage
 * @param {boolean} saleEndDisableShift
 * @returns {JSX.Element} returns product sale ends message component text
 */
export const renderSaleEndDaySoonMessageComp = (
    salePriceEndDate: string,
    isOnSale: boolean,
    isUrgentLowStock: boolean,
    isShoppingCart: boolean,
    hideSaleEndDaySoonMessage: boolean,
    saleEndDaySoonMessage: string,
    saleEndDisableShift: boolean,
): JSX.Element => {
    const saleBadgeDisplay = salePriceEndDate
        ? // eslint-disable-next-line sonar/no-nested-conditional
          `${PREFIX}-shopping-cart__product__sale-end-day-soon-message${isOnSale && !isUrgentLowStock ? '--red' : ''}`
        : `${PREFIX}-shopping-cart__product__sale-end-day-soon-message--hide`
    return (
        salePriceEndDate &&
        (!!isShoppingCart ? (
            <div className={saleBadgeDisplay}>
                {renderUrgencyMessage(saleEndDaySoonMessage, salePriceEndDate, saleEndDisableShift)}
            </div>
        ) : // eslint-disable-next-line sonar/no-nested-conditional
        hideSaleEndDaySoonMessage ? null : (
            <div className={`${PREFIX}-buy-box__sale-end-day-soon-message`}>
                <p className={`${PREFIX}-body-md`}>
                    {renderUrgencyMessage(saleEndDaySoonMessage, salePriceEndDate, saleEndDisableShift)}
                </p>
            </div>
        ))
    )
}

/**
 * JSX.Element, replace string with [0]
 * @param {string} existingBadgeLabel
 * @param {loyaltyBadge} loyaltyValue
 * @param {string} customClass
 * @returns {JSX.Element} return JSX.Element, replace string with [0]
 */
export const replaceStringCharacter = (
    existingBadgeLabel: string,
    loyaltyValue: loyaltyBadge,
    customClass: string,
): JSX.Element => {
    return replaceStrWithDynamicElement(
        existingBadgeLabel,
        <span className={customClass}>&nbsp;{loyaltyValue?.Bonus?.OfferRewardValue}&nbsp;</span>,
    )
}
