import React, { useCallback, useState, useEffect, useRef } from 'react'
import {
    checkDataLength,
    EmailActivationCard,
    getCookieValue,
    parseQuery,
    SkeletonComponent,
    magicNumber,
    localeCookieName,
    getAkamaiPolicy,
    isArrayNotEmpty,
    getOfferCodesParam,
} from '@nl/lib'
import { PREFIX } from '../../config'
import { ActivateTargetedOfferPayload, Offers } from '../../redux/models/offers.interface'
import { activateTargetedOffer } from '../../redux/actions'
import { lmsProfileIdParameterName, offerCodeParameterName, p1ErrorCodes } from './EmailOfferActivation.constant'
import { EmailOfferActivationType, TargetedOffer } from './EmailOfferActivation.type'
import { commonContentAvailableSelector } from '../../redux/selectors/commonContent.selectors'
import { clearOffersCacheCookie } from '../../utils/offersCache.helper'
import { AkamaiImagePolicies } from '../../akamaiPolicy/akamaiPolicy.service'
import { offersSelector } from '../../redux/selectors/offers.selectors'
import {
    certonaOfferRecommendations,
    dispatchCertonaOffers,
    getCertonaEvent,
    getRecommendationList,
    getofferRecommendationObj,
} from '../../helpers/offers.helper'
import {
    AddToCartRecommendationDataType,
    OfferRecommendation,
    ProductCardType,
    RecommendationResponseDataDTO,
} from '../../redux/models/recommendations.interface'
import { RootState } from '../../redux/reducers'
import { CertonaInitialization, getImagesFromCertona } from '../../certona/certona.service'
import { Loyalty } from '../../redux/models/commonContent.interface'
import { useAppDispatch, useAppSelector } from '../../hooks/react-redux.hook'

const EmailOfferActivation = (props: EmailOfferActivationType): JSX.Element => {
    const dispatch = useAppDispatch()
    const ClassName = `${PREFIX}-offer-activation`
    const { targetedOfferActivatedData, offersActivatedError, offersData, hideEmailActivation } =
        useAppSelector(offersSelector)
    const commonContentAvailable = useAppSelector(commonContentAvailableSelector)
    const [paramSelectedOffer, setParamSelectedOffer] = useState<TargetedOffer[]>()
    const [selectedOfferArray, setSelectedOfferArray] = useState<Offers[]>([])
    const runApiOnce = useRef(magicNumber.ZERO)
    const [recommendationList, setRecommendationItems] = useState({} as OfferRecommendation)

    const { recommendationsData: recommendationRootState } = useAppSelector(
        (state: RootState) => state.recommendationsData,
    )
    const productRecommendations = useAppSelector((state: RootState) => state.certona)

    const [offerRecommendationData, setOfferRecommendationsData] = useState<AddToCartRecommendationDataType[]>([])
    const {
        badParametersErrorMessageHeader,
        badParametersErrorMessageText,
        bannerImageList,
        failedActivationImage,
        failedActivationImageAltText,
        offerEndsMessage,
        offerEndsTodayMessage,
        offerEndsDateMessage,
        shopNowCtaA11yLabel,
        shopNowCtaLabel,
        shopNowCtaLink,
        shopNowCtaLinkTarget,
        successMessageHeader,
        successMessageText,
        weeklyOffersCtaA11yLabel,
        weeklyOffersCtaLabel,
        weeklyOffersCtaLink,
        weeklyOffersCtaLinkTarget,
        multipleOffersSuccessImage,
        multipleOffersSuccessImageAltText,
        activatedLabel,
        offerImageAltText,
        offerDetailsLabel,
        multipleOfferTilesSuccessMessage,
        recommendedProductsLabel,
        schemaId,
        maxNumberOfProductRecommendations,
        a11yLabelForOfferDetails,
    } = props
    const urlParams = new URLSearchParams(window.location.search)
    const offerCodeParam = getOfferCodesParam(offerCodeParameterName)
    const lmsProfileIdParam = urlParams.get(lmsProfileIdParameterName)

    const { globalLinks, loyalty = {} as Loyalty } = commonContentAvailable
    const { fallbackOfferImageUrl, moreDetailsLabel, fallbackImageForBrandLogo, fallbackImageForBrandLogoAltText } =
        loyalty
    const { errCode } = offersActivatedError || {}

    const isOnManageRewardsPage = window.location.pathname === globalLinks?.rewardsPageLink

    /**
     * Dispatch activate offer from param provided and use it as a payload
     * @param {string} offerCode - p2
     * @param {string | null} lmsProfileId - p1 (can be null or string)
     */
    const activateOffer = useCallback(
        (offerCode: string, lmsProfileId: string | null) => {
            const hasDelimeter = offerCode.includes(' ')

            const payload: ActivateTargetedOfferPayload = {
                p2: parseQuery(offerCode, hasDelimeter),
            } as ActivateTargetedOfferPayload

            const langValue = getCookieValue(localeCookieName)

            if (lmsProfileId) {
                payload.p1 = lmsProfileId
            }

            if (langValue) {
                payload.lang = langValue
            }

            dispatch(activateTargetedOffer(payload, hasDelimeter))
        },
        [dispatch],
    )

    /**
     * If user doesn't have both parameters, redirect to home page
     * Call activateOffer with the ff:
     * When a user is redirected to the Manage Rewards Page(user is authenticated) with the parameters, OR
     * When a user has both params & not in the Manage Rewards Page
     */
    useEffect(() => {
        if (isOnManageRewardsPage) {
            offerCodeParam && activateOffer(offerCodeParam, null)
        } else {
            lmsProfileIdParam && offerCodeParam && activateOffer(offerCodeParam, lmsProfileIdParam)
        }
    }, [activateOffer, offerCodeParam, lmsProfileIdParam, isOnManageRewardsPage])

    /**
     * Effect to build offer card
     */
    useEffect(() => {
        if (checkDataLength(targetedOfferActivatedData)) {
            const offerList: TargetedOffer[] = []
            targetedOfferActivatedData?.map(targetedOffer => {
                if (!targetedOffer?.aemDetails?.error || !targetedOffer?.epsilonDetails?.error) {
                    const offer: TargetedOffer = {
                        badgeImageUrl: targetedOffer?.aemDetails.badgeImageUrl,
                        code: targetedOffer?.offerCode,
                        daysLeft: targetedOffer?.epsilonDetails.daysLeft,
                        imageUrl: targetedOffer?.aemDetails.imageUrl,
                        shortDesc: targetedOffer?.aemDetails.offerShortDesc,
                        banner: targetedOffer?.epsilonDetails.banner,
                        offerEndDate: targetedOffer?.epsilonDetails.offerEndDate,
                        displayBanner: targetedOffer?.epsilonDetails.displayBanner,
                    }
                    offerList.push(offer)
                }
            })
            setParamSelectedOffer(offerList)
        }
    }, [targetedOfferActivatedData])

    useEffect(() => {
        if (checkDataLength(offersData?.offers)) {
            const offerArray =
                offersData?.offers.filter(selectedOffer => {
                    return paramSelectedOffer?.find(offers => {
                        return offers.code === selectedOffer.offerCode
                    })
                }) || []
            setSelectedOfferArray(offerArray)
        }
    }, [paramSelectedOffer, offersData])

    useEffect(() => {
        setTimeout(() => {
            clearOffersCacheCookie()
        }, magicNumber.TWOTHOUSAND)
    })

    /**
     * useEffect to fetch recommendation productData
     */
    useEffect(() => {
        const certonaSchemaIdList = productRecommendations?.resonance?.schemes
        if (isArrayNotEmpty(certonaSchemaIdList) && schemaId) {
            setOfferRecommendationsData(getofferRecommendationObj(certonaSchemaIdList, schemaId))
            const { getItems } = CertonaInitialization.extractSchemeDetails(certonaSchemaIdList, schemaId)
            const productList =
                recommendationRootState.find(
                    (singleProductData: RecommendationResponseDataDTO) => singleProductData.scheme === schemaId,
                )?.productData || []
            productList?.forEach((product: ProductCardType) => {
                product['images'] = getImagesFromCertona(getItems, product)
            })
            isArrayNotEmpty(productList) &&
                setRecommendationItems(
                    getRecommendationList(
                        certonaSchemaIdList,
                        schemaId,
                        commonContentAvailable,
                        recommendedProductsLabel as string,
                        productList,
                        maxNumberOfProductRecommendations,
                    ),
                )
        }
    }, [
        commonContentAvailable,
        maxNumberOfProductRecommendations,
        productRecommendations,
        recommendationRootState,
        recommendedProductsLabel,
        schemaId,
    ])
    // use effect to dispatch cds api call when pcodes are ready.
    useEffect(() => {
        if (isArrayNotEmpty(offerRecommendationData) && runApiOnce.current === magicNumber.ZERO) {
            dispatchCertonaOffers(offerRecommendationData, recommendationRootState, dispatch)
            runApiOnce.current = magicNumber.ONE
        }
    }, [dispatch, offerRecommendationData, recommendationRootState])

    // checking multiple Offer by checking param length
    const multiOfferActivated = paramSelectedOffer && paramSelectedOffer?.length > 1 ? true : false

    /**
     * Display of title a subtitle of card
     * @param {boolean} isTitle - checks if info is title -- default is false
     * @return {string}
     */

    const isOfferArrayEmpty = paramSelectedOffer && paramSelectedOffer?.length === 0 ? true : false
    // eslint-disable-next-line consistent-return
    const renderCardInformation = (isTitle = false): string | undefined => {
        if (checkDataLength(paramSelectedOffer)) {
            return isTitle ? successMessageHeader : successMessageText
            // eslint-disable-next-line sonarjs/elseif-without-else
        } else if (
            checkDataLength(offersActivatedError) ||
            !offerCodeParam ||
            !lmsProfileIdParam ||
            isOfferArrayEmpty
        ) {
            return isTitle ? badParametersErrorMessageHeader : badParametersErrorMessageText
        }
    }

    const isLoyaltyIdInvalid = (): boolean => {
        return !!((errCode && p1ErrorCodes.includes(errCode)) || !lmsProfileIdParam)
    }
    // Props for email activation card for lib
    const activationProps = {
        ClassName,
        title: renderCardInformation(true),
        details: renderCardInformation(),
        isCardVisible: multiOfferActivated ? false : checkDataLength(paramSelectedOffer),
        messages: {
            dynamicMessage: offerEndsMessage,
            staticMessage: offerEndsTodayMessage,
            dynamicMessageWithDate: offerEndsDateMessage,
        },
        shopNowCtaA11yLabel,
        shopNowCtaLinkTarget,
        shopNowCtaLabel,
        shopNowCtaLink,
        weeklyOffersCtaA11yLabel,
        weeklyOffersCtaLabel,
        weeklyOffersCtaLink,
        weeklyOffersCtaLinkTarget,
        isLoyaltyIdInvalid: isLoyaltyIdInvalid(),
        failedActivationImage,
        failedActivationImageAltText,
        bannerImageList,
        multipleOffersSuccessImage,
        multipleOffersSuccessImageAltText,
        activatedLabel,
        offerImageAltText,
        offerDetailsLabel,
        multipleOfferTilesSuccessMessage,
        offerEndsMessage,
        offerEndsTodayMessage,
        offerEndsDateMessage,
        moreDetailsLabel,
        a11yLabelForOfferDetails,
    }

    const renderSkeleton = (): JSX.Element | null => {
        return (
            <div data-testid="offers-skeleton">
                <SkeletonComponent skeletonClass={`${PREFIX}-offers__skeleton`} height={'20rem'} />
            </div>
        )
    }

    const hideRenderSkeleton = (): boolean =>
        // eslint-disable-next-line sonar/expression-complexity
        checkDataLength(paramSelectedOffer) ||
        checkDataLength(offersActivatedError) ||
        isOfferArrayEmpty ||
        !offerCodeParam ||
        !lmsProfileIdParam

    /**
     * Certona event get called on open flyout
     * @returns {void}
     */
    const callCertona = (): void => {
        certonaOfferRecommendations(getCertonaEvent())
    }

    return (
        <>
            {!hideEmailActivation && (
                <>
                    {hideRenderSkeleton() ? (
                        <EmailActivationCard
                            {...activationProps}
                            selectedCard={paramSelectedOffer && paramSelectedOffer[0]}
                            selectedOfferArray={selectedOfferArray}
                            multiOffer={multiOfferActivated}
                            akamaiPolicy={getAkamaiPolicy(
                                AkamaiImagePolicies.returnPolicy,
                                fallbackOfferImageUrl,
                                fallbackImageForBrandLogo,
                                fallbackImageForBrandLogoAltText,
                            )}
                            callCertona={callCertona}
                            recomendationList={recommendationList}
                        />
                    ) : (
                        renderSkeleton()
                    )}
                </>
            )}
        </>
    )
}

export default EmailOfferActivation
