/**
 * Analytics provider provide a Provider instance based on provide name.
 */
import { Gtm } from './provider.gtm'
import { BaseProvider, Providers } from './provider.type'
// eslint-disable-next-line sonar/no-wildcard-import
import * as types from './provider.type'

/**
 * Get provider by name if name is not provided return default one
 * @param {Providers| undefined} provider provider name
 * @return {BaseProvider}
 */
export function getProvider(provider?: Providers): BaseProvider {
    // return default provider
    if (provider === Providers.DEFAULT) {
        return new Gtm()
    }
    return new Gtm()
}

export { types }
