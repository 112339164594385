import React from 'react'
import PropTypes from 'prop-types'

import { PREFIX } from '../../config'
import { DropdownButtonProps } from '../Dropdown.type'
import Icon from '../../Icon'

/**
 * DropdownButton component
 * @param {DropdownButtonProps} props -isFilter,transformLabel,dropdownClick,keyDownEvent,
 * filterTitle,title,icon,path,opened,isDsm
 * @returns {JSX.Element} returns DropdownButton component
 */
const DropdownButton: React.FC<DropdownButtonProps> = ({ ...props }) => {
    const {
        isFilter,
        transformLabel,
        dropdownClick,
        keyDownEvent,
        filterTitle,
        title,
        icon,
        path,
        opened,
        isDsm,
        errorClass,
        disabled,
        hexNumber,
        ariaLabel,
        buttonContent,
        quantumMetricAttribute,
        id,
        dropdownId,
    } = props

    const quantumMetricDataAttribute = quantumMetricAttribute?.type
        ? { [`data-qm-${quantumMetricAttribute?.type}`]: quantumMetricAttribute?.value }
        : {}

    /**
     *
     * function to get the disabled dropdown stylings
     * @returns { string } - return css class for disabled dropdown
     */
    const getDisabledClass = (): string => {
        return disabled ? `${PREFIX}-dropdown__button--disable` : ''
    }

    /**
     * function to get the title with or without colour swatch
     * @returns { JSX.Element } - return title with or without colour swatch
     */
    const getDropdownTitle = (): JSX.Element => {
        return buttonContent ? (
            <div
                className={`${PREFIX}-dropdown__content-button--colour-swatch ${
                    transformLabel ? `${PREFIX}-dropdown__button--text-sort` : `${PREFIX}-dropdown__button--text-normal`
                }`}>
                <>
                    {hexNumber && buttonContent(hexNumber)}
                    {title && (
                        <span className={`${PREFIX}-dropdown__button--text`} {...quantumMetricDataAttribute}>
                            {title}
                        </span>
                    )}
                </>
            </div>
        ) : (
            title && (
                <span
                    className={`${PREFIX}-dropdown__button--text ${
                        transformLabel
                            ? `${PREFIX}-dropdown__button--text-sort`
                            : `${PREFIX}-dropdown__button--text-normal`
                    }`}>
                    {title}
                </span>
            )
        )
    }

    const ariaDescribedByLabel = (): string => {
        return id ? `${id}__error` : ''
    }

    const defaultAriaLabel = () => {
        return !title ? filterTitle : `${filterTitle} ${title}`
    }

    return (
        <button
            {...quantumMetricDataAttribute}
            className={`${PREFIX}-dropdown__button ${
                isFilter && transformLabel ? `${PREFIX}-dropdown__button--sort-button` : ''
            } ${errorClass} ${getDisabledClass()}`}
            onClick={dropdownClick}
            onKeyDown={keyDownEvent}
            type="button"
            data-qm-allow="true"
            role="combobox"
            aria-controls={dropdownId}
            disabled={disabled}
            aria-expanded={!!opened}
            aria-label={ariaLabel || defaultAriaLabel()}
            ref={props.dropdownButtonref}
            aria-describedby={ariaDescribedByLabel()}>
            {isFilter ? (
                <span
                    className={`${PREFIX}-dropdown__button--title ${
                        transformLabel
                            ? `${PREFIX}-dropdown__button--title-still`
                            : `${PREFIX}-dropdown__button--title-move`
                    }`}>
                    {filterTitle}
                </span>
            ) : null}
            {isFilter ? (
                getDropdownTitle()
            ) : (
                <>
                    {!isDsm ? (
                        <span className={`${PREFIX}-dropdown__button--text`}>{title}</span>
                    ) : (
                        <span className={`${PREFIX}-dropdown__button--text ${PREFIX}-dropdown__button--text-content`}>
                            {title}
                        </span>
                    )}
                </>
            )}
            <span
                className={`${PREFIX}-dropdown__button--icon ${
                    transformLabel ? `${PREFIX}-dropdown__button--icon-sort` : `${PREFIX}-dropdown__button--icon-normal`
                }`}>
                <Icon size="lg" type={icon} path={path} />
            </span>
        </button>
    )
}

DropdownButton.propTypes = {
    title: PropTypes.string,
    icon: PropTypes.string,
    path: PropTypes.string,
    dropdownClick: PropTypes.any,
    transformLabel: PropTypes.bool,
    isFilter: PropTypes.bool,
    filterTitle: PropTypes.string,
    opened: PropTypes.bool,
    keyDownEvent: PropTypes.any,
    isDsm: PropTypes.bool,
    errorClass: PropTypes.string,
    disabled: PropTypes.bool,
    hexNumber: PropTypes.string,
    buttonContent: PropTypes.func,
    dropdownButtonref: PropTypes.any,
    ariaLabel: PropTypes.string,
}

export default DropdownButton
