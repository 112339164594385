import React from 'react'
import Icon from '../components/Icon'
import getBalance from './getBalance'

/**
 * This function is to return a formatted value of CT Money with triangle icon appended
 * @param {string} value
 * @return {JSX.Element}
 */
export const getFormatValueWithIcon = (value: string): JSX.Element => {
    return (
        <>
            <Icon size="md" type="ct-triangleMoney" />
            {getBalance(value)}
        </>
    )
}
