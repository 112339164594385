/**
 * Custom content card type
 */
export interface CustomContentType {
    type: string
    cardInfo: BrazeCardType
    contentPosition: string
}

/**
 * Braze Content card type
 */
export interface BrazeCardType {
    extras: Record<string, unknown>
    imageUrl: string
    id: string
    title: string
    description: string
    url: string
    linkText: string
}

/**
 * Custom content prop type
 */
export interface BrazeContentPropsType {
    position: string
}

/**
 * Custom content event response type
 */
export interface ContentCardType {
    cards: BrazeCardType[]
}

/**
 * Enum for content variation
 */
export enum ContentVariation {
    Image = 'image',
    CaptionImage = 'captioned_image',
    ImageLeft = 'image_left',
    Right = 'right',
}
