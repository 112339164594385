import React, { useRef } from 'react'
import PropTypes from 'prop-types'

import { PREFIX } from '../config'
import { PillsListProps, SkuListProductSpecifications } from './PillsList.types'
import { stringKeyCodes } from '../../utils'
import {
    handleClickOrEnterAccessibility,
    slectionTypeChecked,
    handleArrowsAccessibility,
    arrowActionIdentifier,
} from '../../helpers/keyboardAccessibility.helper'

/**
 * PillsList component
 * @param {PillsListProps} props - path, onCloseClick, pillLabel
 * @return {JSX.Element} returns PillsList component
 */
const PillsList: React.FC<PillsListProps> = (props: PillsListProps): JSX.Element => {
    const { updatedPillsListValue } = props
    const pillsListRef = useRef<HTMLUListElement>(null)

    /**
     * This function handles click or enter event for pill
     * @param { React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement> } event
     * @param { number } currentIndex
     * @param { SkuListProductSpecifications } value
     * @param { HTMLUListElement } pillsListNode
     */
    const processClickOrEnter = (
        event: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>,
        currentIndex: number,
        value: SkuListProductSpecifications,
        pillsListNode: HTMLUListElement,
    ): void => {
        handleClickOrEnterAccessibility(event, currentIndex, pillsListNode, slectionTypeChecked)
        props.handleClickOrEnter(value)
    }

    /**
     * This function handles keydown event for pill
     * @param { React.KeyboardEvent<HTMLDivElement> } event
     * @param { number } currentIndex
     * @param { SkuListProductSpecifications } value
     * @param { HTMLUListElement } pillsListNode
     */
    const processKeyDown = (
        event: React.KeyboardEvent<HTMLDivElement>,
        currentIndex: number,
        value: SkuListProductSpecifications,
        pillsListNode: HTMLUListElement,
    ) => {
        if (event.key === stringKeyCodes.rightArrow) {
            handleArrowsAccessibility(event, arrowActionIdentifier.NEXT, currentIndex, pillsListNode)
        }
        if (event.key === stringKeyCodes.leftArrow) {
            handleArrowsAccessibility(event, arrowActionIdentifier.PREVIOUS, currentIndex, pillsListNode)
        }
        if (event.key === stringKeyCodes.enter || event.key === stringKeyCodes.space) {
            processClickOrEnter(event, currentIndex, value, pillsListNode)
        }
    }

    return (
        <ul
            aria-labelledby={`variant-type-label`}
            className={`${PREFIX}-variants__variant-list`}
            role="radiogroup"
            ref={pillsListRef}>
            {updatedPillsListValue.map((element: SkuListProductSpecifications, index: number) => {
                const value = element?.value
                const isSelectedPill = element?.selected
                const disabledVariantClass = element?.crossingOut ? ` ${PREFIX}-variants__disabled` : ''
                return (
                    <li key={index} className={`${PREFIX}-variants__variant-item`} role="presentation">
                        <div
                            className={`${PREFIX}-variants__variant ${PREFIX}-variants__variant--${
                                isSelectedPill ? 'selected' : 'unselected'
                            }${disabledVariantClass} `}
                            aria-label={value}
                            aria-checked={isSelectedPill}
                            data-accessibility="true"
                            role="radio"
                            tabIndex={isSelectedPill ? 0 : -1}
                            onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
                                processKeyDown(event, index, element, pillsListRef.current)
                            }}
                            onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                                processClickOrEnter(event, index, element, pillsListRef.current)
                            }}>
                            <span className={`${PREFIX}-variants__variant-text`}>{value}</span>
                        </div>
                    </li>
                )
            })}
        </ul>
    )
}

PillsList.propTypes = {
    updatedPillsListValue: PropTypes.array,
    handleClickOrEnter: PropTypes.func,
}

export default PillsList
