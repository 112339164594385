import { priceViewConfigSelector } from '../redux/selectors/commonContent.selectors'
import { getPageType } from '@nl/lib'
import { pageTypes } from '../config'
import { RootState } from '../redux/reducers'
import { breadcrumbListSelector, idSelector } from '../redux/selectors/categoryIdData.selectors'
import { pdpBreadcrumbListSelector } from '../redux/selectors/product.selectors'
import { useAppSelector } from './react-redux.hook'

export const ALL_PAGES = ['All Pages', 'ALL']
const ClearanceAutomotivePagesIdMap = {
    ClearanceTiresWheels: 'DC0000015',
    ClearanceAutoParts: 'DC0000011',
    ClearanceAutoToolsAccessories: 'DC0000006',
}

export const useIsPriceRangeIsAllowed = (): boolean => {
    const { categoriesForPriceRangeView, forPriceRangeViewEnabled } = useAppSelector(priceViewConfigSelector)
    const type = getPageType()

    let strategy: (state: RootState) => boolean = () => false

    const nonEmptyCategories = categoriesForPriceRangeView?.filter(category => Boolean(category.trim())) || []

    if (forPriceRangeViewEnabled) {
        if (nonEmptyCategories?.find(card => ALL_PAGES.find(option => cutForCompare(option) === cutForCompare(card)))) {
            strategy = () => true
        } else {
            switch (true) {
                case pageTypes.categoryPages.includes(type):
                    strategy = isEnableOnCategoryPlp(nonEmptyCategories)
                    break
                case type === pageTypes.promoListing:
                    strategy = isEnableOnClearancePlp(nonEmptyCategories)
                    break
                case type === pageTypes.productPage:
                    strategy = isEnableOnPDP(nonEmptyCategories)
                    break
                default:
                    break
            }
        }
    }

    return useAppSelector(strategy)
}

const cutForCompare = (line: string) => line?.toLowerCase?.()?.replace(/\W/, '') || ''

const isEnableOnCategoryPlp =
    (categoriesForPriceRangeView: string[]) =>
    (state: RootState): boolean => {
        return Boolean(
            breadcrumbListSelector(state)?.find(crumb =>
                categoriesForPriceRangeView
                    .filter(Boolean)
                    ?.find(item =>
                        (crumb?.id?.toUpperCase() || crumb?.url?.toUpperCase() || '').includes(item?.toUpperCase()),
                    ),
            ),
        )
    }

const isEnableOnClearancePlp =
    (categoriesForPriceRangeView: string[]) =>
    (state: RootState): boolean => {
        const cleranceCatId = idSelector(state)

        return Boolean(
            Object.entries(ClearanceAutomotivePagesIdMap)?.find(
                ([key, id]) =>
                    cleranceCatId?.toUpperCase() === id?.toUpperCase() &&
                    categoriesForPriceRangeView.includes(key?.toUpperCase()),
            ),
        )
    }

const isEnableOnPDP =
    (categoriesForPriceRangeView: string[]) =>
    (state: RootState): boolean => {
        return Boolean(
            pdpBreadcrumbListSelector(state)?.find(crumb =>
                categoriesForPriceRangeView.includes(crumb?.categoryId?.toUpperCase() || ''),
            ),
        )
    }

export default useIsPriceRangeIsAllowed
