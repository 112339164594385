import { createSwiper } from '../../utils/createSwiper'
import { SwiperOptions } from './FeaturedListImage.type'
import { SwiperOptions as SwiperCreatorTypes } from '../HeadbandBannerComponent/HeadbandBannerComponent.type'
import { PREFIX } from '../../config'
import { libUtils } from '@nl/lib'

/**
 * Initializes a featured list image with the given element and parameters.
 * @param {HTMLElement} [element] - The HTML element to initialize the featured list image on.
 */
const FeaturedListImage = (element?: HTMLElement): void => {
    if (!element) return

    const uniqueId = `swiper-${libUtils.generateUniqueId()}`

    if (element.parentElement) {
        const parentElement = element.parentElement
        parentElement.setAttribute('id', uniqueId)
    }

    const swiperElementClassName = `#${uniqueId} .${String(element?.className)}`
    const swiperCarouselNextClassName = `#${uniqueId} .${PREFIX}-carousel-swiper--next`
    const swiperCarouselPrevClassName = `#${uniqueId} .${PREFIX}-carousel-swiper--prev`
    const swiperCarouselPaginationClassName = `#${uniqueId} .swiper-pagination`
    const swiperOptions: SwiperOptions = {
        spaceBetween: 16,
        slidesPerView: 1.5,
        slidesPerGroup: 1,
        loop: false,
        autoplay: {
            enabled: false,
            delay: 1000,
            disableOnInteraction: false,
        },
        pagination: {
            el: swiperCarouselPaginationClassName,
            clickable: true,
            dynamicBullets: true,
            dynamicMainBullets: 2,
        },
        navigation: {
            nextEl: swiperCarouselNextClassName,
            prevEl: swiperCarouselPrevClassName,
        },
        breakpoints: {
            375: {
                slidesPerView: 1.5,
                slidesPerGroup: 1,
            },
            768: {
                slidesPerView: 2.5,
                slidesPerGroup: 2,
            },
            1280: {
                slidesPerView: 4,
                slidesPerGroup: 4,
            },
        },
    }

    createSwiper(swiperElementClassName, swiperOptions as SwiperCreatorTypes)
}

export { FeaturedListImage }
