import React from 'react'
import { BannerImagePath } from '../../components/OfferCard/OfferCard.type'

/**
 * Interface for SortOption
 * @interface
 */
export interface SortOption {
    url: string
    selected: boolean
    title: string
}

/**
 * Interface for FacetTypes
 * @interface
 */
export enum FacetTypes {
    BREADCRUMB = 'breadcrumb',
    RADIO = 'radio',
    MULTISELECT = 'multiselect',
    RANGE = 'range',
    RATING = 'rating',
    COLOURPICKER = 'colourPicker',
    CUSTOM_PRICE = 'custom_price',
    CATEGORY_ID = 'category_ast',
    CUSTOM_RANGE = 'custom_range',
}

/**
 * Interface for Facet
 * @interface
 */

export interface Facet {
    type: FacetTypes
    collapsed?: boolean
    label: string
    id?: string // will be removed in the future sprint when implementing search for all filters
    clearLink?: string
    values?: (FacetRange | FacetValue)[]
    selected: boolean
    isTriangleOffer?: boolean
    current?: {
        label: string
        clearUrl: string
    }
    parent?: {
        label: string
        url: string
    }
    value?: string
    minParameter?: string
    maxParameter?: string
    uom?: string
    urlFormat?: string
    clearUrl?: string
    relatedFacet?: string
    url?: string
    lowerAvailable?: number | null
    upperAvailable?: number | null
    min?: number
    max?: number
    upperBound?: number | null
    lowerBound?: number | null
    minSelected?: string
    maxSelected?: string
}

/**
 * Interface  for FacetValue
 * @interface
 */
export interface FacetValue {
    label: string
    count?: number
    selected: boolean
    disabled?: boolean
    url?: string
    clearUrl?: string
    image?: string
    value?: string
    skuCodes?: string[]
    id?: number
    isUnavailable?: boolean
    name?: string
    brandImageData?: BannerImagePath
}

/**
 * Interface for FacetRange
 * @interface
 */
export interface FacetRange {
    url?: string
    type: string
    collapsed?: boolean
    label: string
    id?: string // will be removed in the future sprint when implementing search for all filters
    clearLink?: string
    values: FacetValue[]
    selected: boolean
    lowerAvailable: number | null
    upperAvailable: number | null
    min: number
    max: number
    upperBound: number | null
    lowerBound: number | null
    current?: {
        label: string
        clearUrl: string
    }
    parent?: {
        label: string
        url: string
    }
}

/**
 * interface for last Selected category
 */
export interface ParentCategory {
    label?: string
    url?: string
    clearUrl?: string
}

/**
 * Interface for FacetPanelModalProps
 * @interface
 */

export interface FacetPanelModalProps {
    isOpen: boolean
    closeFlyout: () => void
    closeBtnLabel: string
    title: string
    viewResultsLabel: string
    filtersLabel: string
    mSortByCTALabel?: string
    clearAllLabel: string
    sortLabel?: string
    refineByLabel?: string
    categoryLabel?: string
    showMoreThreshold?: number
    showMoreLabel?: string
    showLessLabel?: string
    resultCount: number
    sortings?: SortOption[]
    facets: Facet[]
    path?: string
    onSortChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
    selectedFacetType?: string
    selectedLabel?: string
    clearAllLink: string
    onFilterChange: (url, value, facetTitle, facetSelected, isChecked, type, id) => void
    currentLocale?: string
    minPriceErrorMessage?: string
    maxPriceErrorMessage?: string
    minPriceLabel?: string
    maxPriceLabel?: string
    isClientSideFilter?: boolean
    isTriangleOffer?: boolean
    enableFilterFacetsEnhancement?: boolean
    searchInputPlaceholderText?: string
    noSearchResults?: string
    categoryData?: FacetValue[]
    showCategoryDropdown?: boolean
    isCategoryPage?: boolean
    isPrerender?: boolean
    resultAvailable?: string
    suggestionsAvailableLabel?: string
    goCtaLabel?: string
    minLabel: string
    maxLabel: string
    applyCTALabel: string
    isSortedAlphabetically?: boolean
    ariaLabelApplyRangeFilterCTA?: string
    minValGreaterThanMaxValError?: string
    invalidMinAndMaxError?: string
    shopAllLabel?: string
    enableVerticalFilterToggle: boolean
    filterByAriaLabel?: string
    isDefaultFocus?: boolean
}
