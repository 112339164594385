import React, { useEffect, useState, useRef, useMemo } from 'react'
import PropTypes from 'prop-types'

import { InteractiveElementEvents, QuantitySelectorProps } from './QuantitySelector.types'
import { PREFIX } from '../config'
import { minInputVal, maxInputVal, incrementDecrementVal } from './quantitySelectorConstant'
import Icon from '../Icon'
import { getLanguage, replaceStrWithDynamicVal } from '../../utils'
import { checkDataLength } from '../../utils/checkDataLength'
import { inputFieldInvalidCharactersRegEx, numberRegEx } from '../../globalConstants/regexPatterns.constant'

/**
 * QuantitySelector component
 * @param {QuantitySelectorProps} props
 * @return {JSX.Element} returns Quantity selector component
 */
const QuantitySelector: React.FC<QuantitySelectorProps> = ({ ...props }): JSX.Element => {
    const {
        setQtySelectorInput,
        qtySelectorInput,
        defaultQty,
        maxQty,
        maxInputValue = maxInputVal,
        minInputValue = minInputVal,
        maximumQuantityError,
        path,
        quantityExceeded,
        disableErrors = false,
        disableErrorIcon = true,
        limitLabel = '',
        limitLabelShow = false,
        checkBannerMaxError,
        addToCartErrorData,
        disabled,
        id,
        limitMsg = '',
        disableMinQuantityReachedMessage = false,
        incrementDecrementValue = incrementDecrementVal,
        infoMessage,
        formatQuantity = false,
        a11yUpdatedQuantityText,
        setInteractiveElementEvent,
    } = props

    const inputRef = useRef(null)
    const [minQuantityReached, setMinQuantityReached] = useState(false)
    const language = getLanguage()
    const isAddErrorClass = useMemo(() => {
        return quantityExceeded || minQuantityReached
    }, [minQuantityReached, quantityExceeded])
    const errorClass = isAddErrorClass ? `${PREFIX}-qty-selector--error` : ''

    useEffect(() => {
        const inputEle = inputRef.current as HTMLElement

        if (formatQuantity) {
            inputEle.setAttribute('pattern', numberRegEx)
        } else {
            const preventNonNumericKeys = (event: KeyboardEvent): void => {
                if (invalidChars.indexOf(event.key) >= 0) {
                    event.preventDefault()
                }
            }

            const invalidChars = ['-', '+', 'e', 'E', '.']
            inputEle.addEventListener('keydown', preventNonNumericKeys)

            return () => {
                inputEle.removeEventListener('keydown', preventNonNumericKeys)
            }
        }
        return undefined
    }, [formatQuantity])

    /**
     * Function called on input change of qty selector
     * @param {React.ChangeEvent<HTMLInputElement>} val event param of change
     * @return {void}
     * if value is less than minVal or more than Max value, simply return the inputMaxRender(), otherwise change input value
     *
     */
    // eslint-disable-next-line consistent-return
    const qtySelectorInputOnChange = (val: React.ChangeEvent<HTMLInputElement>): void => {
        const strValue = val.target.value.replace(inputFieldInvalidCharactersRegEx, '')
        const updatedVal = isNaN(Number(strValue)) ? qtySelectorInput : Number(strValue)

        val.target.value = String(updatedVal)
        if (updatedVal > maxInputValue) {
            return inputMaxRender(true)
        }
        inputMaxRender(false)
        setQtySelectorInput(updatedVal, true, inputRef)
        setInteractiveElementEvent && setInteractiveElementEvent(InteractiveElementEvents.CHANGE)
    }

    /**
     * Function called on input blur of qty selector
     * @param {React.ChangeEvent<HTMLInputElement>} val event param of change
     * @return {void}
     * if value is invalid / not a number, set input back to default qty
     */
    const onInputBlur = (val: React.ChangeEvent<HTMLInputElement>): void => {
        const strValue = val.target.value.replace(inputFieldInvalidCharactersRegEx, '')
        const updatedVal = isNaN(Number(strValue)) ? qtySelectorInput : Number(strValue)
        val.target.value = formatQuantity ? updatedVal.toLocaleString(language) : String(updatedVal)

        if (isNaN(updatedVal) || updatedVal < minInputValue) {
            setQtySelectorInput(defaultQty)
        }
        setInteractiveElementEvent && setInteractiveElementEvent(InteractiveElementEvents.BLUR)
    }

    /**
     * @method inputMaxRender  : Method  used when button clicked post maxInputValue
     *  @param {boolean} value : act as toggle when plus or Minus button is clicked
     *
     */

    const inputMaxRender = (value: boolean) => {
        if (checkBannerMaxError) {
            checkBannerMaxError(value)
        }
    }

    /**
     *  Function called on click of - button
     * @return {void}
     * decrease input value with incrementDecrementValue(1) until minValue
     */
    const decrementVal = (): void => {
        if (qtySelectorInput > minInputValue) {
            setQtySelectorInput(qtySelectorInput - incrementDecrementValue)
            inputMaxRender(false)
        } else {
            setMinQuantityReached(true)
        }
        setInteractiveElementEvent && setInteractiveElementEvent(InteractiveElementEvents.MINUS_BUTTON)
    }

    /**
     * Function called on click of + button
     * @return {void}
     * increase input value with incrementDecrementValue(1) until maxValue
     */
    const incrementVal = (): void => {
        if (qtySelectorInput < maxInputValue) {
            setQtySelectorInput(qtySelectorInput + incrementDecrementValue)
        } else {
            inputMaxRender(true)
        }
        setInteractiveElementEvent && setInteractiveElementEvent(InteractiveElementEvents.PLUS_BUTTON)
    }

    // Method used to conditionally render the Limit to text
    const limitMessageDisplay = (): JSX.Element => {
        if (!quantityExceeded && limitLabel?.length && limitLabelShow) {
            return (
                <div className={`${PREFIX}-qty-error`}>
                    <p className={`${PREFIX}-qty-error__message`} data-testid="limitLabel">
                        {limitLabel}
                    </p>
                </div>
            )
        }
        return null
    }

    // Method used to conditionally render the Quantity Exceeded message
    const quantityExceededMessageDisplay = (): JSX.Element => {
        if (quantityExceeded) {
            return (
                <div className={`${PREFIX}-qty-error`} role="alert" aria-atomic="true">
                    {!disableErrorIcon && <Icon type="ct-warning" size="md" path={path}></Icon>}
                    <p className={`${PREFIX}-qty-error__message`}>
                        {replaceStrWithDynamicVal(maximumQuantityError, maxQty)}
                    </p>
                </div>
            )
        }
        return null
    }

    // Method used to conditionally render the Add to Cart Error message
    const addToCartErrorDataMessageDisplay = (): JSX.Element => {
        if (addToCartErrorData) {
            return (
                <div className={`${PREFIX}-qty-error`} role="alert" aria-atomic="true">
                    <Icon type="ct-warning" size="md" path={path}></Icon>
                    <p className={`${PREFIX}-qty-error__message`}>
                        {replaceStrWithDynamicVal(maximumQuantityError, addToCartErrorData)}
                    </p>
                </div>
            )
        }
        return null
    }

    /**
     * Renders inline message at the bottom of Qty selector
     * @param {string} message
     * @param {boolean} information
     * @return {JSX.Element}
     */
    const showInlineMessage = (message: string, information?: boolean): JSX.Element => {
        const infoMessageClassname = information ? `${PREFIX}-qty-info` : ''
        if (checkDataLength(message)) {
            return (
                <div className={`${PREFIX}-qty-error ${infoMessageClassname}`} role="alert" aria-atomic="true">
                    <Icon type={information ? 'ct-information-blue-outline' : 'ct-warning'} size="md" path={path} />
                    <p className={`${PREFIX}-qty-error__message`}>{message}</p>
                </div>
            )
        }
        return null
    }

    // Method used to conditionally render the Limit to text
    const minQuantityReachedMessageDisplay = (): JSX.Element => {
        if (minQuantityReached && qtySelectorInput !== minInputValue) {
            setMinQuantityReached(false)
        }

        if (minQuantityReached) {
            return (
                <div className={`${PREFIX}-qty-error`} role="alert" aria-atomic="true">
                    {!disableErrorIcon && <Icon type="ct-warning" size="md" path={path}></Icon>}
                    <p className={`${PREFIX}-qty-error__message`}>
                        {replaceStrWithDynamicVal(props.a11yMinQuantityReached, qtySelectorInput)}
                    </p>
                </div>
            )
        }
        return null
    }

    const inputValue = formatQuantity ? qtySelectorInput.toLocaleString(language) : qtySelectorInput

    return (
        <>
            <span className={`${PREFIX}-qty-selector ${errorClass}`} data-qm-allow="true">
                <button
                    aria-label={props.a11yQuantityDecrementLabel}
                    className={`${PREFIX}-qty-selector__minus-btn`}
                    onClick={decrementVal}
                    disabled={disabled}>
                    <Icon type="ct-subtract" size="sm" path={path}></Icon>
                </button>
                <input
                    aria-label={props.a11yQuantityInputAriaLabel}
                    className={`${PREFIX}-qty-selector__text-input`}
                    id={id}
                    data-testid={id}
                    max={maxInputValue}
                    min={minInputValue}
                    onBlur={onInputBlur}
                    onChange={qtySelectorInputOnChange}
                    ref={inputRef}
                    type={formatQuantity ? 'text' : 'number'}
                    disabled={disabled}
                    value={inputValue}></input>
                <button
                    aria-label={props.a11yQuantityIncrementLabel}
                    className={`${PREFIX}-qty-selector__plus-btn`}
                    onClick={incrementVal}
                    aria-disabled={disabled}
                    disabled={disabled}>
                    <Icon type="ct-plus" size="sm" path={path}></Icon>
                </button>
            </span>
            <div id={`quantity-update-${id}`} className="sr-only" role="status" aria-live="polite">
                {replaceStrWithDynamicVal(a11yUpdatedQuantityText, qtySelectorInput)}
            </div>
            {limitMsg && limitMsg.length && <span className={`${PREFIX}-qty-selector__pdp-label`}>{limitMsg}</span>}
            {!disableErrors && (
                <>
                    {limitMessageDisplay()}
                    {quantityExceededMessageDisplay()}
                    {addToCartErrorDataMessageDisplay()}
                    {!disableMinQuantityReachedMessage && minQuantityReachedMessageDisplay()}
                    {infoMessage && showInlineMessage(infoMessage, true)}
                </>
            )}
        </>
    )
}

QuantitySelector.defaultProps = {
    disabled: false,
    id: 'inputBox',
}

QuantitySelector.propTypes = {
    setQtySelectorInput: PropTypes.func,
    qtySelectorInput: PropTypes.number,
    defaultQty: PropTypes.number,
    maxQty: PropTypes.number,
    maxInputValue: PropTypes.number,
    maximumQuantityError: PropTypes.string,
    path: PropTypes.string,
    quantityExceeded: PropTypes.bool,
    disableErrors: PropTypes.bool,
    disableErrorIcon: PropTypes.bool,
    limitLabel: PropTypes.string,
    limitLabelShow: PropTypes.bool,
    checkBannerMaxError: PropTypes.func,
    addToCartErrorData: PropTypes.string,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    a11yQuantityDecrementLabel: PropTypes.string.isRequired,
    a11yQuantityIncrementLabel: PropTypes.string.isRequired,
    a11yQuantityInputAriaLabel: PropTypes.string,
    a11yQuantityLabel: PropTypes.string.isRequired,
    a11yMinQuantityReached: PropTypes.string,
    limitMsg: PropTypes.string,
    disableMinQuantityReachedMessage: PropTypes.bool,
}

export default QuantitySelector
