import React, { useState, useEffect } from 'react'

import ProductImageComponent from '../ProductCartItem/ProductImageComponent'
import Checkbox from '../Checkbox'
import QuantitySelector from '../QuantitySelector'
import Icon from '../Icon'
import { PREFIX } from '../config'
import { getFormattedPrice } from '../../utils/getPriceRangePill'
import { AutomotiveAddonProps, OptionsOnCartItem } from '../ProductCartItem/ProductItemInfo.type'
import { maxInputVal } from '../QuantitySelector/quantitySelectorConstant'
import RenderQuantity from '../../utils/renderQuantity'
import { getBuyItAgainCta } from './AutomotiveProductCards.helper'
import { renderWarningToast, showWishListIcon } from '../ProductCartItem/ProductCartItem.helper'
import usePrevious from '../../hooks/usePrevious.hook'

// eslint-disable-next-line complexity, max-lines-per-function, sonar/cyclomatic-complexity
const AutomotiveAddonCard = (props: AutomotiveAddonProps): JSX.Element => {
    const {
        brand,
        url,
        images,
        name,
        sku,
        price,
        dpBuyAgainLabel,
        isDetailPage,
        productData,
        serviceAdded,
        checkoutButtonClick,
        warningToastProps,
        setErrorInfoHandler,
        errorAnalyticsForInlineToast,
    } = props
    const [checked, setChecked] = useState(true)
    const infoClass = `${PREFIX}-shopping-cart__product`
    const cartClass = `${PREFIX}-shopping-cart__item`
    const automotiveClass = `${PREFIX}-automotive-card`
    const [quantity, setQuantity] = useState(props.quantity)
    const [inlineToastError, setInlineToastError] = useState(false)
    const itemAvailabilityInfo = productData?.itemAvailabilityInfo

    const quantityChangeHandler = (incrementValue: number) => {
        setQuantity(incrementValue)
    }

    const checkAvailableQuantity = (): number => {
        const { orderableFromDC } = productData?.fulfillment || {}
        const { dcQuantity, storeQuantity } = productData?.fulfillment?.stockItemAvailability || {}
        if (orderableFromDC && dcQuantity) {
            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            return dcQuantity + storeQuantity
        }
        return storeQuantity
    }

    const {
        errorMessage: itemAvailabilityErrMsg,
        showInlineError: itemAvailabilityShowInlineError,
        hardStopError: itemAvailabilityShowHardStopError,
        optionsToBeDisplayed: itemAvailabilityOptionsToBeDisplayed,
    } = itemAvailabilityInfo || {}

    const previousItemAvailabilityErrMsg = usePrevious(itemAvailabilityErrMsg)

    useEffect(() => {
        setInlineToastError(!!itemAvailabilityErrMsg && checkoutButtonClick)
    }, [itemAvailabilityErrMsg, checkoutButtonClick])

    /**
     * use effect to trigger error analytics on load when there is an inline errormessage.
     */
    useEffect(() => {
        if (itemAvailabilityErrMsg && itemAvailabilityErrMsg !== previousItemAvailabilityErrMsg) {
            itemAvailabilityShowInlineError &&
                !!errorAnalyticsForInlineToast &&
                errorAnalyticsForInlineToast(itemAvailabilityErrMsg)
            !!itemAvailabilityShowHardStopError && setErrorInfoHandler?.(itemAvailabilityShowInlineError, false) // run this only when the error is hard stop error. so that user cant be allowed to proceed to checkout.
            itemAvailabilityShowInlineError &&
                !!itemAvailabilityOptionsToBeDisplayed.includes(OptionsOnCartItem.UPDATED_TEXT) &&
                setErrorInfoHandler?.(false, true) // run this when eta changed
        }
    }, [
        itemAvailabilityErrMsg,
        itemAvailabilityShowInlineError,
        itemAvailabilityShowHardStopError,
        itemAvailabilityOptionsToBeDisplayed,
        setErrorInfoHandler,
        errorAnalyticsForInlineToast,
        previousItemAvailabilityErrMsg,
    ])

    /**
     * function for checkbox change handler
     * @param {boolean}isChecked
     */
    const checkboxChanged = (isChecked: boolean): void => {
        setChecked(!isChecked)
        !isChecked ? props.selectService(sku) : props.removeService(sku)
    }

    /**
     * Determine if warning toast should be displayed
     * @return {boolean}
     */
    const showWarningToast = (): boolean => {
        return quantity > checkAvailableQuantity() && checkAvailableQuantity() < maxInputVal
    }

    const renderProductInfo = (): JSX.Element => {
        return (
            <div className={infoClass}>
                <div className={`${infoClass}__brand`}>{brand?.label}</div>
                <div className={`${infoClass}__title`}>
                    <a href={url} className={`${infoClass}__title-link`} data-link-value={name}>
                        {name}
                    </a>
                </div>
                {serviceAdded && <RenderQuantity qtyAbbrLabel={props.qtyAbbrLabel} quantity={quantity} />}
            </div>
        )
    }

    /**
     * renders buttons to add to wishlist and to delete product
     * @return { JSX.Element }
     */
    const renderButtons = () => {
        return (
            <div className={`${cartClass}__icon`}>
                {showWishListIcon(
                    itemAvailabilityInfo?.showInlineError,
                    itemAvailabilityInfo?.optionsToBeDisplayed,
                ) && (
                    <button
                        className={`${cartClass}__icon--wishlist`}
                        aria-label={props.a11yWishlistIcon}
                        data-testid="cartWishlistIcon"
                        onClick={() => props.addItemToWishlist(productData)}>
                        <Icon type="ct-favorite-inactive" size="lg" />
                    </button>
                )}
                <button
                    className={`${cartClass}__icon--close`}
                    data-testid="cartCloseIcon"
                    onClick={() => props.onRemoveCartItem(false, productData)}
                    aria-label={props.a11yRemoveLabel}>
                    <Icon type="ct-close" size="lg" />
                </button>
            </div>
        )
    }

    return (
        <div className={`${automotiveClass}__item-addon`}>
            <div
                className={`${PREFIX}-auto-wrapper ${
                    inlineToastError && itemAvailabilityInfo?.hardStopError ? `${PREFIX}-hard-stop-wrapper` : ''
                }`}>
                {(showWarningToast() || !!itemAvailabilityInfo?.showInlineError) &&
                    renderWarningToast(warningToastProps, itemAvailabilityInfo, productData)}
                <div className={`${cartClass} ${automotiveClass}__item`}>
                    <ProductImageComponent itemAvailabilityInfo={itemAvailabilityInfo} images={images} name={name} />
                    <div
                        className={`${cartClass}__text-container ${
                            !serviceAdded ? `${PREFIX}-col-md-8 ${PREFIX}-col-sm-8` : ''
                        }`}>
                        <div
                            className={`${cartClass}__product-item-wrapper ${
                                !serviceAdded ? `${PREFIX}-justify-content` : ''
                            }`}>
                            {renderProductInfo()}
                            {(!itemAvailabilityInfo?.showInlineError ||
                                itemAvailabilityInfo?.optionsToBeDisplayed.includes(
                                    OptionsOnCartItem.PRICE_AND_QUANTITY,
                                )) && (
                                <div className={`${automotiveClass}__price-quantity`}>
                                    <div className={`${cartClass}__quantity`}>
                                        {serviceAdded && !showWarningToast() && (
                                            <QuantitySelector
                                                setQtySelectorInput={quantityChangeHandler}
                                                qtySelectorInput={1}
                                                defaultQty={1}
                                                maxQty={1}
                                                disabled={true}
                                                a11yQuantityLabel={props.accessibilityGlobalProps.a11yQuantityLabel}
                                                a11yQuantityDecrementLabel={
                                                    props.accessibilityGlobalProps.a11yQuantityDecrementLabel
                                                }
                                                a11yQuantityIncrementLabel={
                                                    props.accessibilityGlobalProps.a11yQuantityIncrementLabel
                                                }
                                                a11yMinQuantityReached={
                                                    props.accessibilityGlobalProps.a11yMinQuantityReached
                                                }
                                                a11yQuantityInputAriaLabel={
                                                    props.accessibilityGlobalProps.a11yQuantityInputAriaLabel
                                                }
                                                id={props.quantitySelectorId}
                                            />
                                        )}
                                    </div>
                                    <div
                                        className={`${cartClass}__price ${
                                            !serviceAdded ? `${PREFIX}-margin-top` : ''
                                        }`}>
                                        <div className={`${PREFIX}-price--total`}>
                                            {getFormattedPrice(price, props.language)}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        {!serviceAdded && (
                            <Checkbox
                                id={sku}
                                onChange={() => {
                                    checkboxChanged(checked)
                                }}
                                label={''}
                                checked={checked}
                            />
                        )}
                        {serviceAdded && renderButtons()}
                        {isDetailPage && getBuyItAgainCta(dpBuyAgainLabel, url)}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AutomotiveAddonCard
