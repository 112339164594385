import { stringToBooleanMap } from '@nl/lib'

import { productSelector } from '../../redux/selectors/product.selectors'
import {
    AutomotiveBuyBoxProps,
    WeatherTechApplicableBrandAndCategoryProps,
} from './AutomotiveBuyBox/AutomotiveBuyBox.type'
import { FitmentSelectionsTypes } from './FitmentSelectionsTypes'
import { ProductResponseData } from '../../redux/models/product.interface'
import { tiresDataSelector } from '../../redux/selectors/vehicleTires.selectors'
import { VehicleFitType } from '../../globalConstants'
import { useAppSelector } from '../../hooks/react-redux.hook'

/**
 * @description Returns Enum value with type of fitment message for current product
 * @param {string} vehicleFitType
 * @param {AutomotiveBuyBoxProps} props
 * @returns {FitmentSelectionsTypes} FitmentSelectionsTypes
 */
export const useIsFitmentSelectionsType = (
    vehicleFitType: string,
    props: AutomotiveBuyBoxProps,
): FitmentSelectionsTypes => {
    // eslint-disable-next-line sonar/no-dead-store
    let type = FitmentSelectionsTypes.GENERAL
    const { productData } = useAppSelector(productSelector)
    const tireSizeData = useAppSelector(tiresDataSelector)

    switch (true) {
        case stringToBooleanMap[props.showWeatherTechFitmentMessage] &&
            isWeatherTechApplicableBrandAndCategory(props, productData):
            type =
                vehicleFitType === VehicleFitType.Fit
                    ? FitmentSelectionsTypes.BY_WEATHER_TECH_FIT
                    : FitmentSelectionsTypes.BY_WEATHER_TECH_NOTFIT
            break
        case Boolean(tireSizeData):
            type = FitmentSelectionsTypes.BY_TIRE_SIZE
            break
        default:
            type = FitmentSelectionsTypes.BY_VEHICLE
    }

    return type
}

const commaSplit = (line?: string) => line?.split(',') || []

export const isWeatherTechApplicableBrandAndCategory = <PropsType extends WeatherTechApplicableBrandAndCategoryProps>(
    props: PropsType,
    productData?: ProductResponseData,
): boolean => {
    const { showWeatherTechVehicleSelectorForBrands, showWeatherTechVehicleSelectorForCategoriesIds } = props
    const allowedBrandList = commaSplit(showWeatherTechVehicleSelectorForBrands?.toLowerCase?.() || '').filter(Boolean)
    const allowedCategoryIdsList = commaSplit(showWeatherTechVehicleSelectorForCategoriesIds?.toLowerCase?.()).filter(
        Boolean,
    )
    const breadcrumbCategoryIdsList =
        productData?.breadcrumbList?.map(({ categoryId }) => categoryId?.toLowerCase?.()).filter(Boolean) || []

    const brandLabel = productData?.brand?.label?.toLowerCase?.() || ''
    return Boolean(
        allowedBrandList.indexOf(brandLabel) > -1 &&
            allowedCategoryIdsList.find(catId => breadcrumbCategoryIdsList.indexOf(catId) > -1),
    )
}
