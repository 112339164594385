import { BREAKPOINTS } from '../components/config'

const LARGE_DESKTOP_PRODUCT_PER_ROW = 5
const STANDART_DESKTOP_PRODUCT_PER_ROW = 6
const MIN_DESKTOP_PRODUCT_PER_ROW = 5
const PORTRAIT_TABLET_PRODUCT_PER_ROW = 4
const LANDSCAPE_MOBILE_PRODUCT_PER_ROW = 3
const DEFAULT_PRODUCT_PER_ROW = 2

/**
 * @description Calculates product per row in product grid according to current device width.
 * See packages/theming/src/scss/variables/_gridView.scss for additional details
 * @returns {number} product per row
 */
// eslint-disable-next-line no-magic-numbers
export const getProductPerRowCount = (): 2 | 3 | 4 | 5 | 6 => {
    switch (true) {
        case window.innerWidth >= BREAKPOINTS.desktopLarg:
            return LARGE_DESKTOP_PRODUCT_PER_ROW
        case window.innerWidth > BREAKPOINTS.desktopStandard:
            return STANDART_DESKTOP_PRODUCT_PER_ROW
        case window.innerWidth >= BREAKPOINTS.desktopMin:
            return MIN_DESKTOP_PRODUCT_PER_ROW
        case window.innerWidth >= BREAKPOINTS.tabletPortrait:
            return PORTRAIT_TABLET_PRODUCT_PER_ROW
        case window.innerWidth >= BREAKPOINTS.mobileLandscape:
            return LANDSCAPE_MOBILE_PRODUCT_PER_ROW
        default:
            return DEFAULT_PRODUCT_PER_ROW // Return a default value assuming mobile
    }
}
