import { Price, PriceMessage } from '../Price/Price.types'
import { Specification } from '../ProductReusableCard/product.types'
import { ProductSku } from '../VariantsWrapper/VariantsWrapper.type'
import { minimumVariantsLength, setOfFourCount, setOfTwoCount } from './VehicleProduct.constant'
import { isArrayNotEmpty } from '../../utils/isArrayNotEmpty'
import { magicNumber } from '../../utils/magicNumber'
import { RebateData } from '../Rebate/Rebate.type'
import { combineBadges } from '../../utils/badgeUtil'
import { filterSpecification } from '../ProductCartItem/ProductCartItem.helper'
import { checkDataLength } from '../../utils/checkDataLength'

/**
 * function return specification list with label and its value
 * @param {Specification[]} specifications
 * @return {string}
 */
export const getSpecifications = (specifications: Specification[]): string => {
    let listSpecifications = ''
    if (specifications?.length) {
        specifications.forEach(specification => {
            listSpecifications += `<span><b>${specification?.label}</b>: ${specification?.value}</span>`
        })
    }

    return listSpecifications
}

/**
 * function to check minPrice or value
 * @param {Price} priceValue
 * @param {number} quantity
 * @return {number}
 */
const checkMinPriceAndValue = (priceValue: Price, quantity?: number): number | null => {
    const initialPriceVal = {
        value: 0,
        minPrice: 0,
        maxPrice: 0,
    }
    const { value, minPrice, maxPrice } = !!priceValue ? priceValue : initialPriceVal
    if (value) {
        return value * (quantity ? quantity : setOfTwoCount)
    } else if (minPrice) {
        return minPrice * (quantity ? quantity : setOfTwoCount)
    } else if (maxPrice) {
        return maxPrice * (quantity ? quantity : setOfTwoCount)
    } else {
        return null
    }
}

/**
 * function to check set of 4 price for staggered condition
 * @param {ProductSku[]} skus
 * @param {boolean} isStaggeredState
 * @param {number} frontQuantity
 * @param {number} rearQuantity
 * @return {number}
 */
export const getSetOfFourPriceForStaggered = (
    skus: ProductSku[],
    isStaggeredState?: boolean,
    frontQuantity?: number,
    rearQuantity?: number,
): number => {
    if (isStaggeredState) {
        // eslint-disable-next-line no-magic-numbers
        const isFullStaggered = skus.length === 2
        const frontTirePrice = isArrayNotEmpty(skus) && checkMinPriceAndValue(skus[0].currentPrice, frontQuantity)
        const rearTirePrice =
            isArrayNotEmpty(skus) && isFullStaggered && checkMinPriceAndValue(skus[1].currentPrice, rearQuantity)
        return isFullStaggered ? frontTirePrice + rearTirePrice : frontTirePrice
    } else {
        return 0
    }
}

/**
 * function to check set of 4 price for non-staggered condition
 * @param {ProductSku} skus
 * @param {boolean} isStaggeredState
 * @return {number}
 */
const getSetOfFourPriceForNonStaggered = (skus: ProductSku[], isStaggeredState?: boolean): number => {
    if (!isStaggeredState) {
        return isArrayNotEmpty(skus) && checkMinPriceAndValue(skus[0].currentPrice) * setOfTwoCount
    } else {
        return 0
    }
}

/**
 * function return setOfFour price value
 * @param {ProductSku[]} skus
 * @param {Price} currentPrice
 * @param {boolean} isStaggeredState
 * @param {boolean} vehicleInformationPresent
 * @param {boolean} tireInformationPresent
 * @return {Price}
 */
export const totalCurrentPrice = (
    skus: ProductSku[],
    currentPrice: Price,
    isStaggeredState?: boolean,
    vehicleInformationPresent?: boolean,
    tireInformationPresent?: boolean,
): Price => {
    const productPrice = {
        // eslint-disable-next-line no-unsafe-optional-chaining
        minPrice: currentPrice?.minPrice * setOfFourCount,
        // eslint-disable-next-line no-unsafe-optional-chaining
        maxPrice: currentPrice?.maxPrice * setOfFourCount,
        // eslint-disable-next-line no-unsafe-optional-chaining
        value: currentPrice?.value * setOfFourCount,
    }
    const staggeredPrice = {
        minPrice: getSetOfFourPriceForStaggered(skus, isStaggeredState),
        maxPrice: getSetOfFourPriceForStaggered(skus, isStaggeredState),
        value: getSetOfFourPriceForStaggered(skus, isStaggeredState),
    }
    const skuPrices = {
        minPrice: getSetOfFourPriceForNonStaggered(skus, isStaggeredState),
        maxPrice: getSetOfFourPriceForNonStaggered(skus, isStaggeredState),
        value: getSetOfFourPriceForNonStaggered(skus, isStaggeredState),
    }
    const productOrSkuPrice =
        checkVehicleOrTirePresent(vehicleInformationPresent, tireInformationPresent) && isArrayNotEmpty(skus)
            ? skuPrices
            : productPrice
    return isStaggeredState ? staggeredPrice : productOrSkuPrice
}

const vehicleContextTotalPrice = (skus: ProductSku[]): Price => {
    return isArrayNotEmpty(skus) && skus[0].totalCurrentPrice
}

/**
 * Returns set of 4 price value for PLP page
 *
 * @param {ProductSku[]} skus
 * @param {Price} totalSetOf4Price set of 4 price (without vehicle context)
 * @param {boolean} isStaggeredState
 * @param {boolean} vehicleInformationPresent
 * @param {boolean} tireInformationPresent
 * @return {Price}
 */
export const setOf4Price = (
    skus: ProductSku[],
    totalSetOf4Price: Price,
    isStaggeredState?: boolean,
    vehicleInformationPresent?: boolean,
    tireInformationPresent?: boolean,
): Price => {
    const notStaggeredPrice =
        checkVehicleOrTirePresent(vehicleInformationPresent, tireInformationPresent) && isArrayNotEmpty(skus)
            ? vehicleContextTotalPrice(skus)
            : totalSetOf4Price
    return isStaggeredState
        ? {
              minPrice: getSetOfFourPriceForStaggered(skus, isStaggeredState),
              maxPrice: getSetOfFourPriceForStaggered(skus, isStaggeredState),
              value: getSetOfFourPriceForStaggered(skus, isStaggeredState),
          }
        : notStaggeredPrice
}

/**
 * function return per tire  or rear label
 * @param {boolean} staggeredState
 * @param {string} perTireLabel
 * @param {string} vehicleRearLabel
 * @return {string}
 */
export const vehicleRearLabelValue = (
    staggeredState: boolean,
    perTireLabel: string,
    vehicleRearLabel: string,
): string => {
    return staggeredState ? vehicleRearLabel : perTireLabel
}

/**
 * function return specificiton label and its value for rear tire
 * @param {ProductSku[]} skus
 * @param {Specification[]} specification
 * @param {boolean} staggeredState
 * @param {string} productCardSpecifications
 * @return {string}
 */
export const rearTireSpecification = (
    skus: ProductSku[],
    specification?: Specification[],
    staggeredState?: boolean,
    productCardSpecifications?: string,
): string => {
    const specifications = filterSpecification(
        staggeredState ? skus?.[minimumVariantsLength]?.specifications : specification,
        productCardSpecifications,
    )
    return getSpecifications(specifications)
}

/**
 * function return pcode or sku code based on staggered condition
 * @param {ProductSku[]} skus
 * @param {string} code
 * @param {boolean} staggeredState
 * @param {boolean} vehicleInformationPresent
 * @param {boolean} tireInformationPresent
 * @return {string}
 */
export const rearTireCode = (
    skus: ProductSku[],
    code: string,
    staggeredState?: boolean,
    vehicleInformationPresent?: boolean,
    tireInformationPresent?: boolean,
): string => {
    const productCode =
        checkVehicleOrTirePresent(vehicleInformationPresent, tireInformationPresent) && isArrayNotEmpty(skus)
            ? skus[0].formattedCode
            : code
    return staggeredState ? isArrayNotEmpty(skus) && skus?.[minimumVariantsLength].formattedCode : productCode
}

/**
 * function return rear tire price based on staggered condition
 * @param {ProductSku[]} skus
 * @param {Price} currentPrice
 * @param {boolean} staggeredState
 * @param {boolean} vehicleInformationPresent
 * @param {boolean} tireInformationPresent
 * @return {Price}
 */
export const rearTireCurrentPrice = (
    skus: ProductSku[],
    currentPrice: Price,
    staggeredState?: boolean,
    vehicleInformationPresent?: boolean,
    tireInformationPresent?: boolean,
): Price => {
    // eslint-disable-next-line sonar/expression-complexity
    return staggeredState
        ? isArrayNotEmpty(skus) && skus?.[minimumVariantsLength].currentPrice
        : // eslint-disable-next-line sonar/no-nested-conditional
        checkVehicleOrTirePresent(vehicleInformationPresent, tireInformationPresent) && isArrayNotEmpty(skus)
        ? skus[0].currentPrice
        : // eslint-disable-next-line sonar/no-nested-conditional
        checkDataLength(currentPrice)
        ? currentPrice
        : {
              value: null,
              minPrice: null,
              maxPrice: null,
          }
}

/**
 * function return specificiton label and its value for front tire
 * @param {ProductSku[]} skus
 * @param {boolean} staggeredState
 * @param {string} productCardSpecifications
 * @return {string}
 * @param {Specification[]} productSpecifications
 */
export const frontTireSpecification = (
    skus: ProductSku[],
    staggeredState?: boolean,
    productCardSpecifications?: string,
    productSpecifications?: Specification[],
): string => {
    let specificationValueFront = []
    specificationValueFront = skus?.[magicNumber.ZERO]?.specifications
    const skuSpecificationForFront = specificationValueFront?.slice()
    if (isArrayNotEmpty(productSpecifications)) {
        skuSpecificationForFront.push(
            ...productSpecifications.filter(
                fromSpec =>
                    !skuSpecificationForFront.find(
                        (finaSpec: Record<string, unknown>) => finaSpec.code === fromSpec.code,
                    ),
            ),
        )
    }
    const specifications = filterSpecification(skuSpecificationForFront, productCardSpecifications)
    return staggeredState && getSpecifications(specifications)
}

/**
 * function return rear tire display label based on staggered condition
 * @param {ProductSku[]} skus
 * @param {boolean} displayWasLabel
 * @param {boolean} staggeredState
 * @param {boolean} vehicleInformationPresent
 * @param {boolean} tireInformationPresent
 * @return {Price}
 */
export const rearTireDisplayWasLabel = (
    skus: ProductSku[],
    displayWasLabel: boolean,
    staggeredState?: boolean,
    vehicleInformationPresent?: boolean,
    tireInformationPresent?: boolean,
): boolean => {
    const notStaggeredWasLabel =
        checkVehicleOrTirePresent(vehicleInformationPresent, tireInformationPresent) && isArrayNotEmpty(skus)
            ? skus[0].displayWasLabel
            : displayWasLabel
    return staggeredState
        ? isArrayNotEmpty(skus) && skus?.[minimumVariantsLength].displayWasLabel
        : notStaggeredWasLabel
}

/**
 * function return rear tire price based on staggered condition
 * @param {ProductSku[]} skus
 * @param {Price} originalPrice
 * @param {boolean} staggeredState
 * @param {boolean} vehicleInformationPresent
 * @param {boolean} tireInformationPresent
 * @return {Price}
 */
export const rearTireOriginalPrice = (
    skus: ProductSku[],
    originalPrice: Price,
    staggeredState?: boolean,
    vehicleInformationPresent?: boolean,
    tireInformationPresent?: boolean,
): Price => {
    // eslint-disable-next-line sonar/expression-complexity
    return staggeredState
        ? isArrayNotEmpty(skus) && skus?.[minimumVariantsLength].originalPrice
        : // eslint-disable-next-line sonar/no-nested-conditional
        checkVehicleOrTirePresent(vehicleInformationPresent, tireInformationPresent) && isArrayNotEmpty(skus)
        ? skus[0].originalPrice
        : // eslint-disable-next-line sonar/no-nested-conditional
        checkDataLength(originalPrice)
        ? originalPrice
        : {
              value: null,
              minPrice: null,
              maxPrice: null,
          }
}

/**
 * function to get TRF price for staggered condition on sku[0] and sku[1]
 * @param {ProductSku[]} skus
 * @param {number} position
 * @return {number}
 */
const getSKUFeeValue = (skus: ProductSku[], position: number): number => {
    // eslint-disable-next-line no-unsafe-optional-chaining
    return isArrayNotEmpty(skus) && skus[position]?.feeValue ? skus[position]?.feeValue * setOfTwoCount : 0
}

/**
 * function return TRF price for staggered condition
 * @param {ProductSku[]} skus
 * @param {boolean} staggeredState
 * @return {number}
 */

const staggeredFeeValue = (skus: ProductSku[], isStaggeredState?: boolean): number => {
    if (isStaggeredState && isArrayNotEmpty(skus)) {
        const frontFeeValue = getSKUFeeValue(skus, 0)
        const rearFeeValue = getSKUFeeValue(skus, magicNumber.ONE)
        return frontFeeValue + rearFeeValue
    } else {
        return 0
    }
}

// function to check vehicleInformationPresent and tireInformationPresent present or not
const checkVehicleOrTirePresent = (vehicleInformationPresent?: boolean, tireInformationPresent?: boolean): boolean => {
    return vehicleInformationPresent || tireInformationPresent
}

/**
 * function return TRF price based on staggered condition
 * @param {ProductSku[]} skus
 * @param {number} value
 * @param {boolean} staggeredState
 * @param {boolean} vehicleInformationPresent
 * @param {boolean} tireInformationPresent
 * @return {number}
 */
export const getFeeValue = (
    skus: ProductSku[],
    value?: number,
    staggeredState?: boolean,
    vehicleInformationPresent?: boolean,
    tireInformationPresent?: boolean,
): number => {
    const notStaggeredFeeValue = checkVehicleOrTirePresent(vehicleInformationPresent, tireInformationPresent)
        ? getSKUFeeValue(skus, 0) * setOfTwoCount
        : value * setOfFourCount
    return staggeredState ? staggeredFeeValue(skus, staggeredState) : notStaggeredFeeValue
}

/**
 * function return rear tire priceMessage based on staggered condition
 * @param {ProductSku[]} skus
 * @param {PriceMessage} priceMessage
 * @param {boolean} staggeredState
 * @param {boolean} vehicleInformationPresent
 * @param {boolean} tireInformationPresent
 * @return {PriceMessage[]}
 */
export const rearPriceMessage = (
    skus: ProductSku[],
    priceMessage: PriceMessage[],
    staggeredState?: boolean,
    vehicleInformationPresent?: boolean,
    tireInformationPresent?: boolean,
): PriceMessage[] => {
    const notStaggeredPriceMessage =
        checkVehicleOrTirePresent(vehicleInformationPresent, tireInformationPresent) && isArrayNotEmpty(skus)
            ? skus[0].priceMessage
            : priceMessage
    return staggeredState
        ? isArrayNotEmpty(skus) && skus?.[minimumVariantsLength].priceMessage
        : notStaggeredPriceMessage
}

/**
 * function to return fitment note for tire product
 * @param {ProductSku} skus
 * @param {boolean} isTireProduct
 * @return {string}
 */
export const getFitmentNote = (skus: ProductSku, isTireProduct: boolean): string => {
    if (isTireProduct) {
        const tirefitmentNote = skus?.fitment?.tireWheelFitment
        return Array.isArray(tirefitmentNote) ? tirefitmentNote?.[0]?.note : tirefitmentNote?.note
    } else {
        return ''
    }
}

/**
 * function return rear tire badges based on staggered condition
 * @param {ProductSku[]} skus
 * @param {string[]} badges
 * @param {boolean} staggeredState
 * @param {boolean} vehicleInformationPresent
 * @param {boolean} tireInformationPresent
 * @param {string[]} exclude
 * @param {boolean} isTireProduct
 * @return {string[]}
 */
export const rearBadgeList = (
    skus: ProductSku[],
    badges: string[],
    staggeredState?: boolean,
    vehicleInformationPresent?: boolean,
    tireInformationPresent?: boolean,
    exclude?: string[],
    isTireProduct?: boolean,
): string[] => {
    const notStaggeredBadgeList =
        checkVehicleOrTirePresent(vehicleInformationPresent, tireInformationPresent) && isArrayNotEmpty(skus)
            ? combineBadges(skus[0].badges, badges, exclude, getFitmentNote(skus[0], isTireProduct))
            : badges
    return staggeredState
        ? isArrayNotEmpty(skus) &&
              combineBadges(
                  skus[minimumVariantsLength].badges,
                  badges,
                  exclude,
                  getFitmentNote(skus[minimumVariantsLength], isTireProduct),
              )
        : notStaggeredBadgeList
}

/**
 * function to get rebate details
 * @param { ProductSku[] } skus
 * @param { RebateData } rebate
 * @param {boolean} staggeredState
 * @param { boolean } vehicleInformationPresent
 * @param { boolean } tireInformationPresent
 * @return { RebateData | null }
 */

export const getRebateDetails = (
    skus: ProductSku[],
    rebate: RebateData,
    staggeredState?: boolean,
    vehicleInformationPresent?: boolean,
    tireInformationPresent?: boolean,
): RebateData | null => {
    const notStaggeredRebate =
        checkVehicleOrTirePresent(vehicleInformationPresent, tireInformationPresent) && isArrayNotEmpty(skus)
            ? skus[0]?.rebate
            : rebate
    return !staggeredState ? notStaggeredRebate : rebate
}
