import { GlobalPropsHelper } from '../analytics/helpers/globalProps'
import { easternBanners } from '../globalConstants'

const globalProps = new GlobalPropsHelper()

/**
 * get the current banner id which user is currently browsing
 * @return {string} returns current banner id
 */
export const getOfferBannerId = (): string => {
    const configs = globalProps.init()
    return configs['offerBannerId'] as string
}

/**
 * get the current banner id
 * @return {string} returns current banner id
 */
export const getBannerId = (): string => {
    const configs = globalProps.init()
    return configs['bannerId'] as string
}

/**
 * @deprecated please do not use this method as it is not correct approach to develop logics based on banner ids
 * we will be redefining this logic implementation as part of SITEPOD-18869
 * Method to check if given bannerId is eastern banner or not
 * @param {string} bannerId current banner id
 * @returns {boolean} isEasternBanner
 */
export const isEasternBanner = (bannerId?: string): boolean => {
    if (!bannerId) {
        return easternBanners.includes(getBannerId())
    }
    return easternBanners.includes(bannerId)
}
