import { ActionType, getType } from 'typesafe-actions'

import {
    StoreResponse,
    StoreWithAvailability,
    Filter,
    StoreGeoPoint,
    StoreResponseErrorData,
} from '../models/storeDetails.interface'
import {
    fetchNearbyStoreErrorAction,
    fetchNearbyStoreSuccessAction,
    fetchStoreDetailsSuccessAction,
    setSelectedStoreNameSuccessAction,
    resetSelectedStoreToastSuccessAction,
    fetchStoreFiltersSuccessAction,
    fetchStoreFiltersErrorAction,
    setPreferredStoreIdSuccessAction,
    setPreferredStoreDetailsAction,
    setStoreChangeAction,
    setPreferredStoreDetailsErrorAction,
    storeLatLongDetails,
    setNearByStoreListForSelectedStore,
    setNearByStoreListForSelectedSku,
    setNearByStoreListForPostalCode,
    setNearByStoreListForStoreLocator,
    fetchStoreDetailsErrorAction,
    setIsNearByStoreListApiDoneAction,
    setStoreAPITriangulationFailAction,
    setIsStoreDetailsFetched,
    setIsGeolocationAllowed,
    fetchNearbyStoresErrorCode,
    setIsNearByStoreListApiSuccessAction,
} from '../actionCreators'
import * as actions from '../actionCreators'
import { AxiosResponse, AxiosResponseHeaders } from 'axios'
import { EmptyActionType } from '../models/globalData.interface'

type Action = ActionType<typeof actions> | EmptyActionType

export const emptyStoreResponseError: AxiosResponse<StoreResponseErrorData> = {
    data: {} as StoreResponseErrorData,
    status: 0,
    statusText: '',
    headers: null as unknown as AxiosResponseHeaders,
    config: {},
}

export const initialState: StoreResponse = {
    storeDetails: {} as StoreWithAvailability,
    storeFilters: [] as Filter[],
    nearbyStoreList: [] as StoreWithAvailability[],
    nearByStoreListForAStore: [] as StoreWithAvailability[],
    nearByStoreListForSelectedSku: [] as StoreWithAvailability[],
    nearByStoreListForPostalCode: [] as StoreWithAvailability[],
    storeListForSTH: [] as StoreWithAvailability[],
    selectedStoreName: '',
    isStoreNameChangedSuccess: false,
    selectedPreferredStoreId: '',
    preferredStoreDetails: {} as StoreWithAvailability,
    currentUserLocation: {} as StoreGeoPoint,
    nearByStoreListForStoreLocator: [] as StoreWithAvailability[],
    StoreErrorResponse: emptyStoreResponseError,
    isNearbyStoreListApiDone: false,
    isNearbyStoreListSuccess: false,
    isStoreDetailsFetched: false,
    storeAPITriangulationFail: false,
    isGeoLocationAllowed: false,
}

// eslint-disable-next-line complexity, sonar/cyclomatic-complexity
export const storeDetailsReducer = (state: StoreResponse = initialState, action: Action): StoreResponse => {
    switch (action.type) {
        case getType(fetchStoreFiltersSuccessAction): {
            return { ...state, storeFilters: action.payload }
        }
        case getType(fetchStoreFiltersErrorAction): {
            return { ...state, storeFilters: [] }
        }
        case getType(fetchStoreDetailsSuccessAction): {
            return {
                ...state,
                storeDetails: action.payload,
                StoreErrorResponse: emptyStoreResponseError,
            }
        }
        case getType(fetchNearbyStoreSuccessAction): {
            return { ...state, nearbyStoreList: action.payload.stores }
        }
        case getType(fetchNearbyStoreErrorAction): {
            return { ...state, nearbyStoreList: [] }
        }
        case getType(setSelectedStoreNameSuccessAction): {
            return { ...state, selectedStoreName: action.payload, isStoreNameChangedSuccess: true }
        }
        case getType(resetSelectedStoreToastSuccessAction): {
            return { ...state, isStoreNameChangedSuccess: false }
        }
        case getType(setPreferredStoreIdSuccessAction): {
            return { ...state, selectedPreferredStoreId: action.payload }
        }
        case getType(setPreferredStoreDetailsAction): {
            return { ...state, preferredStoreDetails: action.payload }
        }
        case getType(setStoreChangeAction): {
            return { ...state, storeChangeSource: action.payload }
        }
        case getType(setPreferredStoreDetailsErrorAction): {
            return { ...state, preferredStoreDetails: {} as StoreWithAvailability }
        }
        case getType(storeLatLongDetails): {
            return { ...state, currentUserLocation: action.payload }
        }
        case getType(setNearByStoreListForSelectedStore): {
            return { ...state, nearByStoreListForAStore: action.payload.stores }
        }
        case getType(setNearByStoreListForSelectedSku): {
            return { ...state, nearByStoreListForSelectedSku: action.payload.stores }
        }
        case getType(setNearByStoreListForPostalCode): {
            return { ...state, nearByStoreListForPostalCode: action.payload.stores }
        }
        case getType(setNearByStoreListForStoreLocator): {
            return { ...state, nearByStoreListForStoreLocator: action.payload.stores }
        }
        case getType(fetchStoreDetailsErrorAction): {
            return { ...state, StoreErrorResponse: action.payload }
        }
        case getType(setIsNearByStoreListApiDoneAction): {
            return { ...state, isNearbyStoreListApiDone: action.payload }
        }
        case getType(setIsNearByStoreListApiSuccessAction): {
            return { ...state, isNearbyStoreListSuccess: action.payload }
        }
        case getType(setIsStoreDetailsFetched): {
            return { ...state, isStoreDetailsFetched: action.payload }
        }
        case getType(setStoreAPITriangulationFailAction): {
            return { ...state, storeAPITriangulationFail: action.payload }
        }
        case getType(setIsGeolocationAllowed): {
            return { ...state, isGeoLocationAllowed: action.payload }
        }
        case getType(fetchNearbyStoresErrorCode): {
            return { ...state, StoreErrorResponse: action.payload }
        }
        case getType(actions.setHeliumInflationStoreToggleAction): {
            return {
                ...state,
                preferredStoreDetails: { ...state.preferredStoreDetails, isHeliumInflationOptionEnabled: false },
            }
        }
        default:
            return state
    }
}
