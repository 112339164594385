/**
 * returns formatted string "firstName lastName"
 * @param {string} firstName
 * @param {string} lastName
 * @return {string}
 */
export const getAccountHolderName = (firstName: string | undefined, lastName: string | undefined): string => {
    return !!firstName && !!lastName ? `${firstName} ${lastName}` : ''
}

/**
 * if firstName/lastName is not available return hypen, if available return phoneNumber
 * @param {string} firstName
 * @param {string} lastName
 * @return {string}
 */
export const getNameDetails = (firstName: string, lastName: string): string => {
    // eslint-disable-next-line sonar/expression-complexity
    return firstName || lastName ? (firstName || '') + ' ' + (lastName || '') : '—'
}
